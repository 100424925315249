import React,{useEffect,useState} from "react";
import { Button, Form, Input,Table ,Modal,Tooltip, Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import  {colors}  from "../../../environments/environment"


const OpenOrderDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const record = location.state && location.state.record;
    console.log("record",record);
    const qrScanItemCountStock = record.stock?.filter(item => item.isQrScan).length || 0;
    const totalItemCountStock = record.stock?.length || 0;
    
    const qrScanItemCountWastage = record.wastage?.filter(item => item.isQrScan).length || 0;
    const totalItemCountWastage = record.wastage?.length || 0;
    
    const [loading, setLoading] = useState(false);
    const [depotNames, setDepotNames] = useState(new Set());


     const [selectedItemsDetails, setSelectedItemsDetails] = useState([])
   
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recordForModal, setRecordForModal] = useState(null);

    const handleRequestRemoval = (record) => {
      setRecordForModal(record);
      setIsModalVisible(true);
  };
  
    const handleModalCancel = () => {
      setIsModalVisible(false);
    };
  
    const handleRequestRemovalFinish = () => {
      setIsModalVisible(false); 
    };
  

  useEffect(() => {
    const fetchData = async () => {
    
      const newDepotNames = new Set();

      // Stock dizisi
      if (record && record.stock) {
        record.stock.forEach(item => {
          if (item.depotName) {
            newDepotNames.add(item.depotName);
          }
        });
      }

      // Wastage dizisi
      if (record && record.wastage) {
        record.wastage.forEach(item => {
          if (item.depotName) {
            newDepotNames.add(item.depotName);
          }
        });
      }

      setDepotNames(newDepotNames);

      console.log("depotnamesss", depotNames)
      
    };
  
    fetchData();
  }, []);
  
  const getProductDetailById = async (productId) => {
    try {
      const productDetail = await FirebaseService.getRequestedProductDataById(productId);
      return productDetail;
    } catch (error) {
      console.error('Hata:', error);
      return null;
    }
  };
  
  const goPage = (record) => {
    navigate('/purchaserequestsendmail', { state: {selectedItemsDetails, record } })
  };
   
  const columnsRaw = [
    {
      title: 'Kalite',
      dataIndex: 'quality',
      key: 'quality',
      width: 200,
      render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text}</span>,
    },

      {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
        width: 200,
        render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text}</span>,
      },

     
      {
        title: 'En',
        dataIndex: 'width',
        key: 'width',
        width: 200,
        render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text}</span>,
      },
      {
        title: 'Boy',
        dataIndex: 'height',
        key: 'height',
        width: 200,
        render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text}</span>,
      },

      {
        title: 'Yüzey',
        dataIndex: 'surface',
        key: 'surface',
        width: 200,
        render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text}</span>,
      },
 
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text,record) => <span style={{color: record.isQrScan ? "#84BB4C" : "#000000"}}>{text}</span>,
        width: 200,
      },
      {
      title : ' ',
      dataIndex : 'isSendPurchaseicon',
      key : 'isSendPurchaseicon',

render: (text, record) => {
    return (
      <>
        {record.isSendPurchase ? (
          <Tooltip title="Satın Alma Talebi Gönderildi">
            <div >
              <img width={20} height={20} alt="logo" src="/images/isSendPurchaseicon.png" style={{ paddingBottom: "5%" }} />
            </div>
          </Tooltip>
        ) : null
         
      }
      </>
    );
  },
           
    },
    
    ];

    const goWaitingOpenOrder = async () => {
      setLoading(true);
    
      try {
        console.log("record waiting open order",record)
        const allOpenOrder = await FirebaseService.getOpenOrderData();
        const filteredOpenOrder = allOpenOrder.find(item => 
          item.isDeleted === false &&
          item.jobCode === record.jobCode
        );
    
       console.log("filteredOpenOrder",filteredOpenOrder)
       
       if (filteredOpenOrder) {
        try {
          const updateOpenOrderItem = {
            isSetup: false,
            isWaiting: true,
            deadline:null
            };
            const result = await FirebaseService.updateOpenOrder(filteredOpenOrder.key, updateOpenOrderItem);
            
          console.log('open order verisi başarıyla güncellendi');
        } catch (error) {
          console.error('open order silinirken hata oluştu:', error);
        }
      } else {
        console.error('Eşleşen open order verisi bulunamadı.');
      } 

      
        
       // Setup verisinin tamamen silinmesi
        const setupData = await FirebaseService.getSetupData();
        const filteredSetup = setupData.find(item => 
          item.isDeleted === false && 
          item.jobCode === record.jobCode 
         
        );
        console.log("filteredSetup",filteredSetup)
        if (filteredSetup) {
          try {
            const resultSetup = await FirebaseService.deleteSetupData(filteredSetup.key);
            console.log('Setup verisi başarıyla silindi');
          } catch (error) {
            console.error('Setup silinirken hata oluştu:', error);
          }
        } else {
          console.error('Eşleşen setup verisi bulunamadı.');
        }    

        // fason üretim silme
        const processes = await FirebaseService.getContractManufacturingData();
        const filteredProcesses = processes.filter(item => {
          return !item.isDeleted && item.jobCode && item.jobCode===record.jobCode;
        });

        console.log("filteredProcesses",filteredProcesses)
        
        for (const item of filteredProcesses) {
          try {
            const result = await FirebaseService.deleteContractManufacturingData(item.key);
            if (result) {
              console.log(`Item with key ${item.key} deleted successfully.`);
            } else {
              
              console.log(`Failed to delete item with key ${item.key}.`);
            }
          } catch (error) {
          
            console.error(`Error deleting item with key ${item.key}:`, error);
          }
        }

         //part silme kısmı
         const partsData = await FirebaseService.getPartsData();
         const filteredPart = partsData.filter(item => {
           return  !item.isDeleted && item.jobCode=== record.jobCode
         });
 
         console.log("filteredPart",filteredPart)
         
         for (const item of filteredPart) {
           try {
             const result = await FirebaseService.deletePartData(item.key);
             if (result) {
               console.log(`Item with key ${item.key} deleted successfully.`);
             } else {
              
               console.log(`Failed to delete item with key ${item.key}.`);
             }
           } catch (error) {
            
             console.error(`Error deleting item with key ${item.key}:`, error);
           }
         }
    

         //fire silme
          const wastagesData = await FirebaseService.getWastagesData();
          const filteredWastage = wastagesData.filter(item => {
            return  item.isDeleted && item.jobCode=== record.jobCode
          });
  
          console.log("filteredWastage",filteredWastage)
          
          for (const item of filteredWastage) {
            try {
              const result = await FirebaseService.deleteWastageData(item.key);
              if (result) {
                console.log(`Item with key ${item.key} deleted successfully.`);
              } else {
               
                console.log(`Failed to delete item with key ${item.key}.`);
              }
            } catch (error) {
             
              console.error(`Error deleting item with key ${item.key}:`, error);
            }
          }
    
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: 'Bekleyen siparişe başarıyla aktarıldı.',
          placement: 'topRight'
        });
        setTimeout(() => {
          navigate("/programmerpage");
        }, 2000);
        setLoading(false);
  
       
      } catch (error) {
        console.error("Silme işlemi hatası:", error);
      
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: error.message || 'Teklif silme işlemi başarısız oldu.',
          placement: 'topRight'
        });

      } setLoading(false)
      
    };
    

  
   return ( 
    <div style={{ display: "flex", flexDirection: "column",padding:"10px 100px"}}>
  
    <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}> Açık Sipariş Detay</h2>
          
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Müşteri Firma</p>
          {record && record.companyName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Depo Firma</p>
          {Array.from(depotNames).length > 0 ? (
          Array.from(depotNames).map((depotName, index) => (
            <p key={index}>{depotName}</p>
          ))
        ) : (
          <p>-</p> // Eğer set boşsa '-' yazdır
        )}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
          {record && record.jobCode}
        </div>


        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
  <p style={{ fontWeight: "bold", color: colors.blue }}>Durum</p>
  {
    (() => {
      let statusColor = "#000000"; // Varsayılan renk
      let currentStatus = "Başlamadı"; // Varsayılan durum

      // Lazer durumu
      let allLaserFinish = false;
      let halfLaserFinish = false;

      // record.plates'in var olup olmadığını kontrol et
      if (record.plates && Array.isArray(record.plates)) {
        allLaserFinish = record.plates.every(plate => plate.isFinish);
        halfLaserFinish = record.plates.some(plate => plate.isFinish) && !allLaserFinish;
      }

      if (halfLaserFinish) {
        currentStatus = "Lazerde";
      }
      if (allLaserFinish) {
        currentStatus = "Lazer bitti";
      }

      // Operasyonları kontrol etme
      const operationSet = new Set();
      let otherOperationsOngoing = [];

      if (record.parts?.length > 0) {
        record.parts.forEach(part => {
          part.operations.forEach(operation => {
            if (!operationSet.has(operation)) {
              operationSet.add(operation);

              if (!operation.isFinish) {
                otherOperationsOngoing.push(operation);
              }
            }
          });
        });
      }

      // Eğer lazer tamamlandıysa ve abkant işlemi devam ediyorsa
      if (allLaserFinish && otherOperationsOngoing.some(op => op === "Abkant")) {
        currentStatus = "Abkantta";
      }

      // Eğer lazer ve abkant tamamlandıysa ve başka devam eden işlemler varsa, onları yazdır
      if (allLaserFinish && otherOperationsOngoing.length > 0 && !otherOperationsOngoing.includes("Abkant")) {
        currentStatus = `${otherOperationsOngoing[0]} işleminde`; // İlk devam eden işlemi göster
      }

      return (
        <span style={{ color: record.isDeleted ? "#00000080" : statusColor }}>
          {currentStatus}
        </span>
      );
    })()
  }
</div>


        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Programcı Adı</p>
            {record && record.programmerName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
          {record && record.deadline && typeof record.deadline === 'object' ?
            new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') :
            record.deadline
          }
        </div>
      </div>

     <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px",width:"70%" }}>Gerekli Hammadeler ({qrScanItemCountStock} okutuldu / {totalItemCountStock} toplam) </div>
      <Table
      style={{width:"70%", margin:"auto", border:"2px solid", borderColor:colors.blue, borderRadius:"10px"}}
            locale={{
              emptyText: 'Seçilen hammade bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
           dataSource={record.stock}
           columns={columnsRaw}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />


    <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px",width:"70%" }}>Gerekli Fireler ({qrScanItemCountWastage} okutuldu / {totalItemCountWastage} toplam) </div>
      <Table
      style={{width:"70%", margin:"auto", border:"2px solid", borderColor:colors.blue, borderRadius:"10px"}}
      locale={{
        emptyText: 'Seçilen fire bulunmamaktadır...',
        filterReset: 'Sıfırla',
        filterTitle: 'Filtre Menüsü',
        selectAll: 'Hepsini Seç',
        selectInvert: 'Tersini Seç',
        selectionAll: 'Tümünü Seç',
        sortTitle: 'Sıralama',
        triggerDesc: 'Azalan sıralama için tıklayın',
        triggerAsc: 'Artan sıralama için tıklayın',
        cancelSort: 'Sıralamayı iptal etmek için tıklayın',
      }}
           dataSource={record.wastage}
           columns={columnsRaw}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
       

       <div style={{textAlign:"right", marginTop:"20px"}}>
        <Button 
               style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} 
              onClick={goWaitingOpenOrder}>
             Bekleyen Siparişe Aktar
         </Button>

        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
         )}
       </div>
    </div>



  )};
export default OpenOrderDetail;