import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import  { colors}  from "../../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';

const ProductionOpenOrderList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [items, setItems] = useState();
  console.log( "items",items);
  useEffect(() => {
    window.scrollTo(0, 0);
   

    const fetchData = async () => {
      setItems(record.data);
    };

    fetchData();
  }, [record]);

  const goEditPage = (record) => {
   // navigate('/productionopenorderdetail', { state: { record } })
    navigate('/openorderdetail', { state: { record } })

  };

  console.log("items",items)
  console.log("record",record)


  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
      title: 'Proje Kodu',
      dataIndex: 'projectCode',
      key: 'projectCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        // Lazer durumu
        let currentStatus = "Başlamadı"; // Varsayılan değer
    
        const allLaserFinish = record.plates.every(plate => plate.isFinish);
        const halfLaserFinish = record.plates.some(plate => plate.isFinish) && !allLaserFinish;
    
        if (halfLaserFinish) {
          currentStatus = "Lazerde";
        }
        if (allLaserFinish) {
          currentStatus = "Lazer bitti";
        }
    
        // Operasyonları kontrol etme
        const operationSet = new Set();
        let otherOperationsOngoing = [];
    
        if (record.parts?.length > 0) {
          record.parts.forEach(part => {
            part.operations.forEach(operation => {
              if (!operationSet.has(operation)) {
                operationSet.add(operation);
    
                if (!operation.isFinish) {
                  otherOperationsOngoing.push(operation);
                }
                console.log("operation: ", operation);
              }
            });
          });
        }
    
        console.log("operation set", operationSet);
        console.log("other operation ongoing", otherOperationsOngoing);
    
        // Eğer lazer tamamlandıysa ve abkant işlemi devam ediyorsa
        if (allLaserFinish && otherOperationsOngoing.some(op => op === "Abkant")) {
          currentStatus = "Abkantta";
        }
    
        // Eğer lazer ve abkant tamamlandıysa ve başka devam eden işlemler varsa, onları yazdır
        if (allLaserFinish && otherOperationsOngoing.length > 0 && !otherOperationsOngoing.includes("Abkant")) {
          currentStatus = `${otherOperationsOngoing[0]} işleminde`; // İlk devam eden işlemi göster
        }
    
        return (
          <span>
            {currentStatus}
          </span>
        );
      }
    },
    
    
   /*
    {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
        render: (text, record) => {
          const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
          return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
        },
        sorter: (a, b) => {
          const deadlineA = a.deadline ? a.deadline.seconds : 0;
          const deadlineB = b.deadline ? b.deadline.seconds : 0;
          return deadlineA - deadlineB;
      },
      },*/
      /*
      {
        title: ' ',
        dataIndex: 'important',
        key: 'important',
        render: (text, record) => {
          console.log("record aaa", record.stock);
      
          const showImage = record.stock && record.stock.some(stockItem => stockItem.isSendPurchase === true);
      
          return showImage ? (
            <img
              width={30}
              height={20}
              alt="logo"
              src="/images/important.png"
              style={{ paddingBottom: "5%" }}
            />
          ) : null;
        },
      },*/
     
      {
        title: ' ',
        dataIndex: 'important',
        key: 'important',
        render: (text, record) => {
      
          const showImage = record.stock && record.stock.some(stockItem => stockItem.isSendPurchase === true);
      
          return showImage ? (
           <div style={{color:colors.lightRed}}> Satın Alma Talebi var</div>
          ) : null;
        },
      },
         
    {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
          Detayı Gör
       </Button>
        ),
      }
  ];

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Açık Siparişler - Üretim</h3>
        <Input
        style={{
          borderRadius: 0,
          border: 'none',
          borderBottom: '1px solid #1A446C80',
          width: '100%',
          maxWidth: '400px',
          textAlign: 'left',
          outline: 'none',
        }}
        placeholder="İş Kodu Ara"
        suffix={
          <Space>
              <img width={15} alt="edit" src="/images/searchicon.png" />
          </Space>
        }
        onChange={(e) => {
          const searchValue = e.target.value.toLowerCase();
          const filteredData = items.filter(item => {
            const jobCode = item.jobCode.toLowerCase() ;
            return jobCode.includes(searchValue);
          });
          setItems(filteredData);
        }}
      />

        <Table
            locale={{
              emptyText: 'Henüz üretimde açık sipariş bulunmamaktadır....',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>

  };
  export default ProductionOpenOrderList;
 