import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,DatePicker, Modal, message,Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections,colors}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddRequestAdminSupplier from "../addRequestAdmin/addRequestAdminSupplier";
import AddRequestAdminCompany from "../addRequestAdmin/addRequestAdminCompany";
import AddRequestAdminContractManufacturingProcess from "../addRequestAdmin/addRequestAdminContractManufacturingProcess";
import moment from 'moment'



const ContractManufacturingAddOpenOrder = ({ record,onClose }) => {
  console.log("record",record)
    const [suppliers, setSuppliers] = useState([]); 
    const [process, setProcess] = useState([]); 
    const [parts, setParts] = useState([]); 
    const [openOrderDeadline, setOpenOrderDeadline] = useState(null);
    const [code, setCode] = useState(''); 
    const [loading, setLoading] = useState(false);
    const currentDate =new Date()

    useEffect(() => {
      const fetchData = async () => {
    

        const processes = await FirebaseService.getContractManufacturingProcessesData();
        const filteredProcesses = processes.filter(item => !item.isDeleted && item.processName.toLowerCase() !== 'abkant');
        setProcess(filteredProcesses);

        const suppeliers = await FirebaseService.getSuppliersData();
        const filteredSuppliers = suppeliers.filter(item => !item.isDeleted);
        setSuppliers(filteredSuppliers);

      
        const parts = await FirebaseService.getPartsData();
        const uniqueParts = new Map();
        
        parts.forEach((item) => {
            if (!item.isDeleted && !uniqueParts.has(item.partNo)) {
                uniqueParts.set(item.partNo, item);
            }
        });
        
        const filteredParts = Array.from(uniqueParts.values());
        setParts(filteredParts);
        

        const code= generateRandomCode(5);
        setCode(code);
      };
      fetchData();
    }, []);
    
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };

    
    const onFinish = async (values) => {
      setLoading(true)
      if (values.supplierName === "newSupplier") {
        NotificationService.openErrorNotification({
          title: 'Hata',
          description: 'Lütfen var olan bir tedarikçi seçiniz.',
          placement: 'topRight'
        });
        setLoading(false)
        return;
      }
     
      if (values.processName === "newContactManufacturingProcess") {
        NotificationService.openErrorNotification({
          title: 'Hata',
          description: 'Lütfen var olan bir süreç seçiniz.',
          placement: 'topRight'
        });
        setLoading(false)
        return;
      }


      const parts = additionalContents.map((content, index) => ({
        partCode: values[`partCode-${index}`] || '',
       // unitPrice: values[`unitPrice-${index}`] || '', 
        piece: values[`piece-${index}`] || '', 
       // profit:  values[`profit-${index}`] || '', 
       unitPrice:null,
       profit:null

      }));

      const newParts = additionalContents.map((content, index) => {
        // Assuming processName is already an array
        const processNames = values.processName;
    
        const operations = processNames.map(processName => ({
            processName: processName.trim(),
            isContractManufacturing: false,
            isCompleted: false,
            isQrScan: false,
            firstUserId: localStorage.getItem("uid") || '',
            firstUserNameSurname:  localStorage.getItem('name') + " " + localStorage.getItem('surname') || ''
        }));
    
        return {
            partNo: values[`partCode-${index}`] || null,
            jobCode: record.jobCode || null,
            companyName: record.companyName || null,
            piece: values[`piece-${index}`] || 0,
            operations: operations,
            firstTransactionDate:currentDate,
            projectCode: record.projectCode || null,


            programNo:null,
            width:null,
            thickness:null,   
            customerName:null,
            cadFilePath:null,
            cadFileName:null,
            imageFilePath:null,
            imageFileName:null,
            weightUnit:null,
            timeSetup:null,
            unit:null,
            thicknessSetup:null,
            weightSetup:null,
            quality:null,
            surface:null,
            setupName:null,
        };
    });
    
    console.log('Updated Parts List: newParts', newParts);
  

      if (parts.length === 0) {
        NotificationService.openErrorNotification({
          title: 'Hata',
          description: 'Lütfen parça seçiniz.',
          placement: 'topRight'
        });
        setLoading(false)
        return;
      }



      for (const part of newParts) {
        console.log("part",part, )
        
        try {
         let result = await FirebaseService.addRecording(parsCollections.parts, part);
          if (result) {
            console.log(`part with key ${part.key}  successfully.`);
          } else {
            console.log(`Failed to item with key ${part.key}.`);
          }
        } catch (error) {
          console.error(`Error  item with key ${part.key}:`, error);
        }
      }

     // console.log('Tüm Parts Bilgileri:', parts);
      //console.log("vvalues.openOrderDeadline",openOrderDeadline)
      const processNames = values.processName; 

      const processPromises = processNames.map(async (process) => {
        const data = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          isDeleted: false,
          companyName: record.companyName,
          projectCode: record.projectCode,
          supplierName: values.supplierName,
          jobCode: record.jobCode ? record.jobCode : null,
          deadline: new Date(record.deadline),
          openOrderDeadline : new Date(record.deadline),
          processName: process, 
          isContractManufacturing: false,
          isShipment: false,
          isRequestCompleted: true,
          parts: parts,
          isCompleted: false,
          code:code,
          invoiceNumber:null
      };

         // console.log("data", data);

          try {
              const result = await FirebaseService.addRecording(parsCollections.contractManufacturing, data);
              if (result.success) {
                  return { success: true };
              } else {
                  return { success: false, message: result.message };
              }
          } catch (error) {
              console.error("Hata:", error);
              return { success: false, message: "Bilinmeyen bir hata ile karşılaşıldı." };
          }
      });

      Promise.all(processPromises)
          .then((results) => {
              const allSuccess = results.every((result) => result.success);
              if (allSuccess) {
                  console.log("Tüm işlemler tamamlandı. Yönlendirme yapılabilir.");

                  NotificationService.openSuccessNotification({
                      title: 'İşlem Başarılı',
                      description: 'Tüm işlemler tamamlandı.',
                      placement: 'topRight'
                  });
                  setLoading(false)
                  onClose();
                  setTimeout(() => {
                      window.location.reload();
                  }, 2000);
                  
              } else {
                  console.log("İşlemlerden en az biri başarısız.");

                  NotificationService.openErrorNotification({
                      title: 'İşlem Başarısız',
                      description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                      placement: 'topRight'
                  });setLoading(false)
              }
          })
          .catch((error) => {
              console.error("Bilinmeyen bir hata ile karşılaşıldı:", error);
              NotificationService.openErrorNotification({
                  title: 'İşlem Başarısız',
                  description: "Bilinmeyen bir hata ile karşılaşıldı.",
                  placement: 'topRight'
              });setLoading(false)
          });

   
    }; 

    const onChange = (value) => {
      if (value === 'newSupplier') {
          console.log('Yeni Tedarikçi Ekle seçildi');
          setIsModalVisibleSupplier(true);
      }
     else if (value === 'newContactManufacturingProcess') {
      console.log('Yeni Süreç Ekle seçildi');
      setIsModalVisibleProcess(true);
    }
      else{
        
      }
  };
  const [isModalVisibleSupplier, setIsModalVisibleSupplier] = useState(false);

  const handleModalCancelSupplier = () => {
      setIsModalVisibleSupplier(false);
  };

  const handleAddFinishSupplier = () => {
      setIsModalVisibleSupplier(false);
  };



  const [isModalVisibleProcess, setIsModalVisibleProcess] = useState(false);

  const handleModalCancelProcess = () => {
      setIsModalVisibleProcess(false);
  };

  const handleAddFinishProcess = () => {
      setIsModalVisibleProcess(false);
  };


  const [additionalContents, setAdditionalContents] = useState([]);

  const addAdditionalContent = () => {
    const newContent = {
      partCode: '',
      unitPrice: '',
      piece: '',
      profit: ''
    };
    setAdditionalContents([...additionalContents, newContent]);
  };
  
  const removeAdditionalContent = (contentIndex) => {
    const updatedContents = additionalContents.filter((content, index) => index !== contentIndex);
    setAdditionalContents(updatedContents);
  };
  

  const handleDateChange = (date, dateString) => {
    console.log("dateeeee",date)
    if(date ===null){
      setOpenOrderDeadline(null);

    }
    else{
      const formattedDate= new Date(date);
      //const formattedDate = moment(date).format('DD.MM.YYYY');
      console.log("formattedDate", typeof formattedDate)
      setOpenOrderDeadline(formattedDate);
    }
 
  };
  function generateRandomCode(length) {
    const characters = '0123456789';
    let result = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
  
    return result;
  }

  const disabledPastDates = (current) => {
    return current < moment().startOf('day');
  };
  

   return ( <div>

    
    
         <Form
            name="basic"
            labelCol={{
            span: 8,
            }}
            wrapperCol={{
            span: 15,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 0%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

        
           
           
            


            <Form.Item
             label="Tedarikçi Firma"
            name="supplierName"
            rules={[
                {
                required: true,
                message: 'Lütfen tedarikçi seçiniz!',
                },
            ]}
            >
            <Select
            showSearch
                onChange={onChange}
                placeholder="Tedarikçi Seçiniz"
                suffixIcon={<img style={{ width: '20px' }} alt="edit" src="/images/downarrow.png" />}
                options={[
                  ...suppliers.map(item => ({ label: item.supplierName, value: item.supplierName })),
                  { label: 'Yeni Tedarikçi Ekle', value: 'newSupplier' },
                ]}
                className="input-style"    
                filterOption={(input, option) => 
                  option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
                } 

              />
            
            </Form.Item>

              <Modal
                title="Tedarikçi Ekleme Talebi"
                visible={isModalVisibleSupplier}
                onCancel={handleModalCancelSupplier}
                footer={null}
              >
              <AddRequestAdminSupplier  onClose={handleAddFinishSupplier} />
              </Modal>
            
      

            <Form.Item
             label="Proses"
                name="processName"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen proses giriniz!',
                    },
                ]}
                >
                <Select
                showSearch
                mode="tags"
                onChange={onChange}
                placeholder="Proses Seçiniz"
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                /*
                options={[
                  ...process.map(item => ({ label: item.processName, value: item.processName })),
                  { label: 'Yeni Süreç Ekle', value: 'newContactManufacturingProcess' },
                ]}
                */
                options={
                  process.map(item => ({ label: item.processName, value: item.processName }))
                }
                className="input-style"    
                filterOption={(input, option) => 
                  option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
                } 

            />
            </Form.Item>

            <Modal
                title="Süreç Ekleme Talebi"
                visible={isModalVisibleProcess}
                onCancel={handleModalCancelProcess}
                footer={null}
              >
              <AddRequestAdminContractManufacturingProcess onClose={handleAddFinishProcess} />
              </Modal>

        <div >
          <p style={{ color: colors.blue, fontWeight: "bold" }}>Parça Ekle</p>
          <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left", fontWeight: "bold" }}>
            <div style={{ width: "230px", marginRight: "10px" }}>Parça Adı</div>
            <div style={{ width: "70px", marginRight: "10px" }}>Adet</div>
         
          </div>

          {additionalContents?.map((content, index) => (
            <div style={{ display: "flex" }} key={index}>
              <Form.Item
                name={`partCode-${index}`}
                rules={[{ required: true, message: "Parça adı" }]}
              >
                <Select
                showSearch
                        onChange={onChange}
                        placeholder="Parça Adı"
                        style={{ width:"230px"}}
                        suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                        options={[
                          ...parts.map(item => ({ label: item.partNo, value: item.partNo })),
                      
                        ]}
                        className="input-style" 
                        filterOption={(input, option) => 
                          option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
                        }    

                    />

              </Form.Item>

              <Form.Item
                name={`piece-${index}`}
                rules={[{ pattern: /^\d+$/, message: "Sadece rakam girişi yapınız.",required: true, }]}
              >
                <Input style={{  width: "70px" }} placeholder="Adet" className="input-style"     />
              </Form.Item>


           
              
              <Button onClick={() => removeAdditionalContent(index)}>-</Button>
            </div>
          ))}

          <Form.Item style={{ textAlign: "center" }}>
            <Button onClick={addAdditionalContent}>+</Button>
          </Form.Item>
        </div>

        {code &&  
             <div style={{display:"block"}}>
              <div style={{ color:colors.blue}}> Atanan İş Kodu</div>
              <div style={{ textAlign: 'center', border:"2px solid #2B7199", width:"100px", borderRadius:"10px",padding:"3px 20px", marginRight:"10px"}}> {code}</div>
             </div>
             }


        <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>)

};

ContractManufacturingAddOpenOrder.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

export default ContractManufacturingAddOpenOrder;


/*
   <div style={{ width: "80px", marginRight: "10px" }}>Fason Fiyatı (TL)</div>
            <div style={{ width: "70px", marginRight: "10px" }}>Kar Marjı (TL)</div>

   <Form.Item
                name={`unitPrice-${index}`}
                rules={[{ pattern: /^\d+(\.\d{1,2})?$/, message: "Geçerli fason fiyatı giriniz." ,required: true,}]}
              >
                <Input style={{ width: "80px" }} placeholder="Fason" className="input-style"  />
              </Form.Item>

              <Form.Item
                name={`profit-${index}`}
                rules={[{ pattern: /^\d+(\.\d{1,2})?$/, message: "Geçerli kar fiyatı giriniz." ,required: true,}]}
              >
                <Input style={{ width: "70px" }} placeholder="Kar" className="input-style"  />
              </Form.Item>

*/