import React, { useEffect, useState } from "react";
import { Button, Form, Input, Spin } from "antd";
import NotificationService from "../../services/antNotificationService";
import { useLocation, useNavigate } from "react-router-dom";
import FirebaseService from "../../services/firebaseService";

const SurfaceEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const record = location.state && location.state.record;
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const data = {
      surface: values.surfaceName,
      isDeleted: false,
    };

    try {
      const result = await FirebaseService.updateSurface(record.key, data);
      if (result.success) {
        if (record.surface !== data.surface) {
          const updateSuccess = await FirebaseService.updateSurfaceNameInAllTables(record.surface, data.surface);

          if (!updateSuccess) {
            NotificationService.openErrorNotification({
              title: 'Güncelleme Hatası',
              description: "Tüm tablolar güncellenemedi. Lütfen tekrar deneyiniz.",
              placement: 'topRight'
            });
            setLoading(false);
            return;
          }
        }

        NotificationService.openSuccessNotification({
          title: "İşlem Başarılı",
          description: result.message,
          placement: "topRight",
        });

        setLoading(false);
        setTimeout(() => {
          navigate("/surfacelist");
        }, 2000);
      } else {
        NotificationService.openErrorNotification({
          title: "İşlem Başarısız",
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: "topRight",
        });
        setLoading(false);
      }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: "İşlem Başarısız",
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: "topRight",
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({
      title: "Geçersiz Form",
      description: "Lütfen zorunlu alanları doldurunuz.",
      placement: "topRight",
    });
  };

  return (
    <div>
      <Form
        name="basic"
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 8 }}
        style={{ maxWidth: "100%", padding: "5% 0 0 5%" }}
        initialValues={{ surfaceName: record?.surface || "" }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Yüzey"
          name="surfaceName"
          rules={[
            { required: true, message: "Lütfen yüzey adı giriniz!" },
          ]}
        >
          <Input className="input-style" style={{ width: "100%" }} placeholder="Yüzey Adı" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 15, span: 18 }}>
          <Button htmlType="submit" loading={loading} style={{ backgroundColor: "#1A446C", color: "white", fontWeight: "500" }}>
            Gönder
          </Button>
        </Form.Item>
      </Form>

      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <Spin />
        </div>
      )}
    </div>
  );
};

export default SurfaceEdit;
