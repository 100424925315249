import React,{useState,useEffect} from "react";
import { Button, Form, Input, Space, Select, Checkbox } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { Modal, Upload } from 'antd';
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import  {parsCollections}  from "../../environments/environment"
import FirebaseStorage from "../../services/storegeService";
import { useNavigate } from 'react-router-dom';

const NewEdit = () => {

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
  
    const location = useLocation();
    const navigate = useNavigate();

    const record = location.state && location.state.record;

    const [fileList, setFileList] = useState([]);
    const [newFileList, setNewFileList] = useState([]);
      
    useEffect(() => {
      const fetchData = async () => {
           setFileList([
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: record.imageUrl,
          }
        ]);
      };
      fetchData();
    }, []);

    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };
  
    const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
    
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => {
      setFileList(newFileList)
      setNewFileList(newFileList)
    };
    const uploadButton = (
      <div style={{width:"1000px" }}>
      <img alt="plus" src="/images/uploadplus.png" />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Resim Yükle
      </div>
    </div>
      );

      const onFinish = async (values) => {
     
        const currentDate =new Date()
        const newsData = {
          firstTransactionDate: record.firstTransactionDateEdit??currentDate,
          firstUserId: record.firstUserId??localStorage.getItem("uid"),
          lastTransactionDate: currentDate,
          lastUserId: localStorage.getItem("uid"),
          isDeleted: record.isDeleted,
          description: values.description,
          title: values.title,
         
        };

        values.image = newFileList.length > 0 ? values.image : fileList.length>0 ? record.imageUrl : null;
          if(values.image===null){
            NotificationService.openErrorNotification({
              title: 'Geçersiz Resim',
              description: "Lütfen resim seçiniz!",
              placement: 'topRight'
            });
          }
          try {
              if(newFileList.length>0)
              {
                newsData.imageUrl = await FirebaseStorage.uploadImageAndGetURL(parsCollections.news, values.image.file);
              }
            const result = await FirebaseService.updateNew(record.key, newsData);
            if (result.success) {
              NotificationService.openSuccessNotification({
                title: 'İşlem Başarılı',
                description: result.message,
                placement: 'topRight'
              });
              setTimeout(() => {
                navigate("/newlist");
              }, 2000);
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });}
            
            
          } catch (error) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
          }      
        };

      

   return ( <div>
         <Form
           initialValues={{
            title: record.title ,
            description:record.description,
      
        
         
         
          }}
            name="basic"
            labelCol={{
            span: 2,
            }}
            wrapperCol={{
            span: 8,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
           
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        > 
        <Space style={{display:"flex"}}>
            <Form.Item 
            name="image"
            >
                    <Upload 
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        className="generic-add-upload"
                        beforeUpload={() => false}
                        >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>                
            </Form.Item>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                      <img
                      alt="example"
                      style={{
                          width: '100%',
                      }}
                      src={previewImage}
                      />
            </Modal>
          </Space>

            <Form.Item
            label="Başlık"
            name="title"
            rules={[
                {
                required: true,
                message: 'Lütfen haber başlığını giriniz!',
                },
            ]}
          
            >
            <Input className="input-style" style={{width:"100%"}} placeholder="Başlık"/>
            </Form.Item>

          
            <Form.Item
              label="Açıklama"
            name="description"
            rules={[
                {
                required: true,
                message: 'Lütfen haber içeriğini giriniz!',
                },
            ]}
            >
                 <Input.TextArea className="input-style" style={{width:"100%"}} placeholder="Açıklama"/>

              </Form.Item>

  
            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
    </div>)

        };
  export default NewEdit;