import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors, placeholderMap}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';


const OpenOrderGroupList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [items, setItems] = useState();
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
        // Bugünün tarihini al
        const today = new Date();

        // Grupları güncelle ve en yakın firstTransactionDate'i al
        const updatedRecords = record.map(group => {
            // İçindeki öğelerin firstTransactionDate'lerini al
            const transactionDates = group.data
                .map(item => item.firstTransactionDate ? new Date(item.firstTransactionDate) : new Date(0))
                .filter(date => date instanceof Date && !isNaN(date)); // Geçerli tarihleri filtrele

            // En yakın tarihi bul
            const nearestDate = transactionDates.length > 0 
                ? new Date(Math.max(...transactionDates)) // En küçük tarihi bul
                : null; // Eğer geçerli tarih yoksa null

            // En yakın tarihi grup nesnesine ekle
            return {
                ...group,
                firstTransactionDate: nearestDate,
            };
        });

        // Grupları firstTransactionDate'e göre sıralama (en son tarihten en eskiye)
        const sortedGroups = updatedRecords.sort((a, b) => {
            return (b.firstTransactionDate || new Date(0)) - (a.firstTransactionDate || new Date(0)); // Ters sıralama
        });

        setItems(sortedGroups);
        setFilteredItems(sortedGroups);
    };

    fetchData();
}, [record]);


console.log("record",record)


console.log("filtereditems",filteredItems)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase(); 
      const itemValue = item[dataIndex]?.toString().toLowerCase(); 
      return (
        !item.isDeleted &&
       
        (value ? itemValue.includes(value) : true) 
      );
    });
    setFilteredItems(filteredData);
  };
  
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };


  const getColumnSearchProps = dataIndex => ({
    
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
     <Input
    id="search-input"  
    placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
    value={selectedKeys[0]}
    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    style={{ width: 188, marginBottom: 8, display: 'block' }}
    />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90,background:colors.blue }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });

  const goDetail = (record) => {
    console.log("record",record)
    navigate('/openorderlist', { state: { record } })
  };

  const columns = [
    {
      title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      ...getColumnSearchProps('companyName'),
      sorter: (a, b) => {
        const companyNameA = a && a.companyName ? a.companyName : '';
        const companyNameB = b && b.companyName ? b.companyName : '';
        return companyNameA.localeCompare(companyNameB);
      }
  },
  
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text.substring(0, 5)}
      </span>,
       ...getColumnSearchProps('jobCode'),
       sorter: (a, b) => {
        const itemA = a && a.jobCode ? a.jobCode : '';
        const itemB = b && b.jobCode ? b.jobCode : '';
        return itemA.localeCompare(itemB);
      },
    },
    {
      title: 'Proje Kodu',
      dataIndex: 'projectCode',
      key: 'projectCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
       ...getColumnSearchProps('projectCode'),
       sorter: (a, b) => {
        const itemA = a && a.projectCode ? a.projectCode : '';
        const itemB = b && b.projectCode ? b.projectCode : '';
        return itemA.localeCompare(itemB);
      },
    },
/*
    {
      title: 'Durum',
      dataIndex: 'statusText',
      key: 'statusText',
      render: (text, record) => 
      {
        let statusColor = "#000000";
        let statusText = "Unknown";
  
        return <span style={{ color: record.isDeleted ? "#00000080" : statusColor }}>{text}</span>;
      },
      sorter: (a, b) => {
        const itemA = a && a.statusText ? a.statusText : '';
        const itemB = b && b.statusText ? b.statusText : '';
        return itemA.localeCompare(itemB);
      },
      ...getColumnSearchProps('statusText'),


     
  },*/
   
  {
    title: 'Termin Tarihi',
    dataIndex: 'deadline',
    key: 'deadline',
    render: (text, record) => {
      const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
      return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
    },
    sorter: (a, b) => {
      const deadlineA = a.deadline ? a.deadline.seconds : 0;
      const deadlineB = b.deadline ? b.deadline.seconds : 0;
      return deadlineA - deadlineB;
  },
  
  },      
  {
    title: '   ',
    dataIndex: 'edit',
    key: 'edit',
    render: (text, record) => (
        <Button  onClick={()=>goDetail(record)} style={{ backgroundColor: "white", color: colors.blue, fontWeight: "500", border: "none" }}>
        Detaya Git
      </Button>
    ),
  }
  ];

 
  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Açık Siparişler</h3>
        <Input
  style={{
    borderRadius: 0,
    border: 'none',
    borderBottom: '1px solid #1A446C80',
    width: '100%',
    maxWidth: '400px',
    textAlign: 'left',
    outline: 'none',
  }}
  placeholder="İş Kodu Ara"
  suffix={
    <Space>
      <img width={15} alt="search" src="/images/searchicon.png" />
    </Space>
  }
  onChange={(e) => {
    const searchValue = e.target.value.toLowerCase();
    console.log('Search Value:', searchValue); // Arama değerini kontrol et

    if (searchValue.length === 0) {
      setFilteredItems(items); // Arama kutusu boşsa tüm öğeleri göster
    } else {
      const filteredData = items.filter(item => {
        const jobCode = item.jobCode ? item.jobCode.toString().toLowerCase() : ''; // toString ile formatı kontrol et
        const jobCodeFirstFive = jobCode.slice(0, 5); // İlk 5 karakteri al
        console.log('Job Code (First 5):', jobCodeFirstFive); // İlk 5 karakteri kontrol et
        return jobCodeFirstFive.includes(searchValue);
      });

      console.log('Filtered Data:', filteredData); // Filtrelenmiş verileri kontrol et

      setFilteredItems(filteredData);
    }
  }}
/>




        <Table
            //locale={{ emptyText: 'Henüz açık sipariş bulunmamaktadır.' }}
            locale={{
              emptyText: 'Henüz eklemediniz...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={filteredItems}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />

    </div>

  };
  export default OpenOrderGroupList;
 