import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,DatePicker, Upload,Modal,List} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections, colors,openOrderType}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import FirebaseStorage from "../../../services/storegeService";
import SetupAdd from "./setupAdd";


const OpenOrderSecondOffer = ({ onClose }) => {
    const navigate = useNavigate();
    const [jobCodes, setJobCodes] = useState([]); 
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recordForModal, setRecordForModal] = useState(null);
    const [matchingItems, setMatchingItems] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
       

        const jobCodes = await FirebaseService.getOpenOrderData();
        const uniqueJobCodes = new Map();
        const existingPrefixes = new Set(); 

        jobCodes.forEach((item) => {
          
          if (item.jobCode) { 
            const prefix = item.jobCode.substring(0, 5); 
            if (!item.isDeleted && !existingPrefixes.has(prefix) && item.orderType === "offer" && !item.isOfferApproved && item.isSetup) {
              uniqueJobCodes.set(item.jobCode, item);
              existingPrefixes.add(prefix); 
            }
          }
        });

        const filteredJobCodes = Array.from(uniqueJobCodes.values());
        console.log("mevcut orderlar", filteredJobCodes);
        setJobCodes(filteredJobCodes);
    };
    
      fetchData();
    }, []);
    
const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
};    
    
const onChange =  async (value) => {
    console.log(`selected ${value}`);
    const firstPart = value.substring(0, 5);
    console.log("First part", firstPart);
    const data = await FirebaseService.getOpenOrderData();
    const matchingItems = data.filter(item => item.jobCode.startsWith(firstPart));
    console.log("matchingItems",matchingItems)
    setMatchingItems(matchingItems);
  
};

const handleSetup = (record) => {
  setRecordForModal(record);
  setIsModalVisible(true);
};
const handleModalCancel = () => {
  setIsModalVisible(false);
};

const handleSetupFinish = () => {
  setIsModalVisible(false); 
};

const onFinish = async (value) => {

  const selectedJobCode = value.jobCode;
  const regex = /^[^-]*/;
  const match = selectedJobCode.match(regex);
  const firstPart = match ? match[0] : selectedJobCode;
  console.log("firstPart", firstPart); 

  const data = await FirebaseService.getOpenOrderData();
  const openOrderInfo = data.filter(item => item.jobCode === selectedJobCode && item.orderType ==="offer");
  
  const selectedOpenOrderInfo = {
    companyName: openOrderInfo[0]?.companyName || null,
    orderType: openOrderInfo[0]?.orderType || null,
    projectCode: openOrderInfo[0]?.projectCode || null,
    warehouseName: openOrderInfo[0]?.warehouseName || null
  };
  
  console.log("selectedOpenOrderInfo", selectedOpenOrderInfo);
  const formattedRecord = {
    ...selectedOpenOrderInfo,
  //  deadline: new Date(record.deadline),
    jobCode:firstPart
  };

  handleSetup(formattedRecord)
  // navigate('/openorderaddcontinue', { state: { record: selectedOpenOrderInfo, jobCode:firstPart} });
  };
  

   return ( <div>

          <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
    
               <Form.Item
                label="İş Kodu"
                name="jobCode"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen iş kodu seçiniz!',
                    },
                ]}
                >
               <Select
               showSearch
                  onChange={onChange}
                  placeholder="İş Kodu Seçiniz"
                  suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                  options={jobCodes.map(item => ({ label: item.jobCode, value: item.jobCode }))}
                  className="input-style"
                  filterOption={(input, option) => 
                    option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
                  } 
                />
            </Form.Item>


            {matchingItems.length > 0 && (
              <List
                  header={<div style={{color:colors.blue, fontWeight:"bold"}}>Yüklenen Setuplar</div>}
                  bordered
                  dataSource={matchingItems}
                  renderItem={item => (
                      <List.Item key={item.key}>
                          {item.setupName && item.setupName.trim() !== "" 
                              ? `${item.jobCode} - ${item.setupName}` 
                              : `Setup bulunmamaktadır.`}
                      </List.Item>
                  )}
                  className="input-style" 
                  style={{ width:"100%"}}
              />
            )}
            
            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500", float:"right", marginTop:"20px"}}>Gönder</Button>
            </Form.Item>

        </Form>


        <Modal
          title="Setup Yükle"
          visible={isModalVisible}
          onCancel={handleModalCancel}
          footer={null}
        >
          <SetupAdd  recordx={recordForModal}  onClose={handleSetupFinish} />
        </Modal>
  

    </div>)

};

OpenOrderSecondOffer.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

  export default OpenOrderSecondOffer;