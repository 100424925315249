import React,{useEffect,useState} from "react";
import { Table, Button,Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import  { colors}  from "../../../../environments/environment"


const WaitingOrderOpenOrderListLimited = () => {
  const [items, setItems] = useState([]);
  const [itemsLimited, setItemsLimited] = useState([]);


  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    function isEqual(obj1, obj2) {
      // Tüm özelliklerin eşit olup olmadığını karşılaştır
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    }
  
    
    const fetchData = async () => {
      const dataOpenOrderNormal = await FirebaseService.getOpenOrderData();
      const dataOpenOrderFinish = await FirebaseService.getOpenOrderDataExpired();
  
      
      const filteredDataNormal = dataOpenOrderNormal.filter(item => !item.isDeleted && !item.isOfferApproved && item.setupName != null && !item.isOffer );
      const filteredDataFinish = dataOpenOrderFinish.filter(item => !item.isDeleted && !item.isOfferApproved && item.setupName != null && !item.isOffer );
      const data = [...filteredDataNormal, ...filteredDataFinish];
  
      const setupDataNormal = await FirebaseService.getSetupData();
      const setupDataFinish = await FirebaseService.getSetupDataFinish();
      const setupData = [...setupDataNormal, ...setupDataFinish];



      const invoiceData = await FirebaseService.getOpenOrderOfferData()
      const invoiceJobCodes = invoiceData.map(invoice => invoice.jobCode);
      const filteredData = data.filter(
        item => 
        !item.isDeleted &&
        !item.isWaiting &&
        item.orderType === "order" &&
        !invoiceJobCodes.includes(item.jobCode.substring(0, 5))
        && item.isShipment 
         );

  
      const updatedData = [];
  
      filteredData.forEach(item => {
        const setupItems = setupData.filter(setup => setup.jobCode === item.jobCode   && !item.isDeleted);
      
        if (setupItems.length > 0) {
         // console.log("setupItems", setupItems);
                const addedJobCodes = new Set();
      
          setupItems.forEach(setupItem => {
            // Aynı jobCode ve firstTransactionDate zaten eklenmişse ekleme
            const isDuplicate = updatedData.some(updatedItem => (
              updatedItem.jobCode === setupItem.jobCode &&
              isEqual(updatedItem.firstTransactionDate, setupItem.firstTransactionDate)
            ));
      
            if (!isDuplicate) {
              const updatedItem = { ...item, ...setupItem };
              updatedData.push(updatedItem);
              addedJobCodes.add(setupItem.jobCode);
            }
          });
        } else {
         // updatedData.push(item);
        // console.log("set up yok");
        }
      });
      
    
        const groupedByJobCodePrefix = updatedData.reduce((acc, item) => {
        const prefix = item.jobCode.split('-')[0];
        if (!acc[prefix]) {
          acc[prefix] = []; 
        }
        acc[prefix].push(item);
        return acc;
      }, {});
    
      const uniqueLimitedData = Object.entries(groupedByJobCodePrefix).map(([key, value]) => {
      const sampleItem = value[0]; 
  
  
      const mergedItem = value.reduce((acc, item) => {
  
        return {
          ...acc,
          programmerNames: [...acc.programmerNames, item.programmerName],
          totalKgs: acc.totalKgs + item.totalKg,
          setupTimes: { ...acc.setupTimes, [`${item.machine}-${item.fuel}`]: item.setupTime },
          totalKg: { ...acc.totalKg, [item.quality]: item.totalKg },
  
          deadlines: [...acc.deadlines, item.deadline], 
          parts: acc.parts.concat(item.parts)  
  
        };
      }, { programmerNames: [], totalKgs: 0, setupTimes: [], totalKg: [], deadlines: [], parts: []}); 
      
  
     // console.log("mergeditem", mergedItem)
        return {
          firstTransactionDate: sampleItem.firstTransactionDate,
          firstUserId: sampleItem.firstUserId,
          programmerUserId:sampleItem.programmerUserId,
          noOfferApprovedDescription:sampleItem.noOfferApprovedDescription,
          jobCode: key,    
          isDeleted: sampleItem.isDeleted,
          companyName: sampleItem.companyName,
          projectCode: sampleItem.projectCode,
          isOffer: sampleItem.isOffer,
          isOfferApproved: sampleItem.isOfferApproved,
          isOfferSetupUpdate: sampleItem.isOfferSetupUpdate,
          isOfferChange: sampleItem.isOfferChange,
          isOfferDenied: sampleItem.isOfferDenied,
          orderType: sampleItem.orderType,
          isWaiting: sampleItem.isWaiting,
          isSetup: sampleItem.isSetup,
          isChangedProgrammer: sampleItem.isChangedProgrammer,
          programmerName:mergedItem.programmerNames,
          totalKgs: mergedItem.totalKgs,
          setupTime: mergedItem.setupTimes,
          totalKg: mergedItem.totalKg,
          deadline: mergedItem.deadlines, 
          parts: mergedItem.parts, 
  
          items: value.map(item => ({
            ...item,
          })),
        };
      });
  

  
    if (uniqueLimitedData) {
      let updatedItems = uniqueLimitedData.map(item => {
        let newStatus = "Unknown";
        if (item.isWaiting && !item.isSetup && item.orderType === "order") {
          newStatus = "Setup Bekleniyor";
        } else if (item.isWaiting && !item.isSetup && item.orderType === "offer") {
          newStatus = "Setup Bekleniyor";
        } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && !item.isOffer) {
          newStatus = "Teklif Bekleniyor";
        } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange && !item.isOfferSetupUpdate && !item.isOfferDenied) {
          newStatus = "Teklif Verildi";
        } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && item.isOfferApproved) {
          newStatus = "Onaylandı";
        } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && item.isOfferChange && !item.isOfferSetupUpdate && !item.isOfferDenied) {
          newStatus = "Onaylanmadı,Teklif Değişikliği";
        } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange && item.isOfferSetupUpdate && !item.isOfferDenied) {
          newStatus = "Onaylanmadı,Setup Düzenleme";
        } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange && !item.isOfferSetupUpdate && item.isOfferDenied) {
          newStatus = "Onaylanmadı,Reddedildi";
        }
        
        return { ...item, status: newStatus };
      });
      updatedItems.sort((a, b) => {
        // "Onaylanmadı, Reddedildi" durumu en altta göstermek için özel sıralama
        if (a.status === "Onaylanmadı,Reddedildi" && b.status !== "Onaylanmadı,Reddedildi") {
          return 1; // a'yı sonra göster
        }
        if (a.status !== "Onaylanmadı,Reddedildi" && b.status === "Onaylanmadı,Reddedildi") {
          return -1; // a'yı önce göster
        }
    
        const dateA = new Date(a.firstTransactionDate);
        const dateB = new Date(b.firstTransactionDate);
        return dateB - dateA;
      });
    
    
      setItems(updatedItems);
      setItemsLimited(updatedItems)
    }
    
    };
    
    fetchData();
  }, []);

  const goPage = (record) => {
    navigate('/waitingorderopenorderlist', { state: { record } })
  };

  const columns = [
    {
      title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
    
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
        title: 'Proje Kodu',
        dataIndex: 'projectCode',
        key: 'projectCode',
       
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
      },
   
   /*   
    {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
          Detayı Gör
       </Button>
        ),
      }*/
  ];

  return  <div style={{  
       // width:"50%",
       
      }}
      className="limited-list-border">
      <Button onClick={()=>goPage(items)} className="title-limited-list">
          Faturalandırılmamış
       </Button>

       <Table
            locale={{
              emptyText: 'Henüz faturalandırılmamış sipariş bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={itemsLimited}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
      
     
    </div>

  };
  export default WaitingOrderOpenOrderListLimited;

    