import React, { useState } from "react";
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import AlarmListLimited from "../alarms/alarmListLimited";
import PersonnelPerfomanceListLimited from "../personnelPerfomance/personnelPerfomanceListLimited";
import OpenOrderGroupListLimitedDelete from "../openOrders/deleteOpenOrder/openOrderGroupListLimitedDelete";
import WebNavbar from "../webNavbar";
import ReportingListLimited from "../reporting/reportingListLimited";

const ManagerPage = () => {

  const navigate = useNavigate();
  const handleExitRaw = () => {
    navigate('/exitrawlist')
  };


  const handleReturnRaw = () => {
    navigate('/returnrawlist')
  };

  const handleExpenseList = () => {
    navigate('/expensedisplaylist')
  };

  const handleScrapList = () => {
    navigate('/scrapList')
  };

  return (
    <div style={{padding:"3%"}}>
    <WebNavbar />
  
   <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
      <OpenOrderGroupListLimitedDelete />

      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
      <ReportingListLimited/>

      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>

      <PersonnelPerfomanceListLimited />

      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px", display:"block" }}>
        <div>
        <AlarmListLimited />
        </div>
      
        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: "10px" }}>
           <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button 
            className="classic-button"
                onClick={handleReturnRaw}>
              İade (Hammade)
            </Button>
            </div>
            
            <div style={{ gridColumn: "span 1", gridRow: "span 1" , margin:"10px"}}>
                
            <Button  
            className="classic-button"
            onClick={handleExitRaw}>
             Hammade Çıkışı
             </Button>
            </div>

          
            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
          
                     
            <Button  
            className="classic-button"
            onClick={handleExpenseList}>
            Gider Listesi
             </Button>
          

            </div>

         

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button  
              className="classic-button"
              onClick={handleScrapList}>
           Hurda Listesi
            </Button>
          
            </div>


        </div>

   
   
      </div>
    </div>
 
   
    </div>
  );
};

export default ManagerPage;

