import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input, Modal, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import  { colors, parsCollections}  from "../../../../environments/environment"
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import OneSignalService from "../../../../services/oneSignalService";

const WaitingOrderOpenOrderList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  //console.log("record",record)
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (record) {
      /*
      //deadline göre sıralanıyor
  
      const sortedItems = record.sort((a, b) => {
        if (a.status === "Onaylanmadı,Reddedildi" && b.status !== "Onaylanmadı,Reddedildi") {
          return 1; 
        }
        if (a.status !== "Onaylanmadı,Reddedildi" && b.status === "Onaylanmadı,Reddedildi") {
          return -1;
        }
        const dateA = new Date(a.firstTransactionDate);
        const dateB = new Date(b.firstTransactionDate);
        return dateB - dateA; 
      });
    
      setItems(sortedItems );
      */

      fetchData();
    }
    else{
      fetchData();
    }
    
    
  }, [record]);

  function isEqual(obj1, obj2) {
    // Tüm özelliklerin eşit olup olmadığını karşılaştır
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
  function addTimes(time1, time2) {
    // time1 ve time2 undefined ise varsayılan bir değer ata
    if (!time1) {
      time1 = "00:00:00"; // Varsayılan değer
    }
    if (!time2) {
      time2 = "00:00:00"; // Varsayılan değer
    }
  
    if (!time1 || !time2) {
      return;
    }
  
    const [h1, m1, s1] = time1.split(':').map(Number);
    const [h2, m2, s2] = time2.split(':').map(Number);

  
    let seconds = s1 + s2;
    let minutes = m1 + m2;
    let hours = h1 + h2;
  
    if (seconds >= 60) {
      seconds -= 60;
      minutes++;
    }
  
    // 60 dakikadan fazla varsa, saati artır
    if (minutes >= 60) {
      minutes -= 60;
      hours++;
    }
  
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }
  
  function pad(num) {
    return num < 10 ? `0${num}` : `${num}`;
  }
  
  
  const fetchData = async () => {
    setLoading(true)
    const data = await FirebaseService.getOpenOrderData();
      const invoiceData = await FirebaseService.getOpenOrderOfferData();
      const invoiceJobCodes = invoiceData.map(invoice => invoice.jobCode);
      const filteredData = data.filter(
        item => !item.isDeleted &&
        !item.isWaiting &&
        item.orderType === "order" &&
        !invoiceJobCodes.includes(item.jobCode.substring(0, 5))
        && item.isShipment);

    const setupData = await FirebaseService.getSetupData();

    
    const cleanedFilteredData = filteredData.filter(item => {
      const validSetups = setupData.filter(
        setup => setup.jobCode === item.jobCode && !setup.isDeleted
      );
    
      return validSetups.length > 0;
    });
    
    
    const updatedData = [];
    
    cleanedFilteredData.forEach(item => {
      const setupItems = setupData.filter(
        setup => setup.jobCode === item.jobCode && !setup.isDeleted
      );
    
      const addedJobCodes = new Set();
    
      setupItems.forEach(setupItem => {
        const isDuplicate = updatedData.some(updatedItem => (
          updatedItem.jobCode === setupItem.jobCode &&
          isEqual(updatedItem.firstTransactionDate, setupItem.firstTransactionDate)
        ));
    
        if (!isDuplicate) {
          const updatedItem = { ...item, ...setupItem };
          updatedData.push(updatedItem);
          addedJobCodes.add(setupItem.jobCode);
        }
      });
    });
    
    console.log("Updated Data:", updatedData);
    
    
    
    const groupedByJobCodePrefix = updatedData.reduce((acc, item) => {
      let prefix;
      if (item.jobCode.includes('-')) {
        prefix = item.jobCode.split('-')[0]; // Tire varsa, tireden önceki kısmı al
      } else {
        prefix = item.jobCode.substring(0, 5); // Tire yoksa, ilk 5 karakteri al
      }
    
      if (!acc[prefix]) {
        acc[prefix] = [];
      }
      acc[prefix].push(item);
      return acc;
    }, {});
    
  
    const uniqueLimitedData = Object.entries(groupedByJobCodePrefix).map(([key, value]) => {
    const sampleItem = value[0]; 


    const mergedItem = value.reduce((acc, item) => {

      return {
        ...acc,
        programmerNames: [...acc.programmerNames, item.programmerName],
        totalKgs: acc.totalKgs + item.totalKg,
        setupTimes: { ...acc.setupTimes, [`${item.machine}-${item.fuel}`]: item.setupTime },
        totalKg: { ...acc.totalKg, [item.quality]: item.totalKg },

        deadlines: [...acc.deadlines, item.deadline], 
        parts: acc.parts.concat(item.parts)  

      };
    }, { programmerNames: [], totalKgs: 0, setupTimes: [], totalKg: [], deadlines: [], parts: []}); 
    

      return {
        firstTransactionDate: sampleItem.firstTransactionDate,
        firstUserId: sampleItem.firstUserId,
        programmerUserId:sampleItem.programmerUserId,
        noOfferApprovedDescription:sampleItem.noOfferApprovedDescription,
        jobCode: key,    
        isDeleted: sampleItem.isDeleted,
        companyName: sampleItem.companyName,
        projectCode: sampleItem.projectCode,
        isOffer: sampleItem.isOffer,
        isOfferApproved: sampleItem.isOfferApproved,
        isOfferSetupUpdate: sampleItem.isOfferSetupUpdate,
        isOfferChange: sampleItem.isOfferChange,
        isOfferDenied: sampleItem.isOfferDenied,
        orderType: sampleItem.orderType,
        isWaiting: sampleItem.isWaiting,
        isSetup: sampleItem.isSetup,
        isChangedProgrammer: sampleItem.isChangedProgrammer,
        programmerName:mergedItem.programmerNames,
        totalKgs: mergedItem.totalKgs,
        setupTime: mergedItem.setupTimes,
        totalKg: mergedItem.totalKg,
        deadline: mergedItem.deadlines, 
        parts: mergedItem.parts, 

        items: value.map(item => ({
          ...item,
        })),
      };
    });


  if (uniqueLimitedData) {
    let updatedItems = uniqueLimitedData.map(item => {
      let newStatus = "Unknown";
      if (item.isWaiting && !item.isSetup && item.orderType === "order") {
        newStatus = "Setup Bekleniyor";
      } else if (item.isWaiting && !item.isSetup && item.orderType === "offer") {
        newStatus = "Setup Bekleniyor";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && !item.isOffer) {
        newStatus = "Teklif Bekleniyor";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange && !item.isOfferSetupUpdate && !item.isOfferDenied) {
        newStatus = "Teklif Verildi";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && item.isOfferApproved) {
        newStatus = "Onaylandı";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && item.isOfferChange && !item.isOfferSetupUpdate && !item.isOfferDenied) {
        newStatus = "Onaylanmadı,Teklif Değişikliği";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange && item.isOfferSetupUpdate && !item.isOfferDenied) {
        newStatus = "Onaylanmadı,Setup Düzenleme";
      } else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange && !item.isOfferSetupUpdate && item.isOfferDenied) {
        newStatus = "Onaylanmadı,Reddedildi";
      }
      
      return { ...item, status: newStatus };
    });
    updatedItems.sort((a, b) => {
      // "Onaylanmadı, Reddedildi" durumu en altta göstermek için özel sıralama
      if (a.status === "Onaylanmadı,Reddedildi" && b.status !== "Onaylanmadı,Reddedildi") {
        return 1; // a'yı sonra göster
      }
      if (a.status !== "Onaylanmadı,Reddedildi" && b.status === "Onaylanmadı,Reddedildi") {
        return -1;
      }
  
      const dateA = new Date(a.firstTransactionDate);
      const dateB = new Date(b.firstTransactionDate);
      return dateB - dateA; 
    });
  
  
    setItems(updatedItems);
    setLoading(false)
  }
  
  };

  const goOrderPage = async (record) => {
  
    try {
      const data = await FirebaseService.getContractManufacturingData();
      const filteredData = data.filter(item => !item.isDeleted);
        const matchingData = filteredData.filter(item => 
        item.jobCode && item.jobCode.slice(0, 5) === record.jobCode.slice(0, 5)
      );
       const hasIncompleteRequests = matchingData.some(item => !item.isRequestCompleted);
       
       const hasCompleteRequests = matchingData.filter(item => item.isRequestCompleted === true);
     
        if (hasIncompleteRequests) {
        NotificationService.openErrorNotification({
          title: 'Uyarı',
          description: 'Fasonda bir veya daha fazla talep tamamlanmamış fiyat bilgisi girilmemiş. Lütfen kontrol edin.',
          placement: 'topRight'
        });
        return; 
      }
  
     navigate('/orderdetail', { state: { record , hasCompleteRequests:hasCompleteRequests} });
    } catch (error) {
      console.error('Error fetching data:', error);
      NotificationService.openErrorNotification({
        title: 'Hata',
        description: 'Veri alınırken bir hata oluştu.',
        placement: 'topRight'
      });
    }
  };
  

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  
  const calculateTotalTime = (timeStrings) => {
    const totalSeconds = timeStrings.reduce((acc, time) => {
      const [hours, minutes, seconds] = time.split(':').map(Number);
      return acc + (hours * 3600) + (minutes * 60) + seconds;
    }, 0);
    return formatTime(totalSeconds);
  };

  const columns = [
    {
      title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
     
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
    
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
        title: 'Proje Kodu',
        dataIndex: 'projectCode',
        key: 'projectCode',
    
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
      },
      /*
      {
        title: 'Programcı',
        dataIndex: 'programmerName',
        key: 'programmerName',
       
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text.map(item => (
              <>
              <div key={item}>-{item}</div>
             
              </>
            ))}
          </span>
          
        )
              },
              */
             
    {
      title: 'Programcı',
      dataIndex: 'programmerName',
       key: 'programmerName',
        render: (text, record) => (
         <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
          <div key={text[0]}>-{text[0]}</div>
          </span>
       )
     },
              
   
      {
        title: 'Toplam Kilo',
        dataIndex: 'totalKg',
        key: 'totalKg',
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {record && record.totalKgs.toFixed(2)} 
          </span>
        )
        
      },
     
      {
        title: 'Süre',
        dataIndex: 'setupTime',
        key: 'setupTime',
        render: (text, record) => {
          const totalSetupTime = Object.values(record.setupTime).reduce((total, timeString) => addTimes(total, timeString), '00:00:00');
      
          return (
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {totalSetupTime}
            </span>
          );
        }
      }
,      
      
   /*
      {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
        render: (text, record) => {
          // record.deadline var mı ve bir dizi mi kontrol et
          const formattedDeadlines = Array.isArray(record.deadline) ? 
            record.deadline
              .filter(d => d !== null) // null değerleri filtrele
              .map(d => new Date(d.seconds * 1000).toLocaleDateString('tr-TR')) : 
            [];
      
          return (
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
              {formattedDeadlines.length > 0 ? (
                formattedDeadlines.map((date, index) => (
                  <div key={index}>-{date}</div>
                ))
              ) : (
                <div>-</div>
              )}
           
            </span>
          );
        }
      }*/
      
      
      {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
        render: (text, record) => {

          const formattedDeadlines = Array.isArray(record.deadline) ? 
            record.deadline
              .filter(d => d !== null)
              .map(d => new Date(d.seconds * 1000).toLocaleDateString('tr-TR')) : 
            [];
        
          return (
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
              {formattedDeadlines.length > 0 ? (
                <div>{formattedDeadlines[0]}</div>
              ) : (
                <div>-</div>
              )}
            </span>
          );
        }
      }
,      
           
{
  title: '   ',
  dataIndex: 'edit',
  key: 'edit',
  render: (text, record) => (
    <Button 
    onClick={() => goOrderPage(record)} 
    style={{backgroundColor: colors.blue, color: "white", fontWeight: "500", }}
    >
    Fiyat Ver
    </Button> 
  ),
}

  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recordForModal, setRecordForModal] = useState(null);

  const handleRequestRemoval = (record) => {
    setRecordForModal(record);
    setIsModalVisible(true);
};

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleRequestRemovalFinish = () => {
    setIsModalVisible(false); 
  };

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Faturalandırılmamış</h3>
                
        <Table
            locale={{
              emptyText: 'Henüz bekleyen sipariş bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false}
            className="custom-news-table"
            rowClassName="custom-news-row"
            expandable={{
              expandedRowRender: x => (
                <div style={{ display: 'flex' }}>
                    {/* Kalınlık Parçası */}
                    <div>
                        <div style={{ marginBottom: "10px" }}>
                            <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Kalınlıklar:</span>
                            <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px", display: "block" }}>
                                {x.items.map((item, index) => (
                                    <div key={index} style={{ marginRight: "10px" }}>
                                        <span>{item.thickness} mm</span>
                                    </div>
                                ))}
                            </span>
                        </div>
                    </div>
            
                    {/* Kalite Parçası */}
                    <div style={{ marginLeft: "55px" }}>
                        <div style={{ marginBottom: "10px" }}>
                            <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Kaliteler:</span>
                            <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px", display: "block" }}>
                                {x.items.map((item, index) => (
                                    <div key={index} style={{ marginRight: "10px" }}>
                                        <span>{item.quality}</span>
                                    </div>
                                ))}
                            </span>
                        </div>
                    </div>
            
                    {/* Birim Süre Parçası */}
                    <div style={{ marginLeft: "55px" }}>
                        <div style={{ marginBottom: "10px" }}>
                            <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Birim Süreler:</span>
                            <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px", display: "block" }}>
                                {x.items.map((item, index) => (
                                    <div key={index} style={{ marginRight: "10px" }}>
                                        <span>{item.setupTime}</span>
                                    </div>
                                ))}
                            </span>
                        </div>
                    </div>
            
                    {/* Birim Kilo Parçası */}
                    <div style={{ marginLeft: "55px" }}>
                        <div style={{ marginBottom: "10px" }}>
                            <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Birim Kilolar:</span>
                            <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                                {x.items.map((item, index) => (
                                    <div key={index} style={{ marginRight: "10px" }}>
                                        <span>{item.totalKg?.toFixed(2)} Kg</span>
                                    </div>
                                ))}
                            </span>
                        </div>
                    </div>
                </div>
            )
            
            ,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a type="link" onClick={e => onExpand(record, e)} style={{ border: "none", backgroundColor: "transparent" }}>
                    <img style={{ width: "20px" }} alt="edit" src="/images/uparrow.png" />
                  </a>
                ) : (
                  <a type="link" onClick={e => onExpand(record, e)} style={{ border: "none", backgroundColor: "transparent" }}>
                    <img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />
                  </a>
                ),
              expandIconColumnIndex: 8
            }}
          />
           {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
                
    </div>

  };
  export default WaitingOrderOpenOrderList;
 