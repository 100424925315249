

import React, { useEffect, useState } from "react";
import { Table, Input, Button, Space, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { colors, placeholderMap } from "../../../environments/environment";
import { useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import XLSX from 'sheetjs-style'; 
import ExportReportCardTemplate from "./StockList.xlsx";

const StockList = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [itemsWastage, setItemsWastage] = useState([]);
  const [filteredItemsWastage, setFilteredItemsWastage] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [isWarehouseSelectedAll, setIsWarehouseSelectedAll] = useState(false);
  
  const [companies, setCompanies] = useState([]); 
  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [filters, setFilters] = useState({
    depotName: '',
    thickness: '',
    quality: '',
    width: '',
    height: '',
    surface: '',
    piece: '',
    reservedPiece: '',
    unitkg: '',
    totalkg: '',
});
const [filtersWastage, setFiltersWastage] = useState({
  depotName : '',
  thickness: '',
  quality: '',
  width: '',
  height: '',
  surface: '',
  piece: '',
  reservedPiece: '',
  unitkg: '',
  totalkg: '',
});


useEffect(() => {
  window.scrollTo(0, 0);

  const fetchData = async () => {
    console.log("record.depotName",record.depotName)
    const data = await FirebaseService.getStocksData();
    const filteredData = data.filter(item => {
      const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
      const hasWaitingPiece = item.waitingPiece !== null && item.waitingPiece !== undefined && item.waitingPiece !== 0;

      return (
        !item.isDeleted &&
        item.depotName === record.depotName &&
        (
          (item.piece === 0 && (hasReservedPiece || hasWaitingPiece)) || 
          (item.piece !== 0) 
        )
      );
    });

    setItems(filteredData);
    setFilteredItems(filteredData);

    const dataWastage = await FirebaseService.getWastagesData();
    const filteredDataWastage = dataWastage.filter(item => {
      const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
      const hasComingPiece = item.comingPiece > 0;  
    
      return (
        !item.isDeleted &&
        (
          item.isQRScanned || hasComingPiece 
        ) &&
        item.depotName === record.depotName &&
        (
          (item.piece === 0 && hasReservedPiece) || 
          (item.piece !== 0) || 
          (hasComingPiece && !item.isQRScanned) 
        )
      );
    });
    
    console.log("filteredDataWastage",filteredDataWastage)

    setItemsWastage(filteredDataWastage);
    setFilteredItemsWastage(filteredDataWastage);


    const companies = await FirebaseService.getCompaniesData();
    const filteredCompanies = companies.filter(item => !item.isDeleted);
    setCompanies(filteredCompanies);
  };

  

  fetchData();
}, []);



const handleSearch = (value, dataIndex) => {
  setFilters(prevFilters => {
    const updatedFilters = {
      ...prevFilters,
      [dataIndex]: value, // Sadece değiştirdiğimiz filtreyi günceller
    };

    // Arama terimini Türkçe karakterlere duyarlı şekilde küçült ve boşlukları temizle
    const searchValue = value?.toLocaleLowerCase('tr').trim(); 
    const filteredData = items.filter(item => {
      return (
        !item.isDeleted && // Silinmemiş öğeleri dahil et
        Object.keys(updatedFilters).every(key => {
          // Filtre değerlerini ve öğe değerlerini Türkçe karakterlere duyarlı şekilde küçült ve boşlukları temizle
          const filterValue = updatedFilters[key]?.toLocaleLowerCase('tr').trim(); 
          const itemValue = item[key] !== undefined ? item[key].toString().toLocaleLowerCase('tr').trim() : ''; 

          // Eşitlik kontrolü ile filtreleme
          return filterValue ? itemValue === filterValue : true;
        }) &&
        (searchValue ? item[dataIndex]?.toString().toLocaleLowerCase('tr').trim() === searchValue : true) // Eşitlik kontrolü
      );
    });

    setFilteredItems(filteredData); // Filtrelenmiş veriyi güncelle
    return updatedFilters; // Yeni filtreleri geri döndür
  });
};


// Arama kutusu

  
  

const handleReset = () => {
    setFilteredItems(items); // Tüm listeyi geri döndür
};

const getColumnSearchProps = dataIndex => ({
    title: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{placeholderMap[dataIndex] || dataIndex}</span>
            <Input
                id={`search-input-${dataIndex}`}
                placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
                onChange={e => handleSearch(e.target.value, dataIndex)} // Arama her değişiklikte yapılacak
                style={{ width: 80, marginTop: 8 }} // Genişliği ve üstten boşluğu ayarlayın
                suffix={<SearchOutlined />} // Arama ikonu ekleme
            />
        </div>
    ),
    sorter: (a, b) => {
        const aValue = a[dataIndex] !== undefined ? a[dataIndex].toString().toLowerCase() : '';
        const bValue = b[dataIndex] !== undefined ? b[dataIndex].toString().toLowerCase() : '';
        return aValue.localeCompare(bValue); // Sıralama işlemi
    },
});

  const columns = [
    {
      title: 'Depo',
      dataIndex: 'depotName',
      key: 'depotName',
     ...getColumnSearchProps('depotName'),
      render: (text) => <span style={{ color: items.isDeleted ? '#00000080' : '#000000' }}>{text} </span>,
      sorter: (a, b) => a.depotName - b.depotName,
    },
    {
      title: 'Kalınlık',
      dataIndex: 'thickness',
      key: 'thickness',
      ...getColumnSearchProps('thickness'),
      render: (text, record) => (
<span style={{ 
color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
(record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
fontFamily: "futura" 
}}>
{text} mm
        </span>
      ),       
       sorter: (a, b) => a.thickness - b.thickness,
    },
  
    {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
        ...getColumnSearchProps('quality'),
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
         (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),
        sorter: (a, b) => a.quality.localeCompare(b.quality), 
      },
      
 
      {
        title: 'En',
        dataIndex: 'width',
        key: 'width',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),        ...getColumnSearchProps('width'),
        sorter: (a, b) => a.width - b.width,

      },
      {
        title: 'Boy',
        dataIndex: 'height',
        key: 'height',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),        ...getColumnSearchProps('height'),
        sorter: (a, b) => a.height - b.height,

      },
    
      {
        title: 'Yüzey',
        dataIndex: 'surface',
        key: 'surface',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),        ...getColumnSearchProps('surface'),
        sorter: (a, b) => a.surface.localeCompare(b.surface), 

      },
    
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),        ...getColumnSearchProps('piece'),
        sorter: (a, b) => a.piece - b.piece,

      },
         
      {
        title: 'Rezerve Adet',
        dataIndex: 'reservedPiece',
        key: 'reservedPiece',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text ? text : "-"}
          </span>
        ),   
        ...getColumnSearchProps('reservedPiece'),
        sorter: (a, b) => a.piece - b.piece,  
       },
           
      {
        title: 'Beklenen Adet',
        dataIndex: 'waitingPiece',
        key: 'waitingPiece',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text ? text : "-"}
          </span>
        ),  ...getColumnSearchProps('waitingPiece'),
        sorter: (a, b) => a.piece - b.piece,
        },
    
    
      {
        title: 'Birim Kilo',
        dataIndex: 'unitKilo',
        key: 'unitKg',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text && text.toFixed(2)} Kg
          </span>
        ),  
        ...getColumnSearchProps('unitKg'),
        sorter: (a, b) => a.unitKg - b.unitKg,

      },
    
      {
        title: 'Toplam Kilo',
        dataIndex: 'totalKilo',
        key: 'totalKg',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text && text.toFixed(2)} Kg
          </span>
        ),  
        ...getColumnSearchProps('totalKg'),
        sorter: (a, b) => a.totalKg - b.totalKg,

      },
      {
        title: 'Minimum Adet',
        dataIndex: 'minPiece',
        key: 'minPiece',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text ? text : "-"}
          </span>
        ),  
            },
            {
              title: 'Açıklama',
              dataIndex: 'isProblem',
              key: 'isProblem',
              render: (text, record) => {
                const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
                const formattedDate = record.problemDate ? new Date(record.problemDate.seconds * 1000).toLocaleString('tr-TR', options) : '';
                
                return (
                  <Popover
      content={
        <div style={{ width: '200px', maxHeight: '700px' }}> {/* Sabit yükseklik ve genişlik */}
          <p><strong>Problem Zamanı:</strong> {formattedDate}</p>
          <p><strong>Problem Fotoğrafı:</strong> 
            {record.problemImage ?                 
            <img alt="" src={record.problemImage} style={{  width: '150px',height:"150px",objectFit:"contain" }} />
            : 
            "Fotoğraf bulunmamaktadır."}</p>
                <p style={{ width: '200px', maxHeight: '500px', overflow:"hidden" }}><strong>Problem Açıklaması:</strong> {record.problemDescription}</p>
              </div>
            }
            title="Sac levhada problem bulunmaktadır."
            trigger="click" // veya hover
          >
                    <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>
                      {record.isProblem ? <img width={30} height={20} alt="logo" src="/images/important.png" style={{ paddingBottom: "5%" }} /> : null}
                    </span>
                  </Popover>
                );
              },
            
            },
    
  ];



  
  const handleSearchWastage = (value, dataIndex) => {
    setFiltersWastage(prevFilters => {
      const updatedFilters = {
        ...prevFilters,
        [dataIndex]: value, // Sadece değiştirdiğimiz filtreyi günceller
      };
  
      // Arama terimini Türkçe karakterlere duyarlı şekilde küçült ve boşlukları temizle
      const searchValue = value?.toLocaleLowerCase('tr').trim();
  
      // Filtreleme işlemi
      const filteredData = itemsWastage.filter(item => {
        // Eğer filtre değerleri boşsa tüm listeyi geri döndür
        if (!value) {
          return true;
        }
  
        return (
          !item.isDeleted && // Silinmemiş öğeleri dahil et
          Object.keys(updatedFilters).every(key => {
            // Filtre değerlerini ve öğe değerlerini Türkçe karakterlere duyarlı şekilde küçült
            const filterValue = updatedFilters[key]?.toLocaleLowerCase('tr').trim();
            const itemValue = item[key] ? item[key].toString().toLocaleLowerCase('tr').trim() : '';
  
            // Eşitlik kontrolü
            return filterValue ? itemValue === filterValue : true;
          }) &&
          (searchValue ? item[dataIndex]?.toString().toLocaleLowerCase('tr').trim() === searchValue : true) // Arama değeri eşitlik kontrolü
        );
      });
  
      setFilteredItemsWastage(filteredData); // Filtrelenmiş veriyi güncelle
      return updatedFilters; // Yeni filtreleri geri döndür
    });
  };
  
  
  
  
 
  


  
  // Arama kutusu
  const getColumnSearchPropsWastage = dataIndex => ({
    title: (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{placeholderMap[dataIndex] || dataIndex}</span>
        <Input
          id={`search-input-${dataIndex}`}
          placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
          onChange={e => handleSearchWastage(e.target.value, dataIndex)} // Her değişiklikte arama işlemi yapılacak
          style={{ width: "100%", marginTop: 8 }} // Genişliği ve üstten boşluğu ayarlayın
          suffix={<SearchOutlined />} // Arama ikonu ekleme
        />
      </div>
    ),
    sorter: (a, b) => {
      const aValue = a[dataIndex] !== undefined ? a[dataIndex].toString().toLowerCase() : '';
      const bValue = b[dataIndex] !== undefined ? b[dataIndex].toString().toLowerCase() : '';
      return aValue.localeCompare(bValue); // Sıralama işlemi
    },
  });

  
  

  
  const columnsWastage = [
    {
      title: 'Depo',
      dataIndex: 'depotName',
      key: 'depotName',
      ...getColumnSearchPropsWastage('depotName'),
      render: (text, record) => (
        <span 
          style={{ 
            color: (record.isQRScanned === false && record.comingPiece > 0) ? 'red' : 'black'
          }}
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.depotName - b.depotName,
    },
    {
      title: 'Kalınlık',
      dataIndex: 'thickness',
      key: 'thickness',
      ...getColumnSearchPropsWastage('thickness'),
      render: (text, record) => (
        <span 
          style={{ 
            color: (record.isQRScanned === false && record.comingPiece > 0) ? 'red' : 'black'
          }}
        >
          {text} mm
        </span>
      ),
      sorter: (a, b) => a.thickness - b.thickness,
    },
    {
      title: 'Kalite',
      dataIndex: 'quality',
      key: 'quality',
      ...getColumnSearchPropsWastage('quality'),
      render: (text, record) => (
        <span 
          style={{ 
            color: (record.isQRScanned === false && record.comingPiece > 0) ? colors.red : colors.black
          }}
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.quality.localeCompare(b.quality),
    },
    {
      title: 'En',
      dataIndex: 'width',
      key: 'width',
      render: (text, record) => (
        <span 
          style={{ 
            color: (record.isQRScanned === false && record.comingPiece > 0) ? 'red' : 'black'
          }}
        >
          {text}
        </span>
      ),
      ...getColumnSearchPropsWastage('width'),
      sorter: (a, b) => a.width - b.width,
    },
    {
      title: 'Boy',
      dataIndex: 'height',
      key: 'height',
      render: (text, record) => (
        <span 
          style={{ 
            color: (record.isQRScanned === false && record.comingPiece > 0) ? 'red' : 'black'
          }}
        >
          {text}
        </span>
      ),
      ...getColumnSearchPropsWastage('height'),
      sorter: (a, b) => a.height - b.height,
    },
    {
      title: 'Yüzey',
      dataIndex: 'surface',
      key: 'surface',
      render: (text, record) => (
        <span 
          style={{ 
            color: (record.isQRScanned === false && record.comingPiece > 0) ? 'red' : 'black'
          }}
        >
          {text}
        </span>
      ),
      ...getColumnSearchPropsWastage('surface'),
      sorter: (a, b) => a.surface.localeCompare(b.surface),
    },
    {
      title: 'Adet',
      dataIndex: 'piece',
      key: 'piece',
      render: (text, record) => (
        <span 
          style={{ 
            color: (record.isQRScanned === false && record.comingPiece > 0) ? 'red' : 'black'
          }}
        >
          {text}
        </span>
      ),
      ...getColumnSearchPropsWastage('piece'),
      sorter: (a, b) => a.piece - b.piece,
    },
    {
      title: 'Rezerve Adet',
      dataIndex: 'reservedPiece',
      key: 'reservedPiece',
      ...getColumnSearchPropsWastage('reservedPiece'),
      render: (text, record) => (
        <span 
          style={{ 
            color: (record.isQRScanned === false && record.comingPiece > 0) ? 'red' : 'black'
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Gelecek Adet',
      dataIndex: 'comingPiece',
      key: 'comingPiece',
      render: (text, record) => (
        <span 
          style={{ 
            color: (record.isQRScanned === false && record.comingPiece > 0) ? 'red' : 'black'
          }}
        >
          {text}
        </span>
      ),
      ...getColumnSearchProps('comingPiece'),
      sorter: (a, b) => a.comingPiece - b.comingPiece,
    },
    {
      title: 'Birim Kilo',
      dataIndex: 'unitKilo',
      key: 'unitKg',
      render: (text, record) => (
        <span style={{ color: (record.isQRScanned === false && record.comingPiece > 0) ? 'red' : 'black' }}>
          {text && text.toFixed(2)} Kg
        </span>
      ),
      ...getColumnSearchProps('unitKg'),
      sorter: (a, b) => a.unitKg - b.unitKg,
    },
    {
      title: 'Toplam Kilo',
      dataIndex: 'totalKilo',
      key: 'totalKg',
      render: (text, record) => (
        <span style={{ color: (record.isQRScanned === false && record.comingPiece > 0) ? 'red' : 'black' }}>
          {text && text.toFixed(2)} Kg
        </span>
      ),
      ...getColumnSearchProps('totalKg'),
      sorter: (a, b) => a.totalKg - b.totalKg,
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => (
        <Popover
          content={
            <div style={{ width: '200px', maxHeight: '700px' }}>
              <p><strong>Fotoğrafı:</strong>
                {record.imageUrl ? 
                  <img alt="" src={record.imageUrl} style={{ width: '150px', height: '150px', objectFit: 'contain' }} />
                  : "Fotoğraf bulunmamaktadır."}
              </p>
              <p style={{ width: '200px', maxHeight: '500px', overflow: 'hidden' }}><strong>Açıklaması:</strong> {record.description}</p>
            </div>
          }
          title="Firede açıklama bulunmaktadır."
          trigger="click"
        >
          <span 
            style={{ 
              color: (record.isQRScanned === false && record.comingPiece > 0) ? 'red' : 'black' 
            }}
          >
            {record.description ? <img width={30} height={20} alt="logo" src="/images/important.png" style={{ paddingBottom: "5%" }} /> : null}
          </span>
        </Popover>
      ),
    },
  ];
  

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      fetch(file)
        .then((response) => response.blob())
        .then((file) => reader.readAsBinaryString(file));
    });
  };

  const warehouseOptions = [{ label: 'Tümü', value: "all" }, ...companies.map(item => ({ label: item.companyName, value: item.companyName }))];

  const onChangeFilterWarehouse = async(value) => {
    console.log(`selected ${value}`);
    setSelectedWarehouse(value)
    localStorage.setItem("depotName", value);


    const savedDepotName = localStorage.getItem("depotName")
    console.log("savedDepotName 2 onChangeFilterWarehouse",savedDepotName) 
    setIsWarehouseSelectedAll(value === "all")
    try {
      const warehouseNameToUse = savedDepotName || value;

      console.log("warehouseNameToUse ",warehouseNameToUse)
      if (warehouseNameToUse) {
        const data = await FirebaseService.getStocksData();
        const dataWastage = await FirebaseService.getWastagesData();
  
        if (warehouseNameToUse === "all") {
          const filteredDataStock = data.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            const hasWaitingPiece = item.waitingPiece !== null && item.waitingPiece !== undefined && item.waitingPiece !== 0;
      
            return (
              !item.isDeleted &&
              (
                (item.piece === 0 && (hasReservedPiece || hasWaitingPiece)) || 
                (item.piece !== 0) // Diğer koşullarda piece 0 olmayanları da göster
              )
            );
          });

          setItems(filteredDataStock);
          setFilteredItems(filteredDataStock);
          
          const filteredDataWastage = dataWastage.filter(item => {
          const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;


          const hasComingPiece = item.comingPiece > 0;  // Check if comingPiece is greater than 0
    
          return (
            !item.isDeleted &&
            (
              item.isQRScanned || hasComingPiece // Show if isQRScanned is true or comingPiece is greater than 0
            ) &&
            item.depotName === record.depotName &&
            (
              (item.piece === 0 && hasReservedPiece) || 
              (item.piece !== 0) || 
              (hasComingPiece && !item.isQRScanned) // Show if comingPiece > 0 and QR is not scanned
            )
          );
        });
          
          const updatedDataWastage = filteredDataWastage.map(item => ({
            ...item,
            isQRScanned: item.isQRScanned === undefined ? true : item.isQRScanned
          }));
        
          setItemsWastage(updatedDataWastage);
          setFilteredItemsWastage(updatedDataWastage);
        } else {
          const searchValue = warehouseNameToUse.trim().toLowerCase(); 

          const filteredDataStock = data.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            const hasWaitingPiece = item.waitingPiece !== null && item.waitingPiece !== undefined && item.waitingPiece !== 0;
            const supplier = item.depotName?.trim().toLowerCase(); 
            return (
              !item.isDeleted &&
               supplier === searchValue &&
              (
                (item.piece === 0 && (hasReservedPiece || hasWaitingPiece)) || 
                (item.piece !== 0) // Diğer koşullarda piece 0 olmayanları da göster
              )
            );
          });
  
          setItems(filteredDataStock);
          setFilteredItems(filteredDataStock);
                   
          const filteredDataWastage = dataWastage.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            const hasComingPiece = item.comingPiece > 0;  // Check if comingPiece is greater than 0
            
            const supplier = item.depotName?.trim().toLowerCase(); 
            console.log("supplier searchValue",supplier,searchValue)
            console.log("warehouseNameToUse",warehouseNameToUse)
            return (
              !item.isDeleted &&
              (
                item.isQRScanned || hasComingPiece // Show if isQRScanned is true or comingPiece is greater than 0
              ) &&
              item.depotName === record.depotName &&
              supplier === searchValue &&
              (
                (item.piece === 0 && hasReservedPiece) || 
                (item.piece !== 0) || 
                (hasComingPiece && !item.isQRScanned) // Show if comingPiece > 0 and QR is not scanned
              )
            );
           
          
          });

          const updatedDataWastage = filteredDataWastage.map(item => ({
            ...item,
            isQRScanned: item.isQRScanned === undefined ? true : item.isQRScanned
          }));

          setItemsWastage(updatedDataWastage);
          setFilteredItemsWastage(updatedDataWastage);
        }
      } else {
       // console.log('Lütfen bir depo seçiniz.');
      }
    } catch (error) {
      console.error('Hata oluştu:', error);
    }
    };

  const goExcel = async () => {
    try {
      const binaryString = await readFile(ExportReportCardTemplate);
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
  
      const createDetailedItems = (items, productLabel) => {
        return items.map(productInfo => {
          const unitKilo = productInfo.unitKilo || '-';
          const totalKilo = productInfo.totalKilo || '-';
          const problemDescription = productLabel === "Plaka" ? productInfo.problemDescription || '-'  : productInfo.description || '-';
          return {
            Ürün: productLabel,
            Kalite: productInfo.quality || '-',
            Yüzey: productInfo.surface || '-',
            Kalınlık: productInfo.thickness || '-',
            En: productInfo.width || '-',
            Boy: productInfo.height || '-',
            Adet: productInfo.piece || '-',
            'Birim Kilo': unitKilo,
            'Toplam Kilo': totalKilo,
            Açıklama: problemDescription
          };
        });
      };
  
      const detailedSelectedItems = createDetailedItems(filteredItems, "Plaka");
      const detailedWastageItems = createDetailedItems(filteredItemsWastage, "Fire");
      const combinedItems = [...detailedSelectedItems, ...detailedWastageItems];
  
      let rowIndex = 1; 
      const columnHeaders = ['Ürün', 'Kalite', 'Yüzey', 'Kalınlık', 'En', 'Boy', 'Adet', 'Birim Kilo', 'Toplam Kilo', 'Açıklama'];
      columnHeaders.forEach((header, index) => {
          const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
      
          // Set the value of the header
          worksheet[cellAddress] = { t: 's', v: header };
      
          // Apply bold styling, underline, and background color
          worksheet[cellAddress].s = {
              font: {
                  bold: true,        // Makes the text bold
                  underline: true    // Adds underline
              },
              fill: {
                  patternType: 'solid',    // Fill pattern (solid color)
                  fgColor: { rgb: 'D3D3D3' } // Light gray background color
              },
              border: {
                  bottom: {
                      style: 'thin', // Adds a bottom border (underline)
                      color: { rgb: '000000' } // Border color: black
                  }
              }
          };
      });
      
      
  
      combinedItems.forEach(item => {
        columnHeaders.forEach((key, columnIndex) => {
          const value = item[key] || '-';
          worksheet[XLSX.utils.encode_cell({ r: rowIndex, c: columnIndex })] = { t: 's', v: value };
        });
        rowIndex++;
      });
  
      XLSX.writeFile(workbook, 'Guncel_Stok.xlsx');
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  

  return <div style={{
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '94vh',
    padding: "3%",
  }}>
     <div style={{ textAlign: "left", flex: "1 0 33%" }}>
    <p style={{ fontWeight: "bold", color: colors.blue }}>Depo</p>
    <Select
        onChange={onChangeFilterWarehouse}
        placeholder="Depo Seçiniz"
        style={{ width: "175px" }}
        showSearch
        filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
        }
        suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
        options={warehouseOptions
            .sort((a, b) => {
              if (a.label.toLowerCase() === "tümü") return -1;
              if (b.label.toLowerCase() === "tümü") return 1;
              if (a.label.toLowerCase() === "pars") return a.label.toLowerCase() === "tümü" ? 1 : -1;
              if (b.label.toLowerCase() === "pars") return b.label.toLowerCase() === "tümü" ? 1 : 1;
              return a.label.localeCompare(b.label);
          })
          }
        className="input-style"
    />
</div>

<Button style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500", float:"right" }} onClick={goExcel}>
            Excel İndir
          </Button>


    <h3 style={{ textAlign: "left", color: colors.blue }}>Güncel Stok</h3>
    <h4 style={{ textAlign: "left", color: colors.blue }}>{record.companyName}</h4>

    <Table
    scroll={{ y: 500 }}
      locale={{
        emptyText: 'Henüz stok bulunmamaktadır...',
        filterReset: 'Sıfırla',
        filterTitle: 'Filtre Menüsü',
        selectAll: 'Hepsini Seç',
        selectInvert: 'Tersini Seç',
        selectionAll: 'Tümünü Seç',
        sortTitle: 'Sıralama',
        triggerDesc: 'Azalan sıralama için tıklayın',
        triggerAsc: 'Artan sıralama için tıklayın',
        cancelSort: 'Sıralamayı iptal etmek için tıklayın',
      }}
      dataSource={filteredItems}
      columns={columns}
      showHeader={true}
      pagination={false}
      className="custom-news-table"
      rowClassName="custom-news-row"
    />


<h3 style={{ textAlign: "left", color: colors.blue }}>Güncel Fire</h3>
    <h4 style={{ textAlign: "left", color: colors.blue }}>{record.companyName}</h4>

    <Table
    scroll={{ y: 500 }}
        locale={{
        emptyText: 'Henüz fire bulunmamaktadır...',
        filterReset: 'Sıfırla',
        filterTitle: 'Filtre Menüsü',
        selectAll: 'Hepsini Seç',
        selectInvert: 'Tersini Seç',
        selectionAll: 'Tümünü Seç',
        sortTitle: 'Sıralama',
        triggerDesc: 'Azalan sıralama için tıklayın',
        triggerAsc: 'Artan sıralama için tıklayın',
        cancelSort: 'Sıralamayı iptal etmek için tıklayın',
      }}
      dataSource={filteredItemsWastage}
      columns={columnsWastage}
      showHeader={true}
      pagination={false}
      className="custom-news-table"
      rowClassName="custom-news-row"
    />
       

  </div>
};

export default StockList;

