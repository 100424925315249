import React, { useState } from "react";
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import PurchaseRequestAdd from "../purchaseRequests/purchaseRequestAdd";
import ContractManufacturingAdd from "../contractManufacturings/contractManufacturingAdd";
import AlarmListLimited from "../alarms/alarmListLimited";
import PurchaseRequestListLimited from "../purchaseRequests/purchaseRequestListLimited";
import ContractManufacturingListLimited from "../contractManufacturings/contractManufacturingListLimited";
import WantedOfferListLimited from "../wantedOffers/wantedOfferListLimited";
import ScrapAdd from "../scraps/scrapAdd";
import StockFilter from "../stock/stockFilter";
import WebNavbar from "../webNavbar";

const BuyerPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);


  const handleProcessStatus = () => {
    navigate('/processstatus')
  };
  
  const handlePurchaseRequest = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setTimeout(() => {
      window.location.reload();
    }, 100);
    setIsModalVisible(false);
  };

  const handlePurchaseRequestAddFinish = () => {
    setIsModalVisible(false); 
  };


  const [isModalVisibleContract, setIsModalVisibleContract] = useState(false);

  const handleContractManufacturingAdd = () => {
    setIsModalVisibleContract(true);
  };

  const handleModalCancelContract = () => {
    setIsModalVisibleContract(false);
  };

  const handleContractManufacturingAddFinish = () => {
    setIsModalVisibleContract(false); 
  };  

  const [isModalVisibleOpenOrder, setIsModalVisibleOpenOrder] = useState(false);

  const handleOpenOrder = () => {
    setIsModalVisibleOpenOrder(true);
  };

  const handleModalCancelOpenOrder = () => {
    setIsModalVisibleOpenOrder(false);
  };

  const handleOpenOrderAddFinish = () => {
    setIsModalVisibleOpenOrder(false); 
  };

  const handleReturnRaw = () => {
    navigate('/returnrawlist')
  };


  const [isModalVisibleStock, setIsModalVisibleStock] = useState(false);

  const handleStock = () => {
    setIsModalVisibleStock(true);
  };

  const handleModalCancelStock= () => {
    setIsModalVisibleStock(false);
  };

  const handleStockFinish = () => {
    setIsModalVisibleStock(false); 
  };

  const navigate = useNavigate();
  const handleCheckCurrentPrice = () => {
    navigate('/checkcurrentpricelist')
  };


  const handleFutureRaw = () => {
    navigate('/futurerawlist')
  };

  const [isModalVisibleScrap, setIsModalVisibleScrap] = useState(false);

  const handleScrap = () => {
    setIsModalVisibleScrap(true);
  };

  const handleModalCancelScrap= () => {
    setIsModalVisibleScrap(false);
  };

  const handleScrapFinish = () => {
    setIsModalVisibleScrap(false); 
  };

  const handleScrapList = () => {
    navigate('/scrapList')
  };





  return (
    <div style={{padding:"3%"}}>
     <WebNavbar />

    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
        <PurchaseRequestListLimited />
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
        <WantedOfferListLimited />
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px", overflow:"hidden" }}>
        <ContractManufacturingListLimited />
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px", display:"block" }}>
        <div>
        <AlarmListLimited />
        </div>
      
        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: "10px" }}>
            <div style={{ gridColumn: "span 1", gridRow: "span 1" , margin:"10px"}}>
                <Button 
                className="classic-button"
              onClick={handlePurchaseRequest}>
                Satın Alma Talep Et
                </Button>
                <Modal
                title="Satın Alma Talep Et"
                visible={isModalVisible}
                onCancel={handleModalCancel}
                footer={null}
                >
                <PurchaseRequestAdd onClose={handlePurchaseRequestAddFinish} />
                </Modal>
            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button 
             className="classic-button"
             onClick={handleCheckCurrentPrice}>
              Güncel Fiyat Kontrol
             </Button>

            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button 
            className="classic-button"
            onClick={handleContractManufacturingAdd}>
              Fason Üretim Talep
              </Button>

              <Modal
                title="Fason Üretim Talep Ekle"
                visible={isModalVisibleContract}
                onCancel={handleModalCancelContract}
                footer={null}
              >
                <ContractManufacturingAdd  onClose={handleContractManufacturingAddFinish} />
              </Modal> 

            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button  
              className="classic-button"
              onClick={handleFutureRaw}>
            Gelecek Hammade
            </Button>
            </div>


            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button  
              className="classic-button"
              onClick={handleScrap}>
           Hurda Giriş
            </Button>
            <Modal
                title="Hurda Giriş"
                visible={isModalVisibleScrap}
                onCancel={handleModalCancelScrap}
                footer={null}
              >
                <ScrapAdd onClose={handleScrapFinish} />
              </Modal> 
            </div>
            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
                <Button 
                className="classic-button"
               onClick={handleStock}>
                Güncel Stok
                </Button>
                <Modal
                title="Güncel Stok"
                visible={isModalVisibleStock}
                onCancel={handleModalCancelStock}
                footer={null}
                >
                <StockFilter />
                </Modal>
            </div>

           <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button 
            className="classic-button"
                onClick={handleReturnRaw}>
              İadeler
            </Button>
            </div>


            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button  
              className="classic-button"
              onClick={handleScrapList}>
           Hurda Listesi
            </Button>
          
            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
              <Button 
              className="classic-button"
              onClick={handleProcessStatus}>
              İş Durum Listesi
              </Button>
               
            </div>


          
            
        </div>

   
   
      </div>
    </div>
 
   
    </div>
  );
};

export default BuyerPage;

