import React,{useEffect,useState} from "react";
import { Button, Form, Input,Table ,Modal} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import  {colors}  from "../../../environments/environment"
import AlarmRequestRemoval from "./alarmRequestRemoval";
import AlarmRemove from "./alarmRemove";

const AlarmDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const record = location.state && location.state.record;
   
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleRequest, setIsModalVisibleRequest] = useState(false);

    const [recordForModal, setRecordForModal] = useState(null);
    const [userData, setUserData] = useState({});


    const handleRequestRemoval = (record) => {
      setRecordForModal(record);
      setIsModalVisibleRequest(true);
  };
  
    const handleModalCancelReqest = () => {
      setIsModalVisibleRequest(false);
    };
  
    const handleRequestRemovalFinish = () => {
      setIsModalVisibleRequest(false); 
    };
  
    useEffect(() => {
    const fetchData = async () => {
     
      const userData = await FirebaseService.getUserByUid(localStorage.getItem("uid"));
      setUserData(userData);
    };
  
    fetchData();
    }, []);
  

    
    const onFinishFailed = (errorInfo) => {
        NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };



  const handleRemove = (record) => {
    setRecordForModal(record);
    setIsModalVisible(true);
};

const handleModalCancel = () => {
  setIsModalVisible(false);
};

  const handleRemoveFinish = () => {
    setIsModalVisible(false); 
  };

   
   return ( 
    <>
    <div style={{ display: "flex", flexDirection: "column",padding:"10px 100px"}}>
  
        <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}> Alarm Detay</h2>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ textAlign: "left", flex: "1 0 50%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: "left", flex: "1 0 50%" }}>
                <p style={{ fontWeight: "bold", color: colors.blue }}>Firma</p>
                {record.companyName}
                </div>
                <div style={{ textAlign: "left", flex: "1 0 50%" }}>
                <p style={{ fontWeight: "bold", color: colors.blue }}>Alarm Başlığı</p>
                {record.title}
                </div>

            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ textAlign: "left", flex: "1 0 50%" }}>
                  <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
                  {record && record.jobCode !== null ? record.jobCode : '-'}
                </div>

                  <div style={{ textAlign: "left", flex: "1 0 50%" }}>
                <p style={{ fontWeight: "bold", color: colors.blue }}>Birimi</p>
                {record.unitName}
                </div>
          
            </div>
            
            <div style={{ textAlign: "left" }}>
                  <p style={{ fontWeight: "bold", color: colors.blue }}>Açıklama</p>
                  {record.description}
            </div>
          </div>   

          <div style={{ textAlign: "left", flex: "1 0 50%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Fotoğraf</p>
            <div  style={{ border: "3px solid",  width: '400px',height:"400px" , borderColor:colors.blue, borderRadius:"15px",padding:"10px" }}>
            {record.imageUrl ? (
                  <img alt="" src={record.imageUrl} style={{  width: '400px',height:"400px",objectFit:"contain" , }} />
                ) : (
                  <div style={{textAlign:"center", paddingTop:"150px"}}>
          <img alt="plus" src="/images/uploadcamera.png"  width={30} height={30}/>
                  <p >Fotoğraf bulunmamaktadır</p>
                  </div>
                )}
              </div>
          </div>
        </div>

    </div>

    <div style={{padding:"10px 100px", display:"flex", float:"right"}}>
       <Button
       style={{
         backgroundColor: record.isRequestRemoval ? "gray" : colors.blue,
         color: "white",
         fontWeight: "500",
         float:"right",
         marginRight:"100px",
         marginTop:"40px"
       }}  onClick={() => handleRequestRemoval(record)}
         disabled={record.isRequestRemoval}  
       >
         {record.isRequestRemoval ? "Alarm Kaldırma Talebiniz Gönderildi" : "Alarm Kaldırmayı Talep Et"}
     </Button>

   { userData && userData.isManager ? (
            <Button onClick={() => handleRemove(record)}
            style={{
              backgroundColor: colors.blue,
              color: "white",
              fontWeight: "500",
              float:"right",
              marginRight:"100px",
              marginTop:"40px"
            }} 
            >
              Alarm Kaldır
            </Button>
        ) : null
       }


   <Modal
     title="Alarm Kaldırmayı Talep Et"
     visible={isModalVisibleRequest}
     onCancel={handleModalCancelReqest}
     footer={null}
   >
     <AlarmRequestRemoval  record={recordForModal}  onClose={handleRequestRemovalFinish} />
   </Modal>

   <Modal
            title="Alarmı Kaldırma"
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={null}
          >
            <AlarmRemove record={recordForModal}  onClose={handleRemoveFinish} />
          </Modal>
   </div>
</>

  )};
export default AlarmDetail;