import React, { useEffect, useState } from "react";
import { colors } from "../../../environments/environment";
import { Button, DatePicker, Select,} from 'antd';
import FirebaseService from "../../../services/firebaseService";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";


const MachineReportingList = () => {
  const [chart, setChart] = useState([]);
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(new Date());
  const [isVisible, setIsVisible] = useState(false);
  const [lazerTargetHours, setLazerTargetHours] = useState("400");
  const [abkantTargetHours, setAbkantTargetHours] = useState("180");
  
  useEffect(() => {
    const fetchData = async () => {
      const setupData = await FirebaseService.getSetupData();
      const filteredRecords = filterRecordsByDate(setupData);
      console.log("filteredRecords", filteredRecords);
      
      const result = processSetupTimes(filteredRecords);
      console.log("result:", result);
      
      const data = await FirebaseService.getMachinesData();
      console.log("data", data);
      
      const filteredData = data.filter(item => !item.isDeleted && item.machineType === "abkant");
      console.log("filteredData", filteredData);
      
      const updatedAbkant = result.abkant.map(record => {
        // abkant içindeki operatör ile eşleşen filteredData öğesini bul
        const matchingMachine = filteredData.find(item => item.operatorNameSurname === record.nameSurname);
        
        if (matchingMachine) {
          // Eşleşen öğeyi bulduğumuzda, machineName ve imageUrl'yi güncelle
          return {
            ...record,
            machineName: matchingMachine.machineName,
            imageUrl: matchingMachine.imageUrl || "default_image_url" // imageUrl varsa, yoksa default bir değer
          };
        }
      
        // Eğer eşleşen öğe bulunmazsa, mevcut veriyi olduğu gibi bırak
        return record;
      });
      
      const newMachine = {
        ...updatedAbkant[0], // İlk öğedeki süre ve diğer bilgileri al
        machineName: "DURMA ABKANT 30120 3000MM",
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/pars-58f51.appspot.com/o/machines%2FWhatsApp%20Image%202025-03-21%20at%209.51.49%20PM.jpeg?alt=media&token=0f04a03b-e7dd-41f4-8c6d-de0b74b0c20a" // İlgili görsel URL'sini buraya ekleyebilirsin
      };

       const updatedResult = {
        ...result,
        abkant: [...updatedAbkant, newMachine] // Yeni makineyi diziye ekle
      };
      
      console.log(  "updatedResult",updatedResult)
      // Son olarak, güncellenmiş result verisini setChart ile güncelle
      setChart(updatedResult);
      
    };
  
    fetchData();
  }, []); 
  
  const filterRecordsByDate = (records) => {
    return records.filter((item) => {
      const itemDate = new Date(item.firstTransactionDate); 
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
      if (start && end) {
        return itemDate >= start && itemDate <= end;
      } else if (start) {
        return itemDate >= start;
      } else if (end) {
        return itemDate <= end;
      }
      return true; 
    });
  };

  const timeToSeconds = (timeStr) => {
      const [hh, mm, ss] = timeStr.split(":").map(Number);
      return hh * 3600 + mm * 60 + ss;
  };

  const secondsToTime = (seconds) => {
      const hh = Math.floor(seconds / 3600);
      const mm = Math.floor((seconds % 3600) / 60);
      const ss = seconds % 60;
      return [hh, mm, ss].map((unit) => String(unit).padStart(2, "0")).join(":");
  };

  const processSetupTimes = (setupData) => {
      let setupTime5000 = 0;
      let setupTime10000 = 0;
      let actualTime5000 = 0;
      let actualTime10000 = 0;
    
      const groupedData = {};

      setupData.forEach((record) => {
        let setupTimeInSeconds = record.setupTime ? timeToSeconds(record.setupTime) : 0;
        const platesTotalTimeInSeconds = record.plates
          ? record.plates.reduce((totalPlate, plate) => {
              if (!plate.actualTime) return totalPlate; 
              
              const [hours, minutes, seconds] = plate.actualTime.split(":").map(Number);
              const plateTimeInSeconds = (hours * 3600) + (minutes * 60) + seconds;
              
              return totalPlate + plateTimeInSeconds;
            }, 0)
          : 0;
    
        if (record.machine === "5000") {
          setupTime5000 += setupTimeInSeconds;
          actualTime5000 += platesTotalTimeInSeconds;
        }
        if (record.machine === "10000") {
          setupTime10000 += setupTimeInSeconds;
          actualTime10000 += platesTotalTimeInSeconds;
        }

        if (Array.isArray(record.abkantActualTime)) {
          record.abkantActualTime.forEach(item => {
            const { nameSurname, actualTime } = item;
            if (nameSurname && actualTime) {
              const [hours, minutes, seconds] = actualTime.split(":").map(Number);
              const timeInSeconds = (hours * 3600) + (minutes * 60) + seconds;
      
              // nameSurname'a göre gruplama işlemi
              if (groupedData[nameSurname]) {
                groupedData[nameSurname] += timeInSeconds;
              } else {
                groupedData[nameSurname] = timeInSeconds;
              }
            }
          });
        }
      });
    
      const result = Object.keys(groupedData).map((name) => ({
        nameSurname: name,
        totalTime: secondsToTime(groupedData[name]), 
      }));

      return {
        setupTime5000: secondsToTime(setupTime5000),   
        actualTime5000: secondsToTime(actualTime5000), 
        setupTime10000: secondsToTime(setupTime10000),
        actualTime10000: secondsToTime(actualTime10000),
        abkant:result
      };
  };
    
  const setDateRange = (months) => {
    const end = new Date(); 
    const start = new Date();
    start.setMonth(end.getMonth() - months); 
    setStartDate(start.toISOString().split("T")[0]); 
    setEndDate(end.toISOString().split("T")[0]);
    const targetHours = calculateTargetHours(new Date(startDate), new Date(endDate),20);
    const targetHoursAbkant = calculateTargetHours(new Date(startDate), new Date(endDate),8);
    setLazerTargetHours(targetHours);
    setAbkantTargetHours(targetHoursAbkant)
  };


  const [selectedRange, setSelectedRange] = useState("");


  const handleChange = (event) => {
    const value = event.target.value; 
    setSelectedRange(value); 

    if (value === "") {
      setDateRange(null); 
      setStartDate(null);
      setEndDate(null);
      return; 
    }

    const numericValue = parseInt(value, 10);
    setSelectedRange(numericValue);
    setDateRange(numericValue);

    const endDate = new Date(); 
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - numericValue); 

    setStartDate(startDate);
    setEndDate(endDate);
    const targetHours = calculateTargetHours(new Date(startDate), new Date(endDate),20);
    const targetHoursAbkant = calculateTargetHours(new Date(startDate), new Date(endDate),8);
    setLazerTargetHours(targetHours);
    setAbkantTargetHours(targetHoursAbkant)
    console.log("Güncellenmiş Tarihler:", startDate, endDate);
};


  const handlePrint = () => {
    const printSection = document.getElementById("print-section");
  
    if (!printSection) {
      console.error("Print section not found!");
      return;
    }
  
    const printContents = printSection.innerHTML;
    const printWindow = window.open("", "_blank", "width=800,height=600");
  
    printWindow.document.write(`
      <html>
        <head>
          <title>Yazdır</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            .chart-item { margin: 20px; }
            h3 { color: ${colors.blue}; }
            /* Print için stil ayarları */
            @media print {
              .chart-item {
                width: 300px !important;
                margin: 10px !important;
              }
              .print-container {
                flex-direction: column;
                align-items: center;
              }
              @page {
                margin: 0.5cm;
              }
                .recharts-legend-wrapper{
                bottom:-60px !important;
              }

            }
          </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  };
 
  const toggleAllVisibility = () => {
    setIsVisible(prevState => !prevState); 
  };

  const calculateTargetHours = (startDate, endDate,workHours) => {
    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);

    let currentDate = new Date(startDate);
    let workDays = 0;

    while (currentDate <= endDate) {
        const dayOfWeek = currentDate.getDay(); 
        if (dayOfWeek !== 0 && dayOfWeek !== 6) { 
            workDays++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }

    console.log("Hafta İçi Günleri:", workDays);
    return workDays * workHours; 
};

//abkant için 8 ile çarp
//lazer için günlük 20 

  
  return (
    <div style={{ textAlign: "center", minHeight: "94vh", padding: "3%" }}  id="print-section">
     <div style={{display:"flex", alignItems:"center"}}>
        <h3 style={{ backgroundColor: "white", color: colors.blue, fontWeight: "700", border: "none", textAlign: "left" }}>
        Makine Raporlamaları	
          </h3>
          <Button
            shape="circle"
                  icon={isVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />} 
                  onClick={toggleAllVisibility}
                  style={{
                    backgroundColor: 'transparent',
                    border: '2px solid',
                    borderColor: colors.blue,
                    cursor: 'pointer',
                    marginLeft: '20px'
             }} 
           />
      </div>
      <div style={{display:"flex", float:"right"}}>
      <div style={{ marginBottom: "20px" , display:"block"}}>
        <label>Başlangıç Tarihi: </label>
        <DatePicker     placeholder="Başlangıç Tarihi"   className="input-style"  selected={startDate} onChange={(date) => setStartDate(date)} />
        <label style={{ marginLeft: "20px" }}>Bitiş Tarihi: </label>
        <DatePicker     placeholder="Bitiş Tarihi"   className="input-style"  selected={endDate} onChange={(date) => setEndDate(date)} />
      </div>
     
      <select value={selectedRange} onChange={handleChange}     className="input-style"   style={{ height:"35px", marginLeft:"50px", color:colors.blue,}}>
        <option value="">Tarih Aralığı Seç</option>
        <option value="1">Son 1 Ay</option>
        <option value="3">Son 3 Ay</option>
        <option value="6">Son 6 Ay</option>
        <option value="12">Son 1 Yıl</option>
      </select>

      </div>
      
      <div style={{backgroundColor: "white", color: colors.blue,marginTop:"80px",textAlign:"left" , fontWeight:"bold"}}>Lazer Makineleri</div>
      <div style={{ display: "flex", marginTop: "20px", justifyContent: "space-around", flexWrap: "wrap" }}>
        <div style={{ textAlign: "center", padding: "20px", border: "1px solid #ccc", borderRadius: "10px", backgroundColor: "#f5f5f5", margin: "10px",  }}>
          <h3 style={{ color: "#2196F3" }}>10kW</h3>
          <img alt="" src="/images/lazermachine.png"  style={{ width: '400px', height: "250px", objectFit: "contain" }} />
          <p>🎯 Hedeflenen Süre: {lazerTargetHours} Saat</p>
          <p> Girilen Setup Süresi: {isVisible ? chart.setupTime10000 : ""}</p>
          <p>✅ Gerçekleşen Süre:{isVisible ? chart.actualTime10000 :"" }</p>
        </div>
        
        <div style={{ textAlign: "center", padding: "20px", border: "1px solid #ccc", borderRadius: "10px", backgroundColor: "#f5f5f5", margin: "10px",}}>
          <h3 style={{ color: "#4CAF50" }}>5kW</h3>
          <img alt="" src="/images/lazermachine.png" style={{  width: '400px', height: "250px",objectFit: "contain" }} />
          <p>🎯 Hedeflenen Süre: {lazerTargetHours} Saat</p>
          <p> Girilen Setup Süresi: {isVisible ? chart.setupTime5000 : "" }</p>
          <p>✅ Gerçekleşen Süre:  {isVisible ? chart.actualTime5000 : "" }</p>
        </div>
      </div>

    <div style={{backgroundColor: "white", color: colors.blue,marginTop:"100px",textAlign:"left" , fontWeight:"bold"}}>Abkant Makineleri</div>
    <div style={{ display: "flex", marginTop: "20px", justifyContent: "space-around", flexWrap: "wrap" }}>
  {chart?.abkant?.length > 0 ? (
    chart.abkant.map((machine, index) => {
      const machineData = chart.abkant.find(item => item.nameSurname === machine.nameSurname);

      return (
        <div 
          key={index} 
          style={{
            textAlign: "center", 
            padding: "20px", 
            border: "1px solid #ccc", 
            borderRadius: "10px", 
            backgroundColor: "#f5f5f5", 
            margin: "10px", 
            marginTop: index >= 2 ? "110px" : "0"
          }}
        >
          <h3 style={{ color: "#2196F3" }}>{machine.machineName}</h3>
          <img 
            alt={machine.machineName} 
            src={machine.imageUrl} 
            style={{ width: '400px', height: "300px", objectFit: "contain" }} 
          />
          <p>🎯 Hedeflenen Süre: {abkantTargetHours} Saat</p>
          <p>✅ Gerçekleşen Süre: {machineData ? machineData.totalTime : "-"}</p>
        </div>
      );
    })
  ) : (
    <p>Yükleniyor...</p>
  )}
</div>


      <div style={{float:"right"}}>
       <Button  
         onClick={handlePrint} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",border:"none",marginLeft:"10px"}}>
         Yazdır
       </Button>
       </div>
    </div>
  );
};



export default MachineReportingList ;