import React,{useEffect,useState} from "react";
import { Table, Button,Modal, Space, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';
import { SearchOutlined } from "@ant-design/icons";

const Machine5List = () => {

  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);  // Filtrelenmiş öğeler için state
  const [updatedPriorities, setUpdatedPriorities] = useState({});
  const [isSortDisabled, setIsSortDisabled] = useState(false);




  useEffect(() => {
    window.scrollTo(0, 0);
  
    const fetchData = async () => {
      const productionData = await FirebaseService.getProductionData5000();
      const filteredData = productionData.filter(item => !item.isDeleted);
  
      const setupData = await FirebaseService.getSetupData();
  
      const updatedData = filteredData.map(item => {
        const jobCode = item.jobCode;
        const setupItem = setupData.find(setup => setup.jobCode === jobCode && setup.machine === "5000");

        console.log("setupItem", setupItem)
  
        // If a matching setupItem is found
        if (setupItem && Array.isArray(setupItem.plates)) {
          // Check if any plate has isFinish: false
          const hasUnfinishedPlate = setupItem.plates.some(plate => plate.isFinish === false);

          const filteredPlates = setupItem.plates.filter(plate => plate.status === "Kesilecek");

  
          // Include the item only if there is at least one unfinished plate
          if (hasUnfinishedPlate && filteredPlates.length > 0) {
            return {
              ...item,
              companyName: setupItem.companyName,
              description: setupItem.description,
              expectedWastages: setupItem.expectedWastages,
              firstTransactionDate: setupItem.firstTransactionDate,
              documentUrl: setupItem.documentUrl,
              firstUserId: setupItem.firstUserId,
              isDeleted: setupItem.isDeleted,
              isScrapDelivery: setupItem.isScrapDelivery,
              key: setupItem.key,
              machine: setupItem.machine,
              parts: setupItem.parts,
              plateCount: setupItem.plateCount,
              plates: setupItem.plates,
              priorityAbkant: setupItem.priorityAbkant,
              priorityMachine: setupItem.priorityMachine,
              projectCode: setupItem.projectCode,
              quality: setupItem.quality,
              setupName: setupItem.setupName,
              setupTime: setupItem.setupTime,
              stock: setupItem.stock,
              surface: setupItem.surface,
              thickness: setupItem.thickness,
              wastage: setupItem.wastage,
            };
          }
        }
        return null;
      }).filter(Boolean);

      const groupedItems = updatedData.reduce((acc, item) => {
        if (!acc[item.jobCode]) {
          acc[item.jobCode] = {
            ...item,
            items: [item], // Aynı iş koduna sahip öğeleri bir arada tutuyoruz
          };
        } else {
          acc[item.jobCode].items.push(item);
        }
        return acc;
      }, {});
  
      // Gruplamadan sonra yalnızca gruptaki ilk öğeyi kullanarak veriyi ayarlıyoruz
      const finalItems = Object.values(groupedItems).map(group => ({
        ...group,
        items: group.items.length > 1 ? group.items : [group.items[0]],
      }));
  
  
      // Sort items by priorityMachine
      const sortedItems = finalItems.sort((a, b) => a.priorityMachine - b.priorityMachine);

      setItems(sortedItems);
      setFilteredItems(sortedItems)

      console.log("finali items",finalItems)

    };
  
    fetchData();
  }, []);
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase();
      const itemValue = item[dataIndex]?.toString().toLowerCase();
      return (
        !item.isDeleted &&
        (value ? itemValue.includes(value) : true)
      );
    });
    setFilteredItems(filteredData);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);  // Filtreyi sıfırladığında tüm öğeleri geri getiriyoruz
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
    sorter: (a, b) => a[dataIndex].localeCompare(b[dataIndex]),
  });

  const validatePriorities = (updatedPriorities) => {
    const allPriorities = items.map(item => {
      const priority = updatedPriorities[item.key] ?? item.priorityMachine;
      return priority === 999999999 ? null : priority; // 999999999 değerlerini görmezden geliyoruz
    }).filter(val => val !== null);
    const maxPriority = items.length;
    const hasDuplicates = new Set(allPriorities).size !== allPriorities.length;
    const hasInvalidValues = allPriorities.some(val => val <= 0);
    const hasExceedingValues = allPriorities.some(val => val > maxPriority);
    if (hasInvalidValues) {
      message.error("Sıralama değerleri 0'dan büyük olmalıdır.");
    } else if (hasExceedingValues) {
      message.error(`Sıralama değeri ${maxPriority}den büyük olamaz.`);
    } else if (hasDuplicates) {
      message.error("Sıralama değerleri çakışamaz. Her öğe farklı bir sırada olmalıdır.");
    }
    setIsSortDisabled(hasDuplicates || hasInvalidValues || hasExceedingValues);
  };
  const handlePriorityChange = (key, value) => {
    const numericValue = parseInt(value) || 0;
    const updated = {
      ...updatedPriorities,
      [key]: numericValue,
    };
    setUpdatedPriorities(updated);
    if (numericValue !== 999999999) {
      validatePriorities(updated);
    }
  };
  const handleSort = async () => {
    const sortedItems = [...items].sort((a, b) => {
      const aPriority = updatedPriorities[a.key] ?? a.priorityMachine;
      const bPriority = updatedPriorities[b.key] ?? b.priorityMachine;
      const isAPriorityMax = aPriority === 999999999;
      const isBPriorityMax = bPriority === 999999999;
      // 999999999 değerine sahip öğeleri sona at
      if (isAPriorityMax && !isBPriorityMax) {
        return 1;
      } else if (!isAPriorityMax && isBPriorityMax) {
        return -1;
      } else {
        return aPriority - bPriority;
      }
    });
    setItems(sortedItems);
    const updatedDocuments = sortedItems.map(item => ({
      id: item.key,
      priorityMachine: updatedPriorities[item.key] ?? item.priorityMachine,
    }));
    try {
      const result = await FirebaseService.updateSetupTable(updatedDocuments);
      NotificationService.openSuccessNotification({
        title: 'Başarıyla Güncellendi',
        description: result.message,
      });
      window.location.reload();
    } catch (error) {
      console.error('Güncelleme hatası:', error);
      NotificationService.openErrorNotification({
        title: 'Güncelleme Hatası',
        description: 'Güncelleme sırasında bir hata oluştu.',
      });
    }
  };
  
  const columns = [
    {
      title: 'Sırası',
      dataIndex: 'priorityMachine',
      key: 'priorityMachine',
      render: (text, record) => (
        <Input
          type="number"
          defaultValue={text}
          onChange={e => handlePriorityChange(record.key, e.target.value)}
          style={{ width: "60px" }}
        />
      ),
    },
    
    {
      title:"Firma",
      dataIndex: 'companyName',
      key: 'companyName',
      ...getColumnSearchProps("companyName"),
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text} </span>,
    },
  {
    title: 'Proje Kodu',
    dataIndex: 'projectCode',
    key: 'projectCode',
    ...getColumnSearchProps("projectCode"),
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"} </span>,
  },
  {
    title:"İş Kodu",
    dataIndex: 'jobCode',
    key: 'jobCode',
    ...getColumnSearchProps("jobCode"),
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text} </span>,
},
{
  title: 'Dakika',
  dataIndex: 'setupTime',
  key: 'setupTime',
  ...getColumnSearchProps("setupTime"),
  render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text} </span>,
},
{
  title: 'Durum',  // Yeni Durum column'ı
  dataIndex: 'status',
  key: 'status',
  ...getColumnSearchProps("status"),
  render: (text, record) => {
    console.log("record durumdaki" ,record)
    const isQrScanTrueLength = Array.isArray(record.isQrScanTrue) ? record.isQrScanTrue.length : 0;
    const plateCount = record.plateCount ;

    const status = isQrScanTrueLength === plateCount ? "Depodan Çıktı" : "Depoda";
    return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{status}</span>;
  },
},
/*
{
  title: '   ',
  dataIndex: '',
  key: '',
  render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> 
  <img width={20} height={20} alt="logo" src="/images/sort.svg" />
</span>,
},
*/
  ];

  return  <div style={{
    justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
      }}>
        <h3  style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>Lazer 5KW</h3>

        <Button
     type="primary"
     style={{
        backgroundColor: isSortDisabled ? "grey" : '#1A446C',
        borderColor: isSortDisabled ? "grey" : '#1A446C',
        color: 'white',
        fontWeight: '500',
        marginBottom: '5px', // Tablo ile arada boşluk
        float: "right",
        cursor: isSortDisabled ? "not-allowed" : "pointer"
       }}
    disabled={isSortDisabled}
    onClick={() => {
      if (isSortDisabled) {
        message.error("Hatalı veya çakışan değerler var, sıralama yapılamaz.");
      } else {
        handleSort();
      }
  }}
>
  Sıralama Değiştir
</Button>


          <Table
            locale={{
              emptyText: 'Henüz 5kw lazer bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table drag-row"
            rowClassName="custom-news-row"
           
            rowKey="id"
       
        />



       
    </div>

  };
  export default Machine5List;
