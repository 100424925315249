import React,{useEffect,useState} from "react";
import { Button, Form, Input,Table ,Modal,Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import  {colors}  from "../../../environments/environment"


const InvoicePurchaseDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const recordComing = location.state && location.state.record;
     console.log("record futureraw",recordComing);
     const [loading, setLoading] = useState(false);
   
     const [dataSource, setDataSource] = useState(recordComing.data);

  useEffect(() => {
    const fetchData = async () => {
    
      
    };
  
    fetchData();
  }, [recordComing.requestedProductInfo]);
  
  const handleInvoicePriceChange = (index, value) => {
    // Sadece sayıları kontrol eden bir regex kullanıyoruz
    if (/^\d*\.?\d*$/.test(value)) { // Sayısal değer ve ondalık sayılar geçerli
      const newData = [...dataSource];
      newData[index].invoicePrice = value;
      setDataSource(newData);
    } else {
      // Uyarı mesajı göster
      NotificationService.openErrorNotification({
        title: 'Geçersiz Giriş',
        description: 'Fatura fiyatı sadece rakam olmalıdır!',
        placement: 'topRight',
      });
    }
  };
  

  const handleSave = async () => {
    setLoading(true)
    console.log("dataSource", dataSource);
    try {
      const updatePromises = dataSource.map(item => {
        if (item.invoicePrice !== undefined && item.key) {
          return FirebaseService.updateInvoicePrice(item.key, item.invoicePrice);
        }
        return Promise.resolve(); 
      });
  
      const results = await Promise.all(updatePromises);
  
      const failedUpdates = results.filter(result => !result.success);
  
      if (failedUpdates.length > 0) {
        throw new Error('Bazı fiyatlar kaydedilemedi.');
      }
  
      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: 'Fiyatlar başarıyla kaydedildi.',
        placement: 'topRight'
      });
      setLoading(false)
      setTimeout(() => {
        navigate("/invoicepurchaselist");
      }, 3000);
  
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: `Fiyatlar kaydedilemedi. Lütfen tekrar deneyiniz. Hata: ${error.message}`,
        placement: 'topRight'
      });setLoading(false)
    }
  };
  
  const columnsRaw = [

      {
        title: 'Ürün Adı',
        dataIndex: '',
        key: '',
        
        render: (text,record) => <span style={{ color: record.isDeleted ? '#84BB4C' : '#000000' }}>{text.quality} - {text.surface}- {text.thickness}mm {text.width}x{text.height}</span>,
      },
     
      {
        title: 'İş Kodu',
        dataIndex: 'jobCode',
        key: 'jobCode',
        render: (text,record) => <span style={{color: record.isDeleted ? "#84BB4C" : "#000000"}}>{text ? text : "-"}</span>,
     
      },
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text,record) => <span style={{color: record.isDeleted ? "#84BB4C" : "#000000"}}>{text}</span>,
        
      },
      
      {
        title: 'Fiyat',
        dataIndex: 'price',
        key: 'price',
        render: (text,record) => <span style={{color: record.isDeleted ? "#84BB4C" : "#000000"}}>{text}</span>,
       
      },
      {
        title: 'Faturada Yazan Fiyat',
        dataIndex: 'invoicePrice',
        key: 'invoicePrice',
        render: (text, record, index) => (
          <Input 
            value={text}
            onChange={e => handleInvoicePriceChange(index, e.target.value)}
            style={{ color: record.isDeleted ? "#84BB4C" : "#000000" }}
          />
        ),
      },
    
        
           
           
    
    ];

   
   return ( 
    <div style={{ display: "flex", flexDirection: "column",padding:"10px 100px"}}>
      <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}> Satın Alma Faturası Detay</h2> 
        <Table
          style={{ margin:"auto"}}
            locale={{
              emptyText: 'Henüz bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
           dataSource={recordComing.data}
           columns={columnsRaw}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
        <div style={{textAlign:"right"}}>
          <Button 
            style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}
            onClick={handleSave} >
            Kaydet
          </Button>
        </div>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>


  )};
export default InvoicePurchaseDetail;