import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input,Select,Form,Modal,message, InputNumber } from 'antd';
import { useNavigate } from 'react-router-dom';
import  { colors}  from "../../../../environments/environment"
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import OperationDetail from "./operationDetail";
import NotificationService from "../../../../services/antNotificationService";

const { Option } = Select;

const OfferDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const record = location.state && location.state.record;
  const [visible, setVisible] = useState(false);
  const [jobCodes, setJobCodes] = useState([]); 
  

  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]); 
  const [uniqueOperations, setUniqueOperations] = useState([]); 
  const [abkant, setAbkant] = useState([]); 

  const [qualityWeights, setQualityWeights] = useState([]);
  const [plazmaWeights, setPlazmaWeights] = useState([]);
  const [abkantWeight, setAbkantWeights] = useState([]);


  const [machineTimes, setMachineTimes] = useState([]);
  const [selectedJobCode, setSelectedJobCode] = useState('');

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recordForModal, setRecordForModal] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [selectedTeamCount, setSelectedTeamCount] = useState(1);
  const [description, setDescription] = useState(null);
  const [deliveryMethod, setDeliveryMethod] = useState("Fabrika");
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [unit, setUnit] = useState("Kg");
  const [editableTimes, setEditableTimes] = useState({});
  const [abkantTotalNew, setAbkantTotalNew] = useState(0);

  const [companyInfo, setCompanyInfo] = useState(null);

  //const [updatedSetupTimes , setUpdatedSetupTimes] = useState(record.setupTime)

 
  //const [form] = Form.useForm();

    
  useEffect(() => {

    const initialTimes = {};
    Object.entries(machineTimes).forEach(([machine, data]) => {
      Object.entries(data.fuels).forEach(([fuelType, time]) => {
        initialTimes[`${machine}-${fuelType}`] = time;
      });
    });
    setEditableTimes(initialTimes);

    const fetchData = async () => {
        const paymentMethods = await FirebaseService.getPaymentMethodsData();
        const filteredpaymentMethods = paymentMethods.filter(item => !item.isDeleted);
        setPaymentMethods(filteredpaymentMethods);
        const jobCodes = record.items
        .filter(item => item.jobCode) 
        .map(item => ({ label: item.jobCode, value: item.jobCode })); 

        setJobCodes(jobCodes);


        record.items.forEach(item => {
          if (Array.isArray(item.parts)) {
            item.parts.forEach(part => {
              if (Array.isArray(part.operations)) {
                part.operations.forEach(operation => {
                  if (!uniqueOperations.includes(operation)) {
                    uniqueOperations.push(operation);
                  }
                });
              }
            });
          }
        });
        //sadece abkant olcak.....
        setAbkant(["Abkant"]);
        setUniqueOperations(uniqueOperations);
        
        const qualityWeights = {}; 
        record.items.forEach(item => {
          const quality = item.quality;
          const weight = item.totalKg; 
    
          if (!qualityWeights[quality]) { 
            qualityWeights[quality] = weight; 
          } else {
            qualityWeights[quality] += weight; 
          }
        });
          
        for (const quality in qualityWeights) {
          qualityWeights[quality] = Number(qualityWeights[quality].toFixed(2));
      }
        setQualityWeights(qualityWeights);
        
      const machineData = {}; 

      record.items.forEach(item => {
          const machine = item.machine;
          const setupTime = item.setupTime;
          const fuel = item.fuel;

          if (!machineData[machine]) {
            machineData[machine] = {
              fuels: {} 
            };
          }
            if (!machineData[machine].fuels[fuel]) {
              machineData[machine].fuels[fuel] = setupTime;
            } else {
              machineData[machine].fuels[fuel] = addTimes(machineData[machine].fuels[fuel], setupTime);
            }  
      });

      setMachineTimes(machineData);

           
      const plazmaWeights = {}; 
      record.items.forEach(item => {
          if (item.isPlazma) { // Sadece item.isPlazma true olduğunda işlemleri yapar
            const weight = item.totalKg;
        
            if (!plazmaWeights[item.isPlazma]) { 
              plazmaWeights[item.isPlazma] = weight; 
            } else {
              plazmaWeights[item.isPlazma] += weight; 
            }
          }
        });
        
      setPlazmaWeights(plazmaWeights);
        

      const totalAbkantWeight = record.parts.reduce((total, part) => {
        if (part.operations && Array.isArray(part.operations) &&  part.operations.some(operation => operation.toLowerCase() === 'abkant')) {
          total += (part.weightSetup || 0) * part.piece ;
        }
        return total; 
      }, 0); 
      setAbkantWeights(totalAbkantWeight)


  
      const totalKgSetup = {};
      for (let i = 0; i < record.parts.length; i++) {
        const part = record.parts[i];
        const quality = part.quality;
        const weight = part.weightSetup;
        const piece = part.piece || 1; 
      
        if (weight && quality) {
          if (!totalKgSetup[quality]) {
            totalKgSetup[quality] = 0;
          }
          totalKgSetup[quality] += weight * piece;
        }
      }
     Object.keys(totalKgSetup).forEach(quality => {

      const weightDifference = record.totalKg[quality] - totalKgSetup[quality];
      if (weightDifference > 0) {
        const partsToUpdateWeight = record.parts.filter(part => part.quality === quality && part.weightSetup > 0); 
        partsToUpdateWeight.forEach(part => {
          const weightRatio = part.weightSetup / totalKgSetup[quality]; 
          part.newWeight = part.weightSetup + weightDifference * weightRatio;    
        });
      }
        });
    
    const totalSetupTimeByMachineAndFuel = record.items.reduce((acc, item) => {
      const machine = item.machine;
      const fuel = item.fuel;

      item.parts.forEach(part => {
        const key = `${machine}-${fuel}`;
        if (!acc[key]) {
          acc[key] = "00:00:00"; 
        }
        const totalPartTime = multiplyTime(part.time, part.piece); 
        acc[key] = addTimes(acc[key], totalPartTime);
      });
      return acc;
    }, {});
    
    const setupTimeKeys = Object.keys(totalSetupTimeByMachineAndFuel);
    setupTimeKeys.forEach(key => {
      if (record.setupTime[key]) {
        const machineKey = key.split("-")[0]; // Örneğin: 5000
        const fuelKey = key.split("-")[1];    // Örneğin: oksijen
    
        if (machineData[machineKey] && machineData[machineKey].fuels[fuelKey]) {
          const machineFuelTime = machineData[machineKey].fuels[fuelKey];
    
          const desiredSetupTimeSeconds = parseTime(machineFuelTime).hours * 3600 +
                                          parseTime(machineFuelTime).minutes * 60 +
                                          parseTime(machineFuelTime).seconds;    
          let totalPartTime = 0;
          record.parts.forEach(part => {
            if (part.machine === machineKey && part.fuel === fuelKey) {
              totalPartTime += (parseTime(part.time).hours * 3600 +
                                parseTime(part.time).minutes * 60 +
                                parseTime(part.time).seconds) * part.piece;
            }
          });
        
          // Eğer parça süresi varsa
          if (totalPartTime > 0) {
            const scalingFactor = desiredSetupTimeSeconds / totalPartTime;
    
            record.parts.forEach(part => {
              if (part.machine === machineKey && part.fuel === fuelKey) {
                const partTimeSeconds = (parseTime(part.time).hours * 3600 +
                                         parseTime(part.time).minutes * 60 +
                                         parseTime(part.time).seconds) * part.piece;
    
                const scaledPartTime = partTimeSeconds * scalingFactor;
                const totalScaledTimeForPart = scaledPartTime / part.piece;
    
                const formattedNewTime = formatTime2(totalScaledTimeForPart);
                part.newTime = formattedNewTime;
              }
            });
          }
        } else {
          console.error(`Machine time for ${machineKey} and fuel ${fuelKey} not found.`);
        }
      }
    });
  

    const allCompanies = await FirebaseService.getCompaniesData();
    const companyInfo = allCompanies.find((entry) => 
      entry.companyName?.toLowerCase() === record.companyName?.toLowerCase()
    );
    
    if (!companyInfo) {
      NotificationService.openErrorNotification({
        title: 'Şirket Bulunamadı',
        description: `${record.companyName} için herhangi bir şirket bilgisi bulunamadı.`,
        placement: 'topRight',
      });
      return; 
    }
    
    setCompanyInfo(companyInfo);
    setPaymentMethod(companyInfo.paymentMethod);


    };
  
    fetchData();
  }, []);

  
/*
  useEffect(() => {
    const savedData = sessionStorage.getItem("offerDetailData");
    if (savedData) {
      const previousValues = JSON.parse(savedData);
      console.log("previous values", previousValues);
      
      form.setFieldsValue({
          paymentMethod: previousValues.paymentMethod || "",
          teamCount: previousValues.teamCount || 1,
          deliveryMethod: previousValues.deliveryMethod || "Fabrika",
          deliveryPrice: previousValues.deliveryPrice || 0,
          unit: previousValues.unit || "Kg",
          description: previousValues.description || "",
          abkantWeight: previousValues.abkantWeight || 0,
          abkantSelectedOperation : previousValues.abkantSelectedOperation || ""
        });
        setTotalPricesQuality(previousValues.totalPricesQuality || {});
        console.log("Total Prices Quality:", previousValues.totalPricesQuality);

        setPricesQuality(previousValues.pricesQuality || {});
        console.log("Prices Quality:", previousValues.pricesQuality);

        setTotalPricesTime(previousValues.totalPricesTime || {});
        console.log("Total Prices Time:", previousValues.totalPricesTime);

        setPricesTime(previousValues.pricesTime || {});
        console.log("Prices Time:", previousValues.pricesTime);

        setTotalPricesAbkant(previousValues.totalPricesAbkant || {});
        console.log("Total Prices Abkant:", previousValues.totalPricesAbkant);

        setPricesAbkant(previousValues.pricesAbkant || {});
        console.log("Prices Abkant:", previousValues.pricesAbkant);

        setQuantitiesAbkant(previousValues.quantitiesAbkant || {});
        console.log("Quantities Abkant:", previousValues.quantitiesAbkant);

        setTotalPricesPlazma(previousValues.totalPricesPlazma || {});
        console.log("Total Prices Plazma:", previousValues.totalPricesPlazma);

        setPricesPlazma(previousValues.pricesPlazma || {});
        console.log("Prices Plazma:", previousValues.pricesPlazma);

        setUpdatedSetupTimes(previousValues.setupTime || {})
        console.log("Setup Time" , previousValues.setupTime)

      }
    
  }, [form]);

*/  

/*
  const validateTimeFormat = (timeString) => {
    if (timeString === '') return true;
    const regex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    return regex.test(timeString);
  };
*/

  const validateTimeFormat = (timeString) => {
    if (timeString === '') return true; 
    const regex = /^([0-9]+):[0-5][0-9]:[0-5][0-9]$/; 
    return regex.test(timeString);
  };

  const normalizeTime = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;

    const days = Math.floor(totalSeconds / (24 * 3600));
    const remainingSecondsAfterDays = totalSeconds % (24 * 3600);

    const normalizedHours = Math.floor(remainingSecondsAfterDays / 3600);
    const normalizedMinutes = Math.floor((remainingSecondsAfterDays % 3600) / 60);
    const normalizedSeconds = remainingSecondsAfterDays % 60;

    return `${days > 0 ? days + ' gün ' : ''}${normalizedHours}:${String(normalizedMinutes).padStart(2, '0')}:${String(normalizedSeconds).padStart(2, '0')}`;
  };

  const handleMachineTimeChange = (machine, fuelType, value) => {
    const key = `${machine}-${fuelType}`;
    setEditableTimes(prev => ({
      ...prev,
      [key]: value
    }));

  
    if (validateTimeFormat(value)) {
      const newMachineTimes = JSON.parse(JSON.stringify(machineTimes));
      newMachineTimes[machine].fuels[fuelType] = value;
  
      // Toplam setup süresini yeniden hesapla
      const newTotalSetupTimeByMachineAndFuel = record.items.reduce((acc, item) => {
        const machine = item.machine;
        const fuel = item.fuel;
  
        item.parts.forEach(part => {
          const key = `${machine}-${fuel}`;
          if (!acc[key]) {
            acc[key] = "00:00:00"; 
          }
          const totalPartTime = multiplyTime(part.time, part.piece); 
          acc[key] = addTimes(acc[key], totalPartTime);
        });
  
        // Elle girilen süreyi güncelle
        if (key === `${machine}-${fuelType}`) {
          acc[key] = value; // Yeni girilen süreyi kaydet
        }
  
        return acc;
      }, {});

      setMachineTimes(newMachineTimes);
  
      
      const setupTimeKeys = Object.keys(newTotalSetupTimeByMachineAndFuel);
      setupTimeKeys.forEach(key => {
        if (record.setupTime[key]) {
          const desiredSetupTimeSeconds = parseTime(newTotalSetupTimeByMachineAndFuel[key]).hours * 3600 +
                                          parseTime(newTotalSetupTimeByMachineAndFuel[key]).minutes * 60 +
                                          parseTime(newTotalSetupTimeByMachineAndFuel[key]).seconds;

          let totalPartTime = 0;
          record.parts.forEach(part => {
            if (part.machine === key.split("-")[0] && part.fuel === key.split("-")[1]) {
              totalPartTime += (parseTime(part.time).hours * 3600 + parseTime(part.time).minutes * 60 + parseTime(part.time).seconds) * part.piece;
            }
          });

          if (totalPartTime > 0) {
            const scalingFactor = desiredSetupTimeSeconds / totalPartTime;
            record.parts.forEach(part => {
              if (part.machine === key.split("-")[0] && part.fuel === key.split("-")[1]) {
                const partTimeSeconds = (parseTime(part.time).hours * 3600 + parseTime(part.time).minutes * 60 + parseTime(part.time).seconds)* part.piece;
                const scaledPartTime = partTimeSeconds * scalingFactor;
                const totalScaledTimeForPart = scaledPartTime / part.piece;
                const formattedNewTime = formatTime2(totalScaledTimeForPart);
                part.newTime = formattedNewTime;
              }
            });

         
            const totalMinutes = timeToMinutes(newTotalSetupTimeByMachineAndFuel[key]); 
            const price = pricesTime[key] || 0; 
            const total = price * totalMinutes;
            setTotalPricesTime(prevTotalPrices => ({
              ...prevTotalPrices,
              [key]: total,
            }));
          }
        }
      });
  
    }
  };

  
  const handleButtonClick = () => {
    let allValid = true;
    Object.entries(editableTimes).forEach(([key, value]) => {
      if (validateTimeFormat(value)) {
        const normalizedTime = normalizeTime(value);
      } else {
        allValid = false;
        message.error(`Geçersiz zaman formatı: ${value}`);
      }
    });
  
    if (allValid) {

      // Proceed to the next page or perform other actions
      goUnitPricePage();
    }
  };

  function formatTime2(totalSeconds) {
    totalSeconds = Math.round(totalSeconds); // Kesirli saniyeleri tam sayıya yuvarlama

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  }

  function subtractTimes(time1, time2) {
    const t1 = parseTime(time1);
    const t2 = parseTime(time2);

    const totalSeconds1 = t1.hours * 3600 + t1.minutes * 60 + t1.seconds;
    const totalSeconds2 = t2.hours * 3600 + t2.minutes * 60 + t2.seconds;

    const differenceSeconds = Math.abs(totalSeconds1 - totalSeconds2);

    return formatTime(differenceSeconds);
}

  function multiplyTime(timeString, multiplier) {
    const { hours, minutes, seconds } = parseTime(timeString);
    const totalSeconds = (hours * 3600 + minutes * 60 + seconds) * multiplier;
    return formatTime(totalSeconds);
  }
  
  function parseTime(timeStr) {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return { hours, minutes, seconds };
  }
  
  function formatTime(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }
  
  function addTimes(time1, time2) {
    const t1 = parseTime(time1);
    const t2 = parseTime(time2);
    
    const totalSeconds1 = t1.hours * 3600 + t1.minutes * 60 + t1.seconds;
    const totalSeconds2 = t2.hours * 3600 + t2.minutes * 60 + t2.seconds;
    
    const totalSeconds = totalSeconds1 + totalSeconds2;
    return formatTime(totalSeconds);
  }

  const timeToMinutes = (timeString) => {
    if (typeof timeString !== 'string') {
      if (timeString.setupTime && typeof timeString.setupTime === 'string') {
        timeString = timeString.setupTime; 
      } else {
        console.error('Invalid timeString object:', timeString);
        return 0; 
      }
    }
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 60 + minutes + seconds / 60;
  };
   
  const onChange = (value) => {
    setSelectedJobCode(value);
    setPaymentMethod(value)
     if (value === 'newPaymentMethod') {
      console.log('Yeni ödeme vaadesi Ekle seçildi');
     // setIsModalVisibleCompany(true);
    }
  };


//kalite için 
  const [pricesQuality, setPricesQuality] = useState({});
  const [totalPricesQuality, setTotalPricesQuality] = useState({});

  const handlePriceChangeQuality = (quality, e) => {
    const inputValue = e.target.value;
  
    if (/^[0-9]*\.?[0-9]*$/.test(inputValue)) {
      const value = inputValue === "" ? "" : inputValue;
  
      setPricesQuality(prevPrices => ({
        ...prevPrices,
        [quality]: value, 
      }));
  
      if (!isNaN(parseFloat(value))) {
        const weight = qualityWeights[quality]; 
        const total = parseFloat(value) * weight; 
        setTotalPricesQuality(prevTotalPrices => ({
          ...prevTotalPrices,
          [quality]: total,
        }));
      } else {
        setTotalPricesQuality(prevTotalPrices => ({
          ...prevTotalPrices,
          [quality]: 0,
        }));
      }
    }
  };
  
//makine saat için
  const [pricesTime, setPricesTime] = useState({});
  const [totalPricesTime, setTotalPricesTime] = useState({});

  const handlePriceChangeTime = (key, e) => {

    const value = parseFloat(e.target.value) || 0;
    setPricesTime(prevPrices => ({
      ...prevPrices,
      [key]: value,
    }));
  
    const [machine, fuelType] = key.split('-'); 
  
    const machineData = machineTimes[machine];
    if (!machineData || typeof machineData !== 'object') {
      console.error('Invalid machine data:', machineData);
      return;
    }
  
    const setupTime = machineData.fuels[fuelType]; 
    if (!setupTime || typeof setupTime !== 'string') {
      console.error('Invalid setupTime:', setupTime);
      return;
    }
  
    const totalMinutes = timeToMinutes(setupTime); 
    const total = value * totalMinutes; 
    setTotalPricesTime(prevTotalPrices => ({
      ...prevTotalPrices,
      [key]: total,
    }));
  };
  
//plazma için
  const [pricesPlazma, setPricesPlazma] = useState({});
  const [totalPricesPlazma, setTotalPricesPlazma] = useState({});

  const handlePriceChangePlazma = (quality, e) => {
  
    const value = parseFloat(e.target.value) || 0;
    setPricesPlazma(prevPrices => ({
      ...prevPrices,
      [quality]: value,
    }));
  
    const weight = plazmaWeights[quality]; 
    const total = value * weight; 
    setTotalPricesPlazma(prevTotalPrices => ({
      ...prevTotalPrices,
      [quality]: total,
    }));
  };
  
 
  //abkant için 
  const [pricesAbkant, setPricesAbkant] = useState({});
  const [quantitiesAbkant, setQuantitiesAbkant] = useState({});
  const [totalPricesAbkant, setTotalPricesAbkant] = useState({});


  const handlePriceChangeAbkant = (operation, e) => {
    const value = parseFloat(e.target.value) || 0;

    setPricesAbkant(prevPrices => ({
      ...prevPrices,
      [operation]: value,
    }));
    
    
    if (selectedOperation[operation] !== 'Kg') {
      setTotalPricesAbkant(prevTotalPrices => ({
        ...prevTotalPrices,
        [operation]: 0,
      }));
    }

    let total = 0;
  
    if(selectedOperation[operation] === 'Kg'){
       total = value * (abkantWeight || 0);
    }
    else{
       total = value * (quantitiesAbkant[operation] || 0);
    }
  
    setAbkantTotalNew(total);
    setTotalPricesAbkant(prevTotalPrices => ({
      ...prevTotalPrices,
      [operation]: total,
    }));
  };
  
  const handleQuantityChangeAbkant = (operation, e) => {
    const value = parseFloat(e.target.value) || 0;
    setQuantitiesAbkant(prevQuantities => ({
      ...prevQuantities,
      [operation]: value,
    }));
  
    if (selectedOperation[operation] === 'Kg') {
      setTotalPricesAbkant(prevTotalPrices => ({
        ...prevTotalPrices,
        [operation]: 0,
      }));
      return;
    }
    
  
    const total = value * (pricesAbkant[operation] || 0);

    setAbkantTotalNew(total);
    setTotalPricesAbkant(prevTotalPrices => ({
      ...prevTotalPrices,
      [operation]: total,
    }));
  };
  
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  const handleSetupFinish = () => {
    setIsModalVisible(false); 
  };
  



  const handleOk = async () => {
 
   const selectedItem = record.items.find(item => item.jobCode === selectedJobCode);

   if(selectedItem === undefined ){
    console.log( "buraya geldi")
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: "Lütfen setup seçiniz",
      placement: 'topRight'
    });
    return;

  }

  
    setIsModalVisible(true); 
    setRecordForModal(selectedItem);

   };
  
  const handleCancel = () => {
    setVisible(false);
  };

  const goUnitPricePage = () => {
    if (Object.keys(pricesQuality).length === 0) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Kalite fiyat giriniz.",
        placement: 'topRight'
      });
      return;
    }
    if (Object.keys(pricesTime).length === 0) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Makine fiyat giriniz.",
        placement: 'topRight'
      });
      return;
    }
    //plazma varsa fiyat girmeli
    if(Object.keys(plazmaWeights).length > 0){
      if (Object.keys(pricesPlazma).length === 0) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Plazma fiyat giriniz.",
          placement: 'topRight'
        });
        return;
      }
    }

    const hasPriorityAbkant = record.items.some(item => item.priorityAbkant != null );

    if (hasPriorityAbkant) {

      if (Object.keys(pricesAbkant).length === 0) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Abkant fiyat giriniz.",
          placement: 'topRight'
        });
        return;
      }
      
      if(selectedOperation === null){
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Abkant için dk/kg/adet seçip, bilgileri doldurunuz.",
          placement: 'topRight'
        });
        return;
      }
      
    }

    if(paymentMethod === null){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Ödeme Vaadesini Giriniz.",
        placement: 'topRight'
      });
      return;
    }

    if(deliveryMethod === null){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Teslim Şekli Giriniz.",
        placement: 'topRight'
      });
      return;
    }

    if(unit === null){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Birim Giriniz.",
        placement: 'topRight'
      });
      return;
    }

    const updatedRecord = {
      ...record,
    
      totalPricesQuality: totalPricesQuality || 0,
      pricesQuality: pricesQuality || {},
    
      totalPricesTime: totalPricesTime || 0,
      pricesTime: pricesTime || {},
    
      totalPricesAbkant: totalPricesAbkant || 0,
      pricesAbkant: pricesAbkant || {},
      quantitiesAbkant: quantitiesAbkant || 0,
      abkantSelectedOperation: selectedOperation && selectedOperation[abkant] ? selectedOperation[abkant] : null,
    
      totalPricesPlazma: totalPricesPlazma || 0,
      pricesPlazma: pricesPlazma || {},

    
      paymentMethod: paymentMethod || '',
      description: description || '',
      deliveryMethod: deliveryMethod || '',
      deliveryPrice : deliveryPrice || 0,
      abkantTotalNew : abkantTotalNew || 0,

      teamCount: selectedTeamCount || 1,
      unit: unit || 'Kg',

      abkantWeight : abkantWeight  || 0,
    };

    
    
    
    navigate('/offeroperationprice', { state: { updatedRecord } });
    
  };
  
  
  const handleTeamCountChange = (e) => {
    setSelectedTeamCount(e.target.value);
  };
  
  
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };
  
  const handleDeliveryMethod = (value) => {
    setDeliveryMethod(value);
};


    const handleDeliveryPrice = (value) => {
      setDeliveryPrice(value);
  };
  const handleUnit = (e) => {
    setUnit(e.target.value);
  };

const totalAbkant = Object.values(totalPricesAbkant).reduce((acc, price) => acc + (parseFloat(price) || 0), 0);
const totalQuality = Object.values(totalPricesQuality).reduce((acc, price) => acc + (parseFloat(price) || 0), 0);
const totalTime = Object.values(totalPricesTime).reduce((acc, price) => acc + (parseFloat(price) || 0), 0);
const totalSum = totalAbkant + totalQuality + totalTime; 




const handleWeightChangeQuality = (quality, e) => {
  const inputValue = e.target.value;
  if (/^[0-9]*\.?[0-9]*$/.test(inputValue)) {
    const value = inputValue === "" ? "" : parseFloat(inputValue);
    const updatedWeights = { ...qualityWeights };
    updatedWeights[quality] = value; // Ağırlığı güncelle

    setQualityWeights(updatedWeights);
    // Yeni toplam fiyatı hesapla
    if (!isNaN(value) && pricesQuality[quality] !== undefined) {
      const total = value * pricesQuality[quality];
      setTotalPricesQuality(prevTotalPrices => ({
        ...prevTotalPrices,
        [quality]: total,
      }));
    }


    const totalKgSetup = {};
    for (let i = 0; i < record.parts.length; i++) {
      const part = record.parts[i];
      const quality = part.quality;
      const weight = part.weightSetup;
      const piece = part.piece || 1; 
    
      if (weight && quality) {
        if (!totalKgSetup[quality]) {
          totalKgSetup[quality] = 0;
        }
        totalKgSetup[quality] += weight * piece;
      }
    }
   Object.keys(totalKgSetup).forEach(quality => {

    const weightDifference = updatedWeights[quality] - totalKgSetup[quality];
    if (weightDifference > 0) {
      const partsToUpdateWeight = record.parts.filter(part => part.quality === quality && part.weightSetup > 0); 
      partsToUpdateWeight.forEach(part => {
        const weightRatio = part.weightSetup / totalKgSetup[quality]; 
        part.newWeight = part.weightSetup + weightDifference * weightRatio;    
      });
    }
      });
  
  }
  else{
    console.log("yanlş girdi")
  }
};
  
  return (
    <>
   {/*<Form form={form} layout="vertical">*/}
      <div style={{ display: "flex", flexDirection: "column", padding: "10px 100px" }}>
        <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign: "left" }}> Teklif Detay</h2>
        
        <div style={{display:"flex"}}>
          <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ textAlign: "left", flex: "1 0 25%",marginRight:"10px" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Firma</p>
            {record && record.companyName}
          </div>
  
          <div style={{ textAlign: "left", flex: "1 0 25%",marginRight:"10px" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
            {record && record.jobCode}
          </div>
  
          <div style={{ textAlign: "left", flex: "1 0 25%",marginRight:"10px" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Programcı</p>
            {record && record.programmerName.length > 0 && record.programmerName[0]}
          </div>
  
          <div style={{ textAlign: "left", flex: "1 0 25%",marginRight:"10px" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Operasyonlar</p>
            {uniqueOperations.map((operation, index) => (
              <span key={index}>
                {operation}
                {index !== uniqueOperations.length - 1 && ' - '}
              </span>
            ))}
          </div>

        
        </div>
  
        <div style={{ display: "flex", justifyContent: "space-between", marginTop:"40px" }}>
          <div style={{ textAlign: "left", flex: "1 0 25%" ,marginRight:"10px"}}>

          <p style={{ fontWeight: "bold", color: colors.blue }}>Ödeme Vaadesi</p>
            <Select
            showSearch
              onChange={onChange}
              placeholder="Ödeme Vaadesi Seçiniz"
              style={{ width: "200px" }}
              suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
              value={paymentMethod}
              options={paymentMethods.map(item => ({ label: item.paymentMethod, value: item.paymentMethod }))}
              className="input-style" 
              filterOption={(input, option) => 
                option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
              }    
            />

          
          </div>
  
          <div style={{ textAlign: "left", flex: "1 0 25%",marginRight:"10px" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Takım Adedi</p>
            <Form.Item
              name="teamCount"
              rules={[{ required: true, message: 'Lütfen takım sayısı giriniz!' }]}
            >
              <Input
                className="input-style"
                style={{ width: "200px" }}
                placeholder="Takım Sayısı"
                onChange={handleTeamCountChange}
                value={selectedTeamCount}
                defaultValue={1}
              />
            </Form.Item>
          </div>
  
         
  
          <div style={{ textAlign: "left", flex: "1 0 25%" ,marginRight:"10px"}}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Teslim Şekli</p>
            <Form.Item
              name="deliveryMethod"
              rules={[{ required: true, message: 'Lütfen teslim şekli giriniz!' }]}
            >
              <Select
                showSearch
                className="input-style"
                style={{ width: "200px" }}
                placeholder="Teslim Şekli"
                onChange={handleDeliveryMethod}
                value={deliveryMethod}
                defaultValue={"Fabrika"}
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
              

              >
                <Select.Option value="Fabrika">Fabrika</Select.Option>
                <Select.Option value="Nakliye">Nakliye</Select.Option>
              </Select>
            </Form.Item>
  
            <Form.Item
              name="deliveryPrice"
              style={{ marginTop: "10px" }} // textfield'in üstüne biraz boşluk bırakmak için
            >
              <InputNumber
                className="input-style"
                style={{ width: "200px" }}
                placeholder="Fiyat (opsiyonel)"
                onChange={handleDeliveryPrice}
                value={deliveryPrice}
              />
            </Form.Item>
          </div>
  
          <div style={{ textAlign: "left", flex: "1 0 25%" ,marginRight:"10px"}}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Birim</p>
            <Form.Item
              name="unit"
              rules={[{ required: true, message: 'Lütfen birim giriniz!' }]}
            >
              <Input
                className="input-style"
                style={{ width: "200px" }}
                placeholder="Birim"
                onChange={handleUnit}
                value={unit || "Kg"}
                defaultValue={"Kg"}
              />
            </Form.Item>
        </div>
       

              
       </div>
          </div>

          <div style={{marginLeft:"40px"}}>
          <p style={{ fontWeight: "bold", color: colors.blue , textAlign:"left"}}>Açıklama</p>
            <Form.Item name="description">
              <Input.TextArea 
                className="input-style"
                style={{ width: "200px",height:"145px" }}
                placeholder="Açıklama"
                onChange={handleDescription}
                value={description}
              />
            </Form.Item>
          </div>
       </div>

        <div style={{ marginTop: "100px" }}>
          <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left", color: colors.blue, fontWeight: "bold" }}>
            <div style={{ width: "250px", marginRight: "10px" }}>Kalite</div>
            <div style={{ width: "150px", marginRight: "10px" }}>Kg</div>
            <div style={{ width: "100px", marginRight: "10px" }}>Kg. Fiyat</div>
            <div style={{ width: "100px", marginRight: "10px" }}>Toplam Fiyat</div>
          </div>
  
          {Object.entries(qualityWeights).map(([quality, weight], index) => (
            <div key={index} style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left" }}>
              <div style={{ width: "250px", marginRight: "10px" }}>{quality}</div>
            
              <Input
                  type="number"
                  value={weight} 
                  step="any" 
                  onChange={(e) => handleWeightChangeQuality(quality, e)} 
                  placeholder="Kg"
                  style={{ width: "150px", marginRight: "10px" }}
                  className="input-style"
                  min="0"
                  onKeyPress={(e) => {
                   
                    const charCode = e.charCode;
                    if (
                      (charCode !== 46 && charCode < 48) || 
                      charCode > 57
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
               <Input
                type="number"
                value={pricesQuality[quality] || ''}
                onChange={(e) => handlePriceChangeQuality(quality, e)}
                placeholder="Fiyat"
                style={{ width: "100px", marginRight: "10px" }}
                className="input-style"     
              />
              <div style={{ width: "100px", marginRight: "10px" }}>{totalPricesQuality[quality]?.toFixed(2) || 0}</div>
            </div>
          ))}
  
          {Object.entries(machineTimes).map(([machine, data], index) => (
            <div key={index}>
              <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left", color: colors.blue, fontWeight: "bold" }}>
                <div style={{ width: "250px", marginRight: "10px" }}>Trumpf {machine}KW</div>
                <div style={{ width: "150px", marginRight: "10px" }}>Dakika</div>
                <div style={{ width: "100px", marginRight: "10px" }}>Fiyat</div>
                <div style={{ width: "100px", marginRight: "10px" }}>Toplam Fiyat</div>
              </div>
  
              {Object.entries(data.fuels).map(([fuelType, time], fuelIndex) => (
                <div key={`${index}-${fuelIndex}`} style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left" }}>
                  <div style={{ width: "250px", marginRight: "10px" }}>{fuelType}</div>
                  <Input
                    value={editableTimes[`${machine}-${fuelType}`] !== undefined ? editableTimes[`${machine}-${fuelType}`] : time}
                    onChange={(e) => handleMachineTimeChange(machine, fuelType, e.target.value)}
                    placeholder="HH:MM:SS"
                    style={{ width: "150px", marginRight: "10px" }}
                    className="input-style"
                  />
                  <Input
                    type="number"
                    value={pricesTime[`${machine}-${fuelType}`] || ''}
                    onChange={(e) => handlePriceChangeTime(`${machine}-${fuelType}`, e)}
                    placeholder="Fiyat"
                    style={{ width: "100px", marginRight: "10px" }}
                    className="input-style"
                  />
                  <div style={{ width: "100px", marginRight: "10px" }}>
                    {totalPricesTime[`${machine}-${fuelType}`]?.toFixed(2) || 0}
                  </div>
                </div>
              ))}
            </div>
          ))}
  
          {Object.keys(plazmaWeights).length > 0 && (
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left", color: colors.blue, fontWeight: "bold" }}>
              <div style={{ width: "250px", marginRight: "10px" }}>Plazma</div>
              <div style={{ width: "150px", marginRight: "10px" }}>Kg</div>
              <div style={{ width: "100px", marginRight: "10px" }}>Fiyat</div>
              <div style={{ width: "100px", marginRight: "10px" }}>Toplam Fiyat</div>
            </div>
          )}
  
          {Object.entries(plazmaWeights).map(([isPlazma, weight], index) => (
            <div key={index} style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left" }}>
              <div style={{ width: "250px", marginRight: "10px" }}></div>
              <div style={{ width: "150px", marginRight: "10px" }}>{weight.toFixed(2)} kg</div>
              <Input
                type="number"
                value={pricesPlazma[isPlazma] || ''}
                onChange={(e) => handlePriceChangePlazma(isPlazma, e)}
                placeholder="Fiyat"
                style={{ width: "100px", marginRight: "10px" }}
                className="input-style"     
              />
              <div style={{ width: "100px", marginRight: "10px" }}>{totalPricesPlazma[isPlazma]?.toFixed(2) || 0}</div>
            </div>
          ))}
  
          <div style={{ marginTop: "50px" }}>
            {record.items.some(item => item.priorityAbkant) && (
              <div>
                <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left", color: colors.blue, fontWeight: "bold" }}>
                  <div style={{ width: "250px", marginRight: "10px" }}>Operasyonlar</div>
                  <div style={{ width: "150px", marginRight: "10px" }}>Dakika/Kg/Adet</div>
                  <div style={{ width: "200px", marginRight: "10px" }}></div>
                  <div style={{ width: "100px", marginRight: "10px" }}>Fiyat</div>
                  <div style={{ width: "100px", marginRight: "10px" }}>Toplam Fiyat</div>
                </div>
  
                {abkant.map((operation, index) => (
                  <div key={index} style={{ marginBottom: "10px", display: "flex" }}>
                    <div style={{ display: "flex", flexDirection: "row", textAlign: "left" }}>
                      <div style={{ width: "250px", marginRight: "10px" }}>{operation}</div>
                      <Select
                      showSearch
                        placeholder="Dk/Kg/Adet"
                        style={{ width: "150px", marginRight: "10px" }}
                        className="input-style"
                        onChange={(value) => setSelectedOperation({ ...selectedOperation, [operation]: value })}
                   
                      >
                        <Option value="Dakika">Dakika</Option>
                        <Option value="Kg">Kg</Option>
                        <Option value="Adet">Adet</Option>
                      </Select>
                    </div>
    
                    {selectedOperation && selectedOperation[operation] && (
                      <div style={{ display: "flex", marginBottom: "10px", textAlign: "left" }}>
                        {selectedOperation[operation] === 'Adet' && (
                          <Input
                            type="number"
                            value={quantitiesAbkant[operation] || ''}
                            onChange={(e) => handleQuantityChangeAbkant(operation, e)}
                            placeholder="Toplam Büküm Sayısı"
                            style={{ width: "200px", marginRight: "10px" }}
                            className="input-style"
                          />
                        )}
  
                        {selectedOperation[operation] === 'Dakika' && (
                          <Input
                            value={quantitiesAbkant[operation] || ''}
                            onChange={(e) => handleQuantityChangeAbkant(operation, e)}
                            placeholder="Süre Giriniz (Dakika)"
                            style={{ width: "200px", marginRight: "10px" }}
                            className="input-style"
                          />
                        )}
  
                        {selectedOperation[operation] === 'Kg' && (
                          <div style={{ width: "200px", marginRight: "10px" }}>{abkantWeight.toFixed(2)} kg</div>
                        )}
  
                        <Input
                          type="number"
                          value={pricesAbkant[operation] || ''}
                          onChange={(e) => handlePriceChangeAbkant(operation, e)}
                          placeholder="Fiyat"
                          style={{ width: "100px", marginRight: "10px" }}
                          className="input-style"
                        />
                        <div style={{ width: "100px", marginRight: "10px" }}>
                          {totalPricesAbkant[operation]?.toFixed(2) || 0}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
  
        {/* Toplam fiyatların toplamını hesaplayın */}
        <div style={{ marginTop: "40px", fontWeight: "bold", color: colors.blue, textAlign: "left",marginBottom:"20px" }}>
  Toplam Fiyatların Toplamı:   
  <span style={{ color: "black", fontWeight:"initial" ,marginLeft:"80px"}}> {totalSum.toFixed(2)} TL</span>
</div>
  
        <div style={{ display: "flex", marginTop:"70px", justifyContent:"space-between" }}>
          <Button style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500", float: "left", width: "200px" }} onClick={() => setVisible(true)}>
            Operasyon Detaylarını Gör
          </Button>
  
          <div style={{ float: "right"}}>
            <Button style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500", float: "left", width: "200px" }} onClick={handleButtonClick}>
              Operasyon Fiyatlarını Gir
            </Button>
          </div>
        </div>
  
        <Modal
          title=""
          visible={isModalVisible}
          onCancel={handleModalCancel}
          footer={null}
          className="operation-detail-popup"
        >
          <OperationDetail record={recordForModal} onClose={handleSetupFinish} />
        </Modal>
  
        <Modal
          title="Görmek istediğiniz Setup'ı seçiniz"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          className="popup-modal-button"
          okText="Devam Et"
          cancelText="İptal"
        >
          <div>
            <Form.Item
              name="jobCode"
              rules={[{ required: true, message: 'Lütfen iş kodu seçiniz!' }]}
              style={{ marginBottom: "40px", marginTop: "30px" }}
            >
              <Select
              showSearch
                onChange={onChange}
                placeholder="İş Kodu Seçiniz"
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                options={jobCodes}
                className="input-style"
                filterOption={(input, option) => 
                  option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
                }
              />
            </Form.Item>
          </div>
        </Modal>
      </div>
      {/*</Form>*/}
    </>
  )};
  

export default OfferDetail;


/**
 * 
 * 
   <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
          {record && record.deadline && typeof record.deadline === 'object' ?
            new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') :
            record.deadline
          }
        </div>




          if ( !paymentMethod) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Ödeme vaadesi boş olamaz",
          placement: 'topRight'
        });
        return;
      }


       
       
       
       
 */

