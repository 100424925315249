import React,{useEffect,useState} from "react";
import { Button, Form, Input,Table ,Checkbox, DatePicker,Progress, Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import  {colors,parsCollections}  from "../../../environments/environment"
import OneSignalService from "../../../services/oneSignalService";
import moment from 'moment';

const SetupPlatesDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isScrapDelivery, setIsScrapDelivery] = useState(false);
    const [qualities, setQualities] = useState([]); 
    const [code, setCode] = useState(''); 
    const [loading, setLoading] = useState(false);
    const today = moment().startOf('day'); 
    const [plates, setPlates] = useState(null); 
    const [generatedCode, setGeneratedCode] = useState(false);

    const disabledDate = (current) => {
      return current && current < today;
    }
    const data = location.state && location.state.data;
    const xmlInfo = location.state && location.state.xmlInfo;
     
    const parts = location.state && location.state.xmlInfo.parts;
   const priorityAbkant = parts && parts.some(part => 
        part.operations && part.operations.some(operation => operation.toLowerCase() === "abkant")
  ) ? 999999999 : null;

    const setupDataa = location.state && location.state.setupData;
    const record = location.state && location.state.record;
    const selectedJobCodes = location.state && location.state.selectedJobCodes;
    const selectedCompanies = location.state && location.state.selectedCompanies;

   const [additionalContents, setAdditionalContents] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
  
   
     
    useEffect(() => {
      window.scrollTo(0, 0);
      setIsLoading(true); 
        const fetchData = async () => {
          const qualities = await FirebaseService.getDensitiesData();
          const filteredQualities = qualities.filter(item => !item.isDeleted);
          setQualities(filteredQualities);
/*
          if (setupDataa && setupDataa.plates) {
              const hasImageUrl = setupDataa.plates.some(plate => plate.imageUrl);
               console.log("hasImageUrl",hasImageUrl)
              if (hasImageUrl) {
                console.log("girdi plates şuan ",setupDataa.plates)
                  setPlates(setupDataa.plates);
              } else {

                  const plates = location.state && location.state.xmlInfo.plates;
                  console.log("girmedi",plates)

                  setPlates(plates);
              }
          } else {
              const plates = location.state && location.state.xmlInfo.plates;
              setPlates(plates);
          }*/

              console.log("setupDataa.plates",setupDataa.plates)

           setPlates(setupDataa.plates);


          
          setIsLoading(false);

        };
        fetchData();
    }, []);

  
    const generateUniqueCode = async () => {
      try {
        const codesData = await FirebaseService.getContractManufacturingData();
        const codes = codesData.map(item => item.code);
    
        if (codes.length === 0) {
          return { success: true, code: '13600' };
        }
    
        const maxJobCode = codes
          .map(code => parseInt(code, 10))
          .filter(num => !isNaN(num)) 
          .sort((a, b) => a - b) 
          .pop(); 
    
        const incrementedJobCode = (maxJobCode + 1).toString().padStart(5, '0');
    
        setCode(incrementedJobCode);
    
        return { success: true, code: incrementedJobCode };
    
      } catch (error) {
        return { success: false, code: null };
      }
    };
    

    const addAdditionalContent = (plateIndex) => {
      setAdditionalContents((prevContents) => {
        const newContents = [...prevContents];
        newContents[plateIndex] = [...(newContents[plateIndex] || []), { width: '', height: '', number: 1 }];
        return newContents;
      });
    };
  
    const removeAdditionalContent = (plateIndex, contentIndex) => {
      setAdditionalContents((prevContents) => {
        const newContents = [...prevContents];
        newContents[plateIndex].splice(contentIndex, 1);
        return newContents;
      });
    }
    
    const isDateBeforeToday = (dateString) => {
      const selectedDate = new Date(dateString);
      const today = new Date(); 
        return selectedDate < today;
    };

    const getOneSignalPlayerIds = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satış"));
                
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.oneSignalPlayerId) {
            oneSignalPlayerIds.push(user.oneSignalPlayerId);
          }
        });
                
        return oneSignalPlayerIds;
      } catch (error) {
        return [];
      }
    };

    const getOneSignalUserIds = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satış"));
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.key) {
            oneSignalPlayerIds.push(user.key);
          }
        });
                
        return oneSignalPlayerIds;
      } catch (error) {
        return [];
      }
    };

    const getOneSignalPlayerIdsArchieve = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("depo"));
        
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.oneSignalPlayerId) {
            oneSignalPlayerIds.push(user.oneSignalPlayerId);
          }
        });
                
        return oneSignalPlayerIds;
      } catch (error) {
        return [];
      }
    };

    const getOneSignalUserIdsArchieve = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("depo"));
        
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.key) {
            oneSignalPlayerIds.push(user.key);
          }
        });
        
        
        return oneSignalPlayerIds;
      } catch (error) {
        return [];
      }
    }; 

    const getOneSignalPlayerIdsBuyer= async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satın alma"));
        
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.oneSignalPlayerId) {
            oneSignalPlayerIds.push(user.oneSignalPlayerId);
          }
        });
        
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

    const getOneSignalUserIdsBuyer= async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satın alma"));
        
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.key) {
            oneSignalPlayerIds.push(user.key);
          }
        });
        
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

    const checkItems = async (record) => {
    
      let isAllSuccessful = true;
    
      // Check and update stock items
      for (const stockItem of record.stock) {
        const { id } = stockItem;
    
        const stockTableItem = await FirebaseService.getStockItemById(id);
    
        if (stockTableItem) {
        
          if (stockTableItem.reservedPiece <= 0 ||  (stockTableItem.reservedPiece < stockItem.piece)  ) {            
            if (stockTableItem.piece <= 0 || stockTableItem.piece < stockItem.piece) {
              isAllSuccessful = false;
              break; 
            }
      
            const result = await FirebaseService.updateStockReservedPiece(id, stockItem.piece);
            if (result.success) {
              console.log("Stock item successfully reserved.");
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Parça ID: ${id} güncellenemedi. Hata: ${result.message}`,
                placement: 'topRight',
              });
              isAllSuccessful = false;
              break;
            }
          }
        } else {
          console.log(`Warning: No item found in stock table for id ${id}`);
          isAllSuccessful = false;
          break; 
        }
      }
    
      if (!isAllSuccessful) return false;
    
    for (const wastageItem of record.wastage) {
        const { id } = wastageItem;
    
        const wastageTableItem = await FirebaseService.getWastageItemById(id);
    
        if (wastageTableItem) {
          
          const totalAvailable = wastageTableItem.piece + wastageTableItem.comingPiece; 
          const totalReserved = wastageTableItem.reservedPiece + wastageTableItem.reservedComingPiece; 


          if ((wastageTableItem.reservedPiece <= 0  &&  wastageTableItem.reservedComingPiece <= 0)  || (totalReserved  < wastageItem.piece) ) {
            console.log(`Warning: The reservedPiece for wastage item with id ${id} is not greater than 0.`);
    
            if (totalAvailable <= 0 &&  totalAvailable < wastageItem.piece ) {
              isAllSuccessful = false;
              break; 
            }

            const result = await FirebaseService.updateWastageReservedPiece(id, wastageItem.piece);
            if (result.success) {
              console.log("wastage başarılı reserve güncellensin");
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Parça ID: ${id} güncellenemedi. Hata: ${result.message}`,
                placement: 'topRight',
              });
              isAllSuccessful = false;
              break; 
            }
          }
        } else {
          console.log(`Warning: No item found in wastage table for id ${id}`);
          isAllSuccessful = false;
          break; 
        }
      }
    
      return isAllSuccessful;
    };
    
    async function getPartData(jobCode, partNo, customerName) {
      const partsData = await FirebaseService.getPartsData(); 
    
      return partsData.find(
        part =>
          part.jobCode === jobCode &&
          part.partNo === partNo &&
          part.customerName === customerName
      );
    }
    

    const onFinish = async (values) => {   
      setLoading(true);
    
  
      let onlyJobCode = record.jobCode.split('-')[0];
      const allOpenOrder = await FirebaseService.getOpenOrderData();
      const filteredOpenOrder = allOpenOrder.find(item => 
        !item.isDeleted && 
        item.isWaiting && 
        item.isOffer && 
        item.isOfferApproved && 
        item.orderType === "offer" && 
        item.jobCode.includes(onlyJobCode)
      );
        

      const filteredOpenOrderFirstOffer = allOpenOrder.find(item => 
        !item.isDeleted && 
        item.isWaiting && 
        item.orderType === "offer" && 
        item.jobCode ===onlyJobCode &&
        !item.isSetup &&
        item.setupName ===null

      );      

      const filteredOpenOrderSame = allOpenOrder.find(item => 
        !item.isDeleted && 
        item.isWaiting && 
        item.jobCode ===record.jobCode 
      );  
      
      //  sipariş seçip tüm adımları yaptysa stoktan düşme vs veya teklif onaylandıysa 
      if (
        (record.isSetup !== false && record.orderType === "order") || 
        ((filteredOpenOrder &&  filteredOpenOrder.isOfferApproved && record.orderType === "offer"))
      )
        {
        const requestedItems = record.stock.filter(stockItem =>
          !record.checkedItemsStockExcess.some(item =>
            item.id === stockItem.id &&
            item.quality === stockItem.quality &&
            item.height === stockItem.height &&
            item.width === stockItem.width &&
            item.piece === stockItem.piece &&
            item.thickness === stockItem.thickness &&
            item.surface === stockItem.surface
          )
        );

        if (record.isReserved === false || record.isReserved === undefined) { 
          let updatedCheckedItemsStock;
          
          if (requestedItems && requestedItems.length > 0) {
            for (const x of requestedItems) {
              const { id, piece } = x;
              const result = await FirebaseService.updateStockReservedPiece(id, piece);
              if (result.success) {
                x.isReserved = true; 
              }
              else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Hata: ${result.message}`,
                placement: 'topRight',
              });
              setLoading(false);
              return; 
            }
            }
            updatedCheckedItemsStock = requestedItems.map(stockItem => ({
              ...stockItem,
              isReserved: stockItem.isReserved,
            
            }));
            
          }
        
          let updatedCheckedItemsWastage;
          
          if (record.wastage && record.wastage.length > 0) {
            for (const x of record.wastage) {
              const { id, piece } = x;
              const result = await FirebaseService.updateWastageReservedPiece(id, piece);
              if (result.success) {
                x.isReserved = true; 
              }
              else {
                NotificationService.openErrorNotification({
                  title: 'İşlem Başarısız',
                  description: `Hata: ${result.message}`,
                  placement: 'topRight',
                });
                setLoading(false);
                return; 
              }
            }
        
            updatedCheckedItemsWastage =  record.wastage.map(wastageItem => ({
              ...wastageItem,
              isReserved: wastageItem.isReserved, // Apply the latest `isReserved` state
            
            }));
          }
        } else {
          console.log("daha önce rezerve edilmiş bu adım geçiliyor")
        }
        

      }

      if( record.orderType === "order"){
        //tekrar kontrol edilyor rezerveleer
        const againCheckReserve =  await checkItems(record)
        if(!againCheckReserve){
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Rezerve edilemedi. Rezerve etmek istediğiniz tükenmiş olabilir lütfen başka seçiniz.",
            placement: 'topRight'
        });
        setLoading(false);
        return;
        }
    }
      const currentDate = new Date();
      const groupedParts = {};


      //fason üretime ekleme alanı hem siparişse hemde teklif onaylandıysa 
      if ((record.orderType === "order" && selectedJobCodes.length ===1 && selectedJobCodes)  || (filteredOpenOrder && filteredOpenOrder.isOfferApproved)) {

        console.log("buraya girdi mi fason ve sipariş ama birleşen işler değil")
        parts.forEach((part) => {
          const { partNo, operations } = part;
            operations.forEach((operation) => {
            if (!groupedParts[operation]) {
              groupedParts[operation] = {
                operation: operation, 
                parts: [], 
              };
            }
            groupedParts[operation].parts.push({
              partCode:part.partNo,
              piece: part.piece,
              unitPrice:null,
              profit:null
              
            });
          });
        });
    
        const generatedCode =  await generateUniqueCode();

        if(!generatedCode.success){
         NotificationService.openErrorNotification({
           title: 'İşlem Başarısız',
           description: 'İş kodu üretilemedi. Lütfen daha sonra tekrar deneyinz.',
           placement: 'topRight'
         });
         setLoading(false)
         return;
        }
  
        setGeneratedCode(generatedCode.code);
    
        Object.values(groupedParts).forEach(async (group) => {
        
          if (group.operation.toLowerCase() === "abkant") {
            return; 
          }
          
          const data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
            companyName: record.companyName,
            supplierName: null,
            jobCode: record.jobCode,
            projectCode: record.projectCode,
            deadline: null,
            openOrderDeadline: new Date(record.deadline.seconds * 1000),
            processName: group.operation,
            isContractManufacturing: false,
            isShipment: false,
            isRequestCompleted: false,
            parts: group.parts, 
            isCompleted:false,
            code: generatedCode.code,
            invoiceNumber:null,
            depotName :  plates[0].depotName  || null,

          };
    
          try {
            const result = await FirebaseService.addRecording(parsCollections.contractManufacturing, data);
            if (result.success) {
           
              const ids = await getOneSignalPlayerIdsBuyer();
              const userIds = await getOneSignalUserIdsBuyer();

              const sentUserIdsMap = new Map();
              userIds.forEach(userId => {
                sentUserIdsMap.set(userId, false);
              });
              
              const sentUserIds = Object.fromEntries(sentUserIdsMap);

              if (ids.length > 0) {
                  const oneSignalResult = await OneSignalService.sendNotification("", "Fason içeren Açık Sipariş", ids);
                 
                  const data = {
                    firstTransactionDate: currentDate,
                    firstUserId: localStorage.getItem("uid"),
                    isDeleted: false,
                    sentUserIds:sentUserIds,
                    firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
                    unitName:localStorage.getItem('userUnit'),
                    title:"Fason içeren Açık Sipariş",
                    companyName:record.companyName,
                    jobCode:record.jobCode,
                    projectCode:record.projectCode,
                    machine:null,
                    type:null,
                    notice:"Fason içeren açık sipariş yüklenmiştir, kontrol ediniz.",
                    description:null

                  };

                  const result = await FirebaseService.addRecording(parsCollections.notifications,data);
              } else {
                  console.log("No OneSignal Player IDs found.");
              }


            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
              return;
            }
          } catch (error) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
            setLoading(false);
            return;
          }
          
        });
    
        if(record.isPlazma){
          const data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
            companyName: record.companyName,
            supplierName: null,
            jobCode: record.jobCode,
            projectCode: record.projectCode,
            deadline: null,
            openOrderDeadline: new Date(record.deadline.seconds * 1000),
            processName: "plazma",
            isContractManufacturing: false,
            isShipment: false,
            isRequestCompleted: false,
            parts: null, 
            isCompleted:false,
            code:generatedCode.code,
            invoiceNumber:null,           
            depotName :  plates[0].depotName  || null,
          };
    
          try {
            const result = await FirebaseService.addRecording(parsCollections.contractManufacturing, data);
            if (result.success) {
              const ids = await getOneSignalPlayerIdsBuyer();
              const userIds = await getOneSignalUserIdsBuyer();
              const sentUserIdsMap = new Map();
              userIds.forEach(userId => {
                sentUserIdsMap.set(userId, false);
              });
              
              const sentUserIds = Object.fromEntries(sentUserIdsMap);
              if (ids.length > 0) {
                  const oneSignalResult = await OneSignalService.sendNotification("", "Fason içeren Açık Sipariş", ids);
                 
                  const data = {
                    firstTransactionDate: currentDate,
                    firstUserId: localStorage.getItem("uid"),
                    isDeleted: false,
                    sentUserIds:sentUserIds,
                    firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
                    unitName:localStorage.getItem('userUnit'),
                    title:"Fason içeren Açık Sipariş",
                    companyName:record.companyName,
                    jobCode:record.jobCode,
                    projectCode:record.projectCode,
                    machine:null,
                    type:null,
                    notice:"Fason içeren açık sipariş yüklenmiştir, kontrol ediniz.",
                    description:null

                  };
                  const result = await FirebaseService.addRecording(parsCollections.notifications,data);
              } else {
                  console.log("No OneSignal Player IDs found.");
              }
    
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
              setLoading(false);
              return;
            }
          } catch (error) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
            setLoading(false);
            return;
          }
        }
    
      }

         
      const expectedWastages = [];
      let density = 1;
      const selectedQuality = qualities.find(item => item.quality === data.quality);
      if (selectedQuality) {
          density = selectedQuality.density;
      }
  
      const handleFinish = async (plateIndex) => {
        const plate = plates[plateIndex];
        const contents = additionalContents[plateIndex]?.length || 0; 
        let wastagesKg = 0; 
    
        for (let contentIndex = 0; contentIndex < contents; contentIndex++) {
            const widthValues = values[`width-${plateIndex}-${contentIndex}-programNo-${plate.programNo}`];
            const heightValues = values[`height-${plateIndex}-${contentIndex}-programNo-${plate.programNo}`];
            const numberValues = values[`number-${plateIndex}-${contentIndex}-programNo-${plate.programNo}`];

           
    
            const area = widthValues * heightValues;
            const weight = area * Number(data.thickness) * density; 
            const weightInKg = (weight / 1000000) * plate.piece * Number(numberValues); 

            if (!isNaN(weightInKg) && weightInKg !== 0) {
              expectedWastages.push({
                  width: Number(widthValues),
                  height: Number(heightValues),
                  programNo: plate.programNo,
                  kg: weightInKg,
                  piece: plate.piece * Number(numberValues),
                  depotName:plate.depotName ? plate.depotName  : null
              });
      
          
              wastagesKg += weightInKg;
          } else {
              console.log(`Invalid weight calculated for plate ${plate.programNo}`);
          }
        }
    
        const matchingPlates = plates.filter(p => p.programNo === plate.programNo);
        matchingPlates.forEach(matchingPlate => {
          matchingPlate.wastagesKg = wastagesKg; 
          matchingPlate.kg =  matchingPlate.kg-wastagesKg; 
          matchingPlate.wastage = expectedWastages.filter(w => w.programNo === plate.programNo); 
          matchingPlate.jobCode =  record.jobCode; 

        });
      };
     
      let totalKg = 0;
      const plateCount = plates.length;
      let descriptionValues = null;
      for (let plateIndex = 0; plateIndex < plateCount; plateIndex++) {
            await handleFinish(plateIndex);
            const plate = plates[plateIndex];
            descriptionValues = values[`description-${plateIndex}-${plate.programNo}`];
            plates[plateIndex].description = descriptionValues ? descriptionValues : null;
            plates[plateIndex].status = "Kesilecek";
            plates[plateIndex].isFinish = false;
            totalKg +=  plates[plateIndex].kg 

      }

      //parts tablsouna eklemek
      const updatedParts = xmlInfo.parts.map(item => ({
        ...item,
       // jobCode :record.jobCode,
       // projectCode :record.projectCode ? record.projectCode : null ,
        //companyName :record.companyName,
        operations: item.operations.map(operation => ({
          processName: operation,
          isContractManufacturing: false,
          isCompleted: false,
          isQrScan:false,
          firstUserNameSurname: null,
          firstUserId:null
        }))
      }));    
          
        //teklifse wastage tablosuna eklenmicek. sadece siparişse eklencek yada teklif onaylandıysa 
      if(record.orderType === "order" ||   ( (filteredOpenOrder && filteredOpenOrder.isOfferApproved && record.orderType === "offer")) ){
          const wastageData = await FirebaseService.getWastagesData();
          for (const wastage of expectedWastages) {
            const width = Number(wastage.width);
            const height = Number(wastage.height);
            const thickness = Number(data.thickness);
            const unitKilo = (width * height * thickness * density) / 1000000;
            const totalKilo = unitKilo * wastage.piece;
          
            const existingWastage = wastageData.find(
              (item) =>
                item.surface === data.surface &&
                item.width === width &&
                item.height === height &&
                item.depotName === wastage.depotName &&
                item.thickness === thickness &&
                item.quality === data.quality &&
                item.companyName === record.companyName
            );
          
            if (existingWastage) {
              // Update the existing record
              const updatedPiece = existingWastage.piece + wastage.piece;
              const updatedTotalKilo = existingWastage.unitKilo * updatedPiece;
          
              await FirebaseService.updateRecording(parsCollections.wastages, existingWastage.key, {
               // piece: updatedPiece,
               // totalKilo: updatedTotalKilo,
                comingPiece: wastage.piece,
                isQRScanned: false
              });
            } else {

              const wastageItem = {
                firstTransactionDate: currentDate,
                firstUserId: localStorage.getItem("uid"),
                firstUserNameSurname: localStorage.getItem('name') + " " + localStorage.getItem('surname'),
                isDeleted: false,
                isQRScanned: false,
                companyName: record.companyName,
                depotName: wastage.depotName || null,
                jobCode: record.jobCode,
                width,
                height,
                thickness,
                quality: data.quality,
                surface: data.surface,
                imageUrl: null,
                description: "Teknik ressam tarafından setuptan eklenmiştir.",
                piece: 0,
                reservedPiece: 0,
                unitKilo,
                totalKilo,
                comingPiece: wastage.piece,
                reservedComingPiece: 0
              };

              const result = await FirebaseService.addRecording(parsCollections.wastages, wastageItem);
              wastage.docId = result.docId;
            }
          }
          
         
      }
       
      const priorityMachine = 999999999 ;

      let abkantBreaks= {
        userId:null,
        nameSurname:null,
        reason:null,
        firstTransactionDate:null,
        lastTransactionDate:null,
        description:null
      }
     

      let mergedJobCodes = [];

      if (record?.orderType === "order" && Array.isArray(selectedJobCodes)) {
          mergedJobCodes = selectedJobCodes.map((code) => 
              code.includes("-") 
                  ? code.substring(0, 5) + "-" + record.jobCode.substring(6) 
                  : code + "-" + record.jobCode.substring(6)
          );
      }
      
      console.log("Updated mergedJobCodes:", mergedJobCodes);
      
      const item = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
          isDeleted: false, 
          thickness :data.thickness,
          quality :data.quality,
          surface :data.surface,
          fuel: data.fuel,
          plateCount:plateCount,
          setupTime :xmlInfo.totalRuntime,
          setupName :record.jobCode.substring(6),
          machine :xmlInfo.machine,
          jobCode :record.jobCode,
          projectCode :record.projectCode ? record.projectCode : null ,
          companyName :record.companyName,
          stock:record.stock ? record.stock : [] , //eğer teklifse stock boş gelir 
          wastage:record.wastage ? record.wastage : [] , //eğer teklifse wastage boş gelir 
          description: values.description ?  values.description : null,
          isScrapDelivery : isScrapDelivery,
          parts:setupDataa.parts,
          plates:plates,
          expectedWastages:expectedWastages, 
          documentUrl : setupDataa.documentUrl ?  setupDataa.documentUrl : null,  //boş olabilir 
          priorityMachine: priorityMachine,
          priorityAbkant:priorityAbkant,
          isCustomerRaw: data.isCustomerRaw,
          isPlazma : record.isPlazma,
          totalKg:totalKg,
          abkantBreaks:abkantBreaks,
          abkantTwistShape:null,
          abkantUserCount:0,
          abkantActualTime:null,
          abkantIsFinish:false,
          setupDescription:values.description ?  values.description :null,
          isShipment:false,
          isMerge: record?.orderType === "offer" ? false : selectedJobCodes.length >= 2,
          deadline: new Date(record.deadline.seconds * 1000),
          ...(selectedJobCodes && selectedJobCodes.length >= 2 && record?.orderType === "order" && { 
            mergedJobCodes: mergedJobCodes,
            mergedCompanyNames: selectedCompanies
        })
        
      };

      
    //usera perfomans ekleme setup eklediğinde 
    await FirebaseService.updateUserPerformance(localStorage.getItem("uid"), new Date(), xmlInfo.totalRuntime)
      .then(response => console.log(response))
      .catch(error => console.error(error));
    
     
   const result = await FirebaseService.updateRecording(parsCollections.setup, setupDataa.key, item);
   if (result.success) {

    const customerJobCodeMap = {};
                    
    // Eğer orderType "order" ise seçili şirketler ve iş kodlarını haritaya ekleyelim
    if (record?.orderType === "order") {
      selectedCompanies.forEach((company, index) => {
          customerJobCodeMap[company] = mergedJobCodes[index];
      });
    }


    
    console.log("customerJobCodeMap:", customerJobCodeMap);
    
   //parçalar eklencek
     for (const part of setupDataa.parts) {  
              try {
                console.log("part",part)
                const firstParam = Array.isArray(selectedJobCodes) && selectedJobCodes.length >= 2 
                ? customerJobCodeMap[part.customerName] 
                : part.jobCode ?? "DEFAULT_VALUE";
            
              
                const existingPart = await getPartData(firstParam , part.partNo, part.customerName);
              //  console.log("existingPart",existingPart,selectedJobCodes.length >=2, selectedJobCodes, part.jobCode,customerJobCodeMap[part.customerName] )
                if (existingPart) {
                  let resultPart= await FirebaseService.updateRecording(
                    parsCollections.parts,
                    existingPart.key,
                    { ...existingPart,
                      operations: (part.operations && Array.isArray(part.operations)) 
                      ? part.operations.map(operation => ({
                          processName: operation,
                          isContractManufacturing: false,
                          isCompleted: false,
                          isQrScan: false,
                          firstUserNameSurname: null,
                          firstUserId: null
                        }))
                      : [], 
                      firstTransactionDate:new Date()
                    } 

                  );

                  if (resultPart) {
                    console.log(`Part with jobCode ${part.jobCode}, partNo ${part.partNo}, and customerName ${part.customerName} successfully updated.`);
                  } else {
                    console.log(`Failed to update part with jobCode ${part.jobCode}, partNo ${part.partNo}, and customerName ${part.customerName}.`);
                  }
                } else {
                  console.log(`No part found with jobCode ${part.jobCode}, partNo ${part.partNo}, and customerName ${part.customerName} to update.`);
                }
              } catch (error) {
                console.error(`Error updating part with jobCode ${part.jobCode}, partNo ${part.partNo}, and customerName ${part.customerName}:`, error);
              }
     }
        
    } else {
          
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.2",
                placement: 'topRight'
            });
            setLoading(false);
            
   }
      









    console.log("selectedJobCodes",selectedJobCodes, "record,",record)
    if(record.orderType==="offer"){
      console.log("djdhjbh")
      const filteredDataSameOffer = allOpenOrder.find(item => !item.isDeleted && item.isWaiting && item.orderType === "offer" && item.jobCode === record.jobCode && item.setupName === xmlInfo.setupName);
      const filteredDataFirstOffer = allOpenOrder.find(item => !item.isDeleted && item.isWaiting && item.orderType === "offer" && item.jobCode === record.jobCode.substring(0,5) && item.setupName ===null );

      console.log("datayı buldu filteredDataSameOffer",filteredDataSameOffer)
      console.log("datayı buldu filteredDataFirstOffer",filteredDataFirstOffer)

      if(filteredDataSameOffer){
        console.log("same offer")
        let updateOpenOrderItem = {
          isSetup:true,
          setupName:record.jobCode.substring(6),
          jobCode :record.jobCode,
          machine: xmlInfo.machine,
          isAbkant : priorityAbkant ===  999999999 ? true : false
          };

          const resultx = await FirebaseService.updateOpenOrder(filteredDataSameOffer.key, updateOpenOrderItem);
          if(resultx){

            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description:"Başarıyla kaydedilmiştir.",
              placement: 'topRight'
            });
            setLoading(false);
            setTimeout(() => {
            // navigate("/programmerpage");
              navigate('/waitingopenorderdetail', { state: { record } })

            }, 500);
            
          }
          else{
            
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
              placement: 'topRight'
            });
            
            setLoading(false);
          } 
          }

          else if(filteredDataFirstOffer){
            console.log("filteredDataFirstOffer ")

            let updateOpenOrderItem = {
              isSetup:true,
              setupName:record.jobCode.substring(6),
              jobCode :record.jobCode,
              machine: xmlInfo.machine,
              isAbkant : priorityAbkant ===  999999999 ? true : false
              };
    
              const resultx = await FirebaseService.updateOpenOrder(filteredDataFirstOffer.key, updateOpenOrderItem);
              if(resultx){
    
                NotificationService.openSuccessNotification({
                  title: 'İşlem Başarılı',
                  description:"Başarıyla kaydedilmiştir.",
                  placement: 'topRight'
                });
                setLoading(false);
                setTimeout(() => {
                // navigate("/programmerpage");
                  navigate('/waitingopenorderdetail', { state: { record } })
    
                }, 500);
                
              }
              else{
                
                NotificationService.openErrorNotification({
                  title: 'İşlem Başarısız',
                  description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
                  placement: 'topRight'
                });
                
                setLoading(false);
              } 
          }
          else{
            console.log("data yok aaaaa update geçilmedi")

          }

          
    }

   //open order için update geçilmeli sadece
   else if(filteredOpenOrderSame &&   selectedJobCodes && selectedJobCodes.length === 1 && record.orderType==="order"){
    console.log("buraya mı geliyo test 2")

        let updateOpenOrderItem = {
            isSetup:true,
           
            setupName:record.jobCode.substring(6),
            jobCode :record.jobCode,//5 hane +setup ismi olması lazım
            machine: xmlInfo.machine,
            isAbkant : priorityAbkant ===  999999999 ? true : false


      };

      const resultx = await FirebaseService.updateOpenOrder(filteredOpenOrderSame.key, updateOpenOrderItem);
      if(resultx){
        
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description:"Başarıyla kaydedilmiştir.",
            placement: 'topRight'
          });
          setLoading(false);
          setTimeout(() => {
           // navigate("/programmerpage");
            navigate('/waitingopenorderdetail', { state: { record } })

          }, 500);
          
      }
      else{
          
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
            placement: 'topRight'
          });
          
          setLoading(false);
      }  
      
      
    }

    else if(filteredOpenOrderFirstOffer && selectedJobCodes.length === 1 && selectedJobCodes && record.orderType==="order"){
console.log("buraya mı geliyo test")
      let updateOpenOrderItem = {
            isSetup:true,
            setupName:record.jobCode.substring(6),
            jobCode :record.jobCode,
            machine: xmlInfo.machine,
            isAbkant : priorityAbkant ===  999999999 ? true : false

          };
        
        

      const resultx = await FirebaseService.updateOpenOrder(filteredOpenOrderFirstOffer.key, updateOpenOrderItem);
      if(resultx){
        
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description:"Başarıyla kaydedilmiştir.",
            placement: 'topRight'
          });
          setLoading(false);
          setTimeout(() => {
           // navigate("/programmerpage");
            navigate('/waitingopenorderdetail', { state: { record } })

          }, 500);
          
      }
      else{
          
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
            placement: 'topRight'
          });
          
          setLoading(false);
      }  
    }
  

    //birleşen işleler 
    else if(selectedJobCodes.length >= 2  && selectedJobCodes && record.orderType==="order"){
       //diğer setuplar yüklenmeli

         const generatedCode =  await generateUniqueCode();

        if(!generatedCode.success){
         NotificationService.openErrorNotification({
           title: 'İşlem Başarısız',
           description: 'İş kodu üretilemedi. Lütfen daha sonra tekrar deneyinz.',
           placement: 'topRight'
         });
         setLoading(false)
         return;
        }
  
        setGeneratedCode(generatedCode.code);  
        
       console.log("bu ife girdi birleşen işler yani")
       const openOrderData = await FirebaseService.getOpenOrderData();

      const results = [];
      for (const order of selectedJobCodes) {
       
      const jobCode = order.includes("-") ? order.substring(0,5) + "-" + record.jobCode.substring(6) :  order + "-" + record.jobCode.substring(6)
       const filteredData = openOrderData.find(item => !item.isDeleted && item.orderType === "order" && item.jobCode.substring(0,5) === order.substring(0,5) );
      
       console.log("order",order,order.substring(0,5))
       console.log("ilkinde filteredData",filteredData)
        console.log("order jobCode",jobCode)

        if (jobCode === record.jobCode) {
          console.log("Döngü atlandı çünkü record.jobCode mevcut:", record.jobCode);
          continue;
        }
      
        const item = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
          isDeleted: false,
     
          thickness :data.thickness,
          quality :data.quality,
          surface :data.surface,
          fuel: data.fuel,
          plateCount:plateCount,
     
          setupTime :xmlInfo.totalRuntime,
          setupName :record.setupName ? record.setupName : xmlInfo.setupName,
          machine :xmlInfo.machine,

          jobCode :jobCode ,
          projectCode :filteredData.projectCode ? filteredData.projectCode : null ,
          companyName :filteredData.companyName,
          stock:record.stock ? record.stock : [] , //eğer teklifse stock boş gelir 
          wastage:record.wastage ? record.wastage : [] , //eğer teklifse wastage boş gelir 

          description: values.description ?  values.description : null,
          isScrapDelivery : isScrapDelivery,
          parts:xmlInfo.parts,
          plates:xmlInfo.plates,

          expectedWastages:expectedWastages, 

          documentUrl : setupDataa.documentUrl ?  setupDataa.documentUrl : null,  //boş olabilir 
          priorityMachine: priorityMachine,
          priorityAbkant:priorityAbkant,

          isCustomerRaw: data.isCustomerRaw,
          isPlazma : record.isPlazma,
          totalKg:totalKg,

          abkantBreaks:abkantBreaks,
          abkantTwistShape:null,
          abkantUserCount:0,
          abkantActualTime:null,
          abkantIsFinish:false,
          setupDescription:values.description ?  values.description :null,
          isShipment:false,
          isMerge:true,
          deadline: filteredData.deadline,
          ...(selectedJobCodes && selectedJobCodes.length >= 2 && record?.orderType === "order" && { 
            mergedJobCodes: mergedJobCodes,
            mergedCompanyNames: selectedCompanies
        })

       
        
        };
        console.log("item",item)
        try {
          const result = await FirebaseService.addRecording(parsCollections.setup, item);
          console.log("result",result)
          results.push(result); 
        } catch (error) {
          console.error("Error saving record:", error);
          results.push({ success: false, error }); 
        }
      }
      
      const allSuccess = results.every(result => result.success);
      
      if (allSuccess) {
        console.log("All records were saved successfully.");

         //usera perfomans ekleme setup eklediğinde 
         await FirebaseService.updateUserPerformance(localStorage.getItem("uid"), new Date(), xmlInfo.totalRuntime)
         .then(response => console.log(response))
         .catch(error => console.error(error));
       
    
        console.log(record.key, record)
       
        const updateResults = [];

        if (record?.orderType === "order" && Array.isArray(selectedJobCodes)) {
           mergedJobCodes = selectedJobCodes.map((code) => 
              code.includes("-") 
                  ? code.substring(0, 5) + "-" + record.jobCode.substring(6) 
                  : code + "-" + record.jobCode.substring(6)
          );
      }

        

        console.log("Updated mergedJobCodes:", mergedJobCodes);
        for (const order of selectedJobCodes) {
          console.log(" ikinci update için open orderı", order)
          const jobCode = order.includes("-") ? order.substring(0,5)  + "-"  +  record.jobCode.substring(6) :  order + "-" + record.jobCode.substring(6)
          console.log("üretilen 2. jobocde",jobCode);
          const filteredData = openOrderData.find(item => !item.isDeleted && item.orderType === "order" && item.jobCode === jobCode);
          const filteredDataSimilar = openOrderData.find(item => !item.isDeleted  && item.orderType === "order" && item.jobCode.substring(0,5) === order.substring(0,5));
          const filteredDataSame = openOrderData.find(item => !item.isDeleted  && item.orderType === "order" && item.jobCode === order.substring(0,5));

          console.log("filteredData",filteredData)
          //varsa update geçilcek
          if(filteredData !== undefined){
            const updateOpenOrderItem = {
              isSetup:true,
              setupName:record.jobCode.substring(6),
              jobCode :jobCode,
              machine: xmlInfo.machine,
              stock:record.stock ? record.stock : [],
              wastage:record.wastage ? record.wastage : [] ,             
              isAbkant : priorityAbkant ===  999999999 ? true : false,

              
              ...(selectedJobCodes && selectedJobCodes.length >= 2 && record?.orderType === "order" && { 
                mergedJobCodes: mergedJobCodes,
                mergedCompanyNames: selectedCompanies
            })

           
            };
  
            console.log("updateOpenOrderItem birleşen işler burası", updateOpenOrderItem, filteredData.key);
  
            try {
              const resultx = await FirebaseService.updateOpenOrder(filteredData.key, updateOpenOrderItem);
              updateResults.push(resultx); 
            } catch (error) {
              console.error("Error updating record:", error);
              updateResults.push(false); 
            }
          }
          else{

            let mergedJobCodes = selectedJobCodes.map((code) => 
              code.includes("-") ? code.substring(0,5)  + "-"  +  record.jobCode.substring(6) : code + "-" + record.jobCode.substring(6)
            );
  
            console.log("Updated mergedJobCodes:", mergedJobCodes);

            const newOrder = {
              firstTransactionDate: currentDate,
              firstUserId: localStorage.getItem("uid"),
              programmerUserId: localStorage.getItem("uid"),
              isDeleted: false,
              programmerName: `${localStorage.getItem("name")} ${localStorage.getItem("surname")}`,
              companyName: filteredDataSimilar.companyName,
              projectCode: filteredDataSimilar.projectCode,
              orderType: "order",
              isSetup: true,
              isOffer: false,
              isWaiting: true,
              isOfferApproved: false,
              noOfferApprovedDescription: null,
              noQrScanDescription: null,
              isOfferSetupUpdate: false,
              isOfferChange: false,
              isOfferDenied: false,
              isChangedProgrammer: false,
              isShipment: false,
              shipmentPiece: 1,
              invoiceNumber: null,
              setupName: record.jobCode.substring(6),
              deadline:filteredDataSimilar.deadline,
              jobCode: jobCode,
              stock:record.stock ? record.stock : [],
              wastage:record.wastage ? record.wastage : [] ,
              isOfferable:false,
              isAbkant:false,
              machine: xmlInfo.machine,
              ...(selectedJobCodes && selectedJobCodes.length >= 2 && record?.orderType === "order" && { 
                mergedJobCodes: mergedJobCodes,
                mergedCompanyNames: selectedCompanies
            })
             
            };
          
            console.log("üretilne yeni oopen order ",newOrder)
          
  
            try {
              const resultx = await FirebaseService.addRecording(parsCollections.openOrder, newOrder);
              if (filteredDataSame) {
                try {
                  console.log("filteredDataSame",filteredDataSame)
                  const resultDelete = await FirebaseService.deleteOpenOrderData(filteredDataSame.key);
                  console.log("Silme işlemi başarılı:", resultDelete);
                } catch (error) {
                  console.error("Silme işlemi sırasında hata:", error);
                }
              } else {
                console.log("Silinecek veri bulunamadı.");
              }
              updateResults.push(resultx); 
            } catch (error) {
              console.error("Error updating record:", error);
              updateResults.push(false); 
            }
          }

/*
          //parça ekleme
          for (const part of setupDataa.parts) { 
            
            try {
             
              if (part.jobCode === jobCode) {
                console.log("Döngü atlandı çünkü record.jobCode mevcut:", part.jobCode,jobCode);
                continue;
              }
             
              console.log("eski part",part)
              let updatedPart= 
              { ...part,
                operations: (part.operations && Array.isArray(part.operations)) 
                ? part.operations.map(operation => ({
                    processName: operation,
                    isContractManufacturing: false,
                    isCompleted: false,
                    isQrScan: false,
                    firstUserNameSurname: null,
                    firstUserId: null
                  }))
                : [], 
                firstTransactionDate:new Date(),
                jobCode:jobCode,
                companyName: filteredData?.companyName 
                ? filteredData.companyName 
                : filteredDataSimilar?.companyName 
                ? filteredDataSimilar.companyName 
                : null,
                            } 
              console.log("yeni part",updatedPart)
            
                let resultPart= await FirebaseService.addRecording( parsCollections.parts, updatedPart);
                if (resultPart) {
                  console.log(`Part with jobCode ${part.jobCode}, partNo ${part.partNo}, and customerName ${part.customerName} successfully updated.`);
                } else {
                  console.log(`Failed to update part with jobCode ${part.jobCode}, partNo ${part.partNo}, and customerName ${part.customerName}.`);
                }
             
            } catch (error) {
              console.error(`Error updating part with jobCode ${part.jobCode}, partNo ${part.partNo}, and customerName ${part.customerName}:`, error);
            }
          }
*/
           
        
        }

         //fason üretime ekleme alanı 
      const groupedParts = {};

      const customerJobCodeMap = {};
      selectedCompanies.forEach((company, index) => {
        customerJobCodeMap[company] = mergedJobCodes[index];
      });
   //   console.log("customerJobCodeMap selectedJobCodes",customerJobCodeMap,mergedJobCodes,selectedJobCodes)

      updatedParts.forEach((part) => {
        const { partNo, operations, customerName, jobCode } = part;
        const findJobCode = customerJobCodeMap[customerName];
        operations.forEach((operation) => {
          const key = `${findJobCode}-${operation.processName}`;
      
          console.log("key",key)
          if (!groupedParts[key]) {
            groupedParts[key] = {
              operation: operation, 
              customerName: customerName ? customerName : null,
              parts: [], 
              jobCode: findJobCode
            };
          }
      
          groupedParts[key].parts.push({
            partCode: part.partNo,
            piece: part.piece,
            unitPrice: null,
            profit: null,
          //  customerName: part.customerName,
          });
        });
      }); 
      
      console.log('Grouped Parts:', groupedParts);
      Object.values(groupedParts).forEach(async (group) => {
          console.log("grıp",group)
      
          if (group.operation.processName && group.operation.processName.toLowerCase() === "abkant") {
            console.log("Abkant prosesi atlandı:", group.operation.processName);
            return; 
          }

          const data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
            companyName: group.customerName ? group.customerName : null,
            supplierName: null,
            jobCode: group.jobCode,
            projectCode: group.projectCode ? group.projectCode : null,
            deadline: null,
            openOrderDeadline: new Date(record.deadline.seconds * 1000),
            processName: group.operation.processName,
            isContractManufacturing: false,
            isShipment: false,
            isRequestCompleted: false,
            parts: group.parts, 
            isCompleted:false,
            code: generatedCode.code,
            invoiceNumber:null,
            depotName :  plates[0].depotName || null,
          };
          console.log("fason data",data)
    
          try {
            const result = await FirebaseService.addRecording(parsCollections.contractManufacturing, data);
            if (result.success) {
           
              console.log("Fason üretime başarıyla kaydedildi")
              const ids = await getOneSignalPlayerIdsBuyer();
              const userIds = await getOneSignalUserIdsBuyer();
              const sentUserIdsMap = new Map();
              userIds.forEach(userId => {
                sentUserIdsMap.set(userId, false);
              });
          
          const sentUserIds = Object.fromEntries(sentUserIdsMap);
          //console.log("sentUserIds mapppp", sentUserIds);
              if (ids.length > 0) {
                  const oneSignalResult = await OneSignalService.sendNotification("", "Fason içeren Açık Sipariş", ids);
                  console.log("OneSignal notification result:", oneSignalResult);
                  const data = {
                    firstTransactionDate: currentDate,
                    firstUserId: localStorage.getItem("uid"),
                    isDeleted: false,
                    sentUserIds:sentUserIds,
                    firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
                    unitName:localStorage.getItem('userUnit'),
                    title:"Fason içeren Açık Sipariş",
                    companyName:group.companyName ?group.companyName : null ,
                    jobCode:group.jobCode ? group.jobCode : null,
                    projectCode:group.projectCode ? group.projectCode : null,
                    machine:null,
                    type:null,
                    notice:"Fason içeren açık sipariş yüklenmiştir, kontrol ediniz.",
                    description:null

                  };
                  const result = await FirebaseService.addRecording(parsCollections.notifications,data);
                  console.log("result",result)
              } else {
                  console.log("No OneSignal Player IDs found.");
              }


            } 
            else {   
              console.log(result.message);
              /*
              NotificationService.openErrorNotificationLongTime({
                title: 'İşlem Başarısız',
                description: "Başarısız. Seçtiğiniz firma ile xml'deki müşteri eşleşmememiştir. Fason kaydedilememiştir.",
                placement: 'topRight'
              });*/
              setLoading(false);
              return;
            }
          } catch (error) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
            setLoading(false);
            return;
          }   
      });

        const allUpdatesSuccessful = updateResults.every(result => result.success === true);
        console.log("updateResults",updateResults)
        console.log("allUpdatesSuccessful",allUpdatesSuccessful, "en sonki looop")
        if (allUpdatesSuccessful) {   
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: "Başarıyla kaydedilmiştir.",
            placement: 'topRight'
          });
          setLoading(false);
          
          setTimeout(() => {
            navigate("/programmerpage");
          }, 500);
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
            placement: 'topRight'
          });
          setLoading(false);
        }

      } 
    }

        
  }; 
  const openPdf = () => {
    if (setupDataa.documentUrl) {
      window.open(setupDataa.documentUrl, '_blank'); 
    } else {
      console.error("PDF URL bulunamadı.");
    }
  };

   return ( 
    <>
    {isLoading ? (
      <> Yükleniyor.. </>
      ) : (
      
      <Form
    name="basic"
    labelCol={{
    span: 4,
    }}
    wrapperCol={{
    span: 20,
    }}
    style={{
    maxWidth: "100%",
    padding:"5% 5% 0 5%"
    }}
    initialValues={{
    remember: true,
    }}
    onFinish={onFinish}
   // onFinishFailed={onFinishFailed}
    autoComplete="off"
>


    <div style={{ display: "flex", flexDirection: "column",}}>
  
    <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}>Yerleşimler</h2>
          
    <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Firma</p>
          {record && record.companyName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
          {record && record.jobCode}
        </div>


        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Setup İsmi</p>
          {xmlInfo && xmlInfo.setupName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Genel Açıklama Ekle </p>
          <Form.Item name="description" >
             <Input  className="input-style"   placeholder="" />
           </Form.Item>  
        </div>

       
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>
          <Form.Item
              name="isScrapDelivery">
              <Checkbox   onChange={() => setIsScrapDelivery(!isScrapDelivery)} style={{fontWeight:"500"}}>
              Hurda Teslim
              </Checkbox>
            </Form.Item>
          </p>
         
        </div>   
      </div>    

        <div style={{marginTop:"20px",}}>
            {plates.map((plate, plateIndex) => (          
              <>
                <div className="setup-item-plates" key={plateIndex} style={{border:"1px solid", borderColor:colors.blue, justifyContent:"space-between", textAlign:"left"}}>
                  <img src={plate.imageUrl}  alt={plate.imageFileName} style={{ width: '500px', height:"300px" }} />

                   <div style={{display:"flex"}}>
                      <div>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Program No:</strong> {plate.programNo}</p>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Ebat:</strong> {plate.height} x {plate.width} x {data.thickness} {plate.unit}</p>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Süre:</strong> {plate.processingTime} </p>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Kg (Plaka Ağırlığı):</strong> {plate.kg}</p>

                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Adet:</strong> {plate.piece} </p> 
                      {/*  <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Fire Kg:</strong> </p>*/} 

                      <Form.Item
                        name={`description-${plateIndex}-${plate.programNo}`}
                      // label="Açıklama"
                      >
                      <Input.TextArea
                          placeholder="Açıklama Girebilirsiniz"
                          style={{ width: '250px', height: '80px', borderColor: "#1A446C" }}
                        />                      
                        </Form.Item>
                      </div> 

                   </div>     
                       


                       
                   <div style={{border:"1px solid", borderColor:colors.blue, padding:"5px 20px", width:"250px"}}>
                    <p style={{color:colors.blue, fontWeight:"bold"}}>Fire Ekle</p>

                    {additionalContents[plateIndex]?.map((fireItem, contentIndex) => (
                  <div style={{ display: "flex" }} key={contentIndex}>
                    <Form.Item
                      name={`width-${plateIndex}-${contentIndex}-programNo-${plates[plateIndex].programNo}`}
                     
                      rules={[
                        { pattern: /^\d+$/, message: "Sadece rakam girişi yapınız." },
                        { required: true, message: "En girmek zorunludur." },

                        {
                          validator: (_, value) => {
                            if (value && parseInt(value) > plate.width) {
                              return Promise.reject(new Error("En değeri plakanın genişliğinden büyük olamaz."));
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <Input style={{ borderRadius: 0, borderColor: "#1A446C80", width: "70px" }} placeholder="En" />
                    </Form.Item>

                    <Form.Item
                      name={`height-${plateIndex}-${contentIndex}-programNo-${plates[plateIndex].programNo}`}
                      rules={[
                        { pattern: /^\d+$/, message: "Sadece rakam girişi yapınız." },
                        { required: true, message: "Boy girmek zorunludur." },

                        {
                          validator: (_, value) => {
                            if (value && parseInt(value) > plate.height) {
                              return Promise.reject(new Error("Boy değeri plakanın uzunluğundan büyük olamaz."));
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <Input style={{ borderRadius: 0, borderColor: "#1A446C80", width: "70px" }} placeholder="Boy" />
                    </Form.Item>

                    <Form.Item
                      name={`number-${plateIndex}-${contentIndex}-programNo-${plates[plateIndex].programNo}`}
                      rules={[
                        { pattern: /^\d+$/, message: "Sadece rakam girişi yapınız." },
                        
                      ]}
                      initialValue={1} 
                    >
                      <Input style={{ borderRadius: 0, borderColor: "#1A446C80", width: "70px" }} placeholder="Adet" />
                    </Form.Item>

                    <Button onClick={() => removeAdditionalContent(plateIndex, contentIndex)}>-</Button>
                  </div>
                ))}
                 

                <Form.Item style={{ textAlign: "center" }}>
                  <Button onClick={() => addAdditionalContent(plateIndex)}>+</Button>
                </Form.Item>


                  </div>


                </div>
                
             </>   
            ))}
        </div>
        <div style={{display:"flex",marginTop:"20px", justifyContent:"end"}}>
        <Button onClick={openPdf} style={{marginTop:"20px", backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Pdf Görüntüle</Button>

      <Form.Item style={{textAlign:"right",marginTop:"20px"}}>
          <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Kaydet</Button>
      </Form.Item>
        </div>
       
         {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

    </div>
      </Form>
        
       )}
   </>

  )};
export default SetupPlatesDetail;


/*
 <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
        <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
             <Form.Item
                name="deadline"   >
                <DatePicker   className="input-style"   style={{ width: '100%' }} placeholder="Teslim Tarihi" format="DD-MM-YYYY" disabledDate={disabledDate}  />
             </Form.Item>
        </div>

*/
