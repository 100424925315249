import React,{useEffect,useState} from "react";
import { Table, Button,Modal, Progress } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';

const PersonnelPerfomanceList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const items = location.state && location.state.record;
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());

  const [selectedPeriod, setSelectedPeriod] = useState('daily'); // daily, monthly, yearly
  useEffect(() => {
   // console.log("items",items);
  
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getUserData();
      const filteredData = data.filter(item => !item.isDeleted && (item.userUnit.includes("abkant") || item.userUnit.includes("lazer")   || item.userUnit.includes("teknik ressam") ));
      const sortedData = filteredData.sort((a, b) => {
        const unitA = a.userUnit.join(', ');
        const unitB = b.userUnit.join(', ');
        return unitA.localeCompare(unitB);
      })
      

      setData(sortedData);
      
    };

    fetchData();
  }, []);

  const filterPerformanceByPeriod = (performance, period) => {
    console.log("performance", performance, "period", period);
    if (!performance) return [];

    const today = moment().startOf('day');  
    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().endOf('month');
    const startOfYear = moment().startOf('year');
    const endOfYear = moment().endOf('year');
    return Object.values(performance).filter(perf => {
        if (!perf.firstTransactionDate) {
            console.log("Missing firstTransactionDate in:", perf);
            return false;
        }

        const perfDate = moment(perf.firstTransactionDate.toDate());

        if (!perfDate.isValid()) {
            console.log("Invalid date format:", perf.firstTransactionDate);
            return false;
        }

        switch (period) {
            case 'daily':
                return perfDate.isSame(today, 'day');  // Aynı gün mü?
            case 'monthly':
                return perfDate.isBetween(startOfMonth, endOfMonth, null, '[]');  // Ay içinde mi?
            case 'yearly':
                return perfDate.isBetween(startOfYear, endOfYear, null, '[]');  // Yıl içinde mi?
            default:
                return true;
        }
    });
};

  const calculateAveragePercentageLazer = (performance, period = 'all') => {
    if (!performance) return 0;
  
    const filteredData = filterPerformanceByPeriod(performance, period);
    console.log("filteredData lazer",filteredData)
    if (filteredData.length === 0) return 0;
  
    const total = filteredData.reduce((sum, perf) => sum + (perf.lazerPerformans || 0), 0);
    const average = total / filteredData.length;

    return average;
  };

  const calculateAveragePercentageAbkant = (performance, period = 'all') => {
    if (!performance) return 0;
  
    const filteredData = filterPerformanceByPeriod(performance, period);
    console.log("filteredData abkant",filteredData)

    if (filteredData.length === 0) return 0;
  
    const total = filteredData.reduce((sum, perf) => sum + (perf.abkantPerformans || 0), 0);
    const average = total / filteredData.length;
    return average;
  };

  const calculateAveragePercentageProgrammer = (performance, period = 'all') => {
    if (!performance) {
      console.error("Performance data is null or undefined");
      return 0;
    }
  
    if (!Array.isArray(performance) || performance.length === 0) {
      console.error("Invalid performance data");
      return 0;
    }
  
    const filteredData = filterPerformanceByPeriod(performance, period);
    console.log("filteredData programmer",filteredData)

    if (filteredData.length === 0) return 0;
  
    const totalSeconds = filteredData.reduce((sum, perf) => {
      if (!perf.setupTime) {
        console.error("Invalid setupTime format", perf);
        return sum;
      }
      const timeParts = perf.setupTime.split(':');
      if (timeParts.length !== 3) {
        console.error("Invalid setupTime format", perf.setupTime);
        return sum;
      }
      const seconds = parseInt(timeParts[0]) * 3600 + parseInt(timeParts[1]) * 60 + parseInt(timeParts[2]);
      return sum + seconds;
    }, 0);
  
    const totalMinutes = totalSeconds / 60;
    const averagePercentage = (totalMinutes / 500) * 100;
  
    return averagePercentage;
  };

  
const getColor = (averagePercentage) => {
  if (averagePercentage > 80) return 'green';
  if (averagePercentage > 50) return 'yellow';
  if (averagePercentage > 30) return 'orange';
  return 'red';
};


  const columns = [
    {
      title: ' ',
      dataIndex: '',
      key: '',
     width:100,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>
      {record.imageUrl ? (
      <img alt="" src={record.imageUrl} style={{ marginRight: '10px', width: '30px',height:"30px", borderRadius:"100%",objectFit:"cover" ,marginTop:"20px"}} />
        ) : (
          <img alt="" src="/images/menuusericon.png" style={{ marginRight: '10px', width: '30px',height:"30px", borderRadius:"100%",objectFit:"cover" , background:"#1A446C"}} />
        )}        
          </span>,
      },

    {
      title: 'Kullanıcı Adı',
      dataIndex: 'offerJobCode',
      key: 'offerJobCode',
     width:300,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{record.name}  {record.surname} </span>,
  },
    {
      title: 'Birim',
      dataIndex: 'userUnit',
      key: 'userUnit',
      width:300,
      sorter: (a, b) => {
        // userUnit dizisinin ilk elemanını karşılaştır
        const unitA = a.userUnit.join(', ');
      const unitB = b.userUnit.join(', ');
      return unitA.localeCompare(unitB);
      },
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>  {text.join(', ')} ,
      </span>,
    },
    {
    title: 'Performans',
    dataIndex: 'performance',
    key: 'performance',
    render: (text, record) => {
      const averagePercentage = record.userUnit.includes("lazer")
        ? calculateAveragePercentageLazer(record.lazerPerformance, selectedPeriod)
        : record.userUnit.includes("teknik ressam")
        ? calculateAveragePercentageProgrammer(record.setupPerformance, selectedPeriod)
        : calculateAveragePercentageAbkant(record.abkantPerformance, selectedPeriod);

      const formattedPercentage = parseFloat(averagePercentage.toFixed(2));
      const color = getColor(formattedPercentage);

      return (
        <Progress
          percent={formattedPercentage}
          status={record.isDeleted ? 'exception' : 'normal'}
          strokeColor={color}
          strokeWidth={15}
        />
      );
    },
  },
  ];

  

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3  style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>Personel Performans Listesi</h3>
        <div style={{ marginBottom: '20px', display: 'flex', gap: '10px', float:"right" }}>
            <Button 
              type={selectedPeriod === 'daily' ? 'primary' : 'default'} 
              onClick={() => setSelectedPeriod('daily')}
            >
              Günlük
            </Button>
            <Button 
              type={selectedPeriod === 'monthly' ? 'primary' : 'default'} 
              onClick={() => setSelectedPeriod('monthly')}
            >
              Aylık
            </Button>
            <Button 
              type={selectedPeriod === 'yearly' ? 'primary' : 'default'} 
              onClick={() => setSelectedPeriod('yearly')}
            >
              Yıllık
            </Button>
          </div>
          <Table
            locale={{
              emptyText: 'Henüz personel perfomans bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={data}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />

       
    </div>

  };
  export default PersonnelPerfomanceList;

  