import React, { useEffect, useState } from "react";
import { Button, Checkbox, Modal, Spin, Table } from 'antd';
import { useLocation } from 'react-router-dom';
import { colors, parsCollections } from "../../../environments/environment";
import moment from 'moment';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';

const WantedOfferView = () => {
  const location = useLocation();
  const record = location.state && location.state.record;
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [visible, setVisible] = useState(false);
  const [deliveryDates, setDeliveryDates] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  
console.log("record",record)

  useEffect(() => {
    if (record && record.checkedRequestedProductInfo) {
        const allCompanies = new Set();
        const deliveryDates = {};

        if (record.incomingExcelList) {
          record.incomingExcelList.forEach((item) => {
                deliveryDates[item.supplierName] = item.deadline;
            });
        }
        setDeliveryDates(deliveryDates);
        

        const formattedData = record.checkedRequestedProductInfo.map((item, index) => {
            item.companiesAndPrice.forEach(entry => allCompanies.add(entry.company));
            const lowestPriceEntry = item.companiesAndPrice.reduce((prev, curr) => prev.price < curr.price ? prev : curr, item.companiesAndPrice[0]);
            const highestPriceEntry = item.companiesAndPrice.reduce((prev, curr) => prev.price > curr.price ? prev : curr, item.companiesAndPrice[0]);
            return {
                key: index,
                productDetails: `${item.quality} ${item.surface} ${item.thickness}mm ${item.width} x ${item.height}`,
                lowestPrice: lowestPriceEntry.price,
                highestPrice: highestPriceEntry.price,
                companiesAndPrice: item.companiesAndPrice.reduce((obj, entry) => {
                    obj[entry.company] = entry.price;
                    return obj;
                }, {})
            };
        });


        const totalPrices = {};
        formattedData.forEach(item => {
            for (const [company, price] of Object.entries(item.companiesAndPrice)) {
                if (!totalPrices[company]) {
                    totalPrices[company] = 0;
                }
                totalPrices[company] += price;
            }
        });

        const totalRow = {
            key: 'total',
            productDetails: 'Toplam Fiyat',
            lowestPrice: '',
            highestPrice: '',
            companiesAndPrice: Object.keys(totalPrices).reduce((obj, company) => {
                obj[company] = totalPrices[company];
                return obj;
            }, {})
        };

        formattedData.push(totalRow);
      

        const companyColumns = Array.from(allCompanies).map(company => {
            const deadlineTimestamp = deliveryDates[company];
            const deadline = deadlineTimestamp 
                ? moment.unix(deadlineTimestamp.seconds).format('DD/MM/YYYY') 
                : 'No Deadline';

            return {
                title: (
                    <div style={{textAlign:"center"}}>
                        <Checkbox
                            style={{ marginRight: '10px' }}
                            checked={selectedCompany === company}
                            onChange={(e) => handleCheckboxChange(company, e.target.checked)}
                            disabled={record.acceptExcelDocumentUrl ?  true : false}
                        />
                        {company}
                        <div style={{ fontSize: '12px', marginTop: '5px' }}>
                            Teslim Tarihi: {deadline}
                        </div>
                    </div>
                ),
                dataIndex: ['companiesAndPrice', company],
                key: company,
                width: 200,
                render: (price, record) => {
                  let backgroundColor = 'white'; 

                  if (record.key !== 'total') {
                      if (price === record.lowestPrice) {
                          backgroundColor = "#04d108"; 
                      } else if (price === record.highestPrice) {
                          backgroundColor = '#fc0317'; 
                      } else {
                          backgroundColor = '#fcf403'; 
                      }
                  }
                    return (
                        <div style={{
                            backgroundColor: backgroundColor,
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            padding: "10px",
                            fontFamily: "futura",
                            borderRadius:"10px",
                            width:"100px"
                        }}>
                            {price} TL
                        </div>
                    );
                }
            };
        });

        const fixedColumns = [
            {
                title: ' ',
                dataIndex: 'productDetails',
                key: 'productDetails',
                fixed: 'left',
                width: 220,
                render: (text, record) => {
                    return (
                        <div className="custom-cell">
                            {text}
                        </div>
                    );
                }
            }
        ];

        setColumns([...fixedColumns, ...companyColumns]);
        setData(formattedData);
    }
}, [record, selectedCompany]);


  const handleCheckboxChange = (company, checked) => {
    setSelectedCompany(checked ? company : '');
  };

  const convertToDate = (timestamp) => {
    const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    return new Date(milliseconds);
  };
  
  const handleOk = async () => {
    //setLoading(true);

    console.log("Gelecek hammadeye gidiyor", record);
    const deadlineTimestamp = deliveryDates[selectedCompany];
    const deadlineDate = convertToDate(deadlineTimestamp);

    try {
      for (const checkedItem of record.items) {
        const { productId } = checkedItem;
        
        console.log("checkedItem", checkedItem);
        
        const existingItem = record.checkedRequestedProductInfo.find(item => item.id === productId);
        
        if (existingItem) {
          
          checkedItem.companiesAndPrice = [
            ...(existingItem.companiesAndPrice || []),  
            ...(checkedItem.companiesAndPrice || [])    
          ];
          
          checkedItem.quality = existingItem.quality || checkedItem.quality;
          checkedItem.surface = existingItem.surface || checkedItem.surface;
          checkedItem.thickness = existingItem.thickness || checkedItem.thickness;
          checkedItem.width = existingItem.width || checkedItem.width;
          checkedItem.height = existingItem.height || checkedItem.height;
          checkedItem.unitKilo = existingItem.unitKilo || checkedItem.unitKilo;
          checkedItem.totalKilo = existingItem.totalKilo || checkedItem.totalKilo;
          checkedItem.totalPiece = existingItem.piece || checkedItem.piece;

        
        } else {
          console.log("böyle bir id yok");
        }
      }
      
      console.log("record yeni items", record.items);
      
      
      for (const item of record.items) {
        console.log("item",item)
        const currentDate = new Date();
        const {
          quality,
          thickness,
          width,
          height,
          surface,
          piece,
          jobCode,
          unitKilo,
          code,
          id,
          minPiece,
          companiesAndPrice, 
          companyName,
          totalPiece
        } = item;
  console.log("son item",item)

        const companyInfo = companiesAndPrice.find(company => company.company === selectedCompany);
        const selectedCompanyPrice = companyInfo ? companyInfo.price : null;
      
        const data = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          firstUserName: localStorage.getItem("name"),
          firstUserSurname: localStorage.getItem("surname"),
          isDeleted: false,
          quality: quality,
          thickness: thickness,
          width: width,
          height: height,
          surface: surface,
          piece: piece,
          jobCode: jobCode ? jobCode : null,
          unitKilo: unitKilo,
          totalKilo: 0, 
          stockId : record.isSendSetup?  id : null,
          supplierName: selectedCompany,
          offerJobCode: record.offerJobCode,
          deadline: deadlineDate,
          companyName: companyName,
          problemDescription: null,
          problemImageUrl: null,
          problemDate: null,
          isContinue: true,
          minPiece: minPiece ? minPiece : 0,
          code:code ? code : null,
         // price: selectedCompanyPrice,
          price: (selectedCompanyPrice / totalPiece) * piece
        };

        const dataWithPrice = { 
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          isDeleted: false,
          quality: quality,
          thickness: thickness,
          width: width,
          height: height,
          surface: surface,
          piece: piece,
          jobCode: jobCode ? jobCode : null,
          unitKilo: unitKilo,
          totalKilo: 0,  
          supplierName: selectedCompany,
          offerJobCode: record.offerJobCode,
          deadline: deadlineDate,
          companyName: companyName,
          minPiece: minPiece ? minPiece : 0,
          code:code ? code : null,
          productType: record.productType ,
          paymentMethod: record.paymentMethod ,
          stockId : record.isSendSetup?  id : null,
          invoicePrice:null ,
          // price: selectedCompanyPrice 
          price: (selectedCompanyPrice / totalPiece) * piece
        };
      
        console.log("dataWithPrice", dataWithPrice);
        console.log("data",data)
  
        const result = await FirebaseService.addRecording(parsCollections.futureRaw, data);
        if (result.success) {

         const resultPrice = await FirebaseService.addRecording(parsCollections.invoicePurchase, dataWithPrice);
        if (resultPrice.success) {
         console.log("İşlem Başarılı:", result.message);
            
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: 'Gelecek hammadeye başarıyla eklendi. Excel dosyası başarıyla yüklendi.',
            placement: 'topRight'
          });
      
      
          setLoading(false);
          setTimeout(() => {
            navigate('/wantedofferlist')
          }, 500);
          }
          else{
            NotificationService.openErrorNotification({
                    title: 'İşlem Başarısız',
                    description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.2",
                    placement: 'topRight'
                  });
                  setLoading(false);

          }

                
          
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.3",
            placement: 'topRight'
          });
          setLoading(false);

        } 
        
        
      }
    } catch (error) {
      console.error("İşlem sırasında hata oluştu:", error);

      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.4",
        placement: 'topRight'
      });
      setLoading(false);

    }
    
  };
  

  const saveChanges = async () => {
    try {

      if(!selectedCompany ){
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: 'Lütfen firma seçiniz.',
          placement: 'topRight',
        });
        setLoading(false);
        return;
      }

      let selectedCompanyUrl = '';
      record.incomingExcelList.some(item => {
        if (item.supplierName === selectedCompany) {
          selectedCompanyUrl = item.documentUrl;
          return true; 
        }
        return false;
      });
  
      console.log("Selected company URL:", selectedCompanyUrl);
  
    /*
      const selectedDocument = excelFile;
      const fileName = `${items.offerJobCode}.xlsx`;
     
      const folderName = 'acceptOffers';
      const downloadURL = await StoregeService.uploadDocumentAndGetURL(folderName, selectedDocument, fileName);
     
      console.log('Document uploaded successfully. Download URL:', downloadURL);
*/
       const result = await FirebaseService.updatePurchaseRequestAcceptExcellData(record.key,selectedCompanyUrl);
       if (result) {
        console.log("update oldu")
          setVisible(true);
       } else {
         console.log("update olmadı")
       }
   
     
  
    } catch (error) {
      console.error('Error saving changes:', error);
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
        placement: 'topRight'
      });
      setLoading(false);

    }
  };


  const handleCancel = () => {
    setVisible(false);
};

const columnsFutureRaw = [
  {
    title: 'Kalite',
    dataIndex: 'quality',
    key: 'quality',
    width: 200,
    sorter: (a, b) => a.quality.localeCompare(b.quality), 
    render: (text) => <span>{text}</span>,
  },

    {
      title: 'Kalınlık',
      dataIndex: 'thickness',
      key: 'thickness',
      width: 200,
      sorter: (a, b) => a.thickness - b.thickness,
      render: (text) => <span>{text}</span>,
    },

   
    {
      title: 'En',
      dataIndex: 'width',
      key: 'width',
      width: 200,
      sorter: (a, b) => a.width - b.width,
      render: (text) => <span >{text}</span>,
    },
    {
      title: 'Boy',
      dataIndex: 'height',
      key: 'height',
      width: 200,
      sorter: (a, b) => a.height - b.height,
      render: (text) => <span >{text}</span>,
    },

    {
      title: 'Yüzey',
      dataIndex: 'surface',
      key: 'surface',
      width: 200,
      sorter: (a, b) => a.surface.localeCompare(b.surface), 
      render: (text) => <span>{text}</span>,
    },

    {
      title: 'Adet',
      dataIndex: 'piece',
      key: 'piece',
      render: (text,record) => <span>{text}</span>,
      width: 200,
      sorter: (a, b) => a.piece - b.piece,
    },    
       
  
  ];

  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '94vh',
      padding: "3%",
    }}>
      <h3 style={{
        backgroundColor: "white",
        color: colors.blue,
        fontWeight: "700",
        border: "none",
        float: "left"
      }}>Yüklenen Teklifler</h3>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: 800 }}
        style={{ marginTop: '20px' }}
        locale={{
          emptyText: 'Henüz eklemediniz...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
      />
         <Button
            style={{ 
              backgroundColor: record.acceptExcelDocumentUrl ? colors.gray: colors.blue , 
              color: "white", fontWeight: "500", marginTop:"30px", float:"right" }}
              disabled={record.acceptExcelDocumentUrl ?  true : false}
            onClick={() => saveChanges()}
            >
          Kaydet
        </Button>
        

    <div>           
          <Modal
                  title="Gelecek Hammade"
                  visible={visible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  okText="Evet, Gelecek Hammadeye ekle"
                  cancelText="Vazgeç"
                  className="columnsStockExcess popup-modal-button"
                
              >
              <div> 
                <p>Gelecek Hammade listesine eklemek istediğinize emin misiniz? </p>
                <Table
                   dataSource={record.checkedRequestedProductInfo}
                   locale={{
                    emptyText: 'Henüz eklemediniz...',
                    filterReset: 'Sıfırla',
                    filterTitle: 'Filtre Menüsü',
                    selectAll: 'Hepsini Seç',
                    selectInvert: 'Tersini Seç',
                    selectionAll: 'Tümünü Seç',
                    sortTitle: 'Sıralama',
                    triggerDesc: 'Azalan sıralama için tıklayın',
                    triggerAsc: 'Artan sıralama için tıklayın',
                    cancelSort: 'Sıralamayı iptal etmek için tıklayın',
                  }}
                    columns={columnsFutureRaw}
                    showHeader={true}
                    pagination={false} 
                    className="custom-news-table"
                    rowClassName="custom-news-row"
                />
            </div>
            {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
           </Modal>
           

        </div>
       
    </div>
  );
};

export default WantedOfferView;
