import React, { useState,useEffect } from "react";
import { Button, Form, Modal, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import PurchaseRequestAdd from "../purchaseRequests/purchaseRequestAdd";
import StockFilter from "../stock/stockFilter";
import AlarmListLimited from "../alarms/alarmListLimited";
import OpenOrderFirst from "../openOrders/openOrderFirst";
import Pdf from './ProgrammerPage.pdf';
import OpenOrderGroupListLimited from "../openOrders/openOrderGroupListLimited";
import WaitingOpenOrderGroupListLimited from "../openOrders/waitingOpenOrder/waitingOpenOrderGroupListLimited";
import WebNavbar from "../webNavbar";
import OffersGivenListLimited from "../openOrders/offersGiven/offersGivenListLimited";
import OffersGivenGroupListLimited from "../openOrders/offersGiven/offersGivenGroupListLimited";
import FirebaseService from "../../../services/firebaseService";
import AddStockOrWastage from "../stock/addStockOrWastage"

const ProgrammerPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handlePurchaseRequest = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setTimeout(() => {
      window.location.reload();
    }, 100);
    setIsModalVisible(false);
  };

  const handlePurchaseRequestAddFinish = () => {
    setIsModalVisible(false); 
  };


  const [isModalVisibleContract, setIsModalVisibleContract] = useState(false);

  const handleContractManufacturingAdd = () => {
    setIsModalVisibleContract(true);
  };

  const handleModalCancelContract = () => {
    setIsModalVisibleContract(false);
  };

  const handleContractManufacturingAddFinish = () => {
    setIsModalVisibleContract(false); 
  };  

  const [isModalVisibleOpenOrderFirst, setIsModalVisibleOpenOrderFirst] = useState(false);

  const handleOpenOrderFirst = () => {
    setIsModalVisibleOpenOrderFirst(true);
  };

  const handleModalCancelOpenOrderFirst = () => {
    setIsModalVisibleOpenOrderFirst(false);
  };

  const handleOpenOrderAddFinishFirst = () => {
    setIsModalVisibleOpenOrderFirst(false); 
  };


  const [isModalVisibleStock, setIsModalVisibleStock] = useState(false);

  const handleStock = () => {
    setIsModalVisibleStock(true);
  };

  const handleModalCancelStock= () => {
    setIsModalVisibleStock(false);
  };

  const handleStockFinish = () => {
    setIsModalVisibleStock(false); 
  };

  const navigate = useNavigate();
  const handleCheckCurrentPrice = () => {
    navigate('/checkcurrentpricelist')
  };


  const handleFutureRaw = () => {
    navigate('/futurerawlist')
  };


  const [isModalVisibleAddStockOrWastage, setIsModalVisibleAddStockOrWastage] = useState(false);

  const handleAddStockOrWastage = () => {
    setIsModalVisibleAddStockOrWastage(true);
  };

  const handleModalCancelAddStockOrWastage = () => {
    setIsModalVisibleAddStockOrWastage(false);
  };

  const handleAddStockOrWastageFinish = () => {
    setIsModalVisibleAddStockOrWastage(false); 
  };

  const [selectedFirst5, setSelectedFirst5] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
   const [items,setItems] = useState([])
   const [visible, setVisible] = useState(false);
   const [selectedJobCode, setSelectedJobCode] = useState('');
   const [selectedItem, setSelectedItem] = useState(null);
 
  const getFirst5Chars = (jobCode) => jobCode.slice(0, 5);

  const handleFirst5Change = (value) => {
      setSelectedFirst5(value);
      const filtered = items.filter((item) => getFirst5Chars(item.jobCode) === value);
      setFilteredItems(filtered);
  };
  

  useEffect(() => {
    const fetchData = async () => {
      localStorage.removeItem("depotName");
      localStorage.removeItem("stockFilters");
      localStorage.removeItem("wastageFilters");

      localStorage.removeItem("setupName");
      localStorage.removeItem("checkedItemsStock");
      localStorage.removeItem("checkedItemsWastage");

   
        const setupData = await FirebaseService.getSetupData();
        const data = setupData.filter(item => !item.isDeleted);
       setItems(data);
      

    };
    fetchData();
  }, []);

  

  const handleOk = async () => {
    console.log("Yönlendirme lazım", selectedJobCode);
    if (selectedJobCode) {
      console.log("Selected item", selectedItem);
     navigate('/operationdetailproductmanager', { state: { record: selectedItem , jobCode:selectedJobCode} });
    } else {
      console.log("Please select a job code.");
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onChange = (value) => {
    console.log("value",value)
    const item = items.find((code) => code.jobCode === value); // Seçilen iş koduna karşılık gelen item'i bul
    if (item) {
      setSelectedItem(item); // Seçilen item'i güncelle
      setSelectedJobCode(value); // Seçilen iş kodunu güncelle
    } else {
      setSelectedItem(null); // Hiçbir item bulunamazsa null yap
      setSelectedJobCode(null); // İş kodunu boş yap
    }
  };

  return (
    <div style={{padding:"3%"}}>
     <WebNavbar />
  
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
        <OpenOrderGroupListLimited />
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
        <WaitingOpenOrderGroupListLimited />
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
       <OffersGivenGroupListLimited />
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px", display:"block" }}>
        <div>
        <AlarmListLimited />
        </div>
      
        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: "10px" }}>
            <div style={{ gridColumn: "span 1", gridRow: "span 1" , margin:"10px"}}>
                <Button
                className="classic-button"
                onClick={handlePurchaseRequest}>
                Satın Alma Talep Et
                </Button>
                <Modal
                title="Satın Alma Talep Et"
                visible={isModalVisible}
                onCancel={handleModalCancel}
                footer={null}
                >
                <PurchaseRequestAdd onClose={handlePurchaseRequestAddFinish} />
                </Modal>
            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
                <Button 
                className="classic-button"
               onClick={handleOpenOrderFirst}>
                İş Emri Oluştur
                </Button>
                <Modal
                title="İş Emri Oluştur"
                visible={isModalVisibleOpenOrderFirst}
                onCancel={handleModalCancelOpenOrderFirst}
                footer={null}
                >
                <OpenOrderFirst onClose={handleOpenOrderAddFinishFirst} />
                </Modal>
            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1" , margin:"10px"}}>
                <Button
                className="classic-button"
                onClick={handleAddStockOrWastage}>
              Gelecek Hammade / Fire 
                </Button>
                <Modal
                title="Hammadde / Fire Girişi"
                visible={isModalVisibleAddStockOrWastage}
                onCancel={handleModalCancelAddStockOrWastage}
                footer={null}
                className="wide-popup"
                >
                <AddStockOrWastage onClose={handleAddStockOrWastageFinish} />
                </Modal>
            </div>
          

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
                <Button 
                className="classic-button"
               onClick={handleStock}>
                Güncel Stok
                </Button>
                <Modal
                title="Güncel Stok"
                visible={isModalVisibleStock}
                onCancel={handleModalCancelStock}
                footer={null}
                >
                <StockFilter />
                </Modal>
            </div>
            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button  
              className="classic-button"
              onClick={() =>   setVisible(true)}>
           Sevk Resmi Bas
            </Button>
        {/*<Modal
            title=""
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={null}
            className="operation-detail-popup"
          >
            <OperationDetail  record={recordForModal}  onClose={handleSetupFinish} />
      </Modal>*/}

         <Modal
      title="Görmek istediğiniz Setup'ı seçiniz"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      className="popup-modal-button"
      okText="Devam Et"
      cancelText="İptal"
    >
      <div>
        <Form.Item
          name="first5"
          rules={[
            {
              required: true,
              message: 'Lütfen iş kodu seçiniz!',
            },
          ]}
          style={{ marginBottom: "40px", marginTop: "30px" }}
        >
          <Select
          showSearch
            onChange={handleFirst5Change}
            placeholder="İş Kodu Seçiniz"
            suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
            options={[
              ...new Set(items.map((item) => getFirst5Chars(item.jobCode)))
            ].map((first5) => ({ label: first5, value: first5 }))}
            className="input-style"
            filterOption={(input, option) => 
              option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
            }
          />
        </Form.Item>

        {selectedFirst5 && (
          <Form.Item
            name="jobCode"
            rules={[
              {
                required: true,
                message: 'Lütfen iş kodu seçiniz!',
              },
            ]}
            style={{ marginBottom: "40px" }}
          >
            <Select
            showSearch
              placeholder="Setup Seçiniz"
              suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
              options={filteredItems.map((item) => ({
                label: item.jobCode,
                value: item.jobCode,
              }))}
              className="input-style"
              onChange={onChange}
              filterOption={(input, option) => 
                option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
              }
            />
          </Form.Item>
        )}
      </div>
        </Modal>

            </div>
        </div>

   
   
      </div>
    </div>
 
   
    </div>
  );
};

export default ProgrammerPage;




/*

  <div style={{ gridColumn: "span 1", gridRow: "span 1" , margin:"10px"}}>
                <Button
                className="classic-button"
                onClick={handleAddStockOrWastage}>
              Gelecek Hammade / Fire 
                </Button>
                <Modal
                title="Hammadde / Fire Girişi"
                visible={isModalVisibleAddStockOrWastage}
                onCancel={handleModalCancelAddStockOrWastage}
                footer={null}
                className="wide-popup"
                >
                <AddStockOrWastage onClose={handleAddStockOrWastageFinish} />
                </Modal>
            </div>

*/