import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import { useLocation } from "react-router-dom";
import { colors } from "../../../environments/environment";
import { Button, DatePicker,} from 'antd';
import { useNavigate } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";


const OfferReportingList = () => {
  const [chart1, setChart1] = useState([]);
  const [chart2, setChart2] = useState([]);
  const [chart3, setChart3] = useState([]);
    const navigate = useNavigate();
  
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  
  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(new Date());

  const location = useLocation();
  const record = location.state && location.state.record;

  const filterRecordsByDate = (records) => {
    return records.filter((item) => {
      const itemDate = new Date(item.firstTransactionDate); 
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
     
      console.log("strt",start)
      console.log("end",end)
      if (start && end) {
        return itemDate >= start && itemDate <= end;
      } else if (start) {
        return itemDate >= start;
      } else if (end) {
        return itemDate <= end;
      }
      return true; // Tarih filtresi yoksa tüm kayıtları döndür
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const filteredRecords = filterRecordsByDate(record);

      // İlk pie chart için veriler
      const approvedCount = filteredRecords.filter(item => item.isOfferApproved && item.orderType === 'offer').length;
      const deniedCount = filteredRecords.filter(item => item.isOfferDenied && item.orderType === 'offer').length;
      const revisedCount = filteredRecords.filter(item => item.isOfferChange && item.orderType === 'offer').length;

      const total = approvedCount + deniedCount + revisedCount;
      const safePercentage = (count) => (total > 0 ? (count / total) * 100 : 0);

      const chartData = [
        { name: "Onaylanan Teklifler", value: safePercentage(approvedCount) },
        { name: "Onaylanmayan Teklifler", value: safePercentage(deniedCount) },
        { name: "Revize İstenen Teklifler", value: safePercentage(revisedCount) }
      ];

      setChart1(chartData);

      // İkinci pie chart için veriler
      const priceChangeApproved = filteredRecords.filter(item => item.isOfferApproved && item.orderType === 'order' && item.isOfferChange && item.isOffer).length;
      const priceChangeDenied = filteredRecords.filter(item => item.isOfferDenied && item.orderType === 'offer' && item.isOfferChange && item.isOffer).length;

      const setupChangeApproved = filteredRecords.filter(item => item.isOfferSetupUpdate && item.isOfferApproved && item.orderType === 'order' && item.isOffer).length;
      const setupChangeDenied = filteredRecords.filter(item => item.isOfferSetupUpdate && item.isOfferDenied && item.orderType === 'offer' && item.isOffer).length;

      const revisedTotal = priceChangeApproved + priceChangeDenied + setupChangeApproved + setupChangeDenied;
      const safeRevisedPercentage = (count) => (revisedTotal > 0 ? (count / revisedTotal) * 100 : 0);

      const revisedChartData = [
        { name: "Fiyat Değişikliği Onaylanan", value: safeRevisedPercentage(priceChangeApproved) },
        { name: "Fiyat Değişikliği Onaylanmayan", value: safeRevisedPercentage(priceChangeDenied) },
        { name: "Setup Değişikliği Onaylanan", value: safeRevisedPercentage(setupChangeApproved) },
        { name: "Setup Değişikliği Onaylanmayan", value: safeRevisedPercentage(setupChangeDenied) }
      ];

      setChart2(revisedChartData);

      // Üçüncü pie chart için veriler
      const sales = filteredRecords.filter(item => item.isOfferApproved).length;
      const notSales = filteredRecords.filter(item => item.isOfferDenied).length;

      const revisedTotalChart3 = sales + notSales;
      const safeRevisedPercentageChart3 = (count) => (revisedTotalChart3 > 0 ? (count / revisedTotalChart3) * 100 : 0);

      const chart3 = [
        { name: "Satışa Dönenler", value: safeRevisedPercentageChart3(sales) },
        { name: "Satışa Dönmeyenler", value: safeRevisedPercentageChart3(notSales) },
      ];

      setChart3(chart3);
    };

    fetchData();
  }, [record, startDate, endDate]); 


  const setDateRange = (months) => {
    const end = new Date(); 
    const start = new Date();
   // console.log("end",end,"start",start)
    start.setMonth(end.getMonth() - months); 
    setStartDate(start.toISOString().split("T")[0]); 
    setEndDate(end.toISOString().split("T")[0]);
  };
  const COLORS = ["#4CAF50", "#F44336", "#FFC107", "#2196F3", "#FF5722"];


  const [selectedRange, setSelectedRange] = useState("");

  const handleChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedRange(value);
    setDateRange(value);
  };

  const goEditPage = (record) => {
    navigate('/salesmanpage', { state: { record } })
  };


  const handlePrint = () => {
    const printSection = document.getElementById("print-section");
  
    if (!printSection) {
      console.error("Print section not found!");
      return;
    }
  
    const printContents = printSection.innerHTML;
    const printWindow = window.open("", "_blank", "width=800,height=600");
  
    printWindow.document.write(`
      <html>
        <head>
          <title>Yazdır</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            .chart-item { margin: 20px; }
            h3 { color: ${colors.blue}; }
            /* Print için stil ayarları */
            @media print {
              .chart-item {
                width: 300px !important;
                margin: 10px !important;
              }
              .print-container {
                flex-direction: column;
                align-items: center;
              }
              @page {
                margin: 0.5cm;
              }
                .recharts-legend-wrapper{
                bottom:-60px !important;
              }

            }
          </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  };

  const [isVisible, setIsVisible] = useState(
    chart3.reduce((acc, item) => {
      acc[item.name] = true; 
      return acc;
    }, {})
  );

  const toggleAllVisibility = () => {
    const newVisibility = {};
    const allVisible = Object.values(isVisible).includes(true);
    chart3.forEach(item => {
      newVisibility[item.name] = !allVisible;
    });

    setIsVisible(newVisibility); 
  };

  return (
    <div style={{ textAlign: "center", minHeight: "94vh", padding: "3%" }}  id="print-section">
      <div style={{display:"flex", alignItems:"center"}}>
      <h3 style={{ backgroundColor: "white", color: colors.blue, fontWeight: "700", border: "none", textAlign: "left" }}>
       Teklif Raporlamaları
      </h3>
      <div>
       <Button
          shape="circle"
          icon={isVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />} 
          onClick={toggleAllVisibility}
          style={{
            backgroundColor: 'transparent',
            border: '2px solid',
            borderColor: colors.blue,
            cursor: 'pointer',
            marginLeft: '20px'
          }} 
        />

      </div>
      </div>

      <div style={{display:"flex", float:"right"}}>
      <div style={{ marginBottom: "20px" , display:"block"}}>
        <label>Başlangıç Tarihi: </label>
        <DatePicker      className="input-style"  selected={startDate} onChange={(date) => setStartDate(date)} />
        <label style={{ marginLeft: "20px" }}>Bitiş Tarihi: </label>
        <DatePicker      className="input-style"  selected={endDate} onChange={(date) => setEndDate(date)} />
      </div>
     
      <select value={selectedRange} onChange={handleChange}     className="input-style"   style={{ height:"35px", marginLeft:"50px", color:colors.blue}}>
        <option value="">Tarih Aralığı Seç</option>
        <option value="1">Son 1 Ay</option>
        <option value="3">Son 3 Ay</option>
        <option value="6">Son 6 Ay</option>
        <option value="12">Son 1 Yıl</option>
      </select>

      </div>
      <div style={{ display: "flex", marginTop:"50px" }}>
        <div>
          <PieChart width={400} height={400}>
            <Pie
              data={chart1}
              cx={200}
              cy={200}
              innerRadius={80}
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
            >
              {chart1.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
            <Legend />
          </PieChart>
          <h3 style={{ marginTop: "120px", backgroundColor: "white", color: colors.blue, fontWeight: "700", border: "none" }}>
            Genel Görünüm
          </h3>
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            {chart1.map((entry, index) => {
              const percentage = entry.value.toFixed(2);
              return (
                <div key={`label-${index}`} style={{ marginBottom: "10px", color: COLORS[index % COLORS.length] }}>
                  <strong>{entry.name}: </strong> {percentage}%
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <PieChart width={400} height={400}>
            <Pie
              data={chart2}
              cx={200}
              cy={200}
              innerRadius={80}
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
            >
              {chart2.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
            <Legend />
          </PieChart>
          <h3 style={{ marginTop: "120px", color: colors.blue, fontWeight: "700" }}>
            Revize Teklifler
          </h3>
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            {chart2.map((entry, index) => {
              const percentage = entry.value.toFixed(2);
              return (
                <div key={`label-${index}`} style={{ marginBottom: "10px", color: COLORS[index % COLORS.length] }}>
                  <strong>{entry.name}: </strong> {percentage}%
                </div>
              );
            })}
          </div>
        </div>

        <div>
          
      <PieChart width={400} height={400}>
        <Pie
          data={chart3}
          cx={200}
          cy={200}
          innerRadius={80}
          outerRadius={150}
          fill="#8884d8"
          dataKey="value"
        >
          {chart3.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip 
          formatter={(value, name) => {
            if (isVisible[name]) {
              return `${value.toFixed(2)}%`; 
            }
            return null; 
          }}
        />
        <Legend />
      </PieChart>

    
     
      <h3 style={{ marginTop: "120px", color: colors.blue, fontWeight: "700" }}>
        Toplam
      </h3>

      <div style={{ marginTop: "20px", textAlign: "center" }}>
        {chart3.map((item, index) => (
          <div key={item.name} style={{ marginBottom: '10px', color: COLORS[index % COLORS.length] }}>
            <span>{item.name}</span>
            <span style={{ marginLeft: '10px' }}></span>
            {isVisible[item.name] && <span> {item.value} %</span>}
          </div>
        ))}
      </div>

    </div>

      </div>

      <div style={{float:"right"}}>

      <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",border:"none", }}>
          Detay Satış / Teklif Ekranı
       </Button>
       <Button  
  onClick={handlePrint} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",border:"none",marginLeft:"10px"}}>
         Yazdır
       </Button>
       </div>
    </div>
  );
};

export default OfferReportingList;