import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input,Select,Form,Modal, Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import  { colors}  from "../../../../environments/environment"
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import PropTypes from 'prop-types';

const OperationDetail = ({ record,onClose }) => {
    const navigate = useNavigate();
    console.log("record ????????",record)
    const parts = record.parts;
    const plates = record.plates;


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Get the current items
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = parts.slice(startIndex, startIndex + itemsPerPage);



    useEffect(() => {
        const fetchData = async () => {
         

        };
        fetchData();
    }, []);

  
    const [showParts, setShowParts] = useState(true); // State to toggle between parts and plates

    const handleToggle = () => {
        setShowParts(!showParts);
    };


    return ( 
    <>
      <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}>Operasyon Detayları</h2>

      <div style={{ display: "flex", marginBottom: "20px" }}>
          <Button onClick={() => setShowParts(true)} style={{ backgroundColor: showParts ? colors.blue : '' }}>
                    Parça Detayları
          </Button>
          <Button onClick={() => setShowParts(false)} style={{ backgroundColor: !showParts ? colors.blue : '' }}>
                    Plaka Detayları
          </Button>
      </div>
      {showParts ?
              <>
               <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="setup-item-container" style={{ marginTop: "10px", marginLeft: "100px" }}>
                    {currentItems.map((part, index) => (
                        <div className="setup-item" key={index} style={{ border: "1px solid", borderColor: colors.blue, padding: "10px 30px", marginBottom: "10px" }}>
                            <div style={{ display: "block" }}>
                                <div style={{ display: "flex" }}>
                                    <img src={part.imageUrl} alt={part.imageFileName} style={{ width: '200px', height: "200px" }} />
                                    <div>
                                        <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>Part No:</strong> {part.partNo}</p>
                                        <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>Firma:</strong> {part.customerName}</p>
                                        <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>Parça Ağırlığı:</strong> {part.weightSetup} {part.weightUnit}</p>
                                        <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>Parça Sayısı:</strong> {part.piece}</p>
                                        <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>Ebat:</strong> {part.height} x {part.width} {part.unit}</p>
                                        <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>Süre:</strong> {part.timeSetup}</p>
                                    </div>
                                </div>
                                <div>
                                    <div><strong>Operasyonlar</strong></div>
                                    {part.operations && part.operations.length > 0 ? (
                                        part.operations.map((item, opIndex) => (
                                            <span key={opIndex}>
                                                {opIndex > 0 && <br />}
                                                {'- ' + item}
                                            </span>
                                        ))
                                    ) : (
                                        <div>Operasyon bulunamamaktadır</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <Pagination
                        current={currentPage}
                        total={parts.length}
                        pageSize={itemsPerPage}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
              </> 
              :
              <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
                  <p style={{ fontWeight: "bold", color: colors.blue }}>Genel Açıklama </p>
                   {record.setupDescription ? record.setupDescription : "-"}
                </div>

                <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
                <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
                {record && record.deadline && typeof record.deadline === 'object' ?
                  new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') :
                  record.deadline
                }
                </div>

                <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
                  <p style={{ fontWeight: "bold", color: colors.blue }}>
                      {record.isScrapDelivery ? "Hurda Teslim Yapılacaktır" : "Hurda Teslim Yapılmayacaktır"}   
                  </p>
                
                </div>   
              </div>    

                <div style={{marginTop:"20px",}}>
                    {plates.map((plate, plateIndex) => (          
                      <>
                        <div className="setup-item-plates" key={plateIndex} style={{border:"1px solid", borderColor:colors.blue, justifyContent:"space-between", textAlign:"left"}}>
                          <img src={plate.imageUrl} alt={plate.imageFileName} style={{ width: '500px', height:"300px" }} />

                          <div style={{display:"flex"}}>
                              <div>
                                <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Program No:</strong> {plate.programNo}</p>
                                <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Ebat:</strong> {plate.height} x {plate.width} x {record.thickness} {plate.unit}</p>
                                <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Süre:</strong> {plate.processingTime} </p>
                                <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Kg (Plaka Ağırlığı(Firesiz)):</strong> {plate.kg.toFixed(2)}</p>
                                <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Adet:</strong> {plate.piece} </p> 
                                <p style={{borderBottom:"1px solid", borderColor:colors.blue, width:"300px"}}><strong>Açıklama:</strong> {plate.description} </p> 
                                <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Fire Toplam Kg:</strong> {plate.wastagesKg}  </p>
                              </div> 
                          </div>                               
                          <div style={{ border: "1px solid", borderColor: colors.blue, padding: "5px 20px", width: "200px", marginTop: "20px" }}>
                              <p style={{ color: colors.blue, fontWeight: "bold" }}>Fireler</p>
                              {plate.wastage && plate.wastage.length > 0 ? (
                                  plate.wastage.map((waste, index) => (
                                    <div key={index} style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                    <div style={{ display: "flex", flexDirection: "column", marginRight: "10px" }}>
                                        <span style={{  padding: "5px", margin: "2px", fontWeight: "bold" }}>En</span>
                                        <span style={{ border: "1px solid", borderColor: colors.blue, padding: "5px", margin: "2px" }}>{waste.width} mm</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span style={{  padding: "5px", margin: "2px", fontWeight: "bold" }}>Boy</span>
                                        <span style={{ border: "1px solid", borderColor: colors.blue, padding: "5px", margin: "2px" }}>{waste.height} mm</span>
                                    </div>
                                </div>
                                  ))
                              ) : (
                                  <p>Fire bulunamamaktadır</p>
                              )}
                          </div>
                        </div>
                        
                    </>   
                    ))}
                </div>
              </> 
        }
  
   </>

)};


OperationDetail.propTypes = {
    onClose: PropTypes.func.isRequired, 
};
export default OperationDetail;