import React,{useEffect,useState} from "react";
import { Button, DatePicker, Form, Input, Select,Spin} from 'antd';
import NotificationService from "../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import { useNavigate } from 'react-router-dom';

 

const InvoiceCategoryContentEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const record = location.state && location.state.record;
    const [loading, setLoading] = useState(false);


    useEffect(() => {
      const fetchData = async () => {
  
      };
      fetchData();
    }, []);

   
    const onFinish = async (values) => {
      setLoading(true);
      const currentDate = new Date();

      const data = {
         firstTransactionDate: record.firstTransactionDateEdit??currentDate,
          firstUserId: record.firstUserId??localStorage.getItem("uid"),
          lastTransactionDate: currentDate,
          lastUserId: localStorage.getItem("uid"),
          isDeleted: record.isDeleted,
          categoryName: values.categoryName,
          contentName: values.contentName,
          contentStartDate: record.contentStartDate,
          contentEndDate: record.contentEndDate
      };
    
      try {
        const result = await FirebaseService.updateInvoiceCategoryContentsData(record.key, data);
        console.log("result",result)

        const updatedExpenses = await FirebaseService.updateExpenseCategoriesContent(record.contentName, values.contentName);
        console.log("sonuç",updatedExpenses);
              
        if (result.success) {
        
            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: result.message,
              placement: 'topRight'
            });

            setLoading(false);
            setTimeout(() => {
              navigate("/invoicecategorycontentlist");
            }, 2000);
            
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });
          setLoading(false);
        }
    
        
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
        setLoading(false);
      }
    };
    
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };


   return ( 
    <div>
    
    <Form
       name="basic"
       labelCol={{
       span: 2,
       }}
       wrapperCol={{
       span: 8,
       }}
       style={{
       maxWidth: "100%",
       padding:"5% 0 0 5%"
       }}
       initialValues={{
        categoryName: record?.categoryName || "",
        contentName: record?.contentName || "",
        contentStartDate: record?.contentStartDate || "",
        contentEndDate: record?.contentEndDate || "",

       }}
       onFinish={onFinish}
       onFinishFailed={onFinishFailed}
       autoComplete="off"
   >

   

       <Form.Item
       label="Kategori Adı"
       name="categoryName"
  
       >
     <Input  disabled className="input-style" style={{width:"100%"}}  placeholder="Kategori Adı"/>
       </Form.Item>



      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.categoryName !== currentValues.categoryName
        }
      >
        {({ getFieldValue }) => {
          const typeValue = getFieldValue('categoryName');

          return typeValue === 'Banka/ Kredi Giderleri' ? (
              <>
             
               <Form.Item
                 label="İçerik Adı"
                 name="contentName"
                 rules={[
                   {
                     required: true,
                     message: 'Lütfen giriniz!',
                   },
                   
                 ]}
               >
                 <Input className="input-style" style={{width:"100%"}} placeholder="İçerik Adı Giriniz" />
               </Form.Item>

              
               <Form.Item
            name="contentStartDate"
            label="Başlangıç Tarihi"
            rules={[
              {
              required: true,
              message: 'Lütfen termin tarihi giriniz!',
              },
            ]}
            >
            <DatePicker style={{ width: '100%'}} placeholder="Başlangıç Tarihi" 
            className="input-style" 
           />


              </Form.Item>

              <Form.Item
            name="contentEndDate"
            label="Bitiş Tarihi"
            rules={[
              {
              required: true,
              message: 'Lütfen bitiş tarihi giriniz!',
              },
            ]}
            >
            <DatePicker style={{ width: '100%', }} placeholder="Bitiş Tarihi" 
            className="input-style" 
           />


              </Form.Item>
           
             
               </>
          ) :
          
          (
            <>
             
            <Form.Item
              label="İçerik Adı"
              name="contentName"
              rules={[
                {
                  required: true,
                  message: 'Lütfen giriniz!',
                },
                
              ]}
            >
              <Input className="input-style" style={{width:"100%"}} placeholder="İçerik Adı Giriniz" />
            </Form.Item>  
            </>
          ) ;
        }}
      </Form.Item>


     
       <Form.Item
       wrapperCol={{
           offset: 15,
           span: 18,
       }}
       >
       <Button htmlType="submit" loading={loading} style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
       </Form.Item>

   </Form>
   {loading && (
           <div style={{
               position: 'fixed',
               top: 0,
               left: 0,
               width: '100%',
               height: '100%',
               backgroundColor: 'rgba(0, 0, 0, 0.5)',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               zIndex: 1000,
           }}>
               <Spin/>
           </div>
       )}

    </div>
    )

        };
  export default InvoiceCategoryContentEdit;