import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,Spin} from 'antd';
import NotificationService from "../../services/antNotificationService";

import FirebaseService from "../../services/firebaseService";
import  { parsCollections,components}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';


  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
  };

const UnitAdd = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
  

    useEffect(() => {
      
      const fetchData = async () => {
     
      };
      fetchData();
    }, []);
      const onFinish = async (values) => {
        setLoading(true)
        const currentDate =new Date()
        const data = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          isDeleted: false,
       
          unitName: values.unitName.toLowerCase(),
         // components: values.components,
          //managerComponents : values.managerComponents,
       
        };
        
      try {
        const result = await FirebaseService.addRecording(parsCollections.units,data);
        if (result.success) {
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: result.message,
            placement: 'topRight'
          });
          setLoading(false)
          setTimeout(() => {
            navigate("/unitlist");
          }, 2000);
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });setLoading(false)}
        
        
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });setLoading(false)
      }
    }; 

   

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 2,
            }}
            wrapperCol={{
            span: 8,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

        
<p style={{textAlign:"left"}}>Birim Adı</p>

            <Form.Item
            label="Birim"
            name="unitName"
            rules={[
                {
                required: true,
                message: 'Lütfen birim giriniz!',
                },
            ]}
           
            >
            <Input  className="input-style"
         style={{width:"100%"}} placeholder="Birim Adı"/>
            </Form.Item>

          
          
          
            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>)

        };
  export default UnitAdd;



  /**
   

  <p style={{textAlign:"left"}}>Birime gösterilecek bileşenler</p>
            <Form.Item
            name="components"
            rules={[
                {
                required: true,
                message: 'Lütfen alan giriniz!',
                },
            ]}
            wrapperCol={{
              span: 6,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
     
            <Select
                    
                        mode="tags"
                        placeholder="Birime Eklenecek Alanlar (Birden fazla seçilebilir.)"
                        style={{ borderRadius: 0, borderColor: "#1A446C80"}}
                        suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                        options={components.map(item => ({ label: item.label, value: item.value}))}
                      
                      />
         

         </Form.Item>

<p style={{textAlign:"left"}}>Birim Yöneticisine gösterilecek bileşenler</p>
         <Form.Item
            name="managerComponents"
            rules={[
                {
                required: true,
                message: 'Lütfen alan giriniz!',
                },
            ]}
            wrapperCol={{
              span: 6,
              }}
              style={{
              maxWidth: "100%",
              }}
            >
     
            <Select
                    
                        mode="tags"
                        placeholder="Alanlar (Birden fazla seçilebilir.)"
                        style={{ borderRadius: 0, borderColor: "#1A446C80"}}
                        suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                        options={components.map(item => ({ label: item.label, value: item.value}))}
                      
                      />
         

         </Form.Item>
          
          
   */