import React,{useState,useEffect} from "react";
import { Button, Form, Input, Space, Select, Checkbox ,Spin, DatePicker} from 'antd';
import NotificationService from "../../services/antNotificationService";
import FirebaseService from "../../services/firebaseService";
import  {colors}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth";
import { auth } from "../../services/authService";
const UserAdd = () => {
    const [isManager, setIsManager] = useState(false);
    const [titles, setTitles] = useState([]);
    const [units, setUnits] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
      const fetchData = async () => {
        try {
          const titles = await FirebaseService.getTitlesData();
          const filteredDataTitle = titles.filter(item => !item.isDeleted);
          setTitles(filteredDataTitle);
          const units = await FirebaseService.getUnitsData();
          const filteredDataUnits= units.filter(item => !item.isDeleted);
          setUnits(filteredDataUnits);
        } catch (error) {
          console.error('Firebase veri çekme hatası:', error);
        }
      };
      fetchData();
    }, []);
    const onChange = (value) => {
     // console.log(`selected ${value}`);
    };
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };
    const onFinish = async (values) => {
      setLoading(true)
      try {
        const userEmail = values.email + '@pars-makina.com.tr';
        const userData = await FirebaseService.getUserData();
        const isEmailExists = userData.some(user => user.email === userEmail);
        if (isEmailExists) {
          NotificationService.openErrorNotification({
            title: ' ',
            description: 'Bu mail adresiyle mevcut bir hesap bulunmaktadır. Lütfen başka bir mail adresi kullanınız.',
            placement: 'topRight',
          });setLoading(false)
        } else {
          if (values.password.length < 8) {
            NotificationService.openErrorNotification({
              title: 'Geçersiz Şifre',
              description: 'Şifre minimum 8 karakter olmalıdır.',
              placement: 'topRight',
            });
            setLoading(false)
            return;
        }
          const userCredential = await createUserWithEmailAndPassword(auth, userEmail, values.password);
          const user = userCredential.user;
          NotificationService.openSuccessNotification({
            title: 'İşleminiz başarıyla kaydedilmiştir.',
            description: 'Kişi başarıyla oluşturulmuştur.',
            placement: 'topRight',
          });setLoading(false)
          await FirebaseService.createUser({
              name: values.name,
              surname: values.surname,
              email: userEmail,
              password: values.password,
              userUnit: values.userUnit,
              isManager : isManager,
              title: values.titles,
              birthday: values.birthday ? values.birthday.format('DD-MM-YYYY') : null
          });
          navigate('/userlist');
          console.log("kullancıı başarıyla kaydedilid")
        }

      }

     catch (error) {
      console.error('Registration error:', error);
      NotificationService.openErrorNotification({
        title: 'Invalid Form',
        description: 'Please check your form information.',
        placement: 'topRight',
      });
      setLoading(false);
    }

    };
   return ( <div>
         <Form
            name="basic"
            labelCol={{
            span: 3,
            }}
            wrapperCol={{
            span: 8,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
            label="İsim"
            name="name"
            rules={[
                {
                required: true,
                message: 'Lütfen isim giriniz!',
                },
            ]}
            >
            <Input className="input-style" style={{width:"100%"}} placeholder="İsim"/>
            </Form.Item>
            <Form.Item
              label="Soyisim"
            name="surname"
            rules={[
                {
                required: true,
                message: 'Lütfen soyisim giriniz!',
                },
            ]}
            >
               <Input className="input-style" style={{width:"100%"}} placeholder="Soyisim"/>
            </Form.Item>
            <Form.Item
                    label="Doğum Tarihi"
                    name="birthday"
                    rules={[
                        {
                            required: true,
                            message: 'Lütfen doğum tarihi giriniz!',
                        },
                    ]}
                >
                    <DatePicker className="input-style" style={{ width: "100%" }} placeholder="Doğum Tarihi Seç" />
                </Form.Item>
            <Form.Item
              label="Telefon"
            name="email"
            rules={[
                {
                required: true,
                message: 'Lütfen email giriniz!',
                },
            ]}
            >
            <Input className="input-style" style={{width:"100%"}} placeholder="0(5xx)"/>
            </Form.Item>
            <Form.Item
              label="Şifre"
            name="password"
            rules={[
                {
                required: true,
                message: 'Lütfen şifre giriniz!',
                },
            ]}
            >
             <Input className="input-style" style={{width:"100%"}} placeholder="Parola"/>
            </Form.Item>
            <Form.Item
              label="Ünvan"
            name="titles"
            rules={[
                {
                required: true,
                message: 'Lütfen ünvan giriniz!',
                },
            ]}
            >
          <Select
          showSearch
              mode="tags"
              placeholder="Ünvan Seç (Birden fazla seçilebilir.)"
              className="input-style"
              style={{width:"100%"}}
              suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
              options={titles.map(item => ({ label: item.item, value: item.item }))}
              filterOption={(input, option) => 
                option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
              }
            />
         </Form.Item>
         <Form.Item
              name="isManager"
             >
              <Checkbox  onChange={() => setIsManager(!isManager)} style={{fontWeight:"500"}}>
               Birim Sorumlusu
              </Checkbox>
            </Form.Item>
         <Form.Item
           label="Birim"
            name="userUnit"
            rules={[
                {
                required: true,
                message: 'Lütfen birim giriniz!',
                },
            ]}
            >
            <Select
            showSearch
              className="input-style"
              style={{width:"100%"}}
              mode="tags"
              placeholder="Hangi Birimi Görebilir (Birden fazla seçilebilir.)"
              suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
              options={units.map(item => ({ label: item.unitName, value: item.unitName }))}
              filterOption={(input, option) => 
                option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
              }
            />
         </Form.Item>
            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Kaydet</Button>
            </Form.Item>
        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>)
        };
  export default UserAdd;