import React,{useEffect,useState} from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";
import  { colors}  from "../../environments/environment"

const DayList = () => {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getDaysData();
     // console.log("dataaaa",data);
      setItems(data)
     
    };
    fetchData();
  }, []);


  const goEditPage = (record) => {
    navigate('/daylistdetail', { state: { record } })
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); 
  };

  const columns = [
   
    {
      title: 'Gün',
      dataIndex: 'key',
      key: 'key',
      sorter: (a, b) => parseDate(a.key) - parseDate(b.key),
      render: (text,record) => <span style={{fontWeight:"bold",color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
      width: 400,
    },
   
    {
        title: 'Kişi Sayısı',
        dataIndex: 'itemCount',
        key: 'itemCount',
        render: (text, record) => (
            <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>
            {Object.keys(record).filter(key => key !== 'key').length}
          </span>
        ),
        width: 400,
      },
    {
      title: ' ',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
        Detayı Gör
     </Button>
      ),
    }
  ];
  
  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%"
      }}>
       
          <Table
            locale={{
              emptyText: 'Henüz kayıt bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>

    };
  export default DayList;