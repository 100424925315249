import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../../environments/environment";
import FirebaseService from "../../../services/firebaseService";
import { Button, Checkbox } from "antd";
const SetupPartsView = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [filteredSetup, setFilteredSetup] = useState(null); // filteredSetup state'i eklendi
  const [processes, setProcesses] = useState([]); // Tüm processler için state
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const setupData = await FirebaseService.getSetupData();
        const jobCode = location.state?.record?.jobCode;
        const setup = setupData.find((item) => item.jobCode === jobCode && !item.isDeleted);
        console.log("setup" , setup )
        const processesData = await FirebaseService.getContractManufacturingProcessesData();
        const filteredProcesses = processesData.filter((item) => !item.isDeleted);
        if (setup) {
          setFilteredSetup(setup);
        }
        setProcesses(filteredProcesses);
      } catch (error) {
        console.error("Veri alınırken hata oluştu:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [location.state?.record]);
  console.log("filteredSetup" , filteredSetup)
  return (
    <>
      {isLoading ? (
        <> Yükleniyor... </>
      ) : (
        <div
          style={{
            maxWidth: "100%",
            padding: "5% 5% 0 5%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign: "left" }}>Parça Detayları</h2>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <div style={{ textAlign: "left", flex: "1 0 13.66%" }}>
              <p style={{ fontWeight: "bold", color: colors.blue }}>Programcı Adı</p>
              {filteredSetup?.programmerName || "Belirtilmemiş"}
            </div>
            <div style={{ textAlign: "left", flex: "1 0 13.66%" }}>
              <p style={{ fontWeight: "bold", color: colors.blue }}>Kalınlık</p>
              {filteredSetup?.thickness || "Belirtilmemiş"}
            </div>
            <div style={{ textAlign: "left", flex: "1 0 13.66%" }}>
              <p style={{ fontWeight: "bold", color: colors.blue }}>Kalite</p>
              {filteredSetup?.quality || "Belirtilmemiş"}
            </div>
            <div style={{ textAlign: "left", flex: "1 0 13.66%" }}>
              <p style={{ fontWeight: "bold", color: colors.blue }}>Yüzey</p>
              {filteredSetup?.surface || "Belirtilmemiş"}
            </div>
            <div style={{ textAlign: "left", flex: "1 0 13.66%" }}>
              <p style={{ fontWeight: "bold", color: colors.blue }}>Parça Sayısı</p>
              {filteredSetup?.parts?.length || "Belirtilmemiş"}
            </div>
            <div style={{ textAlign: "left", flex: "1 0 13.66%" }}>
              <p style={{ fontWeight: "bold", color: colors.blue }}>Plaka Sayısı</p>
              {filteredSetup?.plates?.length || 0}
            </div>
            <div style={{ textAlign: "left", flex: "1 0 13.66%" }}>
              <p style={{ fontWeight: "bold", color: colors.blue }}>Toplam Süre</p>
              {filteredSetup?.setupTime || "Belirtilmemiş"}
            </div>
          </div>
          {filteredSetup ? (
 <div className="setup-item-container" style={{ marginTop: "30px" }}>
 {filteredSetup.parts.map((part, index) => (
   <div
     className="setup-item"
     key={index}
     style={{
       display: "flex",
       flexDirection: "row",
       border: "1px solid",
       borderColor: colors.blue,
       padding: "10px 30px",
       marginBottom: "10px",
       height: "auto", // Sabit yükseklik kaldırıldı
     }}
   >
     {/* Sol kısmı: Resim ve Operasyonlar */}
     <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "space-between" }}>
       {/* Sol üst kısmı: Resim */}
       <div style={{ flex: "1", display: "flex", alignItems: "center", justifyContent: "center" }}>
         {part.imageUrl ? (
           <img
             src={part.imageUrl}
             alt={part.imageFileName}
             style={{ width: "200px", height: "200px" }}
           />
         ) : (
           <div
             style={{
               width: "200px",
               height: "200px",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               border: "1px solid gray",
             }}
           >
             <span>No Image Available</span>
           </div>
         )}
       </div>
       {/* Sol alt kısmı: Operasyonlar */}
       <div style={{ flex: "1", padding: "10px" }}>
         <strong>Operasyonlar:</strong>
         {processes.map((process, opIndex) => (
           <div key={opIndex} style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
             <Checkbox
               checked={part.operations?.includes(process.processName) || false}
               readOnly
               style={{ marginRight: "5px" }}
             />
             <label>{process.processName}</label>
           </div>
         ))}
       </div>
     </div>
     {/* Sağ kısmı: Bilgiler */}
     <div style={{ display: "flex", flexDirection: "column", flex: 1, marginLeft: "20px" }}>
       <div style={{ flex: "1", marginBottom: "10px" }}>
         <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}>
           <strong>Part No:</strong> {part.partNo || "Belirtilmemiş"}
         </p>
         <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}>
           <strong>Firma:</strong> {part.customerName || "Belirtilmemiş"}
         </p>
         <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}>
           <strong>Parça Ağırlığı:</strong> {part.weightSetup || "Belirtilmemiş"} {part.weightUnit || ""}
         </p>
         <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}>
           <strong>Parça Sayısı:</strong> {part.piece || "Belirtilmemiş"}
         </p>
         <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}>
           <strong>Ebat:</strong> {part.height || "Belirtilmemiş"} x {part.width || "Belirtilmemiş"} {part.unit || ""}
         </p>
         <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}>
           <strong>Süre:</strong> {part.time || "Belirtilmemiş"}
         </p>
       </div>
     </div>
   </div>
 ))}
</div>
) : (
            <p>Setup bilgisi bulunamadı.</p>
          )}
        </div>
      )}
        <Button
        style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500", float:"right", marginRight:"150px"}}
        onClick={() =>
          navigate("/setupplatesview", { state: { filteredSetup } })
        }
    >
        İleri
        </Button>
        <Button
          style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500", float:"right",}}
        onClick={() => navigate('/setuppartsedit', { state: { filteredSetup } })}
      >
        Düzenle
      </Button>
    </>
  );
};
export default SetupPartsView;