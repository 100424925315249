import React, { useState,useEffect } from "react";
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import PurchaseRequestAdd from "../purchaseRequests/purchaseRequestAdd";
import StockFilter from "../stock/stockFilter";
import AlarmListLimited from "../alarms/alarmListLimited";
import OpenOrderFirst from "../openOrders/openOrderFirst";
import Pdf from './ProgrammerPage.pdf';
import OpenOrderGroupListLimited from "../openOrders/openOrderGroupListLimited";
import WaitingOpenOrderGroupListLimited from "../openOrders/waitingOpenOrder/waitingOpenOrderGroupListLimited";
import WebNavbar from "../webNavbar";
import OffersGivenListLimited from "../openOrders/offersGiven/offersGivenListLimited";
import OffersGivenGroupListLimited from "../openOrders/offersGiven/offersGivenGroupListLimited";


const ProgrammerPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handlePurchaseRequest = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setTimeout(() => {
      window.location.reload();
    }, 100);
    setIsModalVisible(false);
  };

  const handlePurchaseRequestAddFinish = () => {
    setIsModalVisible(false); 
  };


  const [isModalVisibleContract, setIsModalVisibleContract] = useState(false);

  const handleContractManufacturingAdd = () => {
    setIsModalVisibleContract(true);
  };

  const handleModalCancelContract = () => {
    setIsModalVisibleContract(false);
  };

  const handleContractManufacturingAddFinish = () => {
    setIsModalVisibleContract(false); 
  };  

  const [isModalVisibleOpenOrderFirst, setIsModalVisibleOpenOrderFirst] = useState(false);

  const handleOpenOrderFirst = () => {
    setIsModalVisibleOpenOrderFirst(true);
  };

  const handleModalCancelOpenOrderFirst = () => {
    setIsModalVisibleOpenOrderFirst(false);
  };

  const handleOpenOrderAddFinishFirst = () => {
    setIsModalVisibleOpenOrderFirst(false); 
  };


  const [isModalVisibleStock, setIsModalVisibleStock] = useState(false);

  const handleStock = () => {
    setIsModalVisibleStock(true);
  };

  const handleModalCancelStock= () => {
    setIsModalVisibleStock(false);
  };

  const handleStockFinish = () => {
    setIsModalVisibleStock(false); 
  };

  const navigate = useNavigate();
  const handleCheckCurrentPrice = () => {
    navigate('/checkcurrentpricelist')
  };


  const handleFutureRaw = () => {
    navigate('/futurerawlist')
  };


  const [isModalVisibleAddStockOrWastage, setIsModalVisibleAddStockOrWastage] = useState(false);

  const handleAddStockOrWastage = () => {
    setIsModalVisibleAddStockOrWastage(true);
  };

  const handleModalCancelAddStockOrWastage = () => {
    setIsModalVisibleAddStockOrWastage(false);
  };

  const handleAddStockOrWastageFinish = () => {
    setIsModalVisibleAddStockOrWastage(false); 
  };


  useEffect(() => {
    const fetchData = async () => {
      localStorage.removeItem("depotName");
      localStorage.removeItem("stockFilters");
      localStorage.removeItem("wastageFilters");

      localStorage.removeItem("setupName");
      localStorage.removeItem("checkedItemsStock");
      localStorage.removeItem("checkedItemsWastage");

    };
    fetchData();
  }, []);

  


  return (
    <div style={{padding:"3%"}}>
     <WebNavbar />
  
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
        <OpenOrderGroupListLimited />
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
        <WaitingOpenOrderGroupListLimited />
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
       <OffersGivenGroupListLimited />
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px", display:"block" }}>
        <div>
        <AlarmListLimited />
        </div>
      
        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: "10px" }}>
            <div style={{ gridColumn: "span 1", gridRow: "span 1" , margin:"10px"}}>
                <Button
                className="classic-button"
                onClick={handlePurchaseRequest}>
                Satın Alma Talep Et
                </Button>
                <Modal
                title="Satın Alma Talep Et"
                visible={isModalVisible}
                onCancel={handleModalCancel}
                footer={null}
                >
                <PurchaseRequestAdd onClose={handlePurchaseRequestAddFinish} />
                </Modal>
            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
                <Button 
                className="classic-button"
               onClick={handleOpenOrderFirst}>
                İş Emri Oluştur
                </Button>
                <Modal
                title="İş Emri Oluştur"
                visible={isModalVisibleOpenOrderFirst}
                onCancel={handleModalCancelOpenOrderFirst}
                footer={null}
                >
                <OpenOrderFirst onClose={handleOpenOrderAddFinishFirst} />
                </Modal>
            </div>

          

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
                <Button 
                className="classic-button"
               onClick={handleStock}>
                Güncel Stok
                </Button>
                <Modal
                title="Güncel Stok"
                visible={isModalVisibleStock}
                onCancel={handleModalCancelStock}
                footer={null}
                >
                <StockFilter />
                </Modal>
            </div>
        </div>

   
   
      </div>
    </div>
 
   
    </div>
  );
};

export default ProgrammerPage;




/*

  <div style={{ gridColumn: "span 1", gridRow: "span 1" , margin:"10px"}}>
                <Button
                className="classic-button"
                onClick={handleAddStockOrWastage}>
              Gelecek Hammade / Fire 
                </Button>
                <Modal
                title="Hammadde / Fire Girişi"
                visible={isModalVisibleAddStockOrWastage}
                onCancel={handleModalCancelAddStockOrWastage}
                footer={null}
                className="wide-popup"
                >
                <AddStockOrWastage onClose={handleAddStockOrWastageFinish} />
                </Modal>
            </div>

*/