import React,{useEffect,useState} from "react";
import { Button, Form, Input,Table ,Checkbox, DatePicker,Progress, Spin} from 'antd';
import { useLocation  } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import { colors, parsCollections } from "../../../../environments/environment";
import OneSignalService from "../../../../services/oneSignalService";
import moment from 'moment';


const SetupPlatesDetailMergedJobCodes = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isScrapDelivery, setIsScrapDelivery] = useState(false);
    const [qualities, setQualities] = useState([]); 
    const [code, setCode] = useState(''); 
    const partsComing = location.state && location.state.xmlInfo.parts;
    const [updatedParts, setUpdatedParts] = useState(partsComing); 
    
    const [loading, setLoading] = useState(false);
    const today = moment().startOf('day'); 
    
    const currentDate = new Date();

    const disabledDate = (current) => {
      return current && current < today;
    }
    const data = location.state && location.state.data;
  // console.log("xxx data",data);

    const xmlInfo = location.state && location.state.xmlInfo;
  //  console.log("xmlInfo",xmlInfo);
     
    
  //  console.log("parts geldi",parts);
    const priorityAbkant = partsComing && partsComing.some(part => 
  part.operations && part.operations.some(operation => operation.toLowerCase() === "abkant")
) ? 999999999 : null;


   // const priorityAbkant = updatedParts && updatedParts.some(part => part.operations && part.operations.includes("Abkant")) ? 999999999 : null;
   // console.log("parçalarda abkant?", priorityAbkant);
    
    const plates = location.state && location.state.xmlInfo.plates;
  // console.log("plates",plates);
    const plateCount = plates.length;

    const record = location.state && location.state.record;
    console.log("xxx record",record);
   const [additionalContents, setAdditionalContents] = useState([]);

   function normalizeString(str) {
    return str.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""); 
  }
     
    useEffect(() => {
      window.scrollTo(0, 0);

        const fetchData = async () => {
          const qualities = await FirebaseService.getDensitiesData();
          const filteredQualities = qualities.filter(item => !item.isDeleted);
          setQualities(filteredQualities);
  
        
          const code= generateRandomCode(5);
          setCode(code);

          const allOpenOrder = await FirebaseService.getOpenOrderData();
          //console.log("allOpenOrder", allOpenOrder,record.mergedJobCodes ); 
          const mergedJobCodesDetails = allOpenOrder.filter(order => 
            record.mergedJobCodes.includes(order.jobCode.substring(0, 5))
          );

          console.log("mergedJobCodesDetails",mergedJobCodesDetails)

        
          
          const updatedParts = xmlInfo.parts.map(part => {
            let updatedPart = { ...part };
          
            mergedJobCodesDetails.forEach(detail => {
           
              const isMatching = normalizeString(detail.companyName) === normalizeString(part.customerName);
              console.log("detail.companyName",detail.companyName)
              console.log("part.customerName",part.customerName)
              console.log("isMatching",isMatching)

            const jobCode = detail.jobCode.includes("-")
                ? detail.jobCode
                : detail.jobCode + "-" + (record.setupName ? record.setupName : xmlInfo.setupName);

              console.log("jobCode:", jobCode);

              if (isMatching) {
                updatedPart = {
                  ...updatedPart,
                  jobCode: jobCode,
                  projectCode: detail.projectCode || null,
                  companyName: detail.companyName || part.customerName,
                  operations: part.operations.map(operation => ({
                    processName: operation,
                    isContractManufacturing: false,
                    isCompleted: false,
                    isQrScan: false,
                    firstUserNameSurname: null,
                    firstUserId: null,
                  })),
                  setupName: record.setupName ? record.setupName : xmlInfo.setupName,
                  firstTransactionDate: currentDate,
                };
                console.log("updatedPart",updatedPart)
              }
            });
          
            return updatedPart;
          });
          
          setUpdatedParts(updatedParts);
          console.log("updatedParts dışarda",updatedParts)

         // console.log("updatedParts", updatedParts);
        };
        fetchData();
    }, []);

    function generateRandomCode(length) {
      const characters = '0123456789';
      let result = '';
    
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
    
      return result;
    }


    const addAdditionalContent = (plateIndex) => {
      setAdditionalContents((prevContents) => {
        const newContents = [...prevContents];
        newContents[plateIndex] = [...(newContents[plateIndex] || []), { width: '', height: '', number: '' }];
        return newContents;
      });
    };
  
    const removeAdditionalContent = (plateIndex, contentIndex) => {
      setAdditionalContents((prevContents) => {
        const newContents = [...prevContents];
        newContents[plateIndex].splice(contentIndex, 1);
        return newContents;
      });
    }
    
    const isDateBeforeToday = (dateString) => {
      const selectedDate = new Date(dateString);
      const today = new Date(); 
        return selectedDate < today;
    };

    const getOneSignalPlayerIds = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satış"));
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.oneSignalPlayerId) {
            oneSignalPlayerIds.push(user.oneSignalPlayerId);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

    const getOneSignalUserIds = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satış"));
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.key) {
            oneSignalPlayerIds.push(user.key);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

    const getOneSignalPlayerIdsArchieve = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("depo"));
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.oneSignalPlayerId) {
            oneSignalPlayerIds.push(user.oneSignalPlayerId);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

    const getOneSignalUserIdsArchieve = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("depo"));
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.key) {
            oneSignalPlayerIds.push(user.key);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    }; 

    const getOneSignalPlayerIdsBuyer= async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satın alma"));
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.oneSignalPlayerId) {
            oneSignalPlayerIds.push(user.oneSignalPlayerId);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

    const getOneSignalUserIdsBuyer= async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satın alma"));
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.key) {
            oneSignalPlayerIds.push(user.key);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

   
    
    const onFinish = async (values) => {   
      setLoading(true);
      if(values.deadline === undefined ){
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Lütfen termin tarihi giriniz.",
          placement: 'topRight'
        });
        setLoading(false);
        return;
      }

  
      const allOpenOrder = await FirebaseService.getOpenOrderData();
      console.log("allOpenOrder", allOpenOrder,record.mergedJobCodes ); 
      const mergedJobCodesDetails = allOpenOrder.filter(order => 
       
        record.mergedJobCodes.includes(order.jobCode.substring(0, 5))

      );
    

       //parts tablsouna eklemek

      let allSuccessful = true; 
      for (const part of updatedParts) {
        try {
             let result = await FirebaseService.addRecording(parsCollections.parts, part);
             if (result) {
             //  console.log(`Part with key ${part.key} saved successfully under job code ${detail.jobCode}.`);
            } else {
               allSuccessful = false; 
              // console.log(`Failed to save part with key ${part.key} under job code ${detail.jobCode}.`);
             }
        } catch (error) {
             allSuccessful = false; 
            // console.error(`Error saving part with key ${part.key} under job code ${detail.jobCode}:`, error);
        }
      }       
      
      if (allSuccessful) {
         NotificationService.openSuccessNotification({
           title: 'İşlem Başarılı',
           description: "Tüm parçalar başarıyla kaydedilmiştir.",
           placement: 'topRight'
         });
         
      } else {
         NotificationService.openErrorNotification({
           title: 'İşlem Başarısız',
           description: "Bazı parçalar kaydedilemedi. Lütfen tekrar deneyin.",
           placement: 'topRight'
         });
         setLoading(false);
       }
    
     //fason üretime ekleme alanı 
      const groupedParts = {};

      updatedParts.forEach((part) => {
        const { partNo, operations, customerName, jobCode } = part;
        operations.forEach((operation) => {
          const key = `${customerName}-${operation}`;
      
          if (!groupedParts[key]) {
            groupedParts[key] = {
              operation: operation, 
              customerName: customerName,
              parts: [], 
              jobCode: jobCode
            };
          }
      
          groupedParts[key].parts.push({
            partCode: part.partNo,
            piece: part.piece,
            unitPrice: null,
            profit: null,
          //  customerName: part.customerName,
          });
        });
      });
      
      console.log('Grouped Parts:', groupedParts);
      Object.values(groupedParts).forEach(async (group) => {
          console.log("grıp",group)
      
          if (group.operation.processName && group.operation.processName.toLowerCase() === "abkant") {
            console.log("Abkant prosesi atlandı:", group.operation.processName);
            return; 
          }

          const data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
            companyName: group.customerName,
            supplierName: null,
            jobCode: group.jobCode,
            projectCode: group.projectCode ? group.projectCode : null,
            deadline: null,
            openOrderDeadline: new Date(values.deadline),
            processName: group.operation.processName,
            isContractManufacturing: false,
            isShipment: false,
            isRequestCompleted: false,
            parts: group.parts, 
            isCompleted:false,
            code:code,
            invoiceNumber:null
          };
          console.log("fason data",data)
    
          try {
            const result = await FirebaseService.addRecording(parsCollections.contractManufacturing, data);
            if (result.success) {
           
              console.log("Fason üretime başarıyla kaydedildi")
              const ids = await getOneSignalPlayerIdsBuyer();
              const userIds = await getOneSignalUserIdsBuyer();
              const sentUserIdsMap = new Map();
              userIds.forEach(userId => {
                sentUserIdsMap.set(userId, false);
              });
          
          const sentUserIds = Object.fromEntries(sentUserIdsMap);
          //console.log("sentUserIds mapppp", sentUserIds);
              if (ids.length > 0) {
                  const oneSignalResult = await OneSignalService.sendNotification("", "Fason içeren Açık Sipariş", ids);
                  console.log("OneSignal notification result:", oneSignalResult);
                  const data = {
                    firstTransactionDate: currentDate,
                    firstUserId: localStorage.getItem("uid"),
                    isDeleted: false,
                    sentUserIds:sentUserIds,
                    firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
                    unitName:localStorage.getItem('userUnit'),
                    title:"Fason içeren Açık Sipariş",
                    companyName:group.companyName,
                    jobCode:group.jobCode,
                    projectCode:group.projectCode,
                    machine:null,
                    type:null,
                    notice:"Fason içeren açık sipariş yüklenmiştir, kontrol ediniz.",
                    description:null

                  };
                  const result = await FirebaseService.addRecording(parsCollections.notifications,data);
                  console.log("result",result)
              } else {
                  console.log("No OneSignal Player IDs found.");
              }


            } 
            else {   
              console.log(result.message);
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
              setLoading(false);
              return;
            }
          } catch (error) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
            setLoading(false);
            return;
          }   
      });
 

        if(record.isPlazma){
          console.log("plazma var")
          const data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
            companyName: record.companyName,
            supplierName: null,
            jobCode: record.jobCode,
            projectCode: record.projectCode ? record.projectCode : null,
            deadline: null,
            openOrderDeadline: new Date(values.deadline),
            processName: "plazma",
            isContractManufacturing: false,
            isShipment: false,
            isRequestCompleted: false,
            parts: null, 
            isCompleted:false,
            code:code,
            invoiceNumber:null

          };
          console.log("data",data)
    
          try {
            const result = await FirebaseService.addRecording(parsCollections.contractManufacturing, data);
            if (result.success) {
          
              console.log("Fason üretime plazma başarıyla kaydedildi")
              const ids = await getOneSignalPlayerIdsBuyer();
              const userIds = await getOneSignalUserIdsBuyer();
         const sentUserIdsMap = new Map();
          userIds.forEach(userId => {
            sentUserIdsMap.set(userId, false);
          });
          
          const sentUserIds = Object.fromEntries(sentUserIdsMap);
          //console.log("sentUserIds mapppp", sentUserIds);
              if (ids.length > 0) {
                  const oneSignalResult = await OneSignalService.sendNotification("", "Fason içeren Açık Sipariş", ids);
                  console.log("OneSignal notification result:", oneSignalResult);
                  const data = {
                    firstTransactionDate: currentDate,
                    firstUserId: localStorage.getItem("uid"),
                    isDeleted: false,
                    sentUserIds:sentUserIds,
                    firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
                    unitName:localStorage.getItem('userUnit'),
                    title:"Fason içeren Açık Sipariş",
                    companyName:record.companyName,
                    jobCode:record.jobCode,
                    projectCode:record.projectCode,
                    machine:null,
                    type:null,
                    notice:"Fason içeren açık sipariş yüklenmiştir, kontrol ediniz.",
                    description:null

                  };
                  const result = await FirebaseService.addRecording(parsCollections.notifications,data);
                  console.log("result",result)
              } else {
                  console.log("No OneSignal Player IDs found.");
              }
    
            } else {
              console.log(result.message);
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
              setLoading(false);
              return;
            }
          } catch (error) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
            setLoading(false);
            return;
          }
        }
      
          
      //  sipariş seçip tüm adımları yaptysa stoktan düşme vs veya teklif onaylandıysa 
      const requestedItems = record.stock.filter(stockItem =>
          !record.checkedItemsStockExcess.some(item =>
            item.id === stockItem.id &&
            item.quality === stockItem.quality &&
            item.height === stockItem.height &&
            item.width === stockItem.width &&
            item.piece === stockItem.piece &&
            item.thickness === stockItem.thickness &&
            item.surface === stockItem.surface
          )
      );
    
       console.log("stocktan talep edilenler çıktı",requestedItems);
      //stoktan ve fireden reserved update oluyo
     
      if (record.isReserved === false || record.isReserved === undefined) { 

        console.log("daha önce rezerve edilmedi rezerve ediliyor")
        // Stock update logic
        let updatedCheckedItemsStock;
        
        if (requestedItems && requestedItems.length > 0) {
          for (const x of requestedItems) {
            const { id, piece } = x;
            const result = await FirebaseService.updateStockReservedPiece(id, piece);
            console.log(result);
          }
          console.log("Updated checkedItemsStock:", updatedCheckedItemsStock);
        }
      
        // Wastage update logic
        let updatedCheckedItemsWastage;
        
        if (record.wastage && record.wastage.length > 0) {
          for (const x of record.wastage) {
            const { id, piece } = x;
            const result = await FirebaseService.updateWastageReservedPiece(id, piece);
            console.log(result);
          }
      
          updatedCheckedItemsWastage = record.wastage.map(({ oldPiece, ...rest }) => rest);
          console.log("Updated updatedCheckedItemsWastage:", updatedCheckedItemsWastage);
        }
      } else {
        console.log("daha önce rezerve edilmiş bu adım geçiliyor")
      }

      
      const expectedWastages = [];
      let density = 1;
      const selectedQuality = qualities.find(item => item.quality === data.quality);
      if (selectedQuality) {
          density = selectedQuality.density;
      }

  
      const handleFinish = async (plateIndex) => {
        const plate = plates[plateIndex];
        const contents = additionalContents[plateIndex]?.length || 0; 
        let wastagesKg = 0; 
    
        for (let contentIndex = 0; contentIndex < contents; contentIndex++) {
          console.log("plate sayı", plate.piece)
            const widthValues = values[`width-${plateIndex}-${contentIndex}-programNo-${plate.programNo}`];
            const heightValues = values[`height-${plateIndex}-${contentIndex}-programNo-${plate.programNo}`];
            const numberValues = values[`number-${plateIndex}-${contentIndex}-programNo-${plate.programNo}`];

           
            const area = widthValues * heightValues;
            const weight = area * Number(data.thickness) * density;
            const weightInKg = (weight / 1000000) * plate.piece * Number(numberValues); 
    

            if (!isNaN(weightInKg) && weightInKg !== 0) {
              expectedWastages.push({
                  width: Number(widthValues),
                  height: Number(heightValues),
                  programNo: plate.programNo,
                  kg: weightInKg,
                  piece: plate.piece * Number(numberValues),
                  depotName:plate.depotName
                  
              });
      
              wastagesKg += weightInKg;
          } else {
              console.log(`Invalid weight calculated for plate ${plate.programNo}`);
          }
        }
    
        const matchingPlates = xmlInfo.plates.filter(p => p.programNo === plate.programNo);
        matchingPlates.forEach(matchingPlate => {
          matchingPlate.wastagesKg = wastagesKg; 
          matchingPlate.kg =  matchingPlate.kg-wastagesKg; 
          matchingPlate.wastage = expectedWastages.filter(w => w.programNo === plate.programNo); 
          matchingPlate.jobCode =  record.jobCode; 

        });
      };

      let totalKg = 0;
      const plateCount = plates.length;
      let descriptionValues = null;
      for (let plateIndex = 0; plateIndex < plateCount; plateIndex++) {
            await handleFinish(plateIndex);
            const plate = plates[plateIndex];
            descriptionValues = values[`description-${plateIndex}-${plate.programNo}`];
            xmlInfo.plates[plateIndex].description = descriptionValues ? descriptionValues : null;
            xmlInfo.plates[plateIndex].status = "Kesilecek";
            xmlInfo.plates[plateIndex].isFinish = false;
          totalKg +=  xmlInfo.plates[plateIndex].kg

      }
      
      
     
    //wastage tablosuna ekleniyor
     
       for (const wastage of expectedWastages) {
        
            const wastageItem = {
              firstTransactionDate: currentDate,
              firstUserId: localStorage.getItem("uid"),
              firstUserNameSurname: localStorage.getItem('name') + " " + localStorage.getItem('surname'),
              isDeleted: false,   
              isQRScanned: false,
              companyName: "PARS",
            //  depotName:  "PARS",
              depotName:  wastage.depotName,
              jobCode: null,
              width: Number(wastage.width),
              height: Number(wastage.height),
              thickness : Number(data.thickness),
              quality :data.quality,
              surface :data.surface,
              imageUrl: null,
              description: "Teknik ressam tarafından setuptan eklenmiştir.",
              piece: wastage.piece,
              reservedPiece:0,
              unitKilo: (Number(wastage.width) * Number(wastage.height) * Number(data.thickness) * density) / 1000000,
              totalKilo: (Number(wastage.width) * Number(wastage.height) * Number(data.thickness) * density) / 1000000 * wastage.piece
      
            };
            const result = await FirebaseService.addRecording(parsCollections.wastages, wastageItem);
           // console.log("wastage result",result.docId)
            wastage.docId = result.docId;
     } 
       
      const priorityMachine = 999999999 ;

      let abkantBreaks= {
        userId:null,
        nameSurname:null,
        reason:null,
        firstTransactionDate:null,
        lastTransactionDate:null,
        description:null
      }
     
      const results = [];
      for (const order of mergedJobCodesDetails) {

        const jobCode = order.jobCode.includes("-")
        ? order.jobCode
        : order.jobCode + "-" + (record.setupName ? record.setupName : xmlInfo.setupName);

        const item = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
          isDeleted: false,
     
          thickness :data.thickness,
          quality :data.quality,
          surface :data.surface,
          fuel: data.fuel,
          plateCount:plateCount,
     
          setupTime :xmlInfo.totalRuntime,
          setupName :record.setupName ? record.setupName : xmlInfo.setupName,
          machine :xmlInfo.machine,

          jobCode :jobCode ,
          projectCode :order.projectCode ? order.projectCode : null ,
          companyName :order.companyName,
          stock:record.stock ? record.stock : [] , //eğer teklifse stock boş gelir 
          wastage:record.wastage ? record.wastage : [] , //eğer teklifse wastage boş gelir 

          description: values.description ?  values.description : null,
          isScrapDelivery : isScrapDelivery,
          parts:xmlInfo.parts,
          plates:xmlInfo.plates,

          expectedWastages:expectedWastages, 

          documentUrl : data.documentUrl,  //boş olabilir 
          priorityMachine: priorityMachine,
          priorityAbkant:priorityAbkant,

          isCustomerRaw: data.isCustomerRaw,
          isPlazma : record.isPlazma,
          totalKg:totalKg,

          abkantBreaks:abkantBreaks,
          abkantTwistShape:null,
          abkantUserCount:0,
          abkantActualTime:null,
          abkantIsFinish:false,
          setupDescription:values.description ?  values.description :null,
          isShipment:false,
          isMerge:true,
          deadline: new Date(values.deadline),

        };
    
        try {
          const result = await FirebaseService.addRecording(parsCollections.setup, item);
          results.push(result); 
        } catch (error) {
          console.error("Error saving record:", error);
          results.push({ success: false, error }); 
        }
      }
      
      const allSuccess = results.every(result => result.success);
      
      if (allSuccess) {
        console.log("All records were saved successfully.");

         //usera perfomans ekleme setup eklediğinde 
         await FirebaseService.updateUserPerformance(localStorage.getItem("uid"), new Date(), xmlInfo.totalRuntime)
         .then(response => console.log(response))
         .catch(error => console.error(error));
       
    
        console.log(record.key, record)
       
        const updateResults = [];

        for (const order of mergedJobCodesDetails) {
          console.log("update için open orderı", order)
          const jobCode = order.jobCode.includes("-")
          ? order.jobCode
          : order.jobCode + "-" + (record.setupName ? record.setupName : xmlInfo.setupName);
  
          const updateOpenOrderItem = {
            isSetup: true,
            isWaiting: false,
            deadline: new Date(values.deadline),
            setupName:record.setupName ? record.setupName : xmlInfo.setupName,
            stock:record.stock ? record.stock : [],
            wastage:record.wastage ? record.wastage : [] ,
            jobCode: jobCode,
            mergedJobCodes:record.mergedJobCodes
          };

          console.log("updateOpenOrderItem", updateOpenOrderItem, order.key);

          try {
            const resultx = await FirebaseService.updateOpenOrder(order.key, updateOpenOrderItem);
            updateResults.push(resultx); 
          } catch (error) {
            console.error("Error updating record:", error);
            updateResults.push(false); 
          }
        }
        const allUpdatesSuccessful = updateResults.every(result => result.success === true);
        console.log("updateResults",updateResults)
        console.log("allUpdatesSuccessful",allUpdatesSuccessful, "en sonki looop")
        if (allUpdatesSuccessful) {
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: "Başarıyla kaydedilmiştir.",
            placement: 'topRight'
          });
          setLoading(false);
          setTimeout(() => {
            navigate("/programmerpage");
          }, 500);
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
            placement: 'topRight'
          });
          setLoading(false);
        }

      } 
      else {
        console.log("Some records failed to save.");
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
          placement: 'topRight'
        });
        
        setLoading(false);
      }
      
        
  }; 
  const openPdf = () => {
    if (data.documentUrl) {
      window.open(data.documentUrl, '_blank'); 
    } else {
      console.error("PDF URL bulunamadı.");
    }
  };

   return ( 
    <Form
    name="basic"
    labelCol={{
    span: 4,
    }}
    wrapperCol={{
    span: 20,
    }}
    style={{
    maxWidth: "100%",
    padding:"5% 5% 0 5%"
    }}
    initialValues={{
    remember: true,
    }}
    onFinish={onFinish}
   // onFinishFailed={onFinishFailed}
    autoComplete="off"
>


    <div style={{ display: "flex", flexDirection: "column",}}>
  
    <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}>Yerleşimler</h2>
          
    <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Firma</p>
          {record && record.companyName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
          {record && record.jobCode}
        </div>


        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Setup İsmi</p>
          {xmlInfo && xmlInfo.setupName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Genel Açıklama Ekle </p>
          <Form.Item name="description" >
             <Input  className="input-style"   placeholder="" />
           </Form.Item>  
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
        <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
           <Form.Item
                name="deadline"  >
                <DatePicker   className="input-style"   style={{ width: '100%' }} placeholder="Teslim Tarihi" format="DD-MM-YYYY" disabledDate={disabledDate}  />
             </Form.Item>
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>
          <Form.Item
              name="isScrapDelivery">
              <Checkbox   onChange={() => setIsScrapDelivery(!isScrapDelivery)} style={{fontWeight:"500"}}>
              Hurda Teslim
              </Checkbox>
            </Form.Item>
          </p>
         
        </div>   
      </div>    

        <div style={{marginTop:"20px",}}>
            {plates.map((plate, plateIndex) => (          
              <>
                <div className="setup-item-plates" key={plateIndex} style={{border:"1px solid", borderColor:colors.blue, justifyContent:"space-between", textAlign:"left"}}>
                  <img src={plate.imageUrl} alt={plate.imageFileName} style={{ width: '500px', height:"300px" }} />

                   <div style={{display:"flex"}}>
                      <div>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Program No:</strong> {plate.programNo}</p>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Ebat:</strong> {plate.height} x {plate.width} x {data.thickness} {plate.unit}</p>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Süre:</strong> {plate.processingTime} </p>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Kg (Plaka Ağırlığı):</strong> {plate.kg}</p>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Adet:</strong> {plate.piece} </p> 
                      {/*  <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Fire Kg:</strong> </p>*/} 

                      <Form.Item
                        name={`description-${plateIndex}-${plate.programNo}`}
                      // label="Açıklama"
                      >
                      <Input.TextArea
                          placeholder="Açıklama Girebilirsiniz"
                          style={{ width: '250px', height: '80px', borderColor: "#1A446C" }}
                        />                      
                        </Form.Item>
                      </div> 

                   </div>     
                       


                       
                   <div style={{border:"1px solid", borderColor:colors.blue, padding:"5px 20px", width:"250px"}}>
                    <p style={{color:colors.blue, fontWeight:"bold"}}>Fire Ekle</p>

                    {additionalContents[plateIndex]?.map((fireItem, contentIndex) => (
                  <div style={{ display: "flex" }} key={contentIndex}>
                    <Form.Item
                      name={`width-${plateIndex}-${contentIndex}-programNo-${plates[plateIndex].programNo}`}
                      rules={[
                        { pattern: /^\d+$/, message: "Sadece rakam girişi yapınız." },
                        {
                          validator: (_, value) => {
                            if (value && parseInt(value) > plate.width) {
                              return Promise.reject(new Error("En değeri plakanın genişliğinden büyük olamaz."));
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}                    >
                      <Input style={{ borderRadius: 0, borderColor: "#1A446C80", width: "70px" }} placeholder="En" />
                    </Form.Item>

                    <Form.Item
                      name={`height-${plateIndex}-${contentIndex}-programNo-${plates[plateIndex].programNo}`}
                      rules={[
                        { pattern: /^\d+$/, message: "Sadece rakam girişi yapınız." },
                        {
                          validator: (_, value) => {
                            if (value && parseInt(value) > plate.height) {
                              return Promise.reject(new Error("Boy değeri plakanın uzunluğundan büyük olamaz."));
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}                    >
                      <Input style={{ borderRadius: 0, borderColor: "#1A446C80", width: "70px" }} placeholder="Boy" />
                    </Form.Item>

                    <Form.Item
                      name={`number-${plateIndex}-${contentIndex}-programNo-${plates[plateIndex].programNo}`}
                      rules={[
                        { pattern: /^\d+$/, message: "Sadece rakam girişi yapınız." },
                        
                      ]}
                    >
                      <Input style={{ borderRadius: 0, borderColor: "#1A446C80", width: "70px" }} placeholder="Adet" />
                    </Form.Item>

                    <Button onClick={() => removeAdditionalContent(plateIndex, contentIndex)}>-</Button>
                  </div>
                ))}
                 

                <Form.Item style={{ textAlign: "center" }}>
                  <Button onClick={() => addAdditionalContent(plateIndex)}>+</Button>
                </Form.Item>


                  </div>


                </div>
                
             </>   
            ))}
        </div>

        <div style={{display:"flex",marginTop:"20px", justifyContent:"end"}}>
        <Button onClick={openPdf} style={{marginTop:"20px", backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Pdf Görüntüle</Button>

      <Form.Item style={{textAlign:"right",marginTop:"20px"}}>
          <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Kaydet</Button>
      </Form.Item>
        </div>
       
         {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

    </div>
    </Form>

  )};
export default SetupPlatesDetailMergedJobCodes;