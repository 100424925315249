import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,Space, Upload,Modal} from 'antd';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { colors, parsCollections } from "../../../environments/environment";


const ScrapAdd = ({ onClose }) => {
    const navigate = useNavigate();

    useEffect(() => {
      const fetchData = async () => {
        
      };
      fetchData();
    }, []);
    
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };
    
    const onFinish = async (values) => {

      const currentDate = new Date();
      const blackKg = Number(values.siyahKg) || 0;
      const blackPrice = Number(values.siyahTL) || 0;
      const aluminumKg = Number(values.aluminyumKg) || 0;
      const aluminumPrice = Number(values.aluminyumTL) || 0;
      const chromiumKg = Number(values.kromKg) || 0;
      const chromiumPrice = Number(values.kromTL) || 0;
      const ironDustKg = Number(values.demirKg) || 0;
      const ironDustPrice = Number(values.demirTL) || 0;
  
      console.log(blackKg,blackPrice,aluminumKg,aluminumPrice,chromiumKg,chromiumPrice,ironDustKg,ironDustPrice)
     
     
      if (blackKg === 0 && aluminumKg === 0 && chromiumKg === 0 && ironDustKg === 0) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "En az bir malzeme miktarı girilmelidir.",
          placement: 'topRight'
        });
        return;
      }

      if (blackPrice === 0 && aluminumPrice === 0 && chromiumPrice === 0 && ironDustPrice === 0) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "En az bir malzeme fiyatı girilmelidir.",
          placement: 'topRight'
        });
        return;
      }
  
      const data = {
        firstTransactionDate: currentDate,
        firstUserId: localStorage.getItem("uid"),
        isDeleted: false,
        companyName: values.companyName,
        materials: {
          black: { kg: blackKg, price: blackPrice },
          aluminum: { kg: aluminumKg, price: aluminumPrice },
          chromium: { kg: chromiumKg, price: chromiumPrice },
          ironDust: { kg: ironDustKg, price: ironDustPrice }
        },
        invoiceNumber:null
      };
  
      console.log("Data", data);
   
   
   try {
     const result = await FirebaseService.addRecording(parsCollections.scraps, data);
     if (result.success) {

        NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description:"Hurda girişi başarıyla yapılmıştır.",
            placement: 'topRight'
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
     

     } 
     else {
       NotificationService.openErrorNotification({
         title: 'İşlem Başarısız',
         description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
         placement: 'topRight'
       });
     }
     
   } catch (error) {
     NotificationService.openErrorNotification({
       title: 'İşlem Başarısız',
       description: "Bilinmeyen bir hata ile karşılaşıldı.",
       placement: 'topRight'
     });
   }
        
   
    
    }; 


    const onChange = (value) => {
        console.log(`selected ${value}`);  
   };

   return (
    <div>
      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 15 }}
        style={{ maxWidth: "100%", padding: "5% 0 0 5%" }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Verilen Firma"
          name="companyName"
          rules={[{ required: true, message: 'Lütfen firma adı giriniz!' }]}
        >
          <Input className="input-style" style={{ width: "100%" }} placeholder="Verilen Firma" />
        </Form.Item>

        {["siyah", "aluminyum", "krom", "demir"].map((material) => (
          <Form.Item key={material} label={material.charAt(0).toUpperCase() + material.slice(1)}>
            <Input.Group compact>
              <Form.Item
                name={`${material}Kg`}
                noStyle
                rules={[{ pattern: /^[0-9.]+$/, message: 'Sadece rakam ve nokta (.) girişi yapınız.' }]}
              >
                <Input  className="input-style" style={{ width: '50%' }} placeholder={`${material.charAt(0).toUpperCase() + material.slice(1)} (Kg)`} />
              </Form.Item>
              <Form.Item
                name={`${material}TL`}
                noStyle
                rules={[{ pattern: /^[0-9.]+$/, message: 'Sadece rakam ve nokta (.) girişi yapınız.' }]}
              >
                <Input className="input-style" style={{ width: '50%' }} placeholder={`${material.charAt(0).toUpperCase() + material.slice(1)} (Birim TL)`} />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        ))}

        <Form.Item
          wrapperCol={{ offset: 15, span: 18 }}
          style={{textAlign:"right"}}
        >
          <Button htmlType="submit" style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }}>Gönder</Button>
        </Form.Item>
      </Form>
    </div>
  );


};

ScrapAdd.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

  export default ScrapAdd;