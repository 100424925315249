import React,{useEffect,useState} from "react";
import { Table, Button,Modal,Popover } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';
import ContractManufacturingEdit from "./contractManufacturingEdit";
import ContractManufacturingDisplayDetail from "./contractManufacturingDisplayDetail";

const ContractManufacturingList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state && location.state.record;
  const [items, setItems] = useState(''); 


  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const itemsss = await FirebaseService.getContractManufacturingData();   

      const sortedItems = itemsss
      ? [...itemsss].sort((a, b) => {
          const jobCodeA = a.jobCode || '';
          const jobCodeB = b.jobCode || '';
          return jobCodeA.localeCompare(jobCodeB);
        })
      : [];
          setItems(sortedItems);
       // console.log("Sıralı items:", sortedItems);
    };

    fetchData();
}, []);

  

  const [recordForModal, setRecordForModal] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);


  const goEditPage = (record) => {
    setRecordForModal(record);
    setIsModalVisible(true);
  };

  const handleFinish = () => {
    setIsModalVisible(false); 
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };





  const [recordForModalDisplay, setRecordForModalDisplay] = useState(null);
  const [isModalVisibleDisplay, setIsModalVisibleDisplay] = useState(false);


  const goEditPageDisplay = (record) => {
    setRecordForModalDisplay(record);
    setIsModalVisibleDisplay(true);
  };

  const handleFinishDisplay = () => {
    setIsModalVisibleDisplay(false); 
  };

  const handleModalCancelDisplay = () => {
    setIsModalVisibleDisplay(false);
  };

  const columns = [
    {
      title: 'Takip Kodu',
      dataIndex: 'code',
      key: 'code',
      
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
      </span>,
    },
    {
      title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
    
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
  {
    title: 'İş Kodu',
    dataIndex: 'jobCode',
    key: 'jobCode',
    
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
    </span>,
  },
  {
    title: 'Proje Kodu',
    dataIndex: 'projectCode',
    key: 'projectCode',
    
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
    </span>,
  },
  {
    title: 'Fason Tedarikçi Firma',
    dataIndex: 'supplierName',
    key: 'supplierName',
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
    </span>,
  },
  {
    title: 'Durum',
    dataIndex: '',
    key: '',
    render: (text, record) => (
      <span style={{ color: record.isRequestCompleted === true ? "green" : "#000000" }}>
        {record.isRequestCompleted === true ? "Talep Tamamlandı" : "Talebi Tamamla"}
      </span>
    ),
  },
  
  {
    title: 'Proses',
    dataIndex: 'processName',
    key: 'processName',
    render: (text,record) =>
    <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
      {text}
   
  </span>  
   ,
  
  },
  
    
    {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
      
        render: (text, record) => {
          const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
          const isDeadlinePassed = record.deadline && new Date(record.deadline.seconds * 1000) < new Date();
          return (
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
              {formattedDeadline}
              {isDeadlinePassed && 
                <Popover
                  content={<span>Termin tarihi geçti</span>}
                  title="Dikkat"
                  trigger="click" // veya hover
                >
                  <img width={30} height={20} alt="warning" src="/images/important.png" style={{ paddingBottom: "0px", marginLeft: 12 }} />
                </Popover>
              }
            </span>
          );
          },
      },

      /*
      {
        title: 'Düzenle',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          record.isRequestCompleted ? (
            <img 
              src="public/images/editcon.png" // İkonun doğru yolu
              alt="edit"
              style={{ cursor: 'pointer', width: 20, height: 20 }}
              onClick={() => navigate('/contractmanufacturinglistedit', { state: { record } })} // Edit sayfasına yönlendirme
            />
          ) : null
        ),
      },
      */

      {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button 
          onClick={()=>record.isRequestCompleted ?  goEditPageDisplay(record) : goEditPage(record) } 
          style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"500",border:"none"}} >
          Detaya git
       </Button>
        ),
      },
     
      
      
  ];

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3 style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>Fason Üretim Talep</h3>
          <Table
            locale={{
              emptyText: 'Henüz fason üretim talebi  eklemediniz...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
           /* expandable={{
              expandedRowRender: record => (
                <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: "55px" }}>
  <span style={{ fontWeight: "bold" }}>Parçalar:</span>
  <div>
    {record.parts.map((part, index) => (
      <div key={index} style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
        {` Parça Adı: ${part.partCode}, Adet: ${part.piece}, Birim Fiyatı: ${part.unitPrice}`}
      </div>
    ))}
  </div>
</div>


              
            </div>
              ),
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/uparrow.png" /></a>
                ) : (
                  <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" /></a>
                ),
                expandIconColumnIndex: 6
            }}

            */
        />

<Modal
          title="Fason Üretim Detay"
          visible={isModalVisible}
          onCancel={handleModalCancel}
          footer={null}
        >
          <ContractManufacturingEdit  record={recordForModal}  onClose={handleFinish} />
        </Modal>


        <Modal
          title="Fason Üretim Detay Görüntüleme"
          visible={isModalVisibleDisplay}
          onCancel={handleModalCancelDisplay}
          footer={null}
        >
          <ContractManufacturingDisplayDetail  record={recordForModalDisplay}  onClose={handleFinishDisplay} />
        </Modal>
    </div>

  };
  export default ContractManufacturingList;

  
       