import React,{useEffect,useState} from "react";
import { Table, Button,Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';

const AbkantList = () => {

  const [items, setItems] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const productionData = await FirebaseService.getProductionData();
      const filteredData = productionData.filter(item => !item.isDeleted);
    
      const setupData = await FirebaseService.getSetupData();
      const updatedData = filteredData.map(item => {
      const jobCode = item.jobCode;
      const setupItem = setupData.find(setup => setup.jobCode === jobCode && setup.priorityAbkant);

      console.log(setupItem,"set up item")
    
        // Eşleşme varsa özellikleri güncelle
      if (setupItem && !setupItem.abkantIsFinish) {
          return {
            ...item,
            companyName: setupItem.companyName,
            description: setupItem.description,
            expectedWastages: setupItem.expectedWastages,
            firstTransactionDate: setupItem.firstTransactionDate,
            documentUrl: setupItem.documentUrl,
            firstUserId: setupItem.firstUserId,
            isDeleted: setupItem.isDeleted,
            isScrapDelivery: setupItem.isScrapDelivery,
            key: setupItem.key,
            machine: setupItem.machine,
            parts: setupItem.parts,
            plateCount: setupItem.plateCount,
            plates: setupItem.plates,
            priorityAbkant: setupItem.priorityAbkant,
            priorityMachine: setupItem.priorityMachine,
            projectCode: setupItem.projectCode,
            quality: setupItem.quality,
            setupName: setupItem.setupName,
            setupTime: setupItem.setupTime,
            stock: setupItem.stock,
            surface: setupItem.surface,
            thickness: setupItem.thickness,
            wastage: setupItem.wastage,
          };
        }
        return null;
      }).filter(Boolean); 
    
      const sortedItems = [...updatedData].sort((a, b) => a.priorityAbkant - b.priorityAbkant);

      setItems(sortedItems);
    };
    fetchData();
  }, []);


  const handleDragStart = (record) => {
    localStorage.setItem('draggedItem', JSON.stringify(record));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (dragIndex, dropIndex) => {
    const draggedItem = JSON.parse(localStorage.getItem('draggedItem'));
    const updatedItems = [...items];
    const draggedItemRemoved = updatedItems.splice(dragIndex, 1)[0];
    updatedItems.splice(dropIndex, 0, draggedItemRemoved);

    const updatedPriorityValues = updatedItems.map((item, i) => ({
        ...item,
        priorityAbkant: i + 1,
    }));
    setItems(updatedPriorityValues);

    const updatedDocuments = updatedPriorityValues.map(item => ({
        id: item.key,
        priorityAbkant: item.priorityAbkant,
    }));
    FirebaseService.updateSetupTablePriorityAbkant(updatedDocuments);
    
    localStorage.removeItem('draggedItem');
};

  
  const columns = [
    {
      title: 'Sırası',
      dataIndex: 'priorityAbkant',
      key: 'priorityAbkant',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text} </span>,
    },
    
    {
      title:"Firma",
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text} </span>,
    },
  {
    title: 'Proje Kodu',
    dataIndex: 'projectCode',
    key: 'projectCode',
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"} </span>,
  },
  {
    title:"İş Kodu",
    dataIndex: 'jobCode',
    key: 'jobCode',
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text} </span>,
},
{
  title: 'Dakika',
  dataIndex: 'setupTime',
  key: 'setupTime',
  render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text} </span>,
},

{
  title: '   ',
  dataIndex: '',
  key: '',
  render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> 
  <img width={20} height={20} alt="logo" src="/images/sort.svg" />
</span>,
},

  ];

  return  <div style={{
    justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
      }}>
        <h3  style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>Abkant</h3>
          <Table
            locale={{
              emptyText: 'Henüz abkant bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table drag-row"
            rowClassName="custom-news-row"
           
            rowKey="id"
            onRow={(record, index) => ({
              index,
              draggable: true,
              onDragStart: () => handleDragStart(record),
              onDragOver: (e) => handleDragOver(e),
              onDrop: () => handleDrop(index),
            })}
       
        />
                <div style={{color:"gray"}}> *Yerlerinin değişmesini istediğiniz satırı tutup sürükleyerek istediğiniz sıraya yerleştirebilirsiniz.</div>


       
    </div>

  };
  export default AbkantList;

  