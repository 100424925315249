


import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,Table, Upload,Modal,Checkbox, Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections, colors}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import FirebaseStorage from "../../../services/storegeService";
import StoregeService from "../../../services/storegeService";
import AddRequestAdminDensities from "../addRequestAdmin/addRequestAdminDensities";
import { UploadOutlined, FilePdfOutlined } from '@ant-design/icons';

const SetupAdd = ({ recordx,onClose }) => {
    const navigate = useNavigate();
    const [xmlFile, setXmlFile] = useState(null);
    const [jobCode, setJobCode] = useState(null);
    let [xmlInfo, setXmlInfo] = useState({});
    const [qualities, setQualities] = useState([]); 
    const [isCustomerRaw, setIsCustomerRaw] = useState(false);
    const [values, setValues] = useState(null);
    const [record, setRecord] = useState(null);
    const [surfaces, setSurfaces] = useState([]); 
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalData, setModalData] = useState({});
    const [updatedRecord, setUpdatedRecord] = useState({});
    const [fileListPartImage, setFileListPartImage] = useState([]);
    const [fileListPlateImage, setFileListPlateImage] = useState([]);
    const [fileListSetupPdf, setFileListSetupPdf] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisibleSameXmlName, setIsModalVisibleSameXmlName] = useState(false);
    const [jobCodes, setJobCodes] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
       
        const qualities = await FirebaseService.getDensitiesData();
        const filteredQualities = qualities.filter(item => !item.isDeleted);
        setQualities(filteredQualities);

        const surfaces = await FirebaseService.getSurfacesData();
        const filteredSurfaces = surfaces.filter(item => !item.isDeleted);
        setSurfaces(filteredSurfaces);

     

        const processedData = typeof recordx === 'object' && !Array.isArray(recordx) && recordx.stock && recordx.stock.length > 0
        ? {
            ...recordx,
            stock: recordx.stock.map(stockItem => ({
              ...stockItem,
              isSendPurchase: checkSendPurchase(stockItem)
            })),
          }
        : recordx;
      
      setRecord(processedData);
        
      };
      fetchData();
    }, []);

    const checkSendPurchase = (stockItem) => {
        const checkedItemsStockExcess = recordx.checkedItemsStockExcess || [];
      
        return checkedItemsStockExcess.some(checkedItem =>
          checkedItem.id === stockItem.id
        );
      };
      
      
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };

    const handleXmlFileChange = (event) => {
        const file = event.file;
        const reader = new FileReader();
        reader.onload = (e) => {
            const xmlContent = e.target.result;           
            parseXml(xmlContent);
        };
        reader.readAsText(file);
    };

    function convertTo60Base(seconds) {
        return seconds * 60; 
    }
      
    function convertToTimeFormat(seconds) {
        // Saatleri hesapla
       
       const hours = Math.floor(seconds / 3600);
        seconds %= 3600;
      
        // Dakikaları hesapla
        const minutes = Math.floor(seconds / 60);
        seconds %= 60;
      
        // Saniyeleri düzeltilmiş şekilde al
        seconds = Math.floor(seconds);
      
        // Sonuçları iki haneli sayılara dönüştür
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');
      
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
          
    
    }
      
   
    function parseXml(xmlContent) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlContent, "text/xml");
      
      
        if (!xmlDoc || xmlDoc.getElementsByTagName('parsererror').length > 0) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: 'Lütfen uygun bir xml dosyası yükleyiniz.',
                placement: 'topRight'
            });
    
            if (xmlFile) {
                xmlFile.value = '';
                setXmlFile(null);
            }
            return;
        }
    

        const productionOrders = xmlDoc.querySelectorAll("ProductionOrder");
        const maxLaserPowerElement = xmlDoc.querySelector("LaserTechnoTable MaxLaserPower").textContent;

        const parts = xmlDoc.querySelectorAll("Parts Part");
        const partsArray = [];

        parts.forEach(part => {
            const partNo = part.getAttribute("PartNo");
          
            const cadFilePath = part.querySelector("CadFilePath").textContent;
            const cadFileName = part.querySelector("CadFileName").textContent;
            const imageFilePath = part.querySelector("ImageFilePath").textContent;
            const imageFileName = part.querySelector("ImageFileName").textContent;
            const descriptionForImageName = part.querySelector("Description").textContent;

            const weightUnit = part.querySelector("WeightUnit").textContent;
            const piece = parseInt(part.querySelector("TotalQuantityInJob").textContent);
            const weightSetup = parseFloat(part.querySelector("Weight").textContent);
            const timeSetup = parseFloat(part.querySelector("TargetProcessingTimePerPiece").textContent);

            const dimensions = part.querySelector("Dimensions");
            const unit = dimensions.querySelector("Unit").textContent;
            const height = parseFloat(dimensions.querySelector("Length").textContent);
            const width = parseFloat(dimensions.querySelector("Width").textContent);
            const thicknessSetup = parseFloat(dimensions.querySelector("Thickness").textContent);
    
    
            const matchingProductionOrders = Array.from(productionOrders).filter(order => {
                return order.querySelector("PartoNo").textContent === partNo;
            });
    

            const manufacturingOrders = xmlDoc.querySelectorAll("ManufacturingOrders TcBoManufacturingOrder");

            matchingProductionOrders.forEach(order => {
                const customerName = order.querySelector("CustomerName").textContent;
        
                const manufacturingOrder = Array.from(manufacturingOrders).find(manufacturingOrder => {
                    return Array.from(manufacturingOrder.querySelectorAll("Order")).some(productionOrder => {
                        return productionOrder.querySelector("PartoNo").textContent === partNo;
                    });
                });
        
                let manufacturingOrderNo = manufacturingOrder ? manufacturingOrder.getAttribute("ManufacturingOrderNo") : null;

                if (manufacturingOrderNo) {
                  manufacturingOrderNo = manufacturingOrderNo.replace(/_0(\d)$/, '_$1');
                }
               

              const manufacturingOrderNos = [];

              const matchingManufacturingOrders = Array.from(manufacturingOrders).filter(manufacturingOrder => {
                return Array.from(manufacturingOrder.querySelectorAll("Order")).some(productionOrder => {
                  const partoNoElement = productionOrder.querySelector("PartoNo");
                  return partoNoElement && partoNoElement.textContent === partNo;
                });
              });


              if (matchingManufacturingOrders.length > 0) {
                matchingManufacturingOrders.forEach(order => {
                  let manufacturingOrderNo = order.getAttribute("ManufacturingOrderNo");

                  if (manufacturingOrderNo) {
                    manufacturingOrderNo = manufacturingOrderNo.replace(/_0(\d)$/, '_$1');
                    manufacturingOrderNos.push(manufacturingOrderNo);
                  }
                });
              } else {
                console.log("No matching manufacturing orders found.");
              }

                partsArray.push({
                    partNo,
                    customerName,
                    cadFilePath,
                    cadFileName,
                    imageFilePath,
                    imageFileName,
                    weightUnit,
                    piece,
                    timeSetup,
                    time: convertToTimeFormat(convertTo60Base(timeSetup)),
                    unit,
                    height,
                    width,
                    thicknessSetup,
                    weightSetup,
                    machine: maxLaserPowerElement,
                    programNo: manufacturingOrderNo,
                    descriptionForImageName ,
                    programNos: manufacturingOrderNos
                }); 
            });
                
                
           
        });
    
  
        const requiredSheets = xmlDoc.querySelectorAll("RequiredSheets Sheet");
        const plates = xmlDoc.querySelectorAll("NcPrograms NcProgram");
        const platesArray = [];
        
        plates.forEach(program => {
            const programNo = program.getAttribute("ProgramNo");
            const sheetIdentNo = program.querySelector("Sheet").textContent;
            
            const processingTimeCalculate = parseFloat(program.querySelector("ProcessingTime").textContent);
            const processingTime = convertToTimeFormat(convertTo60Base(processingTimeCalculate));

            const piece = parseInt(program.querySelector("TotalNoOfRuns").textContent);
        
            const requiredSheet = Array.from(requiredSheets).find(sheet => {
                return sheet.getAttribute("SheetIdentNo") === sheetIdentNo;
            });
        
            if (requiredSheet) {
                const sheetWeightUnit = requiredSheet.querySelector("SheetWeightUnit").textContent;
                const sheetWeight = parseFloat(requiredSheet.querySelector("SheetWeight").textContent);
                const dimensions = requiredSheet.querySelector("Dimensions");
                const unit = dimensions.querySelector("Unit").textContent;
                const height = parseFloat(dimensions.querySelector("Length").textContent);
                const width = parseFloat(dimensions.querySelector("Width").textContent);


              let breaks= {
                userId:null,
                nameSurname:null,
                reason:null,
                firstTransactionDate:null,
                lastTransactionDate:null,
                description:null
              }
              

                platesArray.push({
                    programNo,
                    processingTime,
                    unit,
                    height,
                    width,
                    piece,
                    sheet: sheetIdentNo,
                    
                    actualTime:null,
                    actualPiece:0,
                    totalTime:null,
                    timeForPlate:null,
                    breaks:breaks,
                    lazerUserId:null,
                    lazerUserNameSurname:null,
                });
            } else {
                console.log("Required sheet not found for program:", programNo);
            }
        });
        
      

        const productionPackage = xmlDoc.querySelector("ProductionPackage");
        const jobName = productionPackage.getAttribute("JobName");

        const totalRuntime = xmlDoc.querySelector("ProductionPackage TotalRuntime").textContent;
        const totalPartCount = xmlDoc.querySelector("ProductionPackage TotalPartCount").textContent;
        
      
        let xmlInfo ={
            setupName: jobName,
            xmlName: jobName,
            parts: partsArray,
            plates: platesArray,
            totalRuntime: convertToTimeFormat(convertTo60Base(totalRuntime)),
            totalPartCount:totalPartCount,
            machine:maxLaserPowerElement,
         
          
        }
        setXmlInfo(xmlInfo)

       
       
        
    }
    
    const [visibleContinue, setVisibleContinue] = useState(false);

    function generateRandomCode(length) {
        const characters = '0123456789';
        let result = '';
      
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters[randomIndex];
        }
      
        return result;
    }

    const handleCancelContinue = () => {
        setVisibleContinue(false);
    };

    const handleOkContinue = async () => {

      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate.setHours(0, 0, 0, 0); 
    

      if (record.isReserved === false || record.isReserved === undefined) { 
      const updatedPurchaseList = record.checkedItemsStockExcess.map(item => ({
        ...item,
        jobCode: jobCode,
        description:null, 
        code: item.code !== undefined ? item.code : 0,
        minPiece: item.minPiece !== undefined ? item.minPiece : 0,
      }));

      //min stok kontrolü için satın alma isteği atılıyor
      for (const item of updatedPurchaseList) {
        const updatedPurchaseListFixed = updatedPurchaseList.map(item => ({  //burda jobcode null olabilir ve currentpiece göndermeye gerek yok
            ...item,
            piece : item.minPiece- item.currentPiece,
            jobCode:null
          }));
        if (item.currentPiece < item.minPiece) {
          try {
            const data = { 
                jobCode: jobCode,
                companyName: record.companyName,
                productType: "raw",
                unitName: "teknik ressam",
                deadline: currentDate,
              //  requestedProductInfo: checkedItemsStockExcess,
                requestedProductInfo:updatedPurchaseListFixed,
               
                description:"Minimum stok altına düşmüştür." ,
                isSendSetup: false,
                depotName:record.depotName

              };

              const result = await FirebaseService.addRecording(parsCollections.purchaseRequests,data);
              if (result.success) {
              console.log(`Purchase request created successfully for item with id ${item.id}.`);
            } else {
              console.log(`Failed to create purchase request for item with id ${item.id}.`);
            }
            
          } catch (error) {
            console.error(`Error creating purchase request for item with id ${item.id}:`, error);
          }
        }
      }
    
      const data = { 
        jobCode: jobCode,
        companyName: record.companyName,
        productType: "raw",
        unitName: "teknik ressam",
        deadline: currentDate,
      //  requestedProductInfo: checkedItemsStockExcess,
        requestedProductInfo:updatedPurchaseList,
        description:"Teknik ressam tarafından seçilmiştir, setupda kullanılacaktır." ,
        isSendSetup: true,
        depotName:record.depotName

      };
      try {
        const result = await FirebaseService.addRecording(parsCollections.purchaseRequests,data);
        if (result.success) {
          setVisibleContinue(false);
        let updatedCheckedItemsStockExcess;

        if (record.checkedItemsStockExcess && record.checkedItemsStockExcess.length > 0) {
          for (const x of record.checkedItemsStockExcess) {
            const { id, piece } = x;
            const result = await FirebaseService.updateStockWaitingPiece(id, piece);
          }
        }
        
        }
        else{
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });
        }
        
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
      }

      }


      const all = await FirebaseService.getDensitiesData();
      const matchedItem = all.find(entry => entry.quality.toLowerCase() === values.quality.toLowerCase());

        const data = {
            surface: values.surface,
            thickness: Number(values.thickness),
            quality:values.quality,
            isCustomerRaw:isCustomerRaw ? isCustomerRaw : false ,
            fuel: matchedItem.fuel

        };

        const updatedRecord = {
            ...record,
            jobCode: record.setupName ? record.jobCode : record.jobCode + "-" + xmlInfo.setupName,
          };

        //  showModal(data, updatedRecord);

     navigate('/setuppartsdetail', { 
        state: { 
            data: data,
            xmlInfo: xmlInfo,
            record:updatedRecord, 
            
        } 
        });



      
    };

    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [selectedJobCodes, setSelectedJobCodes] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);

    const [checkOnFinishData, setCheckOnFinishData] = useState(null);

    
    const handleContinueSetupWithMergeJobCode = () => {
      console.log("selectedJobCodes", selectedJobCodes, selectedCompanies);
      console.log("checkOnFinishData", checkOnFinishData);
      console.log("record", record);
    
      const updatedSelectedCompanies = [...selectedCompanies, record.companyName];
    
      const uniqueCustomerNames = Array.from(new Set(xmlInfo.parts.map(part => part.customerName)));
      const unmatchedCompanies = uniqueCustomerNames.filter(
        customerName => !updatedSelectedCompanies.includes(customerName)
      );
    
      if (unmatchedCompanies.length > 0) {    
        NotificationService.openErrorNotification({
          title: "İşlem Başarısız",
          description: `Bazı müşteri isimleri eşleşmiyor: ${unmatchedCompanies.join(", ")}`,
          placement: "topRight",
        });
    
        return;
      } 
      if (uniqueCustomerNames.length !== updatedSelectedCompanies.length) {
        NotificationService.openErrorNotification({
          title: "İşlem Başarısız",
          description: `Seçilen şirket sayısı (${updatedSelectedCompanies.length}) ile müşteri sayısı (${uniqueCustomerNames.length}) eşleşmiyor.`,
          placement: "topRight",
        });
    
        return;
      }
    
     
      setIsPopupVisible(false);
      checkOnFinish(checkOnFinishData);
      
    };
    
  

    const handlePopupCancel = () => {
      setIsPopupVisible(false);
      setSelectedJobCodes([]);
      setSelectedCompanies([]);
    };
  
    const handleChange = (values) => {
      const selectedData = jobCodes.filter((item) => values.includes(item.jobCode));
    
      const selectedCompanies = selectedData.map((item) => item.companyName);
    
      setSelectedJobCodes(values); 
      setSelectedCompanies(selectedCompanies); 
    };
    


      const checkOnFinish = async (values) => {
     //   setLoading(true);
        const data = await FirebaseService.getOpenOrderData();
        const isAlreadySetup = data.filter(item => !item.isDeleted 
            && item.setupName === xmlInfo.setupName 
            && item.jobCode.substring(0,5) === record.jobCode 
            && item.projectCode === record.projectCode );

        const isAlreadySetupOffer = data.filter(item => !item.isDeleted 
              && item.setupName === xmlInfo.setupName 
              && item.jobCode === record.jobCode
              && item.projectCode === record.projectCode 
            && item.orderType === "offer");
  
      
        if (!xmlInfo || Object.keys(xmlInfo).length === 0) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: 'Lütfen uygun bir xml dosyası yükleyiniz.',
                placement: 'topRight'
            });
            setLoading(false);
            return;
        }

        if (isAlreadySetup.length !== 0) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: 'Daha önce bu xml dosyasyı yüklenmiştir. Lütfen başka xml yükleyiniz.',
                placement: 'topRight'
            });
            setLoading(false);
            return;
        }

        if (isAlreadySetupOffer.length !== 0) {
          NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: 'Daha önce bu xml dosyasyı yüklenmiştir. Lütfen başka xml yükleyiniz.',
              placement: 'topRight'
          });
          setLoading(false);
          return;
      }
        const dataSetup = await FirebaseService.getSetupData();
        const isAlreadySetupSameXmlName = dataSetup.filter(item => !item.isDeleted 
          && item.xmlName === xmlInfo.xmlName 
          && item.jobCode.substring(0,5) === record.jobCode.substring(0,5)
          && item.projectCode === record.projectCode );
  
          let updatedValues={};
          if(record.orderType === "order"){
           updatedValues = { 
              ...values, 
              isCustomerRaw: isCustomerRaw ? isCustomerRaw : false,
              thickness: (record?.stock && record?.stock.length > 0 && record?.stock[0].thickness) 
                        || (record?.wastage && record?.wastage.length > 0 && record?.wastage[0].thickness) 
                        || '',
              quality: (record?.stock && record?.stock.length > 0 && record?.stock[0].quality) 
                       || (record?.wastage && record?.wastage.length > 0 && record?.wastage[0].quality) 
                       || '',
              surface: (record?.stock && record?.stock.length > 0 && record?.stock[0].surface) 
                        || (record?.wastage && record?.wastage.length > 0 && record?.wastage[0].surface) 
                        || ''
            };
          }
          else{
            updatedValues = { 
              ...values, 
              isCustomerRaw: isCustomerRaw ? isCustomerRaw : false,
              thickness: selectedThickness,
              quality: values.quality,
              surface: values.surface
            };
          }
      
        
        if (isAlreadySetupSameXmlName.length !== 0) {
            setIsModalVisibleSameXmlName(true);
            setValues(updatedValues)
            setLoading(false);
            return;    
        }

        const uniqueCustomerNames = new Set(xmlInfo.parts.map(part => part.customerName));

        const jobCodes = await FirebaseService.getOpenOrderData();
        const filteredJobCodes = jobCodes.filter(item => !item.isDeleted && item.orderType === "order");
      
        const uniqueCustomerNamesJobCode = Array.from(new Set(xmlInfo.parts.map(part => part.customerName)));
        const matchingJobCodes = filteredJobCodes.filter(item => uniqueCustomerNamesJobCode.includes(item.companyName));
        
        setJobCodes(matchingJobCodes);
        

        if (uniqueCustomerNames.size > 2 && selectedJobCodes.length === 0 && record.orderType === "order") { 
          setIsPopupVisible(true);
          setCheckOnFinishData(updatedValues);
          setLoading(false);
          return;
        }

        setValues(updatedValues)
        onFinish(updatedValues);
           
      };


      async function getPartData(jobCode, partNo, customerName) {
        const partsData = await FirebaseService.getPartsData(); 
      
        return partsData.find(
          part =>
            part.jobCode === jobCode &&
            part.partNo === partNo &&
            part.customerName === customerName
        );
      }
      
    const onFinish = async (values) => {  
      if(record.orderType==="order"){         
        values.thickness = (record?.stock && record?.stock.length > 0 && record?.stock[0].thickness) 
        || (record?.wastage && record?.wastage.length > 0 && record?.wastage[0].thickness) 
        || '';
        values.quality =(record?.stock && record?.stock.length > 0 && record?.stock[0].quality) 
        || (record?.wastage && record?.wastage.length > 0 && record?.wastage[0].quality) 
        || '';
        values.surface =  (record?.stock && record?.stock.length > 0 && record?.stock[0].surface) 
        || (record?.wastage && record?.wastage.length > 0 && record?.wastage[0].surface) 
        || ''
      }
 
        setLoading(true);
        const currentDate =new Date()
        const uniqueCustomerNames = new Set(xmlInfo.parts.map(part => part.customerName));
        const mergedCompanyNames = recordx.mergedCompanyNames || [];
 
        const setupData = await FirebaseService.getSetupData();

        const filteredSetup = setupData.find(item => !item.isDeleted && item.jobCode===record.jobCode);

        
        if (recordx && Array.isArray(recordx.mergedJobCodes)) {

          function normalizeString(str) {
            return str.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""); 
          }

          const uniqueCustomerNamesArray = Array.from(uniqueCustomerNames).map(name => normalizeString(name));
          const mergedCompanyNamesLower = mergedCompanyNames.map(name => normalizeString(name));

          const unmatchedCompanies = mergedCompanyNamesLower.filter(companyName => 
            !uniqueCustomerNamesArray.includes(companyName)
          );

          if (uniqueCustomerNamesArray.length !== mergedCompanyNamesLower.length) {
            const unmatchedCustomers = uniqueCustomerNamesArray.filter(
              customerName => !mergedCompanyNamesLower.includes(customerName)
            );

            // Eksik müşteri isimlerini hata mesajında göster
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: `Birleşen işler sayısı eşit değildir. Eşleşmeyen müşteri isimleri: ${unmatchedCustomers.join(', ')}`,
              placement: 'topRight',
            });
            setLoading(false);
            return;
          }

          if (unmatchedCompanies.length > 0) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: `Şirket adları eşleşmiyor. Eksik olanlar: ${unmatchedCompanies.join(', ')}`,
              placement: 'topRight',
            });
            setLoading(false);
            return;
          }

        }
         else {
          setLoading(false);
        }
        
    
        const all = await FirebaseService.getDensitiesData();
        const matchedItem = all.find(entry => entry.quality.toLowerCase() === values.quality.toLowerCase());
  
        //teklif için parçalarda gerekiyo
        let updatedParts = xmlInfo.parts.map(part => {
            const updatedPart = { ...part };
            updatedPart.quality = values.quality;
            updatedPart.surface = values.surface;
            updatedPart.thickness = Number(values.thickness);
            updatedPart.fuel = matchedItem.fuel;
            updatedPart.jobCode = record.jobCode;
            updatedPart.projectCode =record.projectCode ? record.projectCode : null ;
            updatedPart.companyName =record.companyName;
            updatedPart.setupName = record.jobCode.substring(6);
            return updatedPart;
          });
          
          xmlInfo.parts = updatedParts;
          
          const isReserved = record.isReserved !== undefined ? record.isReserved : true;

        //eğer siparişse
        if (record.orderType ==="order" && ((record.stock && record.stock.length > 0) || (record.wastage && record.wastage.length > 0))) {
            setLoading(true);
            const groupedPlates = {};
            let matchedPlates = [];
            let unmatchedPlates = [];
            let continuee=true;
          
            xmlInfo.plates.forEach(plate => {
                const { width, height, piece } = plate;
                const key = `${width}-${height}`;
                if (groupedPlates[key]) {
                    groupedPlates[key].piece += piece;
                } else {
                    groupedPlates[key] = { width, height, piece };
                }
            });
                  
            const matchedProgramNos = [];
            
            if (Object.keys(groupedPlates).length >= 1) {
                Object.values(groupedPlates).forEach(group => {
                    let cumulativePieces = 0;
            
                    const matchedStock = record.stock.filter(stockItem => {
                        return (
                            ((stockItem.height === group.height && stockItem.width === group.width) ||
                             (stockItem.height === group.width && stockItem.width === group.height)) &&
                            stockItem.thickness === values.thickness &&
                            stockItem.quality === values.quality &&
                            stockItem.surface === values.surface
                        );
                    });
            
                    matchedStock.forEach(stockItem => {
                        cumulativePieces += stockItem.piece;
                    });
            
                    if (cumulativePieces < group.piece) {
                        const matchedWastage = record.wastage.filter(wastageItem => {
                            return (
                                ((wastageItem.height === group.height && wastageItem.width === group.width) ||
                                 (wastageItem.height === group.width && wastageItem.width === group.height)) &&
                                wastageItem.thickness === values.thickness &&
                                wastageItem.quality === values.quality &&
                                wastageItem.surface === values.surface
                            );
                        });
            
                        matchedWastage.forEach(wastageItem => {
                            cumulativePieces += wastageItem.piece;
                        });
                    }  
        
                    if (cumulativePieces > group.piece) {
                      continuee= false;
                     
                    }
                    else if (cumulativePieces < group.piece) { 
                        continuee= false; 
                      
                    }
            
                  else {
                  if (cumulativePieces === group.piece && continuee) {
                    xmlInfo.plates.forEach(plate => {
                        const matchedStock = record.stock.find(stockItem => {
                            return (
                                ((stockItem.height === plate.height && stockItem.width === plate.width) ||
                                 (stockItem.height === plate.width && stockItem.width === plate.height)) &&
                                stockItem.thickness === values.thickness &&
                                stockItem.quality === values.quality &&
                                stockItem.surface === values.surface
                            );
                        });
        
                        if (matchedStock) {
                            if (!matchedProgramNos.includes(plate.programNo)) {
                                const matchedPlate = {
                                    ...plate,
                                    id: matchedStock.id,
                                    quality: matchedStock.quality,
                                    surface: matchedStock.surface,
                                    thickness: matchedStock.thickness,
                                    type: "stock",
                                    actualTime: null,
                                    wastagesKg: 0,
                                    depotName: matchedStock.depotName
                                };
                                matchedPlates.push(matchedPlate);
                                matchedProgramNos.push(plate.programNo);
                            }
                        } else {
                            const matchedWastage = record.wastage.find(wastageItem => {
                                return (
                                    ((wastageItem.height === plate.height && wastageItem.width === plate.width) ||
                                     (wastageItem.height === plate.width && wastageItem.width === plate.height)) &&
                                    wastageItem.thickness === values.thickness &&
                                    wastageItem.quality === values.quality &&
                                    wastageItem.surface === values.surface
                                );
                            });
        
                            if (matchedWastage && !matchedProgramNos.includes(plate.programNo)) {
                                const matchedPlate = {
                                    ...plate,
                                    id: matchedWastage.id,
                                    quality: matchedWastage.quality,
                                    surface: matchedWastage.surface,
                                    thickness: matchedWastage.thickness,
                                    type: "wastage",
                                    actualTime: null,
                                    wastagesKg: 0,
                                    depotName: matchedWastage.depotName
                                };
                                matchedPlates.push(matchedPlate);
                                matchedProgramNos.push(plate.programNo);
                            }
                        }
                    });
                  } 
                  else{
                      unmatchedPlates.push(group);
                  }
                      
                    }
                });
            }
            
            else {
                xmlInfo.plates.forEach(plate => {
                    const matchedStock = record.stock.find(stockItem => {
                        return (
                            ((stockItem.height === plate.height && stockItem.width === plate.width) ||
                             (stockItem.height === plate.width && stockItem.width === plate.height)) &&
                            stockItem.thickness === values.thickness &&
                            stockItem.piece === plate.piece &&
                            stockItem.quality === values.quality &&
                            stockItem.surface === values.surface
                        );
                    });
            
                    if (matchedStock) {
                        const matchedPlate = {
                            ...plate,
                            id: matchedStock.id,
                            quality: matchedStock.quality,
                            surface: matchedStock.surface,
                            thickness: matchedStock.thickness,
                            type: "stock",
                            actualTime: null,
                            wastagesKg: 0,
                            depotName: matchedStock.depotName
                        };
                        matchedPlates.push(matchedPlate);
                    } else {
                        unmatchedPlates.push(plate);
                    }
                });
            }
            
            if (unmatchedPlates.length > 0 || !continuee) {
                NotificationService.openErrorNotification({
                    title: 'Geçersiz Form',
                    description: 'Stoktan seçilenlerle XMl dosyasındaki plakalar eşlememektir. Lütfen düzenleyiniz.',
                    placement: 'topRight'
                });
                setLoading(false);
                setIsModalVisibleSameXmlName(false);
                setTimeout(() => {
                    onClose();
                }, 2000);
            } 
            

            else if(unmatchedPlates.length === 0  && continuee){
                let density=1;
                const selectedQuality = qualities.find(item => item.quality === values.quality);
                if (selectedQuality) {
                density = selectedQuality.density;
                }

            matchedPlates.forEach((plate, index) => {
                    const area = plate.height * plate.width;
                    const weight = area * Number(values.thickness) * density;
                    const weightInKg = (weight / 1000000).toFixed(3); 
                    matchedPlates[index].kg = weightInKg * plate.piece;
                });

            xmlInfo = {
                ...xmlInfo,
                plates: matchedPlates
                };
                setXmlInfo(xmlInfo);

                //bekleyen siparişten eğer sipariş seçip sadece güncel stoktan rezerve vs yapıldıysa
                if(record.isSetup === false && record.orderType === "order" && isReserved){

                        const all = await FirebaseService.getDensitiesData();
                        const matchedItem = all.find(entry => entry.quality.toLowerCase() === values.quality.toLowerCase());
                  
                        const data = {
                            thickness: Number(values.thickness),
                            isCustomerRaw:isCustomerRaw ? isCustomerRaw : false,
                            fuel:matchedItem.fuel,
                            quality: values.quality,
                            surface: values.surface                         
                        };
                     
                        const item = {
                        firstTransactionDate: currentDate,
                        firstUserId: localStorage.getItem("uid"),
                        programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
                        isDeleted: false,
                        thickness :data.thickness,
                        quality :data.quality,
                        surface :data.surface,
                        fuel: data.fuel,
                        setupTime :xmlInfo.totalRuntime,
                        setupName :record.jobCode.substring(6),
                        machine :xmlInfo.machine,
                        jobCode :record.jobCode,
                        projectCode :record.projectCode ? record.projectCode : null ,
                        companyName :record.companyName,
                        parts:xmlInfo.parts,
                        plates:xmlInfo.plates,
                        isCustomerRaw: data.isCustomerRaw ? data.isCustomerRaw : false,
                        abkantTwistShape:null,
                        abkantUserCount:0,
                        abkantActualTime:null,
                        abkantIsFinish:false,
                        isShipment:false,
                        isMerge:false,
                        xmlName: xmlInfo.xmlName
          
                    };

                    if(filteredSetup){

                      const result = await FirebaseService.updateRecording(parsCollections.setup, filteredSetup.key, item);                    
                      if (result.success) {
                        for (const part of updatedParts) {
                        
                          const existingPart = await getPartData(part.jobCode, part.partNo, part.customerName);
                          console.log("existingPart",existingPart,"part",part)
                          try {
                          let result = await FirebaseService.updateRecording(parsCollections.parts, existingPart.key, part);
                            if (result) {
                              console.log(`part with key ${part.key}  successfully.`);
                            } else {
                              console.log(`Failed to item with key ${part.key}.`);
                            }
                          } catch (error) {
                            console.error(`Error  item with key ${part.key}:`, error);
                          }
                        }
                      //  const delayTime = 20000 + (updatedParts.length * 2000) + (xmlInfo.plates.length * 2000); 

      
                      const delayTime = 10000 + (updatedParts.length * 500) + (xmlInfo.plates.length * 1000); 


                        setTimeout(() => {
                            navigate('/setuppartsdetail', { 
                                state: { 
                                    data: data,
                                    xmlInfo: xmlInfo,
                                    record: record,
                                    selectedJobCodes: [...selectedJobCodes, record.jobCode],
                                    selectedCompanies:  [...selectedCompanies, record.companyName],
                                  } 
                            });
                            setLoading(false);
                        }, delayTime);
                        
                          
                    }
                    else{
                      console.log(" hata setup item yüklenemedi 1")
    
                    }
                    }
                    else{

                      const result = await FirebaseService.addRecording(parsCollections.setup, item);
 

                      let mergedJobCodes = selectedJobCodes.map((code) => 
                        code.includes("-") 
                            ? code.substring(0, 5) + "-" + record.jobCode.substring(6) 
                            : code + "-" + record.jobCode.substring(6)
                    );
                    
                    const customerJobCodeMap = {};
                    
                    // Seçili şirketler ve iş kodlarını haritaya ekleyelim
                    selectedCompanies.forEach((company, index) => {
                        customerJobCodeMap[company] = mergedJobCodes[index];
                    });
                    
                    // record.companyName için de iş kodunu ekleyelim
                    customerJobCodeMap[record.companyName] = record.jobCode;
                    
                    console.log("customerJobCodeMap:", customerJobCodeMap);
                    

                      if (result.success) {

                        let updatedPartsMergedJobCode = updatedParts.map(part => {
                          const updatedPart = { ...part };
                          console.log("Part bilgisi:", updatedPart);
                      
                          const matchingJobCode = customerJobCodeMap[updatedPart.customerName] || record.jobCode;
                          console.log(`Customer: ${updatedPart.customerName}, Eşleşen İş Kodu: ${matchingJobCode}`);
                      
                          updatedPart.jobCode = matchingJobCode; 
                          updatedPart.quality = values.quality;
                          updatedPart.surface = values.surface;
                          updatedPart.thickness = Number(values.thickness);
                          updatedPart.fuel = matchedItem.fuel;
                          updatedPart.projectCode = record.projectCode || null;
                          updatedPart.companyName = updatedPart.customerName; 
                      
                          updatedPart.setupName = matchingJobCode ? matchingJobCode.substring(6) : "";
                      
                          return updatedPart;
                      });
                      
                    
  
  

                        
                          for (const part of updatedPartsMergedJobCode) {
                            
                            try {
                            let result = await FirebaseService.addRecording(parsCollections.parts, part);
                              if (result) {
                                console.log(`part with key ${part.key}  successfully.`);
                              } else {
                                console.log(`Failed to item with key ${part.key}.`);
                              }
                            } catch (error) {
                              console.error(`Error  item with key ${part.key}:`, error);
                            }
                          }
                         // const delayTime = 20000 + (updatedParts.length * 2000) + (xmlInfo.plates.length * 2000); 
                  
                          const delayTime = 10000 + (updatedParts.length * 500) + (xmlInfo.plates.length * 1000); 

                          setTimeout(() => {
                          
                              navigate('/setuppartsdetail', { 
                                  state: { 
                                      data: data,
                                      xmlInfo: xmlInfo,
                                      record: record,
                                      selectedJobCodes: [...selectedJobCodes, record.jobCode],
                                      selectedCompanies:  [...selectedCompanies, record.companyName],

                                  } 
                              });
                              setLoading(false);
                          }, delayTime);
                          
                            
                      }
                      else{
                        console.log(" hata setup item yüklenemedi")
      
                      }
                    }

                  
                 

                }

              

               
            }


        }

        //eğer teklifse yada teklif onaylanmayıp setup güncellenme geldiyse 
        else { 
          console.log("buraya geldi teklif")
            setLoading(true);
            let platesUpdateKg = xmlInfo.plates;
            let density=1;
            const selectedQuality = qualities.find(item => item.quality === values.quality);
            if (selectedQuality) {
            density = selectedQuality.density;
            }

            platesUpdateKg.forEach((plate, index) => {
                const area = plate.height * plate.width;
                const weight = area * Number(values.thickness) * density;
                const weightInKg = (weight / 1000000).toFixed(3); 
                platesUpdateKg[index].kg = weightInKg * plate.piece;
            });

             xmlInfo = {
            ...xmlInfo,
            plates: platesUpdateKg
            };
            setXmlInfo(xmlInfo);

            let updatedRecord = {};

            //yeni open order ekleme kısmı
            const openOrderData = await FirebaseService.getOpenOrderData();
            const filteredData = openOrderData.find(item => !item.isDeleted && item.isWaiting && item.orderType === "offer" && item.jobCode === record.jobCode.substring(0,5) && item.setupName ===null);
            console.log("filteredData",filteredData)
            if(!filteredData){
              const currentDate =new Date()              
              const newOrder = {
                firstTransactionDate: currentDate,
                  firstUserId: localStorage.getItem("uid"),
                  programmerUserId:localStorage.getItem("uid"),
                  isDeleted: false,
                  programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
                  companyName: record.companyName,
                  projectCode: record.projectCode,
                  orderType :"offer",         
                  isSetup:true,
                  isOffer:false,
                  isWaiting: true,
                  isOfferApproved:false,
                  noOfferApprovedDescription:null,
                  noQrScanDescription:null,
                  isOfferSetupUpdate:false,
                  isOfferChange:false,
                  isOfferDenied:false,
                  isChangedProgrammer:false,
                  isShipment: false,
                  shipmentPiece: 1,
                  invoiceNumber:null,
                  setupName: xmlInfo.setupName,
                  deadline: new Date(record.deadline.seconds * 1000),
                  jobCode: record.jobCode.substring(0,5) + "-" +  xmlInfo.setupName,
                  stock: [],
                  wastage: [],
                  isOfferable:false,
                  isAbkant:false

              };
      
               const createResult = await FirebaseService.addRecording(parsCollections.openOrder,newOrder);
               console.log("buranın redsult",createResult)
             }
     
             
            if(record.orderType === "offer" && record.isOffer && record.isOfferSetupUpdate){
                 updatedRecord = {
                    ...record,
                jobCode: record.jobCode
                };
                
            }

            //teklife setup yükleme sadece iş kodu almış
            else if(record.orderType === "offer" && !record.isOffer && !record.isSetup && !record.isOfferApproved ){
              updatedRecord = {
                    ...record,
                jobCode: record.jobCode  + "-" + xmlInfo.setupName,
                };
                
            }
            //teklif onaylanmış setup yüklendi jobcode güncellenmeli
            else if(record.orderType === "offer" && record.isOffer && !record.isSetup  && record.isOfferApproved ){
              updatedRecord = {
                    ...record,
                jobCode: record.jobCode + "-" + xmlInfo.setupName, 
                };
                
            }
            else{
                //iş kodu setup ismine göre ayarlanıyor
                updatedRecord = {
                  ...record,
                  jobCode: record.jobCode.substring(0,5) +  "-" +  xmlInfo.setupName
              };       
                
            }

            console.log("updatedRecord",updatedRecord)
            
            const all = await FirebaseService.getDensitiesData();
            const matchedItem = all.find(entry => entry.quality.toLowerCase() === values.quality.toLowerCase());
      
            const data = {
                surface: values.surface,
                thickness: Number(values.thickness),
                quality:values.quality,
                isCustomerRaw:isCustomerRaw ? isCustomerRaw : false ,
                fuel: matchedItem.fuel

            };
        
           updatedParts = xmlInfo.parts.map(part => {
              const updatedPart = { ...part };
          
              updatedPart.quality = values.quality;
              updatedPart.surface = values.surface;
              updatedPart.thickness = Number(values.thickness);
              updatedPart.fuel = matchedItem.fuel;
              updatedPart.projectCode = record.projectCode ? record.projectCode : null;
              updatedPart.companyName = record.companyName;
              updatedPart.setupName = record.jobCode.substring(6);
          
              if (record.orderType === "offer" && record.isOffer && record.isOfferSetupUpdate) {
                  updatedPart.jobCode = record.jobCode; 
              }
              else if (record.orderType === "offer" && !record.isOffer && !record.isSetup && !record.isOfferApproved) {
                  updatedPart.jobCode = record.jobCode + "-" + xmlInfo.setupName; 
              }
              else if (record.orderType === "offer" && record.isOffer && !record.isSetup && record.isOfferApproved) {
                  updatedPart.jobCode = record.jobCode + "-" + xmlInfo.setupName; 
              }
              else {
                if(!filteredData){
                  updatedPart.jobCode = record.jobCode.substring(0,5) +  "-" +  xmlInfo.setupName
                }
                else{
                  updatedPart.jobCode = record.setupName ? record.jobCode : record.jobCode + "-" + xmlInfo.setupName;
                }
              }
          
              return updatedPart;
          });

          xmlInfo.parts = updatedParts;
          
        const item = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
          isDeleted: false,  
          thickness :data.thickness,
          quality :data.quality,
          surface :data.surface,
          fuel: data.fuel,
          setupTime :xmlInfo.totalRuntime,
          setupName :xmlInfo.setupName,
          machine :xmlInfo.machine,
          jobCode :updatedRecord.jobCode,
          projectCode :record.projectCode ? record.projectCode : null ,
          companyName :record.companyName,
          parts:xmlInfo.parts,
          plates:xmlInfo.plates,
          isCustomerRaw: data.isCustomerRaw ? data.isCustomerRaw : false,
          abkantTwistShape:null,
          abkantUserCount:0,
          abkantActualTime:null,
          abkantIsFinish:false,
          isShipment:false,
          isMerge:false,
          xmlName: xmlInfo.xmlName


         };

        const result = await FirebaseService.addRecording(parsCollections.setup, item);
        if (result.success) {
            for (const part of updatedParts) {          
              try {
              let result = await FirebaseService.addRecording(parsCollections.parts, part);
                if (result) {
                  console.log(`teklif part with key ${part.key}  successfully.`);
                } else {
                  console.log(`Failed to item with key ${part.key}.`);
                }
              } catch (error) {
                console.error(`Error  item with key ${part.key}:`, error);
              }
            }

            //const delayTime = 20000 + (updatedParts.length * 2000) + (xmlInfo.plates.length * 2000); 
            const delayTime = 10000 + (updatedParts.length * 500) + (xmlInfo.plates.length * 1000); 


            setTimeout(() => {
            
              navigate('/setuppartsdetail', { 
                state: { 
                  data: data,
                  xmlInfo: xmlInfo,
                  record: updatedRecord,
                  
                } 
              });
              setLoading(false);
            }, delayTime);
            
              
          }
        else{
         console.log(" hata setup item yüklenemedi 2")
        }   
        
        }   
      
    
        
    }; 



    const columnsStockExcess = [
        {
          title: 'Kalite',
          dataIndex: 'quality',
          key: 'quality',
        
         // ...getColumnSearchPropsStock('quality'),
          sorter: (a, b) => a.quality.localeCompare(b.quality), 
          render: (text) => <span style={{  }}>{text}</span>,
        },
    
          {
            title: 'Kalınlık',
            dataIndex: 'thickness',
            key: 'thickness',
           
          //  ...getColumnSearchPropsStock('thickness'),
            sorter: (a, b) => a.thickness - b.thickness,
            render: (text) => <span style={{ }}>{text}</span>,
          },
    
         
          {
            title: 'En',
            dataIndex: 'width',
            key: 'width',
           
           // ...getColumnSearchPropsStock('width'),
            sorter: (a, b) => a.width - b.width,
            render: (text) => <span style={{  }}>{text}</span>,
          },
          {
            title: 'Boy',
            dataIndex: 'height',
            key: 'height',
           // ...getColumnSearchPropsStock('height'),
            sorter: (a, b) => a.height - b.height,
            render: (text) => <span style={{  }}>{text}</span>,
          },
    
          {
            title: 'Yüzey',
            dataIndex: 'surface',
            key: 'surface',
           // ...getColumnSearchPropsStock('surface'),
            sorter: (a, b) => a.surface.localeCompare(b.surface), 
            render: (text) => <span style={{  }}>{text}</span>,
          },
     
          {
            title: 'Adet',
            dataIndex: 'piece',
            key: 'piece',
            render: (text,record) => <span style={{}}>{text}</span>,
           
            sorter: (a, b) => a.piece - b.piece,
           // ...getColumnSearchPropsStock('piece'),
          },         
        
    ];


    const showModal = (data, updatedRecord) => {
        setModalData(data);
        setUpdatedRecord(updatedRecord);
        setIsModalVisible(true); 
      };

      const getFileNameWithoutPrefixAndSuffixPlates = (fileName) => {
        if (fileName.startsWith('TMT_') && fileName.endsWith('.bmp')) {
          return fileName.slice(4, -4); // "TNT_" prefix'i ve ".bmp" suffix'i keser
        }
        return fileName; // Eğer dosya adı belirtilen formatta değilse, olduğu gibi döner
      };

      const convertDxfToSvgAndUpdateParts = async (fileListPartImage, xmlInfo) => {
        // DXF dosyalarını ConvertAPI ile SVG'ye dönüştürme
        const uploadPromises = fileListPartImage.map(async (file) => {
          try {
            // DXF dosyasını Base64 formatına çeviriyoruz
            const toBase64 = (file) => new Promise((resolve, reject) => {
              const reader = new FileReader();
              
              // Kontrol: file nesnesinin type ve instanceof uygunluğunu doğrulama
              if (file.originFileObj instanceof Blob || file.originFileObj instanceof File) {
                reader.readAsDataURL(file.originFileObj);
              } else {
                reject(new TypeError('FileReader expects a Blob or File instance'));
              }
              
              reader.onload = () => resolve(reader.result.split(',')[1]); // Base64 içeriği
              reader.onerror = error => reject(error);
            });
            
            const base64FileContent = await toBase64(file);
            
            // ConvertAPI'ye POST isteği gönderiyoruz
            const response = await fetch('https://v2.convertapi.com/convert/dxf/to/svg?Secret=secret_wPHQ685r70ENGJ8f', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                "Parameters": [
                  {
                    "Name": "File",
                    "FileValue": {
                      "Name": file.originFileObj.name,
                      "Data": base64FileContent
                    }
                  },
                  {
                    "Name": "StoreFile",
                    "Value": true
                  }
                ]
              }),
            });
        
            const result = await response.json();

            const svgUrl = result.Files[0].Url; 
            const fileName = result.Files[0].FileName; 
            let firebaseStorageUrl = ""; 

            try {
              const responseSvg = await fetch(svgUrl);
              const svgBlob = await responseSvg.blob();
              firebaseStorageUrl = await FirebaseStorage.uploadImageAndGetURLParts("partImages", svgBlob, fileName); // Pass the Blob and file name
            } catch (error) {
              console.error("Error downloading or uploading SVG file:", error);
            }



            return {
              fileName: file.originFileObj.name,
              svgUrl,
              firebaseStorageUrl
            };
          } catch (err) {
            console.error("Error converting DXF to SVG:", err);
            return null;
          }
        });
      
        const convertedFiles = await Promise.all(uploadPromises);
        xmlInfo.parts.forEach((part) => {
      
          const matchingFile = convertedFiles.find((file) => {
            return file && file.fileName.includes(part.descriptionForImageName);
          });
      
          if (matchingFile) {
            part.imageUrl = matchingFile.svgUrl; 
            part.imageUrlStorage = matchingFile.firebaseStorageUrl; 
          } else {
            console.log("No matching URL found for:", part.descriptionForImageName);
          }
        });
      };
      
      

      // Bu fonksiyon, ConvertAPI ve parça güncelleme işlemini tek bir akışta gerçekleştirir.
      // burası da kullanılmıyor şuan 
      const handleOk = async() => {

        setLoading(true);
        const uploadedFileNamesParts = fileListPartImage.map(file => file.name);

        if (uploadedFileNamesParts.length === 0) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: 'Parça fotoğrafları yükleyiniz.',
                placement: 'topRight'
            });
            setLoading(false);
            return;
        }
        const cleanedFileNamesParts = uploadedFileNamesParts.map(name => 
          name.replace(/\.(jpg|svg|pdf|png|dxf)$/i, '').trim()
      );
        const imageUrlsParts = xmlInfo.parts.map(part => part.descriptionForImageName.trim());
        const missingInUploaded = imageUrlsParts.filter(name => !cleanedFileNamesParts.includes(name));
 
        const extraFiles = cleanedFileNamesParts.filter(file => !imageUrlsParts.includes(file));

        if (missingInUploaded.length > 0) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Aşağıdaki parçalar yüklenmedi: ${missingInUploaded.join(', ')}`,
                placement: 'topRight'
            });
            setLoading(false);
            return;
        }
        
        if (cleanedFileNamesParts.length !== imageUrlsParts.length) {
          if (cleanedFileNamesParts.length > imageUrlsParts.length) {
      
              NotificationService.openErrorNotification({
                  title: 'İşlem Başarısız',
                  description: `Yüklenilen fazla parça fotoğrafları var. Fazla olanlar: ${extraFiles.join(', ')}`,
                  placement: 'topRight',
              });
          } else {
              const missingInUploaded = cleanedFileNamesParts.slice(imageUrlsParts.length);
      
              NotificationService.openErrorNotification({
                  title: 'İşlem Başarısız',
                  description: `Yüklenilen parça fotoğrafları eksik. Eksik parçalar: ${missingInUploaded.join(', ')}`,
                  placement: 'topRight',
              });
          }
          
          setLoading(false);
          return;
      }
      


        const uploadedFileNamesPlates = fileListPlateImage.map(file => getFileNameWithoutPrefixAndSuffixPlates(file.name));
        if (uploadedFileNamesPlates.length === 0) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: 'Plaka fotoğrafları yükleyiniz.',
                placement: 'topRight'
            });
            setLoading(false);
            return;
        }

      
        const imageUrlsPlates = xmlInfo.plates.map(plate => plate.programNo);
        const missingImagesPlates = uploadedFileNamesPlates.filter(name => !imageUrlsPlates.includes(name));
    
         if (missingImagesPlates.length > 0) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description:`Plaka için aşağıdaki dosyalar eşleşmiyor: ${missingImagesPlates.join(', ')}`,
                placement: 'topRight'
            });
            setLoading(false);
            return;
        } 
        if(uploadedFileNamesPlates.length !== imageUrlsPlates.length){
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description:"Yüklenilen plaka fotoğrafları eksik.",
                placement: 'topRight'
            });
            setLoading(false);
            return;
        }

        const folderName = 'setupDocuments';

        if (fileListSetupPdf && fileListSetupPdf.length > 0) {
            const selectedDocument = fileListSetupPdf[0].originFileObj; 
            const fileName = selectedDocument.name;

            const documentUrl = await StoregeService.uploadDocumentAndGetURL(folderName, selectedDocument, fileName);

            if (documentUrl && typeof documentUrl === 'string') {
                modalData.documentUrl = documentUrl; 
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description:"Yüklenilen hata oluştu.",
                placement: 'topRight'
            });
            setLoading(false);
            return;
            }
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description:"Yüklenecek PDF dosyası bulunamadı.",
            placement: 'topRight'
          });
          setLoading(false);
          return;
        }

       
              

        const uploadPromisesPlates = fileListPlateImage.map(file => FirebaseStorage.uploadImageAndGetURL('setupPlatesImages', file.originFileObj));
        const urlsPlates = await Promise.all(uploadPromisesPlates);

        xmlInfo.plates.forEach((plate) => {
            
            if (uploadedFileNamesPlates.includes(plate.programNo)) {
            const matchingUrl = urlsPlates.find(url => url.includes(plate.programNo));
            if (matchingUrl) {
                plate.imageUrl = matchingUrl;
            }
            }
        });

       // await convertDxfToSvgAndUpdateParts(fileListPartImage, xmlInfo);
      
        navigate('/setuppartsdetail', {
            state: {
              data: modalData,
              xmlInfo: xmlInfo,
              record: updatedRecord,
            },
        });

      

      
      
       
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };

      const handleCancelSameXmlName = () => {
        setIsModalVisibleSameXmlName(false);
      };

      
      const handleFileChangePartImage = ({ fileList: newFileList }) => {
      
        setFileListPartImage(newFileList);
      };

      const handleFileChangePlateImage = ({ fileList: newFileList }) => {
        setFileListPlateImage(newFileList);
      };

      const handleFileChangeSetupPdf = ({ fileList: newFileList }) => {
        setFileListSetupPdf(newFileList);
      };

      const [isModalVisibleDensities, setIsModalVisibleDensities] = useState(false);
      const [selectedThickness, setSelectedThickness] = useState(0); 

      const handleModalCancelDensities = () => {
          setIsModalVisibleDensities(false);
      };
    
      const handleAddFinishDensities = () => {
          setIsModalVisibleDensities(false);
      };
  
      const handleChangeThickness = (e) => {
        const newValue = parseFloat(e.target.value);  
        setSelectedThickness(newValue);
      };
      
    const onChange = (value) => {
        if (value === 'newQuality') {
            setIsModalVisibleDensities(true);
        } 
        else{
          
        }
    };

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 6,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={checkOnFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

            <Form.Item
            label="XML Dosyası"
            name="xml"
            rules={[
                {
                required: true,
                message: 'Lütfen xml dosyası yükleyiniz!',
                },
            ]}
            wrapperCol={{
                span: 6,
            }}
            style={{
                maxWidth: "100%",
                textAlign: "left"
            }}
            >
            <Upload
                beforeUpload={(file) => {
                setXmlFile(file);
                return false; 
                }}
                showUploadList={false} 
                onChange={handleXmlFileChange} 
            >
                <Button
                icon={<UploadOutlined />}
                style={{ width:"100%" }}
                className="input-style" 
                >
                XML Dosyası Yükle
                </Button>
            </Upload>
            {xmlFile && <div style={{ marginTop: 8 , width:"400px"}}>{xmlFile.name}</div>}

            </Form.Item>


        {record && record.orderType === "offer" ? 
         <>
            <Form.Item
            label="Kalite"
            name="quality"
            rules={[
                {
                required: true,
                message: 'Lütfen kalite giriniz!',
                },
            ]}
            >
            <Select
            showSearch
                onChange={onChange}
                placeholder="Kalite Seçiniz"
             
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                options={[
                  ...qualities.map(item => ({ label: item.quality, value: item.quality })),
                  { label: 'Yeni Kalite Ekle', value: 'newQuality' },
                ]}
                className="input-style" 
                filterOption={(input, option) => 
                  option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
                }
            />
            </Form.Item>

           
            <Modal
                title="Kalite Ekleme Talebi"
                visible={isModalVisibleDensities}
                onCancel={handleModalCancelDensities}
                footer={null}
              >
              <AddRequestAdminDensities onClose={handleAddFinishDensities} />
              </Modal>
            

            <Form.Item
            label="Kalınlık"
                name="thickness"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen kalınlık giriniz!',
                    },
                    {
                        pattern: /^\d+(\.\d+)?$/, // Ondalık sayıları da kabul eden desen
                        message: 'Lütfen geçerli bir kalınlık değeri giriniz.',
                      },
                ]}
              
                >
            <Input    className="input-style"  onChange={handleChangeThickness } style={{width:"100%"}} placeholder="Kalınlık"/>
            </Form.Item>

            <Form.Item
            label="Yüzey"
                name="surface"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen Yüzey Giriniz!',
                    },
                ]}
            
                >
                      <Select
                      showSearch
                onChange={onChange}
                placeholder="Yüzey Seçiniz"
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                options={[
                  ...surfaces.map(item => ({ label: item.surface, value: item.surface })),
                //  { label: 'Yeni Firma Ekle', value: 'newCompany' },
                ]}
                className="input-style"  
                filterOption={(input, option) => 
                  option.label.toLowerCase().includes(input.toLowerCase())
                }   

            />
            </Form.Item>
            </> :
            <></>
            }
                    

            <Form.Item
              name="isCustomerRaw">
              <Checkbox   onChange={() => setIsCustomerRaw(!isCustomerRaw)} style={{fontWeight:"500"}}>
             Müşteri Sacı kullanılacak.
              </Checkbox>
            </Form.Item>

        <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>İleri</Button>
            </Form.Item>
            {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
        </Form>


        <div>           
          <Modal
                  title="Satın Alma Talebi Gönder"
                  visible={visibleContinue}
                  onOk={handleOkContinue}
                  onCancel={handleCancelContinue}
                  okText="Evet, Gönder ve Açık Sipariş Oluşturmaya Devam Et"
                  cancelText="Vazgeç"
                  className="columnsStockExcess popup-modal-button"
                
              >
              <div> 
                <p>Satın alma talebi göndermek istediğinizden emin misiniz?</p>
                <Table
                    dataSource={recordx.checkedItemsStockExcess}
                    columns={columnsStockExcess}
                    showHeader={true}
                    pagination={false} 
                    className="custom-news-table"
                    rowClassName="custom-news-row"
                    locale={{
                        emptyText: 'Henüz eklemediniz...',
                        filterReset: 'Sıfırla',
                        filterTitle: 'Filtre Menüsü',
                        selectAll: 'Hepsini Seç',
                        selectInvert: 'Tersini Seç',
                        selectionAll: 'Tümünü Seç',
                        sortTitle: 'Sıralama',
                        triggerDesc: 'Azalan sıralama için tıklayın',
                        triggerAsc: 'Artan sıralama için tıklayın',
                        cancelSort: 'Sıralamayı iptal etmek için tıklayın',
                      }}
                />
            </div>
        </Modal>


        <Modal
        title="Uyarı"
        visible={isModalVisibleSameXmlName}
        onCancel={handleCancelSameXmlName}
        footer={[
          <Button key="cancel" onClick={handleCancelSameXmlName}>
            İptal
          </Button>,
          <Button key="save"   style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}   onClick={() => onFinish(values)}>
            Devam Et
          </Button>,
        ]}
      >
     <div>
     Daha önce bu xml dosyası farklı bir isim ile yüklenmiştir. Devam etmek isterseniz “Devam Et”e, çıkmak için “İptal”e basınız',
     </div>
     {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
      </Modal>


      <Modal
        title="İş Kodunu Seçiniz"
        visible={isPopupVisible}
        onCancel={handlePopupCancel}
        footer={null} 
        maskClosable={false} 
      
      >
      <div style={{ height: "170px", marginTop: "20px", position: "relative" }}>
  <div>
    Yüklediğiniz setupta başka müşteriler bulunmaktadır. Lütfen diğer iş kodlarını seçiniz.
  </div>
  <Select
    showSearch
    placeholder="İş Kodu Seçiniz"
    suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
    options={jobCodes
      .sort((a, b) => b.jobCode.localeCompare(a.jobCode)) 
      .map((item) => ({
        label: `${item.jobCode}  Firma: ${item.companyName}`,
        value: item.jobCode,
      }))}
    className="input-style"
    mode="multiple" 
    filterOption={(input, option) =>
      option.label.toLowerCase().includes(input.toLowerCase())
    }
    onChange={handleChange}
    value={selectedJobCodes}
    style={{width:"100%",marginTop:"20px"}}
  />
  <Button
    onClick={() => handleContinueSetupWithMergeJobCode()}
    style={{
      backgroundColor: colors.blue,
      color: "white",
      fontWeight: "500",
      position: "absolute",
      bottom: "-10px",
      right: "10px",
    }}
  >
    Setup Yüklemeye Devam Et
  </Button>

</div>

      </Modal>


        <div>
      <Modal
        title="Setup İçeriği Yükle"
        visible={isModalVisible}
        onOk={handleOk} 
        onCancel={handleCancel}
        okText="Yükle"
        cancelText="Vazgeç"
      
        className=" setup-content popup-modal-button"

      >
        <div style={{display:"flex" }}>


        <Upload
          action="/upload.do"
          listType="picture"
          fileList={fileListPartImage}
          onChange={handleFileChangePartImage}
          multiple
          beforeUpload={() => false}
          
        >
          <Button           style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}
 icon={<UploadOutlined />}>DXF Yükle</Button>
        </Upload>

        <Button
          style={{ marginLeft: '10px' }}
          onClick={() => setFileListPartImage([])} 
          >
          Tümünü Sil
        </Button>

        {/* Plaka fotoğrafı yükleme */}
        <Upload
          action="/upload.do"
          listType="picture"
          fileList={fileListPlateImage}
          onChange={handleFileChangePlateImage}
          multiple
          beforeUpload={() => false}
        >
          <Button           style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}
 icon={<UploadOutlined />}>TMT/BMP Yükle</Button>
        </Upload>
        <Button
          style={{ marginLeft: '10px' }}
          onClick={() => setFileListPlateImage([])}  
        >
          Tümünü Sil
        </Button>





        {/* PDF yükleme */}
        <Upload
          action="/upload.do"
          listType="picture"
          fileList={fileListSetupPdf}
          onChange={handleFileChangeSetupPdf}
         
          beforeUpload={() => false}
        >

          <Button           style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}
 icon={<FilePdfOutlined />}>PDF Yükle</Button>

        </Upload>
        </div>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

      </Modal>
     



    </div>


    
        </div>
    </div>)

};

SetupAdd.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

export default SetupAdd;
