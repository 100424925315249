import { useState } from "react";
import { Form, Input, Button } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { auth } from "../../services/authService";
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({
      title: 'Geçersiz Form',
      description: 'Lütfen zorunlu alanları doldurunuz.',
      placement: 'topRight',
    });
  };

  const onFinish = async (values) => {
    const { oldpassword, newpassword, againnewpassword } = values;

    try {
        if (newpassword !== againnewpassword) {
            NotificationService.openErrorNotification({
                title: 'Geçersiz Form',
                description: 'Yeni şifreler uyuşmuyor. Lütfen tekrar kontrol edin.',
                placement: 'topRight',
            });
            return;
        }
        if (newpassword.length < 6) {
            NotificationService.openErrorNotification({
                title: 'Geçersiz Form',
                description: 'Yeni şifre en az 6 karakter olmalıdır.',
                placement: 'topRight',
            });
            return;
          }

          
      setLoading(true);

      const user = auth.currentUser;

      const credentials = EmailAuthProvider.credential(user.email, oldpassword);
      await reauthenticateWithCredential(user, credentials);

      await updatePassword(user, newpassword);

      NotificationService.openSuccessNotification({
        title: 'Başarılı',
        description: 'Şifreniz başarıyla değiştirildi.',
        placement: 'topRight',
      });
      navigate('/profile');

    } catch (error) {
      //console.error(error);

      NotificationService.openErrorNotification({
        title: 'Hata',
        description: 'Şifreniz değiştirilemedi. Lütfen bilgilerinizi kontrol edin.',
        placement: 'topRight',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
      <h2 style={{ fontWeight: 'bold', color: "#1A446C" }}> Şifre Değiştirme</h2>
      <Form
        name="basic"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="oldpassword"
          rules={[{ type: 'password', required: true, message: 'Lütfen mevcut şifrenizi girin!' }]}
        >
          <Input placeholder="Eski Şifre" style={{ width: '450px', height: '50px', borderColor: "#1A446C" }} />
        </Form.Item>

        <Form.Item
          name="newpassword"
          rules={[{ type: 'password', required: true, message: 'Lütfen yeni şifrenizi girin!' }]}
        >
          <Input placeholder="Yeni Şifre" style={{ width: '450px', height: '50px', borderColor: "#1A446C" }} />
        </Form.Item>

        <Form.Item
          name="againnewpassword"
          rules={[{ type: 'password', required: true, message: 'Lütfen yeni şifrenizi tekrar girin!' }]}
        >
          <Input placeholder="Yeni Şifre Tekrar" style={{ width: '450px', height: '50px', borderColor: "#1A446C" }} />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: 5, textAlign: 'right', float: "right" }}
        >
          <Button
            htmlType="submit"
            style={{ background: "#1A446C", color: "white" }}
            loading={loading}
          >
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePassword;
