import React,{useEffect,useState} from "react";
import { Button, Table, Input,Select,Space, List,Modal,Checkbox, Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections, colors,openOrderType, placeholderMap}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import FirebaseStorage from "../../../services/storegeService";
import { useLocation  } from 'react-router-dom';
import SetupAdd from "./setupAdd";
import { SearchOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import PurchaseRequestAdd from "../purchaseRequests/purchaseRequestAdd";


const OpenOrderAddContinue = () => {
    const navigate = useNavigate();
   
    const location = useLocation();
    const record = location.state && location.state.record;
    const jobCode = location.state && location.state.jobCode;
    const [checkedItemsStock, setCheckedItemsStock] = useState([]);
    const [checkedItemsWastage, setCheckedItemsWastage] = useState([]);

    const [checkedItemsStockExcess, setCheckedItemsStockExcess] = useState([]);

    const [inputValuesStock, setInputValuesStock] = useState({});
    const [inputValuesWastage, setInputValuesWastage] = useState({});


    const [companies, setCompanies] = useState([]); 
    const [itemsStock, setItemsStock] = useState([]);

    const [itemsWastage, setItemsWastage] = useState([]);

    const [filteredItemsStock, setFilteredItemsStock] = useState([]);
    const [filteredItemsWastage, setFilteredItemsWastage] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recordForModal, setRecordForModal] = useState(null);

    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [excessValues, setExcessValues] = useState([]); 
  
    const [loading, setLoading] = useState(false);

    const [isWarehouseSelectedAll, setIsWarehouseSelectedAll] = useState(false);

    const [filtersStock, setFiltersStock] = useState({
      thickness: '',
      quality: '',
      width: '',
      height: '',
      surface: '',
      piece: '',
      reservedPiece: '',
      unitkg: '',
      totalkg: '',
    });

      const [filtersWastage, setFiltersWastage] = useState({
        thickness: '',
        quality: '',
        width: '',
        height: '',
        surface: '',
        piece: '',
        reservedPiece: '',
        unitkg: '',
        totalkg: '',
    });

    const [isReserved, setIsReserved] = useState(false);
    const [savedLocalSetupName, setSavedLocalSetupName] = useState(null);


    const handleSetup = (record) => {
     const savedCheckedItemsStock = JSON.parse(localStorage.getItem("checkedItemsStock"));
     const savedCheckedItemsWastage = JSON.parse(localStorage.getItem("checkedItemsWastage"));
     console.log("setup add ekranı açılıyor savedlar",savedCheckedItemsStock,savedCheckedItemsWastage)
     console.log("setup add ekranı açılıyor normaller",checkedItemsStock,checkedItemsWastage)

     const updatedRecord = {
      ...record,
      projectCode: record.projectCode ? record.projectCode : null,
      stock: (setupName || record.setupName) ? savedCheckedItemsStock : checkedItemsStock,
      wastage: (setupName || record.setupName) ? savedCheckedItemsWastage : checkedItemsWastage,
      checkedItemsStockExcess:checkedItemsStockExcess,
      jobCode: createJobCode ? createJobCode : (jobCode ? jobCode : null) ,
      isReserved:isReserved,
      setupName: setupName ? setupName : (record.setupName ? record.setupName : null),    

    };
  
    console.log("updatedRecord",updatedRecord)
    setRecordForModal(updatedRecord);
    setIsModalVisible(true);
      
    };
    
    const handleModalCancel = () => {
      setIsModalVisible(false);
    };
  
    const handleSetupFinish = () => {
      setIsModalVisible(false); 
    };

    const fetchData = async () => {  

      const localSetupName = localStorage.getItem("setupName") ? localStorage.getItem("setupName")  : record.setupName;
      console.log("localSetupName",localSetupName,localStorage.getItem("setupName"), record.setupName )
      setSavedLocalSetupName(localSetupName)
    
    
      console.log("fetch data started");
    
      // Listen to stock data updates
      const unsubscribeStock = FirebaseService.listenToStocksData(snapshot => {

        const savedDepotName = localStorage.getItem("depotName");
        const savedFiltersStock = localStorage.getItem("stockFilters");
      
        const parsedFiltersStock = savedFiltersStock ? JSON.parse(savedFiltersStock) : {};
      
        console.log("savedDepotName", savedDepotName);
        console.log("selectedWarehouse", selectedWarehouse);
        console.log("parsedFiltersStock", parsedFiltersStock);
    
        
        console.log("Received stock snapshot"); // Debugging log
        const data = snapshot.docs.map(doc => ({ id: doc.id, key: doc.id, ...doc.data() }));
       // const data = snapshot.docs.map(doc => ({ ...doc.data() }));

    console.log("data stock on snapshot",data)
        const warehouseNameToUse = record?.warehouseName ?? (savedDepotName || selectedWarehouse);
        console.log("warehouseNameToUse stock", warehouseNameToUse);
    
        let filteredDataStock;
        if (warehouseNameToUse === null || warehouseNameToUse === undefined) {
          filteredDataStock = data.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            const hasWaitingPiece = item.waitingPiece !== null && item.waitingPiece !== undefined && item.waitingPiece !== 0;
            return (
              !item.isDeleted &&
              (
                (item.piece === 0 && (hasReservedPiece || hasWaitingPiece)) ||
                (item.piece !== 0)
              )
            );
          });
        } else if (warehouseNameToUse === "all") {
          filteredDataStock = data.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            return (
              !item.isDeleted &&
              (
                (item.piece === 0 && hasReservedPiece) ||
                (item.piece !== 0)
              )
            );
          });
        } else {
          filteredDataStock = data.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            return (
              !item.isDeleted &&
              item.depotName === warehouseNameToUse &&
              (
                (item.piece === 0 && hasReservedPiece) ||
                (item.piece !== 0)
              )
            );
          });
        }
    
        const finalFilteredDataStock = filteredDataStock.filter(item => {
          return Object.keys(parsedFiltersStock).every(key => {
            const filterValue = parsedFiltersStock[key]?.toLowerCase();
            const itemValue = item[key] !== undefined ? item[key]?.toString().toLowerCase() : '';
            return filterValue ? itemValue === filterValue : true;
          });
        });
    
        console.log("finalFilteredDataStock", finalFilteredDataStock);
        console.log("parsedFiltersStock", parsedFiltersStock);
    
        setItemsStock(filteredDataStock);
        setFilteredItemsStock(finalFilteredDataStock);
      });
    
      // Listen to wastage data updates
      const unsubscribeWastage = FirebaseService.listenToWastagesData(snapshot => {

      const savedDepotName = localStorage.getItem("depotName");
      const savedFiltersWastage = localStorage.getItem("wastageFilters");
    
      const parsedFiltersWastage = savedFiltersWastage ? JSON.parse(savedFiltersWastage) : {};
    
      console.log("savedDepotName", savedDepotName);
      console.log("selectedWarehouse", selectedWarehouse);
      console.log("parsedFiltersWastage", parsedFiltersWastage);
    
        console.log("Received wastage snapshot"); // Debugging log
        const dataWastage = snapshot.docs.map(doc => ({ id: doc.id,key: doc.id, ...doc.data() }));
      //  const dataWastage = snapshot.docs.map(doc => ({ ...doc.data() }));

        console.log("data wastage on snapshot",dataWastage)


        const warehouseNameToUse = record?.warehouseName ?? (savedDepotName || selectedWarehouse);
    
        console.log("warehouseNameToUse wastage", warehouseNameToUse);
    
        let filteredDataWastage;
        if (warehouseNameToUse === null || warehouseNameToUse === undefined) {
          filteredDataWastage = dataWastage.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            const hasWaitingPiece = item.waitingPiece !== null && item.waitingPiece !== undefined && item.waitingPiece !== 0;
            return (
              !item.isDeleted &&
              (
                (item.piece === 0 && (hasReservedPiece || hasWaitingPiece)) ||
                (item.piece !== 0)
              )
            );
          });
        } else if (warehouseNameToUse === "all") {
          filteredDataWastage = dataWastage.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            return (
              !item.isDeleted &&
              (
                (item.piece === 0 && hasReservedPiece) ||
                (item.piece !== 0)
              )
            );
          });
        } else {
          filteredDataWastage = dataWastage.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            return (
              !item.isDeleted &&
              item.depotName === warehouseNameToUse &&
              (
                (item.piece === 0 && hasReservedPiece) ||
                (item.piece !== 0)
              )
            );
          });
        }
    
        const updatedDataWastage = filteredDataWastage.map(item => ({
          ...item,
          isQRScanned: item.isQRScanned === undefined ? true : item.isQRScanned
        }));
    
        const finalFilteredDataWastage = updatedDataWastage.filter(item => {
          return Object.keys(parsedFiltersWastage).every(key => {
            const filterValue = parsedFiltersWastage[key]?.toLowerCase();
            const itemValue = item[key] !== undefined ? item[key]?.toString().toLowerCase() : '';
            return filterValue ? itemValue === filterValue : true;
          });
        });
    
        console.log("finalFilteredDataWastage", finalFilteredDataWastage);
        console.log("parsedFiltersWastage", parsedFiltersWastage);
    
        setItemsWastage(updatedDataWastage);
        setFilteredItemsWastage(finalFilteredDataWastage);
      });
    
      const companies = await FirebaseService.getCompaniesData();
      const filteredCompanies = companies.filter(item => !item.isDeleted);
      setCompanies(filteredCompanies);
    
      // Cleanup function
      return () => {
        unsubscribeStock();
        unsubscribeWastage();
      };
    };
   
    
    useEffect(() => {
      window.scrollTo(0, 0);
     

     
      // Stok ve fire verilerini localStorage'a kaydetme
      const existingStockItems = Array.isArray(record.stock) ? record.stock : JSON.parse(record.stock || '[]');
      localStorage.setItem("checkedItemsStock", JSON.stringify(existingStockItems));
      console.log("existingStockItems LOKALDE", existingStockItems);
    
      const existingWastageItems = Array.isArray(record.wastage) ? record.wastage : JSON.parse(record.wastage || '[]');
      localStorage.setItem("checkedItemsWastage", JSON.stringify(existingWastageItems));
      console.log("existingWastageItems LOKALDE", existingWastageItems);
    
      // Anlık veri güncellemeleri için fetchData fonksiyonunu çağırma
      const unsubscribe = fetchData(); // fetchData çağrısı await ile değiştirildi
    
      // Cleanup fonksiyonu: bileşen unmount edildiğinde dinleyicileri kaldır
      return () => {
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }
      };
    }, []);
    
    
    const warehouseOptions = [{ label: 'Tümü', value: "all" }, ...companies.map(item => ({ label: item.companyName, value: item.companyName }))];
    
    function generateRandomCode(length) {
      const characters = '0123456789';
      let result = '';
    
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
    
      return result;
  }
   
    const handleCheckboxChangeWastage = (item,record) => {
        const isChecked = checkedItemsWastage.some((entry) => entry.id === item);

        if (inputValuesWastage[item]) {
          console.log("inputValuesWastage  geldi")
 
          setCheckedItemsWastage((items) => {
                 if (isChecked) {
                     const newItems = items.filter((entry) => entry.id !== item);
                     return newItems;
                 } else {
                     const newItem = { 
                       id:item,
                       oldPiece: record.piece, //mevucttaki piece
                       quality: record.quality,
                       height: record.height,
                       width: record.width,
                       thickness: record.thickness,
                       surface: record.surface,
                       isQrScan: false,
                       piece: inputValuesWastage[item] || '', //reserve edilen piece bu
                       code:record.code ? record.code : null,
                       depotName : record.depotName ? record.depotName : null,
                       isReserved: false
                       }; 
                     return [...items, newItem];
                 }
             });
         } 
          
       
    };
        
    const handleCheckboxChange = (item,record) => {
     // console.log("record",record,item)
        const isChecked = checkedItemsStock.some((entry) => entry.id === item);
        if (inputValuesStock[item]) {
         // console.log("inputValuesStock  geldi")

            setCheckedItemsStock((items) => {
                if (isChecked) {
                    const newItems = items.filter((entry) => entry.id !== item);
                    return newItems;
                } else {
                    const newItem = { 
                      id:item,
                      oldPiece: record.piece, //mevucttaki piece
                      quality: record.quality,
                      height: record.height,
                      width: record.width,
                      thickness: record.thickness,
                      surface: record.surface,
                      isQrScan: false,
                      piece: inputValuesStock[item] || '', //reserve edilen piece bu,
                      code:record.code,
                      depotName : record.depotName ? record.depotName :  null,
                      isReserved: false

                     }; 
                      
                    return [...items, newItem];
                }
            });
        } 
        
         if(excessValues[item]){
        //  console.log("exces valuese geldi",excessValues[item] )
          setCheckedItemsStock((items) => {
            if (isChecked) {
                const newItems = items.filter((entry) => entry.id !== item);
                return newItems;
            } else {
                const newItem = { 
                  id:item,
                  oldPiece: record.piece, //mevucttaki piece
                  quality: record.quality,
                  height: record.height,
                  width: record.width,
                  thickness: record.thickness,
                  surface: record.surface,
                  isQrScan: false,
                  piece: excessValues[item] || '' ,//reserve edilen piece bu
                  minPiece: record.minPiece,
                  code:record.code,
                  depotName : record.depotName ? record.depotName :  null,
                  isReserved: false

                };  
                return [...items, newItem];
            }
        });
        setCheckedItemsStockExcess((items) => {
          if (isChecked) {
              const newItems = items.filter((entry) => entry.id !== item);
              return newItems;
          } else {
              const newItem = { 
                id:item,
                jobCode: jobCode,
                quality: record.quality,
                height: record.height,
                width: record.width,
                thickness: record.thickness,
                surface: record.surface,
                piece: excessValues[item] || '' , //reserve edilen piece bu
                minPiece: record.minPiece,
                code:record.code,
                currentPiece: record.piece,
                depotName : record.depotName ? record.depotName :  null

                

              }; 
               
              return [...items, newItem];
          }
      });

        }
        
      
    };
 
    const onChangeFilterWarehouse = async(value) => {
    console.log(`selected ${value}`);
    setSelectedWarehouse(value)
    localStorage.setItem("depotName", value);


    const savedDepotName = localStorage.getItem("depotName")
    console.log("savedDepotName 2 onChangeFilterWarehouse",savedDepotName) 
    setIsWarehouseSelectedAll(value === "all")
    try {
      const warehouseNameToUse = savedDepotName || value;

      console.log("warehouseNameToUse ",warehouseNameToUse)
      if (warehouseNameToUse) {
        const data = await FirebaseService.getStocksData();
        const dataWastage = await FirebaseService.getWastagesData();
  
        if (warehouseNameToUse === "all") {
          const filteredDataStock = data.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            const hasWaitingPiece = item.waitingPiece !== null && item.waitingPiece !== undefined && item.waitingPiece !== 0;
      
            return (
              !item.isDeleted &&
              (
                (item.piece === 0 && (hasReservedPiece || hasWaitingPiece)) || 
                (item.piece !== 0) // Diğer koşullarda piece 0 olmayanları da göster
              )
            );
          });

          setItemsStock(filteredDataStock);
          setFilteredItemsStock(filteredDataStock);
          
          const filteredDataWastage = dataWastage.filter(item => {
          const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
         
    
          return (
            !item.isDeleted && 
            (
              (item.piece === 0 && (hasReservedPiece)) || 
              (item.piece !== 0) // Diğer koşullarda piece 0 olmayanları da göster
            )
          );
        });
          
          const updatedDataWastage = filteredDataWastage.map(item => ({
            ...item,
            isQRScanned: item.isQRScanned === undefined ? true : item.isQRScanned
          }));
        
          setItemsWastage(updatedDataWastage);
          setFilteredItemsWastage(updatedDataWastage);
        } else {

          const filteredDataStock = data.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            const hasWaitingPiece = item.waitingPiece !== null && item.waitingPiece !== undefined && item.waitingPiece !== 0;
            const supplier = item.depotName?.trim().toLowerCase(); 
            const searchValue = warehouseNameToUse.trim().toLowerCase(); 
            return (
              !item.isDeleted &&
               supplier === searchValue &&
              (
                (item.piece === 0 && (hasReservedPiece || hasWaitingPiece)) || 
                (item.piece !== 0) // Diğer koşullarda piece 0 olmayanları da göster
              )
            );
          });
  
          setItemsStock(filteredDataStock);
          setFilteredItemsStock(filteredDataStock);
          
        
          const filteredDataWastage = dataWastage.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
           
            const supplier = item.depotName?.trim().toLowerCase(); 
            const searchValue = warehouseNameToUse.trim().toLowerCase(); 
            return (
              !item.isDeleted && 
               supplier === searchValue &&
              (
                (item.piece === 0 && (hasReservedPiece)) || 
                (item.piece !== 0) // Diğer koşullarda piece 0 olmayanları da göster
              )
            );
          });

          const updatedDataWastage = filteredDataWastage.map(item => ({
            ...item,
            isQRScanned: item.isQRScanned === undefined ? true : item.isQRScanned
          }));

          setItemsWastage(updatedDataWastage);
          setFilteredItemsWastage(updatedDataWastage);
        }
      } else {
       // console.log('Lütfen bir depo seçiniz.');
      }
    } catch (error) {
      console.error('Hata oluştu:', error);
    }
    };

    const handleInputChange = (item, value) => {
      const maxLength = Number(itemsStock.find((record) => record.key === item)?.piece);
      const numericValue = Number(value);
  
      console.log("numericValue stock", numericValue);
  
      if (isNaN(numericValue)) {
          NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Hatalı giriş: Sayısal bir değer girmelisiniz.",
              placement: 'topRight'
          });
          return; 
      }
  
      if (numericValue <= maxLength) {
          setInputValuesStock((values) => {
              const newValues = { ...values, [item]: numericValue };
              console.log("input values stokc", newValues, checkedItemsStock, item);
  
              // checkedItemsStock'ı güncelle
              const updatedCheckedItems = checkedItemsStock.map(x => {
                  if (x.id === item) {
                      return { ...x, piece: numericValue };
                  }
                  return x;
              });
              setCheckedItemsStock(updatedCheckedItems);
  
              return newValues;
          });
      } else {
          setExcessValues((prevValues) => ({ ...prevValues, [item]: numericValue - maxLength }));
          setInputValuesStock((values) => ({ ...values, [item]: maxLength }));
          console.log("Burada fazla", numericValue - maxLength);
      }
  };
  
 
    const handleInputChangeWastage = (item, value) => {
    
      const maxLength = Number(itemsWastage.find((record) => record.key === item)?.piece);
      console.log("itemmmmm",item)
      console.log("maxLength",maxLength)
      const numericValue = Number(value);
      console.log("numericValue wastage",numericValue)

      if (isNaN(numericValue)) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Hatalı giriş: Sayısal bir değer girmelisiniz.",
          placement: 'topRight'
        });
        return; 
    }
    else{
      if (!isNaN(numericValue) && (maxLength === undefined || numericValue <= maxLength)) {
        setInputValuesWastage((values) => ({ ...values, [item]: numericValue }));
       console.log("input values wastge", inputValuesWastage, checkedItemsWastage,item);
    
        const checkedItemsWastagesArray = Array.isArray(checkedItemsWastage) ? checkedItemsWastage : [checkedItemsWastage];
    console.log("checkedItemsWastagesArray",checkedItemsWastagesArray)
        const itemExists = checkedItemsWastagesArray.some(x => x.id === item);
    
        if (itemExists) {
            console.log("Item exists.");
            const updatedItems = checkedItemsWastagesArray.map(x => {
                if (x.id === item) {
                    return { ...x, piece: numericValue };
                } else {
                    return x;
                }
            });
            setCheckedItemsWastage(updatedItems);
        } else {
            // itemExists yanlışsa yapılacak işlemler
           console.log("Item does not exist.");
        }
      }
    
      else {

        setInputValuesWastage((values) => ({ ...values, [item]: maxLength }));
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Yeterli sayıda bulunmamaktadır. Lütfen güncel stoktan seçiniz.",
          placement: 'topRight'
        });
         console.log("burda fazla girmemeli");
      
      }
    }

    
    };

    //stock için filtreleme
    const handleSearchStock = (value, dataIndex) => {
      setFiltersStock(prevFilters => {
        // Filtre değerini güncelle
        const updatedFilters = {
          ...prevFilters,
          [dataIndex]: value, // Sadece bu filtreyi günceller
        };

        localStorage.setItem("stockFilters", JSON.stringify(updatedFilters));

        // Tüm filtrelere göre filtreleme yap
        const filteredData = itemsStock.filter(item => {
          return (
            !item.isDeleted && // Silinmemiş öğeleri dahil et
            Object.keys(updatedFilters).every(key => {
              const filterValue = updatedFilters[key]?.toLowerCase(); // Aktif filtreyi al
              const itemValue = item[key] !== undefined ? item[key]?.toString().toLowerCase() : ''; // İlgili değer varsa kontrol et
              return filterValue ? itemValue === filterValue : true; // Tam eşleşme kontrolü
            })
          );
        });
    
        setFilteredItemsStock(filteredData); // Filtrelenmiş veriyi güncelle
        return updatedFilters; // Yeni filtreleri geri döndür
      });
    };
    

    const handleResetStock = () => {
      setFilteredItemsStock(itemsStock); // Tüm listeyi geri döndürüyoruz
    };

    const getColumnSearchPropsStock = dataIndex => ({
      title: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{placeholderMap[dataIndex] || dataIndex}</span>
          <Input
            id={`search-input-${dataIndex}`}
            placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
            onChange={e => handleSearchStock(e.target.value, dataIndex)} // Arama her değişiklikte yapılacak
            style={{ width: 80, height:30, marginTop: 8  }}
            suffix={<SearchOutlined />} // Search ikonu ekleme
          />
        </div>
      ),
      sorter: (a, b) => {
        // Safely handle null/undefined values by using an empty string as a fallback
        const aValue = a[dataIndex] ? a[dataIndex].toString().toLowerCase() : '';
        const bValue = b[dataIndex] ? b[dataIndex].toString().toLowerCase() : '';
        return aValue.localeCompare(bValue);
      },
    });

    const columnsStock = [
     /*
      {
        title: 'Firma',
        dataIndex: 'companyName',
        key: 'companyName',
      
        render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
      {
        title: 'Tedarikçi',
        dataIndex: 'supplierName',
        key: 'supplierName',
      
        render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },*/
      
       {
        title: 'Depo',
        dataIndex: 'depotName',
        key: 'depotName',
      
        render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
      
      ...(isWarehouseSelectedAll ? [{
        title: 'Depo',
        dataIndex: 'depotName',
        key: 'depotName',
        ...getColumnSearchPropsStock('depotName'),
        sorter: (a, b) => {
            const aDepot = a.depotName ? a.depotName.toString().toLowerCase() : '';
            const bDepot = b.depotName ? b.depotName.toString().toLowerCase() : '';
            return aDepot.localeCompare(bDepot);
        },
        render: (text, record) => (
            <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>
        ),
    }] : []),
    
    {
      title: 'Kalınlık',
      dataIndex: 'thickness',
      key: 'thickness',
    
      ...getColumnSearchPropsStock('thickness'),
      sorter: (a, b) => a.thickness - b.thickness,
      render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}mm</span>,
    },
    
      {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
      
        ...getColumnSearchPropsStock('quality'),
        sorter: (a, b) => a.quality.localeCompare(b.quality), 
        render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
  
       
  
       
        {
          title: 'En',
          dataIndex: 'width',
          key: 'width',
        
          ...getColumnSearchPropsStock('width'),
          sorter: (a, b) => a.width - b.width,
          render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
        {
          title: 'Boy',
          dataIndex: 'height',
          key: 'height',
       
          ...getColumnSearchPropsStock('height'),
          sorter: (a, b) => a.height - b.height,
          render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
  
        {
          title: 'Yüzey',
          dataIndex: 'surface',
          key: 'surface',
        
          ...getColumnSearchPropsStock('surface'),
          sorter: (a, b) => a.surface.localeCompare(b.surface), 
          render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
   
        {
          title: 'Adet',
          dataIndex: 'piece',
          key: 'piece',
          render: (text,record) => <span style={{color: itemsStock.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        
          sorter: (a, b) => a.piece - b.piece,
          ...getColumnSearchPropsStock('piece'),
        },
        
        {
          title: 'Rezerve Adet',
          dataIndex: 'reservedPiece',
          key: 'reservedPiece',
          render: (text,record) => <span style={{color: itemsStock.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
      
         
        
        },
        {
          title: 'Beklenen Adet',
          dataIndex: 'waitingPiece',
          key: 'waitingPiece',
          render: (text,record) => <span style={{color: itemsStock.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
       
         
        
        },
        {
          title: 'Açıklama',
          dataIndex: 'isProblem',
          key: 'isProblem',
          render: (text, record) => {
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const formattedDate = record.problemDate ? new Date(record.problemDate.seconds * 1000).toLocaleString('tr-TR', options) : '';
            
            return (
              <Popover
              content={
                <div style={{ width: '200px', maxHeight: '700px' }}> {/* Sabit yükseklik ve genişlik */}
                  <p><strong>Problem Zamanı:</strong> {formattedDate}</p>
                  <p><strong>Problem Fotoğrafı:</strong> 
                    {record.problemImage ?                 
                    <img alt="" src={record.problemImage} style={{  width: '150px',height:"150px",objectFit:"contain" }} />
                    : 
                    "Fotoğraf bulunmamaktadır."}</p>
                        <p style={{ width: '200px', maxHeight: '500px', overflow:"hidden" }}><strong>Problem Açıklaması:</strong> {record.problemDescription}</p>
                      </div>
                    }
                    title="Sac levhada problem bulunmaktadır."
                    trigger="click" // veya hover
                  >
                <span style={{ color: itemsStock.isDeleted ? "#00000080" : "#000000" }}>
                  {record.isProblem ? <img width={30} height={20} alt="logo" src="/images/important.png" style={{ paddingBottom: "5%" }} /> : null}
                </span>
              </Popover>
            );
          },
        
        },
          
        {
            title: 'Kullanılacak Adet',
            dataIndex: '', 
            key: '',
          
            render: (text, record) => (
              <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>
                <Input
                      
                  onChange={(e) => handleInputChange(record.key, e.target.value)}
                   className="input-style"
                   style={{width:"100%"}}

                />
              </span>
            ),
        },
        
        {
          title: ' ',
          dataIndex: '',
          key: '',
       
          render: (text, record) => (
            <Checkbox
            onChange={() => handleCheckboxChange(record.key,record)}
            checked={checkedItemsStock.some(item => item.id === record.key)}
            style={{ fontWeight: '500' }}
          />      
             
         
          ),
        }
             
             
      
    ];

    //wastage için filtelme
    const handleSearchWastage = (value, dataIndex) => {
      setFiltersWastage(prevFilters => {
        // Filtre değerini güncelle
        const updatedFilters = {
          ...prevFilters,
          [dataIndex]: value, // Sadece bu filtreyi günceller
        };

        localStorage.setItem("wastageFilters", JSON.stringify(updatedFilters));

    
        // Tüm filtrelere göre filtreleme yap
        const filteredData = itemsWastage.filter(item => {
          return (
            !item.isDeleted && // Silinmemiş öğeleri dahil et
            Object.keys(updatedFilters).every(key => {
              const filterValue = updatedFilters[key]?.toLowerCase(); // Aktif filtreyi al
              const itemValue = item[key] !== undefined ? item[key]?.toString().toLowerCase() : ''; // İlgili değer varsa kontrol et
              return filterValue ? itemValue === filterValue : true; // Tam eşleşme kontrolü
            })
          );
        });
    
        setFilteredItemsWastage(filteredData); // Filtrelenmiş veriyi güncelle
        return updatedFilters; // Yeni filtreleri geri döndür
      });
    };
    

    const handleResetWastage = () => {
      setFilteredItemsWastage(itemsWastage); // Tüm listeyi geri döndürüyoruz
    };

    const getColumnSearchPropsWastage = dataIndex => ({
      title: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{placeholderMap[dataIndex] || dataIndex}</span>
          <Input
            id={`search-input-${dataIndex}`}
            placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
            onChange={e => handleSearchWastage(e.target.value, dataIndex)} // Her değişiklikte arama işlemi yapılacak
            style={{ width: '100%', marginTop: 8 }} // Başlığın altında input alanı olacak
            suffix={<SearchOutlined />} // Search ikonu ekleme
          />
        </div>
      ),
      sorter: (a, b) => {
        const aValue = a[dataIndex] !== undefined ? a[dataIndex].toString().toLowerCase() : '';
        const bValue = b[dataIndex] !== undefined ? b[dataIndex].toString().toLowerCase() : '';
        return aValue.localeCompare(bValue); // Sıralama işlemi
      },
    });


    const columnsWastage = [
      /*
      {
        title: 'key', // Add a new column title for the ID
        dataIndex: 'key', // Assuming `id` is the field in your data
        key: 'key',
        render: (text) => (
          <span style={{ color: '#000000' }}>{text}</span> // Adjust styling as needed
        ),
      },*/
      /*
      {
        title: 'Firma',
        dataIndex: 'companyName',
        key: 'companyName',
      
        render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },*/
      {
        title: 'Depo',
        dataIndex: 'depotName',
        key: 'depotName',
      
        render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
      
      ...(isWarehouseSelectedAll ? [{
        title: 'Depo',
        dataIndex: 'depotName',
        key: 'depotName',
        ...getColumnSearchPropsWastage('depotName'),
        sorter: (a, b) => a.depotName.localeCompare(b.depotName),
        render: (text, record) => (
          <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
            {text}
          </span>
        ),
    }] : []),
    {
      title: 'Kalınlık',
      dataIndex: 'thickness',
      key: 'thickness',
    
      ...getColumnSearchPropsWastage('thickness'),
      sorter: (a, b) => a.thickness - b.thickness,
      render: (text, record) => (
        <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
          {text} mm
        </span>
      ),
    },

      {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
       
        ...getColumnSearchPropsWastage('quality'),
        sorter: (a, b) => a.quality.localeCompare(b.quality), 
        render: (text, record) => (
          <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
            {text}
          </span>
        ),
      },
  
       
       
        {
          title: 'En',
          dataIndex: 'width',
          key: 'width',
         
          ...getColumnSearchPropsWastage('width'),
          sorter: (a, b) => a.width - b.width,
          render: (text, record) => (
            <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
              {text}
            </span>
          ),
        },
        {
          title: 'Boy',
          dataIndex: 'height',
          key: 'height',
       
          ...getColumnSearchPropsWastage('height'),
          sorter: (a, b) => a.height - b.height,
          render: (text, record) => (
            <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
              {text}
            </span>
          ),
        },
  
        {
          title: 'Yüzey',
          dataIndex: 'surface',
          key: 'surface',
        
          ...getColumnSearchPropsWastage('surface'),
          sorter: (a, b) => a.surface.localeCompare(b.surface), 
          render: (text, record) => (
            <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
              {text}
            </span>
          ),
        },
   
        {
          title: 'Adet',
          dataIndex: 'piece',
          key: 'piece',
          render: (text, record) => (
            <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
                {text !== undefined && text !== null && text !== "" ? text : 1}
            </span>
        ),         
          ...getColumnSearchPropsWastage('piece'),
          sorter: (a, b) => a.piece - b.piece,
        },
          
        {
          title: 'Rezerve Adet',
          dataIndex: 'reservedPiece',
          key: 'reservedPiece',
          render: (text, record) => (
            <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
              {text}
            </span>
          ),
        },
          
       
        
        {
          title: 'Açıklama',
          dataIndex: 'description',
          key: 'description',
          render: (text, record) => {
            // Belirli açıklamayı kontrol et
            const shouldHideDescription = record.description === "Teknik ressam tarafından setuptan eklenmiştir.";
        
            return (
              <div>
                <Popover
                  content={
                    <div style={{ width: '200px', maxHeight: '700px' }}>
                      <p><strong>Fotoğrafı:</strong>
                        {record.imageUrl ?                 
                          <img alt="" src={record.imageUrl} style={{ width: '150px', height: "150px", objectFit: "contain" }} />
                          : 
                          "Fotoğraf bulunmamaktadır."}
                      </p>
                      {/* Eğer açıklama belirtilen metne eşit değilse göster */}
                      {!shouldHideDescription && (
                        <p style={{ width: '200px', maxHeight: '500px', overflow: "hidden" }}>
                          <strong>Açıklaması:</strong> {record.description}
                        </p>
                      )}
                    </div>
                  }
                  title="Firede açıklama bulunmaktadır."
                  trigger="click"
                >
                  {/* Eğer açıklama belirtilen metne eşit değilse simgeyi göster */}
                  {!shouldHideDescription && (
                    <span>
                      {record.description ? (
                        <img width={30} height={20} alt="logo" src="/images/important.png" style={{ paddingBottom: "5%" }} />
                      ) : null}
                    </span>
                  )}
                </Popover>
            
                {!record?.isQRScanned && (
                  <div style={{ marginTop: '5px', color: '#ff0000' }}>
                    <div>{record.jobCode ?  record.jobCode.substring(0, 5) : "-"}</div>
                    <div>{record.companyName ? record.companyName : "-"}</div>
                    <div>{record.firstUserNameSurname ? record.firstUserNameSurname : "-"}</div>
                  </div>
                )}


              </div>
            );
          },
        },
        
        
        
        {
          title: 'Kullanılacak Adet',
          dataIndex: '', 
          key: '',
          render: (text, record) => {
          //  console.log('Record Key:', record.key); // Logs the key for each record
        
            return (
              <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>
                <Input
                  onChange={(e) => handleInputChangeWastage(record.key, e.target.value)}
                  className="input-style"
                  style={{ width: "100%" }}
                  rules={[{ pattern: /^\d+$/, message: "Sadece rakam girişi yapınız.", required: true }]}
                />
              </span>
            );
          },
        },
        
        
        {
          title: ' ',
          dataIndex: '',
          key: '',
        
          render: (text, record) => (
            <Checkbox
            onChange={() => handleCheckboxChangeWastage(record.key,record)}
            checked={checkedItemsWastage.some(item => item.id === record.key)}
            style={{ fontWeight: '500' }}
          />      
         
          ),
        },
             
             
      
    ];
  
    const [isModalVisiblePurchase, setIsModalVisiblePurchase] = useState(false);

    const handlePurchaseRequest = () => {
      setIsModalVisiblePurchase(true);
    };

    const handlePurchaseRequestAddFinish = () => {
      setIsModalVisiblePurchase(false); 
    };

    const handleModalCancelPurchase = () => {
      setIsModalVisiblePurchase(false);
      setLoading(false);
    };

    const [isModalVisibleSetupName, setIsModalVisibleSetupName] = useState(false);
    const [setupName, setSetupName] = useState('');
    const [createJobCode, setCreateJobCode] = useState(null)

    const handleInputChangeSetupName =  (e) => {
      setSetupName(e.target.value);
    };

    const checkSendPurchase = (stockItem) => {
      const checkedItemsStockExcessx = checkedItemsStockExcess || [];
    
      return checkedItemsStockExcessx.some(checkedItem =>
        checkedItem.id === stockItem.id
      );
    };
    
  
    const checkItems = async (record) => {
      console.log("fonk geldiiii rezerveler kontrol ediliyor", record);
    
      let isAllSuccessful = true;
    
      if (record.stock && Array.isArray(record.stock)) {
        for (const stockItem of record.stock) {
        const { id } = stockItem;
    
        const stockTableItem = await FirebaseService.getStockItemById(id);
        console.log("stockTableItem", stockTableItem);
    
        if (stockTableItem) {
        
          if (stockTableItem.reservedPiece <= 0 ||  (stockTableItem.reservedPiece < stockItem.piece) ) {
            console.log(`Warning: The reservedPiece for stock item with id ${id} is not greater than 0.`);
            
            if (stockTableItem.piece <= 0 || stockTableItem.piece < stockItem.piece) {
              console.log(`Piece is 0 or less for stock item with id ${id}, skipping reservation check.`);
              isAllSuccessful = false;
              break; 
            }
      
            const result = await FirebaseService.updateStockReservedPiece(id, stockItem.piece);
            if (result.success) {
              console.log("Stock item successfully reserved.");
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Parça ID: ${id} güncellenemedi. Hata: ${result.message}`,
                placement: 'topRight',
              });
              isAllSuccessful = false;
              break;
            }
          }
        } else {
          console.log(`Warning: No item found in stock table for id ${id}`);
          isAllSuccessful = false;
          break; // Exit loop immediately if item is not found
        }
        }
      }
      if (!isAllSuccessful) return false;
    
      if (record.wastage && Array.isArray(record.wastage)) {
        for (const wastageItem of record.wastage) {
          const { id } = wastageItem;
      
          const wastageTableItem = await FirebaseService.getWastageItemById(id);
          console.log("wastageTableItem", wastageTableItem);
      
          if (wastageTableItem) {
            
      
            if (wastageTableItem.reservedPiece <= 0 ||  (wastageTableItem.reservedPiece < wastageItem.piece) ) {
              console.log(`Warning: The reservedPiece for wastage item with id ${id} is not greater than 0.`);
      
              if (wastageTableItem.piece <= 0 || wastageTableItem.piece < wastageItem.piece) {
                console.log(`Piece is 0 or less for wastage item with id ${id}, skipping reservation check.`);
                isAllSuccessful = false;
                break; // Exit loop immediately
              }

              const result = await FirebaseService.updateWastageReservedPiece(id, wastageItem.piece);
              if (result.success) {
                console.log("wastage başarılı reserve güncellensin");
              } else {
                NotificationService.openErrorNotification({
                  title: 'İşlem Başarısız',
                  description: `Parça ID: ${id} güncellenemedi. Hata: ${result.message}`,
                  placement: 'topRight',
                });
                isAllSuccessful = false;
                break; // Exit loop immediately on failure
              }
            }
          } else {
            console.log(`Warning: No item found in wastage table for id ${id}`);
            isAllSuccessful = false;
            break; // Exit loop immediately if item is not found
          }
        }
      }
      return isAllSuccessful;
    };
    

    const handleUpdate = async() => {
      setLoading(true);
      
      const savedSetupName = localStorage.getItem("setupName") ? localStorage.getItem("setupName")  : record.setupName;
      console.log("hande update   savedSetupName",savedSetupName)
      const savedCheckedItemsStock = JSON.parse(localStorage.getItem("checkedItemsStock"));
      const savedCheckedItemsWastage = JSON.parse(localStorage.getItem("checkedItemsWastage"));
      
      console.log("savedCheckedItemsStock", savedCheckedItemsStock);
      console.log("savedCheckedItemsWastage", savedCheckedItemsWastage);
      
      console.log("checkedItemsStock",checkedItemsStock)
      console.log("checkedItemsStockExcess",checkedItemsStockExcess)
  
      if (!(checkedItemsStock.length > 0 || checkedItemsWastage.length > 0)) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Seçili sac levha ya da fire bulunammaktadır. Lütfen sac levha yada fire seçiniz",
          placement: 'topRight'
        });
        setLoading(false);
        return;
      }

      const createJobCode =  record.setupName ?   jobCode : `${jobCode}-${savedSetupName}`;
      console.log("createJobCode 2",createJobCode)

      setCreateJobCode(createJobCode);
      
    
    
      const arraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        return arr1.every((item, index) => 
            item.id === arr2[index].id &&
            item.oldPiece === arr2[index].oldPiece &&
            item.quality === arr2[index].quality &&
            item.height === arr2[index].height &&
            item.width === arr2[index].width
        );
      };

  
      const data = await FirebaseService.getOpenOrderData();
      const filteredData = data.filter(item => 
        !item.isDeleted && 
        (record.setupName ? item.jobCode === record.jobCode : item.jobCode === createJobCode) &&
        item.companyName === record.companyName &&
        item.orderType === record.orderType &&
        item.projectCode === record.projectCode &&
        arraysEqual(item.stock, savedCheckedItemsStock) &&
        arraysEqual(item.wastage, savedCheckedItemsWastage)
      
        /*
        (record.setupName 
          ? arraysEqual(item.stock, record.stock) && arraysEqual(item.wastage, record.wastage) 
          : arraysEqual(item.stock, savedCheckedItemsStock) && arraysEqual(item.wastage, savedCheckedItemsWastage)
        )*/
      );
      

      
      if (filteredData.length === 0) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Böyle bir açık sipariş bulunamadı.",
          placement: 'topRight'
        });
        setLoading(false);
        console.log("No matching item found.");
        return;
      }

  
      const excessValuesKeys = Object.keys(checkedItemsStockExcess);
      const excessValuesCount = excessValuesKeys.length;
      
    
       

  
      if (excessValuesCount > 0) {
         // console.log("Fazla talep edilen ürün var");
          excessValuesKeys.forEach((key) => {
              const value = checkedItemsStockExcess[key];
            //  console.log(`Key: ${key}, Value: ${value}`);
              
          });
        
          setVisibleContinue(true);
         
  
      }
      else{
console.log("buraya geldi alooooo")
       
       // console.log("fazla ürün yok")
        //stoktan ve fireden reserved update oluyo
        let updatedCheckedItemsStock;
        if (checkedItemsStock.length > 0) {
          for (const y of checkedItemsStock) {
            const { id, piece } = y;
            console.log("piece stock",piece)
            if (piece === 0) {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "Adet 0 seçilemez",
                placement: 'topRight'
              });
              setLoading(false);
              return;
    
            }
            const result = await FirebaseService.updateStockReservedPiece(id, piece);
            console.log(result);
            if (result.success) {
              console.log("stcok başarılı reserve güncellensin")
              y.isReserved = true; // Update isReserved to true if successful
            }
           else{
            console.log("rezerve atmadı wastage")

            /*
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Hata: ${result.message}`,
                placement: 'topRight',
              });
              setLoading(false);
              return; 
              */
            }
          }
        

          updatedCheckedItemsStock = checkedItemsStock.map(stockItem => ({
            ...stockItem,
            isReserved: stockItem.isReserved, // Apply the latest `isReserved` state
            isSendPurchase: checkSendPurchase(stockItem),
            code: stockItem.code ? stockItem.code : null
          }));
          
          console.log("Updated checkedItemsStock:", updatedCheckedItemsStock);
        }
       
        
        let updatedCheckedItemsWastage;
        if (checkedItemsWastage.length > 0) {
          for (const x of checkedItemsWastage) {
            const { id, piece } = x;
            console.log("piece wastage",piece)
            if (piece === 0) {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "Adet 0 seçilemez",
                placement: 'topRight'
              });
              setLoading(false);
              return;
            }
            const result = await FirebaseService.updateWastageReservedPiece(id, piece);

            if (result.success) {
              console.log("wasatage başarılı reserve güncellensin")
              x.isReserved = true; // Update isReserved to true if successful
            }
            // If the update fails, show an error notification for this item but continue the loop
            else {
              console.log("rezerve atmadı wastage")
              /*
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Hata: ${result.message}`,
                placement: 'topRight',
              });
              setLoading(false);
              return; // Skip this item and move to the next one
              */
            }
        
          }

        
          updatedCheckedItemsWastage = checkedItemsWastage.map(wastageItem => ({
            ...wastageItem,
            isReserved: wastageItem.isReserved, // Apply the latest `isReserved` state
            code: wastageItem.code ? wastageItem.code : null
           
          }));

          console.log("Updated updatedCheckedItemsWastage:", updatedCheckedItemsWastage);
        }
  
  
        //stocktaki itemlar isSendPurchase ekleniyor
        let updatedcheckedItemsStock = checkedItemsStock.map(stockItem => ({
              ...stockItem,
              isSendPurchase: checkSendPurchase(stockItem),
              code: stockItem.code ? stockItem.code : null
            })
          ) 
        console.log("updatedcheckedItemsStock",updatedcheckedItemsStock)
        
    
/*
         updatedCheckedItemsWastage = checkedItemsWastage?.map(wastageItem => ({
          ...wastageItem,
          // Other properties as needed
        })) || [];

        updatedCheckedItemsStock = checkedItemsStock?.map(stockItem => ({
          ...stockItem,
          // Other properties as needed
        })) || [];*/


          
       

        const updateOpenOrderItem = {
          stock: updatedcheckedItemsStock,
          wastage: updatedCheckedItemsWastage,   
        };
        console.log("daupdateOpenOrderItemta",updateOpenOrderItem)
  
        try {
          const result = await FirebaseService.updateOpenOrderStockAndWastage(filteredData[0].key, updateOpenOrderItem);
          if (result.success) {

              console.log("record çok önemliiiiii /tekrar kontrol edilyor rezerveleer ",record)
              const againCheckReserve =  await checkItems(updateOpenOrderItem)
              console.log("againCheckReserve",againCheckReserve)
              if(!againCheckReserve){
                NotificationService.openErrorNotification({
                  title: 'İşlem Başarısız',
                  description: "Rezerve edilemedi. Rezerve etmek istediğiniz tükenmiş olabilir lütfen başka seçiniz.",
                  placement: 'topRight'
              });
              setLoading(false);
              return;
              }



            
               // checkedItemsStock için
              const existingStockItems = JSON.parse(localStorage.getItem("checkedItemsStock")) || []; 
              const updatedStockItems = [...existingStockItems, ...updatedcheckedItemsStock]; 
              localStorage.setItem("checkedItemsStock", JSON.stringify(updatedStockItems));
              console.log("updatedStockItems LOKALDE ",updatedStockItems)
              // checkedItemsWastage için
              const existingWastageItems = JSON.parse(localStorage.getItem("checkedItemsWastage")) || []; 
              const updatedWastageItems = [...existingWastageItems, ...checkedItemsWastage]; 
              localStorage.setItem("checkedItemsWastage", JSON.stringify(updatedWastageItems)); 
              console.log("updatedWastageItems LOKALDE ",updatedWastageItems)
      
                  
              NotificationService.openSuccessNotification({
                title: 'İşlem Başarılı',
                description: "Seçilenler başarıyla rezerve edilmiştir.",
                placement: 'topRight'
              });
          
              setIsModalVisibleSetupName(false);
              setTimeout(() => {
                setCheckedItemsStock([]);
                setCheckedItemsStockExcess([]);
                setCheckedItemsWastage([]);
                setInputValuesStock({});
                setInputValuesWastage({});
                fetchData();
                setIsReserved(true);
                setLoading(false);
              //  window.location.reload();
              }, 2000);
            } else {
             // console.log("hata",result)
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
              setLoading(false);
            }
            
            
        } 
        catch (error) {
            console.log("error 7",error)
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
            setLoading(false);
  
        }
        
  
      }
      
  
    };

    const handleSaveSetupName = async() => {
      
    console.log("setupName",setupName)
    setLoading(true);
    if (!setupName || setupName.includes(" ")) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Setup isminde boşluk bulunmamalıdır. Lütfen düzeltiniz.",
        placement: 'topRight'
      });
      setLoading(false);
      return;
  }
  console.log("checkedItemsWastage",checkedItemsWastage)
  
  localStorage.setItem("setupName", setupName);
  
   
    const createJobCode =  record.setupName ? jobCode :  `${jobCode}-${setupName}`;
    console.log("createJobCode 3",createJobCode)

    setCreateJobCode(createJobCode);
    
  

    const excessValuesKeys = Object.keys(checkedItemsStockExcess);
    const excessValuesCount = excessValuesKeys.length;
    
    console.log("checkedItemsStock",checkedItemsStock)
    console.log("checkedItemsStockExcess",checkedItemsStockExcess)

    if (!(checkedItemsStock.length > 0 || checkedItemsWastage.length > 0)) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Seçili sac levha ya da fire bulunammaktadır. Lütfen sac levha yada fire seçiniz",
        placement: 'topRight'
      });
      setIsModalVisibleSetupName(false);
      setLoading(false);
      return;
    }
    
        // checkedItemsStock için
      const existingStockItems = JSON.parse(localStorage.getItem("checkedItemsStock")) || []; 
      const updatedStockItems = [...existingStockItems, ...checkedItemsStock]; 
      localStorage.setItem("checkedItemsStock", JSON.stringify(updatedStockItems)); 

      // checkedItemsWastage için
      const existingWastageItems = JSON.parse(localStorage.getItem("checkedItemsWastage")) || []; 
      const updatedWastageItems = [...existingWastageItems, ...checkedItemsWastage]; 
      localStorage.setItem("checkedItemsWastage", JSON.stringify(updatedWastageItems));


      

    if (excessValuesCount > 0) {
       // console.log("Fazla talep edilen ürün var");
        excessValuesKeys.forEach((key) => {
            const value = checkedItemsStockExcess[key];
          //  console.log(`Key: ${key}, Value: ${value}`);
            
        });
      
        setVisibleContinue(true);
        setLoading(false);

    }
    else{
     
      console.log("else geldi ")

      let updatedCheckedItemsStock = checkedItemsStock.map(stockItem => ({
        ...stockItem,
       // isReserved: false, // Default to false
        isSendPurchase: checkSendPurchase(stockItem),
        code: stockItem.code ? stockItem.code : null

      }));
      
      // Reserve update for wastage items
      let updatedCheckedItemsWastage = checkedItemsWastage.map(wastageItem => ({
        ...wastageItem,
        code: wastageItem.code ? wastageItem.code : null

       // isReserved: false, // Default to false
      }));

      
     // console.log("fazla ürün yok")
      //stoktan ve fireden reserved update oluyo
      if (checkedItemsStock.length > 0) {
        for (const y of checkedItemsStock) {
          const { id, piece } = y;
          if (piece === 0) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Adet 0 seçilemez",
              placement: 'topRight'
            });
            setLoading(false);
            return;
  
          }
          const result = await FirebaseService.updateStockReservedPiece(id, piece);
          console.log("result",result)
          if (result.success) {
            console.log("stcok başarılı reserve güncellensin")
            y.isReserved = true; // Update isReserved to true if successful
          }
         else{
          console.log("rezerve atmadı stock")
          /*
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: `Hata: ${result.message}`,
              placement: 'topRight',
            });
            setLoading(false);
            return; 
            */
          }
          console.log(result);
        }
    
        updatedCheckedItemsStock = checkedItemsStock.map(stockItem => ({
          ...stockItem,
          isReserved: stockItem.isReserved, // Apply the latest `isReserved` state
          isSendPurchase: checkSendPurchase(stockItem),
          code: stockItem.code ? stockItem.code : null
        }));
        
        console.log("Updated checkedItemsStock:", updatedCheckedItemsStock);
      }

    
      if (checkedItemsWastage.length > 0) {
        for (const x of checkedItemsWastage) {
          const { id, piece } = x;
          if (piece === 0) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Adet 0 seçilemez",
              placement: 'topRight'
            });
            setLoading(false);
            return;
  
          }
          const result = await FirebaseService.updateWastageReservedPiece(id, piece);
          if (result.success) {
            console.log("wasatage başarılı reserve güncellensin")
            x.isReserved = true; 
          }
          else {
            console.log("rezerve atmadı wastage")
            /*
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: `Hata: ${result.message}`,
              placement: 'topRight',
            });
            setLoading(false);
            return; // Skip this item and move to the next one
            */
          }
        }

        updatedCheckedItemsWastage = checkedItemsWastage.map(wastageItem => ({
          ...wastageItem,
          isReserved: wastageItem.isReserved, 
          code: wastageItem.code ? wastageItem.code : null

         
        }));
        
        console.log("Updated updatedCheckedItemsWastage:", updatedCheckedItemsWastage);
      }

      /* 
      //stocktaki itemlar isSendPurchase ekleniyor
      let updatedcheckedItemsStock = checkedItemsStock.map(stockItem => ({
            ...stockItem,
            isSendPurchase: checkSendPurchase(stockItem)
          })
        )
      */
      
      //console.log("updatedcheckedItemsStock",updatedcheckedItemsStock)
   
      //burdan hiç emin depğilimmmm
      console.log("buraya geldi kayıt atılıyor yeni open order")
      const currentDate =new Date()
      const data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            programmerUserId:localStorage.getItem("uid"),
            isDeleted: false,
            programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
            jobCode : createJobCode, 
            companyName: record.companyName,
            projectCode: record.projectCode ? record.projectCode : null,
            orderType : (record.orderType === "offer" && record.isOfferApproved) ? "order" : record.orderType,
            stock: updatedCheckedItemsStock,
            wastage: updatedCheckedItemsWastage,
            deadline:null, //setup sonunda seçiliyor
            isSetup:false,
            isOffer:record.isOffer ? record.isOffer : false,
            isWaiting: true,
            isOfferApproved:record.isOfferApproved ? record.isOfferApproved : false,
            noOfferApprovedDescription:record.noOfferApprovedDescription ? record.noOfferApprovedDescription : null,
            noQrScanDescription:record.noQrScanDescription ? record.noQrScanDescription : null,
            isOfferSetupUpdate: record.isOfferSetupUpdate ? record.isOfferSetupUpdate : false,
            isOfferChange:record.isOfferChange ? record.isOfferChange : false,
            isOfferDenied:record.isOfferDenied ? record.isOfferDenied : false,
           // priority:999999,
           isChangedProgrammer:false,
           isShipment: false,
           shipmentPiece: 1,
           invoiceNumber:null,
           setupName:setupName ? setupName : null
           
      };
      console.log("data",data)

      
      try {
          const result = await FirebaseService.addRecording(parsCollections.openOrder,data);
          if (result.success) {
            const item = {
              isDeleted:false,
              item: data.createJobCode,
              firstTransactionDate: currentDate,
              firstUserId: localStorage.getItem("uid"),
            }
            const result = await FirebaseService.addRecording(parsCollections.jobCodes,item);
          
            const currentJobCodeData = await FirebaseService.getCurrentJobCodeData(); // Mevcut iş kodunu al

            if (data && data.jobCode && typeof data.jobCode === 'string') {
                console.log("data.jobCode.substring(0, 5) ", data.jobCode.substring(0, 5));
            
                const filteredData = currentJobCodeData.find(item => item.jobCode === data.jobCode.substring(0, 5));
                if (filteredData) {
                      if (filteredData.key) {
                        const result2 = await FirebaseService.deleteCurrentJobCodeData(filteredData.key);
            
                        if (result2) {
                            console.log(`Item with key ${filteredData.key} deleted successfully.`);
                        } else {
                            console.log(`Failed to delete item with key ${filteredData.key}.`);
                        }
                    } else {
                        console.log("filteredData içinde key bulunamadı.");
                    }
                } else {
                    console.log("Eşleşen jobCode bulunamadı. filteredData undefined.");
                }
            } else {
                console.error("data.jobCode tanımlı değil veya geçersiz.");
            }


          //açık siparişler silme kısmı
              
          const openOrderData = await FirebaseService.getOpenOrderData();
          const filteredOpenOrder = openOrderData.filter(item => 
            item.jobCode && item.jobCode === createJobCode.substring(0, 5)
          );
          
          console.log("filteredOpenOrder", filteredOpenOrder);
          
          for (const item of filteredOpenOrder) {
            try {
              const result = await FirebaseService.deleteOpenOrderData(item.key);
              if (result) {
                console.log(`Item with key ${item.key} deleted successfully.`);
              } else {

                console.log(`Failed to delete item with key ${item.key}.`);
              }
            } catch (error) {
             
              console.error(`Error deleting item with key ${item.key}:`, error);
            }
          }


            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: "Açık siparişiniz başarıyla kaydedilmitşir. Rezerve işlemlerine devam edebilirsiniz.",
              placement: 'topRight'
            });
        

            setLoading(false);
            setIsModalVisibleSetupName(false);
            setTimeout(() => {
             // navigate("/programmerpage");
             setCheckedItemsStock([]);
             setCheckedItemsStockExcess([]);
             setCheckedItemsWastage([]);
             setInputValuesStock({});
             setInputValuesWastage({});
             fetchData();
             setIsReserved(true);
            // window.location.reload();

            }, 2000);
          } else {
           // console.log("hata",result)
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
              placement: 'topRight'
            });
            setLoading(false);
          }
          
          
      } 
      catch (error) {
          console.log("error 2",error)
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });
          setLoading(false);

      }
      
      

    }
    

    };
  
    const handleCancelSetupName = () => {
      setIsModalVisibleSetupName(false);
    };

    const showModal = () => {
      setLoading(true)
     console.log("record.setupName",record.setupName)

      const savedSetupName = localStorage.getItem("setupName");
      console.log("savedSetupName", savedSetupName);
    
      const checkZeroPiece = (items) => {
        for (const record of items) {
          const { piece } = record;
          if (piece === 0) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Adet 0 seçilemez",
              placement: 'topRight'
            });
            setLoading(false);
            return true; // Return true if error found
          }
        }
        return false; // No errors found
      };

       // Eğer seçili öğe yoksa işleme devam etmeyin
    if (checkedItemsStock.length === 0 && checkedItemsWastage.length === 0) {
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: "Seçili sac levha ya da fire bulunmamaktadır. Lütfen sac levha ya da fire seçiniz",
      placement: 'topRight'
    });
    setLoading(false);
    return; // İşlemi sonlandır
  }
    
      if (checkedItemsStock.length > 0 && checkZeroPiece(checkedItemsStock)) {
        return;
      }
    
      if (checkedItemsWastage.length > 0 && checkZeroPiece(checkedItemsWastage)) {
        return;
      }
    
      if(record.setupName === null && savedSetupName ===null) { //record.setupname null ve savedSetupName null
        setLoading(false);
        console.log("buraya girdi hiçbiri yok 1 ")
        if (!(checkedItemsStock.length > 0 || checkedItemsWastage.length > 0)) {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Seçili sac levha ya da fire bulunammaktadır. Lütfen sac levha yada fire seçiniz",
            placement: 'topRight'
          });
          setLoading(false);
          return;
        }
        setIsModalVisibleSetupName(true); 
      }
     else if (savedSetupName !== null  ||   record.setupName !== undefined  ) {
        console.log("nedense buraya girdi",savedSetupName, record.setupName )
        console.log("Saved setupName from localStorage:", savedSetupName);
        handleUpdate();
       // setLoading(false)
      }
      else if(record.setupName === undefined || savedSetupName ===undefined){
        console.log("buraya girdi hiçbiri yok2 ")
        setLoading(false)
        setIsModalVisibleSetupName(true); 
      }
    
     

    
    };
    
    
    const handleExit = () => {
      localStorage.removeItem("depotName");
      localStorage.removeItem("stockFilters");
      localStorage.removeItem("wastageFilters");
      localStorage.removeItem("setupName");
      localStorage.removeItem("checkedItemsStock");
      localStorage.removeItem("checkedItemsWastage");
      
      setTimeout(() => {
        navigate("/programmerpage");
      }, 2000);
    };


    //fazla ürün var satın alma talebi gidiyor
    const handleSendPurchaseRequest = async () => {
      setLoading(true);
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate.setHours(0, 0, 0, 0); 

      const updatedPurchaseList = checkedItemsStockExcess.map(item => ({
        ...item,
        jobCode: createJobCode,
        description:null
      }));

       //min stok kontrolü için satın alma isteği atılıyor
       for (const item of updatedPurchaseList) {
        //console.log("updatedPurchaseList",updatedPurchaseList)
        const updatedPurchaseListFixed = updatedPurchaseList.map(item => ({  //burda jobcode null olabilir ve currentpiece göndermeye gerek yok
            ...item,
            piece : item.minPiece- item.currentPiece,
            jobCode:null
          }));
        //  console.log("updatedPurchaseListFixed",updatedPurchaseListFixed)
        //  console.log(",item.currentPiece",item.currentPiece,item.minPiece,updatedPurchaseListFixed)
        if (item.currentPiece < item.minPiece) {
          try {
            const data = { 
                jobCode: jobCode,
                companyName: record.companyName,
                productType: "raw",
                unitName: "teknik ressam",
                deadline: currentDate,
              //  requestedProductInfo: checkedItemsStockExcess,
                requestedProductInfo:updatedPurchaseListFixed,
                offerJobCode: generateRandomCode(5),
                deadlineForPayment:null,
                paymentMethod:null,
                mailList:null,
                sentExcelList:null,
                incomingExcelList:null,
                acceptExcelDocumentUrl:null,
                description:"Minimum stok altına düşmüştür." ,
                isSendSetup: false
              };

             const result = await FirebaseService.addPurchaseRequest(data);
            if (result.success) {
              console.log(`Purchase request created successfully for item with id ${item.id}.`);
            } else {
              console.log(`Failed to create purchase request for item with id ${item.id}.`);
            }
            
          } catch (error) {
            console.error(`Error creating purchase request for item with id ${item.id}:`, error);
          }
        }
      }

      const data = { 
        jobCode: createJobCode,
        companyName: record.companyName,
        productType: "raw",
        unitName: "teknik ressam",
        deadline: currentDate,
      //  requestedProductInfo: checkedItemsStockExcess,
        requestedProductInfo:updatedPurchaseList,
        offerJobCode: generateRandomCode(5),
        deadlineForPayment:null,
        paymentMethod:null,
        mailList:null,
        sentExcelList:null,
        incomingExcelList:null,
        acceptExcelDocumentUrl:null,
        description:"Teknik ressam tarafından seçilmiştir, setupda kullanılacaktır." ,
        isSendSetup: true
      };
    //  console.log("satın alma talebi data",data)
      
      try {
        const result = await FirebaseService.addPurchaseRequest(data);
        if (result.success) {
          setVisibleContinue(false);
          setIsModalVisibleSetupName(false);

       //  console.log( "satın alma talebi gönderildi.")

       const savedSetupName = localStorage.getItem("setupName");

         
       const savedCheckedItemsStock = JSON.parse(localStorage.getItem("checkedItemsStock"));
       const savedCheckedItemsWastage = JSON.parse(localStorage.getItem("checkedItemsWastage"));
       
       console.log("savedCheckedItemsStock satın alma talebi ", savedCheckedItemsStock);
       console.log("savedCheckedItemsWastage satın alma talebi", savedCheckedItemsWastage);
       
 
       const createJobCode =  record.setupName ?  jobCode : `${jobCode}-${savedSetupName}`;
       console.log("createJobCode 1",createJobCode)
       setCreateJobCode(createJobCode);
       
     
      


       let updatedCheckedItemsStockExcess;
       //  console.log("checkedItemsStockExcess",checkedItemsStockExcess)
         if (checkedItemsStockExcess && checkedItemsStockExcess.length > 0) {
           for (const x of checkedItemsStockExcess) {
             const { id, piece } = x;
             if (piece === 0) {
               NotificationService.openErrorNotification({
                 title: 'İşlem Başarısız',
                 description: "Adet 0 seçilemez",
                 placement: 'topRight'
               });
               setLoading(false);
               return;
     
             }
             const result = await FirebaseService.updateStockWaitingPiece(id, piece);
            // console.log(result);
           }
         //  console.log("Updated checkedItemsStock:", updatedCheckedItemsStockExcess);
         }

         const requestedItems = checkedItemsStock.filter(stockItem =>
           !checkedItemsStockExcess.some(item =>
             item.id === stockItem.id &&
             item.quality === stockItem.quality &&
             item.height === stockItem.height &&
             item.width === stockItem.width &&
             item.piece === stockItem.piece &&
             item.thickness === stockItem.thickness &&
             item.surface === stockItem.surface
           )
         );
         
        // console.log("stocktan talep edilenler çıktı",requestedItems);
   
         //stoktan ve fireden reserved update oluyo
         let updatedCheckedItemsStock;
         
         if (requestedItems && requestedItems.length > 0) {
           for (const x of requestedItems) {
             const { id, piece } = x;
             if (piece === 0) {
               NotificationService.openErrorNotification({
                 title: 'İşlem Başarısız',
                 description: "Adet 0 seçilemez",
                 placement: 'topRight'
               });
               setLoading(false);
               return;
     
             }
             const result = await FirebaseService.updateStockReservedPiece(id, piece);
             if (result.success) {
              console.log("stcok başarılı reserve güncellensin")
              x.isReserved = true; // Update isReserved to true if successful
            }
           else{
            console.log("rezerve atmadı stock")
            /*
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Hata: ${result.message}`,
                placement: 'topRight',
              });
              setLoading(false);
              return; 
              */
            }
            // console.log(result);
           }
         // console.log("Updated checkedItemsStock:", updatedCheckedItemsStock);
         }
   

         let updatedCheckedItemsWastage;
         if (checkedItemsWastage && checkedItemsWastage.length > 0) {
           for (const x of checkedItemsWastage) {
             const { id, piece } = x;
             if (piece === 0) {
               NotificationService.openErrorNotification({
                 title: 'İşlem Başarısız',
                 description: "Adet 0 seçilemez",
                 placement: 'topRight'
               });
               setLoading(false);
               return;
     
             }
             const result = await FirebaseService.updateWastageReservedPiece(id, piece);
             if (result.success) {
              console.log("wasatage başarılı reserve güncellensin")
              x.isReserved = true; // Update isReserved to true if successful
            }
            // If the update fails, show an error notification for this item but continue the loop
            else {
              console.log("rezerve atmadı wastage")
              /*
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Hata: ${result.message}`,
                placement: 'topRight',
              });
              setLoading(false);
              return; // Skip this item and move to the next one
              */
            }
        
            // console.log(result);
           }
         
           updatedCheckedItemsWastage = checkedItemsWastage.map(({ oldPiece, ...rest }) => rest);
          // console.log("Updated updatedCheckedItemsWastage:", updatedCheckedItemsWastage);
         }

       //stocktaki itemlar isSendPurchase ekleniyor
       let updatedcheckedItemsStock = checkedItemsStock.map(stockItem => ({
         ...stockItem,
         isSendPurchase: checkSendPurchase(stockItem),
         code: stockItem.code ? stockItem.code : null

         
       })
       )
       console.log("updatedcheckedItemsStock",updatedcheckedItemsStock)
     
       
     
       const arraysEqual = (arr1, arr2) => {
         if (arr1.length !== arr2.length) return false;
         return arr1.every((item, index) => 
             item.id === arr2[index].id &&
             item.oldPiece === arr2[index].oldPiece &&
             item.quality === arr2[index].quality &&
             item.height === arr2[index].height &&
             item.width === arr2[index].width
         );
       };
   
       const openOrderData = await FirebaseService.getOpenOrderData();
       const filteredData = openOrderData.filter(item => 
         !item.isDeleted && 
         item.jobCode === createJobCode 
         && item.companyName === record.companyName 
         && item.orderType === record.orderType 
         && item.projectCode === record.projectCode
       &&  arraysEqual(item.stock, savedCheckedItemsStock) &&
         arraysEqual(item.wastage, savedCheckedItemsWastage)
       );
          
        if (filteredData.length === 0) {
             //açık siparişlere kayıt
         const currentDate =new Date()
         const data = {
               firstTransactionDate: currentDate,
               firstUserId: localStorage.getItem("uid"),
               programmerUserId:localStorage.getItem("uid"),
               isDeleted: false,
               programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
               jobCode : createJobCode, 
               companyName: record.companyName,
               projectCode: record.projectCode ? record.projectCode : null,
               orderType :record.orderType,
               stock: updatedcheckedItemsStock,
               wastage: checkedItemsWastage,
               deadline:null, //setup sonunda seçiliyor
               isSetup:false,
               isOffer:false,
               isWaiting: true,
               isOfferApproved:false,
               noOfferApprovedDescription:null,
               noQrScanDescription:null,
               isOfferSetupUpdate:false,
               isOfferChange:false,
               isOfferDenied:false,
              // priority:999999,
              isChangedProgrammer:false,
              isShipment: false,
              shipmentPiece: 1,
              invoiceNumber:null,
              setupName:setupName ? setupName : null

              
         };
       console.log("data setupsız kayot",data)
          try {
            const result = await FirebaseService.addRecording(parsCollections.openOrder,data);
            if (result.success) {
              const item = {
                isDeleted:false,
                item: data.createJobCode,
                firstTransactionDate: currentDate,
                firstUserId: localStorage.getItem("uid"),
              }
              const result = await FirebaseService.addRecording(parsCollections.jobCodes,item);
            

              const currentJobCodeData = await FirebaseService.getCurrentJobCodeData(); // Mevcut iş kodunu al

              if (data && data.jobCode && typeof data.jobCode === 'string') {
                  console.log("data.jobCode.substring(0, 5) ", data.jobCode.substring(0, 5));
              
                  const filteredData = currentJobCodeData.find(item => item.jobCode === data.jobCode.substring(0, 5));
                  if (filteredData) {
                        if (filteredData.key) {
                          const result2 = await FirebaseService.deleteCurrentJobCodeData(filteredData.key);
              
                          if (result2) {
                              console.log(`Item with key ${filteredData.key} deleted successfully.`);
                          } else {
                              console.log(`Failed to delete item with key ${filteredData.key}.`);
                          }
                      } else {
                          console.log("filteredData içinde key bulunamadı.");
                      }
                  } else {
                      console.log("Eşleşen jobCode bulunamadı. filteredData undefined.");
                  }
              } else {
                  console.error("data.jobCode tanımlı değil veya geçersiz.");
              }

              
               //açık siparişler silme kısmı
              
              const openOrderData = await FirebaseService.getOpenOrderData();
              const filteredOpenOrder = openOrderData.filter(item => 
                item.jobCode && item.jobCode === createJobCode.substring(0, 5)
              );
              
              console.log("filteredOpenOrder", filteredOpenOrder);
              
              for (const item of filteredOpenOrder) {
                try {
                  const result = await FirebaseService.deleteOpenOrderData(item.key);
                  if (result) {
                    console.log(`Item with key ${item.key} deleted successfully.`);
                  } else {

                    console.log(`Failed to delete item with key ${item.key}.`);
                  }
                } catch (error) {
                
                  console.error(`Error deleting item with key ${item.key}:`, error);
                }
              }

              NotificationService.openSuccessNotification({
                title: 'İşlem Başarılı',
                description: "Açık siparişiniz başarıyla kaydedilmitşir. Rezerve işlemlerine devam edebilirsiniz.",
                placement: 'topRight'
              });
              setLoading(false);
              setIsModalVisibleSetupName(false);
              setTimeout(() => {
               // navigate("/programmerpage");
               setCheckedItemsStock([]);
               setCheckedItemsStockExcess([]);
               setCheckedItemsWastage([]);
               setInputValuesStock({});
               setInputValuesWastage({});
               fetchData();
               setIsReserved(true);
              // window.location.reload();
  
              }, 2000);
            } else {
             // console.log("hata",result)
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
              setLoading(false);
              return;
            }
            
            
        } 
        catch (error) {
            console.log("error 3",error)
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
            setLoading(false);

        }
          
        }
        else{

        // checkedItemsStock için
        const existingStockItems = JSON.parse(localStorage.getItem("checkedItemsStock")) || []; // Mevcut veriyi al veya boş dizi kullan
        const updatedStockItems = [...existingStockItems, ...updatedcheckedItemsStock]; // Mevcut ve yeni verileri birleştir
        localStorage.setItem("checkedItemsStock", JSON.stringify(updatedStockItems)); // Güncellenmiş veriyi kaydet
        console.log("updatedStockItems LOKALDE ",updatedStockItems)
        // checkedItemsWastage için
        const existingWastageItems = JSON.parse(localStorage.getItem("checkedItemsWastage")) || []; // Mevcut veriyi al veya boş dizi kullan
        const updatedWastageItems = [...existingWastageItems, ...checkedItemsWastage]; // Mevcut ve yeni verileri birleştir
        localStorage.setItem("checkedItemsWastage", JSON.stringify(updatedWastageItems)); // Güncellenmiş veriyi kaydet
        console.log("updatedWastageItems LOKALDE ",updatedWastageItems)

          const updateOpenOrderItem = {
            stock: updatedcheckedItemsStock,
            wastage: checkedItemsWastage,   
      };
      console.log("daupdateOpenOrderItemta",updateOpenOrderItem)

      try {
        const result = await FirebaseService.updateOpenOrderStockAndWastage(filteredData[0].key, updateOpenOrderItem);
        if (result.success) {
           
          console.log("record çok önemliiiiii /tekrar kontrol edilyor rezerveleer ",record)
          const againCheckReserve =  await checkItems(updateOpenOrderItem)
          console.log("againCheckReserve",againCheckReserve)
          if(!againCheckReserve){
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Rezerve edilemedi. Rezerve etmek istediğiniz tükenmiş olabilir lütfen başka seçiniz.",
              placement: 'topRight'
          });
          setLoading(false);
          return;
          }


          
          
            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: "Seçilenler başarıyla rezerve edilmiştir.",
              placement: 'topRight'
            });
            setIsModalVisibleSetupName(false);
            setTimeout(() => {
              setCheckedItemsStock([]);
              setCheckedItemsStockExcess([]);
              setCheckedItemsWastage([]);
              setInputValuesStock({});
              setInputValuesWastage({});
              fetchData();
              setIsReserved(true);
              setLoading(false);
            //  window.location.reload();
            }, 2000);
          } else {
           // console.log("hata",result)
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
              placement: 'topRight'
            });
            setLoading(false);
          }
          
          
      } 
      catch (error) {
          console.log("error 4",error)
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });
          setLoading(false);

      }
        }
         
        }
        else{
          console.log("error 5")
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });
          setLoading(false);

        }
        
      } 
      catch (error) {
        console.log("error 6",error)
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
        setLoading(false);
      }
      
    };
  
    const [visibleContinue, setVisibleContinue] = useState(false);

    const handleCancelContinue = () => {
     setVisibleContinue(false);
    };

    const columnsStockExcess = [
      {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
       
      //  ...getColumnSearchPropsStock('thickness'),
        sorter: (a, b) => a.thickness - b.thickness,
        render: (text) => <span style={{ }}>{text}mm </span>,
      },
        {
          title: 'Kalite',
          dataIndex: 'quality',
          key: 'quality',
        
         // ...getColumnSearchPropsStock('quality'),
          sorter: (a, b) => a.quality.localeCompare(b.quality), 
          render: (text) => <span style={{  }}>{text}</span>,
        },     
          {
            title: 'En',
            dataIndex: 'width',
            key: 'width',
           
           // ...getColumnSearchPropsStock('width'),
            sorter: (a, b) => a.width - b.width,
            render: (text) => <span style={{  }}>{text}</span>,
          },
          {
            title: 'Boy',
            dataIndex: 'height',
            key: 'height',
           // ...getColumnSearchPropsStock('height'),
            sorter: (a, b) => a.height - b.height,
            render: (text) => <span style={{  }}>{text}</span>,
          },
    
          {
            title: 'Yüzey',
            dataIndex: 'surface',
            key: 'surface',
           // ...getColumnSearchPropsStock('surface'),
            sorter: (a, b) => a.surface.localeCompare(b.surface), 
            render: (text) => <span style={{  }}>{text}</span>,
          },
     
          {
            title: 'Adet',
            dataIndex: 'piece',
            key: 'piece',
            render: (text,record) => <span style={{}}>{text}</span>,
           
            sorter: (a, b) => a.piece - b.piece,
           // ...getColumnSearchPropsStock('piece'),
          },         
        
    ];

  
    return ( 
    <div style={{padding:"10px 10px",}}>
        <div style={{ display: "flex", justifyContent: "space-between", textAlign:"center",paddingLeft:"40px" }}>
            <div style={{ textAlign: "left", flex: "1 0 25%" }}>
                <p style={{ fontWeight: "bold", color: colors.blue }}>Müşteri</p>
                {record.companyName}
            </div>
            <div style={{ textAlign: "left", flex: "1 0 25%" }}>
                <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
                {jobCode ? jobCode : "-" }
            </div>
            
            <div style={{ textAlign: "left", flex: "1 0 25%" }}>
              <p style={{ fontWeight: "bold", color: colors.blue }}>Proje Kodu</p>
              {record.projectCode ? record.projectCode : "-"}
            </div>

            <div style={{ textAlign: "left", flex: "1 0 25%" }}>
    <p style={{ fontWeight: "bold", color: colors.blue }}>Depo</p>
    <Select
        onChange={onChangeFilterWarehouse}
        placeholder="Depo Seçiniz"
        style={{ width: "150px" }}
        showSearch
        filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
        }
        suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
        options={warehouseOptions
            .sort((a, b) => {
              if (a.label.toLowerCase() === "tümü") return -1;
              if (b.label.toLowerCase() === "tümü") return 1;
              if (a.label.toLowerCase() === "pars") return a.label.toLowerCase() === "tümü" ? 1 : -1;
              if (b.label.toLowerCase() === "pars") return b.label.toLowerCase() === "tümü" ? 1 : 1;
              return a.label.localeCompare(b.label);
          })
          }
        className="input-style"
    />
</div>

        </div>
        
        {!selectedWarehouse ? (
  
  <div style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh", 
    color: colors.gray,
    fontWeight: "bold",
    fontSize: "18px",
  }}>
    Lütfen depo seçiniz
  </div>
) : (
  <>
        <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px",textAlign:"left" }}>Fire Listesi</div>
      <Table
        style={{width:"100%", margin:"auto"}}
        scroll={{ y: 500 }}
            locale={{
              emptyText: 'Henüz fire bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
           dataSource={filteredItemsWastage}
            columns={columnsWastage}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />

    <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px",textAlign:"left" }}>Güncel Stok Listesi</div>
      <Table
         style={{width:"100%", margin:"auto"}}
         scroll={{ y: 500 }}
          locale={{
            emptyText: 'Henüz stok bulunmamaktadır...',
            filterReset: 'Sıfırla',
            filterTitle: 'Filtre Menüsü',
            selectAll: 'Hepsini Seç',
            selectInvert: 'Tersini Seç',
            selectionAll: 'Tümünü Seç',
            sortTitle: 'Sıralama',
            triggerDesc: 'Azalan sıralama için tıklayın',
            triggerAsc: 'Artan sıralama için tıklayın',
            cancelSort: 'Sıralamayı iptal etmek için tıklayın',
          }}
           dataSource={filteredItemsStock}
            columns={columnsStock}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />


      <div style={{display:"flex", justifyContent:"right"}}>
                  <Button 
               style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} 
              onClick={handlePurchaseRequest}>
              Talep Et
                </Button>
                <Modal
                title="Satın Alma Talep Et"
                visible={isModalVisiblePurchase}
                onCancel={handleModalCancelPurchase}
                footer={null}
                >
                <PurchaseRequestAdd onClose={handlePurchaseRequestAddFinish} />
                </Modal>
          

                <Button
                  style={{
                    backgroundColor: !(savedLocalSetupName) ? 'gray' : colors.blue, // Gray background when disabled
                    color: "white",
                    fontWeight: "500",
                    cursor: !(savedLocalSetupName) ? 'not-allowed' : 'pointer' // Show 'not-allowed' cursor when disabled
                  }}
                  onClick={() => handleSetup(record)}
                  disabled={!(savedLocalSetupName)}
                >
                  Setup Yükle & Devam Et
                </Button>


          <Modal
            title="Setup Yükle"
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={null}
          >
            <SetupAdd  recordx={recordForModal}  onClose={handleSetupFinish} />
          </Modal>

          <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}   onClick={showModal} >
          Kaydet (Setupsız)
          </Button>

          <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}   onClick={handleExit} >
        Çık
          </Button>


      <Modal
        title="Setup Name"
        visible={isModalVisibleSetupName}
        onCancel={handleCancelSetupName}
        footer={[
          <Button key="cancel" onClick={handleCancelSetupName}>
            İptal
          </Button>,
          <Button key="save"   style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}  onClick={handleSaveSetupName}>
            Kaydet
          </Button>,
        ]}
      >
        <Input
          placeholder="Setup name"
          value={setupName}
          onChange={handleInputChangeSetupName}
          style={{ marginTop: '10px' }}
        />
         {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
      </Modal>





      <div>           
          <Modal
                  title="Satın Alma Talebi Gönder"
                  visible={visibleContinue}
                  onOk={handleSendPurchaseRequest}
                  onCancel={handleCancelContinue}
                  okText="Evet, Gönder ve Açık Sipariş Oluşturmaya Devam Et"
                  cancelText="Vazgeç"
                  className="columnsStockExcess popup-modal-button"
                
              >
              <div> 
                <p>Satın alma talebi göndermek istediğinizden emin misiniz?</p>
                <Table
                    dataSource={checkedItemsStockExcess}
                    columns={columnsStockExcess}
                    showHeader={true}
                    pagination={false} 
                    className="custom-news-table"
                    rowClassName="custom-news-row"
                    locale={{
                      emptyText: 'Henüz eklemediniz...',
                      filterReset: 'Sıfırla',
                      filterTitle: 'Filtre Menüsü',
                      selectAll: 'Hepsini Seç',
                      selectInvert: 'Tersini Seç',
                      selectionAll: 'Tümünü Seç',
                      sortTitle: 'Sıralama',
                      triggerDesc: 'Azalan sıralama için tıklayın',
                      triggerAsc: 'Artan sıralama için tıklayın',
                      cancelSort: 'Sıralamayı iptal etmek için tıklayın',
                    }}
                />
            </div>
            
        </Modal>

        </div>

           
      </div>
      
      </>
      )}
      {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>
    )

};


export default OpenOrderAddContinue;