import React,{useEffect,useState} from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';
import {Space,Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import  { placeholderMap}  from "../../../environments/environment"

const PurchaseRequestList = () => {
  //const [purchaseRequests, setPurchaseRequests] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const purchaseRequests = location.state && location.state.record;
  const [items, setItems] = useState();
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
   // console.log("purchaseRequests",purchaseRequests);
   // console.log('record.deadline:', purchaseRequests[0].deadline);
    //console.log('record.deadline type:', typeof purchaseRequests[0].deadline);

    window.scrollTo(0, 0);

    const fetchData = async () => {
      const sortedItems = purchaseRequests.sort((a, b) => {
        // Teklif gönderilip gönderilmediğine göre sıralama
        const offerA = (a.mailList?.length >= 3) ? 1 : 0;
        const offerB = (b.mailList?.length >= 3) ? 1 : 0;
    
        if (offerA !== offerB) {
          return offerA - offerB;
        }
    
        // Teklif gönderilmeyenleri termin tarihine göre sıralama
        const dateA = a.deadline && a.deadline.length > 0 ? new Date(a.deadline[0].seconds * 1000) : new Date(0);
        const dateB = b.deadline && b.deadline.length > 0 ? new Date(b.deadline[0].seconds * 1000) : new Date(0);
    
        if (dateA - dateB !== 0) {
          return dateA - dateB;
        }
    
        const unitNameA = a.unitName || '';
        const unitNameB = b.unitName || '';
        return unitNameA.localeCompare(unitNameB);
      });

      setItems(sortedItems);
      setFilteredItems(sortedItems);
    };

    if (purchaseRequests && purchaseRequests.length > 0) {
      fetchData();
    }
  }, [purchaseRequests]);

  const goEditPage = (record) => {
    navigate('/purchaserequestdetail', { state: { record } })
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase(); 
      const itemValue = item[dataIndex]?.toString().toLowerCase(); 
      return (
        !item.isDeleted &&
       
        (value ? itemValue.includes(value) : true) 
      );
    });
    setFilteredItems(filteredData);
  };
  
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };

  const getColumnSearchProps = dataIndex => ({
    
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
     <Input
    id="search-input"  
    placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
    value={selectedKeys[0]}
    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    style={{ width: 188, marginBottom: 8, display: 'block' }}
    />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90,background:colors.blue }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });



  const columns = [
    {
      title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
     
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      ...getColumnSearchProps('companyName'),
      sorter: (a, b) => {
        const companyNameA = a && a.companyName ? a.companyName : '';
        const companyNameB = b && b.companyName ? b.companyName : '';
        return companyNameA.localeCompare(companyNameB);}
  },
    {
      title: 'Ürün',
      dataIndex: 'productType',
      key: 'productType',
      
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text === 'raw' ? 'Saç Levha' : text === 'other' ? 'Diğer' : text}
      </span>,
      ...getColumnSearchProps('companyName'),
      sorter: (a, b) => {
        const productTypeA = a && a.productType ? a.productType : '';
        const productTypeB = b && b.productType ? b.productType : '';
        return productTypeA.localeCompare(productTypeB);
      }
    },
    {
      title: 'Birim',
      dataIndex: 'unitName',
      key: 'unitName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
      sorter: (a, b) => {
        const unitNameA = a && a.unitName ? a.unitName: '';
        const unitNameB = b && b.unitName ? b.unitName : '';
        return unitNameA.localeCompare(unitNameB);
      }
     
    },
    {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
       
        render: (text, record) => {
          const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
          return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
        },
        sorter: (a, b) => {
          const deadlineA = a.deadline ? a.deadline.seconds : 0;
          const deadlineB = b.deadline ? b.deadline.seconds : 0;
          return deadlineA - deadlineB;
      },
      
      },
      {
        title: 'Sipariş Kod',
        dataIndex: 'offerJobCode',
        key: 'offerJobCode',
        width: 200,
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
        </span>,
         sorter: (a, b) => {
          const offerJobCodeA = a && a.offerJobCode ? a.offerJobCode: '';
          const offerJobCodeB = b && b.offerJobCode ? b.offerJobCode : '';
          return offerJobCodeA.localeCompare(offerJobCodeB);
        }
      },
      
      {
        title: 'Durum',
        dataIndex: '',
        key: '',
       
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}> 
        {record.productType === "raw" ?
        record.mailList?.length >= 3 ? "Teklif Gönderildi" : "Teklif Gönderilmedi"   :
        record.isPurchased ? "Satın Alındı" :   record.noPurchasedDescription ? 
        "Satın Alınmayacak" : "Satın Alınma Bekleniyor"
        }       
        
         </span>,
       
      },
      
      {
        title: ' ',
        dataIndex: 'important',
        key: 'important',
        render: (text, record) => {
          const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
          const isBeforeToday = record.deadline ? moment(formattedDeadline, 'DD.MM.YYYY').isBefore(moment(), 'day') : false;
      
          return (
            <>
              {isBeforeToday ? (
                <img width={30} height={20} alt="logo" src="/images/important.png" title="Termin Tarihi Geçmiştir" style={{ paddingBottom: "5%" }} />
              ) : null}
            </>
          );
        },
      },       
    {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
          Detayı Gör
       </Button>
        ),
      }
  ];

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3  style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>Satın Alma Gelen Talepler</h3>
        
          <Table
            locale={{
              emptyText: 'Henüz satın alma talebi bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={filteredItems}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>

  };
  export default PurchaseRequestList;

    /*
            expandable={{
              expandedRowRender: record => (
                <div style={{ display: 'flex' }}>
                <div style={{ flex: 0.5,marginLeft:"55px"}}>
                  <span style={{fontWeight:"bold"}}>Email:</span>
                 <p style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{record.email}</p>
                 </div>
                 <div style={{ flex: 0.5,marginLeft:"55px"}}>
                 <span style={{fontWeight:"bold"}}>Telefon Numarası:</span>
                 <p style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{record.phoneNumber}</p>
                 </div>
                <div style={{ flex: 1,marginLeft:"55px" }}>
                <span style={{fontWeight:"bold"}}>Not:</span>
                 <p style={{color: record.isDeleted ? "#00000080" : "#000000", width:"500px"}}>{record.note}</p>
                </div>
            </div>
              ),
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/uparrow.png" /></a>
                ) : (
                  <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" /></a>
                ),
                expandIconColumnIndex: 5
            }}
            */