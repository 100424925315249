import React, { useState } from "react";
import  { colors}  from "../../../../environments/environment"
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Input } from "antd";
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import { useNavigate } from 'react-router-dom';

const NoOfferApprovedDescriptionDetail = ({ record,onClose }) => {

     const [isOfferSetupUpdate, setIsOfferSetupUpdate] = useState(false);
     const [isOfferChange, setIsOfferChange] = useState(false);
     const [isOfferDenied, setIsOfferDenied] = useState(false);
     const navigate = useNavigate();


     const onFinish = async (values) => {
     console.log( "values",values)
     
     const trueValues = [isOfferSetupUpdate, isOfferChange, isOfferDenied].filter(Boolean);
     if (trueValues.length !== 1) {
       NotificationService.openErrorNotification({
         title: 'Geçersiz Giriş',
         description: "Lütfen yalnızca bir işlem seçin.",
         placement: 'topRight'
       });
       return;
     }


     const allOpenOrder = await FirebaseService.getOpenOrderData();
     const filteredOpenOrder = allOpenOrder.filter(item => !item.isDeleted && item.isWaiting && item.isOffer && item.orderType === "offer" && item.jobCode.includes(record.jobCode));
 
     console.log("filteredOpenOrder",filteredOpenOrder)
 
     const updatePromises =filteredOpenOrder.map(async (item) => {
          let updateOpenOrderItem = {
               noOfferApprovedDescription:values.description,
              
               isOfferable: true, 
               isOffer: true,

               isOfferSetupUpdate: isOfferSetupUpdate ? true : record.isOfferSetupUpdate,
               isOfferChange: isOfferChange ? true : record.isOfferChange,
               isOfferDenied: isOfferDenied ? true : record.isOfferDenied,
          };

          console.log("updateOpenOrderItem", updateOpenOrderItem, record);
           

          //burda daha önce gönderilen teklif silinebilir emin değilim
          
          const resultx = await FirebaseService.updateOpenOrder(item.key, updateOpenOrderItem);
          console.log(resultx);
    
          if (resultx) {
           console.log("başarılı")
          } else {
            console.log("başarısız");
          }
    
          return resultx;
          
        });
    
        const results = await Promise.all(updatePromises);
    
        if (results.every(result => result)) {
          console.log("hespi başarlılııııı")

          const allData = await FirebaseService.getInvoiceOpenOrderData();
          const filteredItem = allData.find(item => item.jobCode === record.jobCode.substring(0,5));

          console.log("filteredItem",filteredItem)
          const result = await FirebaseService.invoiceOpenOrderIsVisible(filteredItem.key);
          console.log("invoce open order güncellendi result",result)



          NotificationService.openSuccessNotification({
            title: 'Başarılı',
            description: "Başarıyla gönderilmiştir.",
            placement: 'topRight',
          });
         
          onClose();

          setTimeout(() => {
               window.location.reload();
          }, 2000);
          
        } 
        
        else {
          NotificationService.openErrorNotification({
            title: 'Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight',
          });
        }
        

     }
     
     return ( 
          <div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ textAlign: "left", flex: "1 0 14.66%" }}>
               <p style={{ fontWeight: "bold", color: colors.blue }}>Firma</p>
               {record && record.companyName}
          </div>

          <div style={{ textAlign: "left", flex: "1 0 14.66%" }}>
               <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
               {record && record.jobCode}
          </div>

          <div style={{ textAlign: "left", flex: "1 0 14.66%" }}>
               <p style={{ fontWeight: "bold", color: colors.blue }}>Proje Kodu</p>
               {record.projectCode ? record.projectCode : "-"}
          </div>

          <div style={{ textAlign: "left", flex: "1 0 14.66%" }}>
               <p style={{ fontWeight: "bold", color: colors.blue }}>Programcı</p>
               {record && record.programmerName.join(",")}
          </div>


          <div style={{ textAlign: "left", flex: "1 0  14.66%" }}>
               <p style={{ fontWeight: "bold", color: colors.blue }}>Durum</p>
               Onaylanmadı
          </div>

          <div style={{ textAlign: "left", flex: "1 0 14.66%" }}>
               <p style={{ fontWeight: "bold", color: colors.blue }}>Toplam Kilo</p>
               {record && record.totalKgs.toFixed(2)} Kg
          </div>


          <div style={{ textAlign: "left", flex: "1 0 14.66%" }}>
               <p style={{ fontWeight: "bold", color: colors.blue }}> Toplam Süre</p>
               {Object.entries(record.setupTime).map(([key, value]) => (
                    <div key={key}>{value}</div>
               ))}
          </div>

          </div>

          <Form
               name="basic"
               initialValues={{
               remember: true,
               }}
               onFinish={onFinish}
               autoComplete="off"
          >
          <div style={{ textAlign: "left", }}>
               <p style={{ fontWeight: "bold", color: colors.blue }}> Açıklama </p>
               <Form.Item name="description"
               rules={[
               {
               required: true,
               message: 'Lütfen açıklama giriniz!',
               }
               ]} 
               >
            <Input.TextArea rows={10} style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Açıklama Giriniz (Zorunludur)"/>
               </Form.Item>  
          </div>

          <div style={{display:"flex"}}>
               <div style={{ textAlign: "left" }}>
                    <p style={{ fontWeight: "bold", color: colors.blue }}>
                    <Form.Item
                    name="isOfferSetupUpdate">
                    <Checkbox  onChange={() => setIsOfferSetupUpdate(!isOfferSetupUpdate)} style={{fontWeight:"500"}}>
                         Setup Güncelleme
                    </Checkbox>
                    </Form.Item>
                    </p>
               </div>   

               <div style={{ textAlign: "left" }}>
                    <p style={{ fontWeight: "bold", color: colors.blue }}>
                    <Form.Item
                    name="isOfferChange">
                    <Checkbox onChange={() => setIsOfferChange(!isOfferChange)} style={{fontWeight:"500"}}>
                         Teklif Değişikliği
                    </Checkbox>
                    </Form.Item>
                    </p>
               </div>  

               <div style={{ textAlign: "left" }}>
                    <p style={{ fontWeight: "bold", color: colors.blue }}>
                    <Form.Item
                    name="isOfferDenied">
                    <Checkbox  onChange={() => setIsOfferDenied(!isOfferDenied)} style={{fontWeight:"500"}}>
                        Reddedildi
                    </Checkbox>
                    </Form.Item>
                    </p>
               </div>  

          </div>
          <div style={{textAlign:"right"}}>
                <Form.Item>
               <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Kaydet</Button>
               </Form.Item>

          </div>

         
          </Form>
               
     </div>
     )
};

NoOfferApprovedDescriptionDetail.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

export default NoOfferApprovedDescriptionDetail;