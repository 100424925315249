import React,{useEffect,useState} from "react";
import { Button, Form, Input ,Select,Spin} from 'antd';
import NotificationService from "../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import { useNavigate } from 'react-router-dom';



const AbkantMachineEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const record = location.state && location.state.record;
    const [loading, setLoading] = useState(false);
   

  const [programmers, setProgrammers] = useState([]); 
  const [selectedProgrammer, setSelectedProgrammer] = useState({ id: null, nameSurname: '', jobCode: '' });

    useEffect(() => {
      const fetchData = async () => {
     
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("abkant"));
        const filteredData = filteredUsers.map(user => {
         
        return {
            nameSurname: user.name  + " " + user.surname,
            id: user.key,
          };
        });
        setProgrammers(filteredData);
      };
      fetchData();
    }, []);


    const onChange = (value) => {
      const selected = programmers.find(item => item.id === value);
      if (selected) {
        setSelectedProgrammer({ id: selected.id, nameSurname: selected.nameSurname });
      }
    };
  
   
    const onFinish = async (values) => {
      setLoading(true)
      const currentDate =new Date()
      const data = {
        firstTransactionDate: record.firstTransactionDateEdit??currentDate,
        firstUserId: record.firstUserId??localStorage.getItem("uid"),
        lastTransactionDate: currentDate,
        lastUserId: localStorage.getItem("uid"),
        isDeleted: record.isDeleted,
        machineName: record.machineName,
        operatorNameSurname: selectedProgrammer.nameSurname,
        operatorId: selectedProgrammer.id,

        machineType: record.machineType
      };

      console.log("data",data)
    
        
       
        try {
          
          const result = await FirebaseService.updateMachine(record.key,data);
          if (result.success) {
            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: result.message,
              placement: 'topRight'
            });setLoading(false)
            setTimeout(() => {
              navigate("/abkantmachinelist");
            }, 2000);
          } else {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
              placement: 'topRight'
            });setLoading(false)}
          
          }
        catch (error) {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });setLoading(false)
        }
      
    };

    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };
 

   return ( <div>
    
         <Form
            name="basic"
            initialValues={{
              item: record.item ,
            }}
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
       
       <Form.Item
           label="Makine Adı"
            name="machineName"
            wrapperCol={{
              span: 6,
              }}
              style={{
              maxWidth: "100%",
              textAlign:"left"
              }}
            >
            <Input  disabled style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder={record.machineName}/>
            </Form.Item>

            <Form.Item
             label="Operatör Adı"
            name="operatorNameSurname"
            rules={[
                {
                required: true,
                message: 'Lütfen operatör giriniz!',
                },
            ]}
            wrapperCol={{
              span: 6,
              }}
              style={{
              maxWidth: "100%",
              textAlign:"left"
              }}
            >
               <Select
                  showSearch
                      onChange={onChange}
                      placeholder="Operatör Seçiniz"
                      style={{ width:"300px"}}
                      className="input-style" 
                      suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                    options={programmers.map(item => ({ label: item.nameSurname, value: item.id }))}
                    filterOption={(input, option) => 
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    />

            </Form.Item>

        
            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>)

        };
  export default AbkantMachineEdit;