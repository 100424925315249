import React, { useEffect, useState } from "react";
import { Table, Button, Spin, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";
const MailList = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const data = await FirebaseService.getMailsData();
      const filteredData = data.filter((item) => !item.isDeleted);
      const sortedData = filteredData.sort(
        (a, b) => new Date(b.firstTransactionDate) - new Date(a.firstTransactionDate)
      );
      setItems(sortedData);
      setFilteredItems(sortedData); 
    };
    fetchData();
  }, []);
  const goEditPage = (record) => {
    navigate("/mailedit", { state: { record } });
  };
  const goAddPage = () => {
    navigate("/mailadd");
  };
  const handleDelete = async (record, isDeleted) => {
    setLoading(true);
    try {
      let result;
      if (isDeleted) {
        result = await FirebaseService.makeMailVisible(record.key);
      } else {
        result = await FirebaseService.deleteMail(record.key);
      }
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: "İşlem Başarılı",
          description: result.message,
          placement: "topRight",
        });
        const data = await FirebaseService.getMailsData();
        const filteredData = data.filter((item) => !item.isDeleted);
        setItems(filteredData);
        setFilteredItems(filteredData); // Filtrelenmiş veriyi güncelle
      } else {
        NotificationService.openErrorNotification({
          title: "İşlem Başarısız",
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: "topRight",
        });
      }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: "İşlem Başarısız",
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: "topRight",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const value = selectedKeys[0]?.toLowerCase();
    const filteredData = items.filter((item) => {
      const itemValue = item[dataIndex]?.toString().toLowerCase();
      return !item.isDeleted && (value ? itemValue.includes(value) : true);
    });
    setFilteredItems(filteredData);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setFilteredItems(items);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890FF" : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => document.getElementById("search-input").select(), 100);
      }
    },
    sorter: (a, b) => (a[dataIndex] || "").localeCompare(b[dataIndex] || ""),
  });
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      render: (text, record) => (
        <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>
          {text}
        </span>
      ),
      width: 400,
    },
    {
      title: "Firma",
      dataIndex: "companyName",
      key: "companyName",
      ...getColumnSearchProps("companyName"),
      render: (text, record) => (
        <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>
          {text}
        </span>
      ),
      width: 400,
    },
    {
      title: 'Güncelle',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <a type="link" onClick={()=>goEditPage(record)}  style={{
          border: "none",
          backgroundColor: "transparent",
          pointerEvents: record.isDeleted ? "none" : "auto",
          opacity: record.isDeleted ? 0.5 : 1,
        }}><img style={{width:"20px"}} alt="edit" src="/images/newEditButton.png" /></a>
      ),
    },
    {
      title: " ",
      dataIndex: "delete",
      key: "delete",
      render: (text, record) =>
        record.isDeleted ? (
          <Button
            loading={loading}
            style={{ backgroundColor: "#1A446C", color: "white", borderRadius: 0 }}
            onClick={() => handleDelete(record, true)}
          >
            Göster
          </Button>
        ) : (
          <Button
            loading={loading}
            style={{ backgroundColor: "#1A446C", color: "white", borderRadius: 0 }}
            onClick={() => handleDelete(record, false)}
          >
            Sil
          </Button>
        ),
    },
  ];
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        minHeight: "94vh",
        padding: "3%",
      }}
    >
      <Button
        onClick={() => goAddPage()}
        icon={<img alt="plus" src="/images/plusbutton.png" style={{ width: "60%" }} />}
        className="admin-add-button"
      >
        Yeni Ekle
      </Button>
      <Table
        locale={{
          emptyText: "Henüz email eklemediniz...",
          filterReset: "Sıfırla",
          filterTitle: "Filtre Menüsü",
          selectAll: "Hepsini Seç",
          selectInvert: "Tersini Seç",
          selectionAll: "Tümünü Seç",
          sortTitle: "Sıralama",
          triggerDesc: "Azalan sıralama için tıklayın",
          triggerAsc: "Artan sıralama için tıklayın",
          cancelSort: "Sıralamayı iptal etmek için tıklayın",
        }}
        dataSource={filteredItems} 
        columns={columns}
        showHeader={true}
        pagination={false}
        className="custom-news-table"
        rowClassName="custom-news-row"
      />
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <Spin />
        </div>
      )}
    </div>
  );
};
export default MailList;