import React,{useEffect,useState} from "react";
import { Button, Form, Input, Select,Spin} from 'antd';
import NotificationService from "../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import { useNavigate } from 'react-router-dom';

 

const InvoiceCategoryEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const record = location.state && location.state.record;
    const [loading, setLoading] = useState(false);


    useEffect(() => {
      const fetchData = async () => {
  
      };
      fetchData();
    }, []);

   
  
  
    const onFinish = async (values) => {
      setLoading(true);
      const currentDate = new Date();

      const data = {
         firstTransactionDate: record.firstTransactionDateEdit??currentDate,
          firstUserId: record.firstUserId??localStorage.getItem("uid"),
          lastTransactionDate: currentDate,
          lastUserId: localStorage.getItem("uid"),
          isDeleted: record.isDeleted,
          categoryName: values.categoryName,
        
      };
    
      try {
        const result = await FirebaseService.updateInvoiceCategory(record.key, data);
        console.log("result",result)

        const updatedExpenses = await FirebaseService.updateExpenseCategories(record.categoryName, values.categoryName);
        console.log("sonuç",updatedExpenses);

        const resultContent = await FirebaseService.updateInvoiceCategoryContentList(record.categoryName, values.categoryName);
        console.log("resultContent",resultContent)
              
        if (result.success) {
        
            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: result.message,
              placement: 'topRight'
            });

            setLoading(false);
            setTimeout(() => {
              navigate("/invoicecategorylist");
            }, 2000);
            
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });
          setLoading(false);
        }
    
        
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
        setLoading(false);
      }
    };
    
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };


   return ( 
    <div>
    
    <Form
       name="basic"
       labelCol={{
       span: 2,
       }}
       wrapperCol={{
       span: 8,
       }}
       style={{
       maxWidth: "100%",
       padding:"5% 0 0 5%"
       }}
       initialValues={{
        categoryName: record?.categoryName || "",
       }}
       onFinish={onFinish}
       onFinishFailed={onFinishFailed}
       autoComplete="off"
   >

   

       <Form.Item
       label="Kategori Adı"
       name="categoryName"
       rules={[
           {
           required: true,
           message: 'Lütfen kategori adı giriniz!',
           },
       ]}
     
       >
     <Input className="input-style" style={{width:"100%"}}  placeholder="Kategori Adı"/>
       </Form.Item>

     
       <Form.Item
       wrapperCol={{
           offset: 15,
           span: 18,
       }}
       >
       <Button htmlType="submit" loading={loading} style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
       </Form.Item>

   </Form>
   {loading && (
           <div style={{
               position: 'fixed',
               top: 0,
               left: 0,
               width: '100%',
               height: '100%',
               backgroundColor: 'rgba(0, 0, 0, 0.5)',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               zIndex: 1000,
           }}>
               <Spin/>
           </div>
       )}

    </div>
    )

        };
  export default InvoiceCategoryEdit;