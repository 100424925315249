import React,{useEffect,useState} from "react";
import { Button, Checkbox, Select } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { colors } from "../../../environments/environment";

const OperationDetailProductManager = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const jobCode = location.state && location.state.jobCode;
    const [record,setRecord] = useState(location.state && location.state.record)
    const [parts,setParts] = useState(record ? record.flatMap(item => item.parts) : []);

    const jobCodes = ['all', ...new Set(record.map(item => item.jobCode))];
    const [selectedRecord,setSelectedRecord] = useState(location.state && location.state.record)

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            setParts(record.flatMap(item => item.parts));

        };
        

    fetchData();
  }, []);


  const onChange = (value) => {
    setSelectedParts([]);
    setSelectAll(false)
    if (value === 'all') {
      setParts(record.flatMap(item => item.parts));
      setSelectedRecord(record)
    } else {
      const selectedRecord = record.find(item => item.jobCode === value);
      if (selectedRecord) {
        setParts(selectedRecord.parts);
        setSelectedRecord(selectedRecord)
      } else {
        setSelectedRecord(record)
        setParts([]);
      }
    }
  };
     const handlePrint = () => {
    const selectedPartsToPrint = partCounts.filter(partCount => selectedParts.includes(partCount.partNo));

    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
        <html>
            <head>
                <title>Yazdır</title>
                <style>
                    body { font-family: Arial, sans-serif; margin: 0; padding: 20px; }
                    .print-container {
                        ${showImages ? "display: flex; flex-wrap: wrap; justify-content: center; gap: 10px;" : ""}
                    }
                    .setup-item {
                        border: 1px solid ${colors.blue};
                        padding: 10px;
                        width: calc(33.33% - 20px);
                        margin-bottom: 20px;
                        box-sizing: border-box;
                        page-break-inside: avoid;
                    }
                    .setup-item img {
                        width: 100%;
                        height: 150px;
                        object-fit: contain;
                    }
                    .setup-item p {
                        border-bottom: 1px solid ${colors.blue};
                        margin: 5px 0;
                        font-size: 12px;
                        padding: 0;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                        margin-top: 20px;
                    }
                    th, td {
                        border: 1px solid ${colors.blue};
                        padding: 5px;
                        text-align: center;
                    }
                    th {
                        background-color: #f2f2f2;
                    }
                    @media print {
                        @page {
                            margin: 1cm;
                        }
                    }
                </style>
            </head>
            <body>
                ${showImages ? `
                <div class="print-container">
                  ${selectedPartsToPrint.map(partCount => {
                const part = parts.find(p => p.partNo === partCount.partNo);
                const thickness = Array.isArray(selectedRecord) 
                    ? selectedRecord.find(item => item.jobCode === part.jobCode)?.thickness || '' 
                    : selectedRecord?.thickness || '';

                return `
                <div class="setup-item">
                    <img src="${part.imageUrl ? part.imageUrl : '/images/parca_fotografi_bulunamamaktadır.svg'}" 
                        alt="${part.imageFileName}" 
                        onerror="this.onerror=null;this.src='/images/parca_fotografi_görüntülenmemektedir.svg';" />
                    <p><strong>Firma:</strong> ${part.customerName}</p>
                    <p><strong>Proje Kodu:</strong> ${part.projectCode}</p>
                    <p><strong>İş Kodu:</strong> ${part.jobCode}</p>
                    <p><strong>Part No:</strong> ${part.partNo}</p>
                   
                    <div style="display: flex; border-bottom: 1px solid ${colors.blue};">
                        <p><strong>Kalınlık:</strong> ${thickness}</p> 
                        <p style=" margin-left :10px "><strong >Parça Sayısı:</strong> ${partCount.piece}</p>
                    </div>
                    <div style="display: flex; border-bottom: 1px solid ${colors.blue};">
                        <p style="margin-right: 20px; margin-bottom: 0;"><strong>Ebat:</strong> ${part.height} x ${part.width} ${part.unit}</p>
                        <p style="margin-bottom: 0;"><strong>Kalite:</strong> ${part.quality}</p>
                    </div>
                </div>
                `;
            }).join('')}

                </div>
                ` : `
                <h2>Parça Listesi</h2>
               <div style="display: flex; justify-content: space-between;">
                    <p><strong>Firma:</strong> ${selectedRecord.companyName}</p>
                    <p style="text-align: right;"><strong>Tarih:</strong> ${new Date(selectedRecord.deadline.seconds * 1000).toLocaleDateString('tr-TR')}</p>
                </div>
                <p><strong>Proje Kodu:</strong> ${selectedRecord.projectCode}</p>
                <p><strong>İş Kodu:</strong> ${selectedRecord.jobCode}</p>

                <table>
                    <thead>
                        <tr>
                            <th>Sıra No</th>
                            <th>Part Adı</th>
                            <th>Kalınlık</th>
                            <th>Kalite</th>
                            <th>Adet</th>
                            <th>En x Boy</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${selectedPartsToPrint.map((partCount, index) => {
                            const part = parts.find(p => p.partNo === partCount.partNo);
                            const thickness = Array.isArray(selectedRecord) 
                            ? selectedRecord.find(item => item.jobCode === part.jobCode)?.thickness || '' 
                            : selectedRecord?.thickness || '';
                            return `
                            <tr>
                                <td>${index + 1}</td>
                                <td>${part.partNo}</td>
                                <td>${thickness}</td>
                                <td>${part.quality}</td>
                                <td>${partCount.piece}</td>
                                <td>${part.width} x ${part.height} ${part.unit}</td>
                            </tr>
                            `;
                        }).join('')}
                    </tbody>
                </table>
                `}
            </body>
        </html>
    `);

    printWindow.document.close();
    printWindow.onload = () => {
        printWindow.focus();
        setTimeout(() => {
            printWindow.print();
            printWindow.onafterprint = () => printWindow.close();
        }, 250);
    };
    };


    const [partCounts, setPartCounts] = useState(
        parts.map(part => ({
            partNo: part.partNo,
            piece: part.piece || 0, 
        }))
    );

    const handlePieceChange = (partNo, newPieceCount) => {
        setPartCounts(prevState => 
            prevState.map(part => 
                part.partNo === partNo ? { ...part, piece: newPieceCount } : part
            )
        );
    };
    const [showImages, setShowImages] = useState(true);

   
    const handleCheckboxChange = () => {
        setShowImages(prevState => !prevState); 
    };


    const [selectedParts, setSelectedParts] = useState([]);
    const [selectAll, setSelectAll] = useState(false); 

    const handleCheckboxChangeParts = () => {
        if (selectAll) {
            setSelectedParts([]);
        } else {
            setSelectedParts(parts.map(part => part.partNo));
        }
        setSelectAll(!selectAll); 
    };

    const handleIndividualCheckboxChange = (partNo) => {
        setSelectedParts((prevSelected) => {
            if (prevSelected.includes(partNo)) {
                return prevSelected.filter((item) => item !== partNo);
            } else {
                return [...prevSelected, partNo];
            }
        });
    };
   

    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px", alignItems:"center" }}>
            <Select
            showSearch
            onChange={onChange}
            placeholder="İş Kodu Seçiniz"
            suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
            options={jobCodes.map(jobCode => ({ label: jobCode === 'all' ? 'Tümü' : jobCode, value: jobCode }))}
            className="input-style"
            filterOption={(input, option) => 
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            style={{width:"200px"}}
          />

                <label style={{ marginLeft: "10px", fontWeight: "500" }}>
                <Checkbox
                  
                    checked={selectAll}
                    onChange={handleCheckboxChangeParts}
                />
                Tümünü Seç
                </label>
                 <label style={{ marginLeft: "10px", fontWeight: "500" }}>
                    <Checkbox 
                      
                        checked={showImages} 
                        onChange={handleCheckboxChange} 
                    />
                    Parça Resimli
                </label>
                <Button
                    style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500",marginLeft:"10px" }}
                    onClick={handlePrint}
                >
                    Yazdır
                </Button>
              
               
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', justifyContent: 'center' }}>
        {showImages ?
        (
            
            parts.map((part, index) => (
                <div
                    className="setup-item"
                    key={index}
                    style={{ 
                        border: "1px solid", 
                        borderColor: colors.blue, 
                        padding: "10px", 
                        width: 'calc(33.33% - 20px)', 
                        marginBottom: '20px'
                    }}
                >
                    <div>
                        <Checkbox
                            checked={selectedParts.includes(part.partNo) || selectAll}
                            onChange={() => handleIndividualCheckboxChange(part.partNo)}
                        />
                    </div>
                    
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img
                            src={part.imageUrl ? part.imageUrl : '/images/parca_fotografi_bulunamamaktadır.svg'}
                            alt={part.imageFileName}
                            style={{ width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'contain' }}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '/images/parca_fotografi_görüntülenmemektedir.svg';
                            }}
                        />
                        <div style={{ width: '100%' }}>
                            <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>Firma:</strong> {part.customerName}</p>
                            <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>Proje Kodu:</strong> {part.projectCode}</p>
                            <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>İş Kodu:</strong> {part.jobCode}</p>
                            <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>Part No:</strong> {part.partNo}</p>
                            <div style={{ display: "flex", borderBottom: "1px solid", borderColor: colors.blue, alignItems:"center" }}>
                            <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}>
                            <strong>Kalınlık:</strong> {
                                Array.isArray(selectedRecord) 
                                ? selectedRecord.find(item => item.jobCode === part.jobCode)?.thickness || '' 
                                : selectedRecord.thickness || ''
                            }
                            </p>
                          
                            <p style={{ borderBottom: "1px solid", borderColor: colors.blue, marginLeft:"10px" , marginBottom:"10px"}}>
                                <strong>Parça Sayısı:</strong> 
                                <input
                                    type="number"
                                    value={partCounts.find(item => item.partNo === part.partNo)?.piece || 0}
                                    onChange={(e) => handlePieceChange(part.partNo, e.target.value)}
                                    style={{ width: "50px", padding: "5px", marginTop: "5px" }}
                                />
                            </p> 
                            </div>                                   
                            <div style={{ display: "flex", borderBottom: "1px solid", borderColor: colors.blue }}>
                                <p style={{ marginRight: "20px" }}><strong>Ebat:</strong> {part.height} x {part.width} {part.unit}</p>
                                <p><strong>Kalite:</strong> {part.quality}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))
    
        
        ) : (
            <div style={{width:"90%"}}>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div style={{textAlign:"left"}}>
                        <p><strong>Firma:</strong> {selectedRecord.companyName}</p>
                        <p><strong>Proje Kodu:</strong> {selectedRecord.projectCode}</p>
                        <p><strong>İş Kodu:</strong> {selectedRecord.jobCode}</p>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <p>
                            <strong>Tarih:</strong> 
                            {
                                Array.isArray(selectedRecord) 
                                    ? new Date(selectedRecord[0].deadline.seconds * 1000).toLocaleDateString('tr-TR') 
                                    : new Date(selectedRecord.deadline.seconds * 1000).toLocaleDateString('tr-TR')
                            }
                        </p>
                    </div>

                </div>
                <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid', borderColor: colors.blue }}>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid', borderColor: colors.blue, padding: '5px' }}></th>
                            <th style={{ border: '1px solid', borderColor: colors.blue, padding: '5px' }}>Sıra No</th>
                            <th style={{ border: '1px solid', borderColor: colors.blue, padding: '5px' }}>Part Adı</th>
                            <th style={{ border: '1px solid', borderColor: colors.blue, padding: '5px' }}>Kalınlık</th>
                            <th style={{ border: '1px solid', borderColor: colors.blue, padding: '5px' }}>Kalite</th>
                            <th style={{ border: '1px solid', borderColor: colors.blue, padding: '5px' }}>Adet</th>
                            <th style={{ border: '1px solid', borderColor: colors.blue, padding: '5px' }}>En x Boy</th>
                        </tr>
                    </thead>
                    <tbody>
                    {parts.map((part, index) => (
                        <tr key={index}>
                            <td style={{ border: '1px solid', borderColor: colors.blue, padding: '5px' }}>
                            <Checkbox
                            checked={selectedParts.includes(part.partNo) || selectAll}
                            onChange={() => handleIndividualCheckboxChange(part.partNo)}
                        />
                            </td>
                            <td style={{ border: '1px solid', borderColor: colors.blue, padding: '5px' }}>{index + 1}</td>
                            <td style={{ border: '1px solid', borderColor: colors.blue, padding: '5px' }}>{part.partNo}</td>
                            <td style={{ border: '1px solid', borderColor: colors.blue, padding: '5px' }}>
                                {
                                    Array.isArray(selectedRecord) 
                                        ? selectedRecord.find(item => item.jobCode === part.jobCode)?.thickness || '' 
                                        : selectedRecord.thickness
                                }
                            </td>
                            <td style={{ border: '1px solid', borderColor: colors.blue, padding: '5px' }}>{part.quality}</td>
                            <td style={{ border: '1px solid', borderColor: colors.blue, padding: '5px' }}>{partCounts.find(item => item.partNo === part.partNo)?.piece || 0}</td>
                            <td style={{ border: '1px solid', borderColor: colors.blue, padding: '5px' }}>{part.width} x {part.height}</td>
                        </tr>
                    ))}
                </tbody>
                </table>
            </div>
        )}
                </div>


            </div>
        </>
    );
};

export default OperationDetailProductManager;
