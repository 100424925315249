import React,{useEffect,useState} from "react";
import { Button, Table, Input,Select,Space, List,Modal,Checkbox, Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections, colors,openOrderType, placeholderMap}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import { useLocation  } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import PurchaseRequestAdd from "../purchaseRequests/purchaseRequestAdd";


const OpenOrderAddContinue = () => {
    const navigate = useNavigate();
   
    const location = useLocation();
    const record = location.state && location.state.record;
    const [checkedItemsStock, setCheckedItemsStock] = useState([]);
    const [checkedItemsWastage, setCheckedItemsWastage] = useState([]);

    const [checkedItemsStockExcess, setCheckedItemsStockExcess] = useState([]);
    const [checkedItemsStockExcessOnlyOne, setCheckedItemsStockExcessOnlyOne] = useState([]);

    const [inputValuesStock, setInputValuesStock] = useState({});
    const [inputValuesWastage, setInputValuesWastage] = useState({});


    const [companies, setCompanies] = useState([]); 
    const [itemsStock, setItemsStock] = useState([]);

    const [itemsWastage, setItemsWastage] = useState([]);

    const [filteredItemsStock, setFilteredItemsStock] = useState([]);
    const [filteredItemsWastage, setFilteredItemsWastage] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recordForModal, setRecordForModal] = useState(null);

    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [excessValues, setExcessValues] = useState([]); 
  
    const [loading, setLoading] = useState(false);

    const [isWarehouseSelectedAll, setIsWarehouseSelectedAll] = useState(false);

    const [filtersStock, setFiltersStock] = useState({
      thickness: '',
      quality: '',
      width: '',
      height: '',
      surface: '',
      piece: '',
      reservedPiece: '',
      unitkg: '',
      totalkg: '',
    });

      const [filtersWastage, setFiltersWastage] = useState({
        thickness: '',
        quality: '',
        width: '',
        height: '',
        surface: '',
        piece: '',
        reservedPiece: '',
        unitkg: '',
        totalkg: '',
    });

    const [isReserved, setIsReserved] = useState(false);
    const [savedLocalSetupName, setSavedLocalSetupName] = useState(null);
    const [lastItem, setLastItem] = useState(false);


 
    
    const handleModalCancel = () => {
      setIsModalVisible(false);
    };
  
    const handleSetupFinish = () => {
      setIsModalVisible(false); 
    };

    const fetchData = async () => {  

      const localSetupName = localStorage.getItem("setupName") ? localStorage.getItem("setupName")  : record.setupName;
      setSavedLocalSetupName(localSetupName)
    
    
    
      const unsubscribeStock = FirebaseService.listenToStocksData(snapshot => {

        const savedDepotName = localStorage.getItem("depotName");
        const savedFiltersStock = localStorage.getItem("stockFilters");
        const parsedFiltersStock = savedFiltersStock ? JSON.parse(savedFiltersStock) : {};
    
        const data = snapshot.docs.map(doc => ({ id: doc.id, key: doc.id, ...doc.data() }));
        const warehouseNameToUse = record?.warehouseName ?? (savedDepotName || selectedWarehouse);
    
        let filteredDataStock;
        if (warehouseNameToUse === null || warehouseNameToUse === undefined) {
          filteredDataStock = data.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            const hasWaitingPiece = item.waitingPiece !== null && item.waitingPiece !== undefined && item.waitingPiece !== 0;
            return (
              !item.isDeleted &&
              (item.piece >= 0 && item.reservedPiece >= 0) && 
              (
                (item.piece === 0 && (hasReservedPiece || hasWaitingPiece)) ||
                (item.piece !== 0)
              )
            );
          });
        } else if (warehouseNameToUse === "all") {
          filteredDataStock = data.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            return (
              !item.isDeleted &&
              (item.piece >= 0 && item.reservedPiece >= 0) && 
              (
                (item.piece === 0 && hasReservedPiece) ||
                (item.piece !== 0)
              )
            );
          });
        } else {
          filteredDataStock = data.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            return (
              !item.isDeleted &&
              item.depotName === warehouseNameToUse &&
              (item.piece >= 0 && item.reservedPiece >= 0) && 
              (
                (item.piece === 0 && hasReservedPiece) ||
                !(item.piece === 0 && item.waitingPiece === 0) // Eğer adet ve gelecek adet ikisi de 0 ise gösterme
              )
            );
          });
        }
    
        const finalFilteredDataStock = filteredDataStock.filter(item => {
          return Object.keys(parsedFiltersStock).every(key => {
            const filterValue = parsedFiltersStock[key]?.toLowerCase();
            const itemValue = item[key] !== undefined ? item[key]?.toString().toLowerCase() : '';
            return filterValue ? itemValue === filterValue : true;
          });
        });
        setItemsStock(filteredDataStock);
        setFilteredItemsStock(finalFilteredDataStock);
      });
    
      const unsubscribeWastage = FirebaseService.listenToWastagesData(snapshot => {

      const savedDepotName = localStorage.getItem("depotName");
      const savedFiltersWastage = localStorage.getItem("wastageFilters");
      const parsedFiltersWastage = savedFiltersWastage ? JSON.parse(savedFiltersWastage) : {};
      const dataWastage = snapshot.docs.map(doc => ({ id: doc.id,key: doc.id, ...doc.data() }));

      const warehouseNameToUse = record?.warehouseName ?? (savedDepotName || selectedWarehouse);
  
    
        let filteredDataWastage;
        if (warehouseNameToUse === null || warehouseNameToUse === undefined) {
          filteredDataWastage = dataWastage.filter(item => {
            const isAllZero = 
            (item.piece === 0 || item.piece === null || item.piece === undefined) &&
            (item.reservedPiece === 0 || item.reservedPiece === null || item.reservedPiece === undefined) &&
            (item.comingPiece === 0 || item.comingPiece === null || item.comingPiece === undefined) &&
            (item.reservedComingPiece === 0 || item.reservedComingPiece === null || item.reservedComingPiece === undefined);
    
            return (
              !item.isDeleted &&
              !isAllZero && 
              (
                  (item.piece === 0 && (item.reservedPiece !== 0 || item.comingPiece !== 0  || item.reservedComingPiece !== 0)) || 
                  (item.piece !== 0) 
              )
            );
          });
        } else if (warehouseNameToUse === "all") {
          filteredDataWastage = dataWastage.filter(item => {
            const isAllZero = 
            (item.piece === 0 || item.piece === null || item.piece === undefined) &&
            (item.reservedPiece === 0 || item.reservedPiece === null || item.reservedPiece === undefined) &&
            (item.comingPiece === 0 || item.comingPiece === null || item.comingPiece === undefined) &&
            (item.reservedComingPiece === 0 || item.reservedComingPiece === null || item.reservedComingPiece === undefined);
    
            return (
              !item.isDeleted &&
              !isAllZero && 
              (
                  (item.piece === 0 && (item.reservedPiece !== 0 || item.comingPiece !== 0 || item.reservedComingPiece !== 0)) || 
                  (item.piece !== 0) 
              )
            );
          });
        } else {
          filteredDataWastage = dataWastage.filter(item => {
            const isAllZero = 
            (item.piece === 0 || item.piece === null || item.piece === undefined) &&
            (item.reservedPiece === 0 || item.reservedPiece === null || item.reservedPiece === undefined) &&
            (item.comingPiece === 0 || item.comingPiece === null || item.comingPiece === undefined) &&
            (item.reservedComingPiece === 0 || item.reservedComingPiece === null || item.reservedComingPiece === undefined);
    
            return (
              !item.isDeleted &&
              item.depotName === warehouseNameToUse &&
              !isAllZero && 
              (item.piece >= 0 && item.reservedPiece >= 0) && // piece ve reservedPiece 0'dan küçük değilse
               (
                      (item.piece === 0 && (item.reservedPiece !== 0 || item.comingPiece !== 0 || item.reservedComingPiece !== 0)) ||
                      !(item.piece === 0 && item.comingPiece === 0) // Eğer adet ve gelecek adet ikisi de 0 ise gösterme
               )
            );
          });
        }
    
        const updatedDataWastage = filteredDataWastage.map(item => ({
          ...item,
          isQRScanned: item.isQRScanned === undefined ? true : item.isQRScanned
        }));
    
        const finalFilteredDataWastage = updatedDataWastage.filter(item => {
          return Object.keys(parsedFiltersWastage).every(key => {
            const filterValue = parsedFiltersWastage[key]?.toLowerCase();
            const itemValue = item[key] !== undefined ? item[key]?.toString().toLowerCase() : '';
            return filterValue ? itemValue === filterValue : true;
          });
        });
    
        setItemsWastage(updatedDataWastage);
        setFilteredItemsWastage(finalFilteredDataWastage);
      });
    
      const companies = await FirebaseService.getCompaniesData();
      const filteredCompanies = companies.filter(item => !item.isDeleted);
      setCompanies(filteredCompanies);
    
      return () => {
        unsubscribeStock();
        unsubscribeWastage();
      };
    };
   
    
    useEffect(() => {
      window.scrollTo(0, 0);
     
      const existingStockItems = Array.isArray(record.stock) ? record.stock : JSON.parse(record.stock || '[]');
      localStorage.setItem("checkedItemsStock", JSON.stringify(existingStockItems));
    
      const existingWastageItems = Array.isArray(record.wastage) ? record.wastage : JSON.parse(record.wastage || '[]');
      localStorage.setItem("checkedItemsWastage", JSON.stringify(existingWastageItems));
    
      const unsubscribe = fetchData(); 

      return () => {
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }
      };
    }, []);
    
    
    const warehouseOptions = [{ label: 'Tümü', value: "all" }, ...companies.map(item => ({ label: item.companyName, value: item.companyName }))];
    
    function generateRandomCode(length) {
      const characters = '0123456789';
      let result = '';
    
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
    
      return result;
  }
   
    const handleWastageReserve = async (itemKey,item) => {
      setLoading(true);

      if(inputValuesWastage[itemKey] === undefined){
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Hatalı giriş: Sayısal bir değer girmelisiniz.",
          placement: 'topRight',
      });
      setLoading(false);
      return;
      }

     
      if(Number(item.comingPiece) + Number(item.piece) < inputValuesWastage[itemKey] || inputValuesWastage[itemKey]  === 0){
        NotificationService.openErrorNotification({
          title: "İşlem Başarısız",
          description: "Yeterli sayıda mevcut değildir.",
          placement: "topRight",
        });
        setLoading(false);
        return;
      }
    
      let combinedJobCode = `${record.jobCode.substring(0,5)}-${item.thickness}${item.quality}`;
     
      const result = await FirebaseService.updateWastageReservedPiece(itemKey, inputValuesWastage[itemKey] || 0);
      if (result.success) {
        console.log("Rezerve edildi");
      } else {
        console.log("Rezerve edilmedi");
        NotificationService.openErrorNotification({
          title: "İşlem Başarısız",
          description: "Yeterli miktarda bulunmamaktadır.",
          placement: "topRight",
        });
        setLoading(false);
        return;
      }
    
      const newItem = {
        id: itemKey,
        oldPiece: item.piece,
        quality: item.quality,
        height: item.height,
        width: item.width,
        thickness: item.thickness,
        surface: item.surface,
        isQrScan: false,
        piece: inputValuesWastage[itemKey] || 0,
        code: item.code ? item.code : null  ,
        depotName: item.depotName ? item.depotName : null,
        isReserved: false,
      };
    
    
      const openOrderData = await FirebaseService.getOpenOrderData();

      const baseCombinedJobCode = getBaseJobCode(combinedJobCode);

      const filteredOpenOrder = openOrderData.filter(item => {
        const itemBaseJobCode = getBaseJobCode(item.jobCode);
        return itemBaseJobCode === baseCombinedJobCode && !item.isDeleted;; 
      });
      console.log("filteredOpenOrder bakkkk",filteredOpenOrder)

      try {
        if (filteredOpenOrder.length > 0 && filteredOpenOrder.every(item => item.isSetup)) {
          console.log("burdaaaa")
          const currentDate = new Date();

          const matchingJobCodes = openOrderData
            .filter(order => order.jobCode.startsWith(record.jobCode.substring(0, 5)))
            .map(order => order.jobCode);
        
          let maxSuffix = 2; 
          matchingJobCodes.forEach(code => {
            const match = code.match(/-(\d+)$/); 
            if (match) {
              const suffix = parseInt(match[1], 10); 
              if (suffix >= maxSuffix) {
                maxSuffix = suffix + 1; 
              }
            }
          });
        
          combinedJobCode = `${record.jobCode.substring(0, 5)}-${item.thickness}${item.quality}_${maxSuffix}`;
        console.log("combinedJobCode",combinedJobCode)
          const newOrder = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            programmerUserId: localStorage.getItem("uid"),
            isDeleted: false,
            programmerName: `${localStorage.getItem("name")} ${localStorage.getItem("surname")}`,
            companyName: record.companyName,
            projectCode: record.projectCode,
            orderType: "order",
            isSetup: false,
            isOffer: false,
            isWaiting: true,
            isOfferApproved: false,
            noOfferApprovedDescription: null,
            noQrScanDescription: null,
            isOfferSetupUpdate: false,
            isOfferChange: false,
            isOfferDenied: false,
            isChangedProgrammer: false,
            isShipment: false,
            shipmentPiece: 1,
            invoiceNumber: null,
            setupName: null,
            deadline: new Date(record.deadline.seconds * 1000),
            jobCode: combinedJobCode,
            stock: [],
            wastage: [newItem],
            isOfferable:false,
            isAbkant:false

          };
        
          const createResult = await FirebaseService.addRecording(parsCollections.openOrder, newOrder);
        
          if (createResult.success) {
            NotificationService.openSuccessNotification({
              title: "İşlem Başarılı",
              description: "Yeni açık sipariş başarıyla oluşturuldu. Başarıyla rezerve edildi.",
              placement: "topRight",
            });
        
        
            setLoading(false);
          } else {
            NotificationService.openErrorNotification({
              title: "İşlem Başarısız",
              description: "Yeni açık sipariş oluşturulamadı. Lütfen daha sonra tekrar deneyiniz.",
              placement: "topRight",
            });
            setLoading(false);
          }
        } 
        
        else if (filteredOpenOrder.length > 0 && filteredOpenOrder.some(item => !item.isSetup)) {
        const openOrderToUpdate = filteredOpenOrder.find(item => !item.isSetup);

        
          console.log("yok burdaaaaa openOrderToUpdate, update etcek",openOrderToUpdate)
          const updateOpenOrderItem = {
            wastage: [newItem], 
          };
    
          const updateResult = await FirebaseService.updateOpenOrderStockAndWastage(
            openOrderToUpdate.key,
            updateOpenOrderItem
          );
    
          if (updateResult.success) {
            NotificationService.openSuccessNotification({
              title: "İşlem Başarılı",
              description: "Başarıyla rezerve edilmiştir.",
              placement: "topRight",
            });
            setLoading(false);

          } else {
            NotificationService.openErrorNotification({
              title: "İşlem Başarısız",
              description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
              placement: "topRight",
            });
            setLoading(false);

          }

        
        }
        
        else {
          const currentDate =new Date()
          const newOrder = {
            firstTransactionDate: currentDate,
              firstUserId: localStorage.getItem("uid"),
              programmerUserId:localStorage.getItem("uid"),
              isDeleted: false,
              programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
              companyName: record.companyName,
              projectCode: record.projectCode,
              orderType :"order",         
              isSetup:false,
              isOffer:false,
              isWaiting: true,
              isOfferApproved:false,
              noOfferApprovedDescription:null,
              noQrScanDescription:null,
              isOfferSetupUpdate:false,
              isOfferChange:false,
              isOfferDenied:false,
              isChangedProgrammer:false,
              isShipment: false,
              shipmentPiece: 1,
              invoiceNumber:null,
              setupName: null,
              deadline: new Date(record.deadline.seconds * 1000),
              jobCode: combinedJobCode,
              stock: [],
              wastage: [newItem],
              isOfferable:false,
              isAbkant:false

          };
    
          const createResult = await FirebaseService.addRecording(parsCollections.openOrder,newOrder);
    
          if (createResult.success) {
            NotificationService.openSuccessNotification({
              title: "İşlem Başarılı",
              description: "Yeni açık sipariş başarıyla oluşturuldu. Başarıyla rezerve edildi.",
              placement: "topRight",
            });

            const filteredOpenOrderDelete = openOrderData.find(item => 
              item.jobCode && item.jobCode === combinedJobCode.substring(0, 5)
            );
  
            const resultDelete = await FirebaseService.deleteOpenOrderData(filteredOpenOrderDelete.key);
            setLoading(false);


          } else {
            NotificationService.openErrorNotification({
              title: "İşlem Başarısız",
              description: "Yeni açık sipariş oluşturulamadı. Lütfen daha sonra tekrar deneyiniz.",
              placement: "topRight",
            });
            setLoading(false);

          }
        }
      } catch (error) {
        /*
        NotificationService.openErrorNotification({
          title: "İşlem Başarısız",
          description: "Bilinmeyen bir hata ile karşılaşıldı.6",
          placement: "topRight",
        });*/
        setLoading(false);

      }
          
       
    };
        

    const getBaseJobCode = (jobCode) => {
      const secondDashIndex = jobCode.indexOf('_', jobCode.indexOf('_') + 1);
      return secondDashIndex !== -1 ? jobCode.substring(0, secondDashIndex) : jobCode;
    };


    const handleStockReserve = async (itemKey,item) => {
      setLoading(true);
      if(inputValuesStock[itemKey] === undefined){
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Hatalı giriş: Sayısal bir değer girmelisiniz.",
          placement: 'topRight',
      });
      setLoading(false);
      return;
      }

      console.log("item",item)

      let combinedJobCode = `${record.jobCode.substring(0,5)}-${item.thickness}${item.quality}`;
      const excessValuesKeys = Object.keys(excessValues);
      const excessValuesCount = excessValuesKeys.length;
      console.log("excessValuesKeys",excessValuesKeys)
     
      console.log("excessValuesCount",excessValuesCount)
    if (excessValuesCount > 0 ) {
     
      const foundItem = checkedItemsStockExcess.find((el) => el.id === item.key) || null;
      console.log("checkedItemsStockExcess",checkedItemsStockExcess)
      setCheckedItemsStockExcessOnlyOne([foundItem])

      console.log("foundItem",foundItem)
      
      setLastItem(foundItem);

      setVisibleContinue(true);
       setLoading(false);

   }
      
     if(inputValuesStock[itemKey] !== 0){
      const result = await FirebaseService.updateStockReservedPiece(itemKey, inputValuesStock[itemKey] || 0);
      if (result.success) {
        console.log("Rezerve edildi");
      } else {
        console.log("Rezerve edilmedi");
        NotificationService.openErrorNotification({
          title: "İşlem Başarısız",
          description: "Yeterli miktarda bulunmamaktadır.",
          placement: "topRight",
        });
        setVisibleContinue(false);
        setLoading(false);
        return;
      }
    
      const newItem = {
        id: itemKey,
        oldPiece: item.piece,
        quality: item.quality,
        height: item.height,
        width: item.width,
        thickness: item.thickness,
        surface: item.surface,
        isQrScan: false,
        piece: inputValuesStock[itemKey] || 0,
        code: item.code,
        depotName: item.depotName ? item.depotName : null,
        isReserved: false,
      };
    
    
      const openOrderData = await FirebaseService.getOpenOrderData();

      const baseCombinedJobCode = getBaseJobCode(combinedJobCode);

      const filteredOpenOrder = openOrderData.filter(item => {
        const itemBaseJobCode = getBaseJobCode(item.jobCode);
        return itemBaseJobCode === baseCombinedJobCode && !item.isDeleted;;
      });

      console.log("filteredOpenOrder combinedJobCode",combinedJobCode,filteredOpenOrder)
    
      try {
        if (filteredOpenOrder.length > 0 && filteredOpenOrder.every(item => item.isSetup)) {
          const currentDate = new Date();

          const matchingJobCodes = openOrderData
            .filter(order => order.jobCode.startsWith(record.jobCode.substring(0, 5)))
            .map(order => order.jobCode);
        
          let maxSuffix = 2; 
          matchingJobCodes.forEach(code => {
            const match = code.match(/-(\d+)$/); 
            if (match) {
              const suffix = parseInt(match[1], 10); 
              if (suffix >= maxSuffix) {
                maxSuffix = suffix + 1; 
              }
            }
          });
        
          combinedJobCode = `${record.jobCode.substring(0, 5)}-${item.thickness}${item.quality}_${maxSuffix}`;
        
          console.log("combinedJobCode",combinedJobCode)
          const newOrder = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            programmerUserId: localStorage.getItem("uid"),
            isDeleted: false,
            programmerName: `${localStorage.getItem("name")} ${localStorage.getItem("surname")}`,
            companyName: record.companyName,
            projectCode: record.projectCode,
            orderType: "order",
            isSetup: false,
            isOffer: false,
            isWaiting: true,
            isOfferApproved: false,
            noOfferApprovedDescription: null,
            noQrScanDescription: null,
            isOfferSetupUpdate: false,
            isOfferChange: false,
            isOfferDenied: false,
            isChangedProgrammer: false,
            isShipment: false,
            shipmentPiece: 1,
            invoiceNumber: null,
            setupName: null,
            deadline: new Date(record.deadline.seconds * 1000),
            jobCode: combinedJobCode,
            stock: [newItem],
            wastage: [],
            isOfferable:false,
            isAbkant:false

          };
        
          const createResult = await FirebaseService.addRecording(parsCollections.openOrder, newOrder);
        
          if (createResult.success) {
            NotificationService.openSuccessNotification({
              title: "İşlem Başarılı",
              description: "Yeni açık sipariş başarıyla oluşturuldu. Başarıyla rezerve edildi.",
              placement: "topRight",
            });
        
        
            setLoading(false);
          } else {
            NotificationService.openErrorNotification({
              title: "İşlem Başarısız",
              description: "Yeni açık sipariş oluşturulamadı. Lütfen daha sonra tekrar deneyiniz.",
              placement: "topRight",
            });
            setLoading(false);
          }
     
        } 
        else if (filteredOpenOrder.length > 0 && filteredOpenOrder.some(item => !item.isSetup)) {
       
          const openOrderToUpdate = filteredOpenOrder.find(item => !item.isSetup);

          const updateOpenOrderItem = {
            stock: [newItem], 
          };
    
          
          const updateResult = await FirebaseService.updateOpenOrderStockAndWastage(
            openOrderToUpdate.key,
            updateOpenOrderItem
          );
          console.log("yok sadece 1 tane",openOrderToUpdate.key,openOrderToUpdate)

    
          if (updateResult.success) {
            NotificationService.openSuccessNotification({
              title: "İşlem Başarılı",
              description: "Başarıyla rezerve edilmiştir.",
              placement: "topRight",
            });
            setLoading(false);

          } else {
            NotificationService.openErrorNotification({
              title: "İşlem Başarısız",
              description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
              placement: "topRight",
            });
            setLoading(false);

          }

    
        }
        
        else{
          const currentDate =new Date()
          const newOrder = {
            firstTransactionDate: currentDate,
              firstUserId: localStorage.getItem("uid"),
              programmerUserId:localStorage.getItem("uid"),
              isDeleted: false,
              programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
              companyName: record.companyName,
              projectCode: record.projectCode,
              orderType :"order",         
              isSetup:false,
              isOffer:false,
              isWaiting: true,
              isOfferApproved:false,
              noOfferApprovedDescription:null,
              noQrScanDescription:null,
              isOfferSetupUpdate:false,
              isOfferChange:false,
              isOfferDenied:false,
              isChangedProgrammer:false,
              isShipment: false,
              shipmentPiece: 1,
              invoiceNumber:null,
              setupName: null,
              deadline: new Date(record.deadline.seconds * 1000),
              jobCode: combinedJobCode,
              stock: [newItem],
              wastage: [],
              isOfferable:false,
              isAbkant:false

          };
    
          const createResult = await FirebaseService.addRecording(parsCollections.openOrder,newOrder);
    
          if (createResult.success) {
            NotificationService.openSuccessNotification({
              title: "İşlem Başarılı",
              description: "Yeni açık sipariş başarıyla oluşturuldu. Başarıyla rezerve edildi.",
              placement: "topRight",
            });

            const filteredOpenOrderDelete = openOrderData.find(item => 
              item.jobCode && item.jobCode === combinedJobCode.substring(0, 5)
            );
  
            if(filteredOpenOrderDelete){
              const resultDelete = await FirebaseService.deleteOpenOrderData(filteredOpenOrderDelete.key);
            }
            setLoading(false);


          } else {
            NotificationService.openErrorNotification({
              title: "İşlem Başarısız",
              description: "Yeni açık sipariş oluşturulamadı. Lütfen daha sonra tekrar deneyiniz.",
              placement: "topRight",
            });
            setLoading(false);

          }
        }
      } catch (error) {
        NotificationService.openErrorNotification({
          title: "İşlem Başarısız",
          description: "Bilinmeyen bir hata ile karşılaşıldı.1",
          placement: "topRight",
        });
        setLoading(false);

      }
     }
  
      
    };
    
    const onChangeFilterWarehouse = async(value) => {
    setSelectedWarehouse(value)
    localStorage.setItem("depotName", value);
    const savedDepotName = localStorage.getItem("depotName")
    setIsWarehouseSelectedAll(value === "all")
    try {
      const warehouseNameToUse = savedDepotName || value;
      if (warehouseNameToUse) {
        const data = await FirebaseService.getStocksData();
        const dataWastage = await FirebaseService.getWastagesData();
  
        if (warehouseNameToUse === "all") {
          const filteredDataStock = data.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            const hasWaitingPiece = item.waitingPiece !== null && item.waitingPiece !== undefined && item.waitingPiece !== 0;
      
            return (
              !item.isDeleted &&
              (item.piece >= 0 && item.reservedPiece >= 0) && 
              (
                (item.piece === 0 && (hasReservedPiece || hasWaitingPiece)) || 
                !(item.piece === 0 && item.waitingPiece === 0) // Eğer adet ve gelecek adet ikisi de 0 ise gösterme
              )
            );
          });

          setItemsStock(filteredDataStock);
          setFilteredItemsStock(filteredDataStock);
          
          const filteredDataWastage = dataWastage.filter(item => {
            const isAllZero = 
            (item.piece === 0 || item.piece === null || item.piece === undefined) &&
            (item.reservedPiece === 0 || item.reservedPiece === null || item.reservedPiece === undefined) &&
            (item.comingPiece === 0 || item.comingPiece === null || item.comingPiece === undefined) &&
            (item.reservedComingPiece === 0 || item.reservedComingPiece === null || item.reservedComingPiece === undefined);
    
              return (
                  !item.isDeleted &&
                  !isAllZero && 
                  (item.piece >= 0 && item.reservedPiece >= 0) && // piece ve reservedPiece 0'dan küçük değilse

                  (
                      (item.piece === 0 && (item.reservedPiece !== 0 || item.comingPiece !== 0 || item.reservedComingPiece !== 0)) ||
                      !(item.piece === 0 && item.comingPiece === 0) // Eğer adet ve gelecek adet ikisi de 0 ise gösterme
                  )
              );
        });
        
          const updatedDataWastage = filteredDataWastage.map(item => ({
            ...item,
            isQRScanned: item.isQRScanned === undefined ? true : item.isQRScanned
          }));
        
          setItemsWastage(updatedDataWastage);
          setFilteredItemsWastage(updatedDataWastage);
        } else {

          const filteredDataStock = data.filter(item => {
            const hasReservedPiece = item.reservedPiece !== null && item.reservedPiece !== undefined && item.reservedPiece !== 0;
            const hasWaitingPiece = item.waitingPiece !== null && item.waitingPiece !== undefined && item.waitingPiece !== 0;
            const supplier = item.depotName?.trim().toLowerCase(); 
            const searchValue = warehouseNameToUse.trim().toLowerCase(); 
            return (
              !item.isDeleted &&
               supplier === searchValue &&
               (item.piece >= 0 && item.reservedPiece >= 0) && 
              (
                (item.piece === 0 && (hasReservedPiece || hasWaitingPiece)) || 
                !(item.piece === 0 && item.waitingPiece === 0) // Eğer adet ve gelecek adet ikisi de 0 ise gösterme
              )
            );
          });
  
          setItemsStock(filteredDataStock);
          setFilteredItemsStock(filteredDataStock);
          
        
          const filteredDataWastage = dataWastage.filter(item => {
          
            const isAllZero = 
            (item.piece === 0 || item.piece === null || item.piece === undefined) &&
            (item.reservedPiece === 0 || item.reservedPiece === null || item.reservedPiece === undefined) &&
            (item.comingPiece === 0 || item.comingPiece === null || item.comingPiece === undefined) &&
            (item.reservedComingPiece === 0 || item.reservedComingPiece === null || item.reservedComingPiece === undefined);
    
            const supplier = item.depotName?.trim().toLowerCase(); 
            const searchValue = warehouseNameToUse.trim().toLowerCase(); 
            return (
              !item.isDeleted && 
               supplier === searchValue &&
               !isAllZero && 
               (item.piece >= 0 && item.reservedPiece >= 0) && // piece ve reservedPiece 0'dan küçük değilse

               (
                   (item.piece === 0 && (item.reservedPiece !== 0 || item.comingPiece !== 0 || item.reservedComingPiece !== 0)) || 
                   !(item.piece === 0 && item.comingPiece === 0) // Eğer adet ve gelecek adet ikisi de 0 ise gösterme

               )
            );
          });

          const updatedDataWastage = filteredDataWastage.map(item => ({
            ...item,
            isQRScanned: item.isQRScanned === undefined ? true : item.isQRScanned
          }));

          setItemsWastage(updatedDataWastage);
          setFilteredItemsWastage(updatedDataWastage);
        }
      } else {
       // console.log('Lütfen bir depo seçiniz.');
      }
    } catch (error) {
      console.error('Hata oluştu:', error);
    }
    };

    const handleInputChange = (item, value) => {
      const record = itemsStock.find((rec) => rec.key === item); 
      if (!record) return;
  
      const maxLength = Number(record.piece); 
      const numericValue = Number(value); 
  
      if (isNaN(numericValue)) {
          NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Hatalı giriş: Sayısal bir değer girmelisiniz.",
              placement: 'topRight',
          });
          return;
      }
  
      if (numericValue <= maxLength) {
          setInputValuesStock((values) => {
              const newValues = { ...values, [item]: numericValue };
              const updatedCheckedItems = checkedItemsStock.map((x) =>
                  x.id === item ? { ...x, piece: numericValue } : x
              );
              setCheckedItemsStock(updatedCheckedItems);
              return newValues;
          });
  
          setCheckedItemsStockExcess((items) => items.filter((entry) => entry.id !== item));
      } else {
          const excessValue = numericValue - maxLength;
  
          setExcessValues((prevValues) => ({ ...prevValues, [item]: excessValue }));
          setInputValuesStock((values) => ({ ...values, [item]: maxLength }));
  
          setCheckedItemsStock((items) => {
              const existingItem = items.find((entry) => entry.id === item);
              if (existingItem) {
                  return items.map((entry) =>
                      entry.id === item ? { ...entry, piece: maxLength } : entry
                  );
              } else {
                  return [
                      ...items,
                      {
                          id: item,
                          oldPiece: record.piece,
                          quality: record.quality,
                          height: record.height,
                          width: record.width,
                          thickness: record.thickness,
                          surface: record.surface,
                          isQrScan: false,
                          piece: maxLength,
                          minPiece: record.minPiece,
                          code: record.code,
                          depotName: record.depotName || null,
                          isReserved: false,
                      },
                  ];
              }
          });
  
          setCheckedItemsStockExcess((items) => {
              const existingExcessItem = items.find((entry) => entry.id === item);
              if (existingExcessItem) {
                  return items.map((entry) =>
                      entry.id === item ? { ...entry, piece: excessValue } : entry
                  );
              } else {
                  return [
                      ...items,
                      {
                          id: item,
                        
                          quality: record.quality,
                          height: record.height,
                          width: record.width,
                          thickness: record.thickness,
                          surface: record.surface,
                          piece: excessValue, 
                          minPiece: record.minPiece,
                          code: record.code,
                          currentPiece: record.piece,
                          depotName: record.depotName || null,
                      },
                  ];
              }
          });
      }
  };
  
  
  
 
    const handleInputChangeWastage = (item, value) => {
    
      const maxLength = Number(itemsWastage.find((record) => record.key === item)?.piece);
      const maxLengthComingPiece = Number(itemsWastage.find((record) => record.key === item)?.comingPiece);

      const numericValue = Number(value);
      if (isNaN(numericValue)) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Hatalı giriş: Sayısal bir değer girmelisiniz.",
          placement: 'topRight'
        });
        return; 
    }
    else{
      if (!isNaN(numericValue) && (maxLength === undefined || numericValue <= maxLength)) {
        setInputValuesWastage((values) => ({ ...values, [item]: numericValue }));
    
        const checkedItemsWastagesArray = Array.isArray(checkedItemsWastage) ? checkedItemsWastage : [checkedItemsWastage];
        const itemExists = checkedItemsWastagesArray.some(x => x.id === item);
    
        if (itemExists) {
            const updatedItems = checkedItemsWastagesArray.map(x => {
                if (x.id === item) {
                    return { ...x, piece: numericValue };
                } else {
                    return x;
                }
            });
            setCheckedItemsWastage(updatedItems);
        } else {
           console.log("Item does not exist.");
        }
      }
    
      else {
    
        const reserveFromComingPiece = numericValue > maxLength ? numericValue - maxLength : 0;
        const totalAvailable = maxLength + maxLengthComingPiece;
  
  
        if (reserveFromComingPiece > 0 &&  totalAvailable >= numericValue ) {
        
            setInputValuesWastage((values) => ({
                ...values,
                [item]: numericValue,
            }));

            const updatedCheckedItems = Array.isArray(checkedItemsWastage) ? checkedItemsWastage : [checkedItemsWastage];
            const itemExists = updatedCheckedItems.some(x => x.id === item);

            if (itemExists) {
                const updatedItems = updatedCheckedItems.map(x => {
                    if (x.id === item) {
                        return { ...x, piece: numericValue };
                    } else {
                        return x;
                    }
                });
                setCheckedItemsWastage(updatedItems);
            } else {
              console.log("else oldu")
            }

        } else {
            setInputValuesWastage((values) => ({ ...values, [item]: maxLength }));
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "Yeterli sayıda bulunmamaktadır. Lütfen güncel stoktan seçiniz.",
                placement: 'topRight',
            });
            return;
        
        }     
      }
    }

   
    };


    //stock için filtreleme
    const handleSearchStock = (value, dataIndex) => {
      setFiltersStock(prevFilters => {
        const updatedFilters = {
          ...prevFilters,
          [dataIndex]: value,
        };

        localStorage.setItem("stockFilters", JSON.stringify(updatedFilters));
        const filteredData = itemsStock.filter(item => {
          return (
            !item.isDeleted && 
            (item.piece >= 0 && item.reservedPiece >= 0) &&
            (
              !(item.piece === 0 && item.waitingPiece === 0) // Eğer adet ve gelecek adet ikisi de 0 ise gösterme
            ) && 
            Object.keys(updatedFilters).every(key => {
              const filterValue = updatedFilters[key]?.toLowerCase(); 
              const itemValue = item[key] !== undefined ? item[key]?.toString().toLowerCase() : ''; 
              return filterValue ? itemValue === filterValue : true; 
            })
          );
        });
    
        setFilteredItemsStock(filteredData); 
        return updatedFilters; 
      });
    };
    

    const handleResetStock = () => {
      setFilteredItemsStock(itemsStock); 
    };

    const getColumnSearchPropsStock = dataIndex => ({
      title: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{placeholderMap[dataIndex] || dataIndex}</span>
          <Input
            id={`search-input-${dataIndex}`}
            placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
            onChange={e => handleSearchStock(e.target.value, dataIndex)} 
            style={{ width: 80, height:30, marginTop: 8  }}
            suffix={<SearchOutlined />} 
          />
        </div>
      ),
      sorter: (a, b) => {
        const aValue = a[dataIndex] ? a[dataIndex].toString().toLowerCase() : '';
        const bValue = b[dataIndex] ? b[dataIndex].toString().toLowerCase() : '';
        return aValue.localeCompare(bValue);
      },
    });

    const columnsStock = [
     /*
      {
        title: 'Firma',
        dataIndex: 'companyName',
        key: 'companyName',
      
        render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
      {
        title: 'Tedarikçi',
        dataIndex: 'supplierName',
        key: 'supplierName',
      
        render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },*/
      
       {
        title: 'Depo',
        dataIndex: 'depotName',
        key: 'depotName',
      
        render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
      
      ...(isWarehouseSelectedAll ? [{
        title: 'Depo',
        dataIndex: 'depotName',
        key: 'depotName',
        ...getColumnSearchPropsStock('depotName'),
        sorter: (a, b) => {
            const aDepot = a.depotName ? a.depotName.toString().toLowerCase() : '';
            const bDepot = b.depotName ? b.depotName.toString().toLowerCase() : '';
            return aDepot.localeCompare(bDepot);
        },
        render: (text, record) => (
            <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>
        ),
    }] : []),
    
    {
      title: 'Kalınlık',
      dataIndex: 'thickness',
      key: 'thickness',
    
      ...getColumnSearchPropsStock('thickness'),
      sorter: (a, b) => a.thickness - b.thickness,
      render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}mm</span>,
    },
    
      {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
      
        ...getColumnSearchPropsStock('quality'),
        sorter: (a, b) => a.quality.localeCompare(b.quality), 
        render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
  
       
  
       
        {
          title: 'En',
          dataIndex: 'width',
          key: 'width',
        
          ...getColumnSearchPropsStock('width'),
          sorter: (a, b) => a.width - b.width,
          render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
        {
          title: 'Boy',
          dataIndex: 'height',
          key: 'height',
       
          ...getColumnSearchPropsStock('height'),
          sorter: (a, b) => a.height - b.height,
          render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
  
        {
          title: 'Yüzey',
          dataIndex: 'surface',
          key: 'surface',
        
          ...getColumnSearchPropsStock('surface'),
          sorter: (a, b) => a.surface.localeCompare(b.surface), 
          render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
   
        {
          title: 'Adet',
          dataIndex: 'piece',
          key: 'piece',
          render: (text,record) => <span style={{color: itemsStock.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        
          sorter: (a, b) => a.piece - b.piece,
          ...getColumnSearchPropsStock('piece'),
        },
        
        {
          title: 'Rezerve Adet',
          dataIndex: 'reservedPiece',
          key: 'reservedPiece',
          render: (text,record) => <span style={{color: itemsStock.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
          sorter: (a, b) => a.reservedPiece - b.reservedPiece,
          ...getColumnSearchPropsStock('reservedPiece'),
         
        
        },
        {
          title: 'Beklenen Adet',
          dataIndex: 'waitingPiece',
          key: 'waitingPiece',
          render: (text,record) => <span style={{color: itemsStock.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
       
         
        
        },
        {
          title: 'Açıklama',
          dataIndex: 'isProblem',
          key: 'isProblem',
          render: (text, record) => {
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const formattedDate = record.problemDate ? new Date(record.problemDate.seconds * 1000).toLocaleString('tr-TR', options) : '';
            
            return (
              <Popover
              content={
                <div style={{ width: '200px', maxHeight: '700px' }}> {/* Sabit yükseklik ve genişlik */}
                  <p><strong>Problem Zamanı:</strong> {formattedDate}</p>
                  <p><strong>Problem Fotoğrafı:</strong> 
                    {record.problemImage ?                 
                    <img alt="" src={record.problemImage} style={{  width: '150px',height:"150px",objectFit:"contain" }} />
                    : 
                    "Fotoğraf bulunmamaktadır."}</p>
                        <p style={{ width: '200px', maxHeight: '500px', overflow:"hidden" }}><strong>Problem Açıklaması:</strong> {record.problemDescription}</p>
                      </div>
                    }
                    title="Sac levhada problem bulunmaktadır."
                    trigger="click" 
                  >
                <span style={{ color: itemsStock.isDeleted ? "#00000080" : "#000000" }}>
                  {record.isProblem ? <img width={30} height={20} alt="logo" src="/images/important.png" style={{ paddingBottom: "5%" }} /> : null}
                </span>
              </Popover>
            );
          },
        
        },
          
        {
            title: 'Kullanılacak Adet',
            dataIndex: '', 
            key: '',
          
            render: (text, record) => (
              <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>
                <Input
                      
                  onChange={(e) => handleInputChange(record.key, e.target.value)}
                   className="input-style"
                   style={{width:"100%"}}

                />
              </span>
            ),
        },
        
        {
          title: ' ',
          dataIndex: '',
          key: '',
          render: (text, record) => ( 
          <Button
          onClick={() => handleStockReserve(record.key, record)}
          style={{ backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} >   
             Rezerve Et 
          </Button> 
         ),
        }           
      
    ];

    //wastage için filtelme
    const handleSearchWastage = (value, dataIndex) => {
      setFiltersWastage(prevFilters => {
        // Filtre değerini güncelle
        const updatedFilters = {
          ...prevFilters,
          [dataIndex]: value, // Sadece bu filtreyi günceller
        };

        localStorage.setItem("wastageFilters", JSON.stringify(updatedFilters));

    
        // Tüm filtrelere göre filtreleme yap
        const filteredData = itemsWastage.filter(item => {
          return (
            !item.isDeleted && // Silinmemiş öğeleri dahil et
            (item.piece >= 0 && item.reservedPiece >= 0) && // piece ve reservedPiece 0'dan küçük değilse
            !(item.piece === 0 && item.comingPiece === 0) && // Eğer adet ve gelecek adet ikisi de 0 ise gösterme

            Object.keys(updatedFilters).every(key => {
              const filterValue = updatedFilters[key]?.toLowerCase(); // Aktif filtreyi al
              const itemValue = item[key] !== undefined ? item[key]?.toString().toLowerCase() : ''; // İlgili değer varsa kontrol et
              return filterValue ? itemValue === filterValue : true; // Tam eşleşme kontrolü
            })
          );
        });
    
        setFilteredItemsWastage(filteredData); // Filtrelenmiş veriyi güncelle
        return updatedFilters; // Yeni filtreleri geri döndür
      });
    };
    
    const handleResetWastage = () => {
      setFilteredItemsWastage(itemsWastage);
    };

    const getColumnSearchPropsWastage = dataIndex => ({
      title: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{placeholderMap[dataIndex] || dataIndex}</span>
          <Input
            id={`search-input-${dataIndex}`}
            placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
            onChange={e => handleSearchWastage(e.target.value, dataIndex)} // Her değişiklikte arama işlemi yapılacak
            style={{ width: '100%', marginTop: 8 }} // Başlığın altında input alanı olacak
            suffix={<SearchOutlined />} 
          />
        </div>
      ),
      sorter: (a, b) => {
        const aValue = a[dataIndex] !== undefined ? a[dataIndex].toString().toLowerCase() : '';
        const bValue = b[dataIndex] !== undefined ? b[dataIndex].toString().toLowerCase() : '';
        return aValue.localeCompare(bValue); // Sıralama işlemi
      },
    });


    const columnsWastage = [
      /*
      {
        title: 'key', // Add a new column title for the ID
        dataIndex: 'key', // Assuming `id` is the field in your data
        key: 'key',
        render: (text) => (
          <span style={{ color: '#000000' }}>{text}</span> // Adjust styling as needed
        ),
      },*/
      /*
      {
        title: 'Firma',
        dataIndex: 'companyName',
        key: 'companyName',
      
        render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },*/
      {
        title: 'Depo',
        dataIndex: 'depotName',
        key: 'depotName',
      
        render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
      
      ...(isWarehouseSelectedAll ? [{
        title: 'Depo',
        dataIndex: 'depotName',
        key: 'depotName',
        ...getColumnSearchPropsWastage('depotName'),
        sorter: (a, b) => a.depotName.localeCompare(b.depotName),
        render: (text, record) => (
          <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
            {text}
          </span>
        ),
    }] : []),
    {
      title: 'Kalınlık',
      dataIndex: 'thickness',
      key: 'thickness',
    
      ...getColumnSearchPropsWastage('thickness'),
      sorter: (a, b) => a.thickness - b.thickness,
      render: (text, record) => (
        <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
          {text} mm
        </span>
      ),
    },

      {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
       
        ...getColumnSearchPropsWastage('quality'),
        sorter: (a, b) => a.quality.localeCompare(b.quality), 
        render: (text, record) => (
          <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
            {text}
          </span>
        ),
      },
  
       
       
        {
          title: 'En',
          dataIndex: 'width',
          key: 'width',
         
          ...getColumnSearchPropsWastage('width'),
          sorter: (a, b) => a.width - b.width,
          render: (text, record) => (
            <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
              {text}
            </span>
          ),
        },
        {
          title: 'Boy',
          dataIndex: 'height',
          key: 'height',
       
          ...getColumnSearchPropsWastage('height'),
          sorter: (a, b) => a.height - b.height,
          render: (text, record) => (
            <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
              {text}
            </span>
          ),
        },
  
        {
          title: 'Yüzey',
          dataIndex: 'surface',
          key: 'surface',
        
          ...getColumnSearchPropsWastage('surface'),
          sorter: (a, b) => a.surface.localeCompare(b.surface), 
          render: (text, record) => (
            <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
              {text}
            </span>
          ),
        },
   
        {
          title: 'Adet',
          dataIndex: 'piece',
          key: 'piece',
          render: (text, record) => (
            <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
                {text !== undefined && text !== null && text !== "" ? text : 1}
            </span>
        ),         
          ...getColumnSearchPropsWastage('piece'),
          sorter: (a, b) => a.piece - b.piece,
        },
          
        {
          title: 'Rezerve Adet',
          dataIndex: 'reservedPiece',
          key: 'reservedPiece',
          render: (text, record) => (
            <span style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
              {text}
            </span>
          ),
          ...getColumnSearchPropsWastage('reservedPiece'),
          sorter: (a, b) => a.reservedPiece - b.reservedPiece,
        },
          
        {
          title: 'Gelecek Adet',
          dataIndex: 'comingPiece',
          key: 'comingPiece',
          render: (text, record) => (
            <span 
            style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
              {text}
            </span>
          ),
          ...getColumnSearchPropsWastage('comingPiece'),
          sorter: (a, b) => a.comingPiece - b.comingPiece,
        },
       
        {
          title: 'Rezerve Gelecek Adet',
          dataIndex: 'reservedComingPiece',
          key: 'reservedComingPiece',
          render: (text, record) => (
            <span 
            style={{ color: record.isQRScanned === false ? '#ff0000' : '#000000' }}>
              {text}
            </span>
          ),          sorter: (a, b) => a.reservedComingPiece - b.reservedComingPiece,
          ...getColumnSearchPropsStock('reservedComingPiece'),
         
        
        },
        {
          title: 'Açıklama',
          dataIndex: 'description',
          key: 'description',
          render: (text, record) => {
            // Belirli açıklamayı kontrol et
            const shouldHideDescription = record.description === "Teknik ressam tarafından setuptan eklenmiştir.";
        
            return (
              <div>
                <Popover
                  content={
                    <div style={{ width: '200px', maxHeight: '700px' }}>
                      <p><strong>Fotoğrafı:</strong>
                        {record.imageUrl ?                 
                          <img alt="" src={record.imageUrl} style={{ width: '150px', height: "150px", objectFit: "contain" }} />
                          : 
                          "Fotoğraf bulunmamaktadır."}
                      </p>
                      {/* Eğer açıklama belirtilen metne eşit değilse göster */}
                      {!shouldHideDescription && (
                        <p style={{ width: '200px', maxHeight: '500px', overflow: "hidden" }}>
                          <strong>Açıklaması:</strong> {record.description}
                        </p>
                      )}
                    </div>
                  }
                  title="Firede açıklama bulunmaktadır."
                  trigger="click"
                >
                  {/* Eğer açıklama belirtilen metne eşit değilse simgeyi göster */}
                  {!shouldHideDescription && (
                    <span>
                      {record.description ? (
                        <img width={30} height={20} alt="logo" src="/images/important.png" style={{ paddingBottom: "5%" }} />
                      ) : null}
                    </span>
                  )}
                </Popover>
            
                {!record?.isQRScanned && (
                  <div style={{ marginTop: '5px', color: '#ff0000' }}>
                    <div>{record.jobCode ?  record.jobCode.substring(0, 5) : "-"}</div>
                    <div>{record.companyName ? record.companyName : "-"}</div>
                    <div>{record.firstUserNameSurname ? record.firstUserNameSurname : "-"}</div>
                  </div>
                )}


              </div>
            );
          },
        },
        
        
        
        {
          title: 'Kullanılacak Adet',
          dataIndex: '', 
          key: '',
          render: (text, record) => {        
            return (
              <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>
                <Input
                  onChange={(e) => handleInputChangeWastage(record.key, e.target.value)}
                  className="input-style"
                  style={{ width: "100%" }}
                  rules={[{ pattern: /^\d+$/, message: "Sadece rakam girişi yapınız.", required: true }]}
                />
              </span>
            );
          },
        },
        
        
        {
          title: ' ',
          dataIndex: '',
          key: '',
          render: (text, record) => (
            <Button
            onClick={() => handleWastageReserve(record.key, record)}
            style={{ backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}
          >   
          Rezerve Et 
          </Button>   
         
          ),
        },
             
             
      
    ];
  
    const [isModalVisiblePurchase, setIsModalVisiblePurchase] = useState(false);

    const handlePurchaseRequest = () => {
      setIsModalVisiblePurchase(true);
    };

    const handlePurchaseRequestAddFinish = () => {
      setIsModalVisiblePurchase(false); 
    };

    const handleModalCancelPurchase = () => {
      setIsModalVisiblePurchase(false);
      setLoading(false);
    };



    const handleExit = () => {
      localStorage.removeItem("depotName");
      localStorage.removeItem("stockFilters");
      localStorage.removeItem("wastageFilters");
      localStorage.removeItem("setupName");
      localStorage.removeItem("checkedItemsStock");
      localStorage.removeItem("checkedItemsWastage");
      
      setTimeout(() => {
        navigate("/programmerpage");
      }, 2000);
    };


    const handleWaitingOpenOrderDetail = () => {
      setTimeout(() => {
         navigate('/waitingopenorderdetail', { state: { record } })
       }, 500);
    };


    //fazla ürün var satın alma talebi gidiyor
    const handleSendPurchaseRequest = async () => {
      setLoading(true);
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate.setHours(0, 0, 0, 0); 


      const updatedPurchaseList = checkedItemsStockExcessOnlyOne.map(item => ({
        ...item,
        
        jobCode: `${record.jobCode.substring(0,5)}-${checkedItemsStockExcessOnlyOne[0].thickness}${checkedItemsStockExcessOnlyOne[0].quality}`,
        description:null
      }));
      

       //min stok kontrolü için satın alma isteği atılıyor
       for (const item of updatedPurchaseList) {
        const updatedPurchaseListFixed = updatedPurchaseList.map(item => ({ 
            ...item,
            piece : item.minPiece- item.currentPiece,
            jobCode:null
          }));
        if (item.currentPiece < item.minPiece) {
          try {
            const data = { 
                jobCode: record.jobCode,
                companyName: record.companyName,
                productType: "raw",
                unitName: "teknik ressam",
                deadline: currentDate,
                requestedProductInfo:updatedPurchaseListFixed,
                description:"Minimum stok altına düşmüştür." ,
                isSendSetup: false,
                depotName:record.depotName
              };

             const result = await FirebaseService.addRecording(parsCollections.purchaseRequests,data);
            if (result.success) {
              console.log(`Purchase request created successfully for item with id ${item.id}.`);
            } else {
              console.log(`Failed to create purchase request for item with id ${item.id}.`);
            }
            
          } catch (error) {
            console.error(`Error creating purchase request for item with id ${item.id}:`, error);
          }
        }
      }

      const data = { 
        jobCode: `${record.jobCode.substring(0,5)}-${checkedItemsStockExcessOnlyOne[0].thickness}${checkedItemsStockExcessOnlyOne[0].quality}`,
        companyName: record.companyName,
        productType: "raw",
        unitName: "teknik ressam",
        deadline: currentDate,
        requestedProductInfo:updatedPurchaseList,
        description:"Teknik ressam tarafından seçilmiştir, setupda kullanılacaktır." ,
        isSendSetup: true
      };
      
      try {
        const result = await FirebaseService.addRecording(parsCollections.purchaseRequests,data);
        if (result.success) {
          setVisibleContinue(false);


          if (checkedItemsStockExcessOnlyOne && checkedItemsStockExcessOnlyOne.length > 0) {
            for (const x of checkedItemsStockExcessOnlyOne) {
                const { id, piece } = x;
        
                if (piece === 0) {
                    NotificationService.openErrorNotification({
                        title: 'İşlem Başarısız',
                        description: "Adet 0 seçilemez",
                        placement: 'topRight',
                    });
        
                    setLoading(false);
                    return;
                }
        
                try {
                    const result = await FirebaseService.updateStockWaitingPiece(id, piece);
                } catch (error) {
                    console.error("Firebase güncelleme hatası:", error);
                    NotificationService.openErrorNotification({
                        title: 'Hata',
                        description: "Güncelleme sırasında bir hata oluştu.",
                        placement: 'topRight',
                    });
                }
            }
        }
        


        let updatedcheckedItemsStock = checkedItemsStockExcessOnlyOne.map(stockItem => {
          const { minPiece, currentPiece, ...remainingFields } = stockItem; 
          return {
              ...remainingFields,
              isSendPurchase: true,
              code: stockItem.code ? stockItem.code : null,
              isQrScan: false,
              isReserved: true,
          };
      });
      
    
     
       let combinedJobCode = `${record.jobCode.substring(0,5)}-${checkedItemsStockExcessOnlyOne[0].thickness}${checkedItemsStockExcessOnlyOne[0].quality}`;

       const openOrderData = await FirebaseService.getOpenOrderData();
      
 
       const baseCombinedJobCode = getBaseJobCode(combinedJobCode);
 
       const filteredOpenOrder = openOrderData.filter(item => {
         const itemBaseJobCode = getBaseJobCode(item.jobCode);
         return itemBaseJobCode === baseCombinedJobCode && !item.isDeleted;;
       });
 
     
       try {
         if (filteredOpenOrder.length > 0 && filteredOpenOrder.every(item => item.isSetup)) {
           const currentDate = new Date();
 
           const matchingJobCodes = openOrderData
             .filter(order => order.jobCode.startsWith(record.jobCode.substring(0, 5)))
             .map(order => order.jobCode);
         
           let maxSuffix = 2; 
           matchingJobCodes.forEach(code => {
             const match = code.match(/-(\d+)$/); 
             if (match) {
               const suffix = parseInt(match[1], 10); 
               if (suffix >= maxSuffix) {
                 maxSuffix = suffix + 1; 
               }
             }
           });
         
           combinedJobCode = `${record.jobCode.substring(0,5)}-${checkedItemsStockExcessOnlyOne[0].thickness}${checkedItemsStockExcessOnlyOne[0].quality}_${maxSuffix}`;
        
         const newOrder = {
             firstTransactionDate: currentDate,
             firstUserId: localStorage.getItem("uid"),
             programmerUserId: localStorage.getItem("uid"),
             isDeleted: false,
             programmerName: `${localStorage.getItem("name")} ${localStorage.getItem("surname")}`,
             companyName: record.companyName,
             projectCode: record.projectCode,
             orderType: "order",
             isSetup: false,
             isOffer: false,
             isWaiting: true,
             isOfferApproved: false,
             noOfferApprovedDescription: null,
             noQrScanDescription: null,
             isOfferSetupUpdate: false,
             isOfferChange: false,
             isOfferDenied: false,
             isChangedProgrammer: false,
             isShipment: false,
             shipmentPiece: 1,
             invoiceNumber: null,
             setupName: null,
             deadline: new Date(record.deadline.seconds * 1000),
             jobCode: combinedJobCode,
             stock: updatedcheckedItemsStock,
             wastage: [],
             isOfferable:false,
             isAbkant:false

           };
         
           const createResult = await FirebaseService.addRecording(parsCollections.openOrder, newOrder);
         
           if (createResult.success) {
             NotificationService.openSuccessNotification({
               title: "İşlem Başarılı",
               description: "Yeni açık sipariş başarıyla oluşturuldu. Başarıyla rezerve edildi.",
               placement: "topRight",
             });
         
         
             setLoading(false);
           } else {
             NotificationService.openErrorNotification({
               title: "İşlem Başarısız",
               description: "Yeni açık sipariş oluşturulamadı. Lütfen daha sonra tekrar deneyiniz.",
               placement: "topRight",
             });
             setLoading(false);
           }
      
         } 
         else if (filteredOpenOrder.length > 0 && filteredOpenOrder.some(item => !item.isSetup)) {
        
           const openOrderToUpdate = filteredOpenOrder.find(item => !item.isSetup);
 
           const updateOpenOrderItem = {
             stock: updatedcheckedItemsStock, 
           };
     
           const updateResult = await FirebaseService.updateOpenOrderStockAndWastage(
             openOrderToUpdate.key,
             updateOpenOrderItem
           );
     
           if (updateResult.success) {
             NotificationService.openSuccessNotification({
               title: "İşlem Başarılı",
               description: "Başarıyla rezerve edilmiştir.",
               placement: "topRight",
             });
             setLoading(false);
 
           } else {
             NotificationService.openErrorNotification({
               title: "İşlem Başarısız",
               description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
               placement: "topRight",
             });
             setLoading(false);
 
           }
 
     
         }
         
         else{
           const currentDate =new Date()
           const newOrder = {
             firstTransactionDate: currentDate,
               firstUserId: localStorage.getItem("uid"),
               programmerUserId:localStorage.getItem("uid"),
               isDeleted: false,
               programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
               companyName: record.companyName,
               projectCode: record.projectCode,
               orderType :"order",         
               isSetup:false,
               isOffer:false,
               isWaiting: true,
               isOfferApproved:false,
               noOfferApprovedDescription:null,
               noQrScanDescription:null,
               isOfferSetupUpdate:false,
               isOfferChange:false,
               isOfferDenied:false,
               isChangedProgrammer:false,
               isShipment: false,
               shipmentPiece: 1,
               invoiceNumber:null,
               setupName: null,
               deadline: new Date(record.deadline.seconds * 1000),
               jobCode: combinedJobCode,
               stock: updatedcheckedItemsStock,
               wastage: [],
               isOfferable:false,
               isAbkant:false

           };
     
           const createResult = await FirebaseService.addRecording(parsCollections.openOrder,newOrder);
     
           if (createResult.success) {
             NotificationService.openSuccessNotification({
               title: "İşlem Başarılı",
               description: "Yeni açık sipariş başarıyla oluşturuldu. Başarıyla rezerve edildi.",
               placement: "topRight",
             });
 
             const filteredOpenOrderDelete = openOrderData.find(item => 
               item.jobCode && item.jobCode === combinedJobCode.substring(0, 5)
             );
   
             if(filteredOpenOrderDelete){
               const resultDelete = await FirebaseService.deleteOpenOrderData(filteredOpenOrderDelete.key);
             }
             setLoading(false);
 
 
           } else {
             NotificationService.openErrorNotification({
               title: "İşlem Başarısız",
               description: "Yeni açık sipariş oluşturulamadı. Lütfen daha sonra tekrar deneyiniz.",
               placement: "topRight",
             });
             setLoading(false);
 
           }
         }
       } catch (error) {
         NotificationService.openErrorNotification({
           title: "İşlem Başarısız",
           description: "Bilinmeyen bir hata ile karşılaşıldı.1",
           placement: "topRight",
         });
         setLoading(false);
 
       }
      
   
      
         
        }
        else{
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.4",
            placement: 'topRight'
          });
          setLoading(false);

        }
        
      } 
      catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.5",
          placement: 'topRight'
        });
        setLoading(false);
      }

      setCheckedItemsStockExcess([]);
      
    };
  
    const [visibleContinue, setVisibleContinue] = useState(false);

    const handleCancelContinue = () => {
     setVisibleContinue(false);
    };

    const columnsStockExcess = [
      {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
       
      //  ...getColumnSearchPropsStock('thickness'),
        sorter: (a, b) => a.thickness - b.thickness,
        render: (text) => <span style={{ }}>{text}mm </span>,
      },
        {
          title: 'Kalite',
          dataIndex: 'quality',
          key: 'quality',
        
         // ...getColumnSearchPropsStock('quality'),
          sorter: (a, b) => a.quality.localeCompare(b.quality), 
          render: (text) => <span style={{  }}>{text}</span>,
        },     
          {
            title: 'En',
            dataIndex: 'width',
            key: 'width',
           
           // ...getColumnSearchPropsStock('width'),
            sorter: (a, b) => a.width - b.width,
            render: (text) => <span style={{  }}>{text}</span>,
          },
          {
            title: 'Boy',
            dataIndex: 'height',
            key: 'height',
           // ...getColumnSearchPropsStock('height'),
            sorter: (a, b) => a.height - b.height,
            render: (text) => <span style={{  }}>{text}</span>,
          },
    
          {
            title: 'Yüzey',
            dataIndex: 'surface',
            key: 'surface',
           // ...getColumnSearchPropsStock('surface'),
            sorter: (a, b) => a.surface.localeCompare(b.surface), 
            render: (text) => <span style={{  }}>{text}</span>,
          },
     
          {
            title: 'Adet',
            dataIndex: 'piece',
            key: 'piece',
            render: (text,record) => <span style={{}}>{text}</span>,
           
            sorter: (a, b) => a.piece - b.piece,
           // ...getColumnSearchPropsStock('piece'),
          },         
        
    ];

  
    return ( 
    <div style={{padding:"10px 10px",}}>
        <div style={{ display: "flex", justifyContent: "space-between", textAlign:"center",paddingLeft:"40px" }}>
            <div style={{ textAlign: "left", flex: "1 0 25%" }}>
                <p style={{ fontWeight: "bold", color: colors.blue }}>Müşteri</p>
                {record.companyName}
            </div>
            <div style={{ textAlign: "left", flex: "1 0 25%" }}>
                <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
                {record.jobCode ? record.jobCode : "-" }
            </div>
            
            <div style={{ textAlign: "left", flex: "1 0 25%" }}>
              <p style={{ fontWeight: "bold", color: colors.blue }}>Proje Kodu</p>
              {record.projectCode ? record.projectCode : "-"}
            </div>

            <div style={{ textAlign: "left", flex: "1 0 25%" }}>
    <p style={{ fontWeight: "bold", color: colors.blue }}>Depo</p>
    <Select
        onChange={onChangeFilterWarehouse}
        placeholder="Depo Seçiniz"
        style={{ width: "150px" }}
        showSearch
        filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
        }
        suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
        options={warehouseOptions
            .sort((a, b) => {
              if (a.label.toLowerCase() === "tümü") return -1;
              if (b.label.toLowerCase() === "tümü") return 1;
              if (a.label.toLowerCase() === "pars") return a.label.toLowerCase() === "tümü" ? 1 : -1;
              if (b.label.toLowerCase() === "pars") return b.label.toLowerCase() === "tümü" ? 1 : 1;
              return a.label.localeCompare(b.label);
          })
          }
        className="input-style"
    />
</div>

        </div>
        
        {!selectedWarehouse ? (
  
  <div style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh", 
    color: colors.gray,
    fontWeight: "bold",
    fontSize: "18px",
  }}>
    Lütfen depo seçiniz
  </div>
) : (
  <>
        <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px",textAlign:"left" }}>Fire Listesi</div>
      <Table
        style={{width:"100%", margin:"auto"}}
        scroll={{ y: 500 }}
            locale={{
              emptyText: 'Henüz fire bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
           dataSource={filteredItemsWastage}
            columns={columnsWastage}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />

    <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px",textAlign:"left" }}>Güncel Stok Listesi</div>
      <Table
         style={{width:"100%", margin:"auto"}}
         scroll={{ y: 500 }}
          locale={{
            emptyText: 'Henüz stok bulunmamaktadır...',
            filterReset: 'Sıfırla',
            filterTitle: 'Filtre Menüsü',
            selectAll: 'Hepsini Seç',
            selectInvert: 'Tersini Seç',
            selectionAll: 'Tümünü Seç',
            sortTitle: 'Sıralama',
            triggerDesc: 'Azalan sıralama için tıklayın',
            triggerAsc: 'Artan sıralama için tıklayın',
            cancelSort: 'Sıralamayı iptal etmek için tıklayın',
          }}
           dataSource={filteredItemsStock}
            columns={columnsStock}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />

        <div style={{display:"flex", justifyContent:"right"}}>
          <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}   onClick={handleWaitingOpenOrderDetail} >
          Setup Yüklemeye Devam Et
          </Button>
          <Button 
                style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} 
                onClick={handlePurchaseRequest}>
                Satın Alma Talep Et
          </Button>
                <Modal
                  title="Satın Alma Talep Et"
                  visible={isModalVisiblePurchase}
                  onCancel={handleModalCancelPurchase}
                  footer={null}
                  >
                  <PurchaseRequestAdd onClose={handlePurchaseRequestAddFinish} />
                  </Modal>
            
      
            <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}   onClick={handleExit} >
              Çık
            </Button>
         <div>  
                   
          <Modal
                  title="Satın Alma Talebi Gönder"
                  visible={visibleContinue}
                  onOk={handleSendPurchaseRequest}
                  onCancel={handleCancelContinue}
                  okText="Evet, Gönder ve Açık Sipariş Oluşturmaya Devam Et"
                  cancelText="Vazgeç"
                  className="columnsStockExcess popup-modal-button"
                
              >
              <div> 
                <p>Satın alma talebi göndermek istediğinizden emin misiniz?</p>
                <Table
                    dataSource={checkedItemsStockExcessOnlyOne}
                    columns={columnsStockExcess}
                    showHeader={true}
                    pagination={false} 
                    className="custom-news-table"
                    rowClassName="custom-news-row"
                    locale={{
                      emptyText: 'Henüz eklemediniz...',
                      filterReset: 'Sıfırla',
                      filterTitle: 'Filtre Menüsü',
                      selectAll: 'Hepsini Seç',
                      selectInvert: 'Tersini Seç',
                      selectionAll: 'Tümünü Seç',
                      sortTitle: 'Sıralama',
                      triggerDesc: 'Azalan sıralama için tıklayın',
                      triggerAsc: 'Artan sıralama için tıklayın',
                      cancelSort: 'Sıralamayı iptal etmek için tıklayın',
                    }}
                />
            </div>
            
        </Modal>

        </div>
      
      </div>
      
      </>
      )}
      {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>
    )

};


export default OpenOrderAddContinue;


