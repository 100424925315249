import React, { useEffect, useState } from "react";
import { Cell, Tooltip, ResponsiveContainer, BarChart, XAxis, YAxis, Bar, LineChart, CartesianGrid, Line } from "recharts";
import { useLocation } from "react-router-dom";
import { colors } from "../../../environments/environment";
import { Button, DatePicker,} from 'antd';
import { useNavigate } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";


const SalesReportingList = () => {
  const [chart1, setChart1] = useState([]);
  const [chart2, setChart2] = useState([]);
  const navigate = useNavigate();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(new Date());
  const location = useLocation();
  const record = location.state && location.state.record;
  const [isVisible, setIsVisible] = useState(false);
  const [selectedRange, setSelectedRange] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const filteredRecords = filterRecordsByDate(record);

      const companyOrderCounts = filteredRecords
      .filter(item => item.orderType === "order") 
      .reduce((acc, item) => {
        acc[item.companyName] = (acc[item.companyName] || 0) + 1; // Şirket adına göre sayım yap
        return acc;
      }, {});

      const orderData = Object.keys(companyOrderCounts).map(company => ({
      companyName: company,
      orderCount: companyOrderCounts[company],
      }));

      const totalOrders = orderData.reduce((acc, item) => acc + item.orderCount, 0);

      const percentageData = orderData.map(item => ({
        companyName: item.companyName,
        percentage: totalOrders > 0 ? Number(((item.orderCount / totalOrders) * 100).toFixed(2)) : 0,
      }));

      setChart1(percentageData);
      setChart2(filteredRecords)


    };

    fetchData();
  }, [record, startDate, endDate]); 

  const filterRecordsByDate = (records) => {
    return records.filter((item) => {
      const itemDate = new Date(item.firstTransactionDate); 
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
     

      if (start && end) {
        return itemDate >= start && itemDate <= end;
      } else if (start) {
        return itemDate >= start;
      } else if (end) {
        return itemDate <= end;
      }
      return true; 
    });
  };

  const HorizontalBarChart = ({ data, title }) => {
    return (
      <div style={{ width: "100%", marginTop: "20px", textAlign: "center" }}>
        <h3>{title}</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            layout="vertical"
            data={data}
            margin={{ top: 20, right: 20, bottom: 20, left: 50 }}
          >
            <XAxis 
              type="number" 
              domain={[0, 100]} 
              tickFormatter={(tick) => `${tick}%`}
              stroke="#000"
              tick={{ fill: "#000" }}
            />
           {isVisible &&  <YAxis dataKey="companyName" type="category" width={150} />}
            <Tooltip formatter={(value) => `${value}%`} />
            <Bar dataKey="percentage" barSize={30}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Bar>         
           </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };
  
  const setDateRange = (months) => {
    const end = new Date(); 
    const start = new Date();
    start.setMonth(end.getMonth() - months); 
    setStartDate(start.toISOString().split("T")[0]); 
    setEndDate(end.toISOString().split("T")[0]);
  };

  const COLORS = [
    "#4CAF50", // Yeşil
    "#F44336", // Kırmızı
    "#FFC107", // Amber
    "#2196F3", // Mavi
    "#FF5722", // Derin Turuncu
    "#000080", // Lacivert
    "#008000", // Koyu Yeşil
    "#9C27B0", // Mor
    "#3F51B5", // Çivit Mavisi
    "#00BCD4", // Cam Göbeği
    "#E91E63", // Pembe
    "#8BC34A", // Açık Yeşil
    "#FF9800", // Turuncu
    "#673AB7", // Derin Mor
    "#CDDC39", // Limon Yeşili
    "#00FA9A", // Canlı Yeşil
    "#FF1493", // Canlı Pembe
    "#1E90FF", // Dodger Mavisi
    "#FFD700", // Altın
    "#ADFF2F"  // Yeşil Sarısı
  ];
  
  const handleChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedRange(value);
    setDateRange(value);
  };

  const goEditPage = (record) => {
    navigate('/salesmanpage', { state: { record } })
  };

  const handlePrint = () => {
    const printSection = document.getElementById("print-section");
  
    if (!printSection) {
      console.error("Print section not found!");
      return;
    }
  
    const printContents = printSection.innerHTML;
    const printWindow = window.open("", "_blank", "width=800,height=600");
  
    printWindow.document.write(`
      <html>
        <head>
          <title>Yazdır</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            .chart-item { margin: 20px; }
            h3 { color: ${colors.blue}; }
            /* Print için stil ayarları */
            @media print {
              .chart-item {
                width: 300px !important;
                margin: 10px !important;
              }
              .print-container {
                flex-direction: column;
                align-items: center;
              }
              @page {
                margin: 0.5cm;
              }
                .recharts-legend-wrapper{
                bottom:-60px !important;
              }

            }
          </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  };

  const LineGraphByCompanyWithMonthlyTable = ({ records }) => {
    const [selectedCompany, setSelectedCompany] = useState("");
    const [lineData, setLineData] = useState([]);
    const companies = Array.from(new Set(records.map((record) => record.companyName)));
    const monthOrder = [
      "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
      "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık",
    ];
  
    useEffect(() => {
      if (selectedCompany) {
        const filtered = records.filter(
          (record) => record.companyName === selectedCompany
        );
        const aggregation = {};
        filtered.forEach((record) => {
          if (record.firstTransactionDate) {
            const date = new Date(record.firstTransactionDate);
            const monthName = date.toLocaleString("tr-TR", { month: "long" });
            aggregation[monthName] = (aggregation[monthName] || 0) + 1;
          }
        });
  
        const aggregatedData = monthOrder.map((month) => ({
          month,
          count: aggregation[month] || 0,
        }));
  
        setLineData(aggregatedData);
      } else {
        setLineData([]);
      }
    }, [selectedCompany, records]);
  
    return (
      <div style={{ width: "100%", marginTop: "40px", textAlign: "center" }}>
        <div style={{ margin: "20px auto", maxWidth: "300px" }}>
          <select
            value={selectedCompany}
            onChange={(e) => setSelectedCompany(e.target.value)}
            className="input-style"
            style={{padding:"7px"}} 
          >
            <option value="">Firma Seçiniz</option>
            {companies.map((company, index) => (
              <option key={index} value={company}>
                {company}
              </option>
            ))}
          </select>
        </div>
        {selectedCompany && lineData.length > 0 && (
          <>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={lineData}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="count"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
            <h3
              style={{
                backgroundColor: "white",
                color: "#2196F3",
                fontWeight: "700",
                border: "none",
                marginTop: "40px",
              }}
            >
              <h3 style={{  backgroundColor: "white", color: colors.blue, fontWeight: "700", border: "none" }}>
              {selectedCompany} - Değişim Tablosu
          </h3>
            </h3>
            <div
              style={{
                marginTop: "20px",
                textAlign: "left",
                maxWidth: "500px",
                margin: "20px auto",
              }}
            >
              {lineData.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: COLORS[index % COLORS.length],
                      marginRight: "10px",
                    }}
                  ></div>
                  <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                    {item.month}:
                  </span>
                  <span>{item.count}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  };
  
  const toggleAllVisibility = () => {
    setIsVisible(prevState => !prevState); 
  };

  return (
    <div style={{ textAlign: "center", minHeight: "94vh", padding: "3%" }}  id="print-section">
     <div style={{display:"flex", alignItems:"center"}}>
      <h3 style={{ backgroundColor: "white", color: colors.blue, fontWeight: "700", border: "none", textAlign: "left" }}>
        Satış Raporlamaları
      </h3>
      <div>
        <Button
            shape="circle"
            icon={isVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />} 
            onClick={toggleAllVisibility}
            style={{
              backgroundColor: 'transparent',
              border: '2px solid',
              borderColor: colors.blue,
              cursor: 'pointer',
              marginLeft: '20px'
            }} 
          />
        </div>
        </div>
      <div style={{display:"flex", float:"right"}}>
      <div style={{ marginBottom: "20px" , display:"block"}}>
        <label>Başlangıç Tarihi: </label>
        <DatePicker      className="input-style"  selected={startDate} onChange={(date) => setStartDate(date)} />
        <label style={{ marginLeft: "20px" }}>Bitiş Tarihi: </label>
        <DatePicker      className="input-style"  selected={endDate} onChange={(date) => setEndDate(date)} />
      </div>
     
      <select value={selectedRange} onChange={handleChange}     className="input-style"   style={{ height:"35px", marginLeft:"50px", color:colors.blue}}>
        <option value="">Tarih Aralığı Seç</option>
        <option value="1">Son 1 Ay</option>
        <option value="3">Son 3 Ay</option>
        <option value="6">Son 6 Ay</option>
        <option value="12">Son 1 Yıl</option>
      </select>

      </div>

      <div style={{  marginTop:"50px" }}>
        <div>
          <HorizontalBarChart data={chart1} title="Genel Görünüm" />
          <h3 style={{  backgroundColor: "white", color: colors.blue, fontWeight: "700", border: "none" }}>
            Genel Görünüm
          </h3>
          
          {isVisible && 
          <div style={{ marginTop: "20px", textAlign: "left" }}>
            {chart1.map((item, index) => (
              <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: COLORS[index % COLORS.length],
                    marginRight: "10px"
                  }}
                ></div>
                <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                  {item.companyName}:
                </span>
                <span>{item.percentage}%</span>
              </div>
            ))}
          </div>
          }
        </div>

         <div>
         <LineGraphByCompanyWithMonthlyTable records={chart2} />
        
          
  
        </div>

      </div>

      <div style={{float:"right"}}>

      <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",border:"none", }}>
          Detay Satış / Teklif Ekranı
       </Button>
       <Button  
  onClick={handlePrint} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",border:"none",marginLeft:"10px"}}>
         Yazdır
       </Button>
       </div>
    </div>
  );
};

export default SalesReportingList;