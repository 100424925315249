import React,{useEffect,useState} from "react";
import { Button, Form, Input, Select,Spin} from 'antd';
import NotificationService from "../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import { useNavigate } from 'react-router-dom';

 

const CompanyEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const record = location.state && location.state.record;
    const [paymentMethods, setPaymentMethods] = useState([]); 
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
      const fetchData = async () => {
        const paymentMethods = await FirebaseService.getPaymentMethodsData();
        const filteredpaymentMethods = paymentMethods.filter(item => !item.isDeleted);
        setPaymentMethods(filteredpaymentMethods);
      };
      fetchData();
    }, []);

  
   
    const onFinish = async (values) => {
      setLoading(true);
      const currentDate = new Date();
      const paymentMethodValue = values.paymentMethod ? values.paymentMethod : record.paymentMethod;

      const data = {
        firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          isDeleted: false,
          companyName: values.companyName,
          vkn : values.vkn,
          taxAdministration: values.taxAdministration,
          paymentMethod : paymentMethodValue,
          address: values.address,
          email :values.email
      };
    
      try {
        const result = await FirebaseService.updateCompany(record.key, data);
        console.log("result",result)
       
        if (result.success) {
         if (record.companyName !== data.companyName) {
              const updateSuccess = await FirebaseService.updateCompanyNameInAllTables(record.companyName, data.companyName);

              if (!updateSuccess) {
                NotificationService.openErrorNotification({
                  title: 'Güncelleme Hatası',
                  description: "Tüm tablolar güncellenemedi. Lütfen tekrar deneyiniz.",
                  placement: 'topRight'
                });
                setLoading(false);
                return; 
              }
            }

            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: result.message,
              placement: 'topRight'
            });

            setLoading(false);
            setTimeout(() => {
              navigate("/companylist");
            }, 2000);
            
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });
          setLoading(false);
        }
    
        
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
        setLoading(false);
      }
    };
    
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };
  
    const onChange = (value) => {
      console.log("value",value)
      setPaymentMethod(value)

    };

   return ( 
    <div>
    
    <Form
       name="basic"
       labelCol={{
       span: 2,
       }}
       wrapperCol={{
       span: 8,
       }}
       style={{
       maxWidth: "100%",
       padding:"5% 0 0 5%"
       }}
       initialValues={{
        companyName: record?.companyName || "",
                    vkn: record?.vkn || "",
                    taxAdministration: record?.taxAdministration || "",
                    email: record?.email || "",
                    address: record?.address || "",
                    paymentMethod: record?.paymentMethod || "",
       }}
       onFinish={onFinish}
       onFinishFailed={onFinishFailed}
       autoComplete="off"
   >

   

       <Form.Item
       label="Firma"
       name="companyName"
       rules={[
           {
           required: true,
           message: 'Lütfen firma adı giriniz!',
           },
       ]}
     
       >
     <Input className="input-style" style={{width:"100%"}}  placeholder="Firma Adı"/>
       </Form.Item>


       <Form.Item
        label="Vkn"
       name="vkn"
       rules={[
           {
           required: true,
           message: 'Lütfen vkn giriniz!',
           },
       ]}
    
       >
     <Input className="input-style" style={{width:"100%"}}  placeholder="Vergi Numarası"/>
       </Form.Item>


       <Form.Item
        label="Vergi Dairesi"
       name="taxAdministration"
       rules={[
           {
           required: true,
           message: 'Lütfen vergi dairesi giriniz!',
           },
       ]}
      
       >
     <Input className="input-style" style={{width:"100%"}}  placeholder="Vergi Dairesi"/>
       </Form.Item>


       <Form.Item
        label="Mail"
       name="email"
       rules={[
         {
           type: 'email',
           required: true,
           message: 'Lütfen mail adresinizi doğru formatta giriniz!',
         },
       ]}
      
       >
     <Input className="input-style" style={{width:"100%"}}  placeholder="Mail adresi"/>
       </Form.Item>

       
    
       <Form.Item
        label="Adres"
       name="address"
       rules={[
           {
           required: true,
           message: 'Lütfen adres giriniz!',
           },
       ]}
     
       >
     <Input className="input-style" style={{width:"100%"}}  placeholder="Adres"/>
       </Form.Item>

       
    
       <Form.Item
        label="Ödeme"
       name="paymentMethod"
       rules={[
           {
           required: true,
           message: 'Lütfen ödeme vaadesi giriniz!',
           },
       ]}
      
       >
       <Select
       showSearch
           onChange={onChange}
           placeholder="Ödeme Vaadesi Seçiniz"
           className="input-style" 
           style={{ width:"350px" }}
           suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
        /*   options={[
             ...paymentMethods.map(item => ({ label: item.paymentMethod, value: item.paymentMethod })),
             { label: 'Yeni Ödeme vaadesi Ekle', value: 'newPaymentMethod' },
           ]}*/
           options={paymentMethods.map(item => ({ label: item.paymentMethod, value: item.paymentMethod }))}
           filterOption={(input, option) => 
            option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
          }
         />            
         </Form.Item>   
     
       <Form.Item
       wrapperCol={{
           offset: 15,
           span: 18,
       }}
       >
       <Button htmlType="submit" loading={loading} style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
       </Form.Item>

   </Form>
   {loading && (
           <div style={{
               position: 'fixed',
               top: 0,
               left: 0,
               width: '100%',
               height: '100%',
               backgroundColor: 'rgba(0, 0, 0, 0.5)',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               zIndex: 1000,
           }}>
               <Spin/>
           </div>
       )}

    </div>
    )

        };
  export default CompanyEdit;