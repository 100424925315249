import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend,BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList } from "recharts";
import { colors } from "../../../environments/environment";
import { Button, DatePicker,} from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";


const RawMaterialReportingList = () => {
  const [chart1, setChart1] = useState([]);
  const [chart2, setChart2] = useState([]);
  const [record, setRecord] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedRange, setSelectedRange] = useState("");
  const navigate = useNavigate();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(new Date());
  
  useEffect(() => {
    const fetchData = async () => {
    
      const dataInvoicePurchase = await FirebaseService.getInvoicePurchaseData();
      const invoiceOpenOrderData = await FirebaseService.getInvoiceOpenOrderData();

      const filteredInvoicePurchaseRecords = filterRecordsByDate(dataInvoicePurchase);
      const filteredRecords = filterRecordsByDate(invoiceOpenOrderData);

      const qualityTotals = {};
      const latestPrices = {};

      filteredInvoicePurchaseRecords.forEach(record => {
        const { quality, unitKilo, piece, price, date } = record;
        if (quality) {
          const kilo = unitKilo * piece;
          qualityTotals[quality] = (qualityTotals[quality] || 0) + kilo;
          
          if (!latestPrices[quality] || new Date(latestPrices[quality].date) < new Date(date)) {
            latestPrices[quality] = { price, date };
          }
        }
      });

      const totalPricesQuality = filteredRecords.reduce((acc, record) => {
        return { ...acc, ...record.totalPricesQuality };
      }, {});

      const qualityTableData = Object.keys(qualityTotals).map(quality => {
        const latestPrice = latestPrices[quality]?.price || 0;
        const qualityPrice = totalPricesQuality[quality] || latestPrice;
        const totalKilo = qualityTotals[quality] || 0;
        
        return {
          quality,
          latestPrice,
          qualityPrice,
          totalKilo,
        };
      });
      
      const totalMaterialCostSum = filteredRecords.reduce((sum, record) => {
        const qualityPrices = Object.values(record.totalPricesQuality || {});
        return sum + qualityPrices.reduce((qSum, price) => qSum + parseFloat(price || 0), 0);
      }, 0);
      
      const totalPriceSum = filteredRecords.reduce((sum, record) => sum + parseFloat(record.allTotalPrice || 0), 0);
      
      const materialCostPercentage = (totalMaterialCostSum / totalPriceSum) * 100;
      const laborCostPercentage = 100 - materialCostPercentage;
      
      console.log({ totalMaterialCostSum, totalPriceSum, materialCostPercentage, laborCostPercentage });

      setChart1(qualityTableData)

      const pieData = [
        { name: "Hammadde Maliyeti", value: materialCostPercentage },
        { name: "İşçilik Maliyeti", value: laborCostPercentage },
      ];
      console.log("pieData",pieData)
      setChart2(pieData);
    
      
    };
  
    fetchData();
  }, [selectedRange]); 
  
  const filterRecordsByDate = (records) => {
    return records.filter((item) => {
      const itemDate = new Date(item.firstTransactionDate); 
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
      if (start && end) {
        return itemDate >= start && itemDate <= end;
      } else if (start) {
        return itemDate >= start;
      } else if (end) {
        return itemDate <= end;
      }
      return true; 
    });
  };

  const BarChartContainer = ({ data }) => {
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 50, bottom: 50 }}
          >
              <XAxis dataKey="quality" type="category" stroke="#000" />
            {isVisible && 
            <YAxis
              dataKey="totalKilo"
              type="number"
              stroke="#000"
              tickFormatter={(value) => `${value.toLocaleString()} kg`} 
            />
            }
            {isVisible && <Tooltip formatter={(value) => `${value.toLocaleString()} kg`} />}
  
            <Bar dataKey="totalKilo">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}

              {isVisible && 
              <LabelList
                dataKey="totalKilo"
                position="top"
                formatter={(value) => `${value.toLocaleString()} kg`}
              />
              }
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };
  
  const COLORS = [
    "#FF5733", // Canlı Kırmızı
    "#000080", // Lacivert (Navy Blue)
    "#808080", // Gri
    "#008000", // Yeşil
    "#C70039", // Derin Kırmızı
    "#800080", // Koyu Mor (Purple)
    "#D8A7D0", // Açık Mor
    "#F39C12"  // Parlak Turuncu
  ];

  const setDateRange = (months) => {
    const end = new Date(); 
    const start = new Date();
    start.setMonth(end.getMonth() - months); 
    setStartDate(start.toISOString().split("T")[0]); 
    setEndDate(end.toISOString().split("T")[0]);
  };

  const handleChange = (event) => {
    const value = event.target.value; 
    setSelectedRange(value); 
  
    if (value === "") {
      setDateRange(null); 
      return; 
    }

    const numericValue = parseInt(event.target.value, 10);
    setSelectedRange(numericValue);
    setDateRange(numericValue);
  };

  const goEditPage = (record) => {
    navigate('/buyerpage', { state: { record } })
  };

  const handlePrint = () => {
    const printSection = document.getElementById("print-section");
  
    if (!printSection) {
      console.error("Print section not found!");
      return;
    }
  
    const printContents = printSection.innerHTML;
    const printWindow = window.open("", "_blank", "width=800,height=600");
  
    printWindow.document.write(`
      <html>
        <head>
          <title>Yazdır</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            .chart-item { margin: 20px; }
            h3 { color: ${colors.blue}; }
            /* Print için stil ayarları */
            @media print {
              .chart-item {
                width: 300px !important;
                margin: 10px !important;
              }
              .print-container {
                flex-direction: column;
                align-items: center;
              }
              @page {
                margin: 0.5cm;
              }
                .recharts-legend-wrapper{
                bottom:-60px !important;
              }

            }
          </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  };

  const toggleAllVisibility = () => {
    setIsVisible(prevState => !prevState); 
  };
 
  return (
    <div style={{ textAlign: "center", minHeight: "94vh", padding: "3%" }}  id="print-section">
     
     <div style={{display:"flex", alignItems:"center"}}>
      <h3 style={{ backgroundColor: "white", color: colors.blue, fontWeight: "700", border: "none", textAlign: "left" }}>
        Ham Madde Raporlamaları	
        </h3>
        <div>
        <Button
            shape="circle"
            icon={isVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />} 
            onClick={toggleAllVisibility}
            style={{
              backgroundColor: 'transparent',
              border: '2px solid',
              borderColor: colors.blue,
              cursor: 'pointer',
              marginLeft: '20px'
            }} 
          />
        </div>
      </div>
      <div style={{display:"flex", float:"right"}}>
      <div style={{ marginBottom: "20px" , display:"block"}}>
        <label>Başlangıç Tarihi: </label>
        <DatePicker     placeholder="Başlangıç Tarihi"   className="input-style"  selected={startDate} onChange={(date) => setStartDate(date)} />
        <label style={{ marginLeft: "20px" }}>Bitiş Tarihi: </label>
        <DatePicker     placeholder="Bitiş Tarihi"   className="input-style"  selected={endDate} onChange={(date) => setEndDate(date)} />
      </div>
     
      <select value={selectedRange} onChange={handleChange}     className="input-style"   style={{ height:"35px", marginLeft:"50px", color:colors.blue,}}>
        <option value="">Tarih Aralığı Seç</option>
        <option value="1">Son 1 Ay</option>
        <option value="3">Son 3 Ay</option>
        <option value="6">Son 6 Ay</option>
        <option value="12">Son 1 Yıl</option>
      </select>

      </div>
      <div style={{marginTop:"100px"}}>
        
        <BarChartContainer data={chart1} />

        {isVisible && 
        <>
         <div style={{fontWeight:"bold"}}>Kalite: Tonaj</div>
        <div style={{ marginTop: "20px", textAlign: "center", display:"flex", justifyContent:"space-evenly" }}>
          {chart1.map((entry, index) => {
            const value = entry.value;
            return (
              <div key={`label-${index}`} style={{ marginBottom: "10px", color: COLORS[index % COLORS.length] }}>
                <strong>{entry.quality}: {entry.totalKilo} ton </strong> {value}
              </div>
            );
          })}
        </div>
        </>
        }
      
      </div>

      <div>
        <PieChart width={400} height={400}>
          <Pie
            data={chart2}
            cx={200}
            cy={200}
            labelLine={false}
            outerRadius={150}
            fill="#8884d8"
            dataKey="value"
          >
            {chart2.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip 
            formatter={(value, name) => {
              if (!isVisible) return null;
              return typeof value === "number" ? `${value.toFixed(2)}%` : "-"; 
            }} 
          />
          <Legend />
        </PieChart>

      </div>
    
  
   
      <div style={{float:"right", marginTop:"100px"}}>


      <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",border:"none", }}>
          Detay Satın Alma Ekranı
       </Button>
       <Button  
         onClick={handlePrint} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",border:"none",marginLeft:"10px"}}>
         Yazdır
       </Button>
       </div>
    </div>
  );
};



export default RawMaterialReportingList;