import React, { useState,useEffect } from "react";
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import PurchaseRequestAdd from "../purchaseRequests/purchaseRequestAdd";
import AlarmListLimited from "../alarms/alarmListLimited";
import WaitingOfferOpenOrderListLimited from "../openOrders/offer/waitingOfferOpenOrderListLimited";
import ContractManufacturingProdcutWithAdd from "../contractManufacturings/contractManufacturingProdcutWithAdd";
import WaitingOrderOpenOrderListLimited from "../openOrders/order/waitingOrderOpenOrderListLimited";
import WebNavbar from "../webNavbar";
import OpenOrderGroupListLimited from "../openOrders/openOrderGroupListLimited";

const SalesmanPage = () => {

  useEffect(() => {
    localStorage.removeItem("qualityWeights");
    localStorage.removeItem("paymentMethod");
    localStorage.removeItem("machineTimes");

  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handlePurchaseRequest = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setTimeout(() => {
      window.location.reload();
    }, 100);
    setIsModalVisible(false);
  };

  const handlePurchaseRequestAddFinish = () => {
    setIsModalVisible(false); 
  };


  const [isModalVisibleContract, setIsModalVisibleContract] = useState(false);

  const handleContractManufacturingProductWithAdd = () => {
    setIsModalVisibleContract(true);
  };

  const handleModalCancelContractProductWith = () => {
    setIsModalVisibleContract(false);
  };

  const handleContractManufacturinggProductWithAddFinish = () => {
    setIsModalVisibleContract(false); 
  };  

  const [isModalVisibleOpenOrder, setIsModalVisibleOpenOrder] = useState(false);

  const handleOpenOrder = () => {
    setIsModalVisibleOpenOrder(true);
  };

  const handleModalCancelOpenOrder = () => {
    setIsModalVisibleOpenOrder(false);
  };

  const handleOpenOrderAddFinish = () => {
    setIsModalVisibleOpenOrder(false); 
  };


  const [isModalVisibleStock, setIsModalVisibleStock] = useState(false);

  const handleStock = () => {
    setIsModalVisibleStock(true);
  };

  const handleModalCancelStock= () => {
    setIsModalVisibleStock(false);
  };

  const handleStockFinish = () => {
    setIsModalVisibleStock(false); 
  };

  const navigate = useNavigate();
  const handleCheckCurrentPrice = () => {
    navigate('/checkcurrentpricelist')
  };


  const handleProcessStatus = () => {
    navigate('/processstatus')
  };

  const handleApprovedOffer = () => {
    navigate('/approvedofferopenorderList')
  };



  const handleOpenOrderSortList = () => {
    navigate('/openordersortlist')
  };


  return (
    <div style={{padding:"3%"}}>
     <WebNavbar/>
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
      <WaitingOfferOpenOrderListLimited />
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
        <OpenOrderGroupListLimited />
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
      <WaitingOrderOpenOrderListLimited />
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px", display:"block" }}>
        <div>
        <AlarmListLimited />
        </div>
      
        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: "10px" }}>
     
{/* 
            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button  
            className="classic-button"
            onClick={handleOpenOrderSortList}>
            Açık Sipariş Sıralama Düzenle
             </Button>

            </div>
*/}
 
            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button 
           className="classic-button"
           onClick={handlePurchaseRequest}>
                Satın Alma Talep Et
                </Button>
                  <Modal
                  title="Satın Alma Talep Et"
                  visible={isModalVisible}
                  onCancel={handleModalCancel}
                  footer={null}
                  >
                  <PurchaseRequestAdd onClose={handlePurchaseRequestAddFinish} />
                  </Modal>
            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
              <Button 
              className="classic-button"
              onClick={handleProcessStatus}>
              İş Durum Listesi
              </Button>
               
            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button 
           className="classic-button"
           onClick={handleContractManufacturingProductWithAdd}>
                Ürün Girişi
                </Button>
                  <Modal
                  title="Ürün Giriş"
                  visible={isModalVisibleContract}
                  onCancel={handleModalCancelContractProductWith}
                  footer={null}
                  >
                  <ContractManufacturingProdcutWithAdd onClose={handleContractManufacturinggProductWithAddFinish} />
                  </Modal>
            </div>
  
            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button 
              className="classic-button"
              onClick={handleApprovedOffer}>
                  Onaylanan Teklifler
              </Button>
            </div>
        </div>

   
   
      </div>
    </div>
 
   
    </div>
  );
};

export default SalesmanPage;

