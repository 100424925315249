import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend,BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList } from "recharts";
import { useLocation } from "react-router-dom";
import { colors } from "../../../environments/environment";
import { Button, DatePicker, Select,} from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import moment from 'moment';
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";


const PurchasingReportsList = () => {
  const [chart1, setChart1] = useState([]);
  const [chart2, setChart2] = useState([]);
  const [chart3, setChart3] = useState([]);
  const [record, setRecord] = useState([]);
  const [selectedQuality, setSelectedQuality] = useState("ST35"); 
  const [qualities, setQualities] = useState([]); 
  const [selectedChart3, setSelectedChart3] = useState([]);
  const [selectedRange, setSelectedRange] = useState("");
  const navigate = useNavigate();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(new Date());
  const [isVisible, setIsVisible] = useState(false);
  

  useEffect(() => {
    const fetchData = async () => {
      const qualities = await FirebaseService.getDensitiesData();
      const filteredQualities = qualities.filter(item => !item.isDeleted);
      setQualities(filteredQualities);

      const data = await FirebaseService.getPurchaseRequestData();
      setRecord(data);
  
      const dataInvoicePurchase = await FirebaseService.getInvoicePurchaseData();
      const filteredInvoicePurchaseRecords = filterRecordsByDate(dataInvoicePurchase);
  
      const filteredRecords = filterRecordsByDate(data);
  
      console.log("data",data)
      console.log("filteredRecords",filteredRecords)


      const producingCount = filteredRecords.filter(item => item.unitName === "lazer" || item.unitName === "abkant" || item.unitName === "üretim müdürü").length;
      const programmerCount = filteredRecords.filter(item => item.unitName === "teknik ressam").length;
      const salesmanCount = filteredRecords.filter(item => item.unitName === "satış").length;
      const buyerCount = filteredRecords.filter(item => item.unitName === "satın alma").length;
      const achieveCount = filteredRecords.filter(item => item.unitName === "depo").length;
      const accountingCount = filteredRecords.filter(item => item.unitName === "muhasebe").length;
      const shipmentCount = filteredRecords.filter(item => item.unitName === "sevkiyat").length;
      const managerCount = filteredRecords.filter(item => item.unitName === "yönetici").length;
  
      const total = producingCount + programmerCount + salesmanCount + buyerCount + achieveCount + accountingCount + shipmentCount + managerCount;
      const safePercentage = (count) => (total > 0 ? (count / total) * 100 : 0);
  
      const allUnits = [
        { key: "producing", names: ["lazer", "abkant", "üretim müdürü"], label: "Üretim" },
        { key: "programmer", names: ["teknik ressam"], label: "Arge" },
        { key: "salesman", names: ["satış"], label: "Satış" },
        { key: "buyer", names: ["satın alma"], label: "Satın Alma" },
        { key: "achieve", names: ["depo"], label: "Depo" },
        { key: "accounting", names: ["muhasebe"], label: "Muhasebe" },
        { key: "shipment", names: ["sevkiyat"], label: "Sevkiyat" },
        { key: "manager", names: ["yönetici"], label: "Yönetici" }
      ];
  
      const groupedData = allUnits.map(({ key, names, label }) => {
        const count = filteredRecords.filter(item => names.includes(item.unitName)).length;
        return { name: label, value: count, percentage:safePercentage(count) };
      });
  
      setChart1(groupedData);
  
      setChart2(prepareBarChartData(filteredInvoicePurchaseRecords));
  
     
      const groupedByQuality = filteredInvoicePurchaseRecords.reduce((acc, record) => {
        const quality = record.quality;
        if (quality) {
          if (!acc[quality]) {
            acc[quality] = [];
          }
          acc[quality].push(record);
        }
        return acc;
      }, {});


      function groupByDate(records) {
        return records.reduce((acc, record) => {
          const dateKey = record.firstTransactionDate ? record.firstTransactionDate.toISOString().split('T')[0] : null;
          if (dateKey) {
            if (!acc[dateKey]) {
              acc[dateKey] = {
                totalAmount: 0,
                totalPieces: 0,
                priceSum: 0,
                recordCount: 0,
              };
            }
            acc[dateKey].totalAmount += record.price / (record.unitKilo * record.piece);  
            acc[dateKey].totalPieces += record.piece;
            acc[dateKey].priceSum += record.price;
            acc[dateKey].recordCount += 1;
          }
          return acc;
        }, {});
      }

      const groupedByDatePerQuality = {};

      for (const quality in groupedByQuality) {
        groupedByDatePerQuality[quality] = groupByDate(groupedByQuality[quality]);
      }

      function calculateAveragePrice(groupedData) {
        const result = {};
        for (const date in groupedData) {
          const data = groupedData[date];
          result[date] = {
            averagePrice: data.priceSum / data.recordCount,
            totalAmount: data.totalAmount,
            totalPieces: data.totalPieces,
          };
        }
        return result;
      }

      const averagePricesPerQuality = {};
      for (const quality in groupedByDatePerQuality) {
        averagePricesPerQuality[quality] = calculateAveragePrice(groupedByDatePerQuality[quality]);
      }

      const chartData = {};
      for (const quality in averagePricesPerQuality) {
        chartData[quality] = {
          labels: Object.keys(averagePricesPerQuality[quality]), 
          averagePrices: Object.values(averagePricesPerQuality[quality]).map((data) => data.averagePrice), 
          totalAmounts: Object.values(averagePricesPerQuality[quality]).map((data) => data.totalAmount), 
        };
      }
      console.log("chartData",chartData)

      setChart3(chartData)
    };
  
    fetchData();
  }, [selectedRange]); 
  
  const filterRecordsByDate = (records) => {
    return records.filter((item) => {
      const itemDate = new Date(item.firstTransactionDate); 
     
      const start = startDate ? new Date(startDate) : null;
     
      const end = endDate ? new Date(endDate) : null;

      if (start && end) {
        return itemDate >= start && itemDate <= end;
      } else if (start) {
        return itemDate >= start;
      } else if (end) {
        return itemDate <= end;
      }
      return true; 
    });
  };
 
  const BarChartContainer = ({ data, title}) => {
  return (
      <div style={{ width: "100%", textAlign: "center" }}>
        <h4 style={{ backgroundColor: "white", color: colors.blue, marginTop: "20px", textAlign: "left" }}>
          {title}
         
        </h4>
  
        <ResponsiveContainer width="100%" height={500}>
          <BarChart 
            data={data} 
            layout="horizontal"
            margin={{ top: 20, right: 30, left: 50, bottom: 50 }}
          >
            {isVisible && <XAxis dataKey="name" type="category" />}
            <YAxis dataKey="percentage" type="number" domain={[0, 100]} ticks={[0, 20, 40, 60, 80, 100]} />
            
            {isVisible && (
              <Tooltip
                formatter={(value) => `${value.toFixed(2)}%`} 
              />
            )}
            
            <Bar dataKey="percentage">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
  
              {isVisible && (
                <LabelList 
                  dataKey="percentage" 
                  position="top"   
                  formatter={(value) => `${value.toFixed(2)}%`}
                />
              )}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };
  
  const COLORS = [
    "#FF5733", // Canlı Kırmızı
    "#000080", // Lacivert (Navy Blue)
    "#808080", // Gri
    "#008000", // Yeşil
    "#C70039", // Derin Kırmızı
    "#800080", // Koyu Mor (Purple)
    "#D8A7D0", // Açık Mor
    "#F39C12"  // Parlak Turuncu
  ];

  const BarChartContainerPrice = ({ data, title}) => {
    if (!Array.isArray(data) || data.length === 0) {
      return <div style={{ padding: "10px", color: colors.blue, fontWeight: "bold" }}>Veri bulunamadı</div>;
    }

    const formattedData = data.map(entry => ({
      ...entry,
      date: moment(entry.date).format('DD-MM-YYYY')
    }));


    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        <h3 style={{ backgroundColor: "white", color: colors.blue, marginTop: "80px", textAlign: "left" }}>
          {title}
        </h3>

        <ResponsiveContainer width="100%" height={500}>
          <BarChart data={formattedData} margin={{ top: 20, right: 30, left: 20, bottom: 50 }}>
            <XAxis dataKey="date" label={{ value: 'Tarih', position: 'insideBottom', offset: -10 }} />

            {isVisible && <YAxis label={{ value: 'Fiyat (TL)', angle: -90, position: 'insideLeft' }} />}
            
            <Tooltip 
              formatter={(value, name) => {
                if (name === 'price') {
                  return isVisible ? `${value.toFixed(2)}TL` : "-";
                }
                return value;
              }} 
            />
            
            <Bar dataKey="price">
              {formattedData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };


  const prepareBarChartData = (records) => {
    const supplierCounts = records.reduce((acc, record) => {
      const { supplierName } = record;

      if (supplierName) {
        acc[supplierName] = (acc[supplierName] || 0) + 1;
      }
      return acc;
    }, {});

    const totalCount = Object.values(supplierCounts).reduce((sum, count) => sum + count, 0);

    const data = Object.keys(supplierCounts).map((supplierName) => ({
      name: supplierName, 
      value: supplierCounts[supplierName],
      percentage: (supplierCounts[supplierName] / totalCount) * 100, 
    }));

    return data;
  };


  const setDateRange = (months) => {
    const end = new Date(); 
    const start = new Date();
    start.setMonth(end.getMonth() - months); 
    
   
    console.log("start",start)
    console.log("end",end)

    setStartDate(start.toISOString().split("T")[0]); 
    setEndDate(end.toISOString().split("T")[0]);
  };

  const handleChange = (event) => {
    const value = event.target.value; 
    setSelectedRange(value); 
  
    if (value === "") {
      setDateRange(null); 
      return; 
    }

    const numericValue = parseInt(event.target.value, 10);
    setSelectedRange(numericValue);
    setDateRange(numericValue);
  };

  const goEditPage = (record) => {
    navigate('/buyerpage', { state: { record } })
  };

  const handlePrint = () => {
    const printSection = document.getElementById("print-section");
  
    if (!printSection) {
      console.error("Print section not found!");
      return;
    }
  
    const printContents = printSection.innerHTML;
    const printWindow = window.open("", "_blank", "width=800,height=600");
  
    printWindow.document.write(`
      <html>
        <head>
          <title>Yazdır</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            .chart-item { margin: 20px; }
            h3 { color: ${colors.blue}; }
            /* Print için stil ayarları */
            @media print {
              .chart-item {
                width: 300px !important;
                margin: 10px !important;
              }
              .print-container {
                flex-direction: column;
                align-items: center;
              }
              @page {
                margin: 0.5cm;
              }
                .recharts-legend-wrapper{
                bottom:-60px !important;
              }

            }
          </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  };

  const handleQualityChange = (event) => {
    setSelectedQuality(event);
    const selectedChartData = chart3[event] || {};
    const chartData = selectedChartData.labels?.map((label, index) => ({
      date: label,
      price: selectedChartData.totalAmounts[index],
    }));
  
    setSelectedChart3(chartData)

  };
  
  const toggleAllVisibility = () => {
    setIsVisible(prevState => !prevState); // Burada, önceki durumu tersine çeviriyoruz.
  };
  

  return (
    <div style={{ textAlign: "center", minHeight: "94vh", padding: "3%" }}  id="print-section">
      <div style={{display:"flex", alignItems:"center"}}>

      <h3 style={{ backgroundColor: "white", color: colors.blue, fontWeight: "700", border: "none", textAlign: "left" }}>
      Satın Alma Raporlamaları	
        </h3>
        <Button
          shape="circle"
          icon={isVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}  
          onClick={toggleAllVisibility}
          style={{
            backgroundColor: 'transparent',
            border: '2px solid',
            borderColor: colors.blue,
            cursor: 'pointer',
            marginLeft: '20px'
          }} 
        />
     </div>

      <div style={{display:"flex", float:"right"}}>
      <div style={{ marginBottom: "20px" , display:"block"}}>
        <label>Başlangıç Tarihi: </label>
        <DatePicker     placeholder="Başlangıç Tarihi"   className="input-style"  selected={startDate} onChange={(date) => setStartDate(date)} />
        <label style={{ marginLeft: "20px" }}>Bitiş Tarihi: </label>
        <DatePicker     placeholder="Bitiş Tarihi"   className="input-style"  selected={endDate} onChange={(date) => setEndDate(date)} />
      </div>
     
      <select value={selectedRange} onChange={handleChange}     className="input-style"   style={{ height:"35px", marginLeft:"50px", color:colors.blue,}}>
        <option value="">Tarih Aralığı Seç</option>
        <option value="1">Son 1 Ay</option>
        <option value="3">Son 3 Ay</option>
        <option value="6">Son 6 Ay</option>
        <option value="12">Son 1 Yıl</option>
      </select>

      </div>
      <div style={{marginTop:"100px"}}>
        
        <BarChartContainer data={chart1} title="Birim Bazlı Satın Alma Talepleri" />
          {isVisible &&
          <>
          <div style={{fontWeight:"bold"}}>Birim: Adet</div>
          <div style={{ marginTop: "20px", textAlign: "center", display:"flex", justifyContent:"space-evenly" }}>
            {chart1.map((entry, index) => {
              const value = entry.value;
              return (
                <div key={`label-${index}`} style={{ marginBottom: "10px", color: COLORS[index % COLORS.length] }}>
                  <strong>{entry.name}: </strong> {value}
                </div>
              );
            })}
          </div>
          </>
          }
       
      </div>
    
    <div style={{marginTop:"50px"}}>

      <BarChartContainer data={chart2} title="Tedarikçiler" />
     
      {isVisible &&
          <>
          <div style={{fontWeight:"bold"}}>Tedarikçi: Adet </div>
          <div style={{ marginTop: "20px", textAlign: "center", display: "flex", justifyContent: "space-evenly" }}>
      {chart2.map((entry, index) => {
        const count = entry.value;
        return (
          <div key={`label-${index}`} style={{ marginBottom: "10px", color: COLORS[index % COLORS.length] }}>
            <span style={{ marginLeft: '10px' }}>
              {entry.name}: {count}
            </span>
          </div>
        );
      })}
    </div>
          </>
          }

     
     
    </div>

    <div>
    <Select
        showSearch
        onChange={handleQualityChange}
        placeholder="Kalite Seçiniz"
        className="input-style" 
        suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
        options={[...qualities.map(item => ({ label: item.quality, value: item.quality }))]}
        filterOption={(input, option) => 
           option.label.toLowerCase().includes(input.toLowerCase()) 
         }
         style={{marginTop:"20px"}}
        />
   
      <BarChartContainerPrice data={selectedChart3} title="Fiyat Değişimi" />



    </div>
      <div style={{float:"right"}}>


      <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",border:"none", }}>
          Detay Satın Alma Ekranı
       </Button>
       <Button  
         onClick={handlePrint} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",border:"none",marginLeft:"10px"}}>
         Yazdır
       </Button>
       </div>
    </div>
  );
};



export default PurchasingReportsList;