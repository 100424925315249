import React, { useEffect, useState } from "react";
import { Button, Form, Select } from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import { months, years } from "../../../environments/environment";
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ExpenseAdd = ({ onClose }) => {
    const navigate = useNavigate();
    const [existingExpenses, setExistingExpenses] = useState([]);

    useEffect(() => {
      const fetchExpenses = async () => {
        const data = await FirebaseService.getExpensesData(); 
        const filteredData = data.filter(expense => !expense.isDeleted)
        setExistingExpenses(filteredData);
      };
      fetchExpenses();
    }, []);

    const onFinish = (values) => {
      const record = {
        year: values.year,
        month: values.month
      };

      // Mevcut kayıtlarla eşleşme kontrolü
      const isDuplicate = existingExpenses.some(expense => 
        expense.year === record.year && expense.month === record.month
      );

      if (isDuplicate) {
        NotificationService.openErrorNotification({
          title: 'Geçersiz İşlem',
          description: 'Bu ay ve yıl için zaten bir kayıt mevcut.',
          placement: 'topRight'
        });
        return; // Aynı kayda izin verme
      }

      // Eşleşme yoksa devam et
      navigate('/expenseaddcontinue', { state: { record: record } });
    };

    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({
        title: 'Geçersiz Form',
        description: 'Lütfen zorunlu alanları doldurunuz.',
        placement: 'topRight'
      });
    };

    return (
      <div>
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          style={{ maxWidth: "100%", padding: "5% 0 0 5%" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Ay"
            name="month"
            rules={[{ required: true, message: 'Lütfen seçiniz!' }]}
            style={{ right: "40px", position: "relative" }}
          >
            <Select
              showSearch
              placeholder="Ay Seçiniz"
              className="select-style"
              options={months.map(item => ({ label: item.label, value: item.value }))}
            />
          </Form.Item>

          <Form.Item
            label="Yıl"
            name="year"
            rules={[{ required: true, message: 'Lütfen seçiniz!' }]}
            style={{ right: "40px", position: "relative" }}
          >
            <Select
              showSearch
              placeholder="Yıl Seçiniz"
              className="select-style"
              options={years.map(item => ({ label: item.label, value: item.value }))}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 15, span: 18 }}>
            <Button htmlType="submit" style={{ backgroundColor: "#1A446C", color: "white", fontWeight: "500" }}>Devam Et</Button>
          </Form.Item>
        </Form>
      </div>
    );
};

ExpenseAdd.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

export default ExpenseAdd;
