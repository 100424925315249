import React,{useEffect,useState} from "react";
import { Table, Button,Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import  { colors}  from "../../../../environments/environment"
import moment from 'moment';
import { or } from "firebase/firestore";


const OpenOrderGroupListLimitedDelete = () => {
  const [items, setItems] = useState([]);
  const [itemsLimited, setItemsLimited] = useState([]);

  const groupByJobCode = (data) => {
    console.log("data", data);
    return data.reduce((groupedData, item) => {
      const { jobCode, companyName, projectCode, deadline, ...rest } = item;
      const newItem = { jobCode, companyName, projectCode, deadline, ...rest };
  
      const jobCodePrefix = jobCode.slice(0, 5);
  
      if (!groupedData[jobCodePrefix]) {
        groupedData[jobCodePrefix] = {
          companyName,
          jobCode: jobCodePrefix, 
          projectCode,
          deadline,
          data: [newItem]
        };
      } else {
        groupedData[jobCodePrefix].data.push(newItem);
      }
      
      return groupedData;
    }, {});
  };
  
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getOpenOrderData();
      const filteredData = data.filter(item => !item.isDeleted && !item.isWaiting && item.isSetup && item.orderType === "order");

      const processedData = filteredData.map(item => {
          let statusText = "Bilinmiyor";
          const combinedList = [...item.stock, ...item.wastage];
          const allQrScansFalse = combinedList.every(item => !item.isQrScan);
          const allQrScansTrue = combinedList.every(item => item.isQrScan);
          const anyQrScanTrue = combinedList.some(item => item.isQrScan);

          if (allQrScansFalse) {
              statusText = "Depoda";
          } else if (allQrScansTrue) {
              statusText = "Depodan Çıktı";
          } else  {
              statusText = "Kısmı Çıkış";
          }
      
          return { ...item, statusText };
      });
      

    const sortedRecords = processedData.sort((a, b) => {
      const dateA = a.deadline && a.deadline.length > 0 ? new Date(a.deadline[0].seconds * 1000) : new Date(0);
      const dateB = b.deadline && b.deadline.length > 0 ? new Date(b.deadline[0].seconds * 1000) : new Date(0);
      return dateA - dateB; 
    });


      const groupedItems = Object.values(groupByJobCode(sortedRecords));
      const limitedData = groupedItems.slice(0, 5); 
      setItemsLimited(limitedData)
      setItems(groupedItems)
    };
    fetchData();
  }, []);

  const goEditPage = (record) => {
    navigate('/openorderdetail', { state: { record } })
  };


  const goPage = (record) => {
    navigate('/openordergrouplistdelete', { state: { record } })
  };

  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text === 'raw' ? 'Saç Levha' : text === 'other' ? 'Diğer' : text}
      </span>,
    }, 
      
   
  ];

  return  <div style={{  
       // width:"50%",
       
      }}
      className="limited-list-border">
      <Button onClick={()=>goPage(items)} className="title-limited-list">
         Açık Siparişler
       </Button>

       <Table
            locale={{
              emptyText: 'Henüz  açık sipariş bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={itemsLimited}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
      
     
    </div>

  };
  export default OpenOrderGroupListLimitedDelete;

    