import React, { useEffect, useState } from "react";
import { Table, Input, Button, Space,Modal,Form,DatePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { colors, parsCollections, placeholderMap } from "../../../environments/environment";
import { useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import NotificationService from "../../../services/antNotificationService";

const ReturnRawList = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [visible, setVisible] = useState(false);
  const [selectedDeadline, setSelectedDeadline] = useState('');
  const [selectedRecord, setSelectedRecord] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getReturnRawData();
    //  const filteredData = data.filter(item => !item.isDeleted);
      
      const updatedData = data.map(item => {
        const updatedType = item.type === 'futureRaw' ? 'gelecek hammadde' : 'hammadde girişi';
        return { ...item, type: updatedType };
      });


      const sortedItems = updatedData.sort((a, b) => {
        const dateA = new Date(a.firstTransactionDate);
        const dateB = new Date(b.firstTransactionDate);
        return dateB - dateA; 
        });

      setItems(sortedItems);
      setFilteredItems(sortedItems);
    };
    
    fetchData();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase(); 
      const itemValue = item[dataIndex]?.toString().toLowerCase(); 
      return (
        !item.isDeleted &&
       
        (value ? itemValue.includes(value) : true) 
      );
    });
    setFilteredItems(filteredData);
  };
  
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };



  const getColumnSearchProps = dataIndex => ({
 
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
     <Input
    id="search-input"  
    placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
    value={selectedKeys[0]}
    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    style={{ width: 188, marginBottom: 8, display: 'block' }}
    />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90,background:colors.blue }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });

  const [modalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const columns = [
    {
      title: 'Firma Adı',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      width: 200,
      ...getColumnSearchProps('companyName'),
      sorter: (a, b) => {
        const companyNameA = a && a.companyName ? a.companyName : '';
        const companyNameB = b && b.companyName ? b.companyName : '';
        return companyNameA.localeCompare(companyNameB);
      }
      
    },
    {
      title: 'Ürün Adı',
      dataIndex: '',
      key: '',
      width: 200,
      render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text.quality} - {text.surface}- {text.thickness}mm {text.width}x{text.height}</span>,
    },
    {
      title: 'Durum',
      dataIndex: '',
      key: '',
      render: (text, record) => <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>
        {record.isSendFutureRaw ? "Tarih Değiştirildi" : ""}
        {record.isSendPurchaseRequest ? "Satın Alma Talebi Gönderildi" : ""}

      </span>,
    },
    
    
      {
        title: 'İş Kodu',
        dataIndex: 'jobCode',
        key: 'jobCode',
        render: (text, record) => <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
        width: 200,
        ...getColumnSearchProps('jobCode'),
      },
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text, record) => <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
        width: 200,
        ...getColumnSearchProps('piece'),
        sorter: (a, b) => a.piece - b.piece,

      },
    
    
      {
        title: 'İade Türü',
        dataIndex: 'type',
        key: 'type',
        render: (text, record) => <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
        width: 200,
        ...getColumnSearchProps('type'),
        sorter: (a, b) => {
          const typeA = a && a.type ? a.type : '';
          const typeB = b && b.type ? b.type : '';
          return typeA.localeCompare(typeB);
        }

      },
    

  
  ];


  function generateRandomCode(length) {
    const characters = '0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    return result;
  }


  const handleSendPurchaseRequest = async (record) => {
  
    console.log("recrd",record)

    const combinedValues = {
      id:record.stockId ? record.stockId : null,
      jobCode:record.jobCode,
      piece:record.piece,
      quality:record.quality,
      surface:record.surface,
      height:record.height,
      width:record.width,
      thickness:record.thickness,
      description: null,
      code:record.code,
      minPiece:record.minPiece
    }
    const data = {
      companyName: record.companyName,
      productType: "raw",
      unitName: "yönetici",
      deadline:record.firstTransactionDate,
      requestedProductInfo: [combinedValues], // combinedValues bir dizi içinde
      checkedRequestedProductInfo:null,
      offerJobCode: generateRandomCode(5),
      deadlineForPayment:null,
      paymentMethod:null,
      mailList:null,
      sentExcelList:null,
      incomingExcelList:null,
      acceptExcelDocumentUrl:null  ,
      description:"İade edilen ürün için talep edilmiştir.",
      isSendSetup: false
    };

    console.log("data",data)


    
    try {
      const result = await FirebaseService.addPurchaseRequest(data);
      if (result.success) {

        let updateItem = {
          isSendPurchaseRequest:true,
        };

        const resultx = await FirebaseService.updatePurchaseRequestForIs(record.key, updateItem);
      //  console.log(resultx);
        if(resultx){
         
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description:"Başarıyla satın alma talebi gönderilmiştir.",
            placement: 'topRight'
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
         
          
        }
        else{
          
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
            placement: 'topRight'
          });
          
        }  
       

      } 
      else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
      }
      
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
    }
    
  
    

  
  
  }; 
  
  const handleSendFutureRaw = async () => {
   // console.log("Deadline: ", selectedDeadline);

  //  console.log("handleSendFutureRaw selectedRecord",selectedRecord)

   const currentDate = new Date();
    const data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
            quality: selectedRecord.quality,
            thickness: selectedRecord.thickness,
            width: selectedRecord.width,
            height: selectedRecord.height,
            surface: selectedRecord.surface,
            piece: selectedRecord.piece,
            jobCode: selectedRecord.jobCode ? selectedRecord.jobCode : null, 
            unitKilo: selectedRecord.unitKilo,
            totalKilo: selectedRecord.unitKilo * selectedRecord.piece,
            stockId : selectedRecord.stockId ?  selectedRecord.stockId :null,
            supplierName: selectedRecord.supplierName,
            offerJobCode: selectedRecord.offerJobCode,
            deadline: new Date(selectedDeadline), 
            companyName: selectedRecord.companyName,
            problemDescription: null,
            problemImageUrl: null,
            problemDate: null,
            isContinue: true,
            code:selectedRecord.code,
            minPiece:selectedRecord.minPiece
    };
    
  //  console.log("Data", data);
    
    try {
      const result = await FirebaseService.addRecording(parsCollections.futureRaw, data);
      if (result.success) {

        let updateItem = {
          isSendFutureRaw:true,
        };

        const resultx = await FirebaseService.updatePurchaseRequestForIs(selectedRecord.key, updateItem);
    //    console.log(resultx);
        if(resultx){
        
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description:"Gelecek hammadeye başarıyla gönderilmiştir.",
            placement: 'topRight'
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        
          
        }
        else{
          
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
            placement: 'topRight'
          });
          
        }  
      

      } 
      else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
      }
      
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
    }

    

  }; 
  const handleDeadline = async (record) => {
  // console.log("record",record)
    setVisible(true);
    setSelectedRecord(record);

  }
  
   const onChange = (value) => {
    setSelectedDeadline(value);
  };

  const handleCancel = () => {
    setVisible(false);
  };


  return <div style={{
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '94vh',
    padding: "3%",
  }}>
    <h3 style={{ textAlign: "left", color: colors.blue }}>İade Edilen Hammaddeler</h3>

    <Table
     
      locale={{
        emptyText: 'Henüz iade bulunmamaktadır...',
        filterReset: 'Sıfırla',
        filterTitle: 'Filtre Menüsü',
        selectAll: 'Hepsini Seç',
        selectInvert: 'Tersini Seç',
        selectionAll: 'Tümünü Seç',
        sortTitle: 'Sıralama',
        triggerDesc: 'Azalan sıralama için tıklayın',
        triggerAsc: 'Artan sıralama için tıklayın',
        cancelSort: 'Sıralamayı iptal etmek için tıklayın',
      }}
      dataSource={filteredItems}
      columns={columns}
      showHeader={true}
      pagination={false}
      className="custom-news-table"
      rowClassName="custom-news-row"
      expandable={{
        expandedRowRender: record => (
          <div style={{ display: 'flex' }}>
          {/* Sol Parça */}
          <div style={{ flex: 1, marginLeft: "55px" }}>
            <div style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Açıklama:</span>
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                {record.description ? record.description : "-"}
              </span>
            </div>
           
          </div>
        
          {/* Orta Parça */}
          <div style={{ flex: 1, marginLeft: "55px" }}>
            <div style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Tarih:</span>
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                {record && record.firstTransactionDate ? (
                  isNaN(Date.parse(record.firstTransactionDate)) ? (
                    "Tarih formatı hatalı"
                  ) : (
                    new Date(record.firstTransactionDate).toLocaleDateString('tr-TR')
                  )
                ) : (
                  "Tarih bulunamadı"
                )}
              </span>
            </div>
          </div>
        
          {/* Sağ Parça */}
         
          {record.imageUrl && (
              <div style={{ flex: 1, marginLeft: "55px" }}>
                <Button
                    disabled={record.isDeleted}
                    style={{ 
                      float: "right", 
                      color: "white", 
                      backgroundColor: record.isDeleted ? "#00000080" : colors.blue,
                      border: 'none',
                      borderRadius: '5px',
                    }}
                    onClick={handleOpenModal}
                  >
                    <img  alt="" src="/images/camera.svg" width={20} height={25}/>
                  </Button>

                  <Modal
                    title="İade Görseli"
                    visible={modalVisible}
                    onCancel={handleCloseModal}
                    footer={null}
                  >
                    {record.imageUrl && <img src={record.imageUrl} alt="Resim" style={{ width: '400px', height: '500px', objectFit:"contain" }} />}
                  </Modal>

              </div>
            )}


                  <Button 
                    onClick={()=> handleSendPurchaseRequest(record)} 
                    disabled = {record.isSendPurchaseRequest ||  record.isSendFutureRaw ? true : false}
                    style={{float:"right",color:"white" ,fontWeight:"500",  
                    backgroundColor:
                     record.isSendPurchaseRequest ? "#00000080" : colors.blue ,
                      display: record.type === "hammadde girişi" ? "none" : "flex "
                    }} 
                    >
                      {record.isSendPurchaseRequest ? "Satın Alma Talebi Gönderildi" : "Satın Alma Talebi Gönder"}
                  </Button>

                  <Button 
                    onClick={()=> handleDeadline(record)} 
                    disabled = {record.isSendPurchaseRequest ||  record.isSendFutureRaw ? true : false}
                    style={{float:"right",color:"white" ,fontWeight:"500",  
                    backgroundColor:
                      record.isSendFutureRaw ? "#00000080" : colors.blue ,
                      display: record.type === "hammadde girişi" ? "none" : "flex "
                    }} 
                    >
                      {record.isSendFutureRaw ? "Tarih Değiştirildi" : "Tarih Değiştir"}
                  </Button>


        </div>
        
      ),
      
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/uparrow.png" /></a>
          ) : (
            <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" /></a>
          ),
          expandIconColumnIndex: 6
      }}
    />

              <Modal
                  title="Teslim edilecek tarihi seçiniz"
                  visible={visible}
                  onOk={()=> handleSendFutureRaw(record)} 
                  onCancel={handleCancel}
                  className="popup-modal-button"
                  okText="Devam Et"      
                  cancelText="İptal"  
              >
              <div> 
              <Form.Item
              name="deadline"
              >
                <DatePicker style={{ width: '100%' }} placeholder="Teslim Tarihi" format="DD-MM-YYYY"  onChange={onChange}/>
             </Form.Item>

            </div>
             </Modal>


  </div>
};

export default ReturnRawList;
