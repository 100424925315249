import React, { useEffect, useState } from "react";
import { Table, Button, Spin } from 'antd';  
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";

const OpenOrderGroupListLimited = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [itemsLimited, setItemsLimited] = useState([]);
  const [loading, setLoading] = useState(true);  

  const groupByJobCode = (data) => {
    return data.reduce((groupedData, item) => {
      const { jobCode, companyName, projectCode, deadline, ...rest } = item;
      const newItem = { jobCode, companyName, projectCode, deadline, ...rest };

      const jobCodePrefix = jobCode.slice(0, 5);

      if (!groupedData[jobCodePrefix]) {
        groupedData[jobCodePrefix] = {
          companyName,
          jobCode: jobCodePrefix,
          projectCode,
          deadline,
          data: [newItem]
        };
      } else {
        groupedData[jobCodePrefix].data.push(newItem);
      }

      return groupedData;
    }, {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      setLoading(true); 
      const data = await FirebaseService.getOpenOrderData();
      const filteredData = data.filter(item => !item.isDeleted && !item.isWaiting && item.isSetup && item.orderType === "order");

      const processedData = filteredData.map(item => {
        let statusText = "Bilinmiyor";
        const combinedList = [...item.stock, ...item.wastage];
        const allQrScansFalse = combinedList.every(item => !item.isQrScan);
        const allQrScansTrue = combinedList.every(item => item.isQrScan);
        const anyQrScanTrue = combinedList.some(item => item.isQrScan);

        if (allQrScansFalse) {
          statusText = "Depoda";
        } else if (allQrScansTrue) {
          statusText = "Depodan Çıktı";
        } else {
          statusText = "Kısmı Çıkış";
        }

        return { ...item, statusText };
      });

      const sortedRecords = processedData.sort((a, b) => {
        const dateA = a.deadline && a.deadline.length > 0 ? new Date(a.deadline[0].seconds * 1000) : new Date(0);
        const dateB = b.deadline && b.deadline.length > 0 ? new Date(b.deadline[0].seconds * 1000) : new Date(0);
        return dateA - dateB;
      });

      const groupedItems = Object.values(groupByJobCode(sortedRecords));

      setItems(groupedItems);
      setItemsLimited(groupedItems);
      setLoading(false); 
    };

    fetchData();
  }, []);

  const columns = [
    {
      title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}> {text.substring(0, 5)}</span>,
    },
    {
      title: 'Proje Kodu',
      dataIndex: 'projectCode',
      key: 'projectCode',
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
    },
    {
      title: 'Termin Tarihi',
      dataIndex: 'deadline',
      key: 'deadline',
      render: (text, record) => {
        const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
        return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
      },
    },
  ];

  const goPage = (record) => {
    if (items && items.length > 0) {
      navigate('/openordergrouplist', { state: { record } });
    } else {
      NotificationService.openErrorNotification({
        title: "Hata",
        description: "Veriler henüz yüklenmedi.",
      });
    }
  };

  return (
    <div className="limited-list-border">
      <Button onClick={() => goPage(items)} className="title-limited-list">
        Açık Siparişler
      </Button>

      {/* Loading göstergesi */}
      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',  // Yarı saydam arka plan
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,  // Diğer öğelerin önünde görünsün
        }}>
          <Spin size="large" tip="Veriler yükleniyor..." />  {/* Büyük loading göstergesi */}
        </div>
      )}

      {/* Tablo gösterimi */}
      <Table
        locale={{
          emptyText: 'Henüz eklemediniz...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
        dataSource={itemsLimited}
        columns={columns}
        showHeader={true}
        pagination={false}
        className="custom-news-table"
        rowClassName="custom-news-row"
      />
    </div>
  );
};

export default OpenOrderGroupListLimited;
