import React, { useEffect, useState } from "react";
import { Table, Button, Spin, Input, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";

const CompanyList = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const data = await FirebaseService.getCompaniesData();
      const filteredData = data.filter(item => !item.isDeleted);
      setItems(filteredData);
      setFilteredItems(filteredData);
    };
    fetchData();
  }, []);

  const goAddPage = () => {
    navigate('/companyadd');
  };

  const goEditPage = (record) => {
    navigate('/companyedit', { state: { record } });
  };

  const handleDelete = async (record, isDeleted) => {
    setLoading(true);
    try {
      let result;
      if (isDeleted) {
        result = await FirebaseService.makeCompanyVisible(record.key);
      } else {
        result = await FirebaseService.deleteCompany(record.key);
      }

      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
        setLoading(false);
        const data = await FirebaseService.getCompaniesData();
        const filteredData = data.filter(item => !item.isDeleted);
        setItems(filteredData);
        setFilteredItems(filteredData);
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
        setLoading(false);
      }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
      setLoading(false);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase();
      const itemValue = item[dataIndex]?.toString().toLowerCase();
      return (
        !item.isDeleted &&
        (value ? itemValue.includes(value) : true)
      );
    });
    setFilteredItems(filteredData);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
    sorter: (a, b) => a[dataIndex].localeCompare(b[dataIndex]),
  });

  const columns = [
    {
      title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getColumnSearchProps('companyName'),
    },
    {
      title: 'Vergi Numarası',
      dataIndex: 'vkn',
      key: 'vkn',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getColumnSearchProps('vkn'),
    },
    {
      title: 'Vergi Dairesi',
      dataIndex: 'taxAdministration',
      key: 'taxAdministration',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getColumnSearchProps('taxAdministration'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Adres',
      dataIndex: 'address',
      key: 'address',
      width: 300,
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getColumnSearchProps('address'),
    },
    {
      title: 'Ödeme Vaadesi',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getColumnSearchProps('paymentMethod'),
    },
    {
      title: 'Güncelle',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <a onClick={() => goEditPage(record)}>
          <img style={{ width: "20px" }} alt="edit" src="/images/newEditButton.png" />
        </a>
      ),
    },
    {
      title: ' ',
      dataIndex: 'delete',
      key: 'delete',
      render: (text, record) => (
        record.isDeleted ?    
          <Button loading={loading} onClick={() => handleDelete(record, true)}>Göster</Button>
        :
          <Button loading={loading} onClick={() => handleDelete(record, false)}>Sil</Button>
      ),
    }
  ];

  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '94vh',
      padding: "3%"
    }}>
      <div style={{ marginBottom: '20px' }}>
        <Button onClick={goAddPage} icon={<img alt="plus" src="/images/plusbutton.png" style={{ width: "60%" }} />} className='admin-add-button'>
          Yeni Ekle
        </Button>
      </div>
      <Table
        locale={{
          emptyText: 'Henüz firma eklemediniz...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
        dataSource={filteredItems}
        columns={columns}
        showHeader={true}
        pagination={false} 
        className="custom-news-table"
        rowClassName="custom-news-row"
      />
      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}>
          <Spin />
        </div>
      )}
    </div>
  );
};

export default CompanyList;
