import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors, placeholderMap}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';


const OpenOrderList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  console.log("open order list record",record)
  const [items, setItems] = useState();
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
     
      setItems(record.data);
      setFilteredItems(record.data);
    };


    fetchData();
    
  }, [record]);

  const goEditPage = (record) => {
    navigate('/openorderdetail', { state: { record } })
  };

  const goDelete = async(record) => {

    setLoading(true)
    console.log("delete",record);
    let success = true; 

    const allOpenOrder = await FirebaseService.getOpenOrderData();
    const filteredOpenOrder = allOpenOrder.filter(item => !item.isDeleted && item.jobCode===record.jobCode);
    
    const ordersWithAllItemsQrScanFalse = filteredOpenOrder.filter(order => {
        const stockItems = order.stock || [];
        const wastageItems = order.wastage || [];
    
        const allStockItemsQrScanFalse = stockItems.every(item => item.isQrScan === false);
        const allWastageItemsQrScanFalse = wastageItems.every(item => item.isQrScan === false);
    
        return allStockItemsQrScanFalse && allWastageItemsQrScanFalse;
    });
    
    console.log("ordersWithAllItemsQrScanFalse", ordersWithAllItemsQrScanFalse);
    if (ordersWithAllItemsQrScanFalse.length === 0) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bu açık sipariş silinemez.",
        placement: 'topRight'
      });
      setLoading(false)
      return;
    }
    else{
      let combinedStockItems = [];

      ordersWithAllItemsQrScanFalse.forEach(order => {
          order.stock.forEach(stockItem => {
              const existingItemIndex = combinedStockItems.findIndex(item => item.id === stockItem.id);
      
              if (existingItemIndex !== -1) {
                  combinedStockItems[existingItemIndex].piece += stockItem.piece;
              } else {
                  combinedStockItems.push({ ...stockItem });
              }
          });
      });
      
      console.log("Combined Stock Items:", combinedStockItems);
      
      //stock listesindekileri update etmek için
      async function updateCombinedStockItems(stockItems) {
        for (const item of stockItems) {
            const { id, piece,isSendPurchase } = item;
            if(isSendPurchase){
              console.log("satın alma talebi gitmiş waiting değişcek");
              const resultPurchase = await FirebaseService.updateStockWaitingPieceRemove(id, piece);
              console.log("Result for item", id, ":", resultPurchase);
            }
            else{
              console.log("reserve değişcek");
              const result = await FirebaseService.updateStockReservedPieceRemove(id, piece);
              console.log("Result for item", id, ":", result);
  
            }
        }
      }
      updateCombinedStockItems(combinedStockItems);
      let combinedWastageItems = [];
  
      ordersWithAllItemsQrScanFalse.forEach(order => {
          order.wastage.forEach(wastageItem => {
              const existingItemIndex = combinedWastageItems.findIndex(item => item.id === wastageItem.id);
  
              if (existingItemIndex !== -1) {
                combinedWastageItems[existingItemIndex].piece += wastageItem.piece;
              } else {
                combinedWastageItems.push({ ...wastageItem });
              }
          });
      });
  
      console.log(" combinedWastageItems Items:",combinedWastageItems);
  
      //wastage listesindekileri update etmek için
      async function updateCombinedWastageItems(wastageItems) {
        for (const item of wastageItems) {
            const { id, piece } = item;
            const result = await FirebaseService.updateWastageReservedPieceRemove(id, piece);
            console.log("Result for item", id, ":", result);
        }
      }
      updateCombinedWastageItems(combinedWastageItems);
  
      //açık siparişler silme kısmı
     
      for (const item of ordersWithAllItemsQrScanFalse) {
        try {
          const result = await FirebaseService.deleteOpenOrderDataIsDeleted(item.key);
          if (result) {
            console.log(`Item with key ${item.key} deleted successfully.`);
          } else {
            success=false;
            console.log(`Failed to delete item with key ${item.key}.`);
          }
        } catch (error) {
          success=false;
          console.error(`Error deleting item with key ${item.key}:`, error);
        }
      }
  
    //setup silme kısmı
      const setupData = await FirebaseService.getSetupData();
      const filteredSetup = setupData.filter(item => !item.isDeleted && item.jobCode===record.jobCode);
      console.log("filteredSetup",filteredSetup)
      
      for (const item of filteredSetup) {
        try {
          const result = await FirebaseService.deleteSetupData(item.key);
          if (result) {
            console.log(`Item with key ${item.key} deleted successfully.`);
          } else {
            success=false;
            console.log(`Failed to delete item with key ${item.key}.`);
          }
        } catch (error) {
          success=false;
          console.error(`Error deleting item with key ${item.key}:`, error);
        }
      }
    
    //iş kodu silme kısmı
      const jobCodes = await FirebaseService.getJobCodesData();
      const filteredJobCodes = jobCodes.filter(item => !item.isDeleted  && item.item===record.jobCode);
      console.log("filteredJobCodes",filteredJobCodes)
      
      for (const item of filteredJobCodes) {
        try {
          const result = await FirebaseService.deleteJobCodeData(item.key);
          if (result) {
            console.log(`Item with key ${item.key} deleted successfully.`);
          } else {
            success=false;
            console.log(`Failed to delete item with key ${item.key}.`);
          }
        } catch (error) {
          success=false;
          console.error(`Error deleting item with key ${item.key}:`, error);
        }
      }
  
      //fason üretim silme 
      const processes = await FirebaseService.getContractManufacturingData();
      console.log("processes",processes)
      const filteredProcesses = processes.filter(item => {
        return !item.isDeleted && item.jobCode && item.jobCode===record.jobCode;
      });

      console.log("filteredProcesses",filteredProcesses)
      
      for (const item of filteredProcesses) {
        try {
          const result = await FirebaseService.deleteContractManufacturingData(item.key);
          if (result) {
            console.log(`Item with key ${item.key} deleted successfully.`);
          } else {
            success=false;
            console.log(`Failed to delete item with key ${item.key}.`);
          }
        } catch (error) {
          success=false;
          console.error(`Error deleting item with key ${item.key}:`, error);
        }
      }
  
        //satın alma talebi silme 
        const purchaseRequests = await FirebaseService.getPurchaseRequestData();
        console.log("purchaseRequests", purchaseRequests);
        
        const filteredPurchaseRequests = purchaseRequests.filter(item => {
            return !item.isDeleted && item.requestedProductInfo && item.requestedProductInfo.some(info => info.jobCode === record.jobCode);
        });
        
        console.log("filteredPurchaseRequests", filteredPurchaseRequests);
        
        
        for (const item of filteredPurchaseRequests) {
          try {
            const result = await FirebaseService.deletePurchaseRequestData(item.key);
            if (result) {
              console.log(`Item with key ${item.key} deleted successfully.`);
            } else {
             success=false;
              console.log(`Failed to delete item with key ${item.key}.`);
            }
          } catch (error) {
            success=false;
            console.error(`Error deleting item with key ${item.key}:`, error);
          }
        }
    
    
        //part silme kısmı
        const partsData = await FirebaseService.getPartsData();
        const filteredPart = partsData.filter(item => {
          return item.jobCode===record.jobCode 
        });

        console.log("filteredPart",filteredPart)
        
        for (const item of filteredPart) {
          try {
            const result = await FirebaseService.deletePartData(item.key);
            if (result) {
              console.log(`Item with key ${item.key} deleted successfully.`);
            } else {
              success=false;
              console.log(`Failed to delete item with key ${item.key}.`);
            }
          } catch (error) {
            success=false;
            console.error(`Error deleting item with key ${item.key}:`, error);
          }
        }

  
      if (success) {
        NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: 'Açık sipariş başarıyla silindi.',
            placement: 'topRight'
        });setLoading(false)
        setTimeout(() => {
          navigate("/programmerpage");
        }, 2000);
    } else {
        NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: 'Açık sipariş silinemedi. Lütfen daha sonra tekrar deneyiniz',
            placement: 'topRight'
        });setLoading(false)
    
        
      
  
    };

    }

    
   
  }


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase(); 
      const itemValue = item[dataIndex]?.toString().toLowerCase(); 
      return (
        !item.isDeleted &&
       
        (value ? itemValue.includes(value) : true) 
      );
    });
    setFilteredItems(filteredData);
  };
  
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };

  const getColumnSearchProps = dataIndex => ({
    
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
     <Input
    id="search-input"  
    placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
    value={selectedKeys[0]}
    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    style={{ width: 188, marginBottom: 8, display: 'block' }}
    />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90,background:colors.blue }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });


  const columns = [
    {
      title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      ...getColumnSearchProps('companyName'),
      sorter: (a, b) => {
        const companyNameA = a && a.companyName ? a.companyName : '';
        const companyNameB = b && b.companyName ? b.companyName : '';
        return companyNameA.localeCompare(companyNameB);
      }
  },
  
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
       ...getColumnSearchProps('jobCode'),
       sorter: (a, b) => {
        const itemA = a && a.jobCode ? a.jobCode : '';
        const itemB = b && b.jobCode ? b.jobCode : '';
        return itemA.localeCompare(itemB);
      },
    },
    {
      title: 'Proje Kodu',
      dataIndex: 'projectCode',
      key: 'projectCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
       ...getColumnSearchProps('projectCode'),
       sorter: (a, b) => {
        const itemA = a && a.projectCode ? a.projectCode : '';
        const itemB = b && b.projectCode ? b.projectCode : '';
        return itemA.localeCompare(itemB);
      },
    },

    {
      title: 'Durum',
      dataIndex: 'statusText',
      key: 'statusText',
      render: (text, record) => 
      {
        let statusColor = "#000000";
        let statusText = "Unknown";
  
        return <span style={{ color: record.isDeleted ? "#00000080" : statusColor }}>{text}</span>;
      },
      sorter: (a, b) => {
        const itemA = a && a.statusText ? a.statusText : '';
        const itemB = b && b.statusText ? b.statusText : '';
        return itemA.localeCompare(itemB);
      },
      ...getColumnSearchProps('statusText'),


     
  },
   
  {
    title: 'Termin Tarihi',
    dataIndex: 'deadline',
    key: 'deadline',
    render: (text, record) => {
      const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
      return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
    },
    sorter: (a, b) => {
      const deadlineA = a.deadline ? a.deadline.seconds : 0;
      const deadlineB = b.deadline ? b.deadline.seconds : 0;
      return deadlineA - deadlineB;
  },
  
  },      
    {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
          Detayı Gör
       </Button>
        ),
      },
      {
        title: ' ',
        dataIndex: 'delete',
        key: 'delete',
        render: (text, record) => (
          <img
          src="/images/delete.svg"
            alt="Detayı Gör"
            onClick={() => goDelete(record)}
            style={{ cursor: 'pointer', width: '20px', height: '20px' }} 
          />
        ),
      }
  ];

 
  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Açık Siparişler</h3>
        <Input
  style={{
    borderRadius: 0,
    border: 'none',
    borderBottom: '1px solid #1A446C80',
    width: '100%',
    maxWidth: '400px',
    textAlign: 'left',
    outline: 'none',
  }}
  placeholder="İş Kodu Ara"
  suffix={
    <Space>
      <img width={15} alt="edit" src="/images/searchicon.png" />
    </Space>
  }
  onChange={(e) => {
    const searchValue = e.target.value.toLowerCase();
    
    if (searchValue.length === 0) {
      // Eğer searchValue temizlenirse, filteredItems'ı 'record.data' dizisine ayarlayın
      setFilteredItems(record.data); // record.data'ya doğrudan erişim
    } else {
      const filteredData = record.data.filter(item => {
        const jobCode = item.jobCode.toLowerCase();
        return jobCode.includes(searchValue);
      });
      setFilteredItems(filteredData);
    }
  }}
/>


        <Table
            locale={{
              emptyText: 'Henüz eklemediniz...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={filteredItems}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

    </div>

  };
  export default OpenOrderList;
 