import React, { useEffect, useState } from "react";
import { Table, Input, Button, Space, Modal, Form ,Spin} from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { colors, parsCollections, placeholderMap } from "../../../environments/environment";
import { useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import NotificationService from "../../../services/antNotificationService";

const InvoiceReturnRawList = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [visible, setVisible] = useState(false);
  const [selectedDeadline, setSelectedDeadline] = useState('');
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [form] = Form.useForm(); // Initialize form instance
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getReturnRawData();
    
      const withWaybill = [];
      const withoutWaybill = [];
    
      data.forEach(item => {
        if (item.deliveryKilo && item.waybillNumber) {
          withWaybill.push(item);
        } else {
          withoutWaybill.push(item);
        }
      });
    
      const sortedWithoutWaybill = withoutWaybill.sort((a, b) => {
        const dateA = new Date(a.firstTransactionDate);
        const dateB = new Date(b.firstTransactionDate);
        return dateB - dateA;
      });
    
      const sortedWithWaybill = withWaybill.sort((a, b) => {
        const dateA = new Date(a.firstTransactionDate);
        const dateB = new Date(b.firstTransactionDate);
        return dateB - dateA;
      });
      
      const sortedItems = [...sortedWithoutWaybill, ...sortedWithWaybill];
    
      setItems(sortedItems);
      setFilteredItems(sortedItems);
    };
    
    fetchData();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase();
      const itemValue = item[dataIndex]?.toString().toLowerCase();
      return (
        !item.isDeleted &&
        (value ? itemValue.includes(value) : true)
      );
    });
    setFilteredItems(filteredData);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, background: colors.blue }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });

  const columns = [
    {
      title: 'Firma Adı',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
     
      ...getColumnSearchProps('companyName'),
      sorter: (a, b) => {
        const companyNameA = a && a.companyName ? a.companyName : '';
        const companyNameB = b && b.companyName ? b.companyName : '';
        return companyNameA.localeCompare(companyNameB);
      }
    },
    {
      title: 'Ürün Adı',
      dataIndex: '',
      key: '',
    
      render: (text, record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{`${text.quality} - ${text.surface} - ${text.thickness}mm ${text.width}x${text.height}`}</span>,
    },
  
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text ? text : "-"}</span>,
     
      ...getColumnSearchProps('jobCode'),
    },
    {
      title: 'Adet',
      dataIndex: 'piece',
      key: 'piece',
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    
      ...getColumnSearchProps('piece'),
      sorter: (a, b) => a.piece - b.piece,
    },
  
    {
      title: 'Toplam Kg',
      dataIndex: 'totalKilo',
      key: 'totalKilo',
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text && text.toFixed(2)} Kg</span>,
  
      ...getColumnSearchProps('totalKilo'),
      sorter: (a, b) => a.totalKilo - b.totalKilo,
    },
    {
      title: ' ',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => {
        if (record.deliveryKilo && record.waybillNumber) {
          return null; // Don't render the button if deliveryKilo and waybillNumber are present
        }
  
        return (
          <Button 
            onClick={() => handleWayBill(record)}
            style={{ float: "right", color: "white", fontWeight: "500", backgroundColor: colors.blue }}
          >
            İrsaliye Kes
          </Button>
        );
      },
    }
  ];

  const handleWayBill = (record) => {
    setVisible(true);
    setSelectedRecord(record);
    form.resetFields(); // Reset form fields when modal is opened
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = async (values) => {
    setLoading(true)
    const newItemWithAdditionalProperties = {
      ...selectedRecord,
      deliveryKilo: Number(values.deliveryKilo),
      waybillNumber: Number(values.waybillNumber),
    };

    console.log("Yeni öğe:", newItemWithAdditionalProperties);
    try {
      const result = await FirebaseService.updateRecording(parsCollections.returnRaw,newItemWithAdditionalProperties.key,newItemWithAdditionalProperties);
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: "Başarıyla kaydedildi",
          placement: 'topRight'
        });
        setLoading(false)
        setVisible(false);
        setTimeout(() => {
          window.location.reload();
        }, 500); 
      
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });setLoading(false)
      }
      
      
    } catch (error) {
      console.log('Firebase Güncelleme Hatası:', error);
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });setLoading(false)
    }

  
  };

  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({ title: 'Geçersiz Form', description: 'Lütfen zorunlu alanları doldurunuz.', placement: 'topRight' });
  };

  return (
    <div style={{ justifyContent: 'center', alignItems: 'center', minHeight: '94vh', padding: "3%" }}>
      <h3 style={{ textAlign: "left", color: colors.blue }}>İade Edilen Hammaddeler</h3>
      <Table
        locale={{
          emptyText: 'Henüz iade bulunmamaktadır...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
        dataSource={filteredItems}
        columns={columns}
        showHeader={true}
        pagination={false}
        className="custom-news-table"
        rowClassName="custom-news-row"
        expandable={{
          expandedRowRender: record => (
            <div style={{ display: 'flex' }}>
               <div style={{ marginLeft: "55px" }}>
                <div style={{ marginBottom: "10px" }}>
                  <span style={{ fontWeight: "bold", color: colors.gray }}>Fatura Numarası:</span>   
                </div>
              </div>

              <div style={{ marginLeft: "55px" }}>
              <div style={{ marginBottom: "10px" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                  {record.waybillNumber && (
                    <div>                   
                      <span style={{  }}>{record.waybillNumber}</span>
                    </div>
                  )}
                </div>

              </div>
            </div>
            </div>
          ),
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/uparrow.png" /></a>
            ) : (
              <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" /></a>
            ),
            expandIconColumnIndex: 5
        }}
      />
      <Modal
        title="İrsaliye"
        visible={visible}
        onCancel={handleCancel}
        className="popup-modal-button"
        okText="Kaydet"
        cancelText="İptal"
        onOk={() => form.submit()} // Trigger form submission when "Kaydet" button is clicked
      >
        <Form
          form={form} // Use form instance
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 10}}
          style={{ maxWidth: "100%", padding: "5% 0 0 5%" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >

             <div style={{display:"flex", margin:"20px"}}>
                <div style={{ fontWeight: "bold", color: colors.blue , marginRight:"10px"}}>Toplam Kilo: </div>
               <div>{selectedRecord ? selectedRecord.totalKilo : ''} Kg</div> 
            </div>

          <Form.Item
            name="waybillNumber"
            label="İrsaliye Numarası"
            rules={[
              {
                required: true,
                message: 'Lütfen irsaliye numarası giriniz!',
              },
              {
                pattern: /^\d+(\.\d+)?$/,
                message: 'Lütfen geçerli bir irsaliye numarası giriniz.',
              },
            ]}
          >
            <Input className="input-style" style={{ width: "100%" }} placeholder="İrsaliye Numarası Giriniz" />
          </Form.Item>
          <Form.Item
            name="deliveryKilo"
            label="İrsaliye Kilosu"
            rules={[
              {
                required: true,
                message: 'Lütfen irsaliye kilosu giriniz!',
              },
              {
                pattern: /^\d+(\.\d+)?$/,
                message: 'Lütfen geçerli bir irsaliye kilosu giriniz.',
              },
            ]}
          >
            <Input className="input-style" style={{ width: "100%" }} placeholder="İrsaliye Kilosu Giriniz" />
          </Form.Item>
          
        </Form>
      </Modal>
      {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>
  );
};

export default InvoiceReturnRawList;
