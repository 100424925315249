import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';

const OpenOrderSortList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [items, setItems] = useState();


  useEffect(() => {
    
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getOpenOrderData();
      const filteredData = data.filter(item => !item.isDeleted && !item.isWaiting && item.isSetup && item.orderType === "order");
     const sortedItems = [...filteredData].sort((a, b) => a.priority - b.priority)
  
     const processedData = sortedItems.map(item => {
      let statusText = "Bilinmiyor";
      const combinedList = [...item.stock, ...item.wastage];
      console.log("combinedList",combinedList)
      const allQrScansFalse = combinedList.every(item => !item.isQrScan);
      console.log("allQrScansFalse",allQrScansFalse)
      const allQrScansTrue = combinedList.every(item => item.isQrScan);
      console.log("allQrScansTrue",allQrScansTrue)
      const anyQrScanTrue = combinedList.some(item => item.isQrScan);
      console.log("anyQrScanTrue",anyQrScanTrue)

      if (allQrScansFalse) {
          statusText = "Depoda";
      } else if (allQrScansTrue) {
          statusText = "Depodan Çıktı";
      } else  {
          statusText = "Kısmı Çıkış";
      }
  
      return { ...item, statusText };
  });
  
     setItems(processedData);
  };

    fetchData();
  }, []);

  const goEditPage = (record) => {
    navigate('/openorderdetail', { state: { record } })
  };
  const handleDragStart = (record) => {
    localStorage.setItem('draggedItem', JSON.stringify(record));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (dragIndex, dropIndex) => {
    const draggedItem = JSON.parse(localStorage.getItem('draggedItem'));
    const updatedItems = [...items];
    const draggedItemRemoved = updatedItems.splice(dragIndex, 1)[0];
    updatedItems.splice(dropIndex, 0, draggedItemRemoved);

    const updatedPriorityValues = updatedItems.map((item, i) => ({
        ...item,
        priority: i + 1,
    }));
    setItems(updatedPriorityValues);

    const updatedDocuments = updatedPriorityValues.map(item => ({
        id: item.key,
        priority: item.priority,
    }));
    FirebaseService.updateOpenOrderPriorityTable(updatedDocuments);
    
    localStorage.removeItem('draggedItem');
};




  const columns = [
    {
        title: 'Sırası',
        dataIndex: 'priority',
        key: 'priority',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text} </span>,
      },
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
    
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text === 'raw' ? 'Saç Levha' : text === 'other' ? 'Diğer' : text}
      </span>,
    },
    {
      title: 'Durum',
      dataIndex: 'statusText',
      key: 'statusText',
      render: (text, record) => {
          return <span style={{ color: record.isDeleted ? "#00000080" :  "#000000" }}>{text}</span>;
      },
  },
   
    {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
       
        render: (text, record) => {
          const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
          return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
        },
      },
      
         

    {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
          Detayı Gör
       </Button>
        ),
      },
      {
        title: ' ',
        dataIndex: '',
        key: '',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> 
        <img width={20} height={20} alt="logo" src="/images/sort.svg" />
      </span>,
      },
      

  ];

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Açık Siparişler</h3>
        <Input
        style={{
          borderRadius: 0,
          border: 'none',
          borderBottom: '1px solid #1A446C80',
          width: '100%',
          maxWidth: '400px',
          textAlign: 'left',
          outline: 'none',
        }}
        placeholder="İş Kodu Ara"
        suffix={
          <Space>
              <img width={15} alt="edit" src="/images/searchicon.png" />
          </Space>
        }
        onChange={(e) => {
          const searchValue = e.target.value.toLowerCase();
          if(searchValue.length === 0){
            setItems(items);
          }
          else {
            const filteredData = items.filter(item => {
              const jobCode = item.jobCode.toLowerCase() ;
              return jobCode.includes(searchValue);
            });
            setItems(filteredData);
          }
         
        }}
      />

        <Table
            locale={{
              emptyText: 'Henüz açık sipariş bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
            rowKey="id"
            onRow={(record, index) => ({
            
              index,
              draggable: true,
              onDragStart: () => handleDragStart(record),
              onDragOver: (e) => handleDragOver(e),
              onDrop: () => handleDrop(index),
            })}
        />
        <div style={{color:"gray"}}> *Yerlerinin değişmesini istediğiniz satırı tutup sürükleyerek istediğiniz sıraya yerleştirebilirsiniz.</div>
    </div>

  };
  export default OpenOrderSortList;
 