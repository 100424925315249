




import React,{useEffect,useState} from "react";
import { Button, Input,Table ,Checkbox, Modal} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import  {colors}  from "../../../environments/environment"


const PurchaseRequestDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const record = location.state && location.state.record;
    const generalRecord = location.state && location.state.generalRecord;

    console.log("generalRecord",generalRecord)
    console.log("record",record)
  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
  
    };
  
    fetchData();
  }, []);
  


  const goPage = (record) => {
  
console.log("record",record)

    if(record.length > 0){
    navigate('/purchaserequestsendmail', { state: { record:record , generalRecord:generalRecord } })

    }
    else{
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Lütfen ürün seçiniz",
        placement: 'topRight'
      });
      return;
    }
    
  };
   
  const handleUpdate = async (record) => {
    const currentDate =new Date()
    let updateItem = {
      isPurchased: true,
      isPurchasedDate: currentDate,
    };

    if(record.length > 0){
      const result = await FirebaseService.updatePurchaseData(record.key,updateItem);
      //  console.log(result);
        if(result){
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description:"Başarıyla kaydedilmiştir",
            placement: 'topRight'
          });
          setTimeout(() => {
            navigate("/buyerpage");
          }, 2000);
        }
        else{
          
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz",
            placement: 'topRight'
          });
          
        } 
  
      }
      else{
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Lütfen ürün seçiniz",
          placement: 'topRight'
        });
        return;
      }
   
  };

  const handleNoPurchaseUpdate = async () => {
    const currentDate =new Date()
    let updateItem = {
      isPurchased: false,
      noPurchasedDescription:noPurchasedDescription
     
    };

    const result = await FirebaseService.updatePurchaseData(record.key,updateItem);
      //  console.log(result);
        if(result){
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description:"Başarıyla kaydedilmiştir",
            placement: 'topRight'
          });
          setIsModalVisible(false);
          setTimeout(() => {
            navigate("/buyerpage");
          }, 2000);
        }
        else{
          
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
            placement: 'topRight'
          });
          
        } 
  
      
      
   
  };
  const columnsRaw = [
  {
    title: 'Kalite',
    dataIndex: 'quality',
    key: 'quality',
    width: 200,
    render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
  },

    {
      title: 'Kalınlık',
      dataIndex: 'thickness',
      key: 'thickness',
      width: 200,
      render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
    },

   
    {
      title: 'En',
      dataIndex: 'width',
      key: 'width',
      width: 200,
      render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
    },
    {
      title: 'Boy',
      dataIndex: 'height',
      key: 'height',
      width: 200,
      render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
    },

    {
      title: 'Yüzey',
      dataIndex: 'surface',
      key: 'surface',
      width: 200,
      render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
    },

    {
      title: 'Adet',
      dataIndex: 'piece',
      key: 'piece',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
      width: 200,
    },     
    ];

  const columnsOther = [
   
 
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        width: 200,
      },
        
      {
        title: 'Açıklama',
        dataIndex: 'description',
        key: 'description',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>   {text !== null ? text : '-'}</span>,
        width: 200,
      },

   
        
     
        
           
           
    
  ];
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [noPurchasedDescription, setNoPurchasedDescription] = useState('');

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleInputChange =  (e) => {
    setNoPurchasedDescription(e.target.value);
  };

  const showModal = () => {
    if(record.length > 0){
      setIsModalVisible(true);
    }
    else{
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Lütfen ürün seçiniz",
          placement: 'topRight'
        });
        return;
    } 

  };

  return ( 
    <div style={{ display: "flex", flexDirection: "column",padding:"10px 100px"}}>
  
      <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}> Satın Alma Gelen Talep</h2>
       
      <div style={{ display: "flex", justifyContent: "space-between" }}>
       
        <div style={{ textAlign: "left", flex: "1 0 10.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Ürün</p>
          {generalRecord && generalRecord.productType === 'raw' ? 'Saç Levha' : 'Diğer'}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 10.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Durum</p>
          {generalRecord.productType === "raw" ?
        generalRecord.mailList?.length >= 3 ? "Teklif Gönderildi" : "Teklif Gönderilmedi"   :
        generalRecord.isPurchased ? "Satın Alındı" :   generalRecord.noPurchasedDescription ? 
        "Satın Alınmayacak" : "Satın Alınma Bekleniyor"
        }       
        </div>

        <div style={{ textAlign: "left", flex: "1 0 10.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
          {generalRecord && generalRecord.deadline && typeof generalRecord.deadline === 'object' ?
            new Date(generalRecord.deadline.seconds * 1000).toLocaleDateString('tr-TR') :
            generalRecord.deadline
          }
        </div>

       
      </div>

      <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px" }}>Talepler Detay</div>
      <Table
      style={{width:"70%", margin:"auto"}}
            locale={{
              emptyText: 'Henüz satın alma talebi bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
           dataSource={record}
          columns={generalRecord.productType ==="raw" ? columnsRaw : columnsOther}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
        <div style={{display:"flex", justifyContent:"right"}}>
       {generalRecord.productType === 'raw' ? 
        <Button 
        style={{
         backgroundColor: generalRecord.mailList?.length >= 3 ? "gray" : colors.blue,
         color:"white",fontWeight:"500"}}
         onClick={()=>goPage(record)} 
         disabled={generalRecord.mailList?.length >= 3  ? true : false}

         >
       Devam
       </Button>
       :
       <>
       <Button 
       style={{
        backgroundColor: generalRecord.isPurchased  || record.noPurchasedDescription  ? colors.gray : colors.blue,
        color:"white",fontWeight:"500"}}
        onClick={()=>handleUpdate(record)} 
        disabled={record.isPurchased || record.noPurchasedDescription ? true : false}
        >
       Satın Al
      </Button>

      <Button 
      style={{
      backgroundColor: generalRecord.isPurchased  || generalRecord.noPurchasedDescription  ? colors.gray : colors.blue,
      color:"white",fontWeight:"500"}}
      onClick={showModal}
      disabled={generalRecord.isPurchased || generalRecord.noPurchasedDescription ? true : false}
      >
      Satın Alınma Gerçekleşmedi
      </Button>
      </>
      }

      <Modal
        title="Satın Alınmama Nedeni"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            İptal
          </Button>,
          <Button key="save"   style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}  onClick={handleNoPurchaseUpdate}>
            Kaydet
          </Button>,
        ]}
      >
        <Input
          placeholder=""
          value={noPurchasedDescription}
          onChange={handleInputChange}
          style={{ marginTop: '10px' }}
        />
      </Modal>     
        </div>
    </div>


  )};
export default PurchaseRequestDetail;

