import React,{useEffect,useState} from "react";
import { Button, Form, Input,Table ,Modal,Tooltip, Popconfirm, Spin} from 'antd';
import NotificationService from "../../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import  {colors}  from "../../../../environments/environment"
import SetupAdd from "../setupAdd";
import FolderAdd from "../folderAdd";
import WaitingOpenOrderUpdateDetail from "./waitingOpenOrderUpdateDetail";
import StoregeService from "../../../../services/storegeService";
import FirebaseStorage from "../../../../services/storegeService";


const WaitingOpenOrderDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
   
    const recordIncoming = location.state?.record;
    const [record, setRecord] = useState(recordIncoming || null);
    const [loading, setLoading] = useState(false);
   
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recordForModal, setRecordForModal] = useState(null);

    const handleRequestRemoval = (record) => {
      setRecordForModal(record);
      setIsModalVisible(true);
  };
  
    const handleModalCancel = () => {
      setIsModalVisible(false);
    };
  
    const handleRequestRemovalFinish = () => {
      setIsModalVisible(false); 
    };
  

    const [isModalVisibleSetup, setIsModalVisibleSetup] = useState(false);
    const [recordForModalSetup, setRecordForModalSetup] = useState(null);

    const handleSetup = (record) => {
      console.log("setup yükleye basıldı record",record)
      setRecordForModalSetup(record);
      setIsModalVisibleSetup(true);
  };
  
    const handleModalCancelSetup = () => {
      setIsModalVisibleSetup(false);
    };
  
    const handleFinishSetup = () => {
      setIsModalVisibleSetup(false); 
    };
  
    const handleOpenOrderContinue = (record) => {
     //open ordercontine olmalı
     console.log("siparişe basla açıldı",record)

     //isOfferApproved vs sonradan eklendi
     const selectedOpenOrderInfo = {
      companyName: record.companyName || null,
      orderType: record.orderType || null,
      projectCode: record.projectCode || null,
      warehouseName: null,
      isOfferApproved: record.isOfferApproved  || false,
      isOffer: record.isOffer  || false,
      isSetup: record.isSetup  || false,
      isOfferSetupUpdate: record.isOfferSetupUpdate  || false,
      setupName: record.setupName,
      stock: record.stock,
      wastage:record.wastage,
      jobCode:record.jobCode

    };
    
  
    console.log("selectedOpenOrderInfo", selectedOpenOrderInfo);
     navigate('/openorderaddcontinue', { state: { record: selectedOpenOrderInfo, jobCode:record.jobCode} });

  };
  const [fileURL, setFileURL] = useState("");
 

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const folderName = 'openOrderOffers';
      const jobCodePrefix = record.jobCode.substring(0, 5);
      const fileName = `Satis_Teklif_Sozlesmesi_${jobCodePrefix}.xlsx`; 

      const url = await FirebaseStorage.getDocumentURL(folderName, fileName);

      if (url) {
          console.log("URL: ", url);
          setFileURL(url);
      } else {
          console.log("File URL not found: ", folderName, fileName);
          setFileURL(null);  
      }
    };
  
    fetchData();
  }, []);
  
  
 //stok için
  const [isModalVisibleDelete, setIsModalVisibleDelete] = useState(false);
  const [inputValueDelete, setInputValueDelete] = useState(null);
  const [savedRecordDelete, setSavedRecordDelete] = useState(null);
  
  const [inputValueAdd, setInputValueAdd] = useState(null);
  const [isModalVisibleAdd, setIsModalVisibleAdd] = useState(false);
  const [savedRecordAdd, setSavedRecordAdd] = useState(null);

//wastage için
 const [isModalVisibleDeleteWastage, setIsModalVisibleDeleteWastage] = useState(false);
  const [inputValueDeleteWastage, setInputValueDeleteWastage] = useState(null);
  const [savedRecordDeleteWastage, setSavedRecordDeleteWastage] = useState(null);

  const [inputValueAddWastage, setInputValueAddWastage] = useState(null);
  const [isModalVisibleAddWastage, setIsModalVisibleAddWastage] = useState(false);
  const [savedRecordAddWastage, setSavedRecordAddWastage] = useState(null);

  
   const loadOpenOrderDetail = async () => {
    setLoading(true);
    try {
      const data = await FirebaseService.getOpenOrderData();
      let recordData = data.find(item => !item.isDeleted && item.jobCode === recordIncoming.jobCode);
      console.log("Initial recordData:", recordData, "for jobCode:", recordIncoming.jobCode);
      if (!recordData) {
          const modifiedJobCode = `${recordIncoming.jobCode}-${recordIncoming.setupName || localStorage.getItem("setupName") || ""}`;
          console.log("No match found. Retrying search with modified jobCode:", modifiedJobCode);
          recordData = data.find(item => !item.isDeleted && item.jobCode === modifiedJobCode);
      }
      if (recordData) {
          setRecord(recordData);
          console.log("Record found:", recordData);
      } else {
          console.log("No matching record found for both original and modified jobCode searches.");
      }

    } catch (error) {
      console.error('Veriler yüklenirken bir hata oluştu:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    loadOpenOrderDetail();
  }, []);


  const goDelete = async (recordx, inputValue) => {
    let success = true;
    setLoading(true);
  
    console.log("Delete record:", recordx, inputValue);
  
    const updateCombinedStockItems = async (stockItems, inputValue) => {
      const { id, isSendPurchase } = stockItems;
      try {
        if (isSendPurchase) {
          console.log("Satın alma talebi gitmiş, waiting değişecek.");
          const resultPurchase = await FirebaseService.updateStockWaitingPieceRemove(id, inputValue);
          console.log("Result for item", id, ":", resultPurchase);
        } else {
          console.log("Reserve değişecek.");
          const result = await FirebaseService.updateStockReservedPieceRemove(id, inputValue);
          console.log("Result for item", id, ":", result);
        }
      } catch (error) {
        success = false;
        console.error(`Error updating stock item ${id}:`, error);
      }
    };

    await updateCombinedStockItems(recordx, inputValue);


    await FirebaseService.updateOpenOrderStockRemove(record.key, recordx,inputValue);

    try {
      const purchaseRequests = await FirebaseService.getPurchaseRequestData();
      console.log("recordx.id)",recordx.id)
      const filteredPurchaseRequests = purchaseRequests.filter(item => {
        return !item.isDeleted &&
          item.requestedProductInfo &&
          item.requestedProductInfo.some(info => info.jobCode === record.jobCode && info.id === recordx.id);
        });

      console.log("Filtered Purchase Requests:", filteredPurchaseRequests);
    
      if (filteredPurchaseRequests.length === 0) {
        console.log("No matching purchase requests found. Exiting.");
        return; 
      }
    
      const requestedProductInfo = filteredPurchaseRequests[0].requestedProductInfo;

        const itemToUpdate = requestedProductInfo.find(item => item.id === recordx.id);
        console.log("itemToUpdate",itemToUpdate)
       
        //satın alma talebi silincek
        if (itemToUpdate.piece === inputValue) {
          console.log("satın alma talebi silincek")

          try {
            const result = await FirebaseService.deletePurchaseRequestData( filteredPurchaseRequests[0].key);
            if (result) {
              console.log(`Item with keydeleted successfully.`);
            } else {
              success = false;
              console.log(`Failed to delete item with key`);
            }
          } catch (error) {
            success = false;
            console.error(`Error deleting item with key `, error);
          }
        }
      
      //satın alma talebi update geçilcek
      else{
        console.log("satın alma talebi update gçeilcek")
        
        const updatedProductInfo = filteredPurchaseRequests[0].requestedProductInfo.map(item => {
          if (item.id === recordx.id) {
            return { ...item, piece: Number(item.piece)- Number(inputValue) };
          }
          return item; 
        });
        
        // Update item'ı hazırla
        const updateItem = {
          id: recordx.id,
          piece: updatedProductInfo.find(item => item.id === recordx.id).piece,
        };
        
          const result = await FirebaseService.updatePurchaseDataRequestedProductInfo(filteredPurchaseRequests[0].key, updateItem);
    
          if(result){
           console.log("Satın alma talebi update geçildi")
          }
          else{       
            success = false;
            console.log("Error deleting item with key");
          }   
      }
    } catch (error) {
      success = false;
      console.error("Error fetching or filtering purchase requests:", error);
    }

    if (success) {
      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: 'Başarıyla silindi.',
        placement: 'topRight',
      });
    } else {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Lütfen daha sonra tekrar deneyiniz.',
        placement: 'topRight',
      });
    }

    setLoading(false); 
  };

  const handleDelete = (record) => {
    console.log("handle delete savedRecord",record) ;
    setSavedRecordDelete(record);
    setIsModalVisibleDelete(true);  
  };

  const handleDeleteOk = async () => {
    setLoading(true);
    console.log("savedRecord handle ok",savedRecordDelete)

    if(inputValueDelete < 0){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Eksi değer girilemez',
        placement: 'topRight'
      }); 
      setLoading(false);
     return;
    }
    if(savedRecordDelete.piece < inputValueDelete){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Çıkarılmak istenen sayı, rezerve edilen sayıdan büyük olamaz.',
        placement: 'topRight'
      }); 
      setLoading(false);
     return;
    }
    await goDelete(savedRecordDelete, inputValueDelete);
    await loadOpenOrderDetail();
    setIsModalVisibleDelete(false);
    setLoading(false); 
  };

  const handleCancelDelete = () => {
    setIsModalVisibleDelete(false);  
  };

  const handleAdd = (record) => {
    console.log("handle add savedRecord",record) ;
    setSavedRecordAdd(record);
    setIsModalVisibleAdd(true);  
  };

  const handleAddOk= async () => {
    setLoading(true);
    console.log("savedRecord handle ok",savedRecordAdd)
   
    if(inputValueAdd < 0){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Eksi değer girilemez',
        placement: 'topRight'
      }); 
      setLoading(false);
     return;
    }

    await goAdd(savedRecordAdd, inputValueAdd);
    await loadOpenOrderDetail();
    setIsModalVisibleAdd(false);
    setLoading(false); 
  };

  const handleCancelAdd = () => {
    setIsModalVisibleAdd(false);  
  };

  const goAdd = async (recordx, inputValue) => {
    let success = true;
    setLoading(true);
  
    console.log("Add record:", recordx, inputValue);
  
    const updateCombinedStockItems = async (stockItems, inputValue) => {

      const { id, isSendPurchase } = stockItems;
      try {
        if (isSendPurchase) {
          console.log("Satın alma talebi gitmiş, waiting değişecek.");
          const resultPurchase = await FirebaseService.updateStockWaitingPiece(id, inputValue);
          //satın alma talebi update geçilcek
           console.log("satın alma talebi update gçeilcek")
           const purchaseRequests = await FirebaseService.getPurchaseRequestData();
          console.log("recordx.id",recordx.id)
           const filteredPurchaseRequests = purchaseRequests.filter(item => {
             return !item.isDeleted &&
               item.requestedProductInfo &&
               item.requestedProductInfo.some(info => info.jobCode === record.jobCode && info.id === recordx.id);

           });
     
           console.log("Filtered Purchase Requests:", filteredPurchaseRequests);
     
     
        const updatedProductInfo = filteredPurchaseRequests[0].requestedProductInfo.map(item => {
          if (item.id === recordx.id) {
            return { ...item, piece: Number(item.piece) + Number(inputValue) };
          }
          return item; 
        });
        
        const updateItem = {
          id: recordx.id,
          piece: updatedProductInfo.find(item => item.id === recordx.id).piece,
        };
        
          const result = await FirebaseService.updatePurchaseDataRequestedProductInfo(filteredPurchaseRequests[0].key, updateItem);
    
          if(result){
           console.log("Satın alma talebi update geçildi ekleme yapıldı")
          }
          else{       
            success = false;
            console.log("Error deleting item with key");
          }  
          console.log("Result for item", id, ":", resultPurchase);
        } else {
          console.log("Reserve değişecek.");
          const { id } = stockItems;
          console.log("id",id)
          const stockItem = await FirebaseService.getStockItemById(id);
          if (!stockItem) {
            console.error(`Stok öğesi bulunamadı: ${id}`);
            return;
          }
        console.log("stockItem",stockItem)
          const { piece } = stockItem;
        console.log("piece",piece,inputValue)
          if (piece >= Number(inputValue)) {
            console.log("Reserve değişecek.");
            const result = await FirebaseService.updateStockReservedPiece(id, Number(inputValue));
            
            console.log("Result for item", id, ":", result);
          } else {
            //yeni satın alma talebi atılcak waitng eklencek
            console.log("Rezerve etmek istenen sayı, mevcut piece sayısından büyük. İşlem iptal edildi.");
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: 'Rezerve etmek istediğiniz sayı stokta mevcut değildir.',
              placement: 'topRight'
            }); 
            success = false;
          
           return;

          }
        }
      } catch (error) {
        success = false;
        console.error(`Error updating stock item ${id}:`, error);
      }


      
     
    };

    await updateCombinedStockItems(recordx, inputValue);


    if (success) {
      await FirebaseService.updateOpenOrderStockAdd(record.key, recordx,Number(inputValue));

      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: 'Başarıyla eklendi.',
        placement: 'topRight',
      });
    } else {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Lütfen daha sonra tekrar deneyiniz.',
        placement: 'topRight',
      });
    }
    setLoading(false); 
};

  const columnsRaw = [
    {
      title: 'Kalite',
      dataIndex: 'quality',
      key: 'quality',
      width: 200,
      render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
    },

      {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },

     
      {
        title: 'En',
        dataIndex: 'width',
        key: 'width',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
      {
        title: 'Boy',
        dataIndex: 'height',
        key: 'height',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },

      {
        title: 'Yüzey',
        dataIndex: 'surface',
        key: 'surface',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
 
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        width: 200,
      },
      {
        title : ' ',
        dataIndex : 'isSendPurchaseicon',
        key : 'isSendPurchaseicon',
  
        render: (text, record) => {
            return (
              <>
                {record.isSendPurchase ? (
                  <Tooltip title="Satın Alma Talebi Gönderildi">
                    <div >
                      <img width={20} height={20} alt="logo" src="/images/isSendPurchaseicon.png" style={{ paddingBottom: "5%" }} />
                    </div>
                  </Tooltip>
                ) : null
                }
              </>
            );
          },            
      },
      {
        title: ' ',
        dataIndex: 'add',
        key: 'add',
        render: (text, record) => {
        
          return (
            <>
              <Popconfirm
                title="Ekleme istediğinize emin misiniz"
                
                onConfirm={() => handleAdd(record)}
                okText="Ekle"
                cancelText="Vazgeç"
              >
                <img
                  src="/images/edit.svg"
                  alt="Ekle"
                  style={{ cursor: 'pointer', width: '20px', height: '20px' }} 
                />
              </Popconfirm>
             
              <Modal
                title="Lütfen eklemek istediğiniz adeti giriniz"
                visible={isModalVisibleAdd}        
                onOk={() => handleAddOk(record)}  
                onCancel={handleCancelAdd}
                okText="Onayla"
                cancelText="İptal"
                 className="popup-modal-button"
              >
                <Input 
                  type="number" 
                  value={inputValueAdd} 
                  onChange={(e) => setInputValueAdd(e.target.value)} 
                  placeholder="Sayı giriniz"
                    className="input-style"
                />
                  {loading && (
                <div style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1000,
                }}>
                  <Spin />
                </div>
              )}
              </Modal>
      
            
            </>
          );
        },
      }
      ,
      {
        title: ' ',
        dataIndex: 'delete',
        key: 'delete',
        render: (text, record) => {
        
          return (
            <>
              <Popconfirm
                title="Silmek istediğinize emin misiniz"
                onConfirm={() => handleDelete(record)}
                okText="Sil"
                cancelText="Vazgeç"
                
              >
                <img
                  src="/images/remove.svg"
                  alt="Delete"
                  style={{ cursor: 'pointer', width: '20px', height: '20px' }} 
                />
              </Popconfirm>
             
              <Modal
                title="Lütfen silmek istediğiniz rezerve sayısını girin"
                visible={isModalVisibleDelete}        
                onOk={() => handleDeleteOk(record)}  
                onCancel={handleCancelDelete}
                okText="Onayla"
                cancelText="İptal"
                className="popup-modal-button"
              >
                <Input 
                  type="number" 
                  value={inputValueDelete} 
                  onChange={(e) => setInputValueDelete(e.target.value)} 
                  placeholder="Sayı giriniz"
                  className="input-style"
                />
                  {loading && (
                <div style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1000,
                }}>
                  <Spin />
                </div>
              )}
              </Modal>
      
            
            </>
          );
        },
      },
    
      
  ];







  const handleAddWastage = (record) => {
    console.log("handle add Wastage savedRecord",record) ;
    setSavedRecordAddWastage(record);
    setIsModalVisibleAddWastage(true);  
  };

  const handleAddOkWastage= async () => {
    setLoading(true);
    console.log("savedRecord handle ok Wastage",savedRecordAddWastage)
   
    if(inputValueAddWastage < 0){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Eksi değer girilemez',
        placement: 'topRight'
      }); 
      setLoading(false);
     return;
    }

    await goAddWastage(savedRecordAddWastage, inputValueAddWastage);
    await loadOpenOrderDetail();
    setIsModalVisibleAddWastage(false);
    setLoading(false); 
  };

  const handleCancelAddWastage = () => {
    setIsModalVisibleAddWastage(false);  
  };

  const goAddWastage = async (recordx, inputValue) => {
    let success = true;
    setLoading(true);
  
    console.log("Add record wastage:", recordx, inputValue);
   
    const updateCombinedWastageItems = async (wastageItems, inputValue) => {
   
          const { id } = wastageItems;
          console.log("id",id)
          const wastageItem = await FirebaseService.getWastageItemById(id);
          if (!wastageItem) {
            console.error(`wastage öğesi bulunamadı: ${id}`);
            return;
          }
        console.log("wastageItem",wastageItem)
          const { piece } = wastageItem;
        console.log("piece",piece,inputValue)
          if (piece >= Number(inputValue)) {
            console.log("Reserve değişecek.");
            const result = await FirebaseService.updateWastageReservedPiece(id, inputValue);
             console.log("Result for item", id, ":", result);            
          
          } else {
            
            console.log("Rezerve etmek istenen sayı, mevcut piece sayısından büyük. İşlem iptal edildi.");
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: 'Rezerve etmek istediğiniz sayı firede mevcut değildir.',
              placement: 'topRight'
            }); 
            success = false;
           return;

          } 
      
    }
   
    await updateCombinedWastageItems(recordx, inputValue);

    if (success) {
      await FirebaseService.updateOpenOrderWastageAdd(record.key, recordx,Number(inputValue));

      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: 'Başarıyla eklendi.',
        placement: 'topRight',
      });
    } else {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Lütfen daha sonra tekrar deneyiniz.',
        placement: 'topRight',
      });
      setLoading(false); 
    }
    
};




const goDeleteWastage = async (recordx, inputValue) => {
  let success = true;
  setLoading(true);

  console.log("remove record wastage:", recordx, inputValue);
 
  const updateCombinedWastageItems = async (wastageItems, inputValue) => {
    const { id } = wastageItems;
    console.log("Reserve değişecek.");
    const result = await FirebaseService.updateWastageReservedPieceRemove(id, inputValue);
    console.log("Result for item", id, ":", result);            
  }
 
  await updateCombinedWastageItems(recordx, inputValue);

  if (success) {
    await FirebaseService.updateOpenOrderWastageRemove(record.key, recordx,Number(inputValue));

    NotificationService.openSuccessNotification({
      title: 'İşlem Başarılı',
      description: 'Başarıyla çıkarıldı.',
      placement: 'topRight',
    });
  } else {
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: 'Lütfen daha sonra tekrar deneyiniz.',
      placement: 'topRight',
    });
    setLoading(false); 
  }
  
};

const handleDeleteWastage = (record) => {
  console.log("handle delete Wastage savedRecord",record) ;
  setSavedRecordDeleteWastage(record);
  setIsModalVisibleDeleteWastage(true);  
};

const handleDeleteOkWastage = async () => {
  setLoading(true);
  console.log("savedRecord handle ok Wastage",savedRecordDeleteWastage)

  if(inputValueDeleteWastage < 0){
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: 'Eksi değer girilemez',
      placement: 'topRight'
    }); 
    setLoading(false);
   return;
  }
  if(savedRecordDeleteWastage.piece < inputValueDeleteWastage){
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: 'Çıkarılmak istenen sayı, rezerve edilen sayıdan büyük olamaz.',
      placement: 'topRight'
    }); 
    setLoading(false);
   return;
  }
  await goDeleteWastage(savedRecordDeleteWastage, inputValueDeleteWastage);
  await loadOpenOrderDetail();
  setIsModalVisibleDeleteWastage(false);
  setLoading(false); 
};

const handleCancelDeleteWastage = () => {
  setIsModalVisibleDeleteWastage(false);  
};

  const columnsWastage = [
    {
      title: 'Kalite',
      dataIndex: 'quality',
      key: 'quality',
      width: 200,
      render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
    },

      {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },

     
      {
        title: 'En',
        dataIndex: 'width',
        key: 'width',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
      {
        title: 'Boy',
        dataIndex: 'height',
        key: 'height',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },

      {
        title: 'Yüzey',
        dataIndex: 'surface',
        key: 'surface',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
 
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        width: 200,
      },
     
      {
        title: ' ',
        dataIndex: 'add',
        key: 'add',
        render: (text, record) => {
        
          return (
            <>
              <Popconfirm
                title="Ekleme istediğinize emin misiniz"
                
                onConfirm={() => handleAddWastage(record)}
                okText="Ekle"
                cancelText="Vazgeç"
              >
                <img
                  src="/images/edit.svg"
                  alt="Ekle"
                  style={{ cursor: 'pointer', width: '20px', height: '20px' }} 
                />
              </Popconfirm>
             
              <Modal
                title="Lütfen eklemek istediğiniz adeti giriniz"
                visible={isModalVisibleAddWastage}        
                onOk={() => handleAddOkWastage(record)}  
                onCancel={handleCancelAddWastage}
                okText="Onayla"
                cancelText="İptal"
                 className="popup-modal-button"
              >
                <Input 
                  type="number" 
                  value={inputValueAddWastage} 
                  onChange={(e) => setInputValueAddWastage(e.target.value)} 
                  placeholder="Sayı giriniz"
                    className="input-style"
                />
                  {loading && (
                <div style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1000,
                }}>
                  <Spin />
                </div>
              )}
              </Modal>
      
            
            </>
          );
        },
      }
      ,
      {
        title: ' ',
        dataIndex: 'delete',
        key: 'delete',
        render: (text, record) => {
        
          return (
            <>
              <Popconfirm
                title="Silmek istediğinize emin misiniz"
                onConfirm={() => handleDeleteWastage(record)}
                okText="Sil"
                cancelText="Vazgeç"
                
              >
                <img
                  src="/images/remove.svg"
                  alt="Delete"
                  style={{ cursor: 'pointer', width: '20px', height: '20px' }} 
                />
              </Popconfirm>
             
              <Modal
                title="Lütfen silmek istediğiniz rezerve sayısını girin"
                visible={isModalVisibleDeleteWastage}        
                onOk={() => handleDeleteOkWastage(record)}  
                onCancel={handleCancelDeleteWastage}
                okText="Onayla"
                cancelText="İptal"
                className="popup-modal-button"
              >
                <Input 
                  type="number" 
                  value={inputValueDeleteWastage} 
                  onChange={(e) => setInputValueDeleteWastage(e.target.value)} 
                  placeholder="Sayı giriniz"
                  className="input-style"
                />
                  {loading && (
                <div style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1000,
                }}>
                  <Spin />
                </div>
              )}
              </Modal>
      
            
            </>
          );
        },
      },
    
      
  ];
   
   return ( 
    <div style={{ display: "flex", flexDirection: "column",padding:"10px 100px"}}>
    
        <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}> Bekleyen Sipariş Detay</h2>
            
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Müşteri Firma</p>
            {recordIncoming && recordIncoming.companyName}
          </div>

          <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
            {recordIncoming && recordIncoming.jobCode}
          </div>

          <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Setup İsmi</p>
            {recordIncoming && recordIncoming.setupName ?  recordIncoming.setupName  : "-"}
          </div>


          <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Durum</p>
            {recordIncoming && recordIncoming.status}
          </div>

          <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
            {recordIncoming && recordIncoming.deadline && typeof recordIncoming.deadline === 'object' ?
              new Date(recordIncoming.deadline.seconds * 1000).toLocaleDateString('tr-TR') :
              recordIncoming.deadline
            }
          </div>

          <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Satış Sözleşmesi</p>
        {fileURL ? (
          <>
            <Button href={fileURL} target="_blank" rel="noopener noreferrer" style={{border: `2px solid #2B7199`,color:colors.blue,fontWeight:"bold"}}>
              İndir
            </Button>
          
          </>
        ) : (
          <p>-</p> 
        )}
        
          </div>

        </div>

        {record.orderType === "order" && (
          <>
            <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px" }}>Seçilen Hammadeler</div>
            <Table
              style={{width:"70%", margin:"auto"}}
              locale={{
                emptyText: 'Seçilen hammade bulunmamaktadır...',
                filterReset: 'Sıfırla',
                filterTitle: 'Filtre Menüsü',
                selectAll: 'Hepsini Seç',
                selectInvert: 'Tersini Seç',
                selectionAll: 'Tümünü Seç',
                sortTitle: 'Sıralama',
                triggerDesc: 'Azalan sıralama için tıklayın',
                triggerAsc: 'Artan sıralama için tıklayın',
                cancelSort: 'Sıralamayı iptal etmek için tıklayın',
              }}
                dataSource={record.stock}
                columns={columnsRaw}
                showHeader={true}
                pagination={false} 
                className="custom-news-table"
                rowClassName="custom-news-row"
                
            />


        <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px" }}>Seçilen Fireler</div>
            <Table
              style={{width:"70%", margin:"auto"}}
              locale={{
                emptyText: 'Seçilen fire bulunmamaktadır...',
                filterReset: 'Sıfırla',
                filterTitle: 'Filtre Menüsü',
                selectAll: 'Hepsini Seç',
                selectInvert: 'Tersini Seç',
                selectionAll: 'Tümünü Seç',
                sortTitle: 'Sıralama',
                triggerDesc: 'Azalan sıralama için tıklayın',
                triggerAsc: 'Artan sıralama için tıklayın',
                cancelSort: 'Sıralamayı iptal etmek için tıklayın',
              }}
                dataSource={record.wastage}
                columns={columnsWastage}
                showHeader={true}
                pagination={false} 
                className="custom-news-table"
                rowClassName="custom-news-row"
                
            />
          </>
        
        )}
        <div style={{display:"flex",justifyContent:"space-between", marginTop:"50px"}}>      
          <div>
            <Button
              style={{
                backgroundColor: "#9D321A",
                color: "white",
                fontWeight: "500",
                float:"right",
                marginRight:"100px",
                display : record.orderType === "offer" && record.noOfferApprovedDescription !=null ? "flex" : "none"
              }}  
              onClick={() => handleRequestRemoval(record)}
              >
                Güncelleme Detay
            </Button>

            <Modal
              title="Güncelleme Detay"
              visible={isModalVisible}
              onCancel={handleModalCancel}
              footer={null}
            >
              <WaitingOpenOrderUpdateDetail  record={recordForModal}  onClose={handleRequestRemovalFinish} />
            </Modal>
          </div>

          <div >
          {record.isOfferSetupUpdate && (
            <>
            <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={() => handleSetup(record)} >
              Setup Yükle
            </Button>
              <Modal
              title="Setup Yükle"
              visible={isModalVisibleSetup}
              onCancel={handleModalCancelSetup}
              footer={null}
            >
              <SetupAdd  recordx={recordForModalSetup}  onClose={handleFinishSetup} />
            </Modal></>
          
          )}

        {(record.orderType === "order" && !record.isSetup && record.isWaiting && record.stock != null
          ) && (
            <>
            <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={() => handleSetup(record)} >
              Setup Yükle
            </Button>
              <Modal
              title="Setup Yükle"
              visible={isModalVisibleSetup}
              onCancel={handleModalCancelSetup}
              footer={null}
            >
              <SetupAdd  recordx={recordForModalSetup}  onClose={handleFinishSetup} />
            </Modal>
            <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={() => handleOpenOrderContinue(record)} >
              Stok Seçimi Düzenle
            </Button></>
          
          )}
        
        {record.isOfferApproved && (
            <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={() => handleOpenOrderContinue(record)} >
              Siparişe Başla
            </Button>
          )}


          {(record.orderType === "order" && !record.isSetup && record.stock === null ) && (
            <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={() => handleOpenOrderContinue(record)} >
              Stok Seçimi
            </Button>
          )}


        {(record.orderType === "offer" && !record.isSetup && record.isWaiting && !record.isOfferApproved ) && (
            <>
            <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={() => handleSetup(record)} >
              Setup Yükle
            </Button>
              <Modal
              title="Setup Yükle"
              visible={isModalVisibleSetup}
              onCancel={handleModalCancelSetup}
              footer={null}
            >
              <SetupAdd  recordx={recordForModalSetup}  onClose={handleFinishSetup} />
            </Modal></>
          
          )}

          </div>
          </div>
    </div>
   
  )};
export default WaitingOpenOrderDetail;