import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Spin, InputNumber, Row, Col } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import { colors, parsCollections, stockOrWastagee } from "../../../environments/environment";

const AddStockOrWastage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  const [surfaceOptions, setSurfaceOptions] = useState([]);
  const [qualityOptions, setQualityOptions] = useState([]);
  const [companyNameOptions, setCompanyNameOptions] = useState([]);
  const [supplierNameOptions, setSupplierNameOptions] = useState([]);
  const [depotNameOptions, setDepotNameOptions] = useState([]);
  
  useEffect(() => {
    const fetchStockData = async () => {
      const stockData = await FirebaseService.getStocksData();
      const densitiesData = await FirebaseService.getDensitiesData(); 
      const companiesData = await FirebaseService.getCompaniesData(); 
      const mailsData = await FirebaseService.getMailsData(); 

      const surfaceSet = new Set();
      const qualitySet = new Set();
      const companyNameSet = new Set();
      const supplierNameSet = new Set();
      const depotNameSet = new Set();

      stockData.forEach(item => {
        if (item.surface) surfaceSet.add(item.surface);
        if (item.quality) {
          qualitySet.add(item.quality); 
        }
      });

      companiesData.forEach(item => {
        if (item.companyName && !item.isDeleted) companyNameSet.add(item.companyName);
        if (item.companyName && !item.isDeleted) depotNameSet.add(item.companyName);
      });

      mailsData.forEach(item => {
        if (item.companyName && !item.isDeleted) supplierNameSet.add(item.companyName);
      });

      const densityValues = densitiesData.reduce((acc, item) => {
        acc[item.quality] = item.density; 
        return acc;
      }, {});

      const qualityOptionsArray = Array.from(qualitySet).map(quality => ({
        quality,
        density: densityValues[quality] 
      }));

      setSurfaceOptions([...surfaceSet]);     
      setQualityOptions(qualityOptionsArray);  
      setCompanyNameOptions([...companyNameSet]);
      setSupplierNameOptions([...supplierNameSet]);
      setDepotNameOptions([...depotNameSet]);
    };

    fetchStockData();
  }, []);

  const calculateUnitKg = (thickness, width, height, density) => {
    return (width * height * thickness * density) / 1000000; 
  };


  function generateRandomCode(length) {
    const characters = '0123456789';
    let result = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  }

  const onFinish = async (values) => {
    const currentDate = new Date();
    const combinedValues = [];
  
    const quality = values.quality.trim().toLowerCase(); 
    const qualityOption = qualityOptions.find(option => option.quality.trim().toLowerCase() === quality); 
    const density = qualityOption ? qualityOption.density : undefined;  
    
    for (const index of additionalContents) {
      const unitKg = calculateUnitKg(
        values[`thickness-${index}`],
        values[`width-${index}`],
        values[`height-${index}`],
        density
      );
  
      let data;
  
      if (values.productType.toLowerCase() === "stock") {
        data = {
          code: generateRandomCode(5),
          companyName: values.companyName || null,
          depotName: values.depotName || null,
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          height: values[`height-${index}`],
          isDeleted: false,
          minPiece: 0,
          piece: Number(values[`piece-${index}`]),
          quality: values.quality || null,
          reservedPiece: 0,
          supplierName: values.supplierName || values.companyName,
          surface: values.surface || null,
          thickness: values[`thickness-${index}`],
          totalKilo: 0,
          unitKilo: unitKg,
          waitingPiece: 0,
          waybillNumber: 0,
          width: values[`width-${index}`],
          description: values[`description-${index}`] || null,
        };
      } else {
        data = {
          comingPiece: 0,
          companyName: values.companyName || null,
          depotName: values.depotName || null,
          description: values[`description-${index}`] || null,
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          height: values[`height-${index}`],
          imageUrl: null,
          isDeleted: false,
          isQRScanned: true,
          jobCode: null,
          piece: Number(values[`piece-${index}`]),
          quality: values.quality || null,
          reservedComingPiece: 0,
          reservedPiece: 0,
          surface: values.surface || null,
          supplierName: values.supplierName || values.companyName,
          thickness: values[`thickness-${index}`],
          unitKilo: unitKg,
          totalKilo: 0,
          width: values[`width-${index}`],
        };
      }
  
      combinedValues.push(data);
    }
  
  
    try {
      const failedRecords = [];
  
      for (const data of combinedValues) {
        try {
          if (values.productType.toLowerCase() === "stock") {
            console.log("Stok ekleniyor:", data);
            await FirebaseService.addRecording(parsCollections.stock, data);
          } else {
            console.log("Fire ekleniyor:", data);
            await FirebaseService.addRecording(parsCollections.wastages, data);
          }
        } catch (error) {
          console.error("Hata oluştu:", error);
          failedRecords.push(data);
        }
      }
  
      if (failedRecords.length === 0) {
        NotificationService.openSuccessNotification({
          title: "Başarılı",
          description: "Tüm ürünler başarıyla eklendi.",
          placement: "topRight",
        });
      } else {
        NotificationService.openErrorNotification({
          title: "Hata",
          description: `${failedRecords.length} ürün eklenemedi. Lütfen tekrar deneyin.`,
          placement: "topRight",
        });
      }
  
      setLoading(false);
      navigate("/programmerpage");
    } catch (error) {
      NotificationService.openErrorNotification({
        title: "Hata",
        description: "Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.",
        placement: "topRight",
      });
      setLoading(false);
    }
  };
  
  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({
      title: 'Geçersiz Form',
      description: 'Lütfen zorunlu alanları doldurunuz.',
      placement: 'topRight'
    });
  };
  const [additionalContents, setAdditionalContents] = useState([1]);

const addAdditionalContent = () => {
  const newIndex = additionalContents.length > 0 ? Math.max(...additionalContents) + 1 : 0;
  setAdditionalContents([...additionalContents, newIndex]);
};

const removeAdditionalContent = (indexToRemove) => {
  setAdditionalContents((prevContents) => prevContents.filter((index) => index !== indexToRemove));
};



  return (
    <div>
          <Form
            name="basic"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 15 }}
            style={{ maxWidth: "100%", padding: "5% 0 0 0%" ,}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={16}>
              {/* Firma */}
              <Col span={8}>
                <Form.Item
                  label="Müşteri Firma"
                  name="companyName"
                  rules={[{ required: true, message: 'Lütfen müşteri firma seçiniz!' }]}
                >
                  <Select
                    showSearch
                    placeholder="Firma Seçiniz"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    className="input-style" 
                  >
                    {companyNameOptions.map(option => (
                      <Select.Option key={option} value={option} label={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* Tedarikçi */}
              <Col span={8}>
                <Form.Item
                  label="Tedarikçi"
                  name="supplierName"
                >
                  <Select
                    showSearch
                    placeholder="Tedarikçi Seçiniz"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    className="input-style" 
                  >
                    {supplierNameOptions.map(option => (
                      <Select.Option key={option} value={option} label={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* Depo */}
              <Col span={8}>
                <Form.Item
                  label="Depo"
                  name="depotName"
                  rules={[{ required: true, message: 'Lütfen depo seçiniz!' }]}
                >
                  <Select
                    showSearch
                    placeholder="Depo Seçiniz"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    className="input-style" 
                  >
                    {depotNameOptions.map(option => (
                      <Select.Option key={option} value={option} label={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

             {/* hammade / fire */}
              <Col span={8}>
                <Form.Item
                      label="Hammade/Fire"
                      name="productType"
                      rules={[{ required: true, message: 'Lütfen tür giriniz!' }]}
                    >
                      <Select
                      showSearch
                        className="input-style" 
                        placeholder="Hammade/Fire Seçiniz"
                        suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                        options={stockOrWastagee.map(item => ({ label: item.label, value: item.value }))}
                        filterOption={(input, option) => 
                          option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
                        }
                      />
                </Form.Item>
              </Col>

              {/* Kalite */}
              <Col span={8}>
                  <Form.Item
                    label="Kalite"
                    name="quality"
                    rules={[{ required: true, message: 'Lütfen kalite seçiniz!' }]}
                  >
                    <Select
                      showSearch
                      placeholder="Kalite Seçiniz"
                      style={{ width: "100%" }}                     
                      filterOption={(input, option) =>
                        option?.label?.toLowerCase().includes(input.toLowerCase())
                      }                      
                      className="input-style" 
                    >
                      {qualityOptions.map(option => (
                        <Select.Option key={option.quality} value={option.quality}>
                          {option.quality}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

              {/* Yüzey */}
              <Col span={8}>
                <Form.Item
                  label="Yüzey"
                  name="surface"
                  rules={[{ required: true, message: 'Lütfen yüzey seçiniz!' }]}
                >
                  <Select
                    showSearch
                    placeholder="Yüzey Seçiniz"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      option?.label?.toLowerCase().includes(input.toLowerCase())
                    }    
                    className="input-style" 
                  >
                    {surfaceOptions.map(option => (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col> 
            </Row>

            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.productType !== currentValues.productType}
              >
                {({ getFieldValue }) => (
                  <div>
                    {additionalContents.map((index) => (
                      <div key={index} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <Form.Item
                          label="Kalınlık"
                          name={`thickness-${index}`}
                          rules={[{ required: true, message: 'Lütfen kalınlık giriniz!' }]}
                        >
                          <InputNumber style={{ width: "100%" }} placeholder="Kalınlık (mm)" className="input-style" />
                        </Form.Item>

                        <Form.Item
                         label="En"
                          name={`width-${index}`}
                          rules={[
                            { pattern: /^\d+$/, message: 'Sadece rakam girişi yapınız.' },
                            { required: true, message: 'Lütfen en giriniz!' }
                          ]}
                        >
                          <InputNumber style={{ width: "100%" }} placeholder="En" className="input-style" />
                        </Form.Item>

                        <Form.Item
                         label="Boy"
                          name={`height-${index}`}
                          rules={[
                            { pattern: /^\d+$/, message: 'Sadece rakam girişi yapınız.' },
                            { required: true, message: 'Lütfen boy giriniz!' }
                          ]}
                        >
                          <InputNumber style={{ width: "100%" }} placeholder="Boy" className="input-style" />
                        </Form.Item>

                        <Form.Item
                         label="Adet"
                          name={`piece-${index}`}
                          rules={[
                            { pattern: /^\d+$/, message: 'Sadece rakam girişi yapınız.' },
                            { required: true, message: 'Lütfen adet giriniz!' }
                          ]}
                        >
                          <InputNumber style={{ width: "100%" }} placeholder="Adet" className="input-style" />
                        </Form.Item>

                        <Form.Item 
                        label="Açıklama"
                        name={`description-${index}`}>
                          <Input style={{ width: "120px" }} placeholder="Açıklama" className="input-style" />
                        </Form.Item>

                        <Button  style={{marginTop:"-20px", marginLeft:"10px"}} onClick={() => removeAdditionalContent(index)}>-</Button>
                      </div>
                    ))}

                    <Form.Item>
                      <Button onClick={addAdditionalContent}>+</Button>
                    </Form.Item>
                  </div>
                )}
              </Form.Item>

        
              <Form.Item wrapperCol={{ offset: 15, span: 18 }}>
                    <Button
                      htmlType="submit"
                      loading={loading}
                      style={{backgroundColor:colors.blue,color:"white",fontWeight:"500", textAlign:"right"}}
                    >
                      Kaydet
                    </Button>
                  </Form.Item>
                </Form>
                {loading && (
                  <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                  }}>
                    <Spin />
                  </div>
                )}
       </div>
  );
};

export default AddStockOrWastage;


/*

    <Col span={8}>
      <Form.Item
        label="Rezerve Adet"
        name="reservedPiece"
        rules={[{ required: true, message: 'Lütfen giriniz!' }]}
      >
        <InputNumber style={{ width: "100%" }} placeholder="Rezerve Adet"  className="input-style"  />
      </Form.Item>
    </Col>
    <Col span={8}>
      <Form.Item
        label="Beklenen Adet"
        name="waitingPiece"
        rules={[{ required: true, message: 'Lütfen giriniz!' }]}
      >
        <InputNumber style={{ width: "100%" }} placeholder="Beklenen Adet"   className="input-style" />
      </Form.Item>
    </Col>
     
     

*/