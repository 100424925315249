import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input,Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import  { colors, placeholderMap}  from "../../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';


const OpenOrderGroupListDelete = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [items, setItems] = useState(record);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);



  useEffect(() => {
    window.scrollTo(0, 0);
   // console.log("record",record);

    setItems(record);
    setFilteredItems(record);
   

    const fetchData = async () => {
    };
    fetchData();
  }, []);

  const goEditPage = (record) => {
    navigate('/openorderlistdelete', { state: { record } })
  };


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase(); 
      const itemValue = item[dataIndex]?.toString().toLowerCase(); 
      return (
        !item.isDeleted &&
       
        (value ? itemValue.includes(value) : true) 
      );
    });
    setFilteredItems(filteredData);
  };
  
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };

 

  const getColumnSearchProps = dataIndex => ({
    
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
     <Input
    id="search-input"  
    placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
    value={selectedKeys[0]}
    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    style={{ width: 188, marginBottom: 8, display: 'block' }}
    />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90,background:colors.blue }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });


  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      ...getColumnSearchProps('companyName'),
      sorter: (a, b) => {
        const companyNameA = a && a.companyName ? a.companyName : '';
        const companyNameB = b && b.companyName ? b.companyName : '';
        return companyNameA.localeCompare(companyNameB);
      }
  },
  
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
       ...getColumnSearchProps('jobCode'),
       sorter: (a, b) => {
        const itemA = a && a.jobCode ? a.jobCode : '';
        const itemB = b && b.jobCode ? b.jobCode : '';
        return itemA.localeCompare(itemB);
      },
    },
      
    {
      title: 'Proje Kodu',
      dataIndex: 'projectCode',
      key: 'projectCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
      </span>,
       ...getColumnSearchProps('projectCode'),
       sorter: (a, b) => {
        const itemA = a && a.projectCode ? a.projectCode : '';
        const itemB = b && b.projectCode ? b.projectCode : '';
        return itemA.localeCompare(itemB);
      },
    },
  
   
  {
    title: 'Termin Tarihi',
    dataIndex: 'deadline',
    key: 'deadline',
    render: (text, record) => {
      const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
      return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
    },
    sorter: (a, b) => {
      const deadlineA = a.deadline ? a.deadline.seconds : 0;
      const deadlineB = b.deadline ? b.deadline.seconds : 0;
      return deadlineA - deadlineB;
  },
  
  },      
    {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
          Detayı Gör
       </Button>
        ),
      },
     
  ];

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Açık Siparişler</h3>
        <Input
        style={{
          borderRadius: 0,
          border: 'none',
          borderBottom: '1px solid #1A446C80',
          width: '100%',
          maxWidth: '400px',
          textAlign: 'left',
          outline: 'none',
        }}
        placeholder="İş Kodu Ara"
        suffix={
          <Space>
              <img width={15} alt="edit" src="/images/searchicon.png" />
          </Space>
        }
        onChange={(e) => {
          const searchValue = e.target.value.toLowerCase();
          //console.log("search value", searchValue)
          if(searchValue.length === 0){
            setFilteredItems(record);
           // console.log("items1", items)
          }
          else {
            const filteredData = items.filter(item => {
              const jobCode = item.jobCode.toString().toLowerCase() ;
              return jobCode.includes(searchValue);
            });
            setFilteredItems(filteredData);
            //console.log("items2", items)
          }
         
        }}
      />

        <Table
            locale={{
              emptyText: 'Henüz açık sipariş bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={filteredItems}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>

  };
export default OpenOrderGroupListDelete;
 