import React, { useEffect, useState } from "react";
import { Table, Button, Space, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import { colors, placeholderMap } from "../../../environments/environment";
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

const ScrapList = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getScrapsData();
      const filteredData = data.filter(item => !item.isDeleted);

      setItems(filteredData);
      setFilteredItems(filteredData);
    };
    fetchData();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase();
      const itemValue = item[dataIndex]?.toString().toLowerCase();
      return !item.isDeleted && (value ? itemValue.includes(value) : true);
    });
    setFilteredItems(filteredData);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, background: colors.blue }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });

  const columns = [
    {
      title: 'Verilen Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      ...getColumnSearchProps('companyName'),
      sorter: (a, b) => {
        const companyNameA = a && a.companyName ? a.companyName : '';
        const companyNameB = b && b.companyName ? b.companyName : '';
        return companyNameA.localeCompare(companyNameB);
      }
    },
    {
      title: 'Siyah',
      dataIndex: 'materials',
      key: 'black',
      render: (text, record) => `${record.materials.black.kg} kg / ${record.materials.black.price} TL`,
      sorter: (a, b) => a.materials.black.kg - b.materials.black.kg,
    },
    {
      title: 'Alüminyum',
      dataIndex: 'materials',
      key: 'aluminum',
      render: (text, record) => `${record.materials.aluminum.kg} kg / ${record.materials.aluminum.price} TL`,
      sorter: (a, b) => a.materials.aluminum.kg - b.materials.aluminum.kg,
    },
    {
      title: 'Krom',
      dataIndex: 'materials',
      key: 'chromium',
      render: (text, record) => `${record.materials.chromium.kg} kg / ${record.materials.chromium.price} TL`,
      sorter: (a, b) => a.materials.chromium.kg - b.materials.chromium.kg,
    },
    {
      title: 'Demir Tozu',
      dataIndex: 'materials',
      key: 'ironDust',
      render: (text, record) => `${record.materials.ironDust.kg} kg / ${record.materials.ironDust.price} TL`,
      sorter: (a, b) => a.materials.ironDust.kg - b.materials.ironDust.kg,
    },
  ];

  return (
    <div style={{ justifyContent: 'center', alignItems: 'center', minHeight: '94vh', padding: "3%" }}>
      <h3 style={{ textAlign: "left", color: colors.blue }}>Hurda Listesi</h3>
      <Table
        locale={{
          emptyText: 'Henüz eklemediniz...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
        dataSource={filteredItems}
        columns={columns}
        showHeader={true}
        pagination={false}
        className="custom-news-table"
        rowClassName="custom-news-row"
      />
    </div>
  );
};

export default ScrapList;
