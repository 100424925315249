import React,{useEffect,useState} from "react";
import { Table, Button,Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import  { colors}  from "../../../../environments/environment"
import moment from 'moment';


const OffersGivenGroupListLimited = () => {
  const [items, setItems] = useState([]);
  const [itemsLimited, setItemsLimited] = useState([]);
  const navigate = useNavigate();
 
  const groupByJobCode = (data) => {
    return data.reduce((groupedData, item) => {
      const { jobCode, companyName, projectCode, deadline, ...rest } = item;
      const newItem = { jobCode, companyName, projectCode, deadline, ...rest };
  
      const jobCodePrefix = jobCode.slice(0, 5);
  
      if (!groupedData[jobCodePrefix]) {
        groupedData[jobCodePrefix] = {
          companyName,
          jobCode: jobCodePrefix, 
          projectCode,
          deadline,
          data: [newItem]
        };
      } else {
        groupedData[jobCodePrefix].data.push(newItem);
      }
      
      return groupedData;
    }, {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getOpenOrderData();
      const filteredData = data.filter(item => !item.isDeleted && item.isWaiting );
      if (filteredData) {
        let updatedItems = filteredData.map(item => {
        
          let newStatus = "Unknown";
             if (item.isWaiting && !item.isSetup && item.orderType === "order" && item.stock != null ) {
            newStatus = "Sipariş,Setup Bekleniyor";
          } 
          else if (item.isWaiting && !item.isSetup && item.orderType === "order" &&  item.stock == null) {
            newStatus = "Sipariş, Güncel Stok Seçimi Bekleniyor";
          } 
          else if (item.isWaiting && !item.isSetup && item.orderType === "offer" && item.isOfferApproved && item.isOffer ) {
            newStatus = "Onaylandı, Setup Bekleniyor";
          }
          else if (item.isWaiting && item.isSetup && item.orderType === "offer" && !item.isOffer) {
            newStatus = "Teklif Bekleniyor";
          } 
          else if (item.isWaiting && !item.isSetup && item.orderType === "offer" && !item.isOffer) {
            newStatus = "Teklif, Setup Bekleniyor";
          } 
          else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange  && !item.isOfferSetupUpdate  && !item.isOfferDenied) {
            newStatus = "Teklif Verildi";
          }
          else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && item.isOfferApproved) {
            newStatus = "Onaylandı"; //bu durumun görünmeesi lazım onaylanan tekliflere düşcek
          }

          else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && item.isOfferChange  && !item.isOfferSetupUpdate  && !item.isOfferDenied) {
            newStatus = "Onaylanmadı,Teklif Değişikliği";
          }
          else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange  && item.isOfferSetupUpdate  && !item.isOfferDenied) {
            newStatus = "Onaylanmadı,Setup Düzenleme";
          }
          else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange  && !item.isOfferSetupUpdate  && item.isOfferDenied) {
            newStatus = "Onaylanmadı,Reddedildi";
          }
          
  
          return { ...item, status: newStatus };
        });
  
        const desiredStatuses = [
          "Teklif Bekleniyor",
          "Teklif Verildi",
          "Onaylanmadı, Teklif Değişikliği",
          "Onaylanmadı, Reddedildi"
        ];
        
        const filteredStatusItems = updatedItems.filter(item => desiredStatuses.includes(item.status));
        const groupedItems = Object.values(groupByJobCode(filteredStatusItems));
          
        setItems(groupedItems);
        setItemsLimited(groupedItems);
        
      }
    
    };

    fetchData();
  }, []);

 


  const goPage = (record) => {
    navigate('/offersgivengrouplist', { state: { record } })
  };

  const columns = [
    {
      title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
  
      
    
  ];

  return  <div style={{  
       // width:"50%",
       
      }}
      className="limited-list-border">
      <Button onClick={()=>goPage(items)} className="title-limited-list">
        Verilen Teklifler
       </Button>

       <Table
            locale={{
              emptyText: 'Henüz bekleyen sipariş bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={itemsLimited}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
      
     
    </div>

  };
  export default OffersGivenGroupListLimited;

    