import React, { useEffect, useState } from 'react';
import { Table, Input, Space, Avatar } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from '../../../services/firebaseService';
import NotificationService from '../../../services/antNotificationService';

const PersonnelList = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getUserData();
      const filteredData = data.filter(item => !item.isDeleted);

      setUsers(filteredData);
      setFilteredUsers(filteredData);
    };

    fetchData();
  }, []);

  const columns = [
    {
      title: ' ',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      width: 150,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={record.imageUrl || "/path/to/default-image.png"}
            size={50}
            shape="circle"
            style={{ marginRight: '10px' }}
          />
        </div>
      ),
    },
    {
      title: 'İsim Soyisim',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <span style={{ color: record.isDeleted ? "#00000080" : "#000000", fontWeight: "bold" }}>
          {record.name} {record.surname}
        </span>
      ),
    },
    {
      title: 'Telefon Numarası',
      dataIndex: 'email',
      key: 'phone',
      render: (text) => {
        const phoneNumber = text.split('@')[0];
        return <span style={{ color: "#000000", fontWeight: "bold" }}>{phoneNumber}</span>; 
      },
    },
    {
      title: 'Birim',
      dataIndex: 'userUnit',
      key: 'userUnit',
      render: (text, record) => (
        <span style={{ color: record.isDeleted ? "#00000080" : "#000000", fontWeight: "bold" }}>
          {text.join(', ')}
        </span>
      ),
    },
  ];

  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '94vh',
      padding: "3%"
    }}>
      <Input
        style={{
          borderRadius: 0,
          border: 'none',
          borderBottom: '1px solid #1A446C80',
          width: '100%',
          maxWidth: '400px',
          textAlign: 'left',
          outline: 'none',
        }}
        placeholder="Personel Ara"
        suffix={
          <Space>
            <img width={15} alt="search" src="/images/searchicon.png" />
          </Space>
        }
        onChange={(e) => {
          const searchValue = e.target.value.toLowerCase();
          const filteredData = users.filter(user => {
            const fullName = (user.name + ' ' + user.surname).toLowerCase();
            return fullName.includes(searchValue);
          });
          setFilteredUsers(filteredData);
        }}
      />

      <Table
        locale={{
          emptyText: 'Henüz kullanıcı eklemediniz...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
        pagination={false}
        showHeader={true}
        columns={columns}
        dataSource={filteredUsers}
        className="custom-news-table"
        rowClassName="custom-news-row"
      />
    </div>
  );
};

export default PersonnelList;
