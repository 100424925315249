

import React,{useEffect,useState} from "react";
import { Table, Button, Space, Input, DatePicker, Spin } from 'antd';
import FirebaseService from "../../../services/firebaseService";
import  { colors, placeholderMap}  from "../../../environments/environment"
import { SearchOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import moment from 'moment';

const ProductionReportingList = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [columns, setColumns] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const invoiceOpenOrderData = await FirebaseService.getInvoiceOpenOrderData();
      const openOrderData = await FirebaseService.getOpenOrderData();

      const contractManufacturingData = await FirebaseService.getContractManufacturingData();
   

      const filteredInvoiceOpenOrderData = invoiceOpenOrderData
        .filter(order => !order.isDeleted) 
        .map(order => {
          const orderJobCodePrefix = order.jobCode.substring(0, 5); 
          const correspondingInvoices = openOrderData
            .filter(invoice => 
              invoice.jobCode.substring(0, 5) === orderJobCodePrefix && !invoice.isDeleted 
            );

          
            const matchingContractManufacturing = contractManufacturingData
            .filter(manufacturing => {
              if (!manufacturing.jobCode || !orderJobCodePrefix) {
                return false; 
              }
              return manufacturing.jobCode.substring(0, 5) === orderJobCodePrefix && !manufacturing.isDeleted;
            });
          
        
          const priceOperations = matchingContractManufacturing.reduce((acc, manufacturing) => {
            const processName = manufacturing.processName; 
            if (!acc[processName]) {
              acc[processName] = {
                contractManufacturing: manufacturing.parts.reduce((total, part) => total + parseFloat(part.unitPrice), 0), // Total price calculation
                profit:  manufacturing.parts.reduce((total, part) => total + parseFloat(part.profit), 0), // Total price calculation
              };
            }
            return acc;
          }, {});

        
          return {
            ...order,
            invoiceTransactionDate: correspondingInvoices.length > 0 ? correspondingInvoices[0].invoiceTransactionDate : null ,
            invoiceTransactionMonth:correspondingInvoices.length > 0 ? correspondingInvoices[0].invoiceTransactionMonth :null,
            items: order.items.map(item => ({
              ...item, 
              priceOperations: priceOperations 
            }))
          };
          
        });

                  
      console.log("filteredInvoiceOpenOrderData", filteredInvoiceOpenOrderData);
      const aggregatedColumns = {}; 
      const totalAbkantWeight = 0;
      filteredInvoiceOpenOrderData.forEach(order => {
        const aggregatedData = {}; 

        order.items.forEach(item => {
          if (item.priceOperations) { 
              Object.entries(item.priceOperations).forEach(([operation, values]) => {
                  if (!aggregatedData[operation]) {
                      aggregatedData[operation] = { ...values }; 
                      
                      if (!aggregatedColumns[`${operation}_cost`]) {
                          aggregatedColumns[`${operation}_cost`] = {
                              title: `${operation} Maliyet`,
                              dataIndex: `${operation}_cost`,
                              key: `${operation}_cost`,
                              render: (text, record) => (
                                  <span>{record.aggregatedData[operation] ? `${record.aggregatedData[operation].contractManufacturing}` : '-'}</span>
                              ),
                          };
                      }
                      
                      if (!aggregatedColumns[`${operation}_total`]) {
                          aggregatedColumns[`${operation}_total`] = {
                              title: `${operation} Toplam Fiyat`,
                              dataIndex: `${operation}_total`,
                              key: `${operation}_total`,
                              render: (text, record) => (
                                  <span>
                                      {record.aggregatedData[operation] ? 
                                          `${record.aggregatedData[operation].contractManufacturing + record.aggregatedData[operation].profit}` 
                                          : '-'}
                                  </span>
                              ),
                          };
                      }
                  } else {
                      aggregatedData[operation].contractManufacturing += values.contractManufacturing;
                      aggregatedData[operation].profit += values.profit;
                  }
              });
          }
      });
      

        order.aggregatedData = aggregatedData;
      });

      filteredInvoiceOpenOrderData.forEach(order => {
        const abkantTotal = order.totalPricesAbkant?.Abkant || 0;
        const totalProfit = Object.values(order.aggregatedData).reduce((sum, item) => {
            const profit = item.profit || 0;
            return sum + profit;
        }, 0);
    
        const totalContractManufacturing = Object.values(order.aggregatedData).reduce((sum, item) => {
          const contractManufacturing = item.contractManufacturing || 0; // Her item'ın contractManufacturing değerini al
          const profit = item.profit || 0; // Her item'ın profit değerini al
          return sum + contractManufacturing + profit; // Toplamını hesapla
        }, 0);

        const oksijen5Total = order.totalPricesTime?.["5000-oksijen"] || 0;
        const azot5Total = order.totalPricesTime?.["5000-azot"] || 0;
        const oksijen10Total = order.totalPricesTime?.["10000-oksijen"] || 0;
        const azot10Total = order.totalPricesTime?.["10000-azot"] || 0;
    

       
        const totalAbkantWeight = filteredInvoiceOpenOrderData.reduce((total, order) => {
          if (order.items && Array.isArray(order.items)) {
            order.items.forEach((part) => {
              if (part.operations && Array.isArray(part.operations) && part.operations.some(op => op.toLowerCase() === 'abkant')) {
                total += (part.weightSetup || 0) * part.piece;
              }
            });
          }
          return total;
        }, 0);
        
        
        const priceWithoutKdv = (order.allTotalPrice / (1 + order.kdvPercent / 100)).toFixed(2);


        const totalLabor = Number(abkantTotal) + Number(totalProfit) + Number(oksijen5Total) + Number(oksijen10Total) + Number(azot5Total) + Number(azot10Total);
        order.totalLabor = totalLabor > 0 ? totalLabor.toFixed(2) : "-";
    
      
        const generalTotalPricesQuality = calculateTotalPrice(order.totalPricesQuality);
        order.generalTotalPricesQuality = generalTotalPricesQuality > 0 ? generalTotalPricesQuality.toFixed(2) : "-";
        
        order.totalProfit = totalProfit;
        order.totalContractManufacturing = totalContractManufacturing;
        order.abkantWeight = totalAbkantWeight;
        order.priceWithoutKdv = priceWithoutKdv;


      });

    
      setItems(filteredInvoiceOpenOrderData);
      setFilteredItems(filteredInvoiceOpenOrderData);
      setFilteredData(filteredInvoiceOpenOrderData);
      


      const newColumns = [
        ...defaultColumns,
        ...Object.values(aggregatedColumns),    
     
      ];
      
      setColumns(newColumns);
       // setColumns(defaultColumns)
    
    };
    fetchData();
  }, []);


  const sumValues = (data) => {
    if (!data) return 0;
    if (Array.isArray(data)) {
      return data.reduce((acc, obj) => acc + Object.values(obj).reduce((sum, val) => sum + val, 0), 0);
    }
    return Object.values(data).reduce((sum, val) => sum + val, 0);
  };

  const sumArrayValues = (data) => {
    if (!data) return 0;

  if (Array.isArray(data)) {
    return data.reduce((sum, item) => {
      if (typeof item === "object" && item !== null) {
        return sum + Object.values(item)
          .filter(val => !isNaN(parseFloat(val))) 
          .reduce((acc, val) => acc + parseFloat(val), 0);
      }
      return sum + (!isNaN(parseFloat(item)) ? parseFloat(item) : 0);
    }, 0);
  }

  if (typeof data === "object") {
    return Object.values(data)
      .filter(val => !isNaN(parseFloat(val)))
      .reduce((acc, val) => acc + parseFloat(val), 0);
  }

  return !isNaN(parseFloat(data)) ? parseFloat(data) : 0;
  };

  const calculateTotalPrice = (prices) => {
       return Object.values(prices).reduce((acc, value) => acc + value, 0);
  };

  useEffect(() => {
    if (!filteredData || filteredData.length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [filteredData]);
  

  const normalizeText = (text) => {
    if (!text) return '';
    
    const normalized = text
      .toString()
      .trim()
      .normalize('NFD') 
      .replace(/[\u0300-\u036f]/g, '') 
      .replace(/ı/g, 'i') 
      .replace(/i/g, 'İ') 
      .replace(/ç/g, 'Ç') 
      .replace(/ğ/g, 'Ğ') 
      .replace(/ö/g, 'Ö') 
      .replace(/ş/g, 'Ş') 
      .replace(/ü/g, 'Ü') 
      .toUpperCase(); 
  
    return normalized;
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setLoading(true);
    const value = normalizeText(selectedKeys[0] || ""); 

    if (!filteredData || filteredData.length === 0) {
        console.log("Veri yükleniyor...");
        setLoading(false);
        return;
    }

    const filteredData2 = filteredData.filter(item => {
        const itemValue = normalizeText(item[dataIndex] || ""); 
        return !item.isDeleted && (value ? itemValue.includes(value) : true);
    });

    setFilteredItems(filteredData2);
    setFilteredData(filteredData2);
    setLoading(false);
  };
      
  const handleReset = clearFilters => {
       clearFilters();
        setFilteredItems(items);
  };
    
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"  
          placeholder={`${normalizeText(placeholderMap[dataIndex] || dataIndex)} ARA`}
          value={selectedKeys[0] ? normalizeText(selectedKeys[0]) : ''}
          onChange={e => setSelectedKeys(e.target.value ? [normalizeText(e.target.value)] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
  
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, background: colors.blue }}
          >
            ARA
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            SIFIRLA
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => 
      normalizeText(record[dataIndex].toString()).includes(normalizeText(value)),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });
  
  const defaultColumns = [
      {
        title: "Ay",
        dataIndex: 'invoiceTransactionMonth',
        key: 'invoiceTransactionMonth',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
        sorter: (a, b) => {
          const invoiceTransactionDateMonthA = a && a.invoiceTransactionDateMonth ? a.invoiceTransactionDateMonth : '';
          const invoiceTransactionDateMonthB = b && b.invoiceTransactionDateMonth ? b.invoiceTransactionDateMonth : '';
          return invoiceTransactionDateMonthA.localeCompare(invoiceTransactionDateMonthB);
        },
      },
        
    {
      title: 'Termin Tarihi',
      dataIndex: 'invoiceTransactionDate',
      key: 'invoiceTransactionDate',
      render: (text, record) => {
        const formattedDeadline = record.invoiceTransactionDate ? new Date(record.invoiceTransactionDate.seconds * 1000).toLocaleDateString('tr-TR') : '';
        return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
      },
      sorter: (a, b) => {
        const deadlineA = a.invoiceTransactionDate ? a.invoiceTransactionDate.seconds : 0;
        const deadlineB = b.invoiceTransactionDate ? b.invoiceTransactionDate.seconds : 0;
        return deadlineA - deadlineB;
    },
    }
  ,  
  {
    title: 'Programcı',
    dataIndex: 'programmerName',
    key: 'programmerName',
    render: (text, record) => {
      // Eğer text bir dizi ise, ilk elemanı alıyoruz
      const programmerName = Array.isArray(text) ? text[0] : text;
      
      return (
        <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
          {programmerName ? programmerName : "-"}
        </span>
      );
    },
  }
,  
      {
        title: 'Fatura No',
        dataIndex: 'invoiceNumber',
        key: 'invoiceNumber',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
        
      },
      {
        title: 'Müşteri Firma',
        dataIndex: 'companyName',
        key: 'companyName',
        ...getColumnSearchProps('companyName'),
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
        sorter: (a, b) => {
          const companyNameA = a && a.companyName ? a.companyName : '';
          const companyNameB = b && b.companyName ? b.companyName : '';
          return companyNameA.localeCompare(companyNameB);
        },

      },
      {
        title: 'İş Kodu',
        dataIndex: 'jobCode',
        key: 'jobCode',
        ...getColumnSearchProps('jobCode'),
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,

      },
      {
        title: 'Para Birimi',
        dataIndex: 'currency',
        key: 'currency',
        ...getColumnSearchProps('currency'),
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
      },
    //5kw 
        {
          title: '5KW Oksijen Süre',
          dataIndex: 'setupTime_oxygen5',
          key: 'setupTime_oxygen5',
          render: (text, record) => {
            const value = sumValues(record.setupTimeChange?.["5000-oksijen"] ?? record.setupTime?.["5000-oksijen"]);         
            return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{value || "-"}</span>;
          },
        },
        {
          title: '5KW Oksijen Fiyat',
          dataIndex: 'totalPricesTime',
          key: 'totalPricesTime_price5',
          render: (text, record) => {
            const value = record.pricesTime?.["5000-oksijen"];
            const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0; 
            return (
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
                {numericValue > 0 ? `${numericValue.toFixed(2)}` : "-"}
              </span>
            );
          },
        },
        {
          title: '5KW Oksijen Toplam',
          dataIndex: 'totalPricesTime',
          key: 'totalPricesTime_total5',
          render: (text, record) => {
            const value = record.totalPricesTime?.["5000-oksijen"];
            const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
            return (
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
                {numericValue > 0 ? `${numericValue.toFixed(2)}` : "-"}
              </span>
            );
          },
        },
        {
          title: '5KW Azot Süre',
          dataIndex: 'setupTime',
          key: 'setupTime_nitrogen5',
          render: (text, record) => {
            const value = sumValues(record.setupTimeChange?.["5000-azot"] ?? record.setupTime?.["5000-azot"]);         
            return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{value || "-"}</span>;
          },
        },
        {
          title: '5KW Azot Fiyat',
          dataIndex: 'totalPricesTime',
          key: 'totalPricesTime_nitrogen5',
          render: (text, record) => {
            const value = record.pricesTime?.["5000-azot"];
            const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
            return (
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
                {numericValue > 0 ? `${numericValue.toFixed(2)}` : "-"}
              </span>
            );
          },
        },
        {
          title: '5KW Azot Toplam',
          dataIndex: 'totalPricesTime',
          key: 'totalPricesTime_total_nitrogen5',
          render: (text, record) => {
            const value = record.totalPricesTime?.["5000-azot"];
            const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
            return (
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
                {numericValue > 0 ? `${numericValue.toFixed(2)}` : "-"}
              </span>
            );
          },
        },
        {
          title: '5KW Genel Toplam',
          dataIndex: '',
          key: 'totalPricesTime_total_all5',
          render: (text, record) => {
            const totalOxygen = sumArrayValues(record.totalPricesTime?.["5000-oksijen"]);
            const totalNitrogen = sumArrayValues(record.totalPricesTime?.["5000-azot"]);
            return (
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
                {totalOxygen + totalNitrogen > 0 ? `${(totalOxygen + totalNitrogen).toFixed(2)}` : "-"}
              </span>
            );
          },
        },
      
        //10kw
        {
          title: '10KW Oksijen Süre',
          dataIndex: 'setupTime_oxygen10',
          key: 'setupTime_oxygen10',
          render: (text, record) => {
            const value = sumValues(record.setupTimeChange?.["10000-oksijen"] ?? record.setupTime?.["10000-oksijen"]);         
            return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{value || "-"}</span>;
          },
        },
        {
          title: '10KW Oksijen Fiyat',
          dataIndex: 'totalPricesTime',
          key: 'totalPricesTime_price10',
          render: (text, record) => {
            const value = record.pricesTime?.["10000-oksijen"];
            const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0; 
            return (
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
                {numericValue > 0 ? `${numericValue.toFixed(2)}` : "-"}
              </span>
            );
          },
        },
        {
          title: '10KW Oksijen Toplam',
          dataIndex: 'totalPricesTime',
          key: 'totalPricesTime_total10',
          render: (text, record) => {
            const value = record.totalPricesTime?.["10000-oksijen"];
            const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
            return (
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
                {numericValue > 0 ? `${numericValue.toFixed(2)}` : "-"}
              </span>
            );
          },
        },
        
        {
          title: '10KW Azot Süre',
          dataIndex: 'setupTime',
          key: 'setupTime_nitrogen10',
          render: (text, record) => {
            const value = sumValues(record.setupTimeChange?.["10000-azot"] ?? record.setupTime?.["10000-azot"]);         
            return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{value || "-"}</span>;
          },
        },
        {
          title: '10KW Azot Fiyat',
          dataIndex: 'totalPricesTime',
          key: 'totalPricesTime_nitrogen10',
          render: (text, record) => {
            const value = record.pricesTime?.["10000-azot"];
            const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
            return (
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
                {numericValue > 0 ? `${numericValue.toFixed(2)}` : "-"}
              </span>
            );
          },
        },
        {
          title: '10KW Azot Toplam',
          dataIndex: 'totalPricesTime',
          key: 'totalPricesTime_total_nitrogen10',
          render: (text, record) => {
            const value = record.totalPricesTime?.["10000-azot"];
            const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
            return (
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
                {numericValue > 0 ? `${numericValue.toFixed(2)}` : "-"}
              </span>
            );
          },
        },
        {
          title: '10KW Genel Toplam',
          dataIndex: '',
          key: 'totalPricesTime_total_all10',
          render: (text, record) => {
            const totalOxygen = sumArrayValues(record.totalPricesTime?.["10000-oksijen"]);
            const totalNitrogen = sumArrayValues(record.totalPricesTime?.["10000-azot"]);
            return (
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
                {totalOxygen + totalNitrogen > 0 ? `${(totalOxygen + totalNitrogen).toFixed(2)}` : "-"}
              </span>
            );
          },
        },
      //abkant

      {
        title: "Abkant Seçili Operasyon",
        dataIndex: 'abkantSelectedOperation',
        key: 'abkantSelectedOperation',
        ...getColumnSearchProps('abkantSelectedOperation'),
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
    },
    {
      title: "Abkant Miktarı/Sayısı",
      dataIndex: 'quantitiesAbkant',
      key: 'quantitiesAbkant',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text.Abkant ? text.Abkant  : record.abkantWeight + " Kg"}  </span>,
    },
    {
        title: "Abkant Fiyatı",
        dataIndex: 'pricesAbkant',
        key: 'pricesAbkant',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text.Abkant ? text.Abkant : "-"} </span>,
    },
    {
        title: "Abkant Toplam Fiyat",
        dataIndex: 'totalPricesAbkant',
        key: 'totalPricesAbkant',
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
              {text?.Abkant ? Number(text.Abkant).toFixed(2) : "-"}
          </span>
      ),
      },
      //fason
      {
        title: "ST kg",
        dataIndex: 'totalKg',
        key: 'st',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const stValues = Object.entries(text)
          .filter(([key]) => key.startsWith("ST") || key.includes("CK") || key.includes("Hardox"))
          .map(([key, value]) => `${key}: ${parseFloat(value).toFixed(2)}`)
            .join(", ");
          return stValues || "-";
        }
      },
      {
        title: "ST kg Fiyat",
        dataIndex: 'pricesQuality',
        key: 'stPrices',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const stPrices = Object.entries(text)
          .filter(([key]) => key.startsWith("ST") || key.includes("CK") || key.includes("Hardox"))
          .map(([key, value]) => `${key}: ${parseFloat(value).toFixed(2)}`)
            .join(", ");
          return stPrices || "-";
        }
      },
      {
        title: "ST Toplam",
        dataIndex: 'totalPricesQuality',
        key: 'stTotal',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const total = Object.entries(text)
          .filter(([key]) => key.startsWith("ST") || key.includes("CK") || key.includes("Hardox"))
          .reduce((sum, [, value]) => sum + parseFloat(value), 0);
          return total.toFixed(2);
        }
      },
      {
        title: "Krom kg",
        dataIndex: 'totalKg',
        key: 'krom',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const kromValues = Object.entries(text)
          .filter(([key]) => key.includes("Krom") || ["304", "316", "430"].some(val => key.includes(val)))
          .map(([key, value]) => `${key}: ${parseFloat(value).toFixed(2)}`)
            .join(", ");
          return kromValues || "-";
        }
      },
      {
        title: "Krom kg Fiyat",
        dataIndex: 'pricesQuality',
        key: 'kromPrices',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const kromPrices = Object.entries(text)
          .filter(([key]) => key.includes("Krom") || ["304", "316", "430"].some(val => key.includes(val)))
          .map(([key, value]) => `${key}: ${parseFloat(value).toFixed(2)}`)
            .join(", ");
          return kromPrices || "-";
        }
      },
      {
        title: "Krom Toplam",
        dataIndex: 'totalPricesQuality',
        key: 'stTotal',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const total = Object.entries(text)
          .filter(([key]) => key.includes("Krom") || ["304", "316", "430"].some(val => key.includes(val)))
          .reduce((sum, [, value]) => sum + parseFloat(value), 0);
          return total.toFixed(2);
        }
      },
      {
        title: "DKP kg",
        dataIndex: 'totalKg',
        key: 'dkp',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const dkpValues = Object.entries(text)
            .filter(([key]) => key.includes("DKP"))
            .map(([key, value]) => `${key}: ${parseFloat(value).toFixed(2)}`)
            .join(", ");
          return dkpValues || "-";
        }
      },
      {
        title: "DKP kg Fiyat",
        dataIndex: 'pricesQuality',
        key: 'dkpPrices',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const dkpPrices = Object.entries(text)
            .filter(([key]) => key.includes("DKP"))
            .map(([key, value]) => `${key}: ${parseFloat(value).toFixed(2)}`)
            .join(", ");
          return dkpPrices || "-";
        }
      },
      {
        title: "DKP Toplam",
        dataIndex: 'totalPricesQuality',
        key: 'stTotal',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const total = Object.entries(text)
            .filter(([key]) => key.startsWith("DKP"))
            .reduce((sum, [, value]) => sum + parseFloat(value), 0);
          return total.toFixed(2);
        }
      },
      {
        title: "Galvaniz kg",
        dataIndex: 'totalKg',
        key: 'galv',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const galvValues = Object.entries(text)
            .filter(([key]) => key.includes("Galv"))
            .map(([key, value]) => `${key}: ${parseFloat(value).toFixed(2)}`)
            .join(", ");
          return galvValues || "-";
        }
      },

      {
        title: "Galvaniz kg Fiyat",
        dataIndex: 'pricesQuality',
        key: 'galvPrices',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const galvPrices = Object.entries(text)
            .filter(([key]) => key.includes("Galv"))
            .map(([key, value]) => `${key}: ${parseFloat(value).toFixed(2)}`)
            .join(", ");
          return galvPrices || "-";
        }
      },
      {
        title: "Galvaniz Toplam",
        dataIndex: 'totalPricesQuality',
        key: 'stTotal',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const total = Object.entries(text)
            .filter(([key]) => key.startsWith("Galv"))
            .reduce((sum, [, value]) => sum + parseFloat(value), 0);
          return total.toFixed(2);
        }
      },

      {
        title: "Bakır kg",
        dataIndex: 'totalKg',
        key: 'bakırkg',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const dkpValues = Object.entries(text)
            .filter(([key]) => key.includes("Bakır"))
            .map(([key, value]) => `${key}: ${parseFloat(value).toFixed(2)}`)
            .join(", ");
          return dkpValues || "-";
        }
      },
      {
        title: "Bakır kg Fiyat",
        dataIndex: 'pricesQuality',
        key: 'bakırPrices',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const dkpPrices = Object.entries(text)
            .filter(([key]) => key.includes("Bakır"))
            .map(([key, value]) => `${key}: ${parseFloat(value).toFixed(2)}`)
            .join(", ");
          return dkpPrices || "-";
        }
      },
      {
        title: "Bakır Toplam",
        dataIndex: 'totalPricesQuality',
        key: 'bakırtotal',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const total = Object.entries(text)
            .filter(([key]) => key.startsWith("Bakır"))
            .reduce((sum, [, value]) => sum + parseFloat(value), 0);
          return total.toFixed(2);
        }
      },
      {
        title: "Pirinç kg",
        dataIndex: 'totalKg',
        key: 'pirinckg',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const dkpValues = Object.entries(text)
            .filter(([key]) => key.includes("DKP"))
            .map(([key, value]) => `${key}: ${parseFloat(value).toFixed(2)}`)
            .join(", ");
          return dkpValues || "-";
        }
      },
      {
        title: "Pirinç kg Fiyat",
        dataIndex: 'pricesQuality',
        key: 'pirinçPrices',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const dkpPrices = Object.entries(text)
            .filter(([key]) => key.includes("Pirinç"))
            .map(([key, value]) => `${key}: ${parseFloat(value).toFixed(2)}`)
            .join(", ");
          return dkpPrices || "-";
        }
      },
      {
        title: "Prinç Toplam",
        dataIndex: 'totalPricesQuality',
        key: 'princtotal',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const total = Object.entries(text)
            .filter(([key]) => key.startsWith("Pirinç"))
            .reduce((sum, [, value]) => sum + parseFloat(value), 0);
          return total.toFixed(2);
        }
      },
      {
        title: "Alm kg",
        dataIndex: 'totalKg',
        key: 'alm',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const dkpValues = Object.entries(text)
            .filter(([key]) => key.includes("alm"))
            .map(([key, value]) => `${key}: ${parseFloat(value).toFixed(2)}`)
            .join(", ");
          return dkpValues || "-";
        }
      },
      {
        title: "Alm kg Fiyat",
        dataIndex: 'pricesQuality',
        key: 'almPrices',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const dkpPrices = Object.entries(text)
            .filter(([key]) => key.includes("alm"))
            .map(([key, value]) => `${key}: ${parseFloat(value).toFixed(2)}`)
            .join(", ");
          return dkpPrices || "-";
        }
      },
      {
        title: "Alm Toplam",
        dataIndex: 'totalPricesQuality',
        key: 'almTotal',
        render: (text) => {
          if (!text || typeof text !== "object") return "-";
          const total = Object.entries(text)
            .filter(([key]) => key.startsWith("alm"))
            .reduce((sum, [, value]) => sum + parseFloat(value), 0);
          return total.toFixed(2);
        }
      },
      {
        title: 'Ödeme Türü',
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
        ...getColumnSearchProps('paymentMethod'),
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
      },
      {
        title: 'İskonto',
        dataIndex: 'discount',
        key: 'discount',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
      },
      {
        title: 'Teslimat Ücreti',
        dataIndex: 'deliveryPrice',
        key: 'deliveryPrice',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
      },
      
      //işçilik toplamı
      {
        title: 'İşçilik Toplam',
        dataIndex: 'totalLabor',
        key: 'totalLabor',
        render: (text, record) => {
         
          return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text ? text : "-"}</span>;

        },
      },  
      {
        title: 'Malzeme Toplam',
        dataIndex: 'generalTotalPricesQuality',
        key: 'generalTotalPricesQuality',
        render: (text, record) => {
            return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text ? text : "-"}</span>;
        },
    },
    {
      title: 'Fason Toplam Komisyon',
      dataIndex: 'totalProfit',
      key: 'totalProfit',
      render: (text, record) => {
        return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text ? text : "-"}</span>;
     },
      },
        
        {
          title: 'Fason Toplam',
          dataIndex: 'total',
          key: 'contracttotal',
          render: (text, record) => {
            const totalContractManufacturing = Object.values(record.aggregatedData).reduce((sum, item) => {
              const contractManufacturing = item.contractManufacturing || 0; // Her item'ın contractManufacturing değerini al
              const profit = item.profit || 0; // Her item'ın profit değerini al
              return sum + contractManufacturing + profit; // Toplamını hesapla
            }, 0);
        
            return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{totalContractManufacturing || "-"}</span>;
          },
        },
        {
          title: 'Genel Toplam Kdvsiz',
          dataIndex: 'priceWithoutKdv',
          key: 'priceWithoutKdv',
          render: (text, record) => {
            return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>;
          },
        },
        
      {
        title: 'Genel Toplam',
        dataIndex: 'allTotalPrice',
        key: 'allTotalPrice',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
      },
    
  ];
  
  const [selectedMonth, setSelectedMonth] = useState(null);

  const handleDateChange = (date) => {
    const formattedDate = date ? dayjs(date).format('YYYY-MM') : null;
    console.log("formattedDate", formattedDate);
    setSelectedMonth(formattedDate);
  
    // Eğer selectedMonth null değilse, filtreleme işlemi yapalım
    const filteredData2 = formattedDate
      ? filteredData.filter(item => {
          if (!item.invoiceTransactionDate || !item.invoiceTransactionDate.seconds) {
            console.log("Eksik veya hatalı tarih:", item);
            return false;
          }
  
          // Firebase Timestamp'i normal Date'e çevir
          const invoiceDate = dayjs(new Date(item.invoiceTransactionDate.seconds * 1000)); 
  
          if (!invoiceDate.isValid()) {
            console.log("Geçersiz Tarih:", item.invoiceTransactionDate);
            return false;
          }
  
          // invoiceDate'in formatlanmış haliyle selectedMonth'ı karşılaştır
          return invoiceDate.format('YYYY-MM') === formattedDate;
        })
      : filteredItems;  // Eğer selectedMonth null ise, filtreleme yapma
  
    console.log("filteredData2", filteredData2);
    setFilteredData(filteredData2);
  };
  
  
   const customMonths = [
        'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
        'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
   ];
    
    

// calculateTotalRow fonksiyonu
const calculateTotalRowPricesTime = (data, key) => {
  return data.reduce((acc, record) => {
    const value = record.pricesTime?.[key];  // "5000-oksijen" değerini al
    const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;  // Sayıya dönüştür
    return acc + numericValue;  // Toplamı hesapla
  }, 0);
};

const calculateTotalQuality = (data, key,key2) => {
  return data.reduce((acc, record) => {
    if (!record[key2]) return acc;

    const filteredKeys = Object.entries(record[key2])
      .filter(([k]) => {
        if (key === "ST") return k.startsWith("ST") || k.includes("CK") || k.includes("Hardox");
        if (key === "Krom") return k.includes("Krom") || ["304", "316", "430"].some(val => k.includes(val));
        if (key === "DKP") return k.includes("DKP");
        if (key === "Bakır") return k.includes("Bakır");
        if (key === "Pirinç") return k.includes("Pirinç");
        if (key === "Galv") return k.includes("Galv");
        if (key === "Alm") return k.includes("Alm");


        return false;
      })
      .map(([_, value]) => value); 

    const totalForRecord = filteredKeys.reduce((sum, value) => {
      const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
      return sum + numericValue;
    }, 0);

    return acc + totalForRecord;
  }, 0);
};



const calculateTotalRowPricesTimeTotal = (data, key) => {
  return data.reduce((acc, record) => {
    const value = record.totalPricesTime?.[key];  // "5000-oksijen" değerini al
    const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;  // Sayıya dönüştür
    return acc + numericValue;  // Toplamı hesapla
  }, 0);
};


const calculateTotalRowPricesTimeTotalGeneral = (data, key,key2) => {
  return data.reduce((acc, record) => {
    const value = record.totalPricesTime?.[key];  // "5000-oksijen" değerini al
    const value2 = record.totalPricesTime?.[key2];  // "5000-oksijen" değerini al

    const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;  // Sayıya dönüştür
    const numericValue2= !isNaN(parseFloat(value2)) ? parseFloat(value2) : 0;  // Sayıya dönüştür

    return acc + numericValue + numericValue2;  // Toplamı hesapla
  }, 0);
};


const calculateTotalRow = (data, key) => {
  return data.reduce((acc, record) => {
    const value = record[key]?.Abkant;  // Burada doğru alanı kullandık.
    const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;  // Sayıya dönüştür
    return acc + numericValue;  // Toplamı hesapla
  }, 0);
};

const calculateTotalRowGeneral= (data, key) => {
  return data.reduce((acc, record) => {
    const value = record[key];  // Burada doğru alanı kullandık.
    const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;  // Sayıya dönüştür
    return acc + numericValue;  // Toplamı hesapla
  }, 0);
};

const sumTimeStrings = (timeStrings) => {
  const totalSeconds = timeStrings.reduce((sum, time) => {
    if (!time) return sum;
    const [hh, mm, ss] = time.split(":").map(Number);
    return sum + hh * 3600 + mm * 60 + ss;
  }, 0);

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
};

const calculateTotalTimeSetup = ( data ,key) => {
  const total = sumTimeStrings(
    data.map((record) => sumValues(record.setupTimeChange?.[key] ?? record.setupTime?.[key]))
  );

  return total;
};


// filteredData'dan toplam fiyatı hesaplama
const totalPricesQualityRow5Oxygen = calculateTotalRowPricesTime(filteredData, "5000-oksijen");
const totalPricesQualityRow50nitrogen = calculateTotalRowPricesTime(filteredData, "5000-azot");
const totalPricesQualityRow10000Oxygen = calculateTotalRowPricesTime(filteredData, "10000-oksijen");
const totalPricesQualityRow10000nitrogen = calculateTotalRowPricesTime(filteredData, "10000-azot");


const totalPricesQualityRow5OxygenTotal = calculateTotalRowPricesTimeTotal(filteredData, "5000-oksijen");
const totalPricesQualityRow50nitrogenTotal = calculateTotalRowPricesTimeTotal(filteredData, "5000-azot");
const totalPricesQualityRow10000OxygenTotal = calculateTotalRowPricesTimeTotal(filteredData, "10000-oksijen");
const totalPricesQualityRow10000nitrogenTotal = calculateTotalRowPricesTimeTotal(filteredData, "10000-azot");


const totalPricesQualityRow50000TotalGeneral = calculateTotalRowPricesTimeTotalGeneral(filteredData, "5000-oksijen", "5000-azot");
const totalPricesQualityRow100000TotalGeneral = calculateTotalRowPricesTimeTotalGeneral(filteredData, "10000-oksijen", "10000-azot");


const totalQuantitiesAbkant = calculateTotalRow(filteredData, "quantitiesAbkant");
const totalQuantitiesAbkantPrice = calculateTotalRow(filteredData, "pricesAbkant");
const totalQuantitiesAbkantPriceTotal = calculateTotalRow(filteredData, "totalPricesAbkant");


const totalDiscount = calculateTotalRowGeneral(filteredData, "discount");
const totalDelivery = calculateTotalRowGeneral(filteredData, "deliveryPrice");

const totalLabor = calculateTotalRowGeneral(filteredData, "totalLabor");
const generalTotalPricesQuality = calculateTotalRowGeneral(filteredData, "generalTotalPricesQuality");
const allTotalPrice = calculateTotalRowGeneral(filteredData, "allTotalPrice");
const priceWithoutKdv = calculateTotalRowGeneral(filteredData, "priceWithoutKdv");

const totalTimeSetup5koxygen = calculateTotalTimeSetup(filteredData, "5000-oksijen");
const totalTimeSetup5knitrogn = calculateTotalTimeSetup(filteredData, "5000-azot");

const totalTimeSetup10koxygen = calculateTotalTimeSetup(filteredData, "10000-oksijen");
const totalTimeSetup10knitrogn = calculateTotalTimeSetup(filteredData, "10000-azot");

const totalSTKg = calculateTotalQuality(filteredData, "ST","totalKg");
const totalKromKg = calculateTotalQuality(filteredData, "Krom","totalKg");
const totalDKPKg = calculateTotalQuality(filteredData, "DKP","totalKg");
const totalGalvKg = calculateTotalQuality(filteredData, "Galv","totalKg");
const totalBakırKg = calculateTotalQuality(filteredData, "Bakır","totalKg");
const totalPirinçKg = calculateTotalQuality(filteredData, "Pirinç","totalKg");
const totalAlmKg = calculateTotalQuality(filteredData, "Alm","totalKg");


const totalSTPrice = calculateTotalQuality(filteredData, "ST","pricesQuality");
const totalKromPrice = calculateTotalQuality(filteredData, "Krom","pricesQuality");
const totalDKPPrice = calculateTotalQuality(filteredData, "DKP","pricesQuality");
const totalGalvPrice = calculateTotalQuality(filteredData, "Galv","pricesQuality");
const totalBakırPrice = calculateTotalQuality(filteredData, "Bakır","pricesQuality");
const totalPirinçPrice = calculateTotalQuality(filteredData, "Pirinç","pricesQuality");
const totalAlmPrice = calculateTotalQuality(filteredData, "Alm","pricesQuality");



const totalSTPriceTotal = calculateTotalQuality(filteredData, "ST","totalPricesQuality");
const totalKromPriceTotal = calculateTotalQuality(filteredData, "Krom","totalPricesQuality");
const totalDKPPriceTotal = calculateTotalQuality(filteredData, "DKP","totalPricesQuality");
const totalGalvPriceTotal = calculateTotalQuality(filteredData, "Galv","totalPricesQuality");
const totalBakırPriceTotal = calculateTotalQuality(filteredData, "Bakır","totalPricesQuality");
const totalPirinçPriceTotal = calculateTotalQuality(filteredData, "Pirinç","totalPricesQuality");
const totalAlmPriceTotal = calculateTotalQuality(filteredData, "Alm","totalPricesQuality");
 
const totalProfit = calculateTotalRowGeneral(filteredData, "totalProfit");
const totalContractManufacturing = calculateTotalRowGeneral(filteredData, "totalContractManufacturing");





moment.locale('tr');

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
      }}>
        <h3  style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>Üretim Raporlama</h3>
        <DatePicker          
        format={(date) => customMonths[date.month()]} 
        onChange={handleDateChange} 
        picker="month" 
        style={{ marginBottom: 16, float: "right" }} placeholder="Ay Seçiniz"/>

          <Table
            locale={{
              emptyText: 'Henüz eklemediniz...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={filteredData}
            columns={columns}
            showHeader={true}
            pagination={false}
            className="custom-news-table"
            rowClassName="custom-news-row"
             summary={() => {
                if (!items || items.length === 0) {
                  return null; 
                }
            
                return (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}><strong>Toplamlar</strong></Table.Summary.Cell>
                    <Table.Summary.Cell index={1} /> 
                    <Table.Summary.Cell index={2} /> 
                    <Table.Summary.Cell index={3} /> 
                    <Table.Summary.Cell index={4} /> 
                    <Table.Summary.Cell index={5} /> 
                    <Table.Summary.Cell index={6}>{}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={7}>{totalTimeSetup5koxygen}</Table.Summary.Cell>
                    <Table.Summary.Cell index={8}>{totalPricesQualityRow5Oxygen.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={9}>{totalPricesQualityRow5OxygenTotal.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={10}>{totalTimeSetup5knitrogn}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={11}>{totalPricesQualityRow50nitrogen.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={12}>{totalPricesQualityRow50nitrogenTotal.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={13}>{totalPricesQualityRow50000TotalGeneral.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={14}>{totalTimeSetup10koxygen}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={15}>{totalPricesQualityRow10000Oxygen.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={16}>{totalPricesQualityRow10000OxygenTotal.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={17}>{totalTimeSetup10knitrogn}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={18}>{totalPricesQualityRow10000nitrogen.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={19}>{totalPricesQualityRow10000nitrogenTotal.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={20}>{totalPricesQualityRow100000TotalGeneral.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={21}>{}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={22}>{totalQuantitiesAbkant}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={23}>{totalQuantitiesAbkantPrice.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={24}>{totalQuantitiesAbkantPriceTotal.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={25}>{totalSTKg.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={26}>{totalSTPrice.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={27}>{totalSTPriceTotal.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={28}>{totalKromKg.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={29}>{totalKromPrice.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={30}>{totalKromPriceTotal.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={31}>{totalDKPKg.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={32}>{totalDKPPrice.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={33}>{totalDKPPriceTotal.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={34}>{totalGalvKg.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={35}>{totalGalvPrice.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={36}>{totalGalvPriceTotal.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={37}>{totalBakırKg.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={38}>{totalBakırPrice.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={39}>{totalBakırPriceTotal.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={40}>{totalPirinçKg.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={41}>{totalPirinçPrice.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={42}>{totalPirinçPriceTotal.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={43}>{totalAlmKg.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={44}>{totalAlmPrice.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={45}>{totalAlmPriceTotal.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={46}>{}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={47}>{totalDiscount}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={48}>{totalDelivery.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={49}>{totalLabor.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={50}>{generalTotalPricesQuality.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={51}>{totalProfit.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={52}>{totalContractManufacturing.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={53}>{priceWithoutKdv.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={53}>{allTotalPrice.toFixed(2)}</Table.Summary.Cell> 
                    <Table.Summary.Cell index={54}>{}</Table.Summary.Cell> 
                    
                    
                  </Table.Summary.Row>
                );
              }}
        />
         {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

      
    </div>
  };
  export default ProductionReportingList;