import React,{useEffect,useState} from "react";
import { Button, Form, Input,Table ,Checkbox, Modal} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import  {colors}  from "../../../environments/environment"
import { he } from "date-fns/locale";


const PurchaseRequestDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [checkedItems, setCheckedItems] = useState({});
    const record = location.state && location.state.record;
    const [dataSource, setDataSource] = useState([]);
    const [selectedItemsDetails, setSelectedItemsDetails] = useState([]);

    function generateRandomId() {
  return Math.random().toString(36).substr(2, 5);
}
   
    const handleCheckboxChange = async (productId, jobCode, piece, productDictionaryOrString) => {
      console.log("productDictionaryOrString",productDictionaryOrString)
      setCheckedItems(prevItems => {
        const isChecked = prevItems[`${productId}-${jobCode}-${piece}`]; 
        const updatedItems = { ...prevItems, [`${productId}-${jobCode}-${piece}`]: !isChecked };
        const selectedItems = Object.keys(updatedItems).filter(id => updatedItems[id]);
    
        (async () => {
          const detailedSelectedItems = await Promise.all(
            selectedItems.map(async id => {
              console.log("id",id)
              const [idPart, jobcodePart1,jobcodePart2, piecePart] = id.split('-'); 
              console.log("idPart",idPart,);
              console.log("jobcodePart1",jobcodePart1,);
              console.log("jobcodePart2",jobcodePart2,);
              console.log("piecePart",piecePart,);

            
              if(jobcodePart1 === "null"){
                console.log("jobcode null")
                const [idPart, jobcodePart1, piecePart] = id.split('-'); 

                const productInfo = record.requestedProductInfo.find(info => info.id === idPart);
                const productDetail = await getProductDetailById(idPart);
                console.log("productDetail 1",idPart,jobcodePart1,jobcodePart2,piecePart,)
  
                if (!productDetail) {
  
                  const productDictionary = {
                    height: productInfo.height,
                    quality: productInfo.quality,
                    surface: productInfo.surface,
                    thickness: productInfo.thickness,
                    width: productInfo.width,
                  };
                
                  console.log("productDictionary", productDictionary);
                
                  return {
                   productDictionary,
                   jobCode: productInfo.jobCode,
                   piece: productInfo.piece,
                   productString:null,
                   id:productInfo.id,
                   description:"Teknik ressam tarafından seçilmiştir, setupda kullanılacaktır.", 
                   code:productInfo.code,
                   minPiece:productInfo.minPiece
                  };
                }
                console.log("jobcoeeee",jobcodePart1,"part2",jobcodePart2);
  
                return {
                  
                  ...productInfo,
                  productDictionary: productDetail.productDictionary,
                  productString: productDetail.productString,
                 
                  jobCode:  null, 
                  piece: Number(piecePart),
                };
              }
              else if(piecePart === undefined){ //jobcode sadece 5 haneli ise buraya geliyor 

                console.log("buraya ggeldi jobcode sadece 5 haneli ise buraya geliyor " )
                const productInfo = record.requestedProductInfo.find(info => info.id === idPart);
                const productDetail = await getProductDetailById(idPart);
                console.log("productInfo",productInfo)
                console.log("productDetail 2",idPart,jobcodePart1,jobcodePart2,piecePart,)
  
                if (!productDetail) {
  
                  const productDictionary = {
                    height: productInfo.height,
                    quality: productInfo.quality,
                    surface: productInfo.surface,
                    thickness: productInfo.thickness,
                    width: productInfo.width,
                  };
                
                  console.log("productDictionary", productDictionary);
                
                  return {
                   productDictionary,
                   jobCode: jobcodePart1,
                   piece: jobcodePart2,
                   productString:null,
                   id:productInfo.id,
                   description:"" 
                  };
                }
                return {
                  
                  ...productInfo,
                  productDictionary: productDetail.productDictionary,
                  productString: productDetail.productString,
                  jobCode: jobcodePart1 ,
                  piece: Number(jobcodePart2),
                  
                };

              }

              else if (idPart === "null") {
                console.log("id yoksa buraya geliyor productDictionaryOrString", productDictionaryOrString);
                
                // Varsayılan productInfo oluşturulması
                const productInfo = {
                  id: generateRandomId(), // Rastgele bir id oluşturulması
                  height: productDictionaryOrString.height || null,
                  quality: productDictionaryOrString.quality || null,
                  surface: productDictionaryOrString.surface || null,
                  thickness: productDictionaryOrString.thickness || null,
                  width: productDictionaryOrString.width || null,
                  jobCode: productDictionaryOrString.jobCode || null,
                  piece: productDictionaryOrString.piece || null,
                  description: productDictionaryOrString.description || null,
                  productString: productDictionaryOrString.productString || null,
                };
                
                console.log("productInfo", productInfo);
                console.log("productDetail 2", idPart, jobcodePart1, jobcodePart2, piecePart);
              
                return {
                  code:productDictionaryOrString.code,
                  companiesAndPrice:null,
                  description:"Gelecek hammadede iade edilen ürün için yapılmıştır.",
                  minPiece:productDictionaryOrString.minPiece,
                  id:null,
                  productDictionary: productDictionaryOrString,
                  productString: null,
                  jobCode: jobcodePart1 + "-" + jobcodePart2,  // satın alma talebi için düzeltildi
                  piece: Number(piecePart),
                };
              }
                 
              else{
                const productInfo = record.requestedProductInfo.find(info => info.id === idPart);
                const productDetail = await getProductDetailById(idPart);
                console.log("productInfo",productInfo)
                console.log("productDetail 2",idPart,jobcodePart1,jobcodePart2,piecePart,)
  
                if (!productDetail) {
  
                  const productDictionary = {
                    height: productInfo.height,
                    quality: productInfo.quality,
                    surface: productInfo.surface,
                    thickness: productInfo.thickness,
                    width: productInfo.width,
                  };
                
                  console.log("productDictionary", productDictionary);
                
                  return {
                   productDictionary,
                   jobCode: productInfo.jobCode,
                   piece: productInfo.piece,
                   productString:null,
                   id:productInfo.id,
                   description:"Gelecek hammade iade edilen ürün tekrar gönderimi yapılacaktır." 
                  };
                }
                console.log("jobcoeeee aaaaaa",jobcodePart1,"part2",jobcodePart2);
  
                return {
                  
                  ...productInfo,
                  productDictionary: productDetail.productDictionary,
                  productString: productDetail.productString,
                  jobCode: jobcodePart1 + "-" + jobcodePart2,  //satın alma talebi için düzeltilid
                  piece: Number(piecePart),
                  
                };
              }
              
            
            })
          );
    
          console.log("checked items global", detailedSelectedItems);
          setSelectedItemsDetails(detailedSelectedItems);
        })();
    
        return updatedItems;
      });
    };
    

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      try {
        
        const updatedRequestedProductInfo = await Promise.all(
          record.requestedProductInfo.map(async (productInfo) => {
           // console.log("id",productInfo.id)
            const productDetail = await getProductDetailById(productInfo.id);
            return {
              ...productInfo,
              productDictionary:productDetail.productDictionary,
              productString:productDetail.productString,
            };
          })
        );
        setDataSource(updatedRequestedProductInfo);
        
      } catch (error) {
     // console.log("bjbjbj")
      
        const updatedRequestedProductInfo = await Promise.all(
          record.requestedProductInfo.map(async (productInfo) => {
            console.log("burda id productInfo",productInfo)

            return {
              ...productInfo,
              productDictionary:productInfo,
              productString:productInfo,
            };
            
          })
        );
      
       setDataSource(updatedRequestedProductInfo);
        
      }
      
    };
  
    fetchData();
  }, [record.requestedProductInfo]);
  
  const getProductDetailById = async (productId) => {
    try {
      const productDetail = await FirebaseService.getAllItemtDataById(productId);
      return productDetail;
    } catch (error) {
      console.error('Hata:', error);
      return null;
    }
  };
  
  const goPage = (record) => {
   
    if(selectedItemsDetails.length > 0){
    navigate('/purchaserequestsendmail', { state: {selectedItemsDetails, record } })

    }
    else{
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Lütfen ürün seçiniz",
        placement: 'topRight'
      });
      return;
    }
  };
   
  const handleUpdate = async (record) => {
    const currentDate =new Date()
    let updateItem = {
      isPurchased: true,
      isPurchasedDate: currentDate,
    };

    if(selectedItemsDetails.length > 0){
      const result = await FirebaseService.updatePurchaseData(record.key,updateItem);
      //  console.log(result);
        if(result){
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description:"Başarıyla kaydedilmiştir",
            placement: 'topRight'
          });
          setTimeout(() => {
            navigate("/buyerpage");
          }, 2000);
        }
        else{
          
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz",
            placement: 'topRight'
          });
          
        } 
  
      }
      else{
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Lütfen ürün seçiniz",
          placement: 'topRight'
        });
        return;
      }
   
  };

  const handleNoPurchaseUpdate = async () => {
    const currentDate =new Date()
    let updateItem = {
      isPurchased: false,
      noPurchasedDescription:noPurchasedDescription
     
    };

    const result = await FirebaseService.updatePurchaseData(record.key,updateItem);
      //  console.log(result);
        if(result){
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description:"Başarıyla kaydedilmiştir",
            placement: 'topRight'
          });
          setIsModalVisible(false);
          setTimeout(() => {
            navigate("/buyerpage");
          }, 2000);
        }
        else{
          
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
            placement: 'topRight'
          });
          
        } 
  
      
      
   
  };
  const columnsRaw = [
      {
        title: ' ',
        dataIndex: '',
        key: '',
        width: 200,
        render: (text, record) => (
          <Checkbox
          onChange={() => handleCheckboxChange(record.id, record.jobCode, record.piece, record.productDictionary)}
          checked={checkedItems[`${record.id}-${record.jobCode}-${record.piece}`]}
          style={{ fontWeight: '500' }}
        />
        ),
      },
      {
        title: 'İş Kodu',
        dataIndex: 'jobCode',
        key: 'jobCode',
        width: 200,
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>   {text !== null ? text : '-'}</span>,
    },
    {
      title: 'Kalite',
      dataIndex: 'productDictionary',
      key: 'productDictionary',
      width: 200,
      render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text.quality}</span>,
    },

      {
        title: 'Kalınlık',
        dataIndex: 'productDictionary',
        key: 'productDictionary',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text.thickness}</span>,
      },

     
      {
        title: 'En',
        dataIndex: 'productDictionary',
        key: 'productDictionary',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text.width}</span>,
      },
      {
        title: 'Boy',
        dataIndex: 'productDictionary',
        key: 'productDictionary',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text.height}</span>,
      },

      {
        title: 'Yüzey',
        dataIndex: 'productDictionary',
        key: 'productDictionary',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text.surface}</span>,
      },
 
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        width: 200,
      },
        
      {
        title: 'Açıklama',
        dataIndex: 'description',
        key: 'description',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>   {text !== null ? text : '-'}</span>,
        width: 200,
      },
        
           
           
    
    ];

  const columnsOther = [
      {
        title: ' ',
        dataIndex: '',
        key: '',
        width: 200,
        render: (text, record) => (
          <Checkbox
          onChange={() => handleCheckboxChange(record.id, record.jobCode, record.piece, record.productString)}
          checked={checkedItems[`${record.id}-${record.jobCode}-${record.piece}`]}
          style={{ fontWeight: '500' }}
        />
           
       
        ),
      },
      
      {
        title: 'İş Kodu',
        dataIndex: 'jobCode',
        key: 'jobCode',
        width: 200,
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>   {text !== null ? text : '-'}</span>,
    },
    {
      title: 'Ürün',
      dataIndex: 'productString',
      key: 'productString',
      width: 200,
      render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
    },
 
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        width: 200,
      },
        
      {
        title: 'Açıklama',
        dataIndex: 'description',
        key: 'description',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>   {text !== null ? text : '-'}</span>,
        width: 200,
      },
        
           
           
    
  ];
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [noPurchasedDescription, setNoPurchasedDescription] = useState('');

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleInputChange =  (e) => {
    setNoPurchasedDescription(e.target.value);
  };

  const showModal = () => {
    if(selectedItemsDetails.length > 0){
      setIsModalVisible(true);
    }
    else{
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Lütfen ürün seçiniz",
          placement: 'topRight'
        });
        return;
    }
   

  };

  return ( 
    <div style={{ display: "flex", flexDirection: "column",padding:"10px 100px"}}>
  
      <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}> Satın Alma Gelen Talep</h2>
          
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Firma</p>
          {record && record.companyName}
        </div>
        {/* 

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Teklif İş Kodu</p>
          {record && record.offerJobCode !== null ? record.offerJobCode : '-'}
        </div>
*/}
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Ürün</p>
          {record && record.productType === 'raw' ? 'Saç Levha' : 'Diğer'}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Kullanıcı</p>
          {record && record.firstUserNameSurname}
        </div>
       
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Talep Tarihi</p>
          {record && record.firstTransactionDate ? (
            isNaN(Date.parse(record.firstTransactionDate)) ? (
              "Tarih formatı hatalı"
            ) : (
              new Date(record.firstTransactionDate).toLocaleDateString('tr-TR')
            )
          ) : (
            "Tarih bulunamadı"
          )}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Durum</p>
          {record.productType === "raw" ?
        record.mailList?.length >= 3 ? "Teklif Gönderildi" : "Teklif Gönderilmedi"   :
        record.isPurchased ? "Satın Alındı" :   record.noPurchasedDescription ? 
        "Satın Alınmayacak" : "Satın Alınma Bekleniyor"
        }       
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
          {record && record.deadline && typeof record.deadline === 'object' ?
            new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') :
            record.deadline
          }
        </div>
       
      </div>

      <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px" }}>Talep Edilen Ürünler</div>
      <Table
      style={{width:"70%", margin:"auto"}}
            locale={{
              emptyText: 'Henüz satın alma talebi bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
           dataSource={dataSource}
          columns={record.productType ==="raw" ? columnsRaw : columnsOther}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
        <div style={{display:"flex", justifyContent:"right"}}>
       {record.productType === 'raw' ? 
        <Button 
        style={{
         backgroundColor: record.mailList?.length >= 3 ? "gray" : colors.blue,
         color:"white",fontWeight:"500"}}
         onClick={()=>goPage(record)} 
         disabled={record.mailList?.length >= 3  ? true : false}

         >
       Devam
       </Button>
       :
       <>
       <Button 
       style={{
        backgroundColor: record.isPurchased  || record.noPurchasedDescription  ? colors.gray : colors.blue,
        color:"white",fontWeight:"500"}}
        onClick={()=>handleUpdate(record)} 
        disabled={record.isPurchased || record.noPurchasedDescription ? true : false}
        >
       Satın Al
      </Button>

      <Button 
      style={{
      backgroundColor: record.isPurchased  || record.noPurchasedDescription  ? colors.gray : colors.blue,
      color:"white",fontWeight:"500"}}
      onClick={showModal}
      disabled={record.isPurchased || record.noPurchasedDescription ? true : false}
      >
      Satın Alınma Gerçekleşmedi
      </Button>
      </>


      }

<Modal
        title="Satın Alınmama Nedeni"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            İptal
          </Button>,
          <Button key="save"   style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}  onClick={handleNoPurchaseUpdate}>
            Kaydet
          </Button>,
        ]}
      >
        <Input
          placeholder=""
          value={noPurchasedDescription}
          onChange={handleInputChange}
          style={{ marginTop: '10px' }}
        />
      </Modal>


        
        </div>
    </div>


  )};
export default PurchaseRequestDetail;