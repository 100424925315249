import React,{useEffect,useState} from "react";
import { Button, Form, Input,Modal,Select,Space,Spin, Upload} from 'antd';
import NotificationService from "../../services/antNotificationService";

import FirebaseService from "../../services/firebaseService";
import  { parsCollections,machineType}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import FirebaseStorage from "../../services/storegeService";


const MachineAdd = () => {
  const navigate = useNavigate();
  const [type, setType] = useState(''); 
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');

  useEffect(() => {
      const fetchData = async () => {
     
      };
      fetchData();
  }, []);

  const onFinish = async (values) => {
      setLoading(true)
        const currentDate =new Date()

        const all = await FirebaseService.getMachinesData();
        const newItems = all.filter((entry) => entry.machineName.toLowerCase() === values.machineName.toLowerCase());

         console.log("newItems",newItems)

         if(newItems.length > 0){
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Böyle bir makine bulunmaktadır.",
            placement: 'topRight'
          });
          setLoading(false)
          return;
         }

        const data = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          isDeleted: false,
          machineName: values.machineName,
          machineType : type
       
        };

        values.image=fileList.length > 0 ? values.image : null;


      try {
        if (values.image) {
          data.imageUrl = await FirebaseStorage.uploadImageAndGetURL(parsCollections.machines, values.image.file);
        const result = await FirebaseService.addRecording(parsCollections.machines,data);
        if (result.success) {
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: result.message,
            placement: 'topRight'
          });
          setLoading(false);
          setTimeout(() => {
            navigate("/machinelist");
          }, 2000);
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });
          setLoading(false);

        }
        
        }else{
          NotificationService.openErrorNotification({
            title: 'Geçersiz Resim',
            description: "Lütfen resim seçiniz!",
            placement: 'topRight'
          });
          setLoading(false);

        }
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
        setLoading(false);

      }
  }; 

  const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
  };
  
  const handleType = (value) => {
      console.log(`selected ${value}`);
      setType(value);
  };

  const getBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
  });

  const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <div style={{width:"1000px" }}>
      <img alt="plus" src="/images/uploadplus.png" />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Resim Yükle (1050x250 pixel boyutları önerilir)
      </div>
    </div>
  );

  const handleCancel = () => setPreviewOpen(false);

  return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 2,
            }}
            wrapperCol={{
            span: 8,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

          <Space style={{display:"flex"}}>
            <Form.Item 
            name="image"
            rules={[
              {
              required: true,
              message: 'Lütfen resim seçiniz!',
              },
          ]}
            >
                    <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        className="generic-add-upload"
                        beforeUpload={() => false}
                        >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>                
            </Form.Item>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                      <img
                      alt="example"
                      style={{
                          width: '100%',
                      }}
                      src={previewImage}
                      />
            </Modal>
          </Space>

            <Form.Item
              label="Makine Adı"
            name="machineName"
            rules={[
                {
                required: true,
                message: 'Lütfen makine adı giriniz!',
                },
            ]}
            
            >
          <Input className="input-style" style={{width:"100%"}} placeholder="Makine Adı"/>
            </Form.Item>

            <Form.Item
            label="Tür"
          name="orderType"
          rules={[
            {
              required: true,
              message: 'Lütfen seçiniz!',
            },
          ]}
         
        >
          <Select
          showSearch
        className="input-style"
         style={{width:"100%"}}
            onChange={handleType} 
            placeholder="Lazer/Abkant"
          
            suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
            options={machineType.map(item => ({ label: item.label, value: item.value }))}
            filterOption={(input, option) => 
              option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
            }
          
          />
            </Form.Item>

            
            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" loading={loading} style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>)

        };
  export default MachineAdd;