import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,DatePicker, Modal, message,Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections,colors}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddRequestAdminSupplier from "../addRequestAdmin/addRequestAdminSupplier";
import AddRequestAdminCompany from "../addRequestAdmin/addRequestAdminCompany";
import AddRequestAdminContractManufacturingProcess from "../addRequestAdmin/addRequestAdminContractManufacturingProcess";
import { useLocation  } from 'react-router-dom';
import dayjs from 'dayjs';
import moment from 'moment';

const ContractManufacturingEdit = ({ record,onClose }) => {
    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]); 
    const [suppliers, setSuppliers] = useState([]); 
    const [process, setProcess] = useState([]); 
    const [jobCodes, setJobCodes] = useState([]); 
    const [loading, setLoading] = useState(false);

  
    const dateFormat = 'DD/MM/YYYY'; 
   

  let formattedDeadline = null;
  if( record.deadline ){
    const seconds = record.deadline.seconds;
    const milliseconds = record.deadline.nanoseconds / 1000000; 
    const timestamp = new Date(seconds * 1000 + milliseconds);
     formattedDeadline = dayjs(timestamp).format(dateFormat);

        //  console.log("formattedDeadline", formattedDeadline);

  }
  
 
    useEffect(() => {
      const fetchData = async () => {
        const companies = await FirebaseService.getCompaniesData();
        const filteredCompanies = companies.filter(item => !item.isDeleted);
        setCompanies(filteredCompanies);

        const processes = await FirebaseService.getContractManufacturingProcessesData();
        const filteredProcesses = processes.filter(item => !item.isDeleted);
        setProcess(filteredProcesses);

        const suppeliers = await FirebaseService.getSuppliersData();
        const filteredSuppliers = suppeliers.filter(item => !item.isDeleted);
        setSuppliers(filteredSuppliers);

        const jobCodes = await FirebaseService.getJobCodesData();
        const filteredJobCodes = jobCodes.filter(item => !item.isDeleted);
        setJobCodes(filteredJobCodes);
      };
      fetchData();
    }, []);
    
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };

    
    const isDateBeforeToday = (dateString) => {
      const selectedDate = new Date(dateString);
      const today = new Date(); 
      selectedDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
        return selectedDate < today;
    };
    
    const onFinish = async (values) => {
      setLoading(true)
    if (isDateBeforeToday(values.deadline) || !values.deadline ){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Teslim tarihi bugünün tarihinden küçük veya boş olamaz.",
        placement: 'topRight'
      });
      setLoading(false)
      return;
    }

      let parts = null;
      if (values.supplierName === "newSupplier") {
        NotificationService.openErrorNotification({
          title: 'Hata',
          description: 'Lütfen var olan bir tedarikçi seçiniz.',
          placement: 'topRight'
        });setLoading(false)
        return;
      }
      

      console.log("additionalContents initial:", additionalContents); // Check the initial state of additionalContents

      if (record.processName.includes('plazma')) {
        if (!additionalContents) {
          console.log('additionalContents is null or undefined');
          return;
        }
        
        parts = additionalContents.map((content, index) => ({
          partCode: values[`partCode-${index}`] || '',
          unitPrice: values[`unitPrice-${index}`] || '', 
          piece: values[`piece-${index}`] || '', 
          profit: values[`profit-${index}`] || '', 
        }));
        console.log('Tüm Parts Bilgileri: plazma var', parts);
      }
      else {
        console.log('additionalContents before processing:', additionalContents); 
      
        if (!additionalContents) {
          console.log('additionalContents is null or undefined');
          return;
        }
  
        console.log('Tüm Parts Bilgileri plazma yok:', additionalContents);
      
        const hasEmptyUnitPrice = additionalContents.some(part => part.unitPrice == null || part.unitPrice === '');
        const hasEmptyProfitPrice = additionalContents.some(part => part.profit == null || part.profit === '');
        
      
        if (hasEmptyUnitPrice) {
          NotificationService.openErrorNotification({
            title: 'Uyarı',
            description: 'Lütfen tüm parçaların fason fiyatını giriniz!',
            placement: 'topRight'
          });setLoading(false)
          
          return;
        } 
      
        if (hasEmptyProfitPrice) {
          NotificationService.openErrorNotification({
            title: 'Uyarı',
            description: 'Lütfen tüm parçaların kar marjı giriniz!',
            placement: 'topRight'
          });
          setLoading(false)
          return;
        } 
      }
      
  
      let deadlineTimestamp=null;

      if(record.deadline !== null){
        const seconds = record.deadline.seconds;
        const nanoseconds = record.deadline.nanoseconds;
        const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1000000);
        deadlineTimestamp = new Date(milliseconds);
      }
    

      const data = {
          companyName: record.companyName,
          supplierName: values.supplierName,
          jobCode: record.jobCode,
         deadline: values.deadline === undefined ? deadlineTimestamp : new Date(values.deadline),
         openOrderDeadline : values.openOrderDeadline === undefined ? record.openOrderDeadline: new Date(values.openOrderDeadline),
          processName: record.processName,
         // description: values.description,
          isContractManufacturing : record.isContractManufacturing,
          isShipment :record.isShipment,
          isRequestCompleted :true,
          parts:additionalContents,
          isCompleted:false,

        };
        
      console.log("data",data)
      
      try {
        const result = await FirebaseService.updateRecording(parsCollections.contractManufacturing,record.key,data);
        if (result.success) {
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: "Başarıyla kaydedildi",
            placement: 'topRight'
          });setLoading(false)
          
          onClose();
          setTimeout(() => {
            window.location.reload();
          }, 500); 
        
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });setLoading(false)
        }
        
        
      } catch (error) {
        console.log('Firebase Güncelleme Hatası:', error);
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });setLoading(false)
      }
      
      
    }; 

    const onChange = (value) => {
      if (value === 'newSupplier') {
          console.log('Yeni Tedarikçi Ekle seçildi');
          setIsModalVisibleSupplier(true);
      } 
      else{
        
      }
  };
  const [isModalVisibleSupplier, setIsModalVisibleSupplier] = useState(false);

  const handleModalCancelSupplier = () => {
      setIsModalVisibleSupplier(false);
  };

  const handleAddFinishSupplier = () => {
      setIsModalVisibleSupplier(false);
  };

  const [additionalContents, setAdditionalContents] = useState([]);

  useEffect(() => {
    setAdditionalContents(record.parts || []);
  }, [record]);

  const handlePartChange = (e, index, field) => {
    const { value } = e.target;
    const updatedParts = [...additionalContents];
    updatedParts[index][field] = value;
    setAdditionalContents(updatedParts);
   
  };

  const addAdditionalContent = () => {
    const newContent = {
      partCode: '',
      unitPrice: '',
      piece: ''
    };
    setAdditionalContents([...additionalContents, newContent]);
  };
  
  const removeAdditionalContent = (contentIndex) => {
    const updatedContents = additionalContents.filter((content, index) => index !== contentIndex);
    setAdditionalContents(updatedContents);
  };

  const disabledPastDates = (current) => {
    return current < moment().startOf('day');
  };
  
  
 
   return ( <div>
         <Form
            name="basic"
            labelCol={{
            span: 5,
            }}
            wrapperCol={{
            span: 5,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 0%",
            textAlign:"left"
            }}
            initialValues={{
              companyName: record.companyName,
              supplierName: record.supplierName,
              jobCode: record.jobCode,
         
              processName: record.processName,
               isContractManufacturing :record.isContractManufacturing,
               isShipment :record.isShipment,
               isRequestCompleted :record.isRequestCompleted,
               parts:record.parts
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

        
        <div style={{ display:"flex", }}>
          <p style={{  }}>Firma : </p>
         <p className="contract-manufacturing-edit-input input-style" style={{marginLeft:"140px"}}> {record && record.companyName}</p>
        </div>     

          {record.supplierName ?
                <>
            
                    <div style={{ display:"flex"}}>
                          <p style={{  }}>Tedarikçi : </p>
                        <p className="contract-manufacturing-edit-input input-style " style={{marginLeft:"120px"}}> {record && record.supplierName}</p>
                        </div>   
                </> 
                :
                <>
                  <Form.Item
                              name="supplierName"
                              label="Tedarikçi"
                              rules={[
                                {
                                required: true,
                                message: 'Lütfen tedarikçi giriniz!',
                                },
                            ]}
                              >
                              <Select
                              showSearch
                              className="input-style"
                                  onChange={onChange}
                                  placeholder="Tedarikçi Seçiniz"
                                  style={{width:"200px",marginLeft:"85px" }}
                                  suffixIcon={<img style={{ width: '20px' }} alt="edit" src="/images/downarrow.png" />}
                                  options={[
                                    ...suppliers.map(item => ({ label: item.supplierName, value: item.supplierName })),
                                    { label: 'Yeni Tedarikçi Ekle', value: 'newSupplier' },
                                  ]}
                                  filterOption={(input, option) => 
                                    option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
                                  }
                                />
                              
                  </Form.Item>

                  <Modal
                                  title="Tedarikçi Ekleme Talebi"
                                  visible={isModalVisibleSupplier}
                                  onCancel={handleModalCancelSupplier}
                                  footer={null}
                                >
                                <AddRequestAdminSupplier  onClose={handleAddFinishSupplier} />
                  </Modal>
                </>
          }
          
            
       <div style={{ display:"flex", }}>
          <p style={{  }}>İş Kodu :</p>
          <p className="contract-manufacturing-edit-input input-style" style={{marginLeft:"130px", width:"200px"}}>   {record && record.jobCode ? record.jobCode : '-'}
          </p>
        </div>

        <div style={{ display:"flex", }}>
          <p style={{  }}>Proje Kodu :</p>
          <p className="contract-manufacturing-edit-input input-style" style={{marginLeft:"110px", width:"200px"}}> {record && record.projectCode}</p>
        </div>
           
        <div style={{ display:"flex", }}>
              <p style={{  }}>Açık Sipariş Termin Tarihi:</p>
              <p className="contract-manufacturing-edit-input input-style" style={{marginLeft:"20px"}} >
                {record && record.openOrderDeadline && typeof record.openOrderDeadline === 'object' ?
                new Date(record.openOrderDeadline.seconds * 1000).toLocaleDateString('tr-TR') :
               "-"
              }</p>
            </div>


            {record.deadline ? 
            <div style={{ display:"flex",}}>
            <p style={{  }}>Termin Tarihi:</p>
            <p className="contract-manufacturing-edit-input input-style" style={{marginLeft:"100px"}} >
              {record && record.deadline && typeof record.deadline === 'object' ?
              new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') :
              "-"
            }</p>
            </div>
            :
            <Form.Item
            name="deadline"
            label="Termin Tarihi"
            rules={[
              {
              required: true,
              message: 'Lütfen termin tarihi giriniz!',
              },
            ]}
            >
            <DatePicker style={{ width: '200px',marginLeft:"85px" }} placeholder="Termin Tarihi" 
            className="input-style" 
            defaultValue={record.deadline === null  ? null :    moment(formattedDeadline, dateFormat)}
            format={dateFormat} 
            disabledDate ={disabledPastDates} />


              </Form.Item>
            }

          

          {/*  <Form.Item
          name="openorderDeadline"
          label="Açık Sipariş Termin Tarihi"
        
        >
            <DatePicker
          style={{ width: '100%' }}
          placeholder="Açık Sipariş Termin Tarihi"
          defaultValue={record.openOrderDeadline ? moment(formattedOopenOrderDeadline, dateFormat) : null}
          format={dateFormat}
        />

        </Form.Item>
                  
        */} 

          <div style={{ display:"flex",}}>
            <p style={{ }}>Prosesler</p>
            <p className="contract-manufacturing-edit-input input-style" style={{marginLeft:"125px"}}> {record && record.processName}</p>


          
           
          </div>
          {/*  
            <Form.Item
            name="description"
            rules={[
                {
                required: true,
                message: 'Lütfen açıklama giriniz!',
                },
            ]}
          
            >
           <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Açıklama"/>
            </Form.Item>

     */}  

     {record.processName.includes('plazma') ?
     <>
       <div style={{  }}>
  <p style={{ color: colors.blue, fontWeight: "bold" }}>Parça Ekle</p>

  {additionalContents?.map((content, index) => (
    <div style={{ display: "flex" }} key={index}>
      <Form.Item
        name={`partCode-${index}`}
        rules={[{ required: true, message: "Parça adı" }]}
      >
        <Input style={{ width:"150px" }}  className="input-style" placeholder="Parça Adı" />
      </Form.Item>

      <Form.Item
        name={`piece-${index}`}
        rules={[{ pattern: /^\d+$/, message: "Sadece rakam girişi yapınız.",required: true, }]}
      >
        <Input style={{  width: "70px" }} className="input-style" placeholder="Adet" />
      </Form.Item>


      <Form.Item
        name={`unitPrice-${index}`}
        rules={[{ pattern: /^\d+(\.\d{1,2})?$/, message: "Geçerli fason fiyatı giriniz." ,required: true,}]}
      >
        <Input style={{  width: "100px" }} className="input-style" placeholder="Fason Fiyatı (Tl)" />
      </Form.Item>

      
      <Button onClick={() => removeAdditionalContent(index)}>-</Button>
    </div>
  ))}

  <Form.Item style={{ textAlign: "center" }}>
    <Button onClick={addAdditionalContent}>+</Button>
  </Form.Item>
</div></> 
     : 
     <>
      <div style={{   }}>
          <p style={{}}>Parçalar</p>
          <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left", fontWeight: "bold" }}>
            <div style={{ width: "230px", marginRight: "10px" }}>Parça Adı</div>
            <div style={{ width: "70px", marginRight: "10px" }}>Adet</div>
            <div style={{ width: "70px", marginRight: "10px" }}>Fason Fiyatı (TL)</div>
            <div style={{ width: "70px", marginRight: "10px" }}>Kar Marjı (TL)</div>

          </div>
          {record.parts.map((part, index) => (
            <div key={index} style={{ marginBottom: "10px", textAlign:"left" }}>
            
              <Input
              className= {part.partCode ? "contract-manufacturing-edit-input input-style pointer-none" : null}
              value={part.partCode}
                onChange={(e) => handlePartChange(e, index, 'partCode')}
                placeholder="Parça Kodu"
                style={{ width: "230px", marginRight: "10px" }}
                disabled = {part.partCode ? true : false}
              />
              <Input
              className= {part.piece ? "contract-manufacturing-edit-input input-style pointer-none" : null}
              value={part.piece}
                onChange={(e) => handlePartChange(e, index, 'piece')}
                placeholder="Adet"
                style={{ width: "70px", marginRight: "2px" }}
                disabled = {part.piece ? true : false}

              />
              <Input
            //  className= {part.unitPrice ? "contract-manufacturing-edit-input input-style pointer-none" : null}
                className= "input-style"
                value={part.unitPrice}
                onChange={(e) => handlePartChange(e, index, 'unitPrice')}
                placeholder="Fason Fiyatı"
                style={{ width: "70px", marginRight: "2px" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if (
                    (charCode !== 46 || e.target.value.includes('.')) && 
                    (charCode < 48 || charCode > 57) 
                  ) {
                    e.preventDefault();
                  }
                }}
              //  disabled = {part.unitPrice ? true : false}

              />
                
                <Input
             // className= {part.profit ? "contract-manufacturing-edit-input input-style pointer-none" : null}
                className= "input-style"
                value={part.profit}
                onChange={(e) => handlePartChange(e, index, 'profit')}
                placeholder="Kar Marjı"
                style={{ width: "70px", marginRight: "2px" }}
              //  disabled = {part.profit ? true : false}

              />
                
            </div>
          ))}
        </div>
          </>
     }


       

      


        <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
          {record.isRequestCompleted ? null : <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>}   
            </Form.Item>

        </Form>

        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>)

};

ContractManufacturingEdit.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

  export default ContractManufacturingEdit;