import React,{useEffect,useState} from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";

const WantedOfferListLimited = () => {
    const [items, setItems] = useState([]);
    const [itemsLimited, setItemsLimited] = useState([]);
  
    const navigate = useNavigate();
  
    useEffect(() => {
      window.scrollTo(0, 0);
  
      const fetchData = async () => {
        const data = await FirebaseService.getPurchasesData();
        data.sort((a, b) => {
          if (!a.deadline && !b.deadline) return 0;
          if (!a.deadline) return 1;
          if (!b.deadline) return -1;
          return a.deadline.toMillis() - b.deadline.toMillis();
        });
        const limitedData = data.slice(0, 5); 
        setItemsLimited(limitedData)
        setItems(data)
      };
  
      fetchData();
    }, []);
  
    
  
    const goPage = (record) => {
        navigate('/wantedofferlist', { state: { record } })
      };
    

    const columns = [
      {
        title: 'Sipariş Kodu',
        dataIndex: 'offerJobCode',
        key: 'offerJobCode',
       
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
      {
        title: 'Ürün',
        dataIndex: 'productType',
        key: 'productType',
     
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text === 'raw' ? 'Saç Levha' : text === 'other' ? 'Diğer' : text}
        </span>,
      },
      
        {
          title:"Durum",
          dataIndex: '',
          key: '',
          render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{record.incomingExcelList ?  record.incomingExcelList.length : 0 } / {record.mailList.length} teklif yüklendi , {record.acceptExcelDocumentUrl ? "Seçildi" : "Seçilmedi"}</span>,
      },
     {
        title: 'Termin Tarihi',
        dataIndex: 'deadlineForPayment',
        key: 'deadlineForPayment',
        render: (text, record) => {
          const deadline = record.deadlineForPayment;
          const formattedDeadline = deadline
            ? new Date(deadline.seconds * 1000).toLocaleDateString('tr-TR')
            : 'Termin tarihi bulunamadı';
          return (
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
              {formattedDeadline}
            </span>
          );
        },
      },       
     
    ];
  
    return  <div
        className="limited-list-border">
        <Button onClick={()=>goPage(items)} className="title-limited-list">
          İstenen Teklif Listesi
         </Button>
            <Table
              locale={{
                emptyText: 'Henüz istenen teklif bulunmamaktadır...',
                filterReset: 'Sıfırla',
                filterTitle: 'Filtre Menüsü',
                selectAll: 'Hepsini Seç',
                selectInvert: 'Tersini Seç',
                selectionAll: 'Tümünü Seç',
                sortTitle: 'Sıralama',
                triggerDesc: 'Azalan sıralama için tıklayın',
                triggerAsc: 'Artan sıralama için tıklayın',
                cancelSort: 'Sıralamayı iptal etmek için tıklayın',
              }}
              dataSource={itemsLimited}
              columns={columns}
              showHeader={true}
              pagination={false} 
              className="custom-news-table"
              rowClassName="custom-news-row"
          />
      </div>
};
export default WantedOfferListLimited;
