import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Spin, InputNumber } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";

const StockListAdminEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const record = location.state && location.state.record;
  const [loading, setLoading] = useState(false);
  
  // Stok verilerinden dinamik olarak çekilecek seçenekler
  const [surfaceOptions, setSurfaceOptions] = useState([]);
  const [qualityOptions, setQualityOptions] = useState([]);
  const [companyNameOptions, setCompanyNameOptions] = useState([]);
  const [supplierNameOptions, setSupplierNameOptions] = useState([]);
  const [depotNameOptions, setDepotNameOptions] = useState([]);
  
  // Stok verilerini yükleme
  useEffect(() => {
    const fetchStockData = async () => {
      const stockData = await FirebaseService.getStocksData();
      const densitiesData = await FirebaseService.getDensitiesData(); // Density verilerini çek
      const companiesData = await FirebaseService.getCompaniesData(); // Company ve depot verileri
      const mailsData = await FirebaseService.getMailsData(); // Supplier verileri

      console.log("mailsData", mailsData)


      // Benzersiz surface değerlerini alalım
      const surfaceSet = new Set();
      const qualitySet = new Set();
      const companyNameSet = new Set();
      const supplierNameSet = new Set();
      const depotNameSet = new Set();

      stockData.forEach(item => {
        if (item.surface) surfaceSet.add(item.surface);
        if (item.quality) {
          qualitySet.add(item.quality); // Kalite seçeneklerini al
        }
      });

      companiesData.forEach(item => {
        if (item.companyName && !item.isDeleted) companyNameSet.add(item.companyName);
        if (item.companyName && !item.isDeleted) depotNameSet.add(item.companyName);
      });

      // supplierName verilerini mailsData'dan al
      mailsData.forEach(item => {
        if (item.companyName && !item.isDeleted) supplierNameSet.add(item.companyName);
      });

      // Density değerlerini kaliteye göre eşle
      const densityValues = densitiesData.reduce((acc, item) => {
        acc[item.quality] = item.density; // item.quality ve item.density'yi kullan
        return acc;
      }, {});

      const qualityOptionsArray = Array.from(qualitySet).map(quality => ({
        quality,
        density: densityValues[quality] // Varsayılan density
      }));

      setSurfaceOptions([...surfaceSet]);     // Yüzey seçenekleri
      setQualityOptions(qualityOptionsArray);  // Kalite seçenekleri
      setCompanyNameOptions([...companyNameSet]);
      setSupplierNameOptions([...supplierNameSet]);
      setDepotNameOptions([...depotNameSet]);
    };

    fetchStockData();
  }, []);

  const calculateUnitKg = (thickness, width, height, density) => {
    return (width * height * thickness * density) / 1000000; // kg cinsinden
  };

  const handleFormChange = (changedValues, allValues) => {
    const { thickness, width, height, quality } = allValues;

    // Kalite seçildiğinde density değerini ayarlama
    let density = 1; // Varsayılan density

    const selectedQuality = qualityOptions.find(item => item.quality === quality);
    if (selectedQuality) {
      density = selectedQuality.density; // density değerini burada alıyoruz
      //console.log("density", density);
    } 

    // unitKg'yi hesapla
    if (thickness && width && height && density) {
      const newUnitKg = calculateUnitKg(thickness, width, height, density);
      record.unitKilo = newUnitKg; // Güncelleme
      //console.log("Calculated unitKilo:", newUnitKg); // Debugging
    }
  };

  console.log("record",record)

  const onFinish = async (values) => {
    setLoading(true);
    const updatedData = { 
      ...record, 
      ...values // unitKilo'yu burada güncelleyin
    }; 

    try {
      await FirebaseService.updateStock(record.key, updatedData); // Firebase'e güncelleme
      NotificationService.openSuccessNotification({
        title: 'Başarılı',
        description: 'Stok başarıyla güncellendi.',
        placement: 'topRight'
      });
      setLoading(false);
      navigate('/stocklistadmin');
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'Hata',
        description: 'Stok güncellenemedi, lütfen tekrar deneyin.',
        placement: 'topRight'
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({
      title: 'Geçersiz Form',
      description: 'Lütfen zorunlu alanları doldurunuz.',
      placement: 'topRight'
    });
  };

  return (
    <div>
      <Form
        name="basic"
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 8 }}
        style={{ maxWidth: "100%", padding: "5% 0 0 5%" }}
        initialValues={{
          thickness: record?.thickness || "",
          surface: record?.surface || "",
          quality: record?.quality || "",
          width: record?.width || "",
          height: record?.height || "",
          piece: record?.piece || 0,
          reservedPiece: record?.reservedPiece || 0,
          waitingPiece: record?.waitingPiece || 0,
          companyName : record?.companyName ||" ",
          supplierName : record?.supplierName || " ",
          depotName : record?.depotName || " "
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={handleFormChange} // Formdaki değişiklikler için çağır
        autoComplete="off"
      >

         {/* Firma */}
         <Form.Item
          label="Firma"
          name="companyName"
          rules={[{ required: true, message: 'Lütfen firma seçiniz!' }]}
        >
          <Select
            showSearch
            placeholder="Firma Seçiniz"
            className="input-style"
            style={{ width: "100%" }}
            filterOption={(input, option) => 
              option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
            }
          >
            {companyNameOptions.map(option => (
              <Select.Option key={option} value={option} label={option}>{option}</Select.Option>
            ))}
          </Select>
        </Form.Item>
          
        {/* Tedarikçi */}
        <Form.Item
          label="Tedarikçi"
          name="supplierName"
          rules={[{ required: true, message: 'Lütfen tedarikçi seçiniz!' }]}
        >
          <Select
            showSearch
            placeholder="Tedarikçi Seçiniz"
            className="input-style"
            style={{ width: "100%" }}
            filterOption={(input, option) => 
              option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
            }
          >
            {supplierNameOptions.map(option => (
              <Select.Option key={option} value={option} label={option}>{option}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        {/* Depo */}
        <Form.Item
          label="Depo"
          name="depotName"
          rules={[{ required: true, message: 'Lütfen depo seçiniz!' }]}
        >
          <Select
            showSearch
            placeholder="Depo Seçiniz"
            className="input-style"
            style={{ width: "100%" }}
            filterOption={(input, option) => 
              option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
            }
          >
            {depotNameOptions.map(option => (
              <Select.Option key={option} value={option} label={option}>{option}</Select.Option>
            ))}
          </Select>
        </Form.Item>


        {/* Kalınlık */}
        <Form.Item
          label="Kalınlık"
          name="thickness"
          rules={[{ required: true, message: 'Lütfen kalınlık giriniz!' }]}
        >
          <InputNumber className="input-style" style={{ width: "100%" }} placeholder="Kalınlık (mm)" />
        </Form.Item>

        {/* Kalite */}
        <Form.Item
          label="Kalite"
          name="quality"
          rules={[{ required: true, message: 'Lütfen kalite seçiniz!' }]}
        >
          <Select showSearch placeholder="Kalite Seçiniz" className="input-style" style={{ width: "100%" }}>
            {qualityOptions.map(option => (
              <Select.Option key={option.quality} value={option.quality}>{option.quality}</Select.Option>
            ))}
            filterOption={(input, option) => 
      option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
    }
          </Select>
        </Form.Item>

        {/* Yüzey */}
        <Form.Item
          label="Yüzey"
          name="surface"
          rules={[{ required: true, message: 'Lütfen yüzey seçiniz!' }]}
        >
          <Select showSearch placeholder="Yüzey Seçiniz" className="input-style" style={{ width: "100%" }}>
            {surfaceOptions.map(option => (
              <Select.Option key={option} value={option}>{option}</Select.Option>
            ))}
            filterOption={(input, option) => 
      option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
    }
          </Select>
        </Form.Item>

        {/* En */}
        <Form.Item
          label="En"
          name="width"
        >
          <InputNumber className="input-style" style={{ width: "100%" }} placeholder="En" />
        </Form.Item>

        {/* Boy */}
        <Form.Item
          label="Boy"
          name="height"
        >
          <InputNumber className="input-style" style={{ width: "100%" }} placeholder="Boy" />
        </Form.Item>

        {/* Adet */}
        <Form.Item
          label="Adet"
          name="piece"
        >
          <InputNumber className="input-style" style={{ width: "100%" }} placeholder="Adet" />
        </Form.Item>

        {/* Rezerve Adet */}
        <Form.Item
          label="Rezerve Adet"
          name="reservedPiece"
        >
          <InputNumber className="input-style" style={{ width: "100%" }} placeholder="Rezerve Adet" />
        </Form.Item>

        {/* Beklenen Adet */}
        <Form.Item
          label="Beklenen Adet"
          name="waitingPiece"
        >
          <InputNumber className="input-style" style={{ width: "100%" }} placeholder="Beklenen Adet" />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 15,
            span: 18,
          }}
        >
          <Button htmlType="submit" loading={loading} style={{ backgroundColor: "#1A446C", color: "white", fontWeight: "500" }}>
            Kaydet
          </Button>
        </Form.Item>
      </Form>

      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}>
          <Spin />
        </div>
      )}
    </div>
  );
};

export default StockListAdminEdit;
