import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import  { colors}  from "../../../../environments/environment"
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";

const OrderOperationPriceView = () => {

  const navigate = useNavigate();
  const location = useLocation();


  const { itemDetails } = location.state || {};


console.log("itemDetails",itemDetails)

useEffect(() => {
  window.scrollTo(0, 0);
}, []);
  function convertToMinutes(timeString) {
    const { hours, minutes, seconds } = parseTime(timeString);
    return hours * 60 + minutes + seconds / 60;
  }
  function parseTime(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return { hours, minutes, seconds };
  }
  
  const columns = [
    {
      title: 'No',
      dataIndex: 'partNo',
      key: 'partNo',
      width : 200,
      render: (text, record, index) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{index + 1}</span>,
    },
    {
      title: 'Müşteri',
      dataIndex: 'customerName',
      key: 'customerName',    
      width : 200,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
      
    },

  
    {
      title: 'Parça No',
      dataIndex: 'partNo',
      key: 'partNo',    
      width : 200,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
        width : 200,
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
      },
      {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
        width : 200,
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
     },
    {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        width : 200,
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
    
      },
      { 
        title: 'Kesim Süresi',
        dataIndex: "time",
        key: "time",
        width : 200,
        render: (text,record) => <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
      },
      {
        title: ' Toplam Kesim Süresi',
        dataIndex: '',
        key: '',
        width : 200,
        render: (text, record) => {
          // Zamanı dakika cinsine dönüştür
          const timeInMinutes = convertToMinutes(record.time) || 0;
          const piece = parseFloat(record.piece) || 0;
          const totalMinutes = timeInMinutes * piece;
          const totalSeconds = totalMinutes * 60;
      
          // HH:MM:SS formatında süreyi formatla
          const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
          const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
          const seconds = String(Math.floor(totalSeconds % 60)).padStart(2, '0');
      
          const formattedTime = `${hours}:${minutes}:${seconds}`;
      
          return (
            <span style={{ display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap', color: record.isDeleted ? "#00000080" : "#000000" }}>
              {formattedTime}
            </span>
          );
   
        },
        

      },
      {
        title: 'Hesaplanan Kesim Süresi',
        dataIndex: 'newTime',
        key: 'newTime',
        width : 200,
        render: (text,record) => <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
       
      },
    
    
      {
        title: 'Hesaplanan Toplam Kesim Süresi',
        dataIndex: '',
        key: '',
        width : 200,
        render: (text, record) => {
          // Zamanı dakika cinsine dönüştür
          const timeInMinutes = convertToMinutes(record.newTime) || 0;
          const piece = parseFloat(record.piece) || 0;
          const totalMinutes = timeInMinutes * piece;
          const totalSeconds = totalMinutes * 60;
      
          // HH:MM:SS formatında süreyi formatla
          const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
          const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
          const seconds = String(Math.floor(totalSeconds % 60)).padStart(2, '0');
      
          const formattedTime = `${hours}:${minutes}:${seconds}`;
      
          return (
            <span style={{ display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap', color: record.isDeleted ? "#00000080" : "#000000" }}>
              {formattedTime}
            </span>
          );
   
        },
        

      },

      {
        title: 'Birim Ağırlık',
        dataIndex: 'weightSetup',
        key: 'weightSetup',
        render: (text, record) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' }}>
            <span>{text && Number(text).toFixed(2)} Kg</span>
          </div>
        ),

      },
      {
        title: 'Hesaplanan Birim Ağırlık',
        dataIndex: 'newWeight',
        key: 'newWeight',
        render: (text, record) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' }}>
            <span>{text && Number(text).toFixed(2)} Kg</span>
          </div>
        ),

      },
      {
        title: 'Toplam Ağırlık',
        dataIndex: '',
        key: '',
        width : 200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap', color: record.isDeleted ? "#00000080" : "#000000" }}>
          {(record.weightSetup * record.piece).toFixed(2)} kg
              </span>
            ),
        },
      {
        title: 'Hesaplanan Toplam Ağırlık',
        dataIndex: '',
        key: '',
        width : 200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>
      {(record.newWeight * record.piece).toFixed(2)} kg
          </span>
        ),
        
      },
   
      {
        title: 'Ebatlar',
        dataIndex: 'partNo',
        key: 'partNo',
        width : 200,
        render: (text,record) => <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}> {record.width} x  {record.height} </span>,
      }, 
      {
        title: 'Malzeme Fiyatı',
        dataIndex: 'priceQuality',
        key: 'priceQuality',
        width : 200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap', color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text && text.toFixed(2)} TL
          </span>
        ),
      },
      
      {
        title: 'Lazer Fiyatı',
        dataIndex: 'priceMachine',
        key: 'priceMachine',
        width : 200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap', color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text && text.toFixed(2)} TL
          </span>
        ),
      },
      {
        title: 'Plazma Fiyatı',
        dataIndex: 'pricePlazma',
        key: 'pricePlazma',
        width : 200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap', color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text !== null && text !== undefined ? `${text.toFixed(2)} TL` : '-'}
          </span>
        ),
      },
      
      {
        title: 'Operasyonlar',
        dataIndex: 'operations',
        key: 'operations',
        width: 450,
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text && text.length > 0 ? (
              text.map((operation, opIndex) => (
                <div key={operation} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    {operation.toLowerCase() !== 'abkant' && (
                    <>
                      <span style={{ width: '100px' }}>{operation}</span>
                      <div> {record.priceOperations?.[operation]?.contractManufacturing || ''}</div>
                     <div style={{marginLeft:"10px"}}> {record.priceOperations?.[operation]?.profit || ''}</div>
                    
                    </>
                  )}
                </div>
              ))
            ) : (
              '-'
            )}
          </span>
        ),
      },





      {
        title: 'Abkant Fiyatı',
        dataIndex: 'pricesAbkant',
        key: 'pricesAbkant',
        width : 200,
        render: (text, record) => (
          <div>
          {record.pricesAbkant !== undefined && record.pricesAbkant !== null 
            ? `${record.pricesAbkant.toFixed(2)} TL` 
            : '-'}
        </div>
        
        ),


      },
      {
        title: 'Toplam Abkant Fiyatı',
        dataIndex: '',
        key: '',
        width : 200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>
           {record.pricesAbkant !== undefined && record.pricesAbkant !== null 
            ? `${(record.pricesAbkant * record.piece).toFixed(2)} TL` 
            : '-'}
          </span>
        ),

      },
      {
        title: 'Birim Fiyat',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        render: (text, record) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' }}>
            <span>{text && Number(text).toFixed(2)} TL</span>
          </div>
        ),
      },
      {
        title: 'Toplam Fiyat',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        render: (text, record) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' }}>
            <span>{text && parseFloat(text).toFixed(2)} TL</span>
          </div>
        ),
      },
  ];

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
      
       
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Operasyon Fiyatları</h3>
       

        <Table
            locale={{
              emptyText: 'Henüz bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={itemDetails.items}
            columns={columns}
            showHeader={true}
            pagination={false}
            className="custom-news-table"
            rowClassName="custom-news-row"
            style={{ width: "200px" }}
          
          />



<div style={{ marginTop: '5px', textAlign: 'left', marginBottom: '10px' }}>
  <div>
    <strong>Nakliye Fiyatı:</strong> 
    {itemDetails.deliveryPrice}  {itemDetails.currency}
  </div>
  <div>
    <strong>Nakliye Hariç Toplam Fiyat: </strong> 
    {(itemDetails.allTotalPrice - itemDetails.deliveryPrice).toFixed(2)}  {itemDetails.currency}
  </div>
  <div>
    <strong>Nakliye Dahil Toplam Fiyat: </strong> 
    {itemDetails.allTotalPrice} {itemDetails.currency}
  </div>
</div>


     
    </div>

  };
  export default OrderOperationPriceView;
 