import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FirebaseService from '../../../../services/firebaseService';
import { colors } from '../../../../environments/environment';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const OfferDetailView = () => {
  const location = useLocation();
  const { selectedItem } = location.state || {};
  const navigate = useNavigate();

  const [itemDetails, setItemDetails] = useState(null);

  const uniqueQualities = [...new Set(itemDetails?.items?.map(item => item.quality))]
  useEffect(() => {
    const fetchData = async () => {
      try {
        const allData = await FirebaseService.getInvoiceOpenOrderData();
        const filteredItem = allData.find(item => item.jobCode === selectedItem.jobCode && !item.isDeleted);
        setItemDetails(filteredItem);
      } catch (error) {
        console.error("Error fetching item details:", error);
      }
    };

    fetchData();
  }, [selectedItem]);

  console.log("itemDetails", itemDetails);

  const handleViewOfferDetails = (record) => {
    console.log("record",record)
    navigate('/offeroperationpriceview', { state: { itemDetails: record } })
    };
    
  return (
    <>
    <div style={{ display: "flex", flexDirection: "column", padding: "10px 100px" }}>
      <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign: "left" }}>Teklif Detay</h2>
  
      <div style={{ display: "flex", flexDirection:"column"}}>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <div style={{ textAlign: "left", flex: "1 0 20%", marginRight: "10px" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Firma</p>
                {itemDetails?.companyName}
          </div>
  
          <div style={{ textAlign: "left", flex: "1 0 20%", marginRight: "10px" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
           {itemDetails?.jobCode}
          </div>
  
          <div style={{ textAlign: "left", flex: "1 0 20%", marginRight: "10px" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Programcı</p>
           {itemDetails?.programmerName[0]}
          </div>
  
          <div style={{ textAlign: "left", flex: "1 0 20%", marginRight: "10px" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Operasyonlar</p>
           
            {itemDetails?.items?.length > 0 ? [...new Set(itemDetails.items.map(item => item.operations.join(', ')))].join(' - ') : ''}

              
          </div>
        </div>
  
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "40px" }}>
          <div style={{ textAlign: "left", flex: "1 0 20%", marginRight: "10px" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Ödeme Vaadesi</p>
            <div style={{borderRadius: "10px",border: `2px solid ${colors.blue}`,  padding: "5px" , display:"inline-block", minWidth:"100px"}}>

            {itemDetails?.paymentMethod || ''}
            </div>
          </div>
  
          <div style={{ textAlign: "left", flex: "1 0 20%", marginRight: "10px" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Takım Adedi</p>
            <div style={{borderRadius: "10px",border: `2px solid ${colors.blue}`,  padding: "5px" , display:"inline-block", minWidth:"100px"}}>

              {itemDetails?.teamCount != null ? itemDetails.teamCount : '1'}
              </div>
          </div>
  
          <div style={{ textAlign: "left", flex: "1 0 20%", marginRight: "10px" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Teslim Şekli</p>
            <div style={{borderRadius: "10px",border: `2px solid ${colors.blue}`,  padding: "5px" , display:"inline-block", minWidth:"100px"}}>

              {itemDetails?.deliveryMethod || 'Fabrika'}
              </div>
          </div>

          <div style={{ textAlign: "left", flex: "1 0 20%", marginRight: "10px" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Teslim Ücreti</p>
            <div style={{borderRadius: "10px",border: `2px solid ${colors.blue}`,  padding: "5px" , display:"inline-block", minWidth:"100px"}}>

              {itemDetails?.deliveryPrice || 0}
              </div>
          </div>
  
  
          <div style={{ textAlign: "left", flex: "1 0 20%", marginRight: "10px" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Birim</p>
            <div style={{borderRadius: "10px",border: `2px solid ${colors.blue}`,  padding: "5px" , display:"inline-block", minWidth:"100px"}}>

              {itemDetails?.unit || 'Kg'}
            </div>
          </div>
        </div>
  
       {/* <div style={{ textAlign:"left" ,marginTop: "20px" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Açıklama</p>
         
            {itemDetails?.description || 'Açıklama bulunmamaktadır'}
          
        </div>*/}
  
        {/* Kalite ve Diğer Detaylar */}
        <div style={{ marginTop: "100px" }}>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left", color: colors.blue, fontWeight: "bold" }}>
  <div style={{ width: "250px", marginRight: "10px" }}>Kalite</div>
  <div style={{ width: "150px", marginRight: "30px" }}>Kg</div>
  <div style={{ width: "100px", marginRight: "20px" }}>Kg. Fiyat</div>
  <div style={{ width: "100px", marginRight: "10px" }}>Toplam Fiyat</div>
</div>

{uniqueQualities.map((quality, index) => {
      // Her benzersiz kalite için toplam değerleri hesapla
      const totalKg = itemDetails.totalKg[quality] || 0;
      const priceQuality = itemDetails.pricesQuality[quality] || 0;
      const totalPriceQuality = itemDetails.totalPricesQuality[quality] || 0;

      return (
        <div key={index} style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left" }}>
          <div style={{ width: "250px", marginRight: "10px" }}>{quality}</div>
          <div style={{ width: "150px", marginRight: "10px", borderRadius: "10px", border: `2px solid ${colors.blue}`, padding: "5px" }}>
            {parseFloat(totalKg)?.toFixed(2)} kg
          </div>
          <div style={{ width: "100px", marginRight: "10px", borderRadius: "10px", border: `2px solid ${colors.blue}`, padding: "5px" }}>
            {parseFloat(priceQuality)?.toFixed(2)} TL
          </div>
          <div style={{ width: "100px", marginRight: "10px", borderRadius: "10px", border: `2px solid ${colors.blue}`, padding: "5px" }}>
            {parseFloat(totalPriceQuality)?.toFixed(2)} TL
          </div>
        </div>
      );
    })}


<div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left", color: colors.blue, fontWeight: "bold", marginTop: "70px" }}>
  <div style={{ width: "250px", marginRight: "10px" }}>Makine</div>
  <div style={{ width: "150px", marginRight: "30px" }}>Dakika</div>
  <div style={{ width: "100px", marginRight: "20px" }}>Fiyat</div>
  <div style={{ width: "100px", marginRight: "10px" }}>Toplam Fiyat</div>
</div>

{itemDetails?.totalPricesTime && Object.entries(itemDetails.totalPricesTime).map(([key, totalPrice], index) => {
  const [machine, fuel] = key.split("-");
  const time = itemDetails.setupTime[key] || "00:00:00";
  const price = itemDetails.pricesTime[key] || 0;

  return (
    <div key={index} style={{ display: "flex", flexDirection: "row", marginBottom: "70px", textAlign: "left" }}>
      <div style={{ width: "250px", marginRight: "10px" }}>Trumpf {machine} KW - {fuel}</div>
      <div style={{ width: "150px", marginRight: "10px" , borderRadius: "10px", border: `2px solid ${colors.blue}`, padding: "5px"}}>{time}</div>
      <div style={{ width: "100px", marginRight: "10px" ,borderRadius: "10px", border: `2px solid ${colors.blue}`, padding: "5px"}}>{price.toFixed(2)} TL</div>
      <div style={{ width: "100px", marginRight: "10px",borderRadius: "10px", border: `2px solid ${colors.blue}`, padding: "5px" }}>{totalPrice.toFixed(2)} TL</div>
    </div>
  );
})}

{itemDetails?.totalPricesPlazma && Object.keys(itemDetails.totalPricesPlazma).length > 0 && (
  <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left", color: colors.blue, fontWeight: "bold" }}>
    <div style={{ width: "250px", marginRight: "10px" }}>Plazma</div>
    <div style={{ width: "150px", marginRight: "10px" }}>Kg</div>
    <div style={{ width: "100px", marginRight: "10px" }}>Fiyat</div>
    <div style={{ width: "100px", marginRight: "10px" }}>Toplam Fiyat</div>
  </div>
)}

{itemDetails?.totalPricesPlazma && Object.entries(itemDetails.totalPricesPlazma).map(([key, totalPrice], index) => {
  const weight = itemDetails.totalKg[key] || 0;
  const price = itemDetails.pricesPlazma[key] || 0;

  return (
    <div key={index} style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left" }}>
      <div style={{ width: "250px", marginRight: "10px" }}></div>
      <div style={{ width: "150px", marginRight: "10px" }}>{weight.toFixed(2)} kg</div>
      <div style={{ width: "100px", marginRight: "10px" }}>{price.toFixed(2)} TL</div>
      <div style={{ width: "100px", marginRight: "10px" }}>{totalPrice.toFixed(2)} TL</div>
    </div>
  );
})}

<div style={{ marginTop: "50px" }}>
  {itemDetails?.items.some(item => item.operations.some(op => op.toLowerCase() === "abkant")) && (
    <div>
      <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left", color: colors.blue, fontWeight: "bold" }}>
        <div style={{ width: "250px", marginRight: "10px" }}>Operasyonlar</div>
        <div style={{ width: "150px", marginRight: "30px" }}>{itemDetails.abkantSelectedOperation}</div>
        <div style={{ width: "100px", marginRight: "20px" }}>Fiyat</div>
        <div style={{ width: "100px", marginRight: "10px" }}>Toplam Fiyat</div>
      </div>

      {/* "Abkant" işlemi için bilgileri yalnızca bir kez göster */}
      <div>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left" }}>
          <div style={{ width: "250px", marginRight: "10px" }}>Abkant</div>

          {/* Seçili tür ve bilgileri göstermek için */}
          {itemDetails.abkantSelectedOperation === 'Kg' && (
            <>
              <div style={{ width: "150px", marginRight: "10px", borderRadius: "10px", border: `2px solid ${colors.blue}`, padding: "5px" }}>
                {itemDetails.abkantWeight} kg
              </div>
              <div style={{ width: "100px", marginRight: "10px", borderRadius: "10px", border: `2px solid ${colors.blue}`, padding: "5px" }}>
                {(itemDetails.pricesAbkant["Abkant"] || 0).toFixed(2)} TL
              </div>
              <div style={{ width: "100px", marginRight: "10px", borderRadius: "10px", border: `2px solid ${colors.blue}`, padding: "5px" }}>
                {(itemDetails.totalPricesAbkant["Abkant"]?.toFixed(2) || 0)} TL
              </div>
            </>
          )}

          {itemDetails.abkantSelectedOperation === 'Adet' && (
            <>
              <div style={{ width: "150px", marginRight: "10px", borderRadius: "10px", border: `2px solid ${colors.blue}`, padding: "5px" }}>
                {itemDetails.quantitiesAbkant["Abkant"] || 0}
              </div>
              <div style={{ width: "100px", marginRight: "10px", borderRadius: "10px", border: `2px solid ${colors.blue}`, padding: "5px" }}>
                {(itemDetails.pricesAbkant["Abkant"] || 0).toFixed(2)} TL
              </div>
              <div style={{ width: "100px", marginRight: "10px", borderRadius: "10px", border: `2px solid ${colors.blue}`, padding: "5px" }}>
                {(itemDetails.totalPricesAbkant["Abkant"]?.toFixed(2) || 0)} TL
              </div>
            </>
          )}

          {itemDetails.abkantSelectedOperation === 'Dakika' && (
            <>
              <div style={{ width: "150px", marginRight: "10px", borderRadius: "10px", border: `2px solid ${colors.blue}`, padding: "5px" }}>
                {itemDetails.quantitiesAbkant["Abkant"] || 0}
              </div>
              <div style={{ width: "100px", marginRight: "10px", borderRadius: "10px", border: `2px solid ${colors.blue}`, padding: "5px" }}>
                {(itemDetails.pricesAbkant["Abkant"] || 0).toFixed(2)} TL
              </div>
              <div style={{ width: "100px", marginRight: "10px", borderRadius: "10px", border: `2px solid ${colors.blue}`, padding: "5px" }}>
                {(itemDetails.totalPricesAbkant["Abkant"]?.toFixed(2) || 0)} TL
              </div>
            </>
          )}
        </div>
              </div>
            </div>
          )}
        </div>


        </div>


        </div>
        
      </div>
      <Button 
      onClick={() => handleViewOfferDetails(itemDetails)} 
      style={{backgroundColor: colors.blue, color: "white", fontWeight: "500", float:"right", marginRight:"100px"}}
      >
      Operasyon Fiyatları Gör
      </Button>
      </>

  );
  
};

export default OfferDetailView;
