import React,{useEffect,useState} from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';

const WantedOfferListLimited = () => {
    const [items, setItems] = useState([]);
    const [itemsLimited, setItemsLimited] = useState([]);
  
    const navigate = useNavigate();
  
    useEffect(() => {
      window.scrollTo(0, 0);
  
      const fetchData = async () => {
        const data = await FirebaseService.getPurchaseRequestData();
       
        const filteredData = data.filter(item => item.mailList && item.mailList.length >= 3);
        filteredData.sort((a, b) => a.deadline.toMillis() - b.deadline.toMillis());  
        const limitedData = filteredData.slice(0, 5); 
        setItemsLimited(limitedData)
  
        setItems(filteredData)
      };
  
      fetchData();
    }, []);
  
    
  
    const goPage = (record) => {
        navigate('/wantedofferlist', { state: { record } })
      };
    
  
  
  
    const columns = [
      {
        title: 'Sipariş Kodu',
        dataIndex: 'offerJobCode',
        key: 'offerJobCode',
       
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
      {
        title: 'Ürün',
        dataIndex: 'productType',
        key: 'productType',
     
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text === 'raw' ? 'Saç Levha' : text === 'other' ? 'Diğer' : text}
        </span>,
      },
      
  {
    title:"Durum",
    dataIndex: '',
    key: '',
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{record.incomingExcelList ?  record.incomingExcelList.length : 0 } / {record.mailList.length} teklif yüklendi , {record.acceptExcelDocumentUrl ? "Seçildi" : "Seçilmedi"}</span>,
},
      /*
      {
        title: 'Durum',
        dataIndex: '',
        key: '',
       
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{record.acceptExcelDocumentUrl ?  "Teklif Yüklendi" : "Teklif Yüklenmedi" }</span>,
       
      },*/
      {
          title: 'Termin Tarihi',
          dataIndex: 'deadline',
          key: 'deadline',
        
          render: (text, record) => {
            const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
            return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
          },
        },
        
        
       
        
     
    ];
  
    return  <div style={{
        //  justifyContent: 'center',
        //  alignItems: 'center',
       //   minHeight: '94vh',
         
        //  width:"60%",
         

        }}
        className="limited-list-border">
        <Button onClick={()=>goPage(items)} className="title-limited-list">
          İstenen Teklif Listesi
         </Button>
            <Table
              locale={{
                emptyText: 'Henüz istenen teklif bulunmamaktadır...',
                filterReset: 'Sıfırla',
                filterTitle: 'Filtre Menüsü',
                selectAll: 'Hepsini Seç',
                selectInvert: 'Tersini Seç',
                selectionAll: 'Tümünü Seç',
                sortTitle: 'Sıralama',
                triggerDesc: 'Azalan sıralama için tıklayın',
                triggerAsc: 'Artan sıralama için tıklayın',
                cancelSort: 'Sıralamayı iptal etmek için tıklayın',
              }}
              dataSource={itemsLimited}
              columns={columns}
              showHeader={true}
              pagination={false} 
              className="custom-news-table"
              rowClassName="custom-news-row"
          />
      </div>
};
export default WantedOfferListLimited;
