import React, { useEffect, useState } from "react";
import { Button, Form, Input, Spin } from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import { parsCollections } from "../../../environments/environment";
import PropTypes from 'prop-types';

const AddRequestAdminOther = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [existingItems, setExistingItems] = useState([]); // Mevcut diğer ürünler

  useEffect(() => {
    const fetchData = async () => {
      // Mevcut ürünleri Firebase'den al
      const itemsData = await FirebaseService.getAllItemsData();
      setExistingItems(itemsData.filter(item => !item.isDeleted && item.productType === "other"));
    };
    fetchData();
  }, []);

  // 5 haneli rastgele kod üreten fonksiyon
  function generateRandomCode(length) {
    const characters = '0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  }

  const onFinish = async (values) => {
    setLoading(true);

    // Aynı ürünün iki kere eklenmemesi için kontrol
    const isDuplicate = existingItems.some(item => item.productString === values.productString);
    if (isDuplicate) {
      NotificationService.openErrorNotification({
        title: 'Hata',
        description: 'Bu ürün zaten mevcut.',
        placement: 'topRight'
      });
      setLoading(false);
      return;
    }

    const currentDate = new Date();
    const data = {
      firstTransactionDate: currentDate,
      firstUserId: localStorage.getItem("uid"),
      code: generateRandomCode(5), // Rastgele kod
      isDeleted: false,
      productType: "other",
      productDictionary: null,
      productString: values.productString,
      minPiece: values.minPiece || 0
    };

    try {
      const result = await FirebaseService.addRecording(parsCollections.allItems, data); // allItems tablosuna ekleme
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: 'Ürün başarıyla eklendi.',
          placement: 'topRight'
        });
        setLoading(false);
        onClose(); // Kapat
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
        setLoading(false);
      }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({
      title: 'Geçersiz Form',
      description: 'Lütfen zorunlu alanları doldurunuz.',
      placement: 'topRight'
    });
  };

  return (
    <div>
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        style={{ maxWidth: "100%", padding: "5% 0 0 5%" }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Ürün"
          name="productString"
          rules={[{ required: true, message: 'Lütfen ürün giriniz!' }]}
        >
          <Input className="input-style" style={{ width: "100%" }} placeholder="Ürün Giriniz" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 15, span: 18 }}>
          <Button htmlType="submit" style={{ backgroundColor: "#1A446C", color: "white", fontWeight: "500" }}>Gönder</Button>
        </Form.Item>
      </Form>

      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}>
          <Spin />
        </div>
      )}
    </div>
  );
};

AddRequestAdminOther.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddRequestAdminOther;
