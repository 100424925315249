import React,{useEffect,useState} from "react";
import { Table, Button,Modal, Input ,Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors, parsCollections}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';

const InvoiceOpenOrdersList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  
    const fetchData = async () => {
      const data = await FirebaseService.getOpenOrderData();
      const filteredData = data.filter(item => !item.isDeleted);
    
      const sortedData = filteredData.sort((a, b) => {
        const isEqualA = a.invoiceNumber ? a.invoiceNumber.length >= a.shipmentPiece : false;
        const isEqualB = b.invoiceNumber ? b.invoiceNumber.length >= b.shipmentPiece : false;
    
        // Durumları kontrol et ve sıralama yap
        const statusA = isEqualA ? 'Kesilmiş' : 'Bekleyen';
        const statusB = isEqualB ? 'Kesilmiş' : 'Bekleyen';
    
        // "Kesilmiş" olanları en alta al
        if (statusA === 'Kesilmiş' && statusB !== 'Kesilmiş') return 1;
        if (statusB === 'Kesilmiş' && statusA !== 'Kesilmiş') return -1;
    
        // Durumlar aynıysa, diğer kriterlere göre sıralama yap
        if (a.shipmentPiece === 0) return 1;
        if (b.shipmentPiece === 0) return -1;
        if (!isEqualA && isEqualB) return -1;
        if (isEqualA && !isEqualB) return 1;
    
        return 0; // Eşitse, sıralama yapma
      });
    
      setItems(sortedData);
    };
    
    
    fetchData();
  }, []);



  const [isShipmentModalVisible, setIsShipmentModalVisible] = useState(false);
  const [newShipmentPiece, setNewShipmentPiece] = useState('');
  const [selectedShipmentRecord, setSelectedShipmentRecord] = useState(null);
  
  const showShipmentModal = (record) => {
    setSelectedShipmentRecord(record);
    // shipmentPiece değeri 0 ise 1 olarak ayarla
    const defaultPiece = record.shipmentPiece > 0 ? record.shipmentPiece : 1;
    setIsShipmentModalVisible(true);
    setNewShipmentPiece(defaultPiece); // 0 ise 1 yap
  };
  
  const handleShipmentCancel = () => {
    setIsShipmentModalVisible(false);
    setNewShipmentPiece('');
  };
  
  const handleShipmentSave = async () => {
    if (!selectedShipmentRecord) return;
  
    // Kullanıcının girdiği değeri al
    const inputPiece = parseInt(newShipmentPiece, 10);
  
    // Kontrol: Sevk sayısı fatura numarasından küçük olamaz
    if (inputPiece < (selectedShipmentRecord.invoiceNumber?.length || 0)) {
      NotificationService.openErrorNotification({
        title: 'Geçersiz Sevk Sayısı',
        description: `Sevk sayısı, mevcut fatura sayısından (${selectedShipmentRecord.invoiceNumber?.length || 0}) küçük olamaz.`,
        placement: 'topRight',
      });
      return;
    }
  
    // Sevk sayısının minimum 1 olmasını sağlayın
    const updatedPiece = Math.max(inputPiece || 1, 1);
    selectedShipmentRecord.shipmentPiece = updatedPiece;
  
    // Veritabanında güncelle
    const result = await FirebaseService.updateOpenOrder(selectedShipmentRecord.key, {
      shipmentPiece: updatedPiece
    });
  
    if (result) {
      // Başarılı bildirim
      NotificationService.openSuccessNotification({
        title: 'Sevk Sayısı Güncellendi',
        description: `Sevk sayısı ${updatedPiece} olarak güncellendi.`,
        placement: 'topRight',
      });
      setItems([...items]); // State'i güncelle
    } else {
      // Hata bildirimi
      NotificationService.openErrorNotification({
        title: 'Güncelleme Başarısız',
        description: 'Sevk sayısı güncellenemedi. Lütfen daha sonra tekrar deneyiniz.',
        placement: 'topRight',
      });
    }
  
    setIsShipmentModalVisible(false);
    setNewShipmentPiece('');
  };
  
  
  



  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
  
    // Sadece sayıları kontrol eden bir regex kullanıyoruz
    if (/^\d*$/.test(value)) {  // Sadece sayılar (0-9) geçerli
      setInvoiceNumber(value);  // Değer geçerli, invoiceNumber state'ini güncelle
    } else {
      // Uyarı mesajı göster
      NotificationService.openErrorNotification({
        title: 'Geçersiz Giriş',
        description: 'Fatura numarası sadece rakam olmalıdır!',
        placement: 'topRight',
      });
    }
  };
  
  const handleSave = async () => {
    setLoading(true)
    if (!selectedRecord) return;
    console.log("selectedRecord",selectedRecord)
    const firstFiveCharacters = selectedRecord.jobCode.substring(0, 5); 


  
    const allOpenOrder = await FirebaseService.getOpenOrderData();
    const filteredOpenOrder = allOpenOrder.filter(item => (!item.isDeleted && item.jobCode.includes(firstFiveCharacters)));

    console.log("filteredOpenOrder", filteredOpenOrder);

    const updatePromises = filteredOpenOrder.map(async (item) => {
      let updateOpenOrderItem;

      const updatedInvoiceNumbers = item.invoiceNumber ? [...item.invoiceNumber, invoiceNumber] : [invoiceNumber];
      updateOpenOrderItem = {
        invoiceNumber: updatedInvoiceNumbers
      };

      console.log("updateOpenOrderItem", updateOpenOrderItem, item.key);

      const resultx = await FirebaseService.updateOpenOrder(item.key, updateOpenOrderItem);
      console.log(resultx);

      if (resultx) {
        console.log("başarılı");
      } else {
        console.log("başarısız");
      }

      return resultx;
    });

    const results = await Promise.all(updatePromises);

    if (results.every(result => result)) {
      NotificationService.openSuccessNotification({
        title: 'Tüm İşlemler Başarılı',
        description: "Başarıyla gönderilmiştir.",
        placement: 'topRight',
      });setLoading(false)

  
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      NotificationService.openErrorNotification({
        title: 'Bazı İşlemler Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight',
      });setLoading(false)
    }

    setIsModalVisible(false);
    setInvoiceNumber('');
  
  };

  
  
  
  
  

  
  
  

  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      sorter: (a, b) => {
        const companyNameA = a && a.companyName ? a.companyName : '';
        const companyNameB = b && b.companyName ? b.companyName : '';
        return companyNameA.localeCompare(companyNameB);
      }
  },
  
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
       sorter: (a, b) => {
        const itemA = a && a.jobCode ? a.jobCode : '';
        const itemB = b && b.jobCode ? b.jobCode : '';
        return itemA.localeCompare(itemB);
      },
    },
    {
      title: 'Proje Kodu',
      dataIndex: 'projectCode',
      key: 'projectCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
       sorter: (a, b) => {
        const itemA = a && a.projectCode ? a.projectCode : '';
        const itemB = b && b.projectCode ? b.projectCode : '';
        return itemA.localeCompare(itemB);
      },
    },
    {
      title: 'Durum',
      dataIndex: '',
      key: '',
      render: (text, record) => {
        const isEqualorGreater = record.invoiceNumber ? (record.invoiceNumber.length === record.shipmentPiece) || (record.invoiceNumber.length >= record.shipmentPiece) : false;
        return (
          <span style={{ color: isEqualorGreater ? colors.green : colors.red }}>
            {isEqualorGreater ? 'Kesilmiş' : 'Bekleyen'}
          </span>
        );
      },
    },

    {
      title: 'Sevk Sayısı',
      dataIndex: '',
      key: 'shipmentCount',
      render: (text, record) => {
        // Eğer record.shipmentPiece 0 ise 1 olarak ayarlıyoruz
        const displayShipmentPiece = record.shipmentPiece > 0 ? record.shipmentPiece : 1;
        return (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {record.invoiceNumber ? record.invoiceNumber.length : 0} / {displayShipmentPiece}
          </span>
        );
      },
    },
    

    {
      title: ' ',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => {
        const isHidden = record.invoiceNumber && record.invoiceNumber.length >= record.shipmentPiece;
    
        return (
          <div style={{ display: 'flex', gap: '10px' }}>
            {!isHidden && (
              <Button
                style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }}
                onClick={() => showModal(record)}
              >
                Fatura No Gir
              </Button>
            )}
            <Button
              style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }}
              onClick={() => showShipmentModal(record)}
            >
              Sevk Sayısı Güncelle
            </Button>
          </div>
        );
      },
    }
    
    
    
  ];


  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3  style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>Açık Sipariş Faturaları</h3>
          <Table
            locale={{
              emptyText: 'Henüz bekleyen faturalar bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
            expandable={{
              expandedRowRender: record => (
                <div style={{ display: 'flex' }}>
                   <div style={{ marginLeft: "55px" }}>
                    <div style={{ marginBottom: "10px" }}>
                      <span style={{ fontWeight: "bold", color: colors.gray }}>Fatura Numaraları:</span>
                  
                    </div>
                  </div>

                  <div style={{ marginLeft: "55px" }}>
      <div style={{ marginBottom: "10px" }}>
        <div style={{display:"flex"}}>
          {record.invoiceNumber && record.invoiceNumber.map((invoice, index) => (
            <span key={index}>
              <span style={{ marginLeft: "20px", color: colors.gray, fontWeight: "bold" }}>
                {index + 1}. Sevkiyat:
              </span>
              <br />
              <span style={{marginLeft: "20px"}}>{invoice}</span>
              {index < record.invoiceNumber.length - 1 && <br />} {/* Son sevkiyattan sonra <br> eklenmesin */}
            </span>
          ))}
        </div>
      </div>
    </div>
                </div>
              ),
              expandIcon: ({ expanded, onExpand, record }) =>
                (record.invoiceNumber && record.invoiceNumber.length > 0) ? ( // Burada kontrol ekliyoruz
                  expanded ? (
                    <a type="link" onClick={e => onExpand(record, e)} style={{ border: "none", backgroundColor: "transparent" }}><img style={{ width: "20px" }} alt="edit" src="/images/uparrow.png" /></a>
                  ) : (
                    <a type="link" onClick={e => onExpand(record, e)} style={{ border: "none", backgroundColor: "transparent" }}><img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" /></a>
                  )
                ) : null, // Eğer invoiceNumber yoksa, icon'u gizliyoruz
              expandIconColumnIndex: 5
            }}
        />

<Modal
        title="Fatura Numarası Gir"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            İptal
          </Button>,
          <Button key="save"   style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}  onClick={handleSave}>
            Kaydet
          </Button>,
        ]}
      >
         
          <div style={{ display: "flex", justifyContent: "space-between", textAlign:"center" }}>
            <div style={{ textAlign: "left", flex: "1 0 33%" }}>
                <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
                {selectedRecord ? selectedRecord.jobCode : ''}
            </div>
            
            <div style={{ textAlign: "left", flex: "1 0 33%" }}>
              <p style={{ fontWeight: "bold", color: colors.blue }}>Proje Kodu</p>
              {selectedRecord ? selectedRecord.projectCode : ''}
            </div>

            <div style={{ textAlign: "left", flex: "1 0 33%" }}>
                <p style={{ fontWeight: "bold", color: colors.blue }}>Sevk Sayısı</p>
                {selectedRecord ?  ( selectedRecord.invoiceNumber ? selectedRecord.invoiceNumber.length : 0 )  + "/"  + selectedRecord.shipmentPiece  : ''}
            </div>
        </div>

        <p  style={{ fontWeight: "bold", color: colors.blue }}>Fatura Numarası : 
          <Input
          
          placeholder="Fatura Numarası"
          value={invoiceNumber}
          onChange={handleInputChange}
          style={{ marginTop: '10px', marginLeft:"20px" }}
          className="input-style" 

        />
        </p>

        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
      </Modal>

      <Modal
        title="Sevk Sayısı Güncelle"
        visible={isShipmentModalVisible}
        onCancel={handleShipmentCancel}
        footer={[
          <Button key="cancel" onClick={handleShipmentCancel}>
            İptal
          </Button>,
          <Button key="save" style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }} onClick={handleShipmentSave}>
            Kaydet
          </Button>,
        ]}
      >
        <div style={{ display: "flex", justifyContent: "space-between", textAlign: "center" }}>
          <div style={{ textAlign: "left", flex: "1 0 50%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
            {selectedShipmentRecord ? selectedShipmentRecord.jobCode : ''}
          </div>
      
          <div style={{ textAlign: "left", flex: "1 0 50%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Proje Kodu</p>
            {selectedShipmentRecord ? selectedShipmentRecord.projectCode : ''}
          </div>
        </div>
      
        <p style={{ fontWeight: "bold", color: colors.blue }}>
          Sevk Sayısı :
          <Input
            placeholder="Sevk Sayısı"
            value={newShipmentPiece}
            onChange={(e) => setNewShipmentPiece(e.target.value)}
            style={{ marginTop: '10px', marginLeft: "20px" }}
            className="input-style"
            type="number" // Sadece sayı girişine izin verir
            min={1} // Minimum değeri 1 olarak ayarlayın
          />
        </p>

        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
      </Modal>

     

       
    </div>

  };
  export default InvoiceOpenOrdersList;

  