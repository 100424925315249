import React,{useEffect,useState} from "react";
import { Button, Form, Input,Table ,Checkbox, DatePicker,Progress, Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import  {colors,parsCollections}  from "../../../environments/environment"
import OneSignalService from "../../../services/oneSignalService";
import moment from 'moment';

const SetupPlatesDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isScrapDelivery, setIsScrapDelivery] = useState(false);
    const [qualities, setQualities] = useState([]); 
    const [code, setCode] = useState(''); 
    const [loading, setLoading] = useState(false);
    const today = moment().startOf('day'); 
    const [plates, setPlates] = useState(null); 

    const disabledDate = (current) => {
      return current && current < today;
    }
    const data = location.state && location.state.data;
 console.log("xxx data",data);

    const xmlInfo = location.state && location.state.xmlInfo;
  //  console.log("xmlInfo",xmlInfo);
     
    const parts = location.state && location.state.xmlInfo.parts;
  //  console.log("parts geldi",parts);
      const priorityAbkant = parts && parts.some(part => 
        part.operations && part.operations.some(operation => operation.toLowerCase() === "abkant")
      ) ? 999999999 : null;
     // console.log("parçalarda abkant?", priorityAbkant);
    
   // const plates = location.state && location.state.xmlInfo.plates;
  // console.log("plates",plates);
   // const plateCount = plates.length;

    const record = location.state && location.state.record;
    console.log("xxx record",record);

    const setupDataa = location.state && location.state.setupData;
console.log("setupDataa",setupDataa)
   const [additionalContents, setAdditionalContents] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

  
     
    useEffect(() => {
      window.scrollTo(0, 0);
      setIsLoading(true); 
        const fetchData = async () => {
          const qualities = await FirebaseService.getDensitiesData();
          const filteredQualities = qualities.filter(item => !item.isDeleted);
          setQualities(filteredQualities);
  

          const code= generateRandomCode(5);
          setCode(code);

          if (setupDataa) {
            console.log("setupDataa.plates",setupDataa.plates)
           setPlates(setupDataa.plates);
            setIsLoading(false);
          } else {
           
            setIsLoading(false);
          }

        };
        fetchData();
    }, []);

    function generateRandomCode(length) {
      const characters = '0123456789';
      let result = '';
    
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
    
      return result;
    }


    const addAdditionalContent = (plateIndex) => {
      setAdditionalContents((prevContents) => {
        const newContents = [...prevContents];
        newContents[plateIndex] = [...(newContents[plateIndex] || []), { width: '', height: '', number: 1 }];
        return newContents;
      });
    };
  
    const removeAdditionalContent = (plateIndex, contentIndex) => {
      setAdditionalContents((prevContents) => {
        const newContents = [...prevContents];
        newContents[plateIndex].splice(contentIndex, 1);
        return newContents;
      });
    }
    
    const isDateBeforeToday = (dateString) => {
      const selectedDate = new Date(dateString);
      const today = new Date(); 
        return selectedDate < today;
    };

    const getOneSignalPlayerIds = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satış"));
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.oneSignalPlayerId) {
            oneSignalPlayerIds.push(user.oneSignalPlayerId);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

    const getOneSignalUserIds = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satış"));
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.key) {
            oneSignalPlayerIds.push(user.key);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

    const getOneSignalPlayerIdsArchieve = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("depo"));
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.oneSignalPlayerId) {
            oneSignalPlayerIds.push(user.oneSignalPlayerId);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

    const getOneSignalUserIdsArchieve = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("depo"));
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.key) {
            oneSignalPlayerIds.push(user.key);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    }; 

    const getOneSignalPlayerIdsBuyer= async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satın alma"));
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.oneSignalPlayerId) {
            oneSignalPlayerIds.push(user.oneSignalPlayerId);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

    const getOneSignalUserIdsBuyer= async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satın alma"));
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.key) {
            oneSignalPlayerIds.push(user.key);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

    const checkItems = async (record) => {
      console.log("fonk geldiiii rezerveler kontrol ediliyor", record);
    
      let isAllSuccessful = true;
    
      // Check and update stock items
      for (const stockItem of record.stock) {
        const { id } = stockItem;
    
        const stockTableItem = await FirebaseService.getStockItemById(id);
        console.log("stockTableItem", stockTableItem);
    
        if (stockTableItem) {
        
          if (stockTableItem.reservedPiece <= 0 ||  (stockTableItem.reservedPiece < stockItem.piece)  ) {
            console.log(`Warning: The reservedPiece for stock item with id ${id} is not greater than 0.`);
            
            if (stockTableItem.piece <= 0 || stockTableItem.piece < stockItem.piece) {
              console.log(`Piece is 0 or less for stock item with id ${id}, skipping reservation check.`);
              isAllSuccessful = false;
              break; 
            }
      
            const result = await FirebaseService.updateStockReservedPiece(id, stockItem.piece);
            if (result.success) {
              console.log("Stock item successfully reserved.");
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Parça ID: ${id} güncellenemedi. Hata: ${result.message}`,
                placement: 'topRight',
              });
              isAllSuccessful = false;
              break;
            }
          }
        } else {
          console.log(`Warning: No item found in stock table for id ${id}`);
          isAllSuccessful = false;
          break; // Exit loop immediately if item is not found
        }
      }
    
      // Exit early if a failure occurred in the stock loop
      if (!isAllSuccessful) return false;
    
      // Check and update wastage items
      for (const wastageItem of record.wastage) {
        const { id } = wastageItem;
    
        const wastageTableItem = await FirebaseService.getWastageItemById(id);
        console.log("wastageTableItem", wastageTableItem);
    
        if (wastageTableItem) {
          
    
          if (wastageTableItem.reservedPiece <= 0  || (wastageTableItem.reservedPiece < wastageItem.piece)) {
            console.log(`Warning: The reservedPiece for wastage item with id ${id} is not greater than 0.`);
    
            if (wastageTableItem.piece <= 0 || wastageTableItem.piece < wastageItem.piece) {
              console.log(`Piece is 0 or less for wastage item with id ${id}, skipping reservation check.`);
              isAllSuccessful = false;
              break; // Exit loop immediately
            }

            const result = await FirebaseService.updateWastageReservedPiece(id, wastageItem.piece);
            if (result.success) {
              console.log("wastage başarılı reserve güncellensin");
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Parça ID: ${id} güncellenemedi. Hata: ${result.message}`,
                placement: 'topRight',
              });
              isAllSuccessful = false;
              break; // Exit loop immediately on failure
            }
          }
        } else {
          console.log(`Warning: No item found in wastage table for id ${id}`);
          isAllSuccessful = false;
          break; // Exit loop immediately if item is not found
        }
      }
    
      // Return true if all updates are successful, otherwise false
      return isAllSuccessful;
    };
    
    async function getPartData(jobCode, partNo, customerName) {
      const partsData = await FirebaseService.getPartsData(); // Retrieve all parts
    
      // Find part that matches all specified criteria
      return partsData.find(
        part =>
          part.jobCode === jobCode &&
          part.partNo === partNo &&
          part.customerName === customerName
      );
    }
    
    const onFinish = async (values) => {   
      setLoading(true);
    
      if(values.deadline === undefined ){
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Lütfen termin tarihi giriniz.",
          placement: 'topRight'
        });
        setLoading(false);
        return;
      }

  
      let onlyJobCode = record.jobCode.split('-')[0];
      const allOpenOrder = await FirebaseService.getOpenOrderData();
      const filteredOpenOrder = allOpenOrder.find(item => 
        !item.isDeleted && 
        item.isWaiting && 
        item.isOffer && 
        item.isOfferApproved && 
        item.orderType === "offer" && 
        item.jobCode.includes(onlyJobCode)
      );
      
      console.log("filteredOpenOrder", filteredOpenOrder ); // Tek bir öğe döndürülür
    


      //  sipariş seçip tüm adımları yaptysa stoktan düşme vs veya teklif onaylandıysa 
      if (
        (record.isSetup !== false && record.orderType === "order") || 
        ((filteredOpenOrder &&  filteredOpenOrder.isOfferApproved && record.orderType === "offer"))
      )
        {
        console.log("buraya girdi")
        const requestedItems = record.stock.filter(stockItem =>
          !record.checkedItemsStockExcess.some(item =>
            item.id === stockItem.id &&
            item.quality === stockItem.quality &&
            item.height === stockItem.height &&
            item.width === stockItem.width &&
            item.piece === stockItem.piece &&
            item.thickness === stockItem.thickness &&
            item.surface === stockItem.surface
          )
        );

        console.log("stocktan talep edilenler çıktı",requestedItems);

        if (record.isReserved === false || record.isReserved === undefined) { 


          console.log("daha önce rezerve edilmedi rezerve ediliyor")
          // Stock update logic
          let updatedCheckedItemsStock;
          
          if (requestedItems && requestedItems.length > 0) {
            for (const x of requestedItems) {
              const { id, piece } = x;
              const result = await FirebaseService.updateStockReservedPiece(id, piece);
              console.log(result);
              if (result.success) {
                console.log("stcok başarılı reserve güncellensin")
                x.isReserved = true; // Update isReserved to true if successful
              }
              else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Hata: ${result.message}`,
                placement: 'topRight',
              });
              setLoading(false);
              return; // Skip this item and move to the next one
            }
            }
            updatedCheckedItemsStock = requestedItems.map(stockItem => ({
              ...stockItem,
              isReserved: stockItem.isReserved, // Apply the latest `isReserved` state
            
            }));
            
            console.log("Updated checkedItemsStock:", updatedCheckedItemsStock);
          }
        
          // Wastage update logic
          let updatedCheckedItemsWastage;
          
          if (record.wastage && record.wastage.length > 0) {
            for (const x of record.wastage) {
              const { id, piece } = x;
              const result = await FirebaseService.updateWastageReservedPiece(id, piece);
              console.log(result);
              if (result.success) {
                console.log("wasatage başarılı reserve güncellensin")
                x.isReserved = true; // Update isReserved to true if successful
              }
              else {
                NotificationService.openErrorNotification({
                  title: 'İşlem Başarısız',
                  description: `Hata: ${result.message}`,
                  placement: 'topRight',
                });
                setLoading(false);
                return; // Skip this item and move to the next one
              }
            }
        
            updatedCheckedItemsWastage =  record.wastage.map(wastageItem => ({
              ...wastageItem,
              isReserved: wastageItem.isReserved, // Apply the latest `isReserved` state
            
            }));
            console.log("Updated updatedCheckedItemsWastage:", updatedCheckedItemsWastage);
          }
        } else {
          console.log("daha önce rezerve edilmiş bu adım geçiliyor")
        }
        

      }

      if( record.orderType === "order"){
        //tekrar kontrol edilyor rezerveleer
        console.log("record çok önemliiiiii /tekrar kontrol edilyor rezerveleer ",record)
        const againCheckReserve =  await checkItems(record)
        console.log("againCheckReserve",againCheckReserve)
        if(!againCheckReserve){
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Rezerve edilemedi. Rezerve etmek istediğiniz tükenmiş olabilir lütfen başka seçiniz.",
            placement: 'topRight'
        });
        setLoading(false);
        return;
        }
    }
      const currentDate = new Date();
      const groupedParts = {};


      //fason üretime ekleme alanı hem siparişse hemde teklif onaylandıysa 
      if (record.orderType === "order" || (filteredOpenOrder && filteredOpenOrder.isOfferApproved)) {

        parts.forEach((part) => {
          const { partNo, operations } = part;
            operations.forEach((operation) => {
            if (!groupedParts[operation]) {
              groupedParts[operation] = {
                operation: operation, 
                parts: [], 
              };
            }
            groupedParts[operation].parts.push({
              partCode:part.partNo,
              piece: part.piece,
              unitPrice:null,
              profit:null
              
            });
          });
        });
    
        console.log('Grouped Parts:', groupedParts);
    
        Object.values(groupedParts).forEach(async (group) => {
          console.log("grıp",group)
        
          if (group.operation.toLowerCase() === "abkant") {
            console.log("Abkant prosesi atlandı:", group.operation);
            return; 
          }
          
          const data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
            companyName: record.companyName,
            supplierName: null,
            jobCode: record.jobCode,
            projectCode: record.projectCode,
            deadline: null,
            openOrderDeadline: new Date(values.deadline),
            processName: group.operation,
            isContractManufacturing: false,
            isShipment: false,
            isRequestCompleted: false,
            parts: group.parts, 
            isCompleted:false,
            code:code,
            invoiceNumber:null
          };
          console.log("data",data)
    
          try {
            const result = await FirebaseService.addRecording(parsCollections.contractManufacturing, data);
            if (result.success) {
           
              console.log("Fason üretime başarıyla kaydedildi")
              const ids = await getOneSignalPlayerIdsBuyer();
              const userIds = await getOneSignalUserIdsBuyer();

              const sentUserIdsMap = new Map();
              userIds.forEach(userId => {
                sentUserIdsMap.set(userId, false);
              });
              
              const sentUserIds = Object.fromEntries(sentUserIdsMap);
              //console.log("sentUserIds mapppp", sentUserIds);

              if (ids.length > 0) {
                  const oneSignalResult = await OneSignalService.sendNotification("", "Fason içeren Açık Sipariş", ids);
                  console.log("OneSignal notification result:", oneSignalResult);
                 
                  const data = {
                    firstTransactionDate: currentDate,
                    firstUserId: localStorage.getItem("uid"),
                    isDeleted: false,
                    sentUserIds:sentUserIds,
                    firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
                    unitName:localStorage.getItem('userUnit'),
                    title:"Fason içeren Açık Sipariş",
                    companyName:record.companyName,
                    jobCode:record.jobCode,
                    projectCode:record.projectCode,
                    machine:null,
                    type:null,
                    notice:"Fason içeren açık sipariş yüklenmiştir, kontrol ediniz.",
                    description:null

                  };

                  const result = await FirebaseService.addRecording(parsCollections.notifications,data);
                  console.log("result",result)
              } else {
                  console.log("No OneSignal Player IDs found.");
              }


            } else {
              console.log(result.message);
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
              return;
            }
          } catch (error) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
            setLoading(false);
            return;
          }
          
        });
    
        if(record.isPlazma){
          console.log("plazma var")
          const data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
            companyName: record.companyName,
            supplierName: null,
            jobCode: record.jobCode,
            projectCode: record.projectCode,
            deadline: null,
            openOrderDeadline: new Date(values.deadline),
            processName: "plazma",
            isContractManufacturing: false,
            isShipment: false,
            isRequestCompleted: false,
            parts: null, 
            isCompleted:false,
            code:code,
            invoiceNumber:null

          };
          console.log("data",data)
    
          try {
            const result = await FirebaseService.addRecording(parsCollections.contractManufacturing, data);
            if (result.success) {
          
              console.log("Fason üretime plazma başarıyla kaydedildi")
              const ids = await getOneSignalPlayerIdsBuyer();
              const userIds = await getOneSignalUserIdsBuyer();
              const sentUserIdsMap = new Map();
              userIds.forEach(userId => {
                sentUserIdsMap.set(userId, false);
              });
              
              const sentUserIds = Object.fromEntries(sentUserIdsMap);
              //console.log("sentUserIds mapppp", sentUserIds);
              if (ids.length > 0) {
                  const oneSignalResult = await OneSignalService.sendNotification("", "Fason içeren Açık Sipariş", ids);
                  console.log("OneSignal notification result:", oneSignalResult);
                 
                  const data = {
                    firstTransactionDate: currentDate,
                    firstUserId: localStorage.getItem("uid"),
                    isDeleted: false,
                    sentUserIds:sentUserIds,
                    firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
                    unitName:localStorage.getItem('userUnit'),
                    title:"Fason içeren Açık Sipariş",
                    companyName:record.companyName,
                    jobCode:record.jobCode,
                    projectCode:record.projectCode,
                    machine:null,
                    type:null,
                    notice:"Fason içeren açık sipariş yüklenmiştir, kontrol ediniz.",
                    description:null

                  };
                  const result = await FirebaseService.addRecording(parsCollections.notifications,data);
                  console.log("result",result)
              } else {
                  console.log("No OneSignal Player IDs found.");
              }
    
            } else {
              console.log(result.message);
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
              setLoading(false);
              return;
            }
          } catch (error) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
            setLoading(false);
            return;
          }
        }
    
      }

     

      
      const expectedWastages = [];
      let density = 1;
      const selectedQuality = qualities.find(item => item.quality === data.quality);
      if (selectedQuality) {
          density = selectedQuality.density;
      }
  
      const handleFinish = async (plateIndex) => {
        const plate = plates[plateIndex];
        const contents = additionalContents[plateIndex]?.length || 0; 
        let wastagesKg = 0; 
    
        for (let contentIndex = 0; contentIndex < contents; contentIndex++) {
          console.log("plate sayı", plate)
            const widthValues = values[`width-${plateIndex}-${contentIndex}-programNo-${plate.programNo}`];
            const heightValues = values[`height-${plateIndex}-${contentIndex}-programNo-${plate.programNo}`];
            const numberValues = values[`number-${plateIndex}-${contentIndex}-programNo-${plate.programNo}`];

           
    
            const area = widthValues * heightValues;
            const weight = area * Number(data.thickness) * density; 
            const weightInKg = (weight / 1000000) * plate.piece * Number(numberValues); 
            console.log("weightInKg",weightInKg)

            if (!isNaN(weightInKg) && weightInKg !== 0) {
              expectedWastages.push({
                  width: Number(widthValues),
                  height: Number(heightValues),
                  programNo: plate.programNo,
                  kg: weightInKg,
                  piece: plate.piece * Number(numberValues),
                  depotName:plate.depotName ? plate.depotName  : null
              });
      
          
              wastagesKg += weightInKg;
          } else {
              console.log(`Invalid weight calculated for plate ${plate.programNo}`);
          }
        }
    
        console.log("expectedWastages",expectedWastages)
        const matchingPlates = xmlInfo.plates.filter(p => p.programNo === plate.programNo);
        matchingPlates.forEach(matchingPlate => {
          matchingPlate.wastagesKg = wastagesKg; 
          matchingPlate.kg =  matchingPlate.kg-wastagesKg; 
          matchingPlate.wastage = expectedWastages.filter(w => w.programNo === plate.programNo); 
          matchingPlate.jobCode =  record.jobCode; 

        });
      };
      console.log('Update olmadan  xmlInfo.plates:', xmlInfo.plates);


      
      let totalKg = 0;
      const plateCount = plates.length;
      let descriptionValues = null;
      for (let plateIndex = 0; plateIndex < plateCount; plateIndex++) {
            await handleFinish(plateIndex);
            const plate = plates[plateIndex];
            console.log("plate",plate.piece)

            descriptionValues = values[`description-${plateIndex}-${plate.programNo}`];
            xmlInfo.plates[plateIndex].description = descriptionValues ? descriptionValues : null;
            xmlInfo.plates[plateIndex].status = "Kesilecek";
            xmlInfo.plates[plateIndex].isFinish = false;
          totalKg +=  xmlInfo.plates[plateIndex].kg 

      }
        
      console.log('Updated xmlInfo.plates:', xmlInfo.plates);
      console.log('Expected Wastages:', expectedWastages);
      console.log("descriptionValues",descriptionValues)
      console.log("totalKg",totalKg)

      //parts tablsouna eklemek
      const updatedParts = xmlInfo.parts.map(item => ({
        ...item,
       // jobCode :record.jobCode,
       // projectCode :record.projectCode ? record.projectCode : null ,
        //companyName :record.companyName,
        operations: item.operations.map(operation => ({
          processName: operation,
          isContractManufacturing: false,
          isCompleted: false,
          isQrScan:false,
          firstUserNameSurname: null,
          firstUserId:null
        }))
        ,
       // setupName:record.setupName ?  record.setupName : xmlInfo.setupName,
       // firstTransactionDate: currentDate,
      }));    
          
        //teklifse wastage tablosuna eklenmicek. sadece siparişse eklencek yada teklif onaylandıysa 
      if(record.orderType === "order" ||   ( (filteredOpenOrder && filteredOpenOrder.isOfferApproved && record.orderType === "offer")) ){
          console.log("sipariş seçildi, wastagelar tabloya ekleniyor")
          for (const wastage of expectedWastages) {
            const wastageItem = {
              firstTransactionDate: currentDate,
              firstUserId: localStorage.getItem("uid"),
              firstUserNameSurname: localStorage.getItem('name') + " " + localStorage.getItem('surname'),
              isDeleted: false,
              isQRScanned: false,
              companyName: record.companyName,
              depotName:  wastage.depotName ?  wastage.depotName : null,
              jobCode: record.jobCode,
              width: Number(wastage.width),
              height: Number(wastage.height),
              thickness : Number(data.thickness),
              quality :data.quality,
              surface :data.surface,
              imageUrl: null,
              description: "Teknik ressam tarafından setuptan eklenmiştir.",
              piece: wastage.piece,
              reservedPiece:0,
              unitKilo: (Number(wastage.width) * Number(wastage.height) * Number(data.thickness) * density) / 1000000,
              totalKilo: (Number(wastage.width) * Number(wastage.height) * Number(data.thickness) * density) / 1000000 * wastage.piece
      
      
            };
            const result = await FirebaseService.addRecording(parsCollections.wastages, wastageItem);
           // console.log("wastage result",result.docId)
            wastage.docId = result.docId;
          }

         
      }
       
      const priorityMachine = 999999999 ;

      let abkantBreaks= {
        userId:null,
        nameSurname:null,
        reason:null,
        firstTransactionDate:null,
        lastTransactionDate:null,
        description:null
      }
     

      const item = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
          isDeleted: false,
     
          thickness :data.thickness,
          quality :data.quality,
          surface :data.surface,
          fuel: data.fuel,
          plateCount:plateCount,
     
          setupTime :xmlInfo.totalRuntime,
          setupName :record.setupName ? record.setupName : xmlInfo.setupName,
          machine :xmlInfo.machine,

          jobCode :record.jobCode,
          projectCode :record.projectCode ? record.projectCode : null ,
          companyName :record.companyName,
          stock:record.stock ? record.stock : [] , //eğer teklifse stock boş gelir 
          wastage:record.wastage ? record.wastage : [] , //eğer teklifse wastage boş gelir 

          description: values.description ?  values.description : null,
          isScrapDelivery : isScrapDelivery,
          parts:setupDataa.parts,
          plates:xmlInfo.plates,

          expectedWastages:expectedWastages, 

          documentUrl : setupDataa.documentUrl ?  setupDataa.documentUrl : null,  //boş olabilir 
          priorityMachine: priorityMachine,
          priorityAbkant:priorityAbkant,

          isCustomerRaw: data.isCustomerRaw,
          isPlazma : record.isPlazma,
          totalKg:totalKg,

          abkantBreaks:abkantBreaks,
          abkantTwistShape:null,
          abkantUserCount:0,
          abkantActualTime:null,
          abkantIsFinish:false,
          setupDescription:values.description ?  values.description :null,
          isShipment:false,
          isMerge:false,
          deadline: new Date(values.deadline),


       
        

      };

      
    //usera perfomans ekleme setup eklediğinde 
    await FirebaseService.updateUserPerformance(localStorage.getItem("uid"), new Date(), xmlInfo.totalRuntime)
      .then(response => console.log(response))
      .catch(error => console.error(error));
    
     
      console.log("item aaaaa",item,setupDataa.key,setupDataa)    
   
      //const result = await FirebaseService.addRecording(parsCollections.setup, item);
      const result = await FirebaseService.updateRecording(parsCollections.setup, setupDataa.key, item);
      console.log("result nedenn",result)
      if (result.success) {
          console.log("result",result,record.key, record)
          //eğer böyle bir open order önceden varsa ve setup yükleniyorsa
                    
            //parçalar eklencek
            for (const part of setupDataa.parts) {
              console.log("Searching for part in setupDataa.parts", part);
console.log("part",part)
              
              try {
                const existingPart = await getPartData(part.jobCode, part.partNo, part.customerName);
                
                if (existingPart) {
                  console.log("existingPart",existingPart)
                  let resultPart= await FirebaseService.updateRecording(
                    parsCollections.parts,
                    existingPart.key,
                    { ...part,
                      operations: (part.operations && Array.isArray(part.operations)) 
                      ? part.operations.map(operation => ({
                          processName: operation,
                          isContractManufacturing: false,
                          isCompleted: false,
                          isQrScan: false,
                          firstUserNameSurname: null,
                          firstUserId: null
                        }))
                      : [], 
                      firstTransactionDate:new Date()
                    } 

                  );

                  if (resultPart) {
                    console.log(`Part with jobCode ${part.jobCode}, partNo ${part.partNo}, and customerName ${part.customerName} successfully updated.`);
                  } else {
                    console.log(`Failed to update part with jobCode ${part.jobCode}, partNo ${part.partNo}, and customerName ${part.customerName}.`);
                  }
                } else {
                  console.log(`No part found with jobCode ${part.jobCode}, partNo ${part.partNo}, and customerName ${part.customerName} to update.`);
                }
              } catch (error) {
                console.error(`Error updating part with jobCode ${part.jobCode}, partNo ${part.partNo}, and customerName ${part.customerName}:`, error);
              }
            }

            
           if(record.key){

              console.log("var")
              let updateOpenOrderItem;

              //eğer teklif onaylanmadı ama setup yüklenmesi istiyorsa tekrar  
              if(record.orderType === "offer" && record.isOffer && record.isOfferSetupUpdate){
                  console.log("var buraya geldi")
                  updateOpenOrderItem = {
                    isOfferSetupUpdate:false,    
                    isOffer:false            
                  };

                 
                  //önceki setupı sil
                  const setupData = await FirebaseService.getSetupData();
                  const setupItem = setupData.find(setup => setup.jobCode === record.jobCode);
                  console.log("setupItem",setupItem)
                  const deleteSetupData = await FirebaseService.deleteSetupData(setupItem.key);
                  console.log("deleteSetupData",deleteSetupData)
                
                  //önceki openorderofferı sil
                  const openOrderOfferData = await FirebaseService.getOpenOrderOfferData();
                  const openOrderOfferItem = openOrderOfferData.find(item => 
                    typeof item.jobCode === 'string' && typeof record.jobCode === 'string' && record.jobCode.includes(item.jobCode)
                  );
                  
                  if (!openOrderOfferItem) {
                    console.log("No matching job code found.");
                  } else {
                    console.log("Matching job code found:", openOrderOfferItem);
                    const deleteOpenOrderOfferData = await FirebaseService.deleteOpenOrderOfferData(openOrderOfferItem.key);
                    console.log("deleteOpenOrderOfferData",deleteOpenOrderOfferData)
                  
                  } 

              }
              //setup yüklendiyse
              else{

                if(record.orderType === "offer" && !record.isSetup){
                  updateOpenOrderItem = {
                    isSetup:true,
                    isWaiting:true,
                    deadline: new Date(values.deadline),
                    setupName:xmlInfo.setupName ? xmlInfo.setupName : null,
                    jobCode :record.jobCode, //5 hane +setup ismi olması lazım
                  };
                }
                else{
                  updateOpenOrderItem = {
                    isSetup:true,
                    isWaiting:false,
                    deadline: new Date(values.deadline),
                    setupName:record.setupName ? record.setupName :  (xmlInfo.setupName ? xmlInfo.setupName : null),
                    jobCode :record.jobCode,//5 hane +setup ismi olması lazım

                  };
                }
                 
              }      
              console.log("updateOpenOrderItem",updateOpenOrderItem, record.key)

              const resultx = await FirebaseService.updateOpenOrder(record.key, updateOpenOrderItem);
              console.log(resultx);
              if(resultx){
                
                  NotificationService.openSuccessNotification({
                    title: 'İşlem Başarılı',
                    description:"Başarıyla kaydedilmiştir.",
                    placement: 'topRight'
                  });
                  setLoading(false);
                  setTimeout(() => {
                    navigate("/programmerpage");
                  }, 500);
                  
              }
              else{
                  
                  NotificationService.openErrorNotification({
                    title: 'İşlem Başarısız',
                    description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
                    placement: 'topRight'
                  });
                  
                  setLoading(false);
              }  
              
              
            }

            
            //eğer open order ilk defa setupla beraber oluşturuluyorsa  yada teklif onaylanıp siparişe geçmişse 2 durumv ar
            else{
            
              //teklif onaylandıysa açık siparişin offerdan order yap ve setup yüklendiyse
              if(filteredOpenOrder != null){
                console.log("varrrrrrr ")

                let updateOpenOrderItem = {
            
                jobCode : record.jobCode, 
                companyName: record.companyName,
                projectCode: record.projectCode ? record.projectCode : null,
                orderType : "order", 
                stock: record.stock ? record.stock : [] ,
                wastage: record.wastage ? record.wastage : [],
                deadline: new Date(values.deadline),
                isSetup:true,
                isWaiting: false,
                setupName:xmlInfo.setupName ? xmlInfo.setupName : null

                  
                };
          
                console.log("updateOpenOrderItem", updateOpenOrderItem, filteredOpenOrder.key);
            
                const resultx = await FirebaseService.updateOpenOrder(filteredOpenOrder.key, updateOpenOrderItem);
                console.log(resultx);
            
                if (resultx) {
                console.log("başarılı update edildi")

                const item = {
                  isDeleted:false,
                  item: record.jobCode,
                  firstTransactionDate: currentDate,
                  firstUserId: localStorage.getItem("uid"),
                }
                const result = await FirebaseService.addRecording(parsCollections.jobCodes,item);
                console.log("jobcode eklendi result", result)
                NotificationService.openSuccessNotification({
                  title: 'İşlem Başarılı',
                  description: "Başarıyla oluşturuldu",
                  placement: 'topRight'
                });
              
                setLoading(false);
                setTimeout(() => {
                  navigate("/programmerpage");
                }, 500);
                
                
                } else {
                  console.log("başarısız update edilemedi");
                }
                
              }

              //hiçbir şekilde yok
              else{
                console.log("bu open order yok", record.jobCode, record.setupName);

                // Determine whether to use full jobCode or first five characters
                let jobCodeForComparison;
                if (record.setupName) {
                  jobCodeForComparison = record.jobCode; // Use full jobCode if setupName exists
                } else {
                  jobCodeForComparison = record.jobCode.substring(0, 5); // Use first five characters if setupName doesn't exist
                }
                
                // Fetch open order data and filter
                const openOrderAllItems = await FirebaseService.getOpenOrderData();
                const filteredData = openOrderAllItems.filter(item => 
                  !item.isDeleted && 
                  item.isWaiting && 
                  !item.isSetup && 
                  (record.setupName ? item.jobCode === jobCodeForComparison : item.jobCode.includes(jobCodeForComparison))
                );
                
                
              //update geçilcek
              if(filteredData.length > 0){
                let updateOpenOrderItemAgain = {
                  deadline: new Date(values.deadline),
                  isSetup:true,
                  isWaiting: false,
                  jobCode : record.jobCode, 
                  programmerUserId: localStorage.getItem("uid"),
                  programmerName:  localStorage.getItem("name") + " " + localStorage.getItem("surname"),
                  stock: record.stock ? record.stock : [] ,
                  wastage: record.wastage ? record.wastage : [],  
                  setupName:record.setupName ? record.setupName :  (xmlInfo.setupName ? xmlInfo.setupName : null)
           
                };
            
                console.log("updateOpenOrderItemAgain", updateOpenOrderItemAgain, filteredData[0].key);
              
                const resultx = await FirebaseService.updateOpenOrder(filteredData[0].key, updateOpenOrderItemAgain);
                console.log(resultx);
              
                if (resultx.success) {
                      console.log("başarılı update edildi")
      
                      const item = {
                        isDeleted:false,
                        item: record.jobCode,
                        firstTransactionDate: currentDate,
                        firstUserId: localStorage.getItem("uid"),
                      }
                      const result = await FirebaseService.addRecording(parsCollections.jobCodes,item);
                      console.log("jobcode eklendi result", result)
                      NotificationService.openSuccessNotification({
                        title: 'İşlem Başarılı',
                        description: "Başarıyla oluşturuldu",
                        placement: 'topRight'
                      });
                    
                  
                      setLoading(false);
                      setTimeout(() => {
                        navigate("/programmerpage");
                      }, 500);
                } 
                else{
                    NotificationService.openErrorNotification({
                      title: 'İşlem Başarısız',
                      description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.1",
                      placement: 'topRight'
                    });
                    setLoading(false);
                    return;
                }
              }
              //yeni eklencek
              else{
                const currentDate =new Date()
                const data = {
                        firstTransactionDate: currentDate,
                        firstUserId: localStorage.getItem("uid"),
                        programmerUserId: localStorage.getItem("uid"),
                        isDeleted: false,
                        programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
                        jobCode : record.jobCode, 
                        companyName: record.companyName,
                        projectCode: record.projectCode ? record.projectCode : null,
                        orderType : record.orderType, 
                        stock: record.stock ? record.stock : [] ,
                        wastage: record.wastage ? record.wastage : [],
                      // deadline:record.deadline,
                        deadline: new Date(values.deadline),
                        isSetup:true,
                        isOffer:false,
                        isWaiting: record.orderType === "offer" ? true : false ,
                        isOfferApproved:false,
                        noOfferApprovedDescription:null,
                        noQrScanDescription:null,
                        isOfferSetupUpdate:false,
                        isOfferChange:false,
                        isOfferDenied:false,
                      // priority:999999,
                      isChangedProgrammer:false,
                      setupDescription:null,
                      isShipment: false,
                      shipmentPiece: 1,
                      invoiceNumber:null,
                      setupName:xmlInfo.setupName
                      
            
                  };
                  console.log("data",data)
                
                  try {
                      const result = await FirebaseService.addRecording(parsCollections.openOrder,data);
                      if (result.success) {
                        const item = {
                          isDeleted:false,
                          item: data.jobCode,
                          firstTransactionDate: currentDate,
                          firstUserId: localStorage.getItem("uid"),
                        }
                        const result = await FirebaseService.addRecording(parsCollections.jobCodes,item);
                        const currentJobCodeData = await FirebaseService.getCurrentJobCodeData(); // Mevcut iş kodunu al

                       
                        if (data && data.jobCode && typeof data.jobCode === 'string') {
                            console.log("data.jobCode.substring(0, 5) ", data.jobCode.substring(0, 5));
                        
                            const filteredData = currentJobCodeData.find(item => item.jobCode === data.jobCode.substring(0, 5));
                            if (filteredData) {
                                  if (filteredData.key) {
                                    const result2 = await FirebaseService.deleteCurrentJobCodeData(filteredData.key);
                        
                                    if (result2) {
                                        console.log(`Item with key ${filteredData.key} deleted successfully.`);
                                    } else {
                                        console.log(`Failed to delete item with key ${filteredData.key}.`);
                                    }
                                } else {
                                    console.log("filteredData içinde key bulunamadı.");
                                }
                            } else {
                                console.log("Eşleşen jobCode bulunamadı. filteredData undefined.");
                            }
                        } else {
                            console.error("data.jobCode tanımlı değil veya geçersiz.");
                        }
          


                        const openOrderData = await FirebaseService.getOpenOrderData();
                        const filteredOpenOrder = openOrderData.filter(item => 
                          item.jobCode && item.jobCode === data.jobCode.substring(0, 5)
                        );
                        
                        console.log("filteredOpenOrder", filteredOpenOrder);
                        
                        for (const item of filteredOpenOrder) {
                          try {
                            const result = await FirebaseService.deleteOpenOrderDataIsDeleted(item.key);
                            if (result) {
                              console.log(`Item with key ${item.key} deleted successfully.`);
                            } else {
          
                              console.log(`Failed to delete item with key ${item.key}.`);
                            }
                          } catch (error) {
                          
                            console.error(`Error deleting item with key ${item.key}:`, error);
                          }
                        }

                        NotificationService.openSuccessNotification({
                          title: 'İşlem Başarılı',
                          description: result.message,
                          placement: 'topRight'
                        });

                        //bildirim
                        if(record.orderType === "offer"){
                          const ids = await getOneSignalPlayerIds();
                          const userIds = await getOneSignalUserIds();
                         
                          const sentUserIdsMap = new Map();
                          userIds.forEach(userId => {
                            sentUserIdsMap.set(userId, false);
                          });
                          
                          const sentUserIds = Object.fromEntries(sentUserIdsMap);
                          //console.log("sentUserIds mapppp", sentUserIds);
                          if (ids.length > 0) {
                              const oneSignalResult = await OneSignalService.sendNotification("", "Bekleyen Teklif var", ids);
                              console.log("OneSignal notification result:", oneSignalResult);
                            
                              const data = {
                                firstTransactionDate: currentDate,
                                firstUserId: localStorage.getItem("uid"),
                                isDeleted: false,
                                sentUserIds:sentUserIds,
                                firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
                                unitName:localStorage.getItem('userUnit'),
                                title:"Bekleyen Teklif var",
                                companyName:record.companyName,
                                jobCode:record.jobCode,
                                projectCode:record.projectCode,
                                machine:null,
                                type:null,
                                notice:"Teklif verilmesi beklenen açık sipariş yüklenmiştir. Bekleyen teklifleri kontrol ediniz.",
                                description:null
            
                              };

                              const result = await FirebaseService.addRecording(parsCollections.notifications,data);
                              console.log("result",result)
                          } else {
                              console.log("No OneSignal Player IDs found.");
                          }

                        }
                        else{
                          const ids = await getOneSignalPlayerIdsArchieve();
                          const userIds = await getOneSignalUserIdsArchieve()
                         
                          const sentUserIdsMap = new Map();
                          userIds.forEach(userId => {
                            sentUserIdsMap.set(userId, false);
                          });
                          
                          const sentUserIds = Object.fromEntries(sentUserIdsMap);
                          //console.log("sentUserIds mapppp", sentUserIds);
                          if (ids.length > 0) {
                              const oneSignalResult = await OneSignalService.sendNotification("", "Sipariş Gelmiştir.", ids);
                              console.log("OneSignal notification result:", oneSignalResult);
                            

                              const data = {
                                firstTransactionDate: currentDate,
                                firstUserId: localStorage.getItem("uid"),
                                isDeleted: false,
                                sentUserIds:sentUserIds,
                                firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
                                unitName:localStorage.getItem('userUnit'),
                                title:"Sipariş Gelmiştir.",
                                companyName:record.companyName,
                                jobCode:record.jobCode,
                                projectCode:record.projectCode,
                                machine:null,
                                type:null,
                                notice:"Yeni sipariş eklenmiştir. Lütfen açık siparişlerinizi kontrol ediniz.",
                                description:null
            
                              };

                              const result = await FirebaseService.addRecording(parsCollections.notifications,data);
                              console.log("result",result)
                          } else {
                              console.log("No OneSignal Player IDs found.");
                          }
                        }
 
                        setLoading(false);
                        setTimeout(() => {
                          navigate("/programmerpage");
                        }, 500);
                        
                      } else {
                      // console.log("hata",result)
                        NotificationService.openErrorNotification({
                          title: 'İşlem Başarısız',
                          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                          placement: 'topRight'
                        });
                        
                        setLoading(false);
                      }
                      
                      
                  } 
                  catch (error) {
                      console.log("error",error)
                    
                      NotificationService.openErrorNotification({
                        title: 'İşlem Başarısız',
                        description: "Bilinmeyen bir hata ile karşılaşıldı.",
                        placement: 'topRight'
                      });
                      setLoading(false);
                      
                  }  
                  
              }
              
              }
              
            }
        
      } else {
          
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.2",
                placement: 'topRight'
            });
            setLoading(false);
            
      }
      
        
  }; 
  const openPdf = () => {
    if (setupDataa.documentUrl) {
      console.log("setupDataa.documentUrl",setupDataa.documentUrl)
      window.open(setupDataa.documentUrl, '_blank'); 
    } else {
      console.error("PDF URL bulunamadı.");
    }
  };

   return ( 
    <>
    {isLoading ? (
      <> Yükleniyor.. </>
      ) : (
      
      <Form
    name="basic"
    labelCol={{
    span: 4,
    }}
    wrapperCol={{
    span: 20,
    }}
    style={{
    maxWidth: "100%",
    padding:"5% 5% 0 5%"
    }}
    initialValues={{
    remember: true,
    }}
    onFinish={onFinish}
   // onFinishFailed={onFinishFailed}
    autoComplete="off"
>


    <div style={{ display: "flex", flexDirection: "column",}}>
  
    <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}>Yerleşimler</h2>
          
    <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Firma</p>
          {record && record.companyName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
          {record && record.jobCode}
        </div>


        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Setup İsmi</p>
          {xmlInfo && xmlInfo.setupName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Genel Açıklama Ekle </p>
          <Form.Item name="description" >
             <Input  className="input-style"   placeholder="" />
           </Form.Item>  
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
        <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
             <Form.Item
                name="deadline"   >
                <DatePicker   className="input-style"   style={{ width: '100%' }} placeholder="Teslim Tarihi" format="DD-MM-YYYY" disabledDate={disabledDate}  />
             </Form.Item>
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>
          <Form.Item
              name="isScrapDelivery">
              <Checkbox   onChange={() => setIsScrapDelivery(!isScrapDelivery)} style={{fontWeight:"500"}}>
              Hurda Teslim
              </Checkbox>
            </Form.Item>
          </p>
         
        </div>   
      </div>    

        <div style={{marginTop:"20px",}}>
            {plates.map((plate, plateIndex) => (          
              <>
                <div className="setup-item-plates" key={plateIndex} style={{border:"1px solid", borderColor:colors.blue, justifyContent:"space-between", textAlign:"left"}}>
                  <img src={plate.imageUrl}  alt={plate.imageFileName} style={{ width: '500px', height:"300px" }} />

                   <div style={{display:"flex"}}>
                      <div>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Program No:</strong> {plate.programNo}</p>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Ebat:</strong> {plate.height} x {plate.width} x {data.thickness} {plate.unit}</p>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Süre:</strong> {plate.processingTime} </p>
                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Kg (Plaka Ağırlığı):</strong> {plate.kg}</p>

                        <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Adet:</strong> {plate.piece} </p> 
                      {/*  <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Fire Kg:</strong> </p>*/} 

                      <Form.Item
                        name={`description-${plateIndex}-${plate.programNo}`}
                      // label="Açıklama"
                      >
                      <Input.TextArea
                          placeholder="Açıklama Girebilirsiniz"
                          style={{ width: '250px', height: '80px', borderColor: "#1A446C" }}
                        />                      
                        </Form.Item>
                      </div> 

                   </div>     
                       


                       
                   <div style={{border:"1px solid", borderColor:colors.blue, padding:"5px 20px", width:"250px"}}>
                    <p style={{color:colors.blue, fontWeight:"bold"}}>Fire Ekle</p>

                    {additionalContents[plateIndex]?.map((fireItem, contentIndex) => (
                  <div style={{ display: "flex" }} key={contentIndex}>
                    <Form.Item
                      name={`width-${plateIndex}-${contentIndex}-programNo-${plates[plateIndex].programNo}`}
                     
                      rules={[
                        { pattern: /^\d+$/, message: "Sadece rakam girişi yapınız." },
                        { required: true, message: "En girmek zorunludur." },

                        {
                          validator: (_, value) => {
                            if (value && parseInt(value) > plate.width) {
                              return Promise.reject(new Error("En değeri plakanın genişliğinden büyük olamaz."));
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <Input style={{ borderRadius: 0, borderColor: "#1A446C80", width: "70px" }} placeholder="En" />
                    </Form.Item>

                    <Form.Item
                      name={`height-${plateIndex}-${contentIndex}-programNo-${plates[plateIndex].programNo}`}
                      rules={[
                        { pattern: /^\d+$/, message: "Sadece rakam girişi yapınız." },
                        { required: true, message: "Boy girmek zorunludur." },

                        {
                          validator: (_, value) => {
                            if (value && parseInt(value) > plate.height) {
                              return Promise.reject(new Error("Boy değeri plakanın uzunluğundan büyük olamaz."));
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <Input style={{ borderRadius: 0, borderColor: "#1A446C80", width: "70px" }} placeholder="Boy" />
                    </Form.Item>

                    <Form.Item
                      name={`number-${plateIndex}-${contentIndex}-programNo-${plates[plateIndex].programNo}`}
                      rules={[
                        { pattern: /^\d+$/, message: "Sadece rakam girişi yapınız." },
                        
                      ]}
                      initialValue={1} 
                    >
                      <Input style={{ borderRadius: 0, borderColor: "#1A446C80", width: "70px" }} placeholder="Adet" />
                    </Form.Item>

                    <Button onClick={() => removeAdditionalContent(plateIndex, contentIndex)}>-</Button>
                  </div>
                ))}
                 

                <Form.Item style={{ textAlign: "center" }}>
                  <Button onClick={() => addAdditionalContent(plateIndex)}>+</Button>
                </Form.Item>


                  </div>


                </div>
                
             </>   
            ))}
        </div>
        <div style={{display:"flex",marginTop:"20px", justifyContent:"end"}}>
        <Button onClick={openPdf} style={{marginTop:"20px", backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Pdf Görüntüle</Button>

      <Form.Item style={{textAlign:"right",marginTop:"20px"}}>
          <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Kaydet</Button>
      </Form.Item>
        </div>
       
         {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

    </div>
      </Form>
        
       )}
   </>

  )};
export default SetupPlatesDetail;
