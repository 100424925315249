import React, { useState, useEffect } from "react";
import { colors } from "../../environments/environment";
import { Button, Dropdown, Menu } from 'antd';
import FirebaseService from "../../services/firebaseService";

const Notifications = () => {
  const [items, setItems] = useState([]);

  const menu = (
    <Menu>
      {items.map((notification, index) => {
        const userId = localStorage.getItem("uid");
        const isRead = notification.sentUserIds && notification.sentUserIds[userId];
        const backgroundColor = isRead ? 'white' : colors.lightBlue;

        return (
          <Menu.Item
            key={notification.key}
            style={{
              backgroundColor: backgroundColor,
              color: 'black',
            }}
          >
            {notification.notice}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await FirebaseService.getNotificationDataByUserId();
      const filteredData = data.filter(item => !item.isDeleted);
      setItems(filteredData);
    };

    fetchData();
  }, []);

  return (
    <div>
      <div style={{ marginTop: "25px" }}>
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
          <a onClick={e => e.preventDefault()} style={{ textDecoration: "none", cursor: "pointer" }}>
            <img alt="Bildirimler" src="/images/notification.svg" width={41} height={41} />
            <p style={{ color: colors.blue, fontWeight: "bold", fontFamily: "Helvetica", textDecoration: "none" }}>
              Bildirimler
            </p>
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default Notifications;
