import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select ,Checkbox} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import  {colors}  from "../../../environments/environment"


const SetupPartsDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [checkedItems, setCheckedItems] = useState({});
    const [processes, setProcesses] = useState([]);
   // const parts = location.state && location.state.xmlInfo.parts;
   // console.log("parts gelen parts",parts)

    const [updatedParts, setUpdatedParts] = useState(null);

    const data = location.state && location.state.data;
    console.log("data",data)
    const plates = location.state && location.state.xmlInfo.plates;
    console.log("plates",plates)

    const plateCount = plates.length;
    const record = location.state && location.state.record;
    console.log("record",record)
    const xmlInfo = location.state && location.state.xmlInfo;

    const [selectedProcesses, setSelectedProcesses] = useState([]);
    const [isPlazma, setIsPlazma] = useState(false);
    const [setupData, setSetupData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


/*
    useEffect(() => {
      
        const updatedPartsWith60Base = parts.map(part => ({
          ...part,
          time: convertToTimeFormat(convertTo60Base(part.time)),
        }));
      
        setUpdatedParts(updatedPartsWith60Base);
      }, [location.state]);
      

*/

      function convertTo60Base(seconds) {
        return seconds * 60; // 10'luk tabandan 60'a çevirme
      }
      
      function convertToTimeFormat(seconds) {
        // Saatleri hesapla
        const hours = Math.floor(seconds / 3600);
        seconds %= 3600;
      
        // Dakikaları hesapla
        const minutes = Math.floor(seconds / 60);
        seconds %= 60;
      
        // Saniyeleri düzeltilmiş şekilde al
        seconds = Math.floor(seconds);
      
        // Sonuçları iki haneli sayılara dönüştür
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');
      
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      }
      




    const handleCheckboxChange = async (part, index ) => {
        console.log("part index", part, index)
        setCheckedItems((prevItems) => {
          const updatedItems = { ...prevItems, [index]: !prevItems[index] };
          const selectedItems = Object.keys(updatedItems).filter((id) => updatedItems[id]);
    
      
          return updatedItems;
        });
    };
      
     
    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
          setIsLoading(true); 
          const processes = await FirebaseService.getContractManufacturingProcessesData();
          const filteredProcesses = processes.filter(item => !item.isDeleted);
          setProcesses(filteredProcesses);

          const setupData = await FirebaseService.getSetupData();
          const filteredSetup = setupData.find(item => !item.isDeleted && item.jobCode===record.jobCode);
          console.log("filteredSetup",filteredSetup)

          if (filteredSetup) {
            setSetupData(filteredSetup);
            console.log("filteredSetup.parts",filteredSetup.parts)
            setUpdatedParts(filteredSetup.parts || []); // Default to empty array if parts is null or undefined
            setIsLoading(false);
          } else {
            console.warn("No matching setup data found.");
            setSetupData(null); // Or handle this case as needed
            setUpdatedParts([]); // Set to empty array if no setup data found
            setIsLoading(false);
          }

         


        };
        fetchData();
    }, []);

    const handleCheckboxChangeProcess = (processName, partNo) => {
        console.log("nkjn", processName, partNo);
    
        const updatedPartsCopy = [...updatedParts];
        const index = updatedPartsCopy.findIndex(part => part.partNo === partNo);
    
        if (index !== -1) {
            const part = updatedPartsCopy[index];
                if (Array.isArray(part.operations)) {
                const uniqueOperations = new Set(part.operations);
    
                if (uniqueOperations.has(processName)) {
                    uniqueOperations.delete(processName); 
                } else {
                    uniqueOperations.add(processName);
                }
    
                updatedPartsCopy[index].operations = Array.from(uniqueOperations);
    
                setUpdatedParts(updatedPartsCopy);
                console.log("Part güncellendi:", updatedPartsCopy[index]);
            } else if (!part.operations) {
                updatedPartsCopy[index].operations = [processName];
                setUpdatedParts(updatedPartsCopy);
                console.log("Part güncellendi:", updatedPartsCopy[index]);
            } else {
                console.error("Part operations dizisi tanımlı değil:", partNo);
            }
        } else {
            console.error("Part bulunamadı:", partNo);
        }
    };
    

    const handleSelectAll = () => {
        const allChecked = Object.keys(checkedItems).length === updatedParts.length;
        const newCheckedItems = {};
        updatedParts.forEach((part, index) => {
            newCheckedItems[index] = !allChecked;
        });
        setCheckedItems(newCheckedItems);
        console.log("newCheckedItems",newCheckedItems)
    };


    const onChange = (value) => {
        console.log('value',value)
        setSelectedProcesses(value); 
    };


    const handleTotalOperation = () => {
        const selectedParts = updatedParts.filter((part, index) => checkedItems[index]);
        const selectedOperations = selectedProcesses;
        console.log("selectedOperations",selectedOperations)
    
        selectedParts.forEach((part) => {
            if (Array.isArray(part.operations)) {
                selectedOperations.forEach((op) => {
                    if (!part.operations.includes(op)) { 
                        part.operations.push(op);
                    }
                });
            } else {
                part.operations = [...selectedOperations];
            }
            
        });
    
        console.log("updatedParts", updatedParts);
        setUpdatedParts([...updatedParts]);
        setCheckedItems({});
        

        NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: "Seçtiğiniz parçalara başarıyla operasyon eklenmiştir.",
            placement: 'topRight'
          });
        
    };
      
 
    const onFinish = async (values) => {   
        
       
        const updatedXmlInfo = {
            ...xmlInfo,
            parts: updatedParts.map(part => ({
                ...part,
                operations: part.operations || [],
              })),
        };
        const updatedRecord = {
            ...record,
            isPlazma: isPlazma 
        };
        console.log("updatedXmlInfo", updatedXmlInfo,updatedRecord,record)

        if(record.mergedJobCodes && record.mergedJobCodes.length > 0){
            navigate('/setupplatesdetailmergedjobcodes', { 
                state: { 
                    data: data,
                    xmlInfo: updatedXmlInfo,
                    record: updatedRecord,
                
                } 
            }); 

        }
        else{
            navigate('/setupplatesdetail', { 
                state: { 
                    data: data,
                    xmlInfo: updatedXmlInfo,
                    record: updatedRecord,
                    setupData: setupData
                   
    
                } 
            }); 
        }
     
        
    }; 
   
    return ( 
        <>
        {isLoading ? (
          <> Yükleniyor.. </>
          ) : (
            <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 5% 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
           // onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
        
            <div style={{ display: "flex", flexDirection: "column",}}>
          
            <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}>Parça Detayları</h2>
        
             <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: "left", flex: "1 0 13.66%" }}>
                  <p style={{ fontWeight: "bold", color: colors.blue }}>Kalınlık</p>
                  {data && data.thickness}
                </div>
        
                <div style={{ textAlign: "left", flex: "1 0 13.66%" }}>
                  <p style={{ fontWeight: "bold", color: colors.blue }}>Kalite</p>
                  {data && data.quality}
                </div>
        
        
                <div style={{ textAlign: "left", flex: "1 0 13.66%" }}>
                  <p style={{ fontWeight: "bold", color: colors.blue }}>Yüzey</p>
                  {data && data.surface}
                </div>
        
                <div style={{ textAlign: "left", flex: "1 0 13.66%" }}>
                  <p style={{ fontWeight: "bold", color: colors.blue }}>Parça Sayısı</p>
                  {xmlInfo && xmlInfo.totalPartCount}
                </div>
        
                <div style={{ textAlign: "left", flex: "1 0 13.66%" }}>
                  <p style={{ fontWeight: "bold", color: colors.blue }}>Plaka Sayısı</p>
                  {plateCount}
                </div>
        
                <div style={{ textAlign: "left", flex: "1 0 13.66%" }}>
                  <p style={{ fontWeight: "bold", color: colors.blue }}>Toplam Süre</p>
                  {xmlInfo && xmlInfo.totalRuntime}
                </div>
        
                <div style={{ textAlign: "left", flex: "1 0 13.66%" , display:"none"}}>
        <p>
                <Form.Item
                      name="isPlazma">
                      <Checkbox   onChange={() => setIsPlazma(!isPlazma)} >
                     Plazma kullanılacak
                      </Checkbox>
                    </Form.Item>
                    </p>
                  </div>
              
              </div>
        
              <div style={{display:"flex",justifyContent: "space-between"}}>
                 <div style={{ textAlign: "left", flex: "1 0 33.66%" }}>
                    <Checkbox
                    onChange={handleSelectAll}
                    checked={Object.keys(checkedItems).length === updatedParts.length && Object.values(checkedItems).every(item => item)}
                    style={{ position: 'relative', top: "50px" }} > Tümünü Seç 
                    </Checkbox>
                 </div>
        
                
                <div style={{ textAlign: "left", flex: "1 0 33.66%" }}>
                <p style={{ fontWeight: "bold", color: colors.blue }}>Operasyonlar</p>
                <Form.Item
                 name="processName"  >
                        <Select
                        showSearch
                        mode="multiple"
                        onChange={onChange}
                        placeholder="Proses Seçiniz"
                        suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                        options={  processes.map(item => ({ label: item.processName, value: item.processName })) }
                        className="input-style"  
                        filterOption={(input, option) => 
                            option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
                          }
                    />
                    </Form.Item>
                </div>
        
                
        
                 <div style={{ textAlign: "left", flex: "1 0 33.66%" }}>
                    <Button style={{ position: 'relative', top: "50px" ,backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleTotalOperation()}>Seçilenlere operasyon uygula</Button>
                 </div>
        
              </div>
        
              </div>     
              <div >
                <div className="setup-item-container" style={{marginTop:"30px"}}>
                    {updatedParts.map((part, index) => (
                        
                        <div className="setup-item"  key="selectAll" style={{border:"1px solid", borderColor:colors.blue, padding:"10px 30px"}}>
                            <div style={{display:"block"}}> 
                                <div style={{display:"flex"}}>
                                <Checkbox
                                    onChange={() => handleCheckboxChange(part,index)}
                                    checked={checkedItems[index]}
                                    style={{ position: 'relative', bottom:"90px", right:"20px" }}
                                    />    
                                        {part.imageUrl ? (
                                    <img src={part.imageUrl} alt={part.imageFileName} style={{ width: '200px', height:"200px" }} />
                                    ) : (
                                    <div style={{ width: '200px', height:"200px", display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid gray" }}>
                                        <span>No Image Available</span>
                                    </div>
                                    )}

        
                                <div>
                                    <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Part No:</strong> {part.partNo}</p>
                                    <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Firma</strong> {part.customerName}</p>
                                    <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Parça Ağırlığı:</strong> {part.weightSetup} {part.weightUnit}</p>
                                    <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Parça Sayısı:</strong> {part.piece}</p>
                                
                                
                                    <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Ebat:</strong> {part.height} x {part.width} {part.unit}</p>
                                    <p style={{borderBottom:"1px solid", borderColor:colors.blue}}><strong>Süre:</strong> {part.time}</p>
                                </div>
                            
                            
                            
                                </div>
                                <div>
                                    Operasyonlar
                                    {processes.map(process => (
                                        <div key={process.processName}>
                                           <Checkbox
                                                id={process.processName}
                                                onChange={() => handleCheckboxChangeProcess(process.processName, part.partNo)}
                                                checked={part.operations && part.operations.includes(process.processName)}
                                                />
        
                                            <label htmlFor={process.processName} style={{marginLeft:"5px"}}>{process.processName}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
        
                        </div>
                       
                    ))}
                </div>
        
                </div>
        
                <div>
                <Form.Item >
                    <Button  htmlType="submit"  style={{float:"right",backgroundColor:colors.blue,color:"white",fontWeight:"500"}} >İleri</Button>
                    </Form.Item>
                </div>
            </div>
            </Form>
     )}

  </>

)};
export default SetupPartsDetail;


