import React, { useState,useEffect } from "react";
import { Button, Form, Modal, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import UserInfo from "../userInfo";
import WeatherData from "../weatherData";
import  { parsCollections, colors}  from "../../../environments/environment"
import AlarmListLimited from "../alarms/alarmListLimited";
import PurchaseRequestAdd from "../purchaseRequests/purchaseRequestAdd";
import ForeignCurrency from "../foreignCurrency";
import ArchieveOpenOrderListLimited from "../openOrders/archieveOpenOrderListLimited";
import PersonnelPerfomanceListLimited from "../personnelPerfomance/personnelPerfomanceListLimited";
import ScrapAdd from "../scraps/scrapAdd";
import Pdf from './ProgrammerPage.pdf';
import ProductionOpenOrderGroupListLimited from "../openOrders/productionOpenOrder/productionOpenOrderGroupListLimited";
import Notifications from "../notifications";
import FirebaseService from "../../../services/firebaseService";
import WebNavbar from "../webNavbar";

const ProductionManagerPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [jobCodes, setJobCodes] = useState([]); 
  const [selectedJobCode, setSelectedJobCode] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [items,setItems] = useState([])


  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const setupData = await FirebaseService.getSetupData();
        console.log('Setup Data:', setupData); 
        const data = setupData.filter(item => !item.isDeleted);
       setItems(data);
      } catch (error) {
        console.error("Error fetching setup data:", error);
      }
    };
    
    fetchData();
  }, []);



  const onChange = (value) => {
    console.log("value",value)
    const item = items.find((code) => code.jobCode === value); // Seçilen iş koduna karşılık gelen item'i bul
    if (item) {
      setSelectedItem(item); // Seçilen item'i güncelle
      setSelectedJobCode(value); // Seçilen iş kodunu güncelle
    } else {
      setSelectedItem(null); // Hiçbir item bulunamazsa null yap
      setSelectedJobCode(null); // İş kodunu boş yap
    }
  };

  const handleOk = async () => {
    console.log("Yönlendirme lazım", selectedJobCode);
    if (selectedJobCode) {
      console.log("Selected item", selectedItem);
     navigate('/operationdetailproductmanager', { state: { record: selectedItem , jobCode:selectedJobCode} });
    } else {
      console.log("Please select a job code.");
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleProcessStatus = () => {
    navigate('/processstatus')
  };


  
  const handleSortEdit = () => {
    navigate('/sorteditlist')
  };

  const handleContractManufacturingDisplayList = () => {
    navigate('/contractmanufacturingdisplaylist')
  }; 

  const handlePurchaseRequest = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setTimeout(() => {
      window.location.reload();
    }, 100);
    setIsModalVisible(false);
  };

  const handlePurchaseRequestAddFinish = () => {
    setIsModalVisible(false); 
  };

  const handleFault = () => {
    navigate('/faultlist')
  };

  const handleScrapList = () => {
    navigate('/scrapList')
  };

  const [selectedFirst5, setSelectedFirst5] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);

  const getFirst5Chars = (jobCode) => jobCode.slice(0, 5);

  const handleFirst5Change = (value) => {
    setSelectedFirst5(value);
    const filtered = items.filter((item) => getFirst5Chars(item.jobCode) === value);
    setFilteredItems(filtered);
  };

  return (
    <div style={{padding:"3%"}}>
   <WebNavbar />
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
      <ProductionOpenOrderGroupListLimited />
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
      <ArchieveOpenOrderListLimited />

      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
      <PersonnelPerfomanceListLimited />

      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px", display:"block" }}>
        <div>
        <AlarmListLimited />
        </div>
      
        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: "10px" }}>
           <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button  
                className="classic-button"
                onClick={handleContractManufacturingDisplayList}>
                Fason Üretim Takip
            </Button>
            <Modal
                title="Satın Alma Talep Et"
                visible={isModalVisible}
                onCancel={handleModalCancel}
                footer={null}
                >
                <PurchaseRequestAdd onClose={handlePurchaseRequestAddFinish} />
                </Modal>

            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button  
            className="classic-button"
                onClick={handlePurchaseRequest}>
                Satın Alma Talep Et
            </Button>
            <Modal
                title="Satın Alma Talep Et"
                visible={isModalVisible}
                onCancel={handleModalCancel}
                footer={null}
                >
                <PurchaseRequestAdd onClose={handlePurchaseRequestAddFinish} />
                </Modal>

            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button  
            className="classic-button"
                 onClick={handleSortEdit}>
            Sıralama Düzenle
             </Button>

            </div>

           

           

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
           
            <Button  
            className="classic-button"
            onClick={handleFault}>
           Arıza Listesi
             </Button>
          

            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button  
              className="classic-button"
              onClick={handleScrapList}>
           Hurda Listesi
            </Button>
          
            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button  
              className="classic-button"
              onClick={() =>   setVisible(true)}>
           Sevk Resmi Bas
            </Button>
        {/*<Modal
            title=""
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={null}
            className="operation-detail-popup"
          >
            <OperationDetail  record={recordForModal}  onClose={handleSetupFinish} />
      </Modal>*/}

<Modal
      title="Görmek istediğiniz Setup'ı seçiniz"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      className="popup-modal-button"
      okText="Devam Et"
      cancelText="İptal"
    >
      <div>
        <Form.Item
          name="first5"
          rules={[
            {
              required: true,
              message: 'Lütfen iş kodu seçiniz!',
            },
          ]}
          style={{ marginBottom: "40px", marginTop: "30px" }}
        >
          <Select
          showSearch
            onChange={handleFirst5Change}
            placeholder="İş Kodu Seçiniz"
            suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
            options={[
              ...new Set(items.map((item) => getFirst5Chars(item.jobCode)))
            ].map((first5) => ({ label: first5, value: first5 }))}
            className="input-style"
            filterOption={(input, option) => 
              option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
            }
          />
        </Form.Item>

        {selectedFirst5 && (
          <Form.Item
            name="jobCode"
            rules={[
              {
                required: true,
                message: 'Lütfen iş kodu seçiniz!',
              },
            ]}
            style={{ marginBottom: "40px" }}
          >
            <Select
            showSearch
              placeholder="Setup Seçiniz"
              suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
              options={filteredItems.map((item) => ({
                label: item.jobCode,
                value: item.jobCode,
              }))}
              className="input-style"
              onChange={onChange}
              filterOption={(input, option) => 
                option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
              }
            />
          </Form.Item>
        )}
      </div>
    </Modal>

            </div>
            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
              <Button 
              className="classic-button"
              onClick={handleProcessStatus}>
              İş Durum Listesi
              </Button>
               
            </div>




        </div>

   
   
      </div>
    </div>
 
   
    </div>
  );
};

export default ProductionManagerPage;

