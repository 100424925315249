import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,Spin} from 'antd';
import NotificationService from "../../services/antNotificationService";
import FirebaseService from "../../services/firebaseService";
import  { parsCollections, qualityGroup}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';



const DensityAdd = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
     
      };
      fetchData();
    }, []);

    const onFinish = async (values) => {
      setLoading(true)
        const currentDate =new Date()
        const densityValue = parseFloat(values.density);
     
        try {
        
            const all = await FirebaseService.getDensitiesData();
            const newItems = all.filter((entry) => entry.quality.toLowerCase() === values.quality.toLowerCase());
            if(newItems.length > 0){
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "Bu kaliteye ait özkütle değeri daha önceden girilmiştir.",
                placement: 'topRight'
              });
              setLoading(false)
              return;
             }
    
            else {
                const data = {
                    firstTransactionDate: currentDate,
                    firstUserId: localStorage.getItem("uid"),
                    isDeleted: false,
                    density: densityValue,
                    quality: values.quality,
                    fuel: values.fuel
                };
    
                const result = await FirebaseService.addRecording(parsCollections.densities, data);
                if (result.success) {
                    NotificationService.openSuccessNotification({
                        title: 'İşlem Başarılı',
                        description: result.message,
                        placement: 'topRight'
                    });
                    setLoading(false)
                    setTimeout(() => {
                        navigate("/densitylist");
                    }, 2000);
                } else {
                    NotificationService.openErrorNotification({
                        title: 'İşlem Başarısız',
                        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                        placement: 'topRight'
                    });
                    setLoading(false)
                }
            }
        } catch (error) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "Bilinmeyen bir hata ile karşılaşıldı.",
                placement: 'topRight'
            });
            setLoading(false)
        }
        
     
    }; 

    const onFinishFailed = (errorInfo) => {
        NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
      };
      
    
   
      const onChange = (value) => {
        //console.log(`selected ${value}`);
        
    };

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 2,
            }}
            wrapperCol={{
            span: 8,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

            <Form.Item
                label="Kalite"
                 name="quality"
                 rules={[
                   {
                     required: true,
                     message: 'Lütfen kalite giriniz!',
                   },
                 ]}
              
               >
                 <Input   className="input-style" style={{width:"100%"}} placeholder="Kalite Giriniz" />
               </Form.Item>

            <Form.Item
            label="Özkütle"
            name="density"
            rules={[
                {
                    required: true,
                    message: 'Lütfen özkütle giriniz!',
                },
                {
                    pattern: /^[0-9.]+$/,
                    message: 'Sadece rakam ve nokta (.) girişi yapınız.',
                },
               
            ]}
            
          
            >
           <Input className="input-style" style={{width:"100%"}} placeholder="Özkütle"/>
            </Form.Item>


            <Form.Item
            label="Tür"
          name="fuel"
          rules={[
            {
              required: true,
              message: 'Lütfen seçiniz!',
            },
          ]}
       
        >
          <Select
          showSearch
            onChange={onChange}
            placeholder="Kalite Grupu Seçiniz"
            className="input-style" 
            style={{width:"100%"}}
            suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
            options={qualityGroup.map(item => ({ label: item.label, value: item.value }))}
            filterOption={(input, option) => 
              option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
            }
          />
            </Form.Item>
            
          <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button loading={loading} htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}


    </div>)

        };
  export default DensityAdd;