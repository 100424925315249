




import React,{useEffect,useState} from "react";
import { Table, Button, Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import  { colors}  from "../../../environments/environment"
import { useLocation  } from 'react-router-dom';
import {Space,Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import  { placeholderMap}  from "../../../environments/environment"
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";

const PurchaseRequestList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const purchaseRequests = location.state && location.state.record;
  const [items, setItems] = useState();
  const [filteredItems, setFilteredItems] = useState([]);


  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const allItems = await FirebaseService.getAllItemsData();
      console.log("allItems",allItems)
    
      const updatedpurchaseRequests = await Promise.all(
        purchaseRequests.map(async (item) => {
          if (item.requestedProductInfo && Array.isArray(item.requestedProductInfo)) {
            for (let product of item.requestedProductInfo) {
              if (!product.width || !product.height) {
                const allItem = allItems.find((i) => i.key === product.id);
                if (allItem) {
                  console.log("ife girdi")
                  product.width = product.width || allItem.productDictionary.width || null;
                  product.height = product.height || allItem.productDictionary.height || null;
                  product.thickness = product.thickness || allItem.productDictionary.thickness || null;
                  product.surface = product.surface || allItem.productDictionary.surface || null;
                  product.quality = product.quality || allItem.productDictionary.quality || null;

                }
              }
            }
          }
          return item;
        })
      );

      console.log("updatedpurchaseRequests",updatedpurchaseRequests)

      const merged = {};
      updatedpurchaseRequests.forEach(item => {
      if (item.requestedProductInfo && Array.isArray(item.requestedProductInfo)) {
        item.requestedProductInfo.forEach(product => {
          const productId = product.id;
          if (!productId) return;
          if (!merged[productId]) {
            merged[productId] = {
              id: productId,
              productType: item.productType || null,
              quality: product.quality ?? null,
              surface: product.surface ?? null,
              thickness: product.thickness ?? null,
              width: product.width ?? null,
              height: product.height ?? null,
              code: product.code ?? null,
              items: [item] 
            };
          } else {
            const mergedItem = merged[productId];
            
            mergedItem.items.push(item);
  
            mergedItem.productType ||= item.productType;
            mergedItem.quality ||= product.quality;
            mergedItem.surface ||= product.surface;
            mergedItem.thickness ||= product.thickness;
            mergedItem.width ||= product.width;
            mergedItem.height ||= product.height;
            mergedItem.code ||= product.code;

          }
       
        });
      } else {
        merged[item.id] = {
          id: item.id,
          productType: item.productType || null,
          quality: item.quality ?? null,
          surface: item.surface ?? null,
          thickness: item.thickness ?? null,
          width: item.width ?? null,
          height: item.height ?? null,
          code: item.code ?? null,
          items: [item]
        };
      }
    });
  
    const mergedItems = Object.values(merged);
    console.log("mergedItems",mergedItems)
    setItems(mergedItems);
    setFilteredItems(mergedItems);
     /* const mergedItems = mergeItems(updatedpurchaseRequests);
      setItems(mergedItems);
      console.log("mergedItems",mergedItems)
      setFilteredItems(mergedItems);
*/
  
         
      
   
      
    };


    if (purchaseRequests && purchaseRequests.length > 0) {
      fetchData();
    }
  }, [purchaseRequests]);



  const mergeItems = async (items) => {
  
   
  };
  
  function generateRandomCode(length) {
    const characters = '0123456789';
    let result = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
  
    return result;
  }
  const goEditPage = () => {
    console.log("selectedItems", selectedItems);
    if(selectedItems.length === 0){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: 'Lütfen ürün seçiniz.',
        placement: 'topRight'
        });
        return; 
    }

    const groupedItems = selectedItems.reduce((acc, item) => {
      const { productId, requestedProductInfo } = item;
    
      const matchingRequestedItem = requestedProductInfo?.find(info => info.id === productId);
    
      const itemPiece = matchingRequestedItem?.piece 
        ? parseInt(matchingRequestedItem.piece, 10) 
        : (item.piece ? parseInt(item.piece, 10) : 1);
    
      if (acc[productId]) {
        acc[productId].piece += itemPiece;
      } else {
        acc[productId] = { ...item, piece: itemPiece };
      }
    
      return acc;
    }, {});
    
    const groupedArray = Object.values(groupedItems);

    const selectedKeys = [
      'width',
      'height',
      'thickness',
      'piece',
      'productId',
      'quality',
      "surface"
    ];

    
    
    const filteredGroupedItems = Object.values(groupedItems).map(item => {
      const newItem = {};
      selectedKeys.forEach(key => {
        newItem[key] = item[key];
      });
      return newItem;
    });

    console.log("filteredGroupedItems",filteredGroupedItems)
    const newItems = selectedItems
    .map(item => {
      if (!Array.isArray(item.requestedProductInfo)) {
        console.warn("requestedProductInfo array değil:", item);
        return null; 
      }
  
      const matchingRequested = item.requestedProductInfo.find(
        info => info.id === item.productId || info.productId === item.productId
      );
  
      if (matchingRequested) {
        // `item` içindeki çıkarmak istediğimiz alanları hariç tut
        const { 
          requestedProductInfo, recordId, itemKey, companiesAndPrice, 
          deadline, firstTransactionDateEdit, isDeleted, jobCode, id, 
          productType, quality, thickness, unitName, surface, width, 
          firstTransactionDate,firstUserId,firstUserNameSurname,height,
          ...filteredItem 
        } = item;
  
        const { 
          requestedProductInfo: _1, recordId: _2, itemKey: _3, companiesAndPrice: _4, 
          deadline: _6, firstTransactionDateEdit: _7, isDeleted: _8, jobCode: _9, id: _10, 
          productType: _11, quality: _12, thickness: _13, unitName: _14, surface: _15, width: _16, 
          firstTransactionDate: _17, firstUserId: _18,firstUserNameSurname:_19, height: _20,
          ...filteredMatchingRequested 
        } = matchingRequested;
  
        return { ...filteredItem, ...filteredMatchingRequested };
      }
  
      return item; 
    })
    .filter(item => item !== null);
  

    console.log("newItems",newItems);

    const generalRecord = {
      offerJobCode: generateRandomCode(5),
      deadline: groupedArray[0]?.deadline,
      productType: groupedArray[0]?.productType,
      jobCode:groupedArray[0]?.jobCode,
      items: newItems
    };
    
    console.log("generalRecord",generalRecord)
  
    
  navigate('/purchaserequestdetail', { state: { record: filteredGroupedItems ,generalRecord:generalRecord} });
  };
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase(); 
      const itemValue = item[dataIndex]?.toString().toLowerCase(); 
      return (
        !item.isDeleted &&
       
        (value ? itemValue.includes(value) : true) 
      );
    });
    setFilteredItems(filteredData);
  };
  
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };

  const getColumnSearchProps = dataIndex => ({
    
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
     <Input
          id="search-input"  
          placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90,background:colors.blue }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });

  const columns = [
    {
      title: 'Ürün',
      dataIndex: 'productType',
      key: 'productType',
      
      render: (text,record) => <span> {text === 'raw' ? 'Saç Levha' : text === 'other' ? 'Diğer' : text}
      </span>,
      ...getColumnSearchProps('companyName'),
      sorter: (a, b) => {
        const productTypeA = a && a.productType ? a.productType : '';
        const productTypeB = b && b.productType ? b.productType : '';
        return productTypeA.localeCompare(productTypeB);
      }
    },
    {
      title: 'Kalınlık',
      dataIndex: 'thickness',
      key: 'thickness',
      render: text => <span >{text} mm </span>,
      ...getColumnSearchProps('thickness'),
    },
    {
      title: 'Kalite',
      dataIndex: 'quality',
      key: 'quality',
      render: text => <span >{text}</span>,
      ...getColumnSearchProps('quality'),
    },
    {
      title: 'En',
      dataIndex: 'width',
      key: 'width',
      render: text => <span >{text}</span>,
      ...getColumnSearchProps('width'),
    },
    {
      title: 'Boy',
      dataIndex: 'height',
      key: 'height',
      render: text => <span >{text}</span>,
      ...getColumnSearchProps('height'),
    },
    {
      title: 'Yüzey',
      dataIndex: 'surface',
      key: 'surface',
      render: text => <span >{text}</span>,
       ...getColumnSearchProps('surface'),

    },   

     
  ];

  const [selectedItems, setSelectedItems] = useState([]);
  
  const handleCheckboxChange = async (item, record) => {
    const isMissingData = 
      record.quality === undefined || 
      record.surface === undefined || 
      record.thickness === undefined || 
      record.width === undefined;
  
    let updatedRecord = { ...record };
  
    if (isMissingData) {
      const productDetail = await getProductDetailById(record.id);
      if (productDetail) {
        updatedRecord = {
          ...record,
          quality: productDetail.quality ?? record.quality,
          surface: productDetail.surface ?? record.surface,
          thickness: productDetail.thickness ?? record.thickness,
          width: productDetail.width ?? record.width,
        };
      }
    }
  
    setSelectedItems((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (selectedItem) => selectedItem.key === item.key && selectedItem.recordId === record.id
      );
  
      if (isAlreadySelected) {
        return prevSelected.filter(
          (selectedItem) => !(selectedItem.key === item.key && selectedItem.recordId === record.id)
        );
      } else {
        const newItem = {
          ...item,
          key: item.key,       
          recordId: record.id,
          height: updatedRecord.height,
          width: updatedRecord.width,
          productType: updatedRecord.productType,
          quality: updatedRecord.quality,
          surface: updatedRecord.surface,
          thickness: updatedRecord.thickness,
          productId: updatedRecord.id,
        };
  
        return [...prevSelected, newItem];
      }
    });
  };
  
  
  const getProductDetailById = async (productId) => {
    try {
      const productDetail = await FirebaseService.getAllItemtDataById(productId);
      return productDetail;
    } catch (error) {
      console.error("Hata:", error);
      return null;
    }
  };
  

  const [selectAll, setSelectAll] = useState([]); 
  const handleSelectAllChange = (record) => {

    if (!selectAll.includes(record.id)) {
        // Eğer record.id seçili değilse, tümünü seç
        const updatedItems = record.items.map(item => ({
            ...item,
            quality: record.quality,
            surface: record.surface, 
            thickness: record.thickness,
            width: record.width,
            recordId: record.id,
            productId: record.id,
            height: record.height
        }));

        setSelectedItems(prevItems => [...prevItems, ...updatedItems]); 
        setSelectAll(prevIds => [...prevIds, record.id]); 
    } else {
      setSelectedItems(prevItems => 
        prevItems.filter(item => item.productId !== record.id) // Sadece record.id olanları kaldır
      );
      setSelectAll(prevIds => prevIds.filter(id => id !== record.id)); 
    }
  };

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3  style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>Satın Alma Gelen Talepler</h3>
        
          <Table
            locale={{
              emptyText: 'Henüz satın alma talebi bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={filteredItems}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowKey="id"
            rowClassName="custom-news-row"
            expandable={{
              expandedRowRender: (record) => (
                <div>
                  <div style={{ marginLeft: "55px", marginTop: "15px", marginBottom: "15px", display: "flex" }}>
                 
                    <div style={{ width: "20%", marginBottom: "10px" }}>
                      <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Müşteri Firma:</span>
                    </div>
                    <div style={{ width: "20%", marginBottom: "10px" }}>
                      <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>İş Kodu:</span>
                    </div>
                    <div style={{ width: "20%", marginBottom: "10px" }}>
                      <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Talep Eden Kişi:</span>
                    </div>
                    <div style={{ width: "20%", marginBottom: "10px" }}>
                      <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Birim:</span>
                    </div>
                    <div style={{ width: "20%", marginBottom: "10px" }}>
                      <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Parça Sayısı:</span>
                    </div>
                    <div style={{ width: "20%", marginBottom: "10px" }}>
                      <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Termin Tarihi:</span>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={selectAll.includes(record.id)}
                      onChange={() => handleSelectAllChange(record,record.id)}
                      style={{ fontWeight: "500" }}
                    />
                    <span>Tümünü Seç</span> 
                  </div>
                  {record.items && record.items.length > 0 ? (
                    record.items.map((item, index) => (

                      <div key={`${record.id}-${index}`} style={{ marginLeft: "55px", marginTop: "15px", display: "flex" }}>
                         <Checkbox
                            style={{ fontWeight: "500" }}
                            onChange={() => handleCheckboxChange(item,record)}
                            checked={selectedItems.some(itemSelected => itemSelected.key === item.key && itemSelected.recordId === record.id)}

                          />
                        <div style={{ width: "20%", marginBottom: "10px" }}>
                          <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                            {item.companyName || "-"}
                          </span>
                        </div>
            
                        <div style={{ width: "20%", marginBottom: "10px" }}>
                          <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                            {item.jobCode || "-"}
                          </span>
                        </div>
            
                        <div style={{ width: "20%", marginBottom: "10px" }}>
                          <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                            {item.firstUserNameSurname || "-"}
                          </span>
                        </div>
            
                        <div style={{ width: "20%", marginBottom: "10px" }}>
                          <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                            {item.unitName || "-"}
                          </span>
                        </div>
            
                        <div style={{ width: "20%", marginBottom: "10px" }}>
                          <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                            {item.requestedProductInfo?.find(p => p.id === record.id)?.piece || "-"}
                          </span>
                        </div>
            
                        <div style={{ width: "20%", marginBottom: "10px" }}>
                          <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                            {item.deadline ? (
                              new Date(item.deadline.seconds * 1000).toLocaleDateString('tr-TR')
                            ) : (
                              'Termin tarihi bulunamadı'
                            )}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div style={{ marginLeft: "55px", marginTop: "15px", display: "flex" }}>
                      <div style={{ width: "20%", marginBottom: "10px" }}>
                        <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                          "-"
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ),
            }}           
          />
          
          <Button onClick={()=>goEditPage()} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500", float:"right"}}>
          Devam Et
       </Button>
       
    </div>


  };
  export default PurchaseRequestList;

  

