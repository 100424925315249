import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input, Modal, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import  { colors, parsCollections}  from "../../../../environments/environment"
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import OneSignalService from "../../../../services/oneSignalService";

const GivenOrderOpenOrderList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  //console.log("record",record)
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem("qualityWeights");
    localStorage.removeItem("paymentMethod");
    localStorage.removeItem("machineTimes");

    fetchData();
  }, []);

  function isEqual(obj1, obj2) {
    // Tüm özelliklerin eşit olup olmadığını karşılaştır
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
  function addTimes(time1, time2) {
    const [h1, m1, s1] = time1.split(':').map(Number);
    const [h2, m2, s2] = time2.split(':').map(Number);
  
    let seconds = s1 + s2;
    let minutes = m1 + m2;
    let hours = h1 + h2;
  
    if (seconds >= 60) {
      seconds -= 60;
      minutes++;
    }
    if (minutes >= 60) {
      minutes -= 60;
      hours++;
    }
  
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }
  
  function pad(num) {
    return num.toString().padStart(2, '0');
  }
  
  
  const fetchData = async () => {
   
      const invoiceData = await FirebaseService.getOpenOrderOfferData();
      const filteredData = invoiceData.filter(
        item => !item.isDeleted &&
        item.type === "order"
      );
       console.log("filteredData",filteredData)
       setItems(filteredData)
   
  };


  const goOfferPage = (record) => {
    navigate('/offerdetail', { state: { record } })
  };

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const goDelete = async (record) => {
    console.log("delete", record);
    
    let success = true; 
    setLoading(true);

    // Tüm teklifleri getirin
    const allOpenOffers = await FirebaseService.getOpenOrderData();

    // Sadece bekleyen teklifleri filtreleyin (orderType: "offer" ve isWaiting: true)
    const filteredOpenOffers = allOpenOffers.filter(item => 
        !item.isDeleted && 
        item.isWaiting && 
        item.orderType === "offer" && 
        item.jobCode.includes(record.jobCode)
    );
    
    const offersWithAllItemsQrScanFalse = filteredOpenOffers.filter(offer => {
        const stockItems = offer.stock || [];
        const wastageItems = offer.wastage || [];
    
        const allStockItemsQrScanFalse = stockItems.every(item => item.isQrScan === false);
        const allWastageItemsQrScanFalse = wastageItems.every(item => item.isQrScan === false);
    
        return allStockItemsQrScanFalse && allWastageItemsQrScanFalse;
    });
    
    console.log("offersWithAllItemsQrScanFalse", offersWithAllItemsQrScanFalse);
    if (offersWithAllItemsQrScanFalse.length === 0) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bu teklif silinemez.",
        placement: 'topRight'
      });
      setLoading(false);
      return;
    } else {
      let combinedStockItems = [];

      offersWithAllItemsQrScanFalse.forEach(offer => {
        if (offer.stock) {
            offer.stock.forEach(stockItem => {
                const existingItemIndex = combinedStockItems.findIndex(item => item.id === stockItem.id);
    
                if (existingItemIndex !== -1) {
                    combinedStockItems[existingItemIndex].piece += stockItem.piece;
                } else {
                    combinedStockItems.push({ ...stockItem });
                }
            });
        }
      });
    
      console.log("Combined Stock Items:", combinedStockItems);
      
      // Stokları güncelleme işlemi
      async function updateCombinedStockItems(stockItems) {
        for (const item of stockItems) {
            const { id, piece, isSendPurchase } = item;
            if (isSendPurchase) {
              console.log("Satın alma talebi gitmiş, waiting değişiyor");
              const resultPurchase = await FirebaseService.updateStockWaitingPieceRemove(id, piece);
              console.log("Result for item", id, ":", resultPurchase);
            } else {
              console.log("Rezerve değişiyor");
              const result = await FirebaseService.updateStockReservedPieceRemove(id, piece);
              console.log("Result for item", id, ":", result);
            }
        }
      }
      updateCombinedStockItems(combinedStockItems);

      // Tekliflere ait wastage (atık) verileriyle ilgili işlem
      let combinedWastageItems = [];
      offersWithAllItemsQrScanFalse.forEach(offer => {
        if (offer.wastage) {
          offer.wastage.forEach(wastageItem => {
              const existingItemIndex = combinedWastageItems.findIndex(item => item.id === wastageItem.id);
  
              if (existingItemIndex !== -1) {
                combinedWastageItems[existingItemIndex].piece += wastageItem.piece;
              } else {
                combinedWastageItems.push({ ...wastageItem });
              }
          });
        }
      });

      console.log("Combined Wastage Items:", combinedWastageItems);

      async function updateCombinedWastageItems(wastageItems) {
        for (const item of wastageItems) {
            const { id, piece } = item;
            const result = await FirebaseService.updateWastageReservedPieceRemove(id, piece);
            console.log("Result for item", id, ":", result);
        }
      }
      updateCombinedWastageItems(combinedWastageItems);

      // Teklif silme işlemi
      for (const offer of filteredOpenOffers) {
        try {
          const result = await FirebaseService.deleteOpenOrderDataIsDeleted(offer.key);
          if (result) {
            console.log(`Offer with key ${offer.key} deleted successfully.`);
          } else {
            success = false;
            console.log(`Failed to delete offer with key ${offer.key}.`);
          }
        } catch (error) {
          success = false;
          console.error(`Error deleting offer with key ${offer.key}:`, error);
        }
      }
  
      if (success) {
        NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: 'Verilen teklif başarıyla silindi.',
            placement: 'topRight'
        });
        setLoading(false);
        setTimeout(() => {
          navigate("/programmerpage");
        }, 2000);
      } else {
        NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: 'Verilen teklif silinemedi. Lütfen daha sonra tekrar deneyiniz',
            placement: 'topRight'
        }); 
        setLoading(false);
      }
    }
}

const handleViewOfferDetails = (record) => {
navigate('/orderdetailview', { state: { selectedItem: record } })
};


  
  const calculateTotalTime = (timeStrings) => {
    const totalSeconds = timeStrings.reduce((acc, time) => {
      const [hours, minutes, seconds] = time.split(':').map(Number);
      return acc + (hours * 3600) + (minutes * 60) + seconds;
    }, 0);
    return formatTime(totalSeconds);
  };

  const columns = [
    {
      title: 'Müşteri Firma',
      dataIndex: 'companyName',
      key: 'companyName',
     
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
    
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
        title: 'Proje Kodu',
        dataIndex: 'projectCode',
        key: 'projectCode',
    
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
      },
      /*
      {
        title: 'Programcı',
        dataIndex: 'programmerName',
        key: 'programmerName',
       
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text.map(item => (
              <>
              <div key={item}>-{item}</div>
             
              </>
            ))}
          </span>
          
        )
              },
              */
             
    {
      title: 'Programcı',
      dataIndex: 'programmerName',
       key: 'programmerName',
        render: (text, record) => (
         <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
          <div key={text[0]}>-{text[0]}</div>
          </span>
       )
     },
       /*       
    {
        title: 'Durum',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
          let statusColor = "#000000";  
          return <span style={{ color: record.isDeleted ? "#00000080" : statusColor }}>{text}</span>;
        },
      
      },*/
      {
        title: 'Toplam Kilo',
        dataIndex: 'totalKg',
        key: 'totalKg',
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {record && record.totalKgs.toFixed(2)} Kg
          </span>
        )
        
      },
     
      {
        title: 'Toplam Süre',
        dataIndex: 'setupTime',
        key: 'setupTime',
        render: (text, record) => {
          const totalSetupTime = Object.values(record.setupTime).reduce((total, timeString) => addTimes(total, timeString), '00:00:00');
      
          return (
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {totalSetupTime}
            </span>
          );
        }
      }
,      
      
   /*
      {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
        render: (text, record) => {
          // record.deadline var mı ve bir dizi mi kontrol et
          const formattedDeadlines = Array.isArray(record.deadline) ? 
            record.deadline
              .filter(d => d !== null) // null değerleri filtrele
              .map(d => new Date(d.seconds * 1000).toLocaleDateString('tr-TR')) : 
            [];
      
          return (
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
              {formattedDeadlines.length > 0 ? (
                formattedDeadlines.map((date, index) => (
                  <div key={index}>-{date}</div>
                ))
              ) : (
                <div>-</div>
              )}
           
            </span>
          );
        }
      }
      
      
      {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
        render: (text, record) => {
          // record.deadline var mı ve bir dizi mi kontrol et
          const formattedDeadlines = Array.isArray(record.deadline) ? 
            record.deadline
              .filter(d => d !== null) // null değerleri filtrele
              .map(d => new Date(d.seconds * 1000).toLocaleDateString('tr-TR')) : 
            [];
        
          return (
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
              {formattedDeadlines.length > 0 ? (
                <div>{formattedDeadlines[0]}</div>
              ) : (
                <div>-</div>
              )}
            </span>
          );
        }
      }
,      */
           
{
  title: '   ',
  dataIndex: 'edit',
  key: 'edit',
  render: (text, record) => (
      <Button 
              onClick={() => handleViewOfferDetails(record)} 
              style={{backgroundColor: colors.blue, color: "white", fontWeight: "500"}}
              >
              Fiyatları Gör
              </Button>   
  ),
},
/*
{
  title: ' ',
  dataIndex: 'delete',
  key: 'delete',
  render: (text, record) => (
    <img
    src="/images/delete.svg"
      alt="Sil"
      onClick={() => goDelete(record)}
      style={{ cursor: 'pointer', width: '20px', height: '20px' }} 
    />
  ),
}
*/
  ];


  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Faturalandırılmışlar</h3>
        <Input
        style={{
          borderRadius: 0,
          border: 'none',
          borderBottom: '1px solid #1A446C80',
          width: '100%',
          maxWidth: '400px',
          textAlign: 'left',
          outline: 'none',
        }}
        placeholder="İş Kodu Ara"
        suffix={
          <Space>
              <img width={15} alt="edit" src="/images/searchicon.png" />
          </Space>
        }
        onChange={(e) => {
          const searchValue = e.target.value.toLowerCase();
          const filteredData = items.filter(item => {
            const jobCode = item.jobCode.toLowerCase() ;
            return jobCode.includes(searchValue);
          });
          setItems(filteredData);
        }}
      />
<Table
  locale={{
    emptyText: 'Henüz faturalandırılmış bulunmamaktadır...',
    filterReset: 'Sıfırla',
    filterTitle: 'Filtre Menüsü',
    selectAll: 'Hepsini Seç',
    selectInvert: 'Tersini Seç',
    selectionAll: 'Tümünü Seç',
    sortTitle: 'Sıralama',
    triggerDesc: 'Azalan sıralama için tıklayın',
    triggerAsc: 'Artan sıralama için tıklayın',
    cancelSort: 'Sıralamayı iptal etmek için tıklayın',
  }}
  dataSource={items}
  columns={columns}
  showHeader={true}
  pagination={false}
  className="custom-news-table"
  rowClassName="custom-news-row"
  
/>
        
          {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>

  };
  export default GivenOrderOpenOrderList;
 