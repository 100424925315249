import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,DatePicker,Upload,Table, Modal, Spin} from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import StoregeService from "../../../services/storegeService";
import NotificationService from "../../../services/antNotificationService";
import  {parsCollections,requestedProductType,colors}  from "../../../environments/environment"
import { useLocation  } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import XLSX from 'sheetjs-style'; 
import PropTypes from 'prop-types';

const WantedOfferAdd = ({ record,onClose }) => {

  const navigate = useNavigate();
  const location = useLocation();
 // const record = location.state && location.state.record;
  const [excelFile, setExcelFile] = useState(null);
  const [mailList, setMailList] = useState([]);
 // const [supplierList, setSupplierList] = useState([]);

  let [futureRaws, setFutureRaws] = useState([]);
  const [qualities, setQualities] = useState([]); 
  const [visible, setVisible] = useState(false);
  const [customerDeadline, setCustomerDeadline] = useState(null);
  const [supplierName, setSupplierName] = useState(null);
  const [selectedSupplierName, setSelectedSupplierName] = useState(null);
  const [loading, setLoading] = useState(false);

  const getMailsData = async () => {
    const mailsCollection = await FirebaseService.getMailsData();
    const filteredMails = mailsCollection.filter(item => !item.isDeleted);
    const filteredMailsData = filteredMails.map(items => {
      return {
        email: items.email,
        companyName: items.companyName,
      };
    });
    return filteredMailsData;
  };

  const filterMailsBySupplier = (filteredMailsData, incomingExcelList) => {
    if (!incomingExcelList) {
      return filteredMailsData;
    }
    const supplierNames = incomingExcelList.map(item => item.supplierName);
    const updatedMailsData = filteredMailsData.filter(mail => !supplierNames.includes(mail.companyName));
    return updatedMailsData;
  };

  useEffect(() => {
    const fetchAndFilterMails = async () => {
      const filteredMailsData = await getMailsData();
      const updatedMailsData = filterMailsBySupplier(filteredMailsData, record.incomingExcelList);
     // console.log("Updated Mails Data:", updatedMailsData);
      setMailList(updatedMailsData);

    };


    fetchAndFilterMails();
  }, [record]);



  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      try {
        /*
        const mailsCollection = await FirebaseService.getMailsData();
        const filteredMails = mailsCollection.filter(item => !item.isDeleted);
        const filteredMailsData = filteredMails.map(items => {
         
          return {
            email: items.email,
            companyName: items.companyName,
          };
        });
        console.log("record",record.incomingExcelList)

        setMailList(filteredMailsData);*/

       // console.log("email yeni liste", mailsData)

       const qualities = await FirebaseService.getDensitiesData();
       const filteredQualities = qualities.filter(item => !item.isDeleted);
       setQualities(filteredQualities);

     //  const data = await FirebaseService.getSuppliersData();
     //  setSupplierList(data)

        
      } catch (error) {
        console.error('Error getting mails data:', error);
        throw error;
      }


    };
    
    fetchData();
    }, []);

    const onChange = (value) => {
      console.log(`selected ${value}`);
      setSelectedSupplierName(selectedSupplierName);
    };
    
    const onFinish = async (values) => {
      console.log("values",values)
     
      setSupplierName(values.supplierName);
      if (!excelFile) {
        throw new Error('Lütfen bir Excel dosyası seçiniz.');
      }
      try {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0]; 
          const worksheet = workbook.Sheets[sheetName];
          const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    
          const cellH6 = worksheet['H6'];
          const excelTimestamp = cellH6 ? cellH6.v : null;
          
          if (excelTimestamp) {
            const daysSinceExcelEpoch = excelTimestamp - 25569; // Excel'deki tarihin başlangıç noktası olan 01.01.1900'den bu yana geçen gün sayısı
            const milliseconds = daysSinceExcelEpoch * 86400 * 1000; // 1 gün = 86400 saniye
            const excelDate = new Date(milliseconds);
            
            console.log("Excel Tarih Değeri:", excelDate); // Bu değer Excel'den gelen tarihi JavaScript tarihine dönüştürmüş olacak
            setCustomerDeadline(excelDate);
          } else {
            console.log("Hücre değeri boş.");
            const today = new Date(); // Bugünün tarihini al
            setCustomerDeadline(today); // Boşsa bugünün tarihini kullan

          }
          



          const rows = [];
         
          for (let i = 9; i <= 34; i++) {
            if (excelData[i] && excelData[i].length > 0) { 
              rows.push(excelData[i]);
            }
          }
          
        

          if (rows.length > 0) {
            const mappedList = rows.map(item => {
              let density = 1;
              const selectedQuality = qualities.find(qualityItem => qualityItem.quality === item[2]);
              if (selectedQuality) {
                density = selectedQuality.density;
              }
          
              return {
               // jobCode: item[1], // 86878
                quality: item[2], // ST30 veya ST48 gibi
                surface: item[3], // simli veya başka bir yüzey
                thickness: Number(item[4]), // 6 gibi
                width: Number(item[5]), // 10 gibi
                height: Number(item[6]), // 78 gibi
                piece: Number(item[7]), // 4 gibi
                unitKilo: (Number(item[4]) * Number(item[5]) * Number(item[6]) * density) / 1000000,
                totalKilo: ((Number(item[4]) * Number(item[5]) * Number(item[6]) * density) / 1000000) * Number(item[7]),
                price: Number(item[8])
              };
            });
          
            console.log('Mapped Liste:', mappedList);
            console.log("record.checkedRequestedProductInfo", record);

            const hasZeroPrice = mappedList.some(item => item.price === 0);

            if (hasZeroPrice) {
              NotificationService.openErrorNotification({
                title: 'Hata',
                description: 'Listedeki bazı ürünlerin fiyatı girilmemiş. Lütfen fiyat giriniz.',
                placement: 'topRight',
              });
              return;
            }
            
            // Eşleşme kontrolü
            const hasMatchingItems = mappedList.every(item => {
              return record.checkedRequestedProductInfo.some(reqItem =>
                reqItem.quality === item.quality &&
                reqItem.width === item.width &&
                reqItem.height === item.height &&
                reqItem.thickness === item.thickness &&
                reqItem.surface === item.surface &&
                reqItem.piece === item.piece
              );
            });
            
            if (!hasMatchingItems) {
              NotificationService.openErrorNotification({
                title: 'Eşleşme Hatası',
                description: 'Teklif gönderilen sac levhalar ile yüklenen exceldeki sac levhalar eşleşmemektedir.',
                placement: 'topRight'
              });
            } else {
              // Eşleşme bulunduğunda listeyi güncelle
              const updatedList = mappedList.map(item => {
                console.log("item",item)
                const matchingItem = record.checkedRequestedProductInfo.find(reqItem => {
                  console.log("Comparing:", reqItem, item);
                  return reqItem.quality === item.quality &&
                         reqItem.width === item.width &&
                         reqItem.height === item.height &&
                         reqItem.thickness === item.thickness &&
                         reqItem.surface === item.surface &&
                         reqItem.piece === item.piece; // Ensure types match
                });
              
                console.log("matchingItem", matchingItem);
          

                const jobCodeValue = matchingItem && matchingItem.jobCode && matchingItem.jobCode !== "-" ? matchingItem.jobCode : null;
                const codeValue = matchingItem && matchingItem.code && matchingItem.code !== "-" ? matchingItem.code : null;
                const minPieceValue = matchingItem && matchingItem.minPiece && matchingItem.minPiece !== "-" ? matchingItem.minPiece : null;
                console.log("minPieceValue",minPieceValue)
                console.log("codeValue",codeValue)
                return { ...item, id: matchingItem.id, jobCode: jobCodeValue, description: record.description ?   record.description : null, code: codeValue, minPiece: minPieceValue , };
              });
            
              console.log('Updated List:', updatedList);
             setFutureRaws(updatedList);
              setVisible(true);
            }
            

          } else {
            console.log('Listeye eklenen satır bulunamadı.');
          }
          
          
          
          
        };
        fileReader.readAsArrayBuffer(excelFile);
      } catch (error) {
        console.error('Excel dosyası okunurken bir hata oluştu:', error);
      }
    

     
     
    };
      
    const onFinishFailed = (errorInfo) => {
        NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };

    const handleExcelFileChange = (info) => {
        if (info.file.status === 'done') {
            NotificationService.openSuccessNotification({
                title: 'İşlem Başarısız',
                description: "oldu.",
                placement: 'topRight'
            });

         // message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "Bilinmeyen bir hata ile karşılaşıldı.",
                placement: 'topRight'
            });
         // message.error(`${info.file.name} file upload failed.`);
        }
    };
      
    const handleOk = async () => {
      setLoading(true);

      try {
        if (!excelFile) {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Lütfen bir Excel dosyası seçiniz.",
            placement: 'topRight'
          });
          return;
        }
        console.log("futureRaws",futureRaws);
        console.log("selectedSupplierName",supplierName)

        futureRaws = futureRaws.map(item => {
          const companiesAndPrice = [{
            price: item.price,
            company: supplierName,
          }];
        
          return {
            ...item, 
            companiesAndPrice: companiesAndPrice 
          };
        });
        
        console.log("futureRaws after update:", futureRaws);
        
        const selectedDocument = excelFile;
        const fileName = `${record.offerJobCode}.xlsx`;
          
        const folderName = 'incomingOffers';
        const downloadURL = await StoregeService.uploadDocumentAndGetURL(folderName, selectedDocument, fileName);
          
        console.log('Document uploaded successfully. Download URL:', downloadURL);

        const result = await FirebaseService.updatePurchaseRequestIncomingExcellData(record.key,downloadURL, futureRaws, supplierName,customerDeadline);
        if (result) {
                console.log("update oldu")
                  NotificationService.openSuccessNotification({
                    title: 'İşlem Başarılı',
                    description: 'Başarıyla kaydedildi. Excel dosyası başarıyla yüklendi.',
                    placement: 'topRight'
                  });  
                 
                  setTimeout(() => {
                    onClose();
                    setVisible(false);
                    setLoading(false);
                    navigate('/wantedofferlist')
                  }, 500);   
                
        } else {
          console.log("update olmadı")
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });
          setLoading(false);

          return;
        }
                    
      }   
      catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: error.message || "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
        setLoading(false);

      }
    };
    

    const handleCancel = () => {
      setVisible(false);
  };


  const columns = [
    {
      title: 'Kalite',
      dataIndex: 'quality',
      key: 'quality',
      width: 200,
      sorter: (a, b) => a.quality.localeCompare(b.quality), 
      render: (text) => <span style={{ color: futureRaws.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
    },

      {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
        width: 200,
        sorter: (a, b) => a.thickness - b.thickness,
        render: (text) => <span style={{ color: futureRaws.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },

     
      {
        title: 'En',
        dataIndex: 'width',
        key: 'width',
        width: 200,
        sorter: (a, b) => a.width - b.width,
        render: (text) => <span style={{ color: futureRaws.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
      {
        title: 'Boy',
        dataIndex: 'height',
        key: 'height',
        width: 200,
        sorter: (a, b) => a.height - b.height,
        render: (text) => <span style={{ color: futureRaws.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },

      {
        title: 'Yüzey',
        dataIndex: 'surface',
        key: 'surface',
        width: 200,
        sorter: (a, b) => a.surface.localeCompare(b.surface), 
        render: (text) => <span style={{ color: futureRaws.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
 
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text,record) => <span style={{color: futureRaws.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        width: 200,
        sorter: (a, b) => a.piece - b.piece,
      },    
      
      {
        title: 'Fiyat',
        dataIndex: 'price',
        key: 'price',
        render: (text,record) => <span style={{color: futureRaws.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        width: 200,
        sorter: (a, b) => a.price - b.price,
      },         
    
    ];
  return  (
    <div>
    <Form
          name="basic"
          labelCol={{
          span: 6,
          }}
          wrapperCol={{
          span: 20,
          }}
          style={{
          maxWidth: "100%",
          padding:"5%"
          }}
          initialValues={{
          remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
      >

     
      <Form.Item
      label="Tedarikçi"
        name="supplierName"
        rules={[
          {
            required: true,
            message: 'Lütfen firma seçiniz!',
          },
        ]}
      >
        <Select
        showSearch
          onChange={onChange}
          placeholder="Firma Seçiniz"
    
          suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
          options={mailList.map(item => ({ label: item.companyName, value: item.companyName }))}
          className="input-style" 
          filterOption={(input, option) => 
            option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
          }
          />
      </Form.Item>
     
      <Form.Item
      label="Excel Dosyası"
        name="excel"
        rules={[
          {
            required: true,
            message: 'Lütfen excel dosyası yükleyiniz!',
          },
        ]}
      
        style={{
         
          textAlign: "left"
        }}
      >

          <Upload
            beforeUpload={(file) => {
              setExcelFile(file); 
              return false; 
            }}
            showUploadList={false} 
            onChange={handleExcelFileChange} 
          >
            <Button
              icon={<UploadOutlined />}
              className="input-style" 
              style={{width:"100%"}}
            >
              Excel Dosyası Yükle
            </Button>
          </Upload>
          {excelFile && <div style={{ marginTop: 8, width:"400px" }}>{excelFile.name}</div>}

      </Form.Item>

  
     
      <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Gönder</Button>
      </Form.Item>

    </Form>

    <div>           
          <Modal
                  title="Ürünler"
                  visible={visible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  okText="Evet, Onaylıyorum"
                  cancelText="Vazgeç"
                  className="columnsStockExcess popup-modal-button"
                
              >
              <div> 
                <p>Ürünleri ve fiyatları eklemek istediğinize emin misiniz? </p>
                <Table
                    dataSource={futureRaws}
                    locale={{
                      emptyText: 'Henüz eklemediniz...',
                      filterReset: 'Sıfırla',
                      filterTitle: 'Filtre Menüsü',
                      selectAll: 'Hepsini Seç',
                      selectInvert: 'Tersini Seç',
                      selectionAll: 'Tümünü Seç',
                      sortTitle: 'Sıralama',
                      triggerDesc: 'Azalan sıralama için tıklayın',
                      triggerAsc: 'Artan sıralama için tıklayın',
                      cancelSort: 'Sıralamayı iptal etmek için tıklayın',
                    }}
                    columns={columns}
                    showHeader={true}
                    pagination={false} 
                    className="custom-news-table"
                    rowClassName="custom-news-row"
                />
                 {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

            </div>
           </Modal>

        </div>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

  </div>
  );
 
};
WantedOfferAdd.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default WantedOfferAdd;


/*
 const navigate = useNavigate();
  const location = useLocation();
  const items = location.state && location.state.record;
  const [excelFile, setExcelFile] = useState(null);
  const [mailList, setMailList] = useState([]);

  useEffect(() => {

    console.log("items maillist",items.mailList);
    window.scrollTo(0, 0);

    const fetchData = async () => {
      try {
        const mailsCollection = await FirebaseService.getMailsData();
        const mailsData = mailsCollection.map(items => {
         
          return {
            email: items.email,
            companyName: items.companyName,
          };
        });
        setMailList(mailsData);
       console.log("email yeni liste", mailsData)
        
      } catch (error) {
        console.error('Error getting mails data:', error);
        throw error;
      }


    };



    
    fetchData();
    }, []);

    const onChange = (value) => {
    console.log(`selected ${value}`);
    };

    const onFinish = async (values) => {
        try {
          if (!excelFile) {
            throw new Error('Lütfen bir Excel dosyası seçiniz.');
          }

         const selectedDocument = excelFile;
         const fileName = `${items.offerJobCode}_${values.email}.xlsx`;
     
         const folderName = 'incomingOffers';
         const downloadURL = await StoregeService.uploadDocumentAndGetURL(folderName, selectedDocument, fileName);
     
         console.log('Document uploaded successfully. Download URL:', downloadURL);

       

       const result = await FirebaseService.updatePurchaseRequestIncomingExcellData(items.key,downloadURL,values.email);
       if (result) {
        console.log("update oldu")
          
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: 'Excel dosyası başarıyla yüklendi.',
            placement: 'topRight'
          });
      
          setTimeout(() => {
            navigate("/wantedofferlist");
          }, 2000);
          
       } else {
         console.log("update olmadı")
       }
   
   
        } catch (error) {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: error.message,
            placement: 'topRight'
          });
        }
      };
      
   
    const onFinishFailed = (errorInfo) => {
        NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };
    const handleExcelFileChange = (info) => {
        if (info.file.status === 'done') {
            NotificationService.openSuccessNotification({
                title: 'İşlem Başarısız',
                description: "oldu.",
                placement: 'topRight'
            });

         // message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "Bilinmeyen bir hata ile karşılaşıldı.",
                placement: 'topRight'
            });
         // message.error(`${info.file.name} file upload failed.`);
        }
    };
      
  return  (
    <div>
    <Form
          name="basic"
          labelCol={{
          span: 4,
          }}
          wrapperCol={{
          span: 20,
          }}
          style={{
          maxWidth: "100%",
          padding:"5% 0 0 5%"
          }}
          initialValues={{
          remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
      >

      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Lütfen şirket seçiniz!',
          },
        ]}
      >
        <Select
          onChange={onChange}
          placeholder="Şirket Seçiniz"
          style={{ borderRadius: 0, borderColor: "#1A446C80" }}
          suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
          options={mailList.map(item => ({ label: item.companyName +" " + item.email , value: item.email }))}
          />
      </Form.Item>

     
      <Form.Item
  name="excel"
  rules={[
    {
      required: true,
      message: 'Lütfen excel dosyası yükleyiniz!',
    },
  ]}
  wrapperCol={{
    span: 6,
  }}
  style={{
    maxWidth: "100%",
    textAlign: "left"
  }}
>
  <Upload
    beforeUpload={(file) => {
      setExcelFile(file); 
      return false; 
    }}
    showUploadList={false} 
    onChange={handleExcelFileChange} 
  >
    <Button
      icon={<UploadOutlined />}
      style={{ borderRadius: 0, borderColor: "#1A446C80" }}
    >
      Excel Dosyası Yükle
    </Button>
  </Upload>
      </Form.Item>

   
     
     
      <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Gönder</Button>
      </Form.Item>

    </Form>
  </div>
  );
 
*/
