import React,{useEffect,useState} from "react";
import { Button, Form, Input,Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import OneSignalService from "../../../services/oneSignalService";
import  { parsCollections,colors}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

 
const AddRequestAdminDensities = ({ onClose }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
     
      };
      fetchData();
    }, []);

    const getOneSignalPlayerIds = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userType.includes("headAdmin"));
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.oneSignalPlayerId) {
            oneSignalPlayerIds.push(user.oneSignalPlayerId);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };
    const getOneSignalUserIds = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userType.includes("headAdmin"));
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          console.log("user",user)
          if (user.key) {
           
            oneSignalPlayerIds.push(user.key);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };


    const onFinish = async (values) => {
      setLoading(true)
        const currentDate =new Date()
        const data = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          firstUserName: localStorage.getItem("name"), 
          firstUserSurname: localStorage.getItem("surname"),
          isDeleted: false,
          quality: values.quality,
          tableName: parsCollections.densities,
          description: values.description ? values.description : null
        };
        
      try {
        const result = await FirebaseService.addRecording(parsCollections.addRequestsAdmin,data);
        if (result.success) {
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: result.message,
            placement: 'topRight'
          });setLoading(false)
            //bildirim 
            const ids = await getOneSignalPlayerIds();
            const userIds = await getOneSignalUserIds();

            const sentUserIdsMap = new Map();
            userIds.forEach(userId => {
              sentUserIdsMap.set(userId, false);
            });
            
            const sentUserIds = Object.fromEntries(sentUserIdsMap);
            //console.log("sentUserIds mapppp", sentUserIds);

            if (ids.length > 0) {
              const oneSignalResult = await OneSignalService.sendNotification("", "Talep Eklendi", ids);
              console.log("OneSignal notification result:", oneSignalResult);
              const data = {
                firstTransactionDate: currentDate,
                firstUserId: localStorage.getItem("uid"),
                isDeleted: false,
                sentUserIds:sentUserIds,
                firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
                unitName:localStorage.getItem('userUnit'),
                title:"Talep Eklendi",
                companyName:null,
                jobCode:null,
                projectCode:null,
                machine:null,
                type:"Kalite Ekleme Talebi",
                notice:"Yeni bir kalite ekleme talebi gelmiştir.",
                description:values.description ? values.description : null
  
              };
              const result = await FirebaseService.addRecording(parsCollections.notifications,data);
              console.log("result",result)
            } else {
              console.log("No OneSignal Player IDs found.");
            }
          onClose();
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });setLoading(false)}
        
        
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });setLoading(false)
      }
    }; 

    const onFinishFailed = (errorInfo) => {
        NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
      };
    
   

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

        

            <Form.Item
               label="Kalite"
            name="quality"
            rules={[
                {
                required: true,
                message: 'Lütfen kalite giriniz!',
                },
            ]}
            >
           <Input className="input-style" style={{width:"100%"}}  placeholder="Kalite"/>
            </Form.Item>

            <Form.Item
               label="Açıklama"
            name="description"
            rules={[
                {
                required: true,
                message: 'Lütfen açıklama giriniz!',
                },
            ]}
            >
           <Input className="input-style" style={{width:"100%"}}  placeholder="Açıklama Giriniz (zorunludur) "/>
            </Form.Item>
       <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>)

};

AddRequestAdminDensities.propTypes = {
    onClose: PropTypes.func.isRequired, 
  };
  
export default AddRequestAdminDensities;