import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input, Form, DatePicker, Select,Spin} from 'antd';
import { useNavigate } from 'react-router-dom';
import  { colors, currency, parsCollections,emailJs}  from "../../../../environments/environment"
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import StoregeService from "../../../../services/storegeService";
import NotificationService from "../../../../services/antNotificationService";
import XLSX from 'sheetjs-style'; 
import emailjs from '@emailjs/browser';
import { PDFDocument } from 'pdf-lib';
import { saveAs } from 'file-saver';
import ExportReportCardTemplate from "../Pars_Satis_Son.xlsx";
import moment from 'moment';

const ThicknessPriceOrderCustomerPage = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const itemsx= location.state && location.state.items;
  const record= location.state && location.state.record;
  const hasCompleteRequests = location.state && location.state.hasCompleteRequests;

  const [items, setItems] = useState([]);
 
  const [showPriceInput, setShowPriceInput] = useState(false); 
  const [showDiscount, setShowDiscount] = useState(true); 

  const [currencyRate, setCurrencyRate] = useState(null); 
  const [discount, setDiscount] = useState(0); 

  const [selectedCurrency, setSelectedCurrency] = useState("TL"); 
  const [selectedCurrencyTemproray, setSelectedCurrencyTemporary] = useState("TL"); 

  const [isCurrencyChanged, setIsCurrencyChanged] = useState(false); 
  
  const [allTotalPrice, setAllTotalPrice] = useState(0);
  const [allTotalPriceKdv, setAllTotalPriceKdv] = useState(allTotalPrice);
  const [totalItem, setTotalItem] = useState(null);
  const [kdvRate, setKdvRate] = useState(20);
  const [companyInfo, setCompanyInfo] = useState(null);
  const today = moment().startOf('day'); 
  const [loading,setLoading] = useState(false)

  

 // console.log("record",record)
 // console.log("itemsx",itemsx)

  const serviceId = emailJs.serviceId;
  //const templateId = emailJs.templateIdPdf;
  const templateId = emailJs.templateIdSatisTeklif;

  const publicKey = emailJs.publicKey;

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const groupedParts = hasCompleteRequests.reduce((acc, request) => {
        request.parts.forEach(part => {
          const key = part.partCode;
          const processName = request.processName; 
      
          if (!acc[key]) {
            acc[key] = {
              processData: {}, 
            };
          }
          if (!acc[key].processData[processName]) {
            acc[key].processData[processName] = {
              totalProfit: 0,
              totalUnitPrice: 0,
            };
          }
  
          acc[key].processData[processName].totalProfit = parseFloat(part.profit);
          acc[key].processData[processName].totalUnitPrice = parseFloat(part.unitPrice);
        });
        return acc;
      }, {});
      console.log("Grouped Parts:", groupedParts);
    
        const updatedItems = itemsx.map(item => {
          const partData = groupedParts[item.partNo]; 

          const unitPrice = Number(calculateUnitPrice(item,partData));
          const totalPrice = unitPrice * item.piece;
      
          return {
            ...item,
            unitPrice: unitPrice,
            totalPrice: totalPrice, 
          };
        });
      
       console.log("updatedItems", updatedItems);
        setItems(updatedItems);

        const allTotalPrice = updatedItems.reduce((acc, item) => acc + item.totalPrice, 0)+ record.deliveryPrice;
       // console.log("allTotalPrice", allTotalPrice);
        setAllTotalPrice(allTotalPrice.toFixed(2))

      

        const groupedItems = groupItemsByThickness(updatedItems);

        console.log("Grouped Items by Thickness", groupedItems);
        setTotalItem(groupedItems);

        const newTotalPrice = allTotalPrice  * (1 + kdvRate/100);
        setAllTotalPriceKdv(newTotalPrice.toFixed(2));



        const allCompanies = await FirebaseService.getCompaniesData();
        const companyInfo = allCompanies.find((entry) => entry.companyName.toLowerCase() === record.companyName.toLowerCase());
        setCompanyInfo(companyInfo);
        
      };
      
    fetchData();
  }, []);

  const disabledDate = (current) => {
    // Bugünden önceki tarihleri devre dışı bırak
    return current && current < today;}

  const groupItemsByThickness = (items) => {
    const groupedItems = {};
  
    items.forEach(item => {
      const thickness = item.thickness;
      const quality = item.quality;

      if (!groupedItems[thickness]) {
        groupedItems[thickness] = {
          thickness: thickness,
          unitPrice: 0,
          totalPrice: 0,
          items: [],
          teamCount: 1,
          quality: quality,

        };
      }
  
      groupedItems[thickness].unitPrice += item.unitPrice;
      groupedItems[thickness].totalPrice += item.totalPrice;

      groupedItems[thickness].items.push(item);
    });
  
    // Convert the grouped items object into an array
    return Object.values(groupedItems);
  };

  
  const calculateUnitPrice = (item,partData) => {
    console.log("calculta unit price item",item)
    
    const priceMachine = item.priceMachine || 0;
    const pricePlazma = item.pricePlazma || 0;
    const priceQuality = item.priceQuality || 0;
    const pricesAbkant = item.pricesAbkant || 0;
    console.log("partData",partData)
    let totalUnitPriceProcess = 0;
    let totalProfitPriceProcess = 0;
    let totalOperationsPrice=0;
   
    if (partData && partData.processData) {
      for (const processName in partData.processData) {
        totalUnitPriceProcess = parseFloat(partData.processData[processName].totalUnitPrice) || 0;
        console.log("totalUnitPriceProcess",totalUnitPriceProcess)

        totalProfitPriceProcess = parseFloat(partData.processData[processName].totalProfit) || 0;
        console.log("totalProfitPriceProcess",totalProfitPriceProcess)

        totalOperationsPrice += totalProfitPriceProcess +totalUnitPriceProcess;
        console.log("totalOperationsPrice",totalOperationsPrice)
      }
    }
    console.log(" son totalOperationsPrice",totalOperationsPrice)
    const totalUnitPrice = parseFloat(priceMachine + totalOperationsPrice + pricePlazma + priceQuality + pricesAbkant);
    return totalUnitPrice.toFixed(2);
  };

  
  const columns = [
    {
      title: 'No',
      dataIndex: 'partNo',
      key: 'partNo',
      render: (text, record, index) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{index + 1}</span>,
    },
    {
      title: 'Kalınlık/Kalite',
      dataIndex: '',
      key: '',    
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text.thickness} / {text.quality}
      </span>,
    },
    {
      title: 'Takım Adedi',
      dataIndex: 'teamCount',
      key: 'teamCount',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text} </span>,
    }, 
  
    
      {
        title: 'Birim Fiyat',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> 
            {text && text.toFixed(2)} {selectedCurrency}
         </span>,
      }, 
    
      
      {
        title: 'Takım Fiyatı',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text && text.toFixed(2)} {selectedCurrency}
          </span>
        ),
      },
   
      
 
      
  ];

  const handleKdvChange = (e) => {
    const { value } = e.target;
    const updatedKdvRate = value ? value : 0;
    setKdvRate(updatedKdvRate); // KDV oranını güncelleyin

    if (updatedKdvRate >= 0) {
        const newTotalPriceWithKdv = allTotalPrice * (1 + updatedKdvRate / 100);
        setAllTotalPriceKdv(newTotalPriceWithKdv.toFixed(2));
    } else {
        setAllTotalPriceKdv(allTotalPrice.toFixed(2));
    }
  };

  const handleDiscountChange = (e) => {
    const { value } = e.target;
    setDiscount(value);
  };

  const handleCurrentRateChange = (e) => {
    const { value } = e.target;
    setCurrencyRate(value);
  };

  const handleCurrency = () => {
    if (currencyRate && currencyRate !== 0) {
      const updatedItems = items.map(item => ({
        ...item,
        unitPrice: item.unitPrice / currencyRate,
        totalPrice: item.totalPrice / currencyRate,
      }));
  
      const newAllTotalPrice =  totalItem[0].totalPrice / currencyRate
      setAllTotalPrice(newAllTotalPrice);

      console.log("updatedItems", updatedItems);

      setItems(updatedItems);
      
      const updatedTotalItems = totalItem.map(item => ({
        ...item,
        unitPrice: item.unitPrice / currencyRate,
        totalPrice: item.totalPrice / currencyRate,
      }));

      setTotalItem(updatedTotalItems);
     
      const newTotalPriceKdv = newAllTotalPrice  * (1 + kdvRate/100);
      setAllTotalPriceKdv(newTotalPriceKdv.toFixed(2));
    

      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: "Girdiğiniz fiyat başarıyla uygulandı",
        placement: 'topRight'
      });
      setShowPriceInput(false);
      setIsCurrencyChanged(true);
      setSelectedCurrency(selectedCurrencyTemproray);

    }
    
  };

  const handleCurrencyChange = (value) => {
    setShowPriceInput(value === 'EUR' || value === 'USD');
    setSelectedCurrencyTemporary(value);
  };

  const handleDiscount = () => {
    if (discount && discount !== 0) {
      const discountRate = 1 - (discount / 100); 
      const newTotalPrice = totalItem[0].totalPrice * discountRate;
      setAllTotalPrice(newTotalPrice);

      // İskonto uygulandıktan sonra KDV'yi yeniden hesapla
      const newTotalPriceWithKdv = newTotalPrice * (1 + kdvRate / 100);
      setAllTotalPriceKdv(newTotalPriceWithKdv.toFixed(2));

      NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: 'İskonto başarıyla uygulandı ve KDV yeniden hesaplandı',
          placement: 'topRight'
      });

      setShowDiscount(false);
  }
  };
  
  const onFinish = async (values) => {
    //console.log("values burda",values)


     //mail atılcakkkkk
     await goMail();


    
    const currentDate = new Date();

    const data = {
      firstTransactionDate: currentDate,
      firstUserId: localStorage.getItem("uid"),
      firstUserName: localStorage.getItem("name"),
      firstUserSurname: localStorage.getItem("surname"),
      isDeleted: false,
      kdvPercent: values.kdvPercent ? Number(values.kdvPercent) : 0 ,
      currency: values.currency,
      discount:values.discount  ? Number(values.discount) : 0 ,
      deadline: new Date(values.deadline),
      jobCode: record.jobCode,
      projectCode:record.projectCode,
      paymentMethod: record.paymentMethod,
      abkantSelectedOperation:record.abkantSelectedOperation,
      companyName:record.companyName,
      pricesAbkant:record.pricesAbkant,
      pricesPlazma:record.pricesPlazma,
      pricesQuality:record.pricesQuality,
      pricesTime:record.pricesTime,
      programmerName:record.programmerName,
      quantitiesAbkant:record.quantitiesAbkant,
      setupTime:record.setupTime,
      setupTimeChange:record.setupTimeChange ?  record.setupTimeChange : record.setupTime ,
      totalKg:record.totalKg,
      totalKgs:record.totalKgs,
      totalPricesAbkant:record.totalPricesAbkant,
      totalPricesPlazma:record.totalPricesPlazma,
      totalPricesQuality:record.totalPricesQuality,
      totalPricesTime:record.totalPricesTime,
      items:items,
      allTotalPrice:  values.kdvPercent  ? allTotalPriceKdv : totalItem[0].totalPrice ,
     type:"order",
     deliveryPrice:record.deliveryPrice,
     deliveryMethod:record.deliveryMethod,
     unit:record.unit ? record.unit : "Kg"

     
    };

    console.log("Data", data);
   
    const result = await FirebaseService.addRecording(parsCollections.invoiceOpenOrder, data);
    if (result.success) {
      console.log("İşlem Başarılı:", result.message);
      
      NotificationService.openSuccessNotification({
        title: 'Tüm İşlemler Başarılı',
        description: "Başarıyla gönderilmiştir.",
        placement: 'topRight',
      });
      setTimeout(() => {
        navigate("/salesmanpage");
      }, 2000);

      

    } else {
      NotificationService.openErrorNotification({
        title: 'Bazı İşlemler Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight',
      });
      return;
    }

    
  
  }

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      fetch(file)
        .then((response) => response.blob())
        .then((file) => reader.readAsBinaryString(file));
    });
  };

  const goMail = async () => {
    setLoading(true)
    const mailTo = companyInfo.email;
    console.log("mailtoooo", mailTo);
  
    const binaryString = await readFile(ExportReportCardTemplate);
    const workbook = XLSX.read(binaryString, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];


    const formatDate = (date) => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
  };

  let formattedDeadlineString = "N/A";
  if (record.deadline && record.deadline.length > 0) {
      const deadlineTimestamp = record.deadline[0];
      const formattedDeadline = new Date(deadlineTimestamp.seconds * 1000);
      if (!isNaN(formattedDeadline.getTime())) {
          formattedDeadlineString = formatDate(formattedDeadline);
      }
  }

    worksheet['I3'] = { t: 's', v: record.jobCode }; //proje kodu
    worksheet['I4'] = { t: 's', v: formattedDeadlineString};  //termin tarihi
    
    worksheet['H9'] = { t: 's', v: record.companyName};  //firma adı
    worksheet['H11'] = { t: 's', v: record.paymentMethod};  //ödeme vaadesi 
    worksheet['H12'] = { t: 's', v: record.deliveryMethod};  //teslim şekli
    worksheet['H13'] = { t: 's', v: selectedCurrency};  //para birimi
    worksheet['H14'] = { t: 's', v: companyInfo.vkn};  //vergi numarası
    worksheet['H15'] = { t: 's', v: companyInfo.address};  //adres

    
    worksheet['A27'] = { t: 's', v: record.description};  //önemli notlar
   
    const discountRate = 1 - (discount / 100); 
   // const newTotalPrice =  totalItem[0].totalPrice * discountRate;

   let sumTotalPrice = 0;

   for (let i = 0; i < totalItem.length; i++) {
     console.log(`Adding totalPrice from item ${i}:`, totalItem[i].totalPrice);
     sumTotalPrice += totalItem[i].totalPrice;
   }

   const newTotalPrice = sumTotalPrice;
     
    worksheet['J21'] = { t: 's', v: parseFloat(newTotalPrice).toFixed(2)};  //tutar
    worksheet['J22'] = { t: 's', v: discount};  //iskonto
    worksheet['J23'] = { t: 's', v: parseFloat(allTotalPrice).toFixed(2)};  //toplam tutar
    worksheet['J24'] = { t: 's', v: kdvRate};  //kdv
    worksheet['J25'] = { t: 's', v: parseFloat(allTotalPriceKdv).toFixed(2)};  //genel toplam

    worksheet['F50'] = { t: 's', v: record.companyName};  //firma adı
/*
    worksheet['A17'] = { t: 's', v: record.projectCode};  //proje kodu
    worksheet['G17'] = { t: 's', v: record.teamCount};  //miktar 
    worksheet['H17'] = { t: 's', v: record.unit};  //birim 
    worksheet['I17'] = { t: 's', v: (newTotalPrice / record.teamCount).toFixed(2)};  //birim fiyat
    worksheet['J17'] = { t: 's', v: parseFloat(newTotalPrice).toFixed(2)};  //toplam fiyat
*/
    const startRow = 18;
      
    totalItem.forEach((item, index) => {
    const row = startRow + index; 
    worksheet[`A${row}`] = { t: 's', v: record.projectCode };  // partNo (proje kodu olarak kabul edelim)
    worksheet[`G${row}`] = { t: 's', v: item.teamCount };  // miktar 
    worksheet[`H${row}`] = { t: 's', v: record.unit };  // birim 
    worksheet[`I${row}`] = { t: 's', v: parseFloat(item.totalPrice).toFixed(2) };  // birim fiyat
    worksheet[`J${row}`] = { t: 's', v: parseFloat(item.totalPrice).toFixed(2) };  // toplam fiyat
    });

    

    //tasarım
    worksheet['A2'].s = {
      font: {
          name: 'Helvatica',
          sz: 16,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"center"
      },
      fill: {
        fgColor: { rgb: "D3D3D3" } 
      }
    }
    worksheet['G3'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['G4'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A9'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
    
      alignment : {
        horizontal :"left",
        vertical: "top" ,
        wrapText: true
      },
    }

    worksheet['A11'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['A12'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A13'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A14'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A15'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['F9'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
          horizontal :"left",
          vertical: "top" ,
          wrapText: true
        },
    }
    worksheet['F11'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['F12'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['F13'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['F14'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['F15'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
   
    worksheet['A27'].s = {
      font: {
          name: 'Helvatica',
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A35'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"center"
      },
    }

    worksheet['C15'].s = {
      font: {
          name: 'Helvetica',
          sz: 10,
       
          color: { rgb: "000000" }  // Use rgb format for color
      },
      alignment: {
          horizontal: "left",
          vertical: "center",
          wrapText: true  
      }
    };
    worksheet['H15'].s = {
      font: {
          name: 'Helvetica',
          sz: 10,
          color: { rgb: "000000" }
      },
      alignment: {
          horizontal: "left",
          vertical: "center",
          wrapText: true  // Enables text wrapping within the cell
      }
  };
  
  

  
    worksheet['A50'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['F50'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"center"
      },
    }

    worksheet['H21'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['H21'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['H22'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['H23'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['H24'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['H25'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    

    worksheet['C9'].s = {
      alignment : {
        horizontal :"left",
        vertical: "top" ,
        wrapText: true
      },
    }
    worksheet['H9'].s = {
      alignment : {
        horizontal :"left",
        vertical: "top" ,
        wrapText: true
      },
    }
    worksheet['A27'].s = {
        font: {
            name: 'Helvatica',
            bold: true,
            color: "#000000",
            underline: true
        },
        alignment : {
          horizontal :"left"
        },
      }
  

    worksheet['A17'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['D17'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['G17'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['H17'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['I17'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['J17'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['C15'].s = {
      font: {
          name: 'Helvetica',
          sz: 10,
         
          color: { rgb: "000000" }  // Use rgb format for color
      },
      alignment: {
          horizontal: "left",
          vertical: "center",
          wrapText: true  
      }
    };
    worksheet['H15'].s = {
      font: {
          name: 'Helvetica',
          sz: 10,
          color: { rgb: "000000" }
      },
      alignment: {
          horizontal: "left",
          vertical: "center",
          wrapText: true  // Enables text wrapping within the cell
      }
  };
  
    
  
    worksheet['A50'].s = {
      alignment : {
        horizontal :"center"
      },
    }
  
    worksheet['A51'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['F51'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['A36'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['A37'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['A38'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['A39'].s = {
      alignment : {
        horizontal :"center"
      },
    }
   
    worksheet['F36'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['F37'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['F38'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['F39'].s = {
      alignment : {
        horizontal :"center"
      },
    }


    const updatedBinaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(updatedBinaryString)], { type: 'application/octet-stream' });


    let base64File;
    const reader = new FileReader();
  
    reader.onload = function (event) {
      base64File = event.target.result.split(',')[1];
  /*
      const templateParams = {
        user_email: mailTo,
        from_name: 'Pars Makine',
        message: 'webden gidiyor',
        file: base64File,
        projectCode : record.projectCode
      };
  
  
      // EmailJS ile e-posta gönderme
 /*     emailjs.send(serviceId, templateId, templateParams, publicKey)
        .then(
          (response) => {
            console.log('Email sent successfully:', response);
            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: "Mail başarıyla gönderildi",
              placement: 'topRight'
            });
            setLoading(false)
          },
          (error) => {
            console.error('Failed to send email:', error);
          }
        );
*/
        
    };
  
    reader.onloadend = function () {
      console.log("Dosya yükleme işlemi tamamlandı.");
  };

  reader.readAsDataURL(blob);

  saveAs(blob, `Satis_Teklif_Sozlesmesi_${record.jobCode}.xlsx`);

  const folderName = 'openOrderOffers';
  const fileName = `Satis_Teklif_Sozlesmesi_${record.jobCode}.xlsx`;
  const sentExcelDocumentUrl = await StoregeService.uploadDocumentAndGetURL(folderName, blob, fileName);
 
  console.log('Belge başarıyla yüklendi. İndirme URL\'si:', sentExcelDocumentUrl);
    
  };
  
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ kdvPercent: 20 });
  }, [form]);

  const downloadExcel = async () => {
   
    const binaryString = await readFile(ExportReportCardTemplate);
    const workbook = XLSX.read(binaryString, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];


    const formatDate = (date) => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
  };

  let formattedDeadlineString = "N/A";
  if (record.deadline && record.deadline.length > 0) {
      const deadlineTimestamp = record.deadline[0];
      const formattedDeadline = new Date(deadlineTimestamp.seconds * 1000);
      if (!isNaN(formattedDeadline.getTime())) {
          formattedDeadlineString = formatDate(formattedDeadline);
      }
  }

    worksheet['I3'] = { t: 's', v: record.jobCode }; //proje kodu
    worksheet['I4'] = { t: 's', v: formattedDeadlineString};  //termin tarihi
    
    worksheet['H9'] = { t: 's', v: record.companyName};  //firma adı
    worksheet['H11'] = { t: 's', v: record.paymentMethod};  //ödeme vaadesi 
    worksheet['H12'] = { t: 's', v: record.deliveryMethod};  //teslim şekli
    worksheet['H13'] = { t: 's', v: selectedCurrency};  //para birimi
    worksheet['H14'] = { t: 's', v: companyInfo.vkn};  //vergi numarası
    worksheet['H15'] = { t: 's', v: companyInfo.address};  //adres

    
    worksheet['A27'] = { t: 's', v: record.description};  //önemli notlar
   
    const discountRate = 1 - (discount / 100); 
  //  const newTotalPrice =  totalItem[0].totalPrice * discountRate;
    let sumTotalPrice = 0;

    for (let i = 0; i < totalItem.length; i++) {
      console.log(`Adding totalPrice from item ${i}:`, totalItem[i].totalPrice);
      sumTotalPrice += totalItem[i].totalPrice;
    }

    const newTotalPrice = sumTotalPrice;
      

    worksheet['J21'] = { t: 's', v: parseFloat(newTotalPrice).toFixed(2)};  //tutar
    worksheet['J22'] = { t: 's', v: discount};  //iskonto
    worksheet['J23'] = { t: 's', v: parseFloat(allTotalPrice).toFixed(2)};  //toplam tutar
    worksheet['J24'] = { t: 's', v: kdvRate};  //kdv
    worksheet['J25'] = { t: 's', v: parseFloat(allTotalPriceKdv).toFixed(2)};  //genel toplam

    worksheet['F50'] = { t: 's', v: record.companyName};  //firma adı
/*
    worksheet['A17'] = { t: 's', v: record.projectCode};  //proje kodu
    worksheet['G17'] = { t: 's', v: record.teamCount};  //miktar 
    worksheet['H17'] = { t: 's', v: record.unit};  //birim 
    worksheet['I17'] = { t: 's', v: (newTotalPrice / record.teamCount).toFixed(2)};  //birim fiyat
    worksheet['J17'] = { t: 's', v: parseFloat(newTotalPrice).toFixed(2)};  //toplam fiyat
*/
    
    const startRow = 18;
      
    totalItem.forEach((item, index) => {
    const row = startRow + index; 
    worksheet[`A${row}`] = { t: 's', v: record.projectCode };  // partNo (proje kodu olarak kabul edelim)
    worksheet[`G${row}`] = { t: 's', v: item.teamCount };  // miktar 
    worksheet[`H${row}`] = { t: 's', v: record.unit };  // birim 
    worksheet[`I${row}`] = { t: 's', v: parseFloat(item.totalPrice).toFixed(2) };  // birim fiyat
    worksheet[`J${row}`] = { t: 's', v: parseFloat(item.totalPrice).toFixed(2) };  // toplam fiyat
    });


      //tasarım
      worksheet['A2'].s = {
        font: {
            name: 'Helvatica',
            sz: 16,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"center"
        },
        fill: {
          fgColor: { rgb: "D3D3D3" } 
        }
      }
      worksheet['G3'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
      worksheet['G4'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
  
      worksheet['A9'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left",
          vertical: "top" ,
          wrapText: true
        },
      }
  
      worksheet['A11'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
      worksheet['A12'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
  
      worksheet['A13'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
  
      worksheet['A14'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
  
      worksheet['A15'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
      worksheet['F9'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left",
          vertical: "top" ,
          wrapText: true
        },
      }
      worksheet['F11'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
      worksheet['F12'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
      worksheet['F13'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
      worksheet['F14'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
  
      worksheet['F15'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
  
   
      worksheet['A35'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000",
            underline: true
        },
        alignment : {
          horizontal :"center"
        },
      }
  
      
      worksheet['C15'].s = {
        font: {
            name: 'Helvetica',
            sz: 10,
            
            color: { rgb: "000000" }  // Use rgb format for color
        },
        alignment: {
            horizontal: "left",
            vertical: "center",
            wrapText: true  
        }
      };
      worksheet['H15'].s = {
        font: {
            name: 'Helvetica',
            sz: 10,
            color: { rgb: "000000" }
        },
        alignment: {
            horizontal: "left",
            vertical: "center",
            wrapText: true  // Enables text wrapping within the cell
        }
    };
    
    
  
      worksheet['A50'].s = {
        font: {
            name: 'Helvatica',
            sz: 10,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
  
      worksheet['F50'].s = {
        font: {
            name: 'Helvatica',
            sz: 10,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"center"
        },
      }
  
      worksheet['H21'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
      worksheet['H21'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
      worksheet['H22'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
      worksheet['H23'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
  
      worksheet['H24'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
      worksheet['H25'].s = {
        font: {
            name: 'Helvatica',
            sz: 12,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"left"
        },
      }
  
      worksheet['C9'].s = {
        alignment : {
          horizontal :"left",
          vertical: "top" ,
          wrapText: true
        },
      }
      worksheet['H9'].s = {
        alignment : {
          horizontal :"left",
          vertical: "top" ,
          wrapText: true
        },
      }
      worksheet['A27'].s = {
        font: {
            name: 'Helvatica',
            bold: true,
            color: "#000000",
            underline: true
        },
        alignment : {
          horizontal :"left"
        },
      }
   
  
      worksheet['A17'].s = {
        font: {
            name: 'Helvatica',
            sz: 10,
            bold: true,
            color: "#000000",
            underline: true
        },
        alignment : {
          horizontal :"left"
        },
      }
  
      worksheet['D17'].s = {
        font: {
            name: 'Helvatica',
            sz: 10,
            bold: true,
            color: "#000000",
            underline: true
        },
        alignment : {
          horizontal :"left"
        },
      }
      worksheet['G17'].s = {
        font: {
            name: 'Helvatica',
            sz: 10,
            bold: true,
            color: "#000000",
            underline: true
        },
        alignment : {
          horizontal :"left"
        },
      }
      worksheet['H17'].s = {
        font: {
            name: 'Helvatica',
            sz: 10,
            bold: true,
            color: "#000000",
            underline: true
        },
        alignment : {
          horizontal :"left"
        },
      }
      worksheet['I17'].s = {
        font: {
            name: 'Helvatica',
            sz: 10,
            bold: true,
            color: "#000000",
            underline: true
        },
        alignment : {
          horizontal :"left"
        },
      }
      worksheet['J17'].s = {
        font: {
            name: 'Helvatica',
            sz: 10,
            bold: true,
            color: "#000000",
            underline: true
        },
        alignment : {
          horizontal :"left"
        },
      }
      worksheet['C15'].s = {
        font: {
            name: 'Helvetica',
            sz: 10,
           
            color: { rgb: "000000" }  // Use rgb format for color
        },
        alignment: {
            horizontal: "left",
            vertical: "center",
            wrapText: true  
        }
      };
      worksheet['H15'].s = {
        font: {
            name: 'Helvetica',
            sz: 10,
            color: { rgb: "000000" }
        },
        alignment: {
            horizontal: "left",
            vertical: "center",
            wrapText: true  // Enables text wrapping within the cell
        }
    };
    
  
    
      worksheet['A50'].s = {
        alignment : {
          horizontal :"center"
        },
      }

      worksheet['A51'].s = {
        alignment : {
          horizontal :"center"
        },
      }
      worksheet['F51'].s = {
        alignment : {
          horizontal :"center"
        },
      }
      worksheet['A36'].s = {
        alignment : {
          horizontal :"center"
        },
      }
      worksheet['A37'].s = {
        alignment : {
          horizontal :"center"
        },
      }
      worksheet['A38'].s = {
        alignment : {
          horizontal :"center"
        },
      }
      worksheet['A39'].s = {
        alignment : {
          horizontal :"center"
        },
      }
      worksheet['F36'].s = {
        alignment : {
          horizontal :"center"
        },
      }
      worksheet['F37'].s = {
        alignment : {
          horizontal :"center"
        },
      }
      worksheet['F38'].s = {
        alignment : {
          horizontal :"center"
        },
      }
      worksheet['F39'].s = {
        alignment : {
          horizontal :"center"
        },
      }
  


    const updatedBinaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(updatedBinaryString)], { type: 'application/octet-stream' });


    let base64File;
    const reader = new FileReader();
  
    reader.onload = function (event) {
      base64File = event.target.result.split(',')[1];
  


        
    };
  
    reader.onloadend = function () {
      console.log("Dosya yükleme işlemi tamamlandı.");
  };

  reader.readAsDataURL(blob);

  saveAs(blob, `Satis_Teklif_Sozlesmesi_${record.jobCode}.xlsx`);

      };

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>

        <Form
        form={form}
            name="basic"
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
        >

        <h3 style={{textAlign:"left", color:colors.blue}}>Kalınlık Fiyat Müşteri Dökümanı</h3>

        <div style={{display:"flex", justifyContent: "space-between"}}>
          <div style={{ textAlign: "left", }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}> İskonto </p>
          <Form.Item name="discount" 
          
          onChange={handleDiscountChange} 
          
          >
             <Input style={{width:"100px" }} placeholder="%"  className="input-style"     />
           </Form.Item>  
           
           <Button 
              onClick={handleDiscount} 
              disabled={showDiscount ? false: true }
              style={{float:"right",color:"white" ,fontWeight:"500",  
              backgroundColor: showDiscount ? colors.blue  :  "gray" 
               }} 
               >
               Uygula
             </Button>
          </div>

          <div style={{ textAlign: "left",  }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Ödeme Şekli</p>
            {record && record.paymentMethod}
          </div>

          <div style={{ textAlign: "left",  }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}> Para Birimi </p>
            <Form.Item
            name="currency"
            rules={[
              {
                required: true,
                message: 'Lütfen seçiniz!',
              },
            ]}
          >
            <Select
            showSearch
              onChange={isCurrencyChanged ?   null : handleCurrencyChange}
              placeholder="Para Birimi Seçiniz"
              style={{ width:"200px" }}
              suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
              options={currency.map(item => ({ label: item.label, value: item.value }))}
              className="input-style"     
              filterOption={(input, option) => 
                option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
              }
            />
              </Form.Item>

          </div>

          <div style={{ textAlign: "left",}}>
          {showPriceInput && (
             <div> 
            <p style={{ fontWeight: "bold", color: colors.blue }}>Para Birimi için Fiyat </p>

            <Form.Item
              name="currencyRate"
              rules={[
                {
                  required: true,
                  message: 'Lütfen fiyatı giriniz!',
                },
              ]}
              onChange={handleCurrentRateChange} 
            >
              <Input type="number" placeholder="Fiyatı Giriniz" style={{ width: '200px' }}   className="input-style"    />
            </Form.Item>

            <Button 
              onClick={handleCurrency} 
              style={{float:"right",color:"white" ,fontWeight:"500",  backgroundColor: colors.blue }} >
               Uygula
             </Button>
            </div>
          )}
          </div>
        
        </div>
        
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Firma</p>
          {record && record.companyName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
          {record && record.jobCode}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Proje Kodu</p>
          {record && record.projectCode}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Teklif Veren</p>
          {localStorage.getItem('name') + " " + localStorage.getItem('surname')}
        </div>


     
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
        <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
        <Form.Item 
        name="deadline"
         rules={[
          {
            required: true,
            message: 'Lütfen tarih giriniz!',
          },
        ]} >
            <DatePicker style={{ width: '100%' }} placeholder="Teslim Tarihi" format="DD-MM-YYYY"  className="input-style"  disabledDate={disabledDate}   />
         </Form.Item>
        </div>

        </div>
        

        <Table
            locale={{
              emptyText: 'Henüz eklemediniz...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={totalItem}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />

        <div style={{ display:"flex"  }}>
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Toplam Fiyat</p>
            {parseFloat(allTotalPrice).toFixed(2)} {selectedCurrency}
        </div>


        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}> Kdv % </p>
          <Form.Item name="kdvPercent"
           rules={[
            {
              required: true,
              message: 'Lütfen Kdv giriniz!',
            }
          ]} 
          onChange={handleKdvChange} 

          >
             <Input style={{  width:"200px" }} placeholder=""   className="input-style"    />
           </Form.Item>  
        </div>
        

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Kdvli Fiyat</p>
         {allTotalPriceKdv} {selectedCurrency}
        </div>
        </div>
        
        
        <div style={{float:"right"}}>
        <Form.Item>
            <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Pdf Oluştur ve Gönder</Button>
            </Form.Item>

        </div>
        <div style={{float:"right"}}>
        <Form.Item>
            <Button onClick={downloadExcel} style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Excel İndir</Button>
            </Form.Item>

        </div>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>

  };
  export default ThicknessPriceOrderCustomerPage;
 