import React,{useEffect,useState} from "react";
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { colors } from "../../../environments/environment";

const  ReportingListLimited = () => {
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([
      { title: "Satış Raporlamaları", link: "/salesreportinglist" },
      { title: "Teklif Raporlamaları", link: "/offerreportinglist" },
      { title: "Üretim Raporlamaları", link: "/productionreportinglist" },
      { title: "Hammadde Raporlamaları", link: "/rawmaterialreportinglist" },
      { title: "Satın Alma Raporlamaları", link: "/purchasingreportslist" },
      { title: "Gelir-Gider Raporlamaları", link: "/salesandquotationreportinglist" },
      { title: "Makine Raporlamaları", link: "/machinereportinglist" },

    ]);
    
    const navigate = useNavigate();
  
    useEffect(() => {
      window.scrollTo(0, 0);
  
      const fetchData = async () => {
        try {
            const [data, dataExpired] = await Promise.all([
                FirebaseService.getOpenOrderData(),
                FirebaseService.getOpenOrderDataExpired()
            ]);
    
            const combinedData = [...data, ...dataExpired]; 
    
            setItems(combinedData); 
        } catch (error) {
            console.error("Veri çekme hatası:", error);
        }
    };
    
  
      fetchData();
    }, []);
  
  
    const goDetail = (link,record) => {
      console.log("link",link,record)
        navigate(link, { state: { record } })
    };

    const columns = [

      {
        title: 'Rapor Türü',
        dataIndex: 'title',
        key: 'title',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
        </span>,
      },
  
          
      {
          title: ' ',
          dataIndex: 'edit',
          key: 'edit',
          render: (text, record) => (
              <Button  onClick={()=>goDetail(record.link,items)} style={{ backgroundColor: "white", color: colors.blue, fontWeight: "500", border: "none" }}>
              Detaya Git
            </Button>
          ),
        }
    ];

    return  <div
        className="limited-list-border">
        <Button  className="title-limited-list">
        Satış Veri Raporlamaları
         </Button>

         <Table
            locale={{
              emptyText: 'Henüz veri bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={options}
            columns={columns}
            showHeader={true}
            pagination={false}
            className="custom-news-table"
            rowClassName="custom-news-row"
          />
          
    </div>
};
export default ReportingListLimited;
