import React, { useEffect, useState } from "react";
import { Input, Button ,Spin} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { colors, parsCollections } from "../../../environments/environment";
import NotificationService from "../../../services/antNotificationService";

const ExpenseEdit = () => {
  const location = useLocation();
  const record = location.state && location.state.record;
  console.log("record",record)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [previousExpenses, setPreviousExpenses] = useState({}); 
  const [inputValues, setInputValues] = useState({});
  

  
  useEffect(() => {
   
    if (record && record.prices) {
      setInputValues(record.prices);
  
      const currentMonth = record.month;
      const currentYear = record.year;

      console.log("Mevcut Ay ve Yıl:", currentMonth, currentYear);
    
    let previousMonth, previousYear;
    if (currentMonth === 1) { 
      previousMonth = 12;
      previousYear = currentYear - 1;
    } else {
      previousMonth = currentMonth - 1;
      previousYear = currentYear;
    }
      fetchPreviousExpenses(previousMonth, previousYear);
    }
  }, [record]);
  
  console.log("set input values" , inputValues)

  const fetchPreviousExpenses = async (previousMonth, previousYear) => {
    try {
        const fetchedData = await FirebaseService.getExpensesData();

        console.log("Fetched data:", fetchedData);

        const filteredData = fetchedData.filter( (data) => data.month === previousMonth && data.year === previousYear)

        console.log(filteredData , "filteredData")  
        const expenseData = {};

        filteredData.forEach(data => {
            if (data.prices) {
                Object.entries(data.prices).forEach(([category, items]) => {
                    if (!expenseData[category]) {
                        expenseData[category] = [];
                    }
                    items.forEach(item => {
                        const price = parseFloat(item.price);
                        if (!isNaN(price)) {
                            expenseData[category].push({
                                ...item,
                                price,
                            });
                        } else {
                            console.warn("Geçersiz fiyat verisi:", item.price);
                        }
                    });
                });
            }
        });

        console.log("Processed Previous Expenses:", expenseData);
        setPreviousExpenses(expenseData);
    } catch (error) {
        console.error("Gider verileri alınırken hata oluştu:", error);
    }
};

  
  
const calculatePercentageDifference = (current, previous) => {
  console.log("farkı hesapladı", "current :", current, "previous : ", previous)
  if (previous === 0) return " ";
  const difference = ((current - previous) / previous) * 100;
  return `${difference.toFixed(2)}%`;
};


const getPreviousPrice = (category, index) => {
  console.log("get previous price çlışıyor")
  console.log("category :", category, "index", index)

  return previousExpenses[category]?.[index]?.price || 0;
};

const getPreviousTotal = (category) => {
  console.log("get Previus total çalışıyo")

  const previousTotal = (previousExpenses[category] || []).reduce((total, item) => {
      const price = parseFloat(item.price) || 0;
      return total + price;
  }, 0);
  console.log("previous total", previousTotal)
  return previousTotal;
};

  
  


  const calculateCreditMonth = (startDate, endDate) => {
    console.log("credit month u da hesapladı")
    if (!startDate || !endDate) return null;

    const start = new Date(startDate.seconds * 1000);
    const end = new Date(endDate.seconds * 1000);
    const current = new Date();

    const totalMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
    const currentMonth = (current.getFullYear() - start.getFullYear()) * 12 + (current.getMonth() - start.getMonth()) + 1;

    return `${currentMonth}/${totalMonths}`;
  };

  const handleInputChange = (category, index, field, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [category]: [
        ...(prevValues[category] || []).map((entry, idx) =>
          idx === index ? { ...entry, [field]: value } : entry
        )
      ]
    }));
  };

  const calculateTotal = (category) => {
    console.log("calculate total çalışıyo")
    const existingEntriesTotal = (inputValues[category] || []).reduce((total, item) => {
      const inputValue = parseFloat(item.price) || 0;
      return total + inputValue;
    }, 0);
    console.log("existingEntriesTotal" ,existingEntriesTotal)
    return existingEntriesTotal;
  };



  const saveChanges = async () => {
    setLoading(true)
    const currentDate = new Date();

    const data = {
      ...record,
      prices: inputValues
    };

    const result = await FirebaseService.updateRecording(parsCollections.expenses, record.key, data);
    if (result.success) {
      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: result.message,
        placement: 'topRight'
      });
      setLoading(false)
      setTimeout(() => {
        navigate("/expenselist");
      }, 2000);
    } else {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight'
      });setLoading(false)
    }
  };

  const addNewItem = (category) => {
    setInputValues((prev) => ({
      ...prev,
      [category]: [...(prev[category] || []), { contentName: "", price: "" }],
    }));
  };
  
  const removeItem = (category, index) => {
    setInputValues((prev) => ({
      ...prev,
      [category]: prev[category].filter((_, i) => i !== index),
    }));
  };
  
  
  return (
    <div style={{ padding: "10px 100px" }}>
      <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign: "left" }}> {record.month}/{record.year} Gider Listesi</h2>
      <div style={{display:"flex"}}> 
        {Object.keys(inputValues)
          .sort((a, b) => a.localeCompare(b))
          .map(category => (
            <div key={category} style={{ marginBottom: '20px', marginRight: "40px" }}>
              <h3 style={{ fontWeight: "bold", color: colors.blue, textAlign: "left" }}>{category}</h3>
              <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                {inputValues[category].map((item, index) => (
                  <div key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Input
                      placeholder="İçerik Adı"
                      style={{ width: "150px", marginRight: "10px" }}
                      value={item.contentName}
                      onChange={(e) => handleInputChange(category, index, 'contentName', e.target.value)}
                    />
                  <Input
                      placeholder="Fiyat"
                      style={{ width: "80px", marginRight: "10px" }}
                      value={item.price}
                      onChange={(e) => {
                        const onlyNumbers = e.target.value.replace(/[^0-9.]/g, ""); 
                        handleInputChange(category, index, "price", onlyNumbers);
                      }}
                    />   
                    <Button onClick={() => removeItem(category, index)}>-</Button>
                  </div>
                ))}
              </div>
                <Button style={{marginTop:"10px"}} onClick={() => addNewItem(category)}>+</Button>
              
            </div>
          ))}
      </div>
      <Button
        style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500", float: "right" }}
        onClick={saveChanges}>
        Kaydet
      </Button>

      {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>
  );
};

export default ExpenseEdit;
