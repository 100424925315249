import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,Modal, DatePicker,Spin} from 'antd';
import NotificationService from "../../services/antNotificationService";

import FirebaseService from "../../services/firebaseService";
import  { parsCollections}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';


const InvoiceCategoryContentAdd = () => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      
      const fetchData = async () => {
       
        const data = await FirebaseService.getInvoiceCategoryData();
        setCategories(data);
     
      };
      fetchData();
    }, []);


   
   const onFinish = async (values) => {
    setLoading(true)
    const currentDate =new Date()    
    let data = "";

    if(values.categoryName === "Banka/ Kredi Giderleri"){
         
           data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
  
            categoryName: values.categoryName,
            contentName: values.contentName  ,
            contentStartDate : new Date(values.contentStartDate),
            contentEndDate: new Date(values.contentEndDate)
  
          };
        }else{

           data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
  
            categoryName: values.categoryName,
            contentName: values.contentName  ,
            contentStartDate : null,
            contentEndDate: null
      
          };
        
        }
        
console.log("data",data)
      
      try {
        const result = await FirebaseService.addRecording(parsCollections.invoiceCategoryContents,data);
        if (result.success) {
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: result.message,
            placement: 'topRight'
          });
          setLoading(false)
          setTimeout(() => {
            navigate("/invoicecategorycontentlist");
          }, 2000);
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });setLoading(false)}
        
        
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
        setLoading(false)
      }
      
   
    }; 

    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen zorunlu alanları doldurunuz.',placement:'topRight'})
    };
    
  

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 3,
            }}
            wrapperCol={{
            span: 10,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
   <Form.Item
   label="Kategori"
        name="categoryName"
        rules={[
          {
            required: true,
            message: 'Lütfen kategori seçiniz!',
          },
        ]}
      >
        <Select
        showSearch
          className="input-style" 
          placeholder="Kategori Seçiniz"
         
          suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
          options={categories.map(item => ({ label: item.categoryName, value: item.categoryName }))}
          filterOption={(input, option) => 
            option.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
          }
        />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.categoryName !== currentValues.categoryName
        }
      >
        {({ getFieldValue }) => {
          const typeValue = getFieldValue('categoryName');

          return typeValue === 'Banka/ Kredi Giderleri' ? (
              <>
             
               <Form.Item
                 label="İçerik Adı"
                 name="contentName"
                 rules={[
                   {
                     required: true,
                     message: 'Lütfen giriniz!',
                   },
                   
                 ]}
               >
                 <Input className="input-style" style={{width:"100%"}} placeholder="İçerik Adı Giriniz" />
               </Form.Item>

              
               <Form.Item
            name="contentStartDate"
            label="Başlangıç Tarihi"
            rules={[
              {
              required: true,
              message: 'Lütfen termin tarihi giriniz!',
              },
            ]}
            >
            <DatePicker style={{ width: '100%'}} placeholder="Başlangıç Tarihi" 
            className="input-style" 
           />


              </Form.Item>

              <Form.Item
            name="contentEndDate"
            label="Bitiş Tarihi"
            rules={[
              {
              required: true,
              message: 'Lütfen bitiş tarihi giriniz!',
              },
            ]}
            >
            <DatePicker style={{ width: '100%', }} placeholder="Bitiş Tarihi" 
            className="input-style" 
           />


              </Form.Item>
           
             
               </>
          ) :
          
          (
            <>
             
            <Form.Item
              label="İçerik Adı"
              name="contentName"
              rules={[
                {
                  required: true,
                  message: 'Lütfen giriniz!',
                },
                
              ]}
            >
              <Input className="input-style" style={{width:"100%"}} placeholder="İçerik Adı Giriniz" />
            </Form.Item>  
            </>
          ) ;
        }}
      </Form.Item>

            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button loading={loading} htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
        
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}


    </div>)

        };
  export default InvoiceCategoryContentAdd;