import React, { useEffect, useState, useRef } from 'react';
import { Menu, Modal } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import NotificationService from "../../services/antNotificationService";
import FirebaseService from '../../services/firebaseService';
import { colors } from "../../environments/environment";

function getItem(link, label, key, children, type, image, selectedImage) {
  return {
    link,
    key,
    children,
    label,
    type,
    image,
    selectedImage
  };
}

const WebMenu = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [selectedKey, setSelectedKey] = useState('');
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(window.innerWidth < 1317);
  const menuRef = useRef(null);
  
  const handleMenuClick = (key) => {
    setSelectedKey(key);
  };

  const handleLogout = () => {
    setVisible(false);
    localStorage.clear();
    navigate('/login');
  };

  const showConfirm = () => {
    Modal.confirm({
      title: 'Çıkış Yap',
      content: 'Çıkış yapmak istediğinize emin misiniz?',
      className: "popup-modal-button",
      okText: 'Evet, Çıkış Yap',
      cancelText: 'Vazgeç',
      onOk: handleLogout,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedUserData = await FirebaseService.getUserByUid(localStorage.getItem("uid"));
        if (fetchedUserData) {
          setUserData(fetchedUserData);
          const isUser = fetchedUserData.userType.includes('user');
          const isAdmin = fetchedUserData.userType.includes('headAdmin');
          const isProgrammer = fetchedUserData.userUnit.includes('teknik ressam');
          const isBuyer = fetchedUserData.userUnit.includes('satın alma');
          const isSalesman = fetchedUserData.userUnit.includes('satış');
          const isManager = fetchedUserData.userUnit.includes('yönetici');
          const isProductionManager = fetchedUserData.userUnit.includes('üretim müdürü');
          const isAccountingManager = fetchedUserData.userUnit.includes('muhasebe');

          setItems((prevItems) => {
            const updatedItems = [...prevItems];
            if (isUser && !prevItems.some(item => item.link === '/homepage')) {
              updatedItems.push(getItem('/homepage', 'Anasayfa', '15', '', '', '/images/menu-home-white.svg', '/images/menu-home-blue.svg'));
            }
            if (isProgrammer && !prevItems.some(item => item.link === '/programmerpage')) {
              updatedItems.push(getItem('/programmerpage', 'Teknik Ressam', '9', '', '', '/images/menu-programmer-white.svg', '/images/menu-programmer-blue.svg'));
            }
            if (isBuyer && !prevItems.some(item => item.link === '/buyerpage')) {
              updatedItems.push(getItem('/buyerpage', 'Satın Alma', '10', '', '', '/images/menu-buyer-white.svg', '/images/menu-buyer-blue.svg'));
            }
            if (isSalesman && !prevItems.some(item => item.link === '/salesmanpage')) {
              updatedItems.push(getItem('/salesmanpage', 'Satış', '11', '', '', '/images/menu-salesman-white.svg', '/images/menu-salesman-blue.svg'));
            }
            if (isManager && !prevItems.some(item => item.link === '/managerpage')) {
              updatedItems.push(getItem('/managerpage', 'Yönetici', '12', '', '', '/images/menu-manager-white.svg', '/images/menu-manager-blue.svg'));
            }
            if (isProductionManager && !prevItems.some(item => item.link === '/productionmanagerpage')) {
              updatedItems.push(getItem('/productionmanagerpage', 'Üretim Müdürü', '13', '', '', '/images/menu-productionManager-white.svg', '/images/menu-productionManager-blue.svg'));
            }
            if (isAccountingManager && !prevItems.some(item => item.link === '/accountingpage')) {
              updatedItems.push(getItem('/accountingpage', 'Muhasebe', '14', '', '', '/images/menu-accounting-white.svg', '/images/menu-accounting-blue.svg'));
            }
            if (isAdmin && !prevItems.some(item => item.link === '/adminpanel')) {
              updatedItems.push(getItem('/adminpanel', 'Admin Panel', '8', '', '', '/images/menu-adminpanel-white.svg', '/images/menu-adminpanel-blue.svg'));
            }
            return updatedItems;
          });
        } else {
          console.log('User not found in Firestore');
        }
      } catch (error) {
        console.log('Error fetching user data:', error.message);
        setItems([]);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const pathname = location.pathname;
    const menuItem = items.find(item => item.link === pathname);
    if (menuItem) {
      setSelectedKey(menuItem.key);
    }
  }, [location.pathname, items]);

  useEffect(() => {
    const handleResize = () => {
      setIsMenuCollapsed(window.innerWidth < 1317);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Menü referansını kontrol et ve dışarı tıklanmışsa menüyü kapat
      if (window.innerWidth < 1317 && menuRef.current && !menuRef.current.contains(event.target) && !isMenuCollapsed) {
        setIsMenuCollapsed(true); // Menü kapat
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuCollapsed]);

  return (
    <div style={{ position: 'relative' }}>
      {isMenuCollapsed && (
        <div
          style={{ position: 'absolute', top: 10, left: 10
            , zIndex: 9999 }}
          onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
        >
          <img
            src="/images/hamburger_menu.svg"
            alt="menu"
            style={{ width: '40px', height: '40px' }}
          />
        </div>
      )}
      <div
        ref={menuRef}
        style={{
          overflowY: 'auto',
          width: isMenuCollapsed ? '0' : '160px',
          transition: 'width 0.3s',
          background: colors.blue,
          height: '100%',
          position: isMenuCollapsed ? 'absolute' : 'relative',
          top: '0',
          left: isMenuCollapsed ? '-160px' : '0',
          zIndex: isMenuCollapsed ? '9998' : '1',
        }}
      >
        <Menu
          selectedKeys={[selectedKey]}
          defaultOpenKeys={['1']}
          mode="inline"
          theme="dark"
          style={{ background: colors.blue, height: '100%', width: '100%', overflowY: 'auto', position: 'relative' }}
          onClick={({ key }) => handleMenuClick(key)}
        >
          <img width={100} height={60} alt="logo" src="/images/prosis-logo-white.svg" />
          {items.map((item) => (
            <Menu.Item
              className={selectedKey === item.key ? "selected" : ""}
              key={item.key}
              style={{ display: "block", height: "130px", padding: 0, textAlign: "center" }}
            >
              <Link to={item.link}>
                <div>
                  <img
                    alt=""
                    src={selectedKey === item.key ? item.selectedImage : item.image}
                    style={{ width: '50px', height: "50px", objectFit: "contain", marginTop: "20px" }}
                  />
                </div>
                <div>{item.label}</div>
              </Link>
            </Menu.Item>
          ))}
          {userData.email ? (
           <Menu.Item
           key={"signout"}
           style={{ textAlign: "center", display: "block", height: "130px" }}
           onClick={() => {
             setSelectedKey("signout"); // "Çıkış Yap" tıklandığında seçili anahtarı güncelle
             showConfirm(); // Onay modalını göster
           }}
         >
           <div>
             <img
               alt=""
               src={selectedKey === "signout" ? '/images/menu-signout-blue.svg' : '/images/menu-signout-white.svg'}
               style={{ width: '50px', height: "50px", objectFit: "contain", marginTop: "20px" }}
             />
           </div>
           <div style={{ textAlign: "center", marginRight: "10px" }}>
             Çıkış Yap
           </div>
         </Menu.Item>
         
          ) : (
            <Menu.Item key={"login"} style={{ textAlign: "left" }}>
              <Link to="/login">Giriş Yap</Link>
            </Menu.Item>
          )}
        </Menu>
      </div>

      <Modal
        title="Çıkış Yap"
        visible={visible}
        onOk={handleLogout}
        onCancel={() => setVisible(false)}
      >
        <p>Çıkış yapmak istediğinize emin misiniz?</p>
      </Modal>
    </div>
  );
};

export default WebMenu;


/**
   <div style={{ marginBottom: "60px", marginTop: "40px" }}>
          <Menu.Item key={"icon"} style={{ height: "100px", pointerEvents: "none", marginBottom: "-20px" }}>
 
        {userData.imageUrl ? (
    <img alt="" src={userData.imageUrl} style={{ marginRight: '10px', width: '100px',height:"100px", borderRadius:"100%",objectFit:"cover", marginTop:"20px" }} />
  ) : (
    <img alt="" src="/images/menuusericon.png" style={{marginRight: '10px', width: '100px',height:"100px", borderRadius:"100%",objectFit:"cover", marginTop:"20px" }} />
  )}
          
        </Menu.Item>
        <Menu.Item key={"name"} style={{ pointerEvents: "none", marginBottom: "-20px",  marginTop:"20px" }}>
          <span style={{ color: "white", fontSize: "18px", pointerEvents: "none" }}>{userData.name} {userData.surname} </span>
        </Menu.Item>

            <Menu.Item key={"email"} style={{ pointerEvents: "none" }}>
              <span style={{ color: "white", fontSize: "12px" }}>{userData.email}</span>
            </Menu.Item>
          </div>


            <Button
        type="primary"
        onClick={toggleCollapsed}
        style={{ display: 'block', zIndex:20, padding:"0px 0px",marginTop:"-1px"}}
        className='menu-icon'
      >
        {collapsed ?     
        <div style={{ background: "white", width:"40px", textAlign:"left" }}>
         <img src='/images/menu.png' style={{ cursor: 'pointer', backgroundColor:"white", width:"30px", height:"20px", marginTop:"5px", marginLeft:"5px" }}  alt="menu" />
         </div>
         : 
         <div   style={{ background: colors.blue, width:"220px" , textAlign:"left"}}>
         <img src='/images/whitemenu.png' style={{ cursor: 'pointer',  width:"30px", height:"20px" ,  marginTop:"5px", marginLeft:"5px" }}  alt="menu" />
      </div>
      }
      </Button>
 */